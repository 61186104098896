<div
  class="header"
  *ngIf="showHeader"
  [ngClass]="{ expanded: (collapsed$ | async) === false }"
>
  <div class="title" *ngIf="config.display">{{ title }}</div>
  <div class="actions">
    <cb-loading
      *ngIf="isLoading$ | async"
      [diameter]="15"
      [isAbsolute]="false"
    ></cb-loading>
    <ng-container *ngIf="processButtons.length">
      <app-context-menu [contextMenuItems]="processButtons"></app-context-menu>
    </ng-container>
    <button *ngIf="config.collapsible" mat-icon-button (click)="toggle()" class="cb-icon-button">
      <i
        class="fa fa-chevron-down"
        [ngClass]="{ expanded: (collapsed$ | async) === false }"
      ></i>
    </button>
  </div>
</div>

<div
  class="collapsible"
  [ngClass]="{
    headerShown: showHeader,
    expanded: (collapsed$ | async) === false
  }"
  [@collapseAnimation]="
    (collapsed$ | async) === true ? 'collapsed' : 'expanded'
  "
>
  <ng-content></ng-content>
</div>
