import { Pipe, PipeTransform } from "@angular/core";
import { FormConfigService } from "../../../form.config.service";

@Pipe({
  name: "formFieldVisibilityState",
})
export class FormFieldVisibilityStatePipe implements PipeTransform {
  constructor(private formConfigService: FormConfigService) {}
  transform(value: any, ...args: any[]): any {
    return this.formConfigService.getFieldVisibilityState(value);
  }
}
