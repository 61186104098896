// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials/partials.module";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../core/base";
// Shared
// Components
import { CrudFormModule } from "../../partials/content/crud-form/crud-form.module";
import { CrudTableModule } from "../../partials/content/crud-table/crud-table.module";
import { VirtualGarageFormComponent } from "./form/virtualgarage-form.component";
import { VirtualGarageTableComponent } from "./table/virtualgarage-table.component";

import { UserEffects, usersReducer } from "../../../core/store";
import { CbFormsModule } from "../../../modules/cb-forms/cb-forms.module";

const routes: Routes = [
  {
    path: "virtualgarage",
    component: VirtualGarageTableComponent,
  },
  {
    path: "virtualgarage/edit/:id",
    component: VirtualGarageFormComponent,
  },
  {
    path: "virtualgarage/edit/:id/:tab",
    component: VirtualGarageFormComponent,
  },
  {
    path: "virtualgarage/create",
    component: VirtualGarageFormComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature("users", usersReducer),
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CrudTableModule,
    CrudFormModule,
    CbFormsModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [VirtualGarageTableComponent, VirtualGarageFormComponent],
})
export class VirtualGarageManagementModule {}
