import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

@Component({
  selector: "kt-permission-group-description",
  templateUrl: "./permission-group-description.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PermissionGroupDescriptionComponent {
  number: string;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  private componentSubscriptions: Subscription;
  isTestdata: any;
  apiUrl: string;

  constructor(
    public dialogRef: MatDialogRef<PermissionGroupDescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  getTitle(): string {
    return "Permission Group Description";
  }
}
