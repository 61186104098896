// Angular
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
	selector: 'kt-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
})
export class TimeInputComponent implements OnInit {
  // Public properties
  @ViewChild('hh') hours: ElementRef;
  @ViewChild('mm') minutes: ElementRef;
  @ViewChild('ss') seconds: ElementRef;
  @ViewChild('sss') micsec: ElementRef;
  @Output() onTimePicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() time = '';
  hh = '00';
  mm = '00';
  ss = '00';
  sss = '000';

	 
	ngOnInit() {
    this.time = '00:00:00.000';
    this.pickDate(this.time);
  }

  onKeyPress(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onhhKey(event: any) {
    if(event.target.value < 0) {
      event.target.value = '00';
      return false;
    } else {
      if(event.target.value > 0 && event.target.value <= 9) {
        this.hh = '0'+event.target.value;
      } else {
        this.hh = event.target.value;
      }
      this.getTime(this.hh, this.mm, this.ss, this.sss);
    }
  }
  
  onmmKey(event: any) {
    if(event.target.value.length > 2 || event.target.value < 0) {
      event.target.value = '00';
      return false;
    } else {
      if(event.target.value > 0 && event.target.value <= 9) {
        this.mm = '0'+event.target.value;
      } else {
        this.mm = event.target.value;
      }
      this.getTime(this.hh, this.mm, this.ss, this.sss);
    }
  }
  
  onssKey(event: any) {
    if(event.target.value.length > 2|| event.target.value < 0) {
      event.target.value = '00';
      return false;
    } else {
      if(event.target.value > 0 && event.target.value <= 9) {
        this.ss = '0'+event.target.value;
      } else {
        this.ss = event.target.value;
      }
      this.getTime(this.hh, this.mm, this.ss, this.sss);
    }
  }
  
  onsssKey(event: any) {
    if(event.target.value.length > 3|| event.target.value < 0) {
      event.target.value = '000';
      return false;
    } else {
      this.sss = event.target.value;
      this.getTime(this.hh, this.mm, this.ss, this.sss);
    }
  }

  getTime(hour, min, sec, sss){
    this.time = hour + ':' + min + ':' + sec + '.' + sss;
    this.pickDate(this.time);
  }
  pickDate(date: any): void {
    this.onTimePicked.emit(date);
  }

  resetTime() {
    this.hours.nativeElement.value = '00';
    this.minutes.nativeElement.value = '00';
    this.seconds.nativeElement.value = '00';
    this.micsec.nativeElement.value = '000';
    this.hh = '00';
    this.mm = '00';
    this.ss = '00';
    this.sss = '000';
  }

	/**
	 * close alert
	 */
	closeAlert() {
		
	}
}
