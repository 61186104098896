import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  OnInit,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "cb-lazy-load-component",
  template: `
    <ng-container></ng-container>
    <ng-container
      [ngTemplateOutlet]="contentTemplate"
      *ngIf="loadContent"
    ></ng-container>
  `,
})
export class LazyLoadComponent implements AfterContentChecked {
  constructor(private elRef: ElementRef) {}

  @ContentChild("body", { static: true })
  contentTemplate: TemplateRef<ElementRef>;

  loadContent: boolean;
  ngAfterContentChecked() {
    if (this.elRef.nativeElement.offsetParent) this.loadContent = true;
  }
}
