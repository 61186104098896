<div
  ngbDropdown
  placement="bottom-right"
  *ngIf="user$ | async as _user"
  class="kt-header__topbar-item kt-header__topbar-item--user"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <div
      class="kt-header__topbar-user custom-user-menu__header"
      [ngClass]="{ 'kt-header__topbar-icon': icon }"
    >
      <span class="kt-header__topbar-welcome kt-hidden-mobile" *ngIf="greeting"
        >Hi,</span
      >
      <span class="kt-header__topbar-username kt-hidden-mobile" *ngIf="greeting"
        >{{ _user.firstname }} {{ _user.lastname }}</span
      >
      <i *ngIf="icon" class="flaticon2-user-outline-symbol"></i>

      <ng-container *ngIf="profileImage || _user.pic; else noPic">
        <img
          *ngIf="profileImage; else avatar"
          alt="Pic"
          [attr.src]="profileImage"
          style="max-height: 40px; border-radius: 4px"
        />
        <ng-template #avatar>
          <img
            *ngIf="avatar && _user.pic"
            alt="Pic"
            [attr.src]="_user.pic"
            style="max-height: 40px; border-radius: 4px"
          />
        </ng-template>
      </ng-container>
      <span
        *ngIf="badge"
        class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
        >{{ _user.firstname | firstLetter
        }}{{ _user.lastname | firstLetter }}</span
      >
    </div>
  </div>
  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl custom-user-menu__content"
  >
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
    >
      <div class="kt-user-card__avatar">
        <ng-container *ngIf="profileImage || _user.pic; else noPic">
          <img
            alt="Pic"
            [attr.src]="storageUrl + _user.pic"
            style="max-height: 40px; border-radius: 4px"
          />
          <span
            [hidden]="true"
            class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
          >
            {{ _user.firstname | firstLetter }}
          </span>
        </ng-container>
      </div>
      <div class="kt-user-card__name">
        {{ _user.firstname }} {{ _user.lastname }}
      </div>
      <!--
                        <div class="kt-user-card__badge">
                            <span class="btn btn-success btn-sm btn-bold btn-font-md">23 messages</span>
                        </div>
            -->
    </div>
    <!--end: Head -->

    <!--begin: Navigation -->
    <div class="kt-notification">
      <a
        ngbDropdownItem
        [routerLink]="['/profile/info']"
        href="javascript:;"
        class="chevronless signout-link"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ "MENU.AUTHENTICATION.MY_PROFILE" | translate }}
          </div>
          <div class="kt-notification__item-time">
            {{ "MENU.AUTHENTICATION.ACCOUNT_SETTINGS" | translate }}
          </div>
        </div>
      </a>

      <a
        ngbDropdownItem
        [routerLink]="['/profile/configuration']"
        href="javascript:;"
        class="chevronless signout-link"
      >
        <div class="kt-notification__item-icon">
          <i class="fa-solid fa-sliders"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ "MENU.AUTHENTICATION.MY_CONFIGURATION" | translate }}
          </div>
          <div class="kt-notification__item-time">
            {{ "MENU.AUTHENTICATION.MY_CONFIGURATION_DESC" | translate }}
          </div>
        </div>
      </a>

      <a
        ngbDropdownItem
        [routerLink]="['/profile/password-two-factor']"
        href="javascript:;"
        class="chevronless signout-link"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-shield"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ "MENU.AUTHENTICATION.AUTHENTICATION" | translate }}
          </div>
          <div class="kt-notification__item-time">
            {{ "MENU.AUTHENTICATION.PASSWORD_AND_TWO_FACTOR" | translate }}
          </div>
        </div>
      </a>

      <a
        ngbDropdownItem
        href="https://www.circuit-booking.com/terms-and-conditions"
        target="_blank"
        class="chevronless signout-link"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-crisp-icons"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ "MENU.AUTHENTICATION.TERMS_AND_CONDITIONS" | translate }}
          </div>
          <div class="kt-notification__item-time">
            {{ "MENU.AUTHENTICATION.TERMS_AND_CONDITIONS_DESC" | translate }}
          </div>
        </div>
      </a>
      <!--
                        <a ngbDropdownItem routerLink="profile" href="javascript:;" class="kt-notification__item">
                            <div class="kt-notification__item-icon">
                                <i class="flaticon2-mail kt-font-warning"></i>
                            </div>
                            <div class="kt-notification__item-details">
                                <div class="kt-notification__item-title kt-font-bold">
                                    My Messages
                                </div>
                                <div class="kt-notification__item-time">
                                    Inbox and tasks
                                </div>
                            </div>
                        </a>
                        <a ngbDropdownItem routerLink="profile" href="javascript:;" class="kt-notification__item">
                            <div class="kt-notification__item-icon">
                                <i class="flaticon2-rocket-1 kt-font-danger"></i>
                            </div>
                            <div class="kt-notification__item-details">
                                <div class="kt-notification__item-title kt-font-bold">
                                    My Activities
                                </div>
                                <div class="kt-notification__item-time">
                                    Logs and notifications
                                </div>
                            </div>
                        </a>
                        <a ngbDropdownItem routerLink="profile" href="javascript:;" class="kt-notification__item">
                            <div class="kt-notification__item-icon">
                                <i class="flaticon2-hourglass kt-font-brand"></i>
                            </div>
                            <div class="kt-notification__item-details">
                                <div class="kt-notification__item-title kt-font-bold">
                                    My Tasks
                                </div>
                                <div class="kt-notification__item-time">
                                    latest tasks and projects
                                </div>
                            </div>
                        </a>
            -->
      <div class="kt-notification__custom p-0">
        <a
          href="javascript:;"
          (click)="logout()"
          class="signout-link kt-font-bold"
        >
          <i class="la la-sign-out"></i>
          <span>
            {{ "MENU.AUTHENTICATION.SIGN_OUT" | translate }}
          </span>
        </a>
      </div>
    </div>
    <!--end: Navigation -->

    <!--begin: Navigation(alternative) -->
    <ul class="kt-nav kt-margin-b-10 kt-hidden">
      <li class="kt-nav__item">
        <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"
            ><i class="flaticon2-calendar-3"></i
          ></span>
          <span class="kt-nav__link-text">{{
            "AUTHENTICATION.MY_PROFILE" | translate
          }}</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"
            ><i class="flaticon2-browser-2"></i
          ></span>
          <span class="kt-nav__link-text">My Tasks</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"><i class="flaticon2-mail"></i></span>
          <span class="kt-nav__link-text">Messages</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"><i class="flaticon2-gear"></i></span>
          <span class="kt-nav__link-text">Settings</span>
        </a>
      </li>
      <li class="kt-nav__item kt-nav__item--custom kt-margin-t-15 p-0">
        <a href="javascript:;" (click)="logout()" class="signout-link">
          <i class="la la-sign-out"></i>
          <span>Sign Out</span>
        </a>
      </li>
    </ul>
    <!--end: Navigation(alternative) -->
  </div>
</div>
<ng-template #noPic>
  <span
    class="svg-icon svg-icon-primary svg-icon-2x"
    style="color: var(--primary-text-color)"
  >
    <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-12-28-020759/theme/html/demo2/dist/../src/media/svg/icons/General/User.svg--><svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
          fill="currentColor"
          fill-rule="nonzero"
          opacity="0.3"
        />
        <path
          d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
          fill="currentColor"
          fill-rule="nonzero"
        />
      </g>
    </svg>
    <!--end::Svg Icon-->
  </span>
</ng-template>
