import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ConfigurationComponent } from "./configuration.component";

@Component({
  selector: "app-admin-settings",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserConfigurationsPageComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private location: Location,
    private router: Router
  ) {}

  // this is not properly implemented (we need to have a named router outlet),
  // but for now it works
  ngOnInit(): void {
    this.dialog
      .open(ConfigurationComponent, {
        panelClass: ["fullscreen-modal"],
      })
      .afterClosed()
      .subscribe({
        next: (_) => {
          const navigationState = this.location.getState();
          if (navigationState && navigationState["navigationId"] > 1) {
            this.location.back();
          } else {
            this.router.navigate([".."]);
          }
        },
      });
  }
}
