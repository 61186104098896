export class BaseModel {
  // Edit
  _isEditMode = false;
  // Log
  _userId = 0; // Admin
  _createdDate: string;
  _updatedDate: string;

  // Optional:
  // internal id: random unique id used for table to form editing
  // see sample usage on MyProfile > Virtual Garage
  _internalId: string;

  // set InternalId: called when added as an entry in the UI
  _setInternalId() {
    // generate random unique alpha-numeric id
    this._internalId = (Math.random() * 1e32).toString(36);
  }
}

export interface KeyValuePair<T> {
  key: string;
  value: T;
}
