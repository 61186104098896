import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from "@angular/core";
import { WsService } from "../../../../../services";

@Component({
  selector: "app-ws-status",
  templateUrl: "./ws-status.component.html",
  styleUrls: ["./ws-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WsStatusComponent implements OnInit {
  wsService = inject(WsService);
  currentStatus$ = this.wsService.getCurrentWsStatus();
  ngOnInit(): void {}
}
