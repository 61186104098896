import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewRef,
} from "@angular/core";
import { forkJoin } from "rxjs";
import { ApiConfig } from "./../../../../core/config/api.config";
import { ApiService } from "./../../../../services/api.service";
import { CustomDropzonePreviewComponent } from "./custom-dropzone-preview/custom-dropzone-preview.component";

@Component({
  selector: "custom-dropzone",
  templateUrl: "./custom-dropzone.component.html",
  styleUrls: ["./custom-dropzone.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDropzoneComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService
  ) {}

  @Input() name: string; // unique name
  @Input() type: string;
  @Input() model: string;
  @Input() modelId: number | string;
  @Input() types: string[] = [];
  @Input() maxUploadCount: number = null;
  @Input() readonly = false;
  @Input() acceptedFileTypes = [];

  @Output() onInit: EventEmitter<CustomDropzoneComponent> =
    new EventEmitter<CustomDropzoneComponent>();

  files: File[] = [];
  mediaItems: any[] = [];
  loadingMediaItems: boolean;

  previewItems: CustomDropzonePreviewComponent[] = [];
  // imageTypes: string[] = [];
  // docTypes: string[] = [];

  subscription: any;

  ngOnInit() {
    if (this.modelId) this.loadMediaItems();

    // get media upload types
    // const apiUrl = ApiConfig.MediaLibraryUrl() + '/media-upload-types';
    // this.apiService.get(apiUrl).subscribe((result) => {
    //     this.imageTypes = [...result.images];
    //     this.docTypes = [...result.docs];

    //     //console.log('upload-types', this.imageTypes, this.docTypes);
    //     this.crefDetectChanges();
    // });
  }

  ngAfterViewInit() {
    this.onInit.emit(this);
  }

  ngOnChanges({ model, modelId, types, maxUploadCount }: SimpleChanges) {
    let needReload = false;

    if (model && model.currentValue != this.model) {
      this.model = model.currentValue;
      needReload = true;
    }

    if (modelId && modelId.currentValue != this.modelId) {
      this.modelId = modelId.currentValue;
      needReload = true;
    }

    if (this.modelId && needReload) this.loadMediaItems();

    if (types) this.types = types.currentValue;
    if (maxUploadCount) this.maxUploadCount = maxUploadCount.currentValue;
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  loadMediaItems() {
    this.loadingMediaItems = true;
    const url =
      ApiConfig.MediaLibraryUrl() +
      `?model=${this.model}&model_id=${this.modelId}&type=${
        this.type
      }&prop_types=${this.types.join(",")}`;
    this.subscription = this.apiService.getAll(url).subscribe((result) => {
      this.loadingMediaItems = false;
      this.mediaItems = result || [];
      this.crefDetectChanges();
    });
  }

  crefDetectChanges() {
    if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

  onSelect(event) {
    console.log(event);
    if (!this.canUpload()) return;
    if (event.addedFiles) this.files.push(...event.addedFiles);
  }

  onRemove(item) {
    //console.log("onRemove", item);
    if (item.id) {
      this.mediaItems.splice(
        this.mediaItems.findIndex((r) => r.id == item.id),
        1
      );
    } else {
      this.files.splice(this.files.indexOf(item), 1);
    }
  }

  previewItemOnDestroy(item) {
    const i = this.previewItems.findIndex(
      (r) => r.componentId == item.componentId
    );
    if (i >= 0) this.previewItems.splice(i, 1);
  }

  hasItemsToDisplay() {
    return (
      (this.files && this.files.length > 0) ||
      (this.mediaItems && this.mediaItems.length > 0)
    );
  }

  getAcceptedFileTypes() {
    if (this.acceptedFileTypes && this.acceptedFileTypes.length > 0)
      return this.acceptedFileTypes.join(",");

    if (this.type == "images") return "image/*";
    else if (this.type == "documents") return "application/pdf";
  }

  canUpload() {
    if (this.readonly) return false;
    const count = (this.mediaItems || []).length + (this.files || []).length;
    return !this.maxUploadCount || count < this.maxUploadCount;
  }

  hasPendingItems() {
    return this.previewItems && this.previewItems.length > 0;
  }

  uploadFiles() {
    if (!this.previewItems || this.previewItems.length == 0) return;
    const joins = {};
    this.previewItems.forEach((file) => {
      joins[file.componentId] = file.uploadDone;
      file.modelId = this.modelId;
      file.upload();
    });
    return forkJoin(joins);
  }

  getFileDesc(file) {
    if (!file || !file.name) return "";
    return file.name.split(".").slice(0, -1).join("");
  }
}
