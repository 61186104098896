import { Pipe, PipeTransform } from "@angular/core";
import { FormConfigService } from "../form.config.service";

@Pipe({
  name: "parsePlaceholder",
})
export class ParsePlaceholderPipe implements PipeTransform {
  constructor(private formConfigService: FormConfigService) {}
  transform(value: any): any {
    return this.formConfigService.parsePlaceholders(value);
  }
}
