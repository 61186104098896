<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ data.title | translate }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <div class="form-group kt-form__group row">
        <div
          *ngIf="data.icon"
          class="col-lg-4"
          [innerHTML]="data.icon | safe"
        ></div>
        <div
          class="col-lg-8"
          [innerHTML]="data.message | safe"
          runScripts
        ></div>
      </div>
    </div>
    <div
      class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right"
    >
      <div class="kt-form__actions kt-form__actions--sm">
        <ng-container>
          <button
            mat-raised-button
            (click)="dialogRef.close()"
            class="btn btn-outline-primary reset-button-styles danger-outline-button"
          >
            {{ "COMMON.CLOSE" | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
