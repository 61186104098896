<ng-container
  *ngIf="filteredParticipants$ | async as participants; else loading"
>
  <header class="d-flex align-items-center justify-content-between">
    <h4 class="font-weight-bold">
      {{ (participants$ | async)?.length || 0 }}
      {{ "SEND_EMAIL.EMAIL_RECIPIENTS" | translate }}
    </h4>
    <!-- <div class="search">
      <mat-form-field class="mat-form-field-fluid" floatLabel="never">
        <mat-icon matPrefix>search</mat-icon>
        <input
          (input)="onSearch($event)"
          matInput
          #searchInput
          autocomplete="do_not_fill"
          placeholder="Search all fields"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="searchInput.value = null; onSearchReset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div> -->
  </header>
  <div>
    <mat-chip-listbox
      #chipList
      aria-label="recipients selection"
      [@removeItem]="participants.length"
    >
      <mat-chip-option
        *ngFor="let participant of participants; trackBy: tracker"
        (removed)="remove(participant)"
        [ngClass]="{
          sent: participant.sent === 1,
          notSent: participant.sent === -1
        }"
      >
        {{ participant | labelValue: (labelTemplate$ | async) }}
        <button
          matChipRemove
          *ngIf="(participants$ | async).length > 1"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</ng-container>

<ng-template #loading>  
  <cb-loading [diameter]="20"></cb-loading>
</ng-template>
