import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "labelValue",
})
export class LabelValuePipe implements PipeTransform {
  transform(value: any, template: string): any {
    console.log(value, template);
    if (!template) return value.id;
    const result = template.replace(/(?:)(\{.*?\})(?=)/gi, (match, key) => {
      let actualVariable = match.slice(1, -1);
      if (value.hasOwnProperty(actualVariable)) {
        return value[actualVariable];
      }
      return " ";
    });

    return result;
  }
}
