// Angular
import { Component, OnInit, Input, ChangeDetectorRef, Output, ViewChild } from '@angular/core';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// Lodash
import { each, find, remove } from 'lodash';
// State
import { AppState } from '../../../../../../core/store/reducers/app.reducers';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
// Auth
import { AuthService } from '../../../../../../core/store/';
import { TimeInputComponent } from '../../../../../partials/content/time-input/time-input.component';
import { LaptimeTableComponent } from './table/laptime-table.component';

@Component({
	selector: 'kt-laptime-list',
	templateUrl: './laptime-list.component.html'
})
export class LaptimeListComponent implements OnInit {
  // Public properties
  @ViewChild('time') timeInputComponent: TimeInputComponent;
  @ViewChild('laptimeTable') laptimeTable: LaptimeTableComponent;
	// Incoming data
	@Input() loadingSubject = new BehaviorSubject<boolean>(false);
  @Input() laptimeSubject = new BehaviorSubject<[]>([]);
  @Input() userId: number;
  @Input() time = '';
  @Input() laptime;
	// Laptime
	assignedLaptime:any = [];
	location:any = [];
	variant:any = [];
  addLaptimeForm: UntypedFormGroup;
  hasFormErrors = false;
  selectedVariant = '';
  selectedLocation = '';

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>,private fb: UntypedFormBuilder, private auth: AuthService, private cdRef:ChangeDetectorRef) {}

	 
	ngOnInit() {
    this.loadData();
  }
  
  /**
	 * Load data
	 */
	loadData() {
      this.assignedLaptime = this.laptime;
      this.auth.getLocation().subscribe(res => {
        this.location = res;
        this.cdRef.detectChanges();
      });
      this.createForm();
  }
  
  /**
   * Get Variant
   */
  getVariant(event) {
    var locationId = event.value;
    this.auth.getRelatedVariant(locationId).subscribe(res => {
      this.variant = res;
      this.cdRef.detectChanges();
    });
    this.selectedLocation = event.source.selected._element.nativeElement.innerText.trim();
  }

  getSelectedVariant(event) {
    this.selectedVariant = event.source.selected._element.nativeElement.innerText.trim();
  }

  /**
	 * Init form
	 */
  createForm() {
		this.addLaptimeForm = this.fb.group({
			variant_id: ['', Validators.required]
		});
  }
  
	/**
	 * Assign laptime
	 */
	assignLaptime() {    
    this.loadingSubject.next(true);
		this.hasFormErrors = false;
		const controls = this.addLaptimeForm.controls;
		/** check form */
		if (this.addLaptimeForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			this.loadingSubject.next(false);

			return;
    }
    this.assignedLaptime.push({ 
      'laptime': this.time, 
      'variant_id': this.addLaptimeForm.value.variant_id, 
      'variant': { 'name' : this.selectedVariant, 'location': { 'name' : this.selectedLocation }} 
       
    });
    this.assignedLaptime = [...this.assignedLaptime];
    this.updateLaptime();
    this.addLaptimeForm.reset();
    this.resetLaptimeInput();
	}

  /**
   * reset laptime input
   */
  resetLaptimeInput() {
    this.timeInputComponent.resetTime();
  }
	/**
	 * Unassign Laptime
	 *
	 * @param laptime
	 */
	unassingLaptime(index) {
    this.assignedLaptime.splice(index, 1);
    this.assignedLaptime = [...this.assignedLaptime];
    this.updateLaptime();
  }

  /**
	 * update laptime
	 */
  updateLaptime() {
    const _laptime:any = [];
    each(this.assignedLaptime, elem => {
      _laptime.push({
        laptime: elem.laptime,
        variant_id: elem.variant_id,
      })
    });
    console.log(_laptime);
    this.laptimeSubject.next(_laptime);
  }

	/**
	 * Close alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
  }
  
  getTime(date: any):void {
    this.time = date;
  }
}
