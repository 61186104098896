import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material/material.module";
import { ActionMenuItemsComponent } from "./components/action-menu-items/action-menu-items.component";
import { ContextMenuItemComponent } from "./components/context-menu-item/context-menu-item.component";
import { ContextMenuComponent } from "./components/context-menu/context-menu.component";

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [ContextMenuComponent, ActionMenuItemsComponent],
  declarations: [
    ContextMenuComponent,
    ContextMenuItemComponent,
    ActionMenuItemsComponent,
  ],
  providers: [],
})
export class ContextMenuModule {}
