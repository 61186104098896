<mat-form-field
  [class]="matFormFieldClass"  
  [floatLabel]="matFormFieldFloatLabel"
  appearance="fill"
>  
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select    
    [formControl]="control"
    [compareWith]="compareSingleSelectOptions"
    [placeholder]="placeholder"
    (selectionChange)="handleSelectionChange($event)"
    [required]="required"
    [matTooltip]="tooltip"
    [multiple]="multiple"
    [attr.data-pgm-formelement]="'formitem_' + name"
    #matSelect
  >
    <!-- <mat-select-trigger *ngIf="multiple">
      <mat-chip-listbox aria-label="Selections">
        <mat-chip-option
          *ngFor="let val of control.value || []"
          [removable]="true"
          (removed)="removeFieldArrayValue(val)"
        >
          {{ getSelectedLabel(val) | translate }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </mat-select-trigger> -->

    <mat-option>
      <ngx-mat-select-search
        [formControl]="filterCtrl"
        placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
        noEntriesFoundLabel="{{ 'COMMON.NO_ENTRIES_FOUND' | translate }}"
        [preventHomeEndKeyPropagation]="true"
        [disableScrollToActiveOnOptionsChanged]="true"
      ></ngx-mat-select-search>
    </mat-option>

    <mat-option
      *ngFor="
        let option of filteredOptions
          | async
          | sortBy : 'isBookmarkedOption,label'
      "
      [value]="option.value"
      [ngClass]="{
        'is-bookmarked-option': option.isBookmarkedOption,
        'is-inactive-option': option.isInactive
      }"
    >
      {{ getLabelAsString(option.label) | translate }}
    </mat-option>
  </mat-select>
  <span matPrefix *ngIf="!!tooltip">
    <i
      class="far fa-question-circle"
      matTooltipPosition="above"
      [matTooltip]="tooltip"
      style="margin-right: 4px;"
    ></i>
  </span>
  <button
    *ngIf="allowClear !== false && control.value && !control.disabled"
    mat-icon-button
    matSuffix
    [attr.aria-label]="'Clear' | translate"
    (click)="clear(); $event.stopPropagation()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-error
    *ngIf="control.hasError('required')"
    [innerHtml]="'VALIDATION_ERRORS.REQUIRED' | translate : { title }"
  >
  </mat-error>
  <mat-error *ngIf="control.hasError('custom')">
    {{ customError }}
  </mat-error>
  <mat-error *ngIf="control.hasError('serverError')">
    <!-- {{ control.errors('serverError')}} -->
    {{ control.errors["serverError"] }}
  </mat-error>

  <mat-hint
    *ngIf="required && !disabled"
    align="start"
    [innerHtml]="'COMMON.PLEASE_SELECT' | translate : { title }"
  >
  </mat-hint>
</mat-form-field>
