// Angular
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
// Tranlsation
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { ApiConfig } from "../../config/api.config";
import { UserConfig } from "../../store/models/user-config.model";

export interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

export interface Locale {
  lang: string;
  // tslint:disable-next-line:ban-types
  data: Object;
}

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private langIds: any = [];
  constructor(private translate: TranslateService, private router: Router) {
    let currentLanguage = localStorage.getItem("language");
    if (!currentLanguage) {
      currentLanguage = "en";
    }
    this.translate.addLangs([currentLanguage]);
    this.setLanguage(currentLanguage);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(currentLanguage);
  }

  /**
   * Load Translation
   *
   * @param args: Locale[]
   */
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  /**
   * Setup language
   *
   * @param lang: any
   */
  setLanguage(lang) {
    // if (lang) {
    // fetch(`${ApiConfig.translationResourceURL()}/${lang}`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const enLang = data.find(e => e.lang == lang)
    //     this.loadTranslations(
    //       enLang
    //     );
    //   });
    //   this.translate.use(this.translate.getDefaultLang());
    // }
    this.translate.use(lang);
    localStorage.setItem("language", lang);
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    return localStorage.getItem("language") || this.translate.getDefaultLang();
  }

  getAvailableLanguages(): LanguageFlag[] {
    return [
      {
        lang: "en",
        name: "English",
        flag: "./assets/media/flags/260-united-kingdom.svg",
      },
      {
        lang: "fr",
        name: "French",
        flag: "./assets/media/flags/195-france.svg",
      },
      {
        lang: "de",
        name: "German",
        flag: "./assets/media/flags/162-germany.svg",
      },
      {
        lang: "it",
        name: "Italian",
        flag: "./assets/media/flags/013-italy.svg",
      },
      {
        lang: "ja",
        name: "Japanese",
        flag: "./assets/media/flags/063-japan.svg",
      },
      {
        lang: "nl",
        name: "Netherlands",
        flag: "./assets/media/flags/237-netherlands.svg",
      },
      {
        lang: "es",
        name: "Spanish",
        flag: "./assets/media/flags/128-spain.svg",
      },

      //		{
      //			lang: 'ch',
      //			name: 'Mandarin',
      //			flag: './assets/media/flags/034-china.svg'
      //		},
    ];
  }

  getAvailableLanguages$(): Observable<LanguageFlag[]> {
    return of<LanguageFlag[]>([
      {
        lang: "en",
        name: "English",
        flag: "./assets/media/flags/260-united-kingdom.svg",
      },
      {
        lang: "de",
        name: "German",
        flag: "./assets/media/flags/162-germany.svg",
      },
      {
        lang: "fr",
        name: "French",
        flag: "./assets/media/flags/195-france.svg",
      },
      {
        lang: "es",
        name: "Spanish",
        flag: "./assets/media/flags/128-spain.svg",
      },
      {
        lang: "jp",
        name: "Japanese",
        flag: "./assets/media/flags/063-japan.svg",
      },
    ]);
  }
}
