// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Sanitize HTML
 */
@Pipe({
	name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
	/**
	 * Pipe Constructor
	 *
	 */
	constructor() { }

	/**
	 * Transform
	 *
	 * @param value: string
	 * @param type: string
	 */
	transform(value: string): any {
		if (typeof (value) == 'string') return value.replace(/<.*?>/g, ''); // replace tags
		return value;
	}

}
