import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";

@Directive({
  selector:
    "button[mat-button][confirmAction], button[mat-raised-button][confirmAction]",
})
export class ConfirmActionButtonDirective
  implements OnInit, OnChanges, OnDestroy
{
  @Input("shouldConfirm") shouldConfirm: boolean = false;
  @Input("confirmActionMessage") confirmActionMessage: string = "Are you sure?";

  constructor(
    private vcRef: ViewContainerRef,
    private renderer: Renderer2,
    private elRef: ElementRef<HTMLElement>
  ) {}

  @HostListener("click", ["$event"]) onClick(event: MouseEvent) {
    console.log(this.shouldConfirm, this.confirmActionMessage);
  }

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {}
}
