import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ECharts, EChartsOption } from "echarts";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { environment_api } from "../../../../../environments/environment.api";
import { FilterComponentOutput } from "../../types/types";
// this component accepts a url, get all config for a chart component, then updates the UI.
@Component({
  selector: "app-generic-chart",
  templateUrl: "./generic-chart.component.html",
  styleUrls: ["./generic-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericChartComponent implements OnInit, OnDestroy {
  constructor(private http: HttpClient) {}
  @Input() filters: any = null;
  @Input() searchableFields: string[] = [];
  @Input() set apiUrl(value) {
    this.apiUrl$.next(value);
  }
  private apiUrl$ = new BehaviorSubject<string>("");
  private appliedFilters$ = new BehaviorSubject("");
  chartContainerHeight$ = new BehaviorSubject<string>("400px");

  isLoading$ = new BehaviorSubject<boolean>(false);
  isFiltersReady$ = new BehaviorSubject<boolean>(false);
  destroyed$ = new Subject<void>();

  chartInstance: ECharts;

  updateOptions$ = new BehaviorSubject<Partial<EChartsOption>>(null);
  chartOptions: EChartsOption = {};
  shouldRefresh$ = new BehaviorSubject<boolean>(false);
  ngOnInit(): void {
    combineLatest([
      this.appliedFilters$,
      this.isFiltersReady$,
      this.shouldRefresh$,
    ])
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => {
          this.isLoading$.next(true);
        }),
        filter(([, isReady]) => (!!this.filters ? isReady : true)),
        switchMap(([appliedFilter]) => {
          let fullUrl = this.apiUrl$.value;
          if (appliedFilter) {
            fullUrl += `?${appliedFilter}`;
          }
          fullUrl = environment_api.api_url + fullUrl;
          return this.http.get(fullUrl).pipe(
            map((res: { chart: EChartsOption; chart_config: any }) => {
              // response can be an object in the following form
              // {chart: ChartOptions, height: number}, or the second parameter can be omitted or be an object that includes all the config about the chart (like height, width, etc.)
              // using this approach, we can update the chart container height dynamically
              // we might need to have another component for `time series`, which only uses the `merge` functionality of the same chart
              // we can connect to WS for that in order to get the real-time data
              this.chartContainerHeight$.next(
                res.chart_config?.height || "400px"
              );
              this.updateOptions$.next(res.chart);
              this.isLoading$.next(false);
            }),
            catchError((error) => {
              this.isLoading$.next(false);
              return of(null);
            })
          );
        })
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onFilterChange(event: string) {
    this.appliedFilters$.next(event);
  }

  handleFilterChange(event: FilterComponentOutput) {
    const { queryString } = event;
    this.appliedFilters$.next(queryString);
  }

  handleFiltersReady(event: boolean) {
    this.isFiltersReady$.next(event);
  }

  onChartInit(event: ECharts) {
    this.chartInstance = event;
  }

  refresh() {
    this.shouldRefresh$.next(false);
  }
}
