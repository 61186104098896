// Angular
import { Injectable } from "@angular/core";
// RxJS
import { BehaviorSubject, of, throwError } from "rxjs";
// Object path
import * as objectPath from "object-path";
// Services
import { MenuConfigService } from "./menu-config.service";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { MenusConfigService } from "./../../../services/menus.config.service";
import { AuthService } from "./../../../services/auth.service";
import { delay, switchMap } from "rxjs/operators";

@Injectable()
export class MenuAsideService {
  // Public properties

  // menu list
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  toolboxItems$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  // current menu
  currenMenuItem$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Service constructor
   *
   * @param menuConfigService: MenuConfigService
   */
  constructor(
    private auth: AuthService,
    private menusConfigService: MenusConfigService,
    private router: Router
  ) {
    this.loadMenu();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.currenMenuItem$.next(this._getCurrentMenuItem());
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
        //console.log(event.error);
      }
    });

    this.loading$.next(true);
    this.menusConfigService
      .getMenuConfig(localStorage.getItem("language") ?? "en", "menu")
      .subscribe((res) => {
        res.config = res.config.map((e) => {
          e.isMainRoot = true;
          return e;
        });
        this.auth.getAllPermissions().subscribe((response) => {
          response.forEach((data) => {
            if (res && res.config) {
              res.config.forEach((element) => {
                if (
                  !this.menuList$.value.find(
                    (r) => r.page == element.page && r.title == element.title
                  )
                ) {
                  this.menuList$.value.push(element);
                }
              });
            }
          });
          this.toolboxItems$.next(this.menuList$.value);
          this.loading$.next(false);
          this.currenMenuItem$.next(this._getCurrentMenuItem());
        });
      });
  }

  loadToolbar() {
    return this.menusConfigService
      .getMenuConfig(localStorage.getItem("language") ?? "en", "toolbar")
      .pipe(delay(3000));
    // return Math.random() > 0.5
    //   ? of(false).pipe(
    //       delay(3000),
    //       switchMap(() => throwError("error"))
    //     )
    //   : this.menusConfigService
    //       .getMenuConfig(localStorage.getItem("language") ?? "en", "toolbar")
    //       .pipe(delay(3000));
  }

  /**
   * Load menu list
   */
  loadMenu() {
    // get menu list
    const menuItems: any[] = []; //objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
    this.menuList$.next(menuItems);
  }

  private _getCurrentMenuItem() {
    const currentRouteUrl = this.router.url.split(/[?#]/)[0];
    //console.log('currentMenuItem', currentRouteUrl, this.menuList$.value);

    let matchedMenu = null;
    for (var menuItem of this.menuList$.value) {
      const matched = this._findMenuItem(menuItem, currentRouteUrl);
      if (matched) {
        if (!matched.partial) return matched;
        if (
          matchedMenu == null ||
          matched.page.length > matchedMenu.page.length
        )
          matchedMenu = matched;
      }
    }

    return matchedMenu;
  }

  private _findMenuItem(menuItem: any, url: string): any {
    let matchedMenu = null;

    if (menuItem.page) {
      if (url == menuItem.page) return menuItem;
      if (url.indexOf(menuItem.page) == 0)
        matchedMenu = { ...menuItem, partial: true };
    }

    if (menuItem.submenu && menuItem.submenu.length > 0) {
      for (var item of menuItem.submenu) {
        const matched = this._findMenuItem(item, url);
        if (matched) {
          if (!matched.partial) return matched;
          if (
            matchedMenu == null ||
            matched.page.length > matchedMenu.page.length
          )
            matchedMenu = matched;
        }
      }
    }

    return matchedMenu;
  }
}
