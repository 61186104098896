<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-progress"></div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ data.title }}</h3>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <ng-template #target></ng-template>
    </div>
    <div
      class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right"
    >
      <div class="kt-form__actions kt-form__actions--sm">
        <button
          class="pull-left"
          color="warn"
          mat-flat-button
          type="button"
          (click)="dialogRef.close()"
        >
          <i class="fa-solid fa-circle-x"></i>
          {{ "COMMON.CANCEL" | translate }}</button
        >&nbsp;
        <button color="info" mat-flat-button (click)="close('PRIMARY')">
          <i class="la la-plus"></i>
          {{
            data.primaryTitle ?? "CANCELLATION_PRICES.USE_PRESET.BTN_ADD_LABEL"
              | translate
          }}</button
        >&nbsp;
        <button
          color="success"
          *ngIf="data?.showSecondaryButton"
          mat-flat-button
          (click)="close('SECONDARY')"
          cdkFocusInitial
        >
          <i class="fa-solid fa-arrows-cross"></i>
          {{ "CANCELLATION_PRICES.USE_PRESET.BTN_REPLACE_LABEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
