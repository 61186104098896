import { createFeatureSelector, createSelector } from "@ngrx/store";
import { KanbanStoreModel } from "./default.model";
import { KANBAN_FEATURE } from "./default.reducer";


export const selectKanbanRootState = createFeatureSelector<KanbanStoreModel>(
  KANBAN_FEATURE
);

export const selectProjects = createSelector(
  selectKanbanRootState,
  (state) => state.projects
);

export const selectActiveProjectId = createSelector(
  selectKanbanRootState,
  (state) => state.activeProjectId
);

export const selectActiveProject = createSelector(
  selectKanbanRootState,
  selectActiveProjectId,
  (state, id) => {
    return state.projects.find(e => e.id === id)
  }
);

export const selectTasksByBoardId = createSelector(
  selectKanbanRootState,  
  (state) => {
    return state.tasks
  }
);