import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ColorPickerConfig } from "../models/color-picker.config.model";

// text,email,tel,textarea,password,
@Component({
  selector: "cb-color-picker",
  template: `
    <div [formGroup]="form">
      <mat-form-field class="mat-form-field-fluid" appearance="fill" #formTab>
        <mat-label>{{ config.placeholder }}</mat-label>
        <input
          matInput
          class="input-color-picker"
          [placeholder]="config.placeholder"
          [formControlName]="config.formControlName"
          [required]="config.required"
          [readonly]="config.readonly"
          [cpAlphaChannel]="'always'"
          [cpOutputFormat]="'hex'"
          [colorPicker]="color$ | async"
          (colorPickerChange)="changeEventHandler($event)"
          [cpUseRootViewContainer]="true"
          [cpPosition]="'bottom'"
          [attr.data-pgm-formelement]="config.testingAttribute"
          [matTooltip]="config.tooltip"
        />

        <div
          matTextPrefix
          class="input-color-picker-hint"
          [style.background]="color$ | async"
        ></div>
        <mat-error
          *ngIf="form.controls[this.config.formControlName].hasError"
          [innerHtml]="
            form.controls[this.config.formControlName].errors
              | inputErrors : field
          "
        >
        </mat-error>
      </mat-form-field>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CbColorPickerComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: ColorPickerConfig;
  color$ = new BehaviorSubject<string>("");
  get isValid() {
    return this.form.controls[this.config.formControlName].valid;
  }
  get isDirty() {
    return this.form.controls[this.config.formControlName].dirty;
  }

  constructor() {}
  ngOnInit(): void {
    this.color$.next(this.config.color);
  }

  changeEventHandler(event) {
    this.color$.next(event);
    this.form.patchValue({ color: event });
    this.form.markAsDirty();
  }
}
