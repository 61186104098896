// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
// NgBootstrap
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
// Extra module
// SVG inline
import { NgxEchartsModule } from "ngx-echarts";
// Perfect Scrollbar
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
// Core module
import { CoreModule } from "../../core/core.module";
import { AddressComponent } from "../pages/profile/profile-info/_subs/address/address.component";
import { TopbarComponent } from "../theme/header/topbar/topbar.component";
// CRUD Partials
import {
  ActionNotificationComponent,
  AlertComponent,
  BtnDropdownselectComponent,
  ConfirmActionDialogComponent,
  DeleteEntityDialogComponent,
  ExportEntityDialogComponent,
  FetchEntityDialogComponent,
  HtmlContentDialogComponent,
  InfoDialogComponent,
  UpdateStatusDialogComponent,
} from "./content";
import { DataHighChartComponent } from "./content/data-high-chart/data-high-chart.component";
// Errpr
import { ErrorComponent } from "./content/error/error.component";
import { HtmlContentComponent } from "./content/html-content/html-content.component";
// General
import { PortletModule } from "./content/portlet/portlet.module";
// Layout partials
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SharedModule } from "../../modules/shared/shared.module";
import { AddReplaceModalComponent } from "./content/add-replace-modal/add-replace-modal.component";
import { ConfirmActionButtonComponent } from "./content/confirm-action-button/confirm-action-button.component";
import { CrudLayoutSwitcherComponent } from "./content/crud-layout-switcher/crud-layout-switcher.component";
import { SaveTemplateAsFormComponent } from "./content/export-column-config/components/save-template-as-form/save-template-as-form.component";
import { SaveTemplateAsComponent } from "./content/export-column-config/components/save-template-as/save-template-as.component";
import { TemplateListItemComponent } from "./content/export-column-config/components/template-list-item/template-list-item.component";
import { FormAnchorDirective } from "./content/export-column-config/directives/form-anchor.directive";
import { ExportColumnConfigComponent } from "./content/export-column-config/export-column-config.component";
import { HelpViewerComponent } from "./content/help-viewer/help-viewer.component";
import { HtmlModalComponent } from "./content/html-modal/html-modal.component";
import { TableColumnConfigComponent } from "./content/table-column-config/table-column-config.component";
import {
  LanguageSelectorComponent,
  ScrollTopComponent,
  SearchDropdownComponent,
  SearchResultComponent,
  SplashScreenComponent,
  //StickyToolbarComponent,
  Subheader1Component,
  SubheaderSearchComponent,
  UserProfileComponent,
} from "./layout";
import { ToolboxComponent } from "./layout/subheader/toolbox/toolbox.component";
import { MaterialModule } from "../../modules/material/material.module";

@NgModule({
  declarations: [
    ScrollTopComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    ConfirmActionDialogComponent,
    HtmlContentDialogComponent,
    InfoDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    // topbar components
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    //StickyToolbarComponent,
    Subheader1Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    ErrorComponent,
    BtnDropdownselectComponent,
    ExportEntityDialogComponent,
    HtmlContentComponent,
    DataHighChartComponent,
    // topbar components
    TopbarComponent,
    AddressComponent,
    HtmlModalComponent,
    TableColumnConfigComponent,
    ConfirmActionButtonComponent,
    AddReplaceModalComponent,
    ExportColumnConfigComponent,
    SaveTemplateAsComponent,
    SaveTemplateAsFormComponent,
    FormAnchorDirective,
    TemplateListItemComponent,
    CrudLayoutSwitcherComponent,
    ToolboxComponent,
    HelpViewerComponent,
  ],
  exports: [
    FormsModule,
    PortletModule,
    ScrollTopComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    ConfirmActionDialogComponent,
    HtmlContentDialogComponent,
    InfoDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    // topbar components
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    //StickyToolbarComponent,
    Subheader1Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    ErrorComponent,
    BtnDropdownselectComponent,
    ExportEntityDialogComponent,
    HtmlContentComponent,
    DataHighChartComponent,
    // topbar components
    TopbarComponent,
    AddressComponent,
    CrudLayoutSwitcherComponent,
    HelpViewerComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    CoreModule,
    PortletModule,
    TranslateModule,
    DragDropModule,
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTooltipModule,
    NgbNavModule,
    SharedModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
  ],
})
export class PartialsModule {}
