import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AnchorComponentDirective } from "../../directives/component-anchor.directive";
import {
  DateRangeFilter,
  Filter,
  MultiSelectFilter,
  RangeFilter,
  SingleSelectFilter,
  searchFilter,
} from "../../types/types";
import { FilterSingleSelectComponent } from "./filter-single-select.component";
import { FilterDateRangeComponent } from "./filter-date-range.component";
import { FormControl } from "@angular/forms";
import { FilterRangeComponent } from "./filter-range.component";
import { FilterSearchComponent } from "./filter-search.component";
import { FilterMultiSelectComponent } from "./filter-multi-select.component";

@Component({
  selector: "cb-filters-generator",
  template: `<ng-template cbComponentAnchor></ng-template>`,
})
export class FilterGeneratorComponent implements OnInit {
  @Input() filter: Filter;
  @Input() control: FormControl;
  @ViewChild(AnchorComponentDirective, { static: true })
  cbFilterAnchor!: AnchorComponentDirective;

  ngOnInit() {
    switch (this.filter.type) {
      case "search":
        this.generateSearchFilter(this.filter);
        break;
      case "single_select":
        this.generateSingleSelect(this.filter);
        break;
      case "multi_select":
        this.generateMultiSelect(this.filter);
        break;
      case "daterange":
        this.generateDateRange(this.filter);
        break;
      case "date_range":
        this.generateRangeFilter(this.filter);
        break;
    }
  }

  private generateSearchFilter(filter: searchFilter) {
    const _viewContainerRef = this.cbFilterAnchor.viewContainerRef;
    _viewContainerRef.clear();
    const component = _viewContainerRef.createComponent(FilterSearchComponent);
    component.instance.filter = filter;
    component.instance.control = this.control;
  }

  private generateMultiSelect(filter: MultiSelectFilter) {
    const _viewContainerRef = this.cbFilterAnchor.viewContainerRef;
    _viewContainerRef.clear();
    const component = _viewContainerRef.createComponent(
      FilterMultiSelectComponent,
    );
    component.instance.filter = filter;
    component.instance.control = this.control;
  }

  private generateSingleSelect(filter: SingleSelectFilter) {
    const _viewContainerRef = this.cbFilterAnchor.viewContainerRef;
    _viewContainerRef.clear();
    const component = _viewContainerRef.createComponent(
      FilterSingleSelectComponent
    );
    component.instance.filter = filter;
    component.instance.control = this.control;
  }

  private generateDateRange(filter: DateRangeFilter) {
    const _viewContainerRef = this.cbFilterAnchor.viewContainerRef;
    _viewContainerRef.clear();
    const component = _viewContainerRef.createComponent(FilterRangeComponent);
    component.instance.filter = filter;
    component.instance.control = this.control;
  }

  private generateRangeFilter(filter: RangeFilter) {
    const _viewContainerRef = this.cbFilterAnchor.viewContainerRef;
    _viewContainerRef.clear();
    const component = _viewContainerRef.createComponent(
      FilterDateRangeComponent
    );
    component.instance.filter = filter;
    component.instance.control = this.control;
  }
}
