import { BaseModel } from '../../base';

export class VirtualGarage extends BaseModel {
    id: number = 0;
    name: string;
    description: string;
    year_construction: number;
    transponder: number;
    type_id: number;
    type: string;
    make_id: number;
    make: string;
    model_id: number;
    model: string;
    generation_id: number;
    generation: string;
    serie_id: number;
    serie: string;
    trim_id: number;   
    trim: string;
    is_testdata: number;

    constructor(init?:Partial<VirtualGarage>) {
        super();
        Object.assign(this, init);
    }

    clear() {
        this.id = 0;
        this.name = '';
        this.description = undefined;
        this.year_construction = undefined;
        this.transponder = undefined;
        this.type_id = undefined;
        this.type = undefined;
        this.make_id = undefined;
        this.make = undefined;
        this.model_id = undefined;
        this.model = undefined;
        this.generation_id = undefined;
        this.generation = undefined;
        this.serie_id = undefined;
        this.serie = undefined;
        this.trim_id = undefined;
        this.trim = undefined;
        this.is_testdata = 0;
    }
}
