import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { CommonService } from "../../../../../services/common.service";
import { AddReplaceDialogResultType } from "../../../../../shared/data/models";
import { UsersTableComponent } from "../../../../../views/pages/user-management/users/users-table/users-table.component";
import { FormConfigService } from "../../../form.config.service";
import { LookupConfig } from "../models/lookup.config.model";

@Component({
  selector: "cb-lookup",
  template: `
    <div [formGroup]="form">
      <mat-form-field appearance="fill" class="mat-form-field-fluid">
        <mat-label>{{ config.placeholder }}</mat-label>
        <input
          matInput
          [placeholder]="config.placeholder"
          [disabled]="config.disabled"
          [placeholder]="config.placeholder"
          [required]="config.required"
          [readonly]="true"
          [attr.data-pgm-formelement]="config.testingAttribute"
          [disabled]="config.disabled"
          [matTooltip]="config.tooltip"
          [value]="labelField$ | async"
          (click)="lookup()"
        />

        <span matPrefix *ngIf="!!config.tooltip">
          <i
            class="far fa-question-circle"
            matTooltipPosition="above"
            [matTooltip]="config.tooltip"
            style="margin-right: 4px;"
          ></i>
        </span>

        <button
          mat-icon-button
          matSuffix
          (click)="lookup()"
          [disabled]="config.disabled"
        >
          <mat-icon>search</mat-icon>
        </button>

        <input
          type="hidden"
          [formControlName]="config.formControlName"
          [required]="config.required"
        />
      </mat-form-field>
    </div>
  `,
  //   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CbLookupComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: LookupConfig;

  labelField$ = new BehaviorSubject("");

  constructor(
    private commonService: CommonService,
    private formConfigService: FormConfigService
  ) {}

  ngOnInit() {
    this.labelField$.next(this.config.value);
  }

  lookup() {
    if (this.config.readonly) return;

    const apiUrl = this.field.lookup.url;
    // this config is not entirely correct, but it works for now
    // crudTable component, in itself checks if its opened in a modal or not
    // I don't like this, but we cannot change it without too many collateral damages
    this.commonService
      .addReplaceDialog({
        data: {
          crudTable: {
            apiUrl: apiUrl,
            readonly: true,
            selection: true,
            allowMultiSelect: false,
            alwaysShowCheckboxes: true,
          },
          component: UsersTableComponent,
          title: "Select User",
          primaryTitle: "Select User",
        },
        // maxWidth: "80%",
        width: "100%",
      })
      .subscribe((dialogResult: AddReplaceDialogResultType) => {
        if (dialogResult) {
          if (dialogResult.action === "CANCEL") return;
          this.formConfigService.patchFormDataWithLookupData(
            dialogResult.selection[0],
            this.field
          );
          this.updateLabel();
        }
      });
    // this.commonService
    //   .openDialog(UsersTableComponent, {
    //     data: {
    //       crudTable: {
    //         apiUrl: apiUrl,
    //         readonly: true,
    //         selection: true,
    //         allowMultiSelect: false,
    //         alwaysShowCheckboxes: true,
    //       },
    //     },
    //     maxWidth: "80%",
    //     width: "80%",
    //   })
    //   .subscribe((dialogResult: any) => {
    //     if (dialogResult) {
    //       this.formConfigService.patchFormDataWithLookupData(
    //         dialogResult.selected[0],
    //         this.field
    //       );
    //       this.updateLabel();
    //     }
    //   });
  }

  updateLabel() {
    const label = this.formConfigService.parsePlaceholders(
      this.field.lookup.label_field,
      true
    );
    this.labelField$.next(label);
  }
}
