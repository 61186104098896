import { KeyValuePair } from "./base.model";

export class QueryParamsModel {
  // fields
  filter: any;
  search: any;
  sortOrder: string; // asc || desc
  sortField: string;
  pageNumber: number;
  pageSize: number;
  isTestdata: number;
  filterRange: any;
  lang: string;
  filterBadge: KeyValuePair<string>[];
  // constructor overrides
  constructor(
    _filter: any = {},
    _search: any = {},
    _sortOrder: string = "asc",
    _sortField: string = "",
    _pageNumber: number = 0,
    _pageSize: number = 10,
    _isTestdata: number = 0,
    _filterRange: any = {},
    _lang: string = null,
    _filterBadge = []
  ) {
    this.filter = _filter;
    this.search = _search;
    this.sortOrder = _sortOrder;
    this.sortField = _sortField;
    this.pageNumber = _pageNumber;
    this.pageSize = _pageSize;
    this.isTestdata = _isTestdata;
    this.filterRange = _filterRange;
    this.lang = _lang;
    this.filterBadge = _filterBadge;
  }
}
