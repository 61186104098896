import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Output,
  EventEmitter,
  inject,
} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { FormConfigService } from "../../form.config.service";
import { ProcessButton } from "../../../../shared/data/models";

@Component({
  selector: "app-process-buttons",
  templateUrl: "./process-buttons.component.html",
  styleUrls: ["./process-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessButtonsComponent implements OnInit, OnDestroy {
  @Output() onSubmit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  formConfigService = inject(FormConfigService);
  destroyed$ = new Subject<void>();
  isFormSaving$ = new BehaviorSubject(false);
  processButtons$ = new BehaviorSubject<ProcessButton[]>([]);
  title: string = "";
  formMode = this.formConfigService.formMode;
  constructor() {}

  ngOnInit(): void {
    this.processButtons$.next(this.formConfigService.processButtons);
  }

  deleteItem = () => {
    this.onDelete.emit();
  };

  //   submit = ({
  //     shouldExit = false,
  //     shouldCreateNew = false,
  //   }: {
  //     shouldExit?: boolean;
  //     shouldCreateNew?: boolean;
  //   }) => {
  //     this.onSubmit.emit({ shouldExit, shouldCreateNew });
  //   };
  refreshForm = () => {
    this.onRefresh.emit();
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
