import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { SaveAsComponent } from "../components/save-as/save-as.component";
import { sendEmailActions, sendEmailSelectors } from "./index";
import { SendEmailState } from "./send-email.model";
import { SendEmailService } from "./send-email.service";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class SendEmailEffect {
  actions$ = inject(Actions);
  store = inject(Store<SendEmailState>);
  sendEmailService = inject(SendEmailService);
  constructor(private toastr: ToastrService, private dialog: MatDialog) {}

  getParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.getParticipantsFromServer),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectSendConfig))
      ),
      switchMap(([_, config]) => {
        return this.sendEmailService
          .getParticipants(config.callbacks?.get_objects || "")
          .pipe(
            map((response) => {
              return sendEmailActions.getParticipantsFromServerSuccess({
                participants: response,
              });
            }),
            catchError((error) =>
              of(sendEmailActions.getParticipantsFromServerFailure({ error }))
            )
          );
      })
    )
  );
  saveTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.saveTemplate),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectCurrentTemplateId)),
        this.store.pipe(select(sendEmailSelectors.selectFormToBeSaved)),
        this.store.pipe(select(sendEmailSelectors.selectSendConfig))
      ),
      switchMap(([_, templateId, templateDetails, config]) => {
        const payload = {
          templateId,
          templates: this.convertTemplateDetails(templateDetails),
        };
        return this.sendEmailService
          .saveTemplate(payload, config.callbacks?.template_save)
          .pipe(
            map((response) => {
              this.toastr.success(response?.message, "Templates", {
                enableHtml: true,
              });
              return sendEmailActions.saveTemplateSuccess({
                template: JSON.parse(response.request),
              });
            }),
            catchError((error) =>
              of(sendEmailActions.saveTemplateFailure({ error }))
            )
          );
      })
    )
  );

  saveTemplateAs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.saveTemplateAs),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectFormToBeSaved)),
        this.store.pipe(select(sendEmailSelectors.selectSendConfig))
      ),
      switchMap(
        ([
          { templateName, templateTypeId, templateDescription },
          templateDetails,
          config,
        ]) => {
          const payload = {
            templateName,
            templateTypeId,
            templateDescription,
            templates: this.convertTemplateDetails(templateDetails),
          };
          return this.sendEmailService
            .saveTemplateAs(payload, config.callbacks?.template_saveas)
            .pipe(
              map((response) => {
                this.toastr.success(
                  `template saved as {newNameFromServer}`,
                  "Templates"
                );
                return sendEmailActions.saveTemplateAsSuccess({
                  template: JSON.parse(response.request),
                });
              }),
              catchError((err) => {
                return of(
                  sendEmailActions.saveTemplateAsFailure({
                    error: err?.error?.error,
                  })
                );
              })
            );
        }
      )
    )
  );
  sendTestEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.sendTestEmail),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectParticipants)),
        this.store.pipe(select(sendEmailSelectors.selectFormToBeSaved)),
        this.store.pipe(select(sendEmailSelectors.selectSendConfig)),
        this.store.pipe(select(sendEmailSelectors.selectCurrentThemeId)),
        this.store.pipe(select(sendEmailSelectors.selectCurrentTemplateId))
      ),
      switchMap(
        ([_, participants, templateDetails, config, themeId, templateId]) => {
          let ids = config.id_fields;
          let selectedIds = [];
          participants.forEach((p) => {
            let temp = {};
            ids.forEach((id) => {
              temp = Object.assign({}, temp, { [id]: p[id] });
            });
            selectedIds.push(temp);
          });

          let url = `${config?.callbacks?.send_test_email ?? ""}`;
          const payload = {
            selected: selectedIds,
            themeId,
            templates: this.convertTemplateDetails(templateDetails),
            templateId: templateId === -1 ? null : templateId,
          };

          return this.sendEmailService.sendTestEmail(payload, url).pipe(
            map((response) => {
              return sendEmailActions.sendTestEmailSuccess({ response });
            }),
            catchError((error) =>
              of(sendEmailActions.getSelectedTemplateDetailFailure({ error }))
            )
          );
        }
      )
    )
  );
  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.sendEmail),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectParticipants)),
        this.store.pipe(select(sendEmailSelectors.selectFormToBeSaved)),
        this.store.pipe(select(sendEmailSelectors.selectSendConfig)),
        this.store.pipe(select(sendEmailSelectors.selectCurrentThemeId)),
        this.store.pipe(select(sendEmailSelectors.selectCurrentTemplateId))
      ),
      switchMap(
        ([_, participants, templateDetails, config, themeId, templateId]) => {
          let ids = config.id_fields;
          let selectedIds = [];
          participants.forEach((p) => {
            let temp = {};
            ids.forEach((id) => {
              temp = Object.assign({}, temp, { [id]: p[id] });
            });
            selectedIds.push(temp);
          });

          let url = `${config?.callbacks?.send_email}`;
          const payload = {
            selected: selectedIds,
            themeId,
            templates: this.convertTemplateDetails(templateDetails),
            templateId: templateId === -1 ? null : templateId,
          };

          return this.sendEmailService.sendEmail(payload, url).pipe(
            map((response) => {
              return sendEmailActions.sendEmailSuccess({ response });
            }),
            catchError((error) =>
              of(sendEmailActions.getSelectedTemplateDetailFailure({ error }))
            )
          );
        }
      )
    )
  );
  updateSelectedTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.updateSelectedTemplate),
      map(({ id }) =>
        id
          ? sendEmailActions.getSelectedTemplateDetail({ id: id })
          : sendEmailActions.resetSelectedTemplateDetail()
      )
    )
  );
  getTemplateDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.getSelectedTemplateDetail),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectSendConfig))
      ),
      switchMap(([action, config]) =>
        this.sendEmailService
          .getTemplateDetail(action.id, config.callbacks.template_single)
          .pipe(
            map((response) =>
              sendEmailActions.getSelectedTemplateDetailSuccess({
                templateDetails: response,
              })
            ),
            catchError((error) =>
              of(sendEmailActions.getSelectedTemplateDetailFailure({ error }))
            )
          )
      )
    )
  );
  getTemplateTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.getTemplateTypes),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectSendConfig))
      ),
      switchMap(([_, config]) =>
        this.sendEmailService.getAllTemplateTypes().pipe(
          map((response) => {
            return sendEmailActions.getTemplateTypesSuccess({
              templateTypes: response,
            });
          }),
          catchError((error) =>
            of(sendEmailActions.getTemplateTypesFailure({ error }))
          )
        )
      )
    )
  );
  getTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.getTemplates),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectSendConfig))
      ),
      switchMap(([_, config]) =>
        this.sendEmailService
          .getAllTemplates(config.callbacks?.template_index)
          .pipe(
            map((response) => {
              return sendEmailActions.getTemplatesSuccess({
                templates: response,
              });
            }),
            catchError((error) =>
              of(sendEmailActions.getTemplatesFailure({ error }))
            )
          )
      )
    )
  );

  getThemes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.getThemes),
      switchMap((_) =>
        this.sendEmailService.getAllThemes().pipe(
          map((response) =>
            sendEmailActions.getThemesSuccess({ themes: response })
          ),
          catchError((error) =>
            of(sendEmailActions.getThemesFailure({ error }))
          )
        )
      )
    )
  );

  getNTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.nGetTemplates),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectTemplates))
      ),
      filter(([_, templates]) => !templates?.length),
      map(() => sendEmailActions.getTemplates())
    )
  );

  getNThemes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.nGetThemes),
      withLatestFrom(this.store.pipe(select(sendEmailSelectors.selectThemes))),
      filter(([_, themes]) => !themes?.length),
      map(() => sendEmailActions.getThemes())
    )
  );
  getNTemplateTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.nGetTemplateTypes),
      withLatestFrom(
        this.store.pipe(select(sendEmailSelectors.selectTemplateTypes))
      ),
      filter(([_, templateTypes]) => !templateTypes?.length),
      map(() => sendEmailActions.getTemplateTypes())
    )
  );

  saveTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailActions.saveTemplateAsSuccess),
      map((_) => {
        return sendEmailActions.toggleSaveAsModal({ status: false });
      })
    )
  );

  toggleSaveAsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendEmailActions.toggleSaveAsModal),
        tap(({ status }) => {
          if (status) {
            let dialogRef = this.dialog.open(SaveAsComponent, {
              id: "saveAsDialog",
              width: "400px",
            });
          } else {
            this.dialog.getDialogById("saveAsDialog").close();
          }
        })
      ),
    { dispatch: false }
  );

  private convertTemplateDetails = (templateDetails) =>
    templateDetails.map((t) => {
      const newObject = {};
      delete Object.assign(newObject, t, { ["body"]: t["template"] })[
        "template"
      ];

      return newObject;
    });
}
