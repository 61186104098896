import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DateRangeFilter, Filter, RangeFilter } from "../../types/types";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { formatDate } from "@angular/common";
import { SERVER_DATE_FORMAT } from "../../static";
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FilterService } from "../../services/filter.service";

// this is an actual date range component
// which currently has no use (no config on the server)
// code is kept in this state for now, later we will add
// this to the server, and this should be completed
@Component({
  template: `<mat-form-field class="mat-form-field-fluid" appearance="fill">
    <mat-label>{{ filter.title }}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="from" placeholder="Start date" />
      <input matEndDate formControlName="to" placeholder="End date" />
    </mat-date-range-input>
    <button
      mat-icon-button
      matSuffix
      *ngIf="range.value.from || range.value.to"
      (click)="reset()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>`,
})
export class FilterDateRangeComponent implements OnInit, OnDestroy {
  @Input() filter: RangeFilter;
  @Input() control: FormControl;
  destroyed$ = new Subject<void>();

  range: FormGroup;
  constructor(private filterService: FilterService, private fb: FormBuilder) {}

  ngOnInit() {
    this.subscribeToReset();
    this.range = this.fb.group({
      from: new FormControl<Date | null>(null),
      to: new FormControl<Date | null>(null),
    });
    this.range.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val: any) => {
        // this should use the formatter in the service

        const label = this.filterService.formatDateRangeForServer(
          val.from,
          val.to
        );
        this.filterService.setAppliedFilters({
          component: this.filter,
          value: val,
          label: label,
        });
        this.control.setValue(label);
      });
  }

  subscribeToReset() {
    this.filterService.resetFiltersSignal$
      .pipe(takeUntil(this.filterService.destroyed$))
      .subscribe(() => {
        this.range.reset({ from: null, to: null }, { emitEvent: false });
      });
  }

  reset() {
    this.range.reset({ from: null, to: null });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
