import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { ProcessButton } from "../../../shared/data/models";

@Injectable()
export class ContextMenuService {
  buttonClickEvent$ = new BehaviorSubject<ProcessButton>(null);
  constructor(private http: HttpClient) {}

  processCallback(button: ProcessButton, value?: any) {
    if (button.callback.method === "DELETE") {      
      return this.http.delete(`${button.callback.url}/${button.itemId}`);
    }

    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    let httpOptions = {};
    let params = new HttpParams();
    let idField = button.callback.idField ?? "id";
    let selected = this.prepareSelectedData(button.selected, idField);

    if (button.callback.method === "POST") {
      if (value) {
        params = params.append(button.confirm.paramType, value);
        httpOptions = {
          body: JSON.stringify({
            [button.confirm.paramName]: value,
            selected,
          }),
        };
      } else {
        params = params.append("selected", selected);
        httpOptions = {
          params,
          body: JSON.stringify({
            selected,
          }),
        };
      }
    } else {
      params = params.append("selected", button.itemId);
      params = params.append(
        "is_testdata",
        localStorage.getItem("is_testdata")
      );
      httpOptions = {
        params,
      };
    }

    return this.http.request(
      button.callback.method,
      button.callback.url,
      httpOptions
    );
  }

  prepareSelectedData(selected: any[], idField: string) {
    return selected.map((item) => item[idField]).join(",");
  }
}
