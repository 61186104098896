export class Address {
    street: string;
    number: number;
    city: string;
    state: string;
    zip: string;
    phone: string;
    fax: string;
    mobile: string;
    country_id: string;
    web: string;
    lang: string;

    clear() {
        this.street = '';
        this.number = undefined;
        this.city = '';
        this.state = '';
        this.zip = '';
        this.phone = '';
        this.fax = '';
        this.mobile = '';
        this.country_id = '';
        this.web = '';
        this.lang = '';
    }
}
