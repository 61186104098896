<div class="chart-wrapper">
  <ng-container *ngIf="!!filters">
    <app-filter-shell
      [filters]="filters"
      [searchableFields]="searchableFields"
      (filteredQueryString)="onFilterChange($event)"
      (onFilterChangeEvent)="handleFilterChange($event)"
      (onFiltersReady)="handleFiltersReady($event)"
    ></app-filter-shell>
  </ng-container>
  <div class="chart-container">
    <div
      echarts
      [merge]="updateOptions$ | async"
      [options]="chartOptions"
      [style.height]="chartContainerHeight$ | async"
      class="chart"
      [loading]="(isLoading$ | async) === true"
      (chartInit)="onChartInit($event)"
    ></div>
    <button
      [loading]="(isLoading$ | async) === true"
      mat-raised-button
      class="btn btn-outline-primary reset-button-styles info-outline-button refresh-button"
      (click)="refresh()"
    >
      <i class="fa-solid fa-arrows-rotate"></i>
      <span> {{ "COMMON.REFRESH" | translate }} </span>
    </button>
  </div>
</div>
