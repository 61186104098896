import { Action, createReducer, on } from "@ngrx/store";
import { AppState } from "./app.reducers";
import { reloadControllerActions } from "../actions/reload-controller.actions";
import { helpSidebarActions } from "../actions/help-sidebar.actions";

export interface HelpSidebarState {
  sidebarStatus: boolean;
  url: string;
  lastUpdated: Date;
}

export const HelpSidebarInitialState: HelpSidebarState = {
  sidebarStatus: false,
  url: null,
  lastUpdated: null,
};

const reducer = createReducer(
  HelpSidebarInitialState,
  on(helpSidebarActions.closeSidebar, (state) => ({
    ...state,
    sidebarStatus: false,
  })),
  on(helpSidebarActions.openSidebar, (state) => ({
    ...state,
    sidebarStatus: true,
  })),
  on(helpSidebarActions.setTargetUrl, (state, { targetUrl }) => ({
    ...state,
    url: targetUrl,
  }))
);

export function helpSidebarReducer(
  state: HelpSidebarState | undefined,
  action: Action
) {
  return reducer(state, action);
}

export interface State extends AppState {
  helpSidebarReducer: HelpSidebarState;
}
