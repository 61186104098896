<kt-portlet-header
  [title]="title$ | async"
  [class]="'kt-portlet__head--lg text-nowrap'"
  [ngClass]="{ minimized: (isSubFormShown$ | async) }"
  (click)="handleClickOnFormTitle($event)"
  [icon]="
    (isSubFormShown$ | async) === true
      ? 'fa-sharp fa-light fa-caret-right'
      : null
  "
>
  <ng-container ktPortletAlerts *ngIf="form && (isFormDirty$ | async)">
    <div class="flex-grow-1 m-3 d-flex align-items-center">
      <div class="align-items-center d-flex bg-warning p-1 px-3 rounded">
        <i class="la la-warning"></i>
        <span>{{ "TRANSLATOR.WARNING_UNSAVED_CHANGES" | translate }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container ktPortletTools>
    <div
      class="action-button-container"
      [ngClass]="{ invisible: (isSubFormShown$ | async) }"
    >
      <ng-container *ngIf="customActionButtons">
        <ng-container
          *ngTemplateOutlet="customActionButtons; context: { $implicit: data }"
        >
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!readonly; else readonlyActions">
        <button
          type="button"
          *ngIf="infoText$ | async"
          class="cb-icon-button"
          mat-icon-button
          (click)="handleInfoToggle()"
        >
          <i class="far fa-question-circle text-info info-help-icon"></i>
        </button>
        <app-process-buttons
          (onDelete)="deleteItem()"
          (onSubmit)="submit($event)"
          (onRefresh)="refreshForm()"
        ></app-process-buttons>
        <a
          (click)="backButtonClicked()"
          color="warn"
          mat-flat-button
          [attr.data-pgm-formbutton]="'formbutton_back'"
          data-breadcrumbClose="true"
        >
          <i class="fa-solid fa-circle-x"></i>
          <span class="kt-hidden-mobile">
            {{ "COMMON.CLOSE" | translate }}
          </span>
        </a>

        <ng-container *ngFor="let button of saveButtons$ | async">
          <a
            [class]="button.classList"
            (click)="
              submit({
                shouldExit: button.shouldExit,
                shouldCreateNew: button.shouldCreateNew
              })
            "
            mat-flat-button
            color="success"
            [ngClass]="{ disabled: (isFormSaving$ | async) === true }"
            [attr.data-pgm-formbutton]="button.testAttribute"
          >
            <span *ngIf="isFormSaving$ | async; else defaultText">
              <i class="fa fa-circle-o-notch fa-spin p-0"></i> Saving...
            </span>
            <ng-template #defaultText>
              <i [class]="button.icon"></i>
              <span class="kt-hidden-mobile">
                {{ button.label | translate }}
              </span>
            </ng-template>
          </a>
        </ng-container>
      </ng-container>

      <ng-template #readonlyActions>
        <!-- <ng-container *ngIf="!dialogRef">
          <button
            type="button"
            class="btn btn-outline-primary reset-button-styles danger-outline-button"
            mat-raised-button
            (click)="dialogRef.close(null)"
            *ngIf="data.cancelBtn !== false"
          >
            <i class="la la-arrow-left"></i>
            {{ "COMMON.BACK" | translate }}</button
          >&nbsp;
        </ng-container>

        <ng-container *ngIf="dialogRef">
          <button
            type="button"
            class="btn btn-outline-primary reset-button-styles danger-outline-button"
            mat-raised-button
            (click)="dialogRef.close()"
          >
            <i class="la la-arrow-left"></i>
            {{ "COMMON.CANCEL" | translate }}</button
          >&nbsp;

          <button
            type="button"
            class="btn btn-outline-primary reset-button-styles success-outline-button"
            mat-raised-button
            (click)="dialogRef.close(data)"
          >
            <i class="fas fa-check"></i>
            {{ "COMMON.OK" | translate }}
          </button>
        </ng-container> -->
      </ng-template>
    </div>
  </ng-container>
</kt-portlet-header>
