import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "parseLink",
})
export class ParseLinkPipe implements PipeTransform {
  transform(value: any, text: string): any {
    if (!value || !text) {
      return "";
    }

    const { url, type, tooltip } = value;
    return `
            <a class="${
              type !== "external" ? "cb-router-link" : "table-link"
            }" href="${url}" title="${tooltip}" target="${
      type === "external" ? "_blank" : "_self"
    }">
            ${
              type === "external"
                ? '<i style="font-family: auto !important;" class="fa-solid fa-arrow-up-right-from-square"></i>'
                : ""
            }
                ${text}
            </a>
        `;
  }
}
