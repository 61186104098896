export const availableButtons = [
  {
    name: "save",
    // classList:
    //   "btn btn-outline-primary reset-button-styles success-outline-button",
    shouldExit: false,
    shouldCreateNew: false,
    testAttribute: "formbutton_save",
    icon: "fa-solid fa-floppy-disk",
    label: "COMMON.SAVE",
    mode: "ALL",
  },
  {
    name: "save_new",
    // classList:
    //   "btn btn-outline-primary reset-button-styles success-outline-button",
    shouldExit: false,
    shouldCreateNew: true,
    testAttribute: "formbutton_save_new",
    icon: "fa-solid fa-floppy-disk-circle-arrow-right",
    label: "COMMON.SAVE_AND_NEW",
    mode: "CREATE",
  },
  {
    name: "save_exit",
    // classList:
    //   "btn btn-outline-primary reset-button-styles success-outline-button",
    shouldExit: true,
    shouldCreateNew: false,
    testAttribute: "formbutton_save_exit",
    icon: "fa-solid fa-circle-x",
    label: "COMMON.SAVE_AND_EXIT",
    mode: "ALL",
  },
];
