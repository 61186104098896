// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../core/store/reducers/app.reducers';
import { ApiConfig } from "../../../../core/config/api.config";
import { SubheaderService } from '../../../../core/base';
import { CrudTableComponent } from '../crud-table/crud-table.component';
// Auth

export interface GenericInquiryParams {
	name: string;
	title: string;
	columns: any[];
	searchableColumns: string[];
	apiUrl: string;
}

@Component({
	selector: 'generic-inquiry',
	templateUrl: './generic-inquiry.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericInquiryComponent implements OnInit, OnChanges {

	@Input() name: string;
	@Input() title: string = "Generic Inquiry";
	@Input() columns = [];
	@Input() searchableColumns = [];
	@Input() apiUrl: string;

	@Input() displayedColumns: string[] = [];
	@Output() columnsLoaded: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('crudTable', { static: true }) crudtable: CrudTableComponent;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param router: Router
	 */
	constructor(
		private store: Store<AppState>,
		private router: Router) {
	}

	 
	ngOnInit() {
		if (!this.name) this.name = this.makeid(10);
	}

	ngOnChanges({ displayedColumns }: SimpleChanges) {
		// if (displayedColumns && displayedColumns.currentValue)
		// 	this.displayedColumns = displayedColumns.currentValue || [];
	}

	updateDisplayColumns(columns: string[]) {
		if (this.crudtable)
			this.crudtable.updateDisplayColumns(columns);
	}

	// TODO: move this somewhere to a generic utility/helper class
	makeid(length: number) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
}
