import { createAction, props } from "@ngrx/store";

export const updateConnectionStatus = createAction(
  "[Reload Controller] Update Connection Status",
  props<{ connectionStatus: boolean }>()
);

export const updateTargetComponent = createAction(
  "[Reload Controller] Update Target Component",
  props<{ targetComponent: string }>()
);

export const resetTargetComponent = createAction(
  "[Reload Controller] Reset Target Component"
);

export const reloadControllerActions = {
  updateConnectionStatus,
  updateTargetComponent,
  resetTargetComponent,
};
