// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import {ApiConfig} from "../../../../../core/config/api.config";
import {Validators} from "@angular/forms";
import {SubheaderService} from '../../../../../core/base';


const userManagementPermissionId = 2;
@Component({
	selector: 'trackday-types-table',
	templateUrl: './trackday-types-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackdayTypesTableComponent implements OnInit {
  columns = [];
  searchableColumns = ['name', 'color'];
  apiUrl: string;
  createButtonLabel = 'Create Trackday Type';

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param router: Router
	 */
	constructor(private router: Router, private subheaderService: SubheaderService) {
	}

	 
	ngOnInit() {
    	this.apiUrl = ApiConfig.TrackdayItemTypesURL();

		// Set title to page breadCrumbs
		this.subheaderService.setTitle('Event Type');
	}
}
