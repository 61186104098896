<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-progress">
			<!-- here can place a progress bar-->
			<mat-progress-bar mode="indeterminate" *ngIf="downloading$ | async"></mat-progress-bar>
		</div>
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
		</div>

		<div class="kt-portlet__head-toolbar">
			<button mat-icon-button matTooltip="Close" type="button"
				[mat-dialog-close]>
				<i class="la la-close"></i>
			</button>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">

			<div class="mt-4 mb-2 d-flex justify-content-left">
				<h5>{{ 'COMMON.VISIBLE_COLUMNS' | translate }}</h5>
				<mat-checkbox [(ngModel)]="selectionAll" class="ml-3" [(indeterminate)]="selectionIndeter"
					(change)="toggleAll()">
					{{ 'COMMON.SELECT_ALL' | translate }}
				</mat-checkbox>
			</div>

			<div class="row">
				<div class="col-md-6" *ngFor="let column of data.columns;">
					<mat-checkbox [matTooltip]="column.text" [checked]="isSelected(column)"
						(change)="toggleSelected(column)">
						{{column.text}}
					</mat-checkbox>
				</div>
			</div>

			<div class="form-group row border-top pt-4 mt-4">
				<div class="col-lg-12">
					<mat-form-field class="mat-form-field-fluid custom-input mt-4">
						<mat-label>{{ 'COMMON.EXPORT_FILE_TYPE' | translate }}</mat-label>
						<mat-select [(ngModel)]="exportType">
							<mat-option *ngFor="let type of exportTypeOptions | keyvalue" [value]="type.key">
								{{type.value}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<div class="form-group row" *ngIf="exportType == 'pdf'">
				<div class="col-lg-12">
					<mat-form-field class="mat-form-field-fluid custom-input mt-4">
						<mat-label>{{ 'COMMON.EXPORT_PAPER_ORIENTATION' | translate }}</mat-label>
						<mat-select [(ngModel)]="exportPaperOrientation">
							<mat-option *ngFor="let value of ['Portrait', 'Landscape']" [value]="value">
								{{value}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-12">
					<mat-checkbox [(ngModel)]="exportOnlyFiltered">
						{{'COMMON.EXPORT_FILTERED' | translate }}
					</mat-checkbox>
				</div>
			</div>
		</div>

		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
			<div class="kt-form__actions kt-form__actions--sm">
				<div class="row">
					<div class="col-lg-12" align="center">
						<button mat-button (click)="download()" color="primary" [disabled]="downloading$ | async">
							Download
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>