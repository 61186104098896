import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { UrlConfig } from "../models/url.config.model";

// text,email,tel,textarea,password,
@Component({
  selector: "cb-url",
  template: `
    <div [formGroup]="form">
      <mat-form-field
        appearance="fill"
        floatLabel="always"
        class="mat-form-field-fluid"
      >
        <mat-label>{{ config?.placeholder }}</mat-label>
        <input
          matInput
          [placeholder]="config?.placeholder"
          [formControlName]="config?.formControlName"
          [required]="config?.required"
          [readonly]="config?.readonly"
          [attr.data-pgm-formelement]="config?.testingAttribute"
          [matTooltip]="config?.tooltip"
        />
        <span matPrefix *ngIf="!!config.tooltip">
          <i
            class="far fa-question-circle"
            matTooltipPosition="above"
            [matTooltip]="config.tooltip"
            style="margin-right: 4px;"
          ></i>
        </span>
        <mat-icon
          matSuffix
          fontSet="fa"
          (click)="goToLink(field)"
          style="font-size: 16px; cursor: pointer"
          fontIcon="fa-external-link-alt"
        ></mat-icon>
      </mat-form-field>
    </div>
  `,
})
export class CbUrlComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: UrlConfig;
  get isValid() {
    return this.form.controls[this.field.name].valid;
  }
  get isDirty() {
    return this.form.controls[this.field.name].dirty;
  }

  goToLink(field) {
    // window.open(this.getControl(field.name).value, "_blank");
  }

  constructor() {}
  ngOnInit(): void {}
}
