import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel, HttpUtilsService } from '../core/base';
import { environment } from '../../environments/environment';
import { ApiConfig } from '../core/config/api.config';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class MotoDbService {
    constructor(private http: HttpClient, private httpUtilsService: HttpUtilsService) { }

    getTypes(queryParams: QueryParamsModel, usePagination: boolean = true): Observable<QueryResultsModel> {
        let params = this.httpUtilsService.convertQueryParams(queryParams);
        params = params.set('usePagination', usePagination ? '1' : '0');
        return this.http.get<QueryResultsModel>(ApiConfig.MotoDBTypesURL(), { params: params });
    }

    getMakes(queryParams: QueryParamsModel, usePagination: boolean = true): Observable<QueryResultsModel> {
        let params = this.httpUtilsService.convertQueryParams(queryParams);
        params = params.set('usePagination', usePagination ? '1' : '0');
        return this.http.get<QueryResultsModel>(ApiConfig.MotoDBMakesURL(), { params: params });
    }

    getModels(queryParams: QueryParamsModel, usePagination: boolean = true): Observable<QueryResultsModel> {
        let params = this.httpUtilsService.convertQueryParams(queryParams);
        params = params.set('usePagination', usePagination ? '1' : '0');
        return this.http.get<QueryResultsModel>(ApiConfig.MotoDBModelsURL(), { params: params });
    }

    getGenerations(queryParams: QueryParamsModel, usePagination: boolean = true): Observable<QueryResultsModel> {
        let params = this.httpUtilsService.convertQueryParams(queryParams);
        params = params.set('usePagination', usePagination ? '1' : '0');
        return this.http.get<QueryResultsModel>(ApiConfig.MotoDBGenerationsURL(), { params: params });
    }

    getSeries(queryParams: QueryParamsModel, usePagination: boolean = true): Observable<QueryResultsModel> {
        let params = this.httpUtilsService.convertQueryParams(queryParams);
        params = params.set('usePagination', usePagination ? '1' : '0');
        return this.http.get<QueryResultsModel>(ApiConfig.MotoDBSeriesURL(), { params: params });
    }

    getTrims(queryParams: QueryParamsModel, usePagination: boolean = true): Observable<QueryResultsModel> {
        let params = this.httpUtilsService.convertQueryParams(queryParams);
        params = params.set('usePagination', usePagination ? '1' : '0');
        return this.http.get<QueryResultsModel>(ApiConfig.MotoDBTrimsURL(), { params: params });
    }

}
