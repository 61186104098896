// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { ServiceFormComponent } from "../app/views/pages/services-management/service/service-form/service-form.component";
import { BaseComponent } from "./views/theme/base/base.component";
import { ErrorPageComponent } from "./views/theme/error-page/error-page.component";
// Auth
import { AuthGuard } from "./core/store";
import { TrackdayDirectbookingSnippetFormComponent } from "./views/pages/snippet/trackday-directbooking-snippet/trackday-directbooking-snippet-form/trackday-directbooking-snippet-form.component";
import { TrackdayDirectbookingSnippetTableComponent } from "./views/pages/snippet/trackday-directbooking-snippet/trackday-directbooking-snippet-table/trackday-directbooking-snippet-table.component";
import { ContactUsComponent } from "./views/pages/static/contact-us/contact-us.component";
import { ExternalLicensesComponent } from "./views/pages/static/external-licenses/external-licenses.component";
import { ReleaseNotesComponent } from "./views/pages/static/release-notes/release-notes.component";
import { DraftBookingsFormComponent } from "./views/pages/trackdays-management/draft-bookings/draft-bookings-form/draft-bookings-form.component";
import { TrackdayBookingsFormComponent } from "./views/pages/trackdays-management/trackday-bookings/trackday-bookings-form/trackday-bookings-form.component";
import { TrackdayGroupBookingsFormComponent } from "./views/pages/trackdays-management/trackday-group-bookings/trackday-group-bookings-form/trackday-group-bookings-form.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then((m) => m.AuthModule),
  },

  {
    path: "check-in",
    outlet: "modal",
    loadChildren: () =>
      import("./views/pages/check-in/check-in.module").then(
        (m) => m.CheckInModule
      ),
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "playground",
        loadChildren: () =>
          import("./views/pages/playground/playground.module").then(
            (m) => m.PlaygroundModule
          ),
      },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },

      {
        path: "project-management",
        loadChildren: () =>
          import(
            "./views/pages/project-management/project-management.module"
          ).then((m) => m.ProjectManagementModule),
      },
      {
        path: "season-management",
        loadChildren: () =>
          import(
            "./views/pages/season-management/season-management.module"
          ).then((m) => m.SeasonManagementModule),
      },
      {
        path: "project-template-management",
        loadChildren: () =>
          import(
            "./views/pages/project-template-management/project-template-management.module"
          ).then((m) => m.ProjectTemplateManagementModule),
      },

      {
        path: "user-management",
        loadChildren: () =>
          import("./views/pages/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./views/pages/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./views/pages/calendar/calendar.module").then(
            (m) => m.CalendarModule
          ),
      },
      {
        path: "roles-management",
        loadChildren: () =>
          import("./views/pages/roles-management/roles-management.module").then(
            (m) => m.RolesManagementModule
          ),
      },
      {
        path: "trackdays-management",
        loadChildren: () =>
          import(
            "./views/pages/trackdays-management/trackdays-management.module"
          ).then((m) => m.TrackdaysManagementModule),
      },
      {
        path: "vehicle-management",
        loadChildren: () =>
          import(
            "./views/pages/vehicle-management/vehicle-management.module"
          ).then((m) => m.VehicleManagementModule),
      },
      {
        path: "system-management",
        loadChildren: () =>
          import(
            "./views/pages/system-management/system-management.module"
          ).then((m) => m.SystemManagementModule),
      },
      {
        path: "companies-management",
        loadChildren: () =>
          import(
            "./views/pages/company-management/companies-management.module"
          ).then((m) => m.CompaniesManagementModule),
      },
      {
        path: "service-management",
        loadChildren: () =>
          import(
            "./views/pages/services-management/services-management.module"
          ).then((m) => m.ServicesManagementModule),
      },
      {
        path: "user.management",
        loadChildren: () =>
          import(
            "./views/pages/virtualgarage-management/virtualgarage-management.module"
          ).then((m) => m.VirtualGarageManagementModule),
      },
      {
        path: "statistics",
        loadChildren: () =>
          import("./views/pages/statistics/statistics.module").then(
            (m) => m.StatisticsModule
          ),
      },
      {
        path: "motodb",
        loadChildren: () =>
          import(
            "./views/pages/circuit-booking/motodb/motodb-management.module"
          ).then((m) => m.MotodbManagementModule),
      },
      {
        path: "circuit-booking",
        loadChildren: () =>
          import(
            "./views/pages/circuit-booking/circuit-booking-management.module"
          ).then((m) => m.CircuitBookingManagementModule),
      },
      {
        path: "permission",
        loadChildren: () =>
          import(
            "./views/pages/permission-keys-management/permission-management.module"
          ).then((m) => m.PermissionManagementModule),
      },
      {
        path: "admin-management",
        loadChildren: () =>
          import("./views/pages/admin-management/admin-management.module").then(
            (m) => m.AdminManagementModule
          ),
      },
      {
        path: "newsletter-management",
        loadChildren: () =>
          import("./views/pages/newsletter-management/newsletter.module").then(
            (m) => m.NewsletterModule
          ),
      },
      {
        path: "shopping",
        loadChildren: () =>
          import("./views/pages/cart/cart.module").then((m) => m.CartModule),
      },
      {
        path: "service-management/additional-services/create",
        component: ServiceFormComponent,
      },
      {
        path: "service-management/additional-services/edit/:id",
        component: ServiceFormComponent,
      },
      {
        path: "service-management/additional-services/edit/:id/:tab",
        component: ServiceFormComponent,
      },
      {
        path: "trackdays-management/bookings/edit/:id",
        component: TrackdayBookingsFormComponent,
      },
      {
        path: "trackdays-management/bookings/edit/:id/:tab",
        component: TrackdayBookingsFormComponent,
      },
      {
        path: "trackdays-management/bookings-short/edit/:id",
        component: TrackdayBookingsFormComponent,
      },
      {
        path: "trackdays-management/bookings-short/edit/:id/:tab",
        component: TrackdayBookingsFormComponent,
      },
      {
        path: "trackdays-management/draft-bookings/edit/:id",
        component: DraftBookingsFormComponent,
      },
      {
        path: "trackdays-management/draft-bookings/edit/:id/:tab",
        component: DraftBookingsFormComponent,
      },
      {
        path: "trackdays-management/group-bookings/edit/:id",
        component: TrackdayGroupBookingsFormComponent,
      },
      {
        path: "trackdays-management/group-bookings/edit/:id/:tab",
        component: TrackdayGroupBookingsFormComponent,
      },
      {
        path: "preset-cancellation-price-management",
        loadChildren: () =>
          import(
            "./views/pages/preset-cancellation-price-management/preset-cancellation-prices-management.module"
          ).then((m) => m.PresetCancellationPricesManagementModule),
      },
      {
        path: "preset-dynamic-price-management",
        loadChildren: () =>
          import(
            "./views/pages/preset-dynamic-price-management/preset-dynamic-prices-management.module"
          ).then((m) => m.PresetDynamicPricesManagementModule),
      },
      {
        path: "pages/contact-us",
        component: ContactUsComponent,
      },
      {
        path: "pages/release-notes",
        component: ReleaseNotesComponent,
      },
      {
        path: "pages/external-licenses",
        component: ExternalLicensesComponent,
      },

      {
        path: "snippet/directbooking-trackday",
        component: TrackdayDirectbookingSnippetTableComponent,
      },
      {
        path: "snippet/directbooking-trackday/edit/:id",
        component: TrackdayDirectbookingSnippetFormComponent,
      },
      {
        path: "snippet/directbooking-trackday/edit/:id/:tab",
        component: TrackdayDirectbookingSnippetFormComponent,
      },
      {
        path: "snippet/directbooking-trackday/create",
        component: TrackdayDirectbookingSnippetFormComponent,
      },

      //	{
      //		path: 'pages/permission-info',
      //		component: PermissionInfoComponent,
      //	},
      {
        path: "error/403",
        component: ErrorPageComponent,
        data: {
          type: "error-v6",
          code: 403,
          title: "403... Access forbidden",
          desc: "Looks like you don't have permission to access the requested page.<br> Please, contact administrator",
        },
      },
      { path: "error/:type", component: ErrorPageComponent },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "**", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },

  {
    path: "playground",
    loadChildren: () =>
      import("./views/pages/playground/playground.module").then(
        (m) => m.PlaygroundModule
      ),
  },

  { path: "**", redirectTo: "error/403", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
