<mat-form-field class="mat-form-field-fluid" appearance="fill">
  <mat-label>{{ title }} </mat-label>
  <mat-select
    [formControl]="control"
    [multiple]="allowMultiple"
    (selectionChange)="handleSelectionChange($event)"
    [disabled]="(isLoading$ | async) === true"
  >
    <ng-container *ngIf="allOptions$ | async as options">
      <mat-option *ngFor="let item of options" [value]="item.value">
        {{ item.label }}
      </mat-option>
    </ng-container>
  </mat-select>

  <button
    *ngIf="control.value && !control.disabled"
    mat-icon-button
    matSuffix
    [attr.aria-label]="'Clear' | translate"
    (click)="clear($event)"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
