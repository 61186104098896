<!-- <div class="img-overlay" *ngIf="fullImageUrl" style="display: none;">
    <img [src]="fullImageUrl" alt="..." />
</div> -->
<div class="card bg-dark text-white w-100" style>
  <div
    style="
      position: absolute;
      padding: 5px 15px;
      background: #000;
      opacity: 0.7;
      font-size: smaller;
    "
    *ngIf="id && !showEdit"
  >
    {{ type }} ({{ language }})
  </div>

  <ng-container *ngIf="groupType == 'images'; else documents">
    <div class="img-wrapper d-flex align-items-center">
      <img
        [src]="sanitizer.bypassSecurityTrustUrl('' + imageSrc)"
        *ngIf="imageSrc"
        class="card-img"
        alt="..."
      />
    </div>
  </ng-container>
  <ng-template #documents>
    <div class="img-wrapper d-flex align-items-center">
      <ng-container *ngIf="imageSrc; else pdfLogo">
        <img
          *ngIf="(shouldReplaceImageWithIcon$ | async) === false; else pdfLogo"
          [src]="sanitizer.bypassSecurityTrustUrl('' + imageSrc)"
          class="card-img"
          alt="..."
          (error)="replaceImageWithPdfLogo($event)"
        />
      </ng-container>
    </div>
    <ng-template #pdfLogo>
      <i class="fa fa-file-pdf mx-auto" style="font-size: 120px"></i>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="!readonly; else readonlyActions">
    <ng-container *ngIf="id; else showUpload">
      <ngx-dropzone-remove-badge *ngIf="removable" (click)="delete($event)">
      </ngx-dropzone-remove-badge>
      <a
        class="ngx-dropzone-action-badge"
        data-href=""
        *ngIf="!showEdit"
        (click)="edit($event)"
      >
        <i class="fas fa-pencil"></i>
      </a>
      <ng-container *ngIf="groupType == 'documents'">
        <a
          [href]="fullImageUrl"
          class="ngx-dropzone-action-badge"
          *ngIf="!showEdit"
          target="_blank"
          style="top: 56px"
        >
          <i class="fas fa-download"></i>
        </a>
      </ng-container>

      <ng-container *ngIf="!showEdit; else showEditPanel">
        <div class="card-body">
          <p class="card-text" *ngIf="description">{{ description }}</p>
          <p class="card-text" *ngIf="!description"><i>No description</i></p>
        </div>
      </ng-container>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="deleteInProgress"
      ></mat-progress-bar>

      <ng-template #showEditPanel>
        <div class="card-body">
          <div class="form-group">
            <label>Select Language</label>
            <select class="form-control" [(ngModel)]="language">
              <option
                *ngFor="let language of availableLanguages"
                [value]="language.lang"
              >
                {{ language.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label
              >Select
              {{ groupType == "images" ? "Image" : "Document" }} Type</label
            >
            <select class="form-control" [(ngModel)]="type">
              <option *ngFor="let imgType of types" [value]="imgType">
                {{ imgType }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>Enter Description</label>
            <input
              [(ngModel)]="description"
              [disabled]="uploadInProgress"
              type="text"
              class="form-control"
              placeholder="Enter description"
            />
          </div>
        </div>
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="saveInProgress; else showEditActions"
        >
        </mat-progress-bar>
        <ng-template #showEditActions>
          <ng-container *ngIf="errorMessage">
            <ng-template #showErrorMessage>{{ errorMessage }}</ng-template>
            <a
              data-href
              class="btn btn-danger"
              (click)="retrySave($event)"
              [ngbTooltip]="showErrorMessage"
            >
              <i class="fas fa-exclamation-triangle"></i> Save failed. Try
              Again.</a
            >
          </ng-container>
          <div class="card-footer text-right">
            <button
              mat-raised-button
              color="warn"
              [ngClass]="{ disabled: errorMessage }"
              (click)="cancelEdit($event)"
            >
              Cancel
            </button>
            <button
              mat-raised-button
              color="primary"
              class="ml-2"
              [ngClass]="{ disabled: errorMessage }"
              (click)="save($event)"
            >
              Save
            </button>

            <!-- <a data-href class="btn btn-primary ml-2" [ngClass]="{ 'disabled': errorMessage }"
                          (click)="save($event)">Save</a> -->
          </div>
        </ng-template>
      </ng-template>
    </ng-container>

    <ng-template #showUpload>
      <div class="card-body">
        <div class="form-group">
          <label>Select Language</label>
          <select class="form-control" [(ngModel)]="language">
            <option
              *ngFor="let language of availableLanguages"
              [value]="language.lang"
            >
              {{ language.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label
            >Select
            {{ groupType == "images" ? "Image" : "Document" }} Type</label
          >
          <select class="form-control" [(ngModel)]="type">
            <option *ngFor="let type of types" [value]="type">
              {{ type | titlecase }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label>Enter Description</label>
          <input
            [(ngModel)]="description"
            [disabled]="uploadInProgress"
            type="text"
            class="form-control"
            placeholder="Enter description"
          />
        </div>
      </div>

      <ng-container *ngIf="modelId; else cancelOnlyFooter">
        <ng-container *ngIf="uploadInProgress; else showUploadFooter">
          <mat-progress-bar
            mode="determinate"
            [value]="uploadProgress"
          ></mat-progress-bar>
          <div class="card-footer text-right">
            <ng-template #showErrorMessage>{{ errorMessage }}</ng-template>
            <ng-container *ngIf="errorMessage; else showCancelUpload">
              <a
                data-href
                class="btn btn-danger"
                (click)="retryUpload($event)"
                [ngbTooltip]="showErrorMessage"
              >
                <i class="fas fa-exclamation-triangle"></i> Upload failed. Try
                Again.</a
              >
            </ng-container>
            <ng-template #showCancelUpload>
              <a data-href class="btn btn-danger" (click)="cancelUpload($event)"
                >Cancel</a
              >
            </ng-template>
          </div>
        </ng-container>
        <ng-template #showUploadFooter>
          <div class="card-footer text-right">
            <button mat-raised-button color="warn" (click)="delete($event)">
              Cancel
            </button>
            <button
              mat-raised-button
              color="primary"
              class="ml-2"
              (click)="upload($event)"
            >
              Upload
            </button>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #cancelOnlyFooter>
        <div class="card-footer text-right">
          <button mat-raised-button color="warn" (click)="delete($event)">
            Cancel
          </button>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-template #readonlyActions>
    <ng-container *ngIf="groupType == 'documents'">
      <a
        [href]="fullImageUrl"
        class="ngx-dropzone-action-badge"
        *ngIf="!showEdit"
        target="_blank"
        style="top: 8px"
      >
        <i class="fas fa-download"></i>
      </a>
    </ng-container>
  </ng-template>
</div>
