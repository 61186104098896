import {LayoutConfigModel} from '../base';

export class LayoutConfig {
  public defaults: LayoutConfigModel = {
    demo: 'pgm',
    // == Base Layout
    self: {
      layout: 'fluid', // fluid|boxed
      body: {
        'background-image': './assets/media/misc/bg-1.jpg',
      },
      logo: {
        dark: './assets/media/logos/cb-logo-scaled-sm.png',
        light: './assets/media/logos/cb-logo-scaled-sm.png',
        brand: './assets/media/logos/cb-logo-scaled-sm.png',
        green: './assets/media/logos/cb-logo-scaled-sm.png',
      },
    },
    // == Page Splash Screen loading
    loader: {
      enabled: true,
      type: 'spinner-logo',
      logo: './assets/media/pgmware/cb-square.jpg',
      message: 'Please wait...',
    },
    // == Colors for javascript
    colors: {
      state: {
        brand: '#e3001b', // overwritten in _varianble.scss
        dark: '#2b2d42',
        light: '#fafafb',
        primary: '#8d9cb6',
        success: '#4cd964',
        info: '#e0e6f0',
        warning: '#ffcd00',
        danger: '#e3001b',
      },
      base: {
        label: [
          '#ffffff',
          '#f4f7fc',
          '#69718C',
          '#69718C',
        ],
        shape: [
          '#ffffff',
          '#f4f7fc',
          '#69718C',
          '#69718C',
        ],
      },
    },
    header: {
      self: {
        skin: 'light',
        fixed: {
          desktop: true,
          mobile: true,
        },
      },
      menu: {
        self: {
          display: false,
          layout: 'default',
          'root-arrow': true,
        },
        desktop: {
          arrow: true,
          toggle: 'click',
          submenu: {
            skin: 'light',
            arrow: true,
          },
        },
        mobile: {
          submenu: {
            skin: 'dark',
            accordion: true,
          },
        },
      },
    },
    subheader: {
      display: true,
      layout: 'subheader-v1',
      fixed: true,
      width: 'fluid',
      style: 'solid',
    },
    content: {
      width: 'fluid',
    },
    brand: {
      self: {
        skin: 'light',
      },
    },
    aside: {
      self: {
        skin: 'light',
        display: true,
        fixed: true,
        minimize: {
          toggle: true,
          default: false,
        },
      },
      footer: {
        self: {
          display: true,
        },
      },
      menu: {
        dropdown: false,
        scroll: false,
        submenu: {
          accordion: true,
          dropdown: {
            arrow: true,
            'hover-timeout': 500,
          },
        },
      },
    },
    footer: {
      self: {
        width: 'fluid',
      },
    },
  };

  /**
   * Good place for getting the remote config
   */
  public get configs(): LayoutConfigModel {
    return this.defaults;
  }
}
