// Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// RxJS
import { Subject } from "rxjs";
// NGRX
// AppState
import { DatePipe } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { EChartsOption } from "echarts";
import { defaultsDeep } from "lodash";
import { switchMap } from "rxjs/operators";
import { LocalDatePipe, LocalNumberPipe } from "../../../../core/base";
import { environment_api } from "./../../../../../environments/environment.api";
// Auth

@Component({
  selector: "data-high-chart",
  templateUrl: "./data-high-chart.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataHighChartComponent implements OnInit, OnDestroy {
  @Input() apiUrl: string;
  //@Input() chartOptions: any;

  langChangeSubs$: any;
  dataChartLoader$: Subject<void> = new Subject();
  chartLocale: string;

  // chart
  chart;
  updateFromInput = false;
  chartConstructor = "chart";
  chartCallback;

  chartOptions: EChartsOption;
  mergeOptions: EChartsOption;

  chartOption: EChartsOption = {
    xAxis: {
      type: "category",
      data: [],
      axisLabel: {
        rotate: -45,
        formatter: (value: string) => {
          return this.localDate.transform(+value, "date");
        },
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif",
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      axisLabel: {
        fontSize: "13px",
        formatter: (value: string) => {
          return this.localNumber.transform(+value, null);
        },
      },
    },
    series: [
      {
        data: [],
        type: "bar",
        barCategoryGap: 0,
      },
    ],
  };

  // destroy
  private destroy$: any;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   * @param router: Router
   */
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private localDate: LocalDatePipe,
    private localNumber: LocalNumberPipe,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef
  ) {
    const self = this;

    this.langChangeSubs$ = this.translateService.onLangChange.subscribe(
      (lang: LangChangeEvent) => {
        self.chartLocale = lang.lang;
        self.updateFromInput = true;
        //self.chart.reflow();
      }
    );

    // saving chart reference using chart callback
    // this.chartCallback = chart => {
    // 	self.chart = chart;
    // 	self.initChart();
    // 	self.loadChartData();
    // };
  }

  ngOnInit() {
    // set defaults
    defaultsDeep(this.chartOptions, {
      xAxis: {
        labels: {
          style: {},
        },
      },
      yAxis: {
        labels: {
          style: {},
        },
      },
    });
    this.initChart();

    //console.log(this.chartOptions);
  }

  ngOnDestroy() {
    // this.langChangeSubs$.unsubscribe();
    // this.dataChartLoader$.unsubscribe();
    // if (this.destroy$) this.destroy$.unsubscribe();
  }

  initChart() {
    //chart.showLoading();

    const dataChartUrl = environment_api.api_url + this.apiUrl;

    this.destroy$ = this.dataChartLoader$
      .pipe(
        switchMap(() => {
          const isTestdata = localStorage.getItem("is_testdata");

          let params = new HttpParams();
          params = params.set("isTestdata", isTestdata.toString());

          return this.http.get<any>(dataChartUrl, { params: params });
        })
      )
      .subscribe((results: any) => {
        let parsedData = results.data_chart.map((r) => [
          Date.parse(r[0]),
          r[1],
        ]);

        this.mergeOptions = {
          series: {
            data: parsedData.map((e) => e[1]),
          },
          xAxis: {
            data: parsedData.map((e) => e[0]),
          },
          tooltip: {
            formatter: (params) => {
              const currencyPrefix =
                results.currency_unit || results.currency_name;
              const header = this.localDate.transform(
                +params.name,
                "date",
                "fullDate"
              );
              const footer = this.translateService.instant(
                "CHART.TRACKDAY_ITEM.CANCELLATION_PRICES.TOOLTIP",
                {
                  price:
                    "<b>" +
                    currencyPrefix +
                    this.localNumber.transform(params.value, null) +
                    "</b>",
                }
              );
              return header + "<br>" + footer;
            },
          },
          yAxis: {
            name: this.translateService.instant(
              "CHART.TRACKDAY_ITEM.CANCELLATION_PRICES.YAXIS_TITLE",
              { currency: results.currency_name }
            ),
            nameLocation: "middle",
            nameRotate: 90,
            nameGap: 70,
          },
        };
        this.cdRef.markForCheck();
      });

    this.loadChartData();

    // this.destroy$ = this.dataChartLoader$
    // 	.pipe(switchMap(() => {
    // 		chart.showLoading();

    // 		const isTestdata = localStorage.getItem('is_testdata');

    // 		let params = new HttpParams();
    // 		params = params.set('isTestdata', isTestdata.toString());
    // 		return this.http.get<any>(dataChartUrl, { params: params });
    // 	}))
    // 	.subscribe(results => {
    // 		chart.hideLoading();

    // 		self.chartOptions.yAxis.title.text =
    // 			this.translateService.instant('CHART.TRACKDAY_ITEM.DYNAMIC_PRICES.YAXIS_TITLE', { currency: results.currency_name });

    // 		let parsedData = results.data_chart.map(r => [Date.parse(r[0]), r[1]]);

    // 		const currencyPrefix = results.currency_unit || results.currency_name;
    // 		self.chartOptions.tooltip = {
    // 			formatter: function () {
    // 				const header = self.localDate.transform(this.x, 'date', 'fullDate');
    // 				const footer = self.translateService.instant('CHART.TRACKDAY_ITEM.DYNAMIC_PRICES.TOOLTIP', {
    // 					price: '<b>' + currencyPrefix + self.localNumber.transform(this.y, null) + '</b>'
    // 				});

    // 				return header + '<br>' + footer;
    // 			}

    // 			//pointFormat: this.translateService.instant('CHART.TRACKDAY_ITEM.DYNAMIC_PRICES.TOOLTIP', { price: '<b>' + currencyPrefix + '{point.y:.1f}</b>' })
    // 		};

    // 		self.chartOptions.series = [
    // 			{
    // 				data: parsedData,
    // 				name: 'Dynamic Prices',
    // 				dataGrouping: {
    // 					//forced: true,
    // 					units: [['month', [1]]]
    // 				},
    // 				//step: 'right'
    // 			}
    // 		];

    // 		self.updateFromInput = true;
    // 		if (chart) chart.reflow();
    // 	});
  }

  loadChartData() {
    this.dataChartLoader$.next();
  }
}
