// Angular
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../../../services/auth.service";
import { TokenExpiredDialogComponent } from "../../../views/partials/content/token-expired/token-expired-dialog.component";

@Injectable()
export class TokenGuard implements CanActivate {
  data: Boolean;

  constructor(public dialog: MatDialog, public auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.auth.isTokenExpired().subscribe((res) => {
      this.data = res;
    });
    const value = setTimeout(() => {
      this.checkToken(this.data);
    }, 4000);

    return true;
  }

  checkToken(data) {
    console.log(data);
    if (data === true) {
      return data;
    } else {
      const dialogRef = this.dialog.open(TokenExpiredDialogComponent, {
        width: "300px",
        data: {},
        disableClose: true,
      });
      return data;
    }
  }
}
