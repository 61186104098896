import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";

@Component({
  selector: "startnumberpool-form",
  templateUrl: "./startnumberpool-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartnumberpoolFormComponent implements OnInit {
  apiUrl: string;
  constructor() {}

  ngOnInit() {
    this.apiUrl = ApiConfig.StartnumberpoolTrackdayitemUrl();
  }
}
