import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NgxEchartsModule } from "ngx-echarts";
import { MaterialModule } from "../material/material.module";
import { CbLoadingComponent } from "./components/cb-loading.component";
import { FilterDateRangeComponent } from "./components/filter-shell/filter-date-range.component";
import { FilterGeneratorComponent } from "./components/filter-shell/filter-generator.component";
import { FilterRangeComponent } from "./components/filter-shell/filter-range.component";
import { FilterSearchComponent } from "./components/filter-shell/filter-search.component";
import { FilterShellComponent } from "./components/filter-shell/filter-shell.component";
import { FilterSingleSelectComponent } from "./components/filter-shell/filter-single-select.component";
import { GenericChartComponent } from "./components/generic-chart/generic-chart.component";
import { AnchorComponentDirective } from "./directives/component-anchor.directive";
import { FilterMultiSelectComponent } from "./components/filter-shell/filter-multi-select.component";

@NgModule({
  declarations: [
    CbLoadingComponent,
    GenericChartComponent,
    FilterShellComponent,
    AnchorComponentDirective,
    FilterGeneratorComponent,
    FilterSingleSelectComponent,
    FilterDateRangeComponent,
    FilterRangeComponent,
    FilterSearchComponent,
    FilterMultiSelectComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    HttpClientModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
  ],
  //   providers: [
  //     { provide: MAT_DATE_LOCALE, useValue: "en-US" },
  //     {
  //       provide: DateAdapter,
  //       useClass: MomentDateAdapter,
  //       deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  //     },
  //     { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  //   ],
  exports: [CbLoadingComponent, GenericChartComponent, FilterShellComponent],
})
export class SharedModule {}
