import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { CustomDropzoneComponent } from "../../../../../../views/partials/content/custom-dropzone/custom-dropzone.component";
import { FormConfigService } from "../../../../form.config.service";

@Component({
  selector: "cb-tab-content-upload",
  templateUrl: "./tab-content-upload.component.html",
  styleUrls: ["./tab-content-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentUploadComponent implements OnInit {
  @Input() content: any;
  @Input() forms: UntypedFormGroup;
  customDropzoneComponents: CustomDropzoneComponent[] = [];
  id: number;
  constructor(private formService: FormConfigService) {
    this.id = this.formService?.formData?.id;
  }

  ngOnInit(): void {}
}
