import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../../core/base/utils";
import { CoreModule } from "../../../../core/core.module";
import {
  CrudTableEffects,
  CrudTableStore,
  crudTableReducer,
} from "../../../../core/store";
import { PermissionGroupDescriptionComponent } from "../../../pages/permission-keys-management/permission-groups/table/_subs/permission-group-description/permission-group-description.component";
import { PartialsModule } from "../../partials.module";
import { CbPaginatorComponent } from "../cb-paginator/cb-paginator.component";
import { CustomMatSelectModule } from "../custom-mat-select/custom-mat-select.module";
import { TranslationService } from "./../../../../core/base/services/translation.service";
import { TrackdayBookingsInfoDialogComponent } from "./../../../pages/trackdays-management/trackday-bookings/trackday-bookings-info-dialog/trackday-bookings-info-dialog.component";
import { CrudTableComponent } from "./crud-table.component";

import { SharedModule } from "../../../../modules/shared/shared.module";
import { TrackdayDirectbookingSnippetTableComponent } from "./../../../pages/snippet/trackday-directbooking-snippet/trackday-directbooking-snippet-table/trackday-directbooking-snippet-table.component";
import { MaterialModule } from "../../../../modules/material/material.module";

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    HttpClientModule,
    PartialsModule,
    StoreModule.forFeature("crudTable", crudTableReducer),
    EffectsModule.forFeature([CrudTableEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MaterialModule,

    RouterModule,
    DragDropModule,
    SharedModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [TranslationService],
      useFactory: (translationService) =>
        translationService.getSelectedLanguage(),
    },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    CrudTableStore,
    // Title,
  ],
  declarations: [
    CrudTableComponent,
    PermissionGroupDescriptionComponent,
    TrackdayBookingsInfoDialogComponent,
    CbPaginatorComponent,
    TrackdayDirectbookingSnippetTableComponent,
  ],
  exports: [CrudTableComponent],
})
export class CrudTableModule {}
