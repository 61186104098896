import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private data = {};

    setData(name, value) {
        this.data[name] = value;
    }

    getData(name) {
        return this.data[name];
    }

    cleardata() {
        this.data = {};
    }
}