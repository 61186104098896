import { BaseModel } from '../../base';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';
import { VirtualGarage } from './virtual-garage.model';

export class User extends BaseModel {
  id: number;
  username: string;
  password: string;
  password_confirmation: string;
  email: string;
  access_token: string;
  refreshToken: string;
  roles: any[];
  laptime: any[];
  pic: Object;
  firstname: string;
  lastname: string;
  department: string;
  occupation: string;
  companyName: string;
  company_id: number;
  phone: string;
  fax: string;
  mobile: string;
  function: string;
  city: string;
  zip: string;
  country_id: number;
  street: string;
  number: number;
  web: string;
  lang: string;
  address: Address;
  invoice_address: Address;
  socialNetworks: SocialNetworks;
  is_testdata: number;
  user_info: Object | any;
  virtual_garages: VirtualGarage[];
  user_type: any[];
  trackday_item_id: '';
  email_config: any = {};
  default_config: {
    test_data: null;
    test_data_show_switch: null;
    frontend_navigation: null;
  }

  clear(): void {
    this.id = undefined;
    this.username = '';
    this.password = '';
    this.password_confirmation = '';
    this.email = '';
    this.roles = [];
    this.laptime = [];
    this.firstname = '';
    this.lastname = '';
    this.department = '';
    this.access_token = 'access-token-' + Math.random();
    this.refreshToken = 'access-token-' + Math.random();
    this.pic = './assets/media/users/default.jpg';
    this.occupation = '';
    this.companyName = '';
    this.company_id = undefined;
    this.phone = '';
    this.fax = '';
    this.mobile = '';
    this.function = '';
    this.street = '';
    this.number = undefined;
    this.city = '';
    this.street = '';
    this.country_id = undefined;
    this.web = '';
    this.lang = '';
    this.address = new Address();
    this.address.clear();
    this.socialNetworks = new SocialNetworks();
    this.socialNetworks.clear();
    this.is_testdata = 0;
    this.user_info = '';
    this.virtual_garages = [];
    this.user_type = [];
    this.trackday_item_id = '';
  }
}
