import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  takeUntil,
  tap,
} from "rxjs/operators";
import { ConfigurationItemType, ConfigurationType } from "../../types";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "cb-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  @Input() apiURL: string;
  @Input() settingsKey: string = "SETTINGS_KEY";
  @Input() saveURL: string;

  isLoading$ = new BehaviorSubject(false);
  isSaving$ = new BehaviorSubject(false);
  destroyed$ = new Subject<void>();
  configurations$ = new BehaviorSubject<ConfigurationType[]>([]);
  selectedConfiguration$ = new BehaviorSubject<ConfigurationType>(null);
  hasError$ = new BehaviorSubject(false);
  configForm = new UntypedFormGroup({});
  currentConfigurations$ = combineLatest([
    this.configurations$,
    this.selectedConfiguration$,
  ]).pipe(
    map(([configurations, selectedConfiguration]) => {
      if (!selectedConfiguration) return [];
      const targetConfiguration = configurations.find(
        (item) => item.title === selectedConfiguration.title
      ).items;

      if (!targetConfiguration) return [];
      return targetConfiguration;
    })
  );

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ConfigurationComponent>
  ) {}

  ngOnInit(): void {
    this.fetchConfigurations();

    this.configForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe({ next: (value) => this.checkForErrors(value) });
  }

  checkForErrors(value) {
    if (this.configForm.valid) {
      this.configurations$.next(
        this.configurations$.value.map((config) => {
          return {
            ...config,
            hasError: false,
          };
        })
      );
      return;
    }

    const keys = Object.keys(value);
    let sectionHasError = [];
    keys.forEach((changedInputKey) => {
      this.selectedConfiguration$.value.items.forEach((item) => {
        if (item.key === changedInputKey) {
          const control = this.configForm.get(changedInputKey);
          sectionHasError.push(control.invalid);
        }
      });
    });

    const currentSelectedConfiguration = this.selectedConfiguration$.value;
    this.configurations$.next(
      this.configurations$.value.map((config) => {
        if (config.title === currentSelectedConfiguration.title) {
          return {
            ...config,
            hasError: sectionHasError.includes(true) ? true : false,
          };
        }
        return config;
      })
    );
  }

  update() {
    if (this.configForm.invalid) {
        this.checkForErrors(this.configForm.getRawValue())
        return;
    };
    this.isSaving$.next(true);
    // const url = ApiConfig.ConfigURLGroupsUpdate();
    const formValues = this.configForm.getRawValue();
    const payload = [];
    const items: ConfigurationItemType[] = this.configurations$.value.reduce(
      (acc, config) => {
        return [...acc, ...config.items];
      },
      []
    );
    for (const key in formValues) {
      const target = items.find((item) => item.key === key);
      if (target) {
        payload.push({
          id: target.id,
          key: target.key,
          value: formValues[key],
        });
      }
    }
    this.http
      .put(this.saveURL, payload)
      .pipe(tap(() => this.isSaving$.next(false)))
      .subscribe({
        next: (_) => {
          this.configForm.markAsUntouched();
          this.configForm.markAsPristine();
          this.configForm.updateValueAndValidity();
          this.toastr.success("Configuration updated successfully");
          this.isSaving$.next(false);
        },
        error: (_) => {
          this.toastr.error("Something went wrong", "Error", {
            disableTimeOut: true,
          });
          this.isSaving$.next(false);
        },
      });
  }

  fetchConfigurations() {
    this.isLoading$.next(true);
    this.http
      .get<ConfigurationItemType[]>(this.apiURL)
      .pipe(
        takeUntil(this.destroyed$),
        map((configurations) => {
          return configurations.reduce((acc, config) => {
            const currentGroup = config.group;
            const target = acc.find((item) => item.title === currentGroup);
            if (target) {
              target.items.push(config);
              target.icon = config.icon;
              target.count = target.items.length;
              return acc;
            }
            acc.push({
              title: currentGroup,
              icon: config.icon,
              items: [config],
              count: 1,
              hasError: false,
            });

            return acc;
          }, [] as ConfigurationType[]);
        }),
        tap((configurations) => {
          this.configurations$.next(configurations);
          if (configurations.length > 0) {
            this.selectedConfiguration$.next(configurations[0]);
          }
          this.isLoading$.next(false);
        }),
        tap((configuration) => this.createForm(configuration)),
        catchError((error) => {
          this.hasError$.next(true);
          this.isLoading$.next(false);
          return of(null);
        })
      )
      .subscribe();
  }

  createForm(configuration) {
    configuration.forEach((group) => {
      group.items.forEach((item) => {
        const isOptional = !!item?.["is_optional"];
        if (item.type === 16) {
          this.configForm.addControl(
            item.key,
            new UntypedFormControl(!!item.value)
          );
          return;
        }
        this.configForm.addControl(
          item.key,
          new UntypedFormControl(
            item.value,
            !isOptional ? [Validators.required] : []
          )
        );
      });
    });
  }

  retry() {
    this.hasError$.next(false);
    this.fetchConfigurations();
  }

  changeConfiguration(configuration: ConfigurationType) {
    this.selectedConfiguration$.next(configuration);
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
