import { animate, style, transition, trigger } from "@angular/animations";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "cb-confirm-action-button",
  templateUrl: "./confirm-action-button.component.html",
  styleUrls: ["./confirm-action-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(275, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ConfirmActionButtonComponent implements OnInit {
  @Input() buttonLabel: string;
  @Input() shouldConfirm: boolean = false;
  @Input() buttonClass: string;
  @Input() confirmMessage: string = "Are you sure?";
  @Input() confirmButtonText: string = "Yes";
  @Input() cancelButtonText: string = "No";
  @Input() buttonIcon: string;

  @Output() onConfirm = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  confirm() {
    this.onConfirm.emit();
  }

  cancel() {
    this.onCancel.emit();
  }

  click() {
    this.onClick.emit();
  }
}
