// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials/partials.module";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../core/base";
// Shared
// Components
import { UserEffects, usersReducer } from "../../../core/store";
import { CbFormsModule } from "../../../modules/cb-forms/cb-forms.module";
import { CrudFormModule } from "../../partials/content/crud-form/crud-form.module";
import { CrudTableModule } from "../../partials/content/crud-table/crud-table.module";
import { TimeInputModule } from "../../partials/content/time-input/time-input.module";
import { TrackdaysManagementModule } from "./../trackdays-management/trackdays-management.module";
import { LaptimeImportsFormComponent } from "./laptime-imports/laptime-imports-form/laptime-imports-form.component";
import { LaptimeImportsTableComponent } from "./laptime-imports/laptime-imports-table/laptime-imports-table.component";
import { LaptimeLevelsFormComponent } from "./laptime-levels/laptime-levels-form/laptime-levels-form.component";
import { LaptimeLevelsTableComponent } from "./laptime-levels/laptime-levels-table/laptime-levels-table.component";
import { UserAddressFormComponent } from "./user-address/user-address-form/user-address-form.component";
import { UserAddressTableComponent } from "./user-address/user-address-table/user-address-table.component";
import { UserManagementComponent } from "./user-management.component";
import { UserTypesFormComponent } from "./user-types/user-types-form/user-types-form.component";
import { UserTypesTableComponent } from "./user-types/user-types-table/user-types-table.component";
import { UsersFormComponent } from "./users/users-form/users-form.component";
import { UsersTableComponent } from "./users/users-table/users-table.component";

const routes: Routes = [
  {
    path: "",
    component: UserManagementComponent,
    children: [
      {
        path: "",
        redirectTo: "users",
        pathMatch: "full",
      },
      {
        path: "users",
        component: UsersTableComponent,
      },
      {
        path: "users:id",
        component: UsersTableComponent,
      },

      /*************** Users ********************/

      {
        path: "users",
        component: UsersTableComponent,
      },
      {
        path: "users/edit/:id",
        component: UsersFormComponent,
      },
      {
        path: "users/edit/:id/:tab",
        component: UsersFormComponent,
      },
      {
        path: "users/create",
        component: UsersFormComponent,
      },

      {
        path: "user-types",
        component: UserTypesTableComponent,
      },
      {
        path: "user-types/edit/:id",
        component: UserTypesFormComponent,
      },
      {
        path: "user-types/edit/:id/:tab",
        component: UserTypesFormComponent,
      },
      {
        path: "user-types/create",
        component: UserTypesFormComponent,
      },

      {
        path: "laptime-imports",
        component: LaptimeImportsTableComponent,
      },
      {
        path: "laptime-imports/create",
        component: LaptimeImportsFormComponent,
      },
      {
        path: "laptime-imports/edit/:id",
        component: LaptimeImportsFormComponent,
      },
      {
        path: "laptime-imports/edit/:id/:tab",
        component: LaptimeImportsFormComponent,
      },

      // laptime levels
      {
        path: "laptime-levels",
        component: LaptimeLevelsTableComponent,
      },
      {
        path: "laptime-levels/edit/:id",
        component: LaptimeLevelsFormComponent,
      },
      {
        path: "laptime-levels/edit/:id/:tab",
        component: LaptimeLevelsFormComponent,
      },
      {
        path: "laptime-levels/create",
        component: LaptimeLevelsFormComponent,
      },

      // {
      // 	path: 'user-address',
      // 	component: UserAddressTableComponent
      // },
      // {
      // 	path: 'user-address/edit/:id',
      // 	component: UserAddressFormComponent
      // },
      // {
      // 	path: 'user-address/create',
      // 	component: UserAddressFormComponent
      // },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature("users", usersReducer),
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),

    CrudTableModule,
    CrudFormModule,
    TimeInputModule,
    TrackdaysManagementModule,
    CbFormsModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [
    UserManagementComponent,
    UsersTableComponent,
    UsersTableComponent,
    UsersFormComponent,
    UserTypesTableComponent,
    UserTypesFormComponent,
    UserAddressFormComponent,
    UserAddressTableComponent,
    LaptimeImportsTableComponent,
    LaptimeImportsFormComponent,
    LaptimeLevelsTableComponent,
    LaptimeLevelsFormComponent,
  ],
})
export class UserManagementModule {}
