import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { ProcessButton } from "../../../../../../shared/data/models";
import { BehaviorSubject, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";

type collapsibleConfig = {
  collapsibleKey?: string;
  collapsible: boolean;
  display: boolean;
};
@Component({
  selector: "cb-collapsible-portal",
  templateUrl: "./collapsible-portal.component.html",
  styleUrls: ["./collapsible-portal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("collapseAnimation", [
      state(
        "collapsed",
        style({
          height: "0px",
          overflow: "hidden",
          padding: "0px",
          opacity: 0,
        })
      ),
      state(
        "expanded",
        style({
          height: "*",
          opacity: 1,
        })
      ),
      transition("collapsed <=> expanded", [animate("0.375s ease-in-out")]),
    ]),
  ],
})
export class CollapsiblePortalComponent implements OnInit {
  @Input() showHeader: boolean;
  @Input() title: string;
  @Input() config?: collapsibleConfig;
  @Input() processButtons: ProcessButton[] = [];
  private loadingComponents$ = new BehaviorSubject<
    { status: boolean; componentIdentifier: number }[]
  >([]);
  @Input() set loadingComponents(value) {
    this.loadingComponents$.next(value);
  }
  private localConfigName: string;
  @Input() set formConfigName(value: string) {
    this.localConfigName = `collapsible:${value.toLocaleLowerCase()}:${this.title.toLocaleLowerCase()}`;
  }
  @Input() componentIdentifier: number;

  isLoading$ = new BehaviorSubject(false);

  collapsed$ = new BehaviorSubject(false);
  constructor() {}

  ngOnInit(): void {
    this.collapsed$.next(this.getCollapsedState());
    combineLatest([this.loadingComponents$])
      .pipe(
        filter(([isChildLoading]) => isChildLoading.length > 0),
        map(([isChildLoading]) => {
          const res = isChildLoading.find(
            (c) => c.componentIdentifier === this.componentIdentifier
          );
          return res;
        }),
        filter((c) => c !== undefined)
      )
      .subscribe((value: any) => {
        this.isLoading$.next(value.status);
      });
  }

  getCollapsedState() {
    if (this.showHeader) {
      if (!this.config?.collapsible) {
        return false;
      }
      const lsValue = this.readFromLs();
      if (lsValue) {
        return lsValue === "true";
      }
    }
    return false;
  }

  toggle() {
    const value = this.collapsed$.value;
    this.updateLs(!value);
    this.collapsed$.next(!value);
  }

  private readFromLs() {
    return localStorage.getItem(this.lsKey());
  }

  private updateLs(value: boolean) {
    localStorage.setItem(this.lsKey(), value.toString());
  }

  private lsKey() {
    return this.config?.collapsibleKey
      ? `collapsible:${this.config.collapsibleKey}`
      : this.localConfigName;
  }
}
