// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials.module";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../../core/base";
// Shared
import { ChangeEntityDialogComponent } from "..";
// Components
import { ColorPickerModule } from "ngx-color-picker";
import { CoreModule } from "../../../../core/core.module";
import { CrudFormComponent } from "./crud-form.component";

import { CrudTableEffects, crudTableReducer } from "../../../../core/store";

//ckeditor
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
// import { ServicesModule } from '../../../pages/services-management/service/services.module';
import { NgxEchartsModule } from "ngx-echarts";
import { CrudTableModule } from "../crud-table/crud-table.module";

import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDropzoneModule } from "ngx-dropzone";
import { MaterialModule } from "../../../../modules/material/material.module";
import { CancellationPricesTrackdayItemsTableComponent } from "../../../pages/trackdays-management/trackday-items/_subs/cancellation-prices-trackday-items/cancellation-prices-trackday-items-table/cancellation-prices-trackday-items-table.component";
import { CustomDropzoneModule } from "../custom-dropzone/custom-dropzone.module";
import { CustomMatSelectModule } from "../custom-mat-select/custom-mat-select.module";
import { VariantComponent } from "./../../../pages/circuit-booking/locations/_subs/variant/variant.component";
import { DynamicPricesTrackdayItemsTableComponent } from "./../../../pages/trackdays-management/trackday-items/_subs/dynamic-prices-trackday-items/dynamic-prices-trackday-items-table/dynamic-prices-trackday-items-table.component";
import { Wizard3Component } from "./../crud-wizard/wizard3/wizard3.component";
import { CrudFormDialogComponent } from "./_sub/crud-form-dialog.component";
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    StoreModule.forFeature("crudTable", crudTableReducer),
    EffectsModule.forFeature([CrudTableEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    NgbTooltipModule,
    MaterialModule,
    RouterModule,
    CKEditorModule,
    CrudTableModule,
    ColorPickerModule,
    NgxDropzoneModule,
    CustomDropzoneModule,
    CustomMatSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    provideEnvironmentNgxMask(),
  ],
  declarations: [
    CrudFormComponent,
    // ServiceTableComponent,
    // ServiceFormComponent,
    ChangeEntityDialogComponent,
    Wizard3Component,
    // TrackdayBookingsFormComponent,
    // DraftBookingsFormComponent,
    // TrackdayGroupBookingsFormComponent,
    CrudFormDialogComponent,
    CancellationPricesTrackdayItemsTableComponent,
    DynamicPricesTrackdayItemsTableComponent,
    VariantComponent, // PGMTODO: refactor
    // TrackdayDirectbookingSnippetFormComponent
  ],
  exports: [CrudFormComponent],
})
export class CrudFormModule {}
