<mat-button-toggle-group  
  [value]="defaultValue"
  #group="matButtonToggleGroup"
  (change)="handleChange(group.value)"
>
  <mat-button-toggle value="table" aria-label="Text align left">
    <i class="fa-sharp fa-regular fa-table"></i>
  </mat-button-toggle>
  <mat-button-toggle value="card" aria-label="Text align center">
    <i class="fa-sharp fa-solid fa-grid-2"></i>
  </mat-button-toggle>
</mat-button-toggle-group>
