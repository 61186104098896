<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
    <crud-table
            title="Session Types"
            name="session-types"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/trackdays-management/session-types"
    ></crud-table>
</div>
