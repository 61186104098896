<ng-container *ngIf="!!tab.content">
  <ng-container [ngSwitch]="tab.type" *ngIf="forceRefresh$ | async">
    <ng-container *ngSwitchCase="'form'">
      <div class="form-group kt-form__group">
        <cb-tab-content-form
          [content]="tab.content"
          [form]="forms?.controls[tab.name]"
        ></cb-tab-content-form>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'tab'">
      <cb-tab-content-tab
        [tabs]="tab.content"
        [forms]="forms"
        [tabErrors]="tabErrors"
      ></cb-tab-content-tab>
    </ng-container>
    <ng-container *ngSwitchCase="'portlet'">
      <cb-tab-content-portlet
        [content]="tab.content"
        [forms]="forms"
        [layout]="tab.layout"
      ></cb-tab-content-portlet>
    </ng-container>
    <ng-container *ngSwitchCase="'translation'">
      <cb-tab-content-translation
        [content]="tab.content"
        [forms]="forms"
      ></cb-tab-content-translation>
    </ng-container>
    <ng-container *ngSwitchCase="'upload'">
      <cb-tab-content-upload
        [content]="tab.content"
        [forms]="forms"
      ></cb-tab-content-upload>
    </ng-container>
    <ng-container *ngSwitchCase="'crud-table'">
      <cb-tab-content-crud-table
        [content]="tab.content[0]"
        [form]="forms"
      ></cb-tab-content-crud-table>
    </ng-container>
    <ng-container *ngSwitchCase="'html'">
      <cb-tab-content-html
        [content]="tab.content[0]"
        [form]="forms"
      ></cb-tab-content-html>
    </ng-container>
    <div *ngSwitchDefault>this should not happen</div>
  </ng-container>
</ng-container>
