import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "cb-tab-content-form",
  templateUrl: "./tab-content-form.component.html",
  styleUrls: ["./tab-content-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentFormComponent implements OnInit {
  @Input() content = [];
  @Input() form: UntypedFormGroup;
  constructor() {}

  ngOnInit(): void {}
}
