import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { sendEmailSelectors } from "../../store";

@Component({
  selector: "app-template-extra-information",
  templateUrl: "./template-extra-information.component.html",
  styleUrls: ["./template-extra-information.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateExtraInformationComponent {
  templateExtraInfo$ = this.store.pipe(
    select(sendEmailSelectors.selectTemplateExtraInfo)
  );

  constructor(private store: Store) {}
}
