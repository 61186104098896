import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";

const userManagementPermissionId = 2;
@Component({
  selector: "laptime-levels-table",
  templateUrl: "./laptime-levels-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaptimeLevelsTableComponent implements OnInit {
  columns = [];
  searchableColumns = ["enum", "color"];
  apiUrl: string;
  createButtonLabel = "Create Laptime Level";
  constructor() {}

  ngOnInit() {
    this.apiUrl = ApiConfig.LaptimeLevelsURL();
  }
}
