// Angular
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
// RxJS
import { BehaviorSubject, Subscription } from "rxjs";
// Layout
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  DashboardConfigService,
  MenuAsideService,
  SubheaderService,
} from "../../../../../core/base";
import { DashboardConfigPortletType } from "../../../../../core/base/services/dashboard-config.service";
import { Breadcrumb } from "../../../../../core/base/services/subheader.service";
import {
  ApiService,
  AuthService,
  MenusConfigService,
} from "../../../../../services";
import { ConfirmActionDialogComponent } from "../../../content";
import { ApiConfig } from "./../../../../../core/config/api.config";

@Component({
  selector: "kt-subheader1",
  templateUrl: "./subheader1.component.html",
  styleUrls: ["./subheader1.component.scss"],
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  @Input() fluid: boolean;
  @Input() clear: boolean;

  today: number = Date.now();
  title = "";
  desc = "";
  breadcrumbs: Breadcrumb[] = [];
  staticInfo = "";
  pageInfo = "";

  pageInfoUrl$ = new BehaviorSubject<string>(null);

  hasMenuHorizontal: boolean;

  // Private properties
  private subscriptions: Subscription[] = [];

  //@Output() onConfigDashboardAddRow: EventEmitter<VirtualGarage> = new EventEmitter<VirtualGarage>();

  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private menusConfigService: MenusConfigService,
    private menusAsideService: MenuAsideService,
    private auth: AuthService,
    private dialog: MatDialog,
    public subheaderService: SubheaderService,
    private router: Router,
    public dashboardConfigService: DashboardConfigService,
    public cRef: ChangeDetectorRef
  ) {
    // load config portlet menus
    this.menusConfigService
      .getMenuConfig(
        localStorage.getItem("language") ?? "en",
        "dashboard-portlet"
      )
      .subscribe((res) => {
        this.auth.getAllPermissions().subscribe((response) => {
          // load translations
          this.dashboardConfigService.loadTranslations(res.config.translations);

          // load porlets
          let portletMenus: DashboardConfigPortletType[] = [];

          (response || []).forEach((data) => {
            (res.config.portlets[data["role_name"]] || []).forEach(
              (element) => {
                if (portletMenus.find((r) => r.title == element.title)) return;
                portletMenus.push(<DashboardConfigPortletType>{ ...element });
              }
            );
          });

          this.dashboardConfigService.loadPorletMenus(portletMenus);
        });
      });
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // set sub-header title
    // const currentMenuItem = this.menusAsideService.currentMenuItem();
    // console.log('subheader1.currentMenuitem', currentMenuItem);
    // if (currentMenuItem) this.subheaderService.setTitle(currentMenuItem.title);

    const frontendNavs = (
      localStorage.getItem("frontend_navigation") || "topbar"
    ).split(",");
    this.hasMenuHorizontal = frontendNavs.indexOf("topbar") >= 0;

    this.menusAsideService.currenMenuItem$.subscribe((menuItem) => {
      const title = menuItem ? menuItem.translate || menuItem.title : "";
      this.subheaderService.setTitle(title);
    });

    this.dashboardConfigService.editMode$.subscribe((mode) => {
      this.cRef.detectChanges();
    });

    // Load header info -> the content is in footer!!! now, find a way to move the whole thing to footer component instead
    const apiBaseUrl = ApiConfig.InfoURL() + "/static";
    this.apiService.getHtml(apiBaseUrl).subscribe((resp) => {
      this.subheaderService.setStaticInfo(resp);
    });
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.subheaderService.title$.subscribe((bt) => {
        // breadcrumbs title sometimes can be undefined
        if (bt) {
          Promise.resolve(null).then(() => {
            this.title = bt.title;
            this.desc = bt.desc;
          });
        }
      })
    );

    this.subscriptions.push(
      this.subheaderService.breadcrumbs$.subscribe((bc) => {
        Promise.resolve(null).then(() => {
          this.breadcrumbs = bc;
        });
      })
    );

    // this.subscriptions.push(this.subheaderService.staticInfo$.subscribe(info => {
    // 	Promise.resolve(null).then(() => {
    // 		this.staticInfo = info;
    // 	});
    // }));

    // this.subscriptions.push(this.subheaderService.pageInfo$.subscribe(info => {
    // 	Promise.resolve(null).then(() => {
    // 		this.pageInfo = info;
    // 	});
    // }));
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  isDashboardPage() {
    return this.router.url === "/dashboard";
  }

  addRow(totalColumns: number) {
    this.dashboardConfigService.addRow(totalColumns);
  }

  addPortlet(portlet: DashboardConfigPortletType) {
    this.dashboardConfigService.addPortlet(portlet);
  }

  editDashboardConfig() {
    this.dashboardConfigService.editMode$.next(true);
  }

  saveDashboardConfig() {
    this.dashboardConfigService.saveChanges$.next(true);
  }

  cancelDashboardConfig() {
    let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        title: "Discard Changes",
        message: "Are you sure you want to cancel your changes?",
      },
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        //this.dashboardConfigService.editMode$.next(false);
        this.dashboardConfigService.cancelChanges$.next(true);
      }
    });
  }
}
