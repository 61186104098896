// Angular
import { Injectable, inject } from "@angular/core";
// RxJS
import { forkJoin, of, throwError } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
// NGRX
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
// CRUD
import { LayoutUtilsService } from "../../base";
// Services
import { CrudTableService } from "../../../services/crud-table.service";
// State
import {
  CrudActionServerError,
  CrudActionToggleLoading,
  CrudTableActionTypes,
  ItemCreated,
  ItemDeleted,
  ItemOnServerCreated,
  ItemOnServerDeleted,
  ItemOnServerUpdated,
  ItemUpdated,
  PageLoaded,
  PageRequested,
  PageToggleLoading,
} from "../actions/crud-table.actions";
import { AppState } from "../reducers/app.reducers";

@Injectable()
export class CrudTableEffects {
  actions$ = inject(Actions);
  store = inject(Store<AppState>);
  layoutUtilsService = inject(LayoutUtilsService);
  crudTableService = inject(CrudTableService);
  showPageLoadingDistpatcher = new PageToggleLoading({ isLoading: true });
  hidePageLoadingDistpatcher = new PageToggleLoading({ isLoading: false });

  showActionLoadingDistpatcher = new CrudActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new CrudActionToggleLoading({
    isLoading: false,
  });

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PageRequested>(CrudTableActionTypes.PageRequested),
      switchMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.crudTableService.findItems(
          payload.url,
          payload.page
        );
        const resolveData = of(payload.resolveData);
        return forkJoin(requestToServer, resolveData);
      }),
      map((response) => {
        const result: any = response[0];
        const resolveData: any = response[1];
        let items = result.data || result || [];

        if (resolveData) {
          items = resolveData(items);
        }

        return new PageLoaded({
          items,
          totalCount: result.total,
          page: result.current_page,
        });
      })
    )
  );

  deleteItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ItemDeleted>(CrudTableActionTypes.ItemDeleted),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);

        return this.crudTableService.deleteItem(payload.url, payload.id).pipe(
          tap((res) => {
            this.store.dispatch(
              new ItemOnServerDeleted({ id: payload.id, url: payload.url })
            );
          }),
          catchError((res: any) => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            console.log(res);
            if (res.status === 400 && res.error) {
              this.store.dispatch(
                new CrudActionServerError({
                  errors: {
                    statusCode: res.status,
                    message: res.error,
                  },
                })
              );
            } else if (res.status == 500) {
              this.store.dispatch(
                new CrudActionServerError({
                  errors: `An Unexpected Error Occurred. Please try again.`,
                })
              );
            }

            return throwError(res);
          })
        );
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );

  updateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ItemUpdated>(CrudTableActionTypes.ItemUpdated),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.crudTableService.updateItem(payload.url, payload.item).pipe(
          tap((res) => {
            const message = `Item has been saved successfully`;
            this.layoutUtilsService.showNotification("success", message);
            this.store.dispatch(
              new ItemOnServerUpdated({ item: res, url: payload.url })
            );
          }),
          catchError((res: any) => {
            if (res.status === 400 && res.error) {
              this.store.dispatch(
                new CrudActionServerError({
                  errors: {
                    statusCode: res.status,
                    message: res.error,
                  },
                })
              );
            } else if (res.status == 500) {
              this.store.dispatch(
                new CrudActionServerError({
                  errors: `An Unexpected Error Occurred. Please try again.`,
                })
              );
            }

            return throwError(res);
          })
        );
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );

  createItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ItemOnServerCreated>(CrudTableActionTypes.ItemOnServerCreated),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.crudTableService.createItem(payload.url, payload.item).pipe(
          tap((res) => {
            this.store.dispatch(
              new ItemCreated({ item: res, url: payload.url })
            );
          }),
          catchError((res: any) => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            console.log(res);
            if (res.status === 400 && res.error) {
              this.store.dispatch(
                new CrudActionServerError({
                  errors: {
                    statusCode: res.status,
                    message: res.error,
                  },
                })
              );
            } else if (res.status == 500) {
              this.store.dispatch(
                new CrudActionServerError({
                  errors: `An Unexpected Error Occurred. Please try again.`,
                })
              );
            }

            return throwError(res);
          })
        );
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );
}
