import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";
import {environment_api} from '../../../../../../environments/environment.api';

@Component({
  selector: "sessions-form",
  templateUrl: "./sessions-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionsFormComponent implements OnInit {
  apiUrl: string;
  constructor() {}

  ngOnInit() {
    this.apiUrl = environment_api.api_url + '/sessions';
  }
}
