import { Location } from "@angular/common";
import { Component, Inject, OnInit, ViewChild, inject } from "@angular/core";
import { Store, select } from "@ngrx/store";
import {
  CardRenderedEventArgs,
  CardSettingsModel,
  ColumnsModel,
  DialogSettingsModel,
  DragEventArgs,
  SwimlaneSettingsModel,
} from "@syncfusion/ej2-angular-kanban";
import { addClass, extend } from "@syncfusion/ej2-base";
import { BehaviorSubject } from "rxjs";
import { ACTIONS } from "../../store/default.actions";
import { selectTasksByBoardId } from "../../store/default.selector";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "kanban-board",
  templateUrl: "./kanban-board.component.html",
  styleUrls: ["./kanban-board.component.scss"],
})
export class KanbanBoardComponent implements OnInit {
  @ViewChild("kanbanObj") kanbanObj: KanbanBoardComponent | undefined;
  store = inject(Store);
  tasks = [];
  isLoading$ = new BehaviorSubject(true);
  tasks$ = this.store.pipe(select(selectTasksByBoardId));

  public kanbanData: Object[] = extend(
    [],
    this.tasks,
    undefined,
    true
  ) as Object[];
  public columns: ColumnsModel[] = [
    { headerText: "To Do", keyField: "todo", allowToggle: true },
    { headerText: "In Progress", keyField: "inprogress", allowToggle: true },
    { headerText: "In Review", keyField: "review", allowToggle: true },
    { headerText: "Done", keyField: "done", allowToggle: true },
  ];
  public cardSettings: CardSettingsModel = {
    headerField: "Title",
    template: "#cardTemplate",
    selectionType: "Multiple",
  };
  public dialogSettings: DialogSettingsModel = {
    fields: [
      { text: "ID", key: "Title", type: "TextBox" },
      { key: "Status", type: "DropDown" },
      { key: "Assignee", type: "DropDown" },
      //{ key: 'RankId', type: 'TextBox' },
      { key: "Summary", type: "TextArea" },
    ],
  };
  public swimlaneSettings: SwimlaneSettingsModel = { keyField: "Assignee" };

  constructor(
    @Inject(MAT_DIALOG_DATA) public settings: any,
    private dialogRef: MatDialogRef<KanbanBoardComponent>,
    private location: Location
  ) {}
  ngOnInit(): void {
    this.tasks$.subscribe((e) => {
      const d = e.map((a: any) => {
        return {
          Id: a.id,
          Title: a.title,
          Status: a?.status.name,
          Summary: a.description,
          Priority: "High",
          Tags: "",
          //'RankId': 1,
          Assignee: "Nancy Davloio",
        };
      });
      this.kanbanData = extend([], d, undefined, true) as Object[];
    });
    //const urlParts = this.location.path().split('/');
    const boards = this.settings.board;
    if (boards.length) {
      this.store.dispatch(
        ACTIONS.tasks.GetTasksByBoardId({
          payload: boards[0].id,
        })
      );
    }
  }

  getStateOf(name) {
    const states = {
      Open: 1,
      InProgress: 2,
      Review: 3,
      Close: 4,
    };
    return states[name];
  }

  close = () => {
    this.store.dispatch(ACTIONS.tasks.ResetTasks());
    this.dialogRef.close();
  };

  onDialogClose = (e: any) => {
    console.log(e.requestType);
    const targetTicket = this.kanbanData.find((t: any) => t.Id === e.data.Id);
    if (e.requestType === "Edit") {
      if (targetTicket) {
        console.log(e.data);
        const taskToUpdate = {
          id: e.data.Id,
          title: e.data.Title,
          description: e.data.Summary,
          state_id: this.getStateOf(e.data.Status),
        };
        this.store.dispatch(
          ACTIONS.tasks.UpdateTaskByIdForPopUp({
            payload: taskToUpdate,
          })
        );
      }
    }
    if (e.requestType === "Delete") {
    }
  };

  onKanbanDragStop(e: DragEventArgs) {
    //console.log(e)
    if (e.data.length) {
      //@ts-ignore
      const taskId = e.data[0]?.Id;
      //@ts-ignore
      const stateToGo = e.data[0]?.Status;
      this.store.dispatch(
        ACTIONS.tasks.UpdateTaskById({
          payload: {
            id: taskId,
            state: stateToGo,
          },
        })
      );
    }
  }

  public getString(assignee: string) {
    //@ts-ignore
    return assignee
      .match(/\b(\w)/g)
      .join("")
      .toUpperCase();
  }
  cardRendered(args: CardRenderedEventArgs): void {
    //@ts-ignore
    const val: string = (<{ [key: string]: Object }>args.data)
      .Priority as string;
    addClass([args.element], val);
  }
}
