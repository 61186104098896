<ng-container *ngIf="apiUrl !== ''">
  <ng-container *ngIf="(isFormShown$ | async) === false; else form">
    <crud-table
      [title]="content?.title"
      [name]="content.table_config"
      [configName]="content.table_config"
      [configNameClient]="content?.table_config_client ?? content.table_config"
      [relatedId]="content.relatedId"
      [apiUrl]="apiUrl"
      [formConfig]="content?.form?.config"
      [configActionLoadBtns]="content.action_load_buttons"
      [masterData]="parentFormData$ | async"
      [searchFilterParams]="content.search_filter_params"
      (onCreate)="onCreateHandler(content, $event)"
      (onEdit)="onEditHandler(content, $event)"
      (onLoadTableList)="content.items = $event.entitySubject.value"
      [isSubForm]="true"
      [hasReadonlyActions]="content?.['has_readonly_actions'] || false"
      [readonly]="content?.['readonly'] || !content?.['form'] || false"
      [disableFilters]="content?.['disable_filters'] || false"
      (onLoadingStateChange)="onChildLoadingChangeEvent($event)"
      [portletComponentIdentifier]="componentIdentifier"
      [hideTitle]="parentIsPortlet"
    ></crud-table>
  </ng-container>
</ng-container>
<ng-template #form>
  <app-forms-shell
    *ngIf="formConfigForm !== null"
    [name]="formConfigForm.config"
    [apiUrl]="formConfigForm.api_url"
    [listUrl]="formConfigForm.api_url"
    [isSubForm]="true"
    [initialData]="formConfigForm.initial_data"
    [parentData]="parentData$ | async"
    (onExit)="onExit()"
    (onBreadCrumb)="onBreadCrumb($event)"
  ></app-forms-shell>
</ng-template>
