import { NgxPermissionsModule } from "ngx-permissions";
// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
  NgbProgressbarModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
// Translation
import { TranslateModule } from "@ngx-translate/core";
// Loading bar
import { LoadingBarModule } from "@ngx-loading-bar/core";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Ngx DatePicker
// Perfect Scrollbar
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
// Core Module
import { CoreModule } from "../../core/core.module";
import {
  PermissionEffects,
  permissionsReducer,
  RoleEffects,
  rolesReducer,
} from "../../core/store";
import { PagesModule } from "../pages/pages.module";
import { PartialsModule } from "../partials/partials.module";
import { AsideLeftComponent } from "./aside/aside-left.component";
import { BaseComponent } from "./base/base.component";
import { BrandComponent } from "./brand/brand.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { WsStatusComponent } from "./footer/components/ws-status/ws-status.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderMobileComponent } from "./header/header-mobile/header-mobile.component";
import { HeaderComponent } from "./header/header.component";
import { MenuHorizontalComponent } from "./header/menu-horizontal/menu-horizontal.component";
import { HtmlClassService } from "./html-class.service";
import { SubheaderComponent } from "./subheader/subheader.component";
import { TestdataSwitchComponent } from "./testdata-switch/testdata-switch.component";
import { MaterialModule } from "../../modules/material/material.module";

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,
    WsStatusComponent,
    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    TestdataSwitchComponent,

    // subheader
    SubheaderComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,
  ],
  exports: [
    BaseComponent,
    FooterComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    TestdataSwitchComponent,

    // subheader
    SubheaderComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,
  ],
  providers: [HtmlClassService],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    StoreModule.forFeature("roles", rolesReducer),
    StoreModule.forFeature("permissions", permissionsReducer),
    EffectsModule.forFeature([PermissionEffects, RoleEffects]),
    PagesModule,
    PartialsModule,
    CoreModule,
    PerfectScrollbarModule,
    MaterialModule,
    FormsModule,
    TranslateModule.forChild(),
    LoadingBarModule,
    // ng-bootstrap modules
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
})
export class ThemeModule {}
