// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials/partials.module";
// Services
import {
    HttpUtilsService,
    InterceptService,
    LayoutUtilsService,
    TypesUtilsService,
} from "../../../core/base";
// Shared
// Components
import { UserEffects, usersReducer } from "../../../core/store";
import { CrudFormModule } from "../../partials/content/crud-form/crud-form.module";
import { CrudTableModule } from "../../partials/content/crud-table/crud-table.module";
import { PresetCancellationPricesManagementModule } from "../preset-cancellation-price-management/preset-cancellation-prices-management.module";
import { DebugLostCouponsTableComponent } from "./debug-lost-coupons/debug-lost-coupons-table/debug-lost-coupons-table.component";
import { DebugLostServicesTableComponent } from "./debug-lost-services/debug-lost-services-table/debug-lost-services-table.component";
import { TrackdayItemsFormComponent } from "./trackday-items/trackday-items-form/trackday-items-form.component";
import { TrackdayItemsTableComponent } from "./trackday-items/trackday-items-table/trackday-items-table.component";
import { TrackdayTypesFormComponent } from "./trackday-types/trackday-types-form/trackday-types-form.component";
import { TrackdayTypesTableComponent } from "./trackday-types/trackday-types-table/trackday-types-table.component";
import { TrackdaysFormComponent } from "./trackdays/trackdays-form/trackdays-form.component";
import { TrackdaysTableComponent } from "./trackdays/trackdays-table/trackdays-table.component";
// import { TrackdayBookingsTableComponent } from './trackday-bookings/trackday-bookings-table/trackday-bookings-table.component';
import { CbFormsModule } from "../../../modules/cb-forms/cb-forms.module";
import { PresetDynamicPricesManagementModule } from "../preset-dynamic-price-management/preset-dynamic-prices-management.module";
import { StatisticsModule } from "../statistics/statistics.module";
import { VirtualGarageManagementModule } from "../virtualgarage-management/virtualgarage-management.module";
import { DraftBookingsFormComponent } from "./draft-bookings/draft-bookings-form/draft-bookings-form.component";
import { DraftBookingsTableComponent } from "./draft-bookings/draft-bookings-table/draft-bookings-table.component";
import { GroupBookingZombiesTableComponent } from "./group-booking-zombies/group-booking-zombies-table/group-booking-zombies-table.component";
import { InvoicesFormComponent } from "./invoices/invoices-form/invoices-form.component";
import { InvoicesTableComponent } from "./invoices/invoices-table/invoices-table.component";
import { PaymentsFormComponent } from "./payments/payments-form/payments-form.component";
import { PaymentsTableComponent } from "./payments/payments-table/payments-table.component";
import { PricesTableComponent } from "./prices/prices-table/prices-table.component";
import { PricestrashedinvoicesTableComponent } from "./pricestrashedinvoices/pricestrashedinvoices-table/pricestrashedinvoices-table.component";
import { SessionTypesFormComponent } from "./session-types/session-types-form/session-types-form.component";
import { SessionTypesTableComponent } from "./session-types/session-types-table/session-types-table.component";
import { SessionsFormComponent } from "./sessions/sessions-form/sessions-form.component";
import { SessionsTableComponent } from "./sessions/sessions-table/sessions-table.component";
import { StartnumberpoolFormComponent } from "./startnumberpool/startnumberpool-form/startnumberpool-form.component";
import { StartnumberpoolTableComponent } from "./startnumberpool/startnumberpool-table/startnumberpool-table.component";
import { TrackdayBookingsFormComponent as shortBookingComponent } from "./trackday-bookings-short/trackday-bookings-form/trackday-bookings-form.component";
import { TrackdayBookingsShortTableComponent } from "./trackday-bookings-short/trackday-bookings-table/trackday-bookings-short-table.component";
import { TrackdayBookingsFormComponent } from "./trackday-bookings/trackday-bookings-form/trackday-bookings-form.component";
import { TrackdayGroupBookingsFormComponent } from "./trackday-group-bookings/trackday-group-bookings-form/trackday-group-bookings-form.component";
import { TrackdayGroupBookingsTableComponent } from "./trackday-group-bookings/trackday-group-bookings-table/trackday-group-bookings-table.component";
import { TrackdaySeasonBookingsFormComponent } from "./trackday-season-bookings/trackday-season-bookings-form/trackday-season-bookings-form.component";
import { TrackdaySeasonBookingsTableComponent } from "./trackday-season-bookings/trackday-season-bookings-table/trackday-season-bookings-table.component";
import { MaterialModule } from "../../../modules/material/material.module";

const routes: Routes = [
  /*************** Trackadays ********************/

  {
    path: "session-types",
    component: SessionTypesTableComponent,
  },
  {
    path: "session-types/edit/:id",
    component: SessionTypesFormComponent,
  },
  {
    path: "session-types/edit/:id/:tab",
    component: SessionTypesFormComponent,
  },
  {
    path: "session-types/create",
    component: SessionTypesFormComponent,
  },

  {
    path: "sessions",
    component: SessionsTableComponent,
  },
  {
    path: "sessions/edit/:id",
    component: SessionsFormComponent,
  },
  {
    path: "sessions/edit/:id/:tab",
    component: SessionsFormComponent,
  },
  {
    path: "sessions/create",
    component: SessionsFormComponent,
  },

  {
    path: "events",
    component: TrackdaysTableComponent,
  },
  {
    path: "events/edit/:id",
    component: TrackdaysFormComponent,
  },
  {
    path: "events/edit/:id/:tab",
    component: TrackdaysFormComponent,
  },
  {
    path: "events/create",
    component: TrackdaysFormComponent,
  },

  /*************** Order Trackadays ********************/

  {
    path: "trackday-events",
    component: TrackdayItemsTableComponent,
  },
  {
    path: "trackday-events/edit/:id",
    component: TrackdayItemsFormComponent,
  },
  {
    path: "trackday-events/edit/:id/:tab",
    component: TrackdayItemsFormComponent,
  },
  {
    path: "trackday-events/create",
    component: TrackdayItemsFormComponent,
  },

  /*************** Trackdays types ********************/
  {
    path: "event-types",
    component: TrackdayTypesTableComponent,
  },
  {
    path: "event-types/edit/:id",
    component: TrackdayTypesFormComponent,
  },
  {
    path: "event-types/edit/:id/:tab",
    component: TrackdayTypesFormComponent,
  },
  {
    path: "event-types/create",
    component: TrackdayTypesFormComponent,
  },

  /*************** Bookings ********************/

  //{
  //  path: "bookings",
  //  component: TrackdayBookingsTableComponent,
  //},
  {
    path: "bookings",
    component: TrackdayBookingsShortTableComponent,
  },
  {
    path: "group-bookings",
    component: TrackdayGroupBookingsTableComponent,
  },
  {
    path: "season-bookings",
    component: TrackdaySeasonBookingsTableComponent,
  },
  {
    path: "season-bookings/edit/:id",
    component: TrackdaySeasonBookingsFormComponent,
  },
  {
    path: "season-bookings/edit/:id/:tab",
    component: TrackdaySeasonBookingsFormComponent,
  },
  {
    path: "season-bookings/create",
    component: TrackdaySeasonBookingsFormComponent,
  },

  {
    path: "draft-bookings",
    component: DraftBookingsTableComponent,
  },
  {
    path: "draft-bookings/edit/:id",
    component: DraftBookingsFormComponent,
  },
  {
    path: "draft-bookings/edit/:id/:tab",
    component: DraftBookingsFormComponent,
  },

  {
    path: "invoices",
    component: InvoicesTableComponent,
  },
  {
    path: "invoices/edit/:id",
    component: InvoicesFormComponent,
  },
  {
    path: "invoices/edit/:id/:tab",
    component: InvoicesFormComponent,
  },
  {
    path: "payments",
    component: PaymentsTableComponent,
  },
  {
    path: "payments/edit/:id",
    component: PaymentsFormComponent,
  },
  {
    path: "payments/edit/:id/:tab",
    component: PaymentsFormComponent,
  },

  {
    path: "prices",
    component: PricesTableComponent,
  },
  {
    path: "lostprices",
    component: PricestrashedinvoicesTableComponent,
  },
  {
    path: "lostservices",
    component: DebugLostServicesTableComponent,
  },
  {
    path: "lostcoupons",
    component: DebugLostCouponsTableComponent,
  },
  {
    path: "group-booking-zombies",
    component: GroupBookingZombiesTableComponent,
  },
  /*************** Startnumberpool ********************/
  {
    path: "startnumberpool",
    component: StartnumberpoolTableComponent,
  },
  {
    path: "startnumberpool/edit/:id",
    component: StartnumberpoolFormComponent,
  },
  {
    path: "startnumberpool/edit/:id/:tab",
    component: StartnumberpoolFormComponent,
  },
  {
    path: "startnumberpool/create",
    component: StartnumberpoolFormComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature("users", usersReducer),
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  MaterialModule, 
    CrudTableModule,
    CrudFormModule,
    PresetCancellationPricesManagementModule,
    PresetDynamicPricesManagementModule,
    VirtualGarageManagementModule,
    StatisticsModule,
    CbFormsModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [
    DebugLostServicesTableComponent,
    DebugLostCouponsTableComponent,
    TrackdaysTableComponent,
    TrackdaysFormComponent,
    TrackdayTypesTableComponent,
    TrackdayTypesFormComponent,
    TrackdayItemsTableComponent,
    TrackdayItemsFormComponent,
    TrackdayBookingsFormComponent,
    shortBookingComponent,
    //   TrackdayBookingsTableComponent,
    TrackdayBookingsShortTableComponent,
    TrackdayBookingsFormComponent,
    TrackdayGroupBookingsTableComponent,
    TrackdaySeasonBookingsTableComponent,
    TrackdaySeasonBookingsFormComponent,
    DraftBookingsTableComponent,
    PaymentsTableComponent,
    PaymentsFormComponent,
    InvoicesTableComponent,
    InvoicesFormComponent,
    PricesTableComponent,
    PricestrashedinvoicesTableComponent,
    GroupBookingZombiesTableComponent,
    StartnumberpoolTableComponent,
    StartnumberpoolFormComponent,
    DraftBookingsFormComponent,
    TrackdayGroupBookingsFormComponent,
    SessionsFormComponent,
    SessionsTableComponent,
    SessionTypesTableComponent,
    SessionTypesFormComponent,
  ],
  exports: [TrackdayItemsTableComponent],
})
export class TrackdaysManagementModule {}
