import { Component, Input } from "@angular/core";

@Component({
  selector: "cb-loading",
  template: `
    <div class="loading-container" [ngClass]="{ relative: !isAbsolute }">
      <mat-spinner
        [strokeWidth]="strokeWidth"
        [color]="color"
        [diameter]="diameter"
      ></mat-spinner>
    </div>
  `,
  styles: [
    `
      .loading-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
      }
      .relative {
        position: relative;
        left: initial;
        top: initial;
        right: initial;
        bottom: initial;
      }
    `,
  ],
})
export class CbLoadingComponent {
  @Input() diameter: number = 100;
  @Input() color: string = "primary";
  @Input() mode: string = "indeterminate";
  @Input() strokeWidth: number = 1;
  @Input() isAbsolute: boolean = true;
}
