import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ITab } from "../../../../../../shared/data/models";

@Component({
  selector: "cb-tab-content-tab",
  templateUrl: "./tab-content-tab.component.html",
  styleUrls: ["./tab-content-tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentTabComponent implements OnInit {
  @Input() tabs: ITab[] = [];
  @Input() forms: UntypedFormGroup;
  @Input() tabErrors: string[] = [];
  constructor() {}

  ngOnInit(): void {}
}
