<div
  [ngClass]="{ 'kt-quick-search--has-result': isInFocus }"
  class="kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact"
  id="kt_quick_search_dropdown"
>
  <div class="search-container">
    <form class="kt-quick-search__form w-100" (ngSubmit)="search()">
      <div
        class="input-group"
        [ngClass]="{
          'kt-spinner kt-spinner--input kt-spinner--sm kt-spinner--brand kt-spinner--right':
            loading
        }"
      >
        <div class="input-group-prepend">
          <span class="input-group-text"
            ><i class="flaticon2-search-1"></i
          ></span>
        </div>
        <input
          #searchInput
          type="text"
          class="form-control kt-quick-search__input"
          [placeholder]="'COMMON.SEARCH' | translate"
        />
        <div class="input-group-append">
          <span class="input-group-text"
            ><i
              (click)="clear($event)"
              [ngStyle]="{ display: 'flex' }"
              [hidden]="!data || !data?.length"
              class="la la-close kt-quick-search__close"
            ></i
          ></span>
        </div>
      </div>
    </form>
    <button mat-icon-button class="cb-icon-button" (click)="search()">
      <i class="fa-solid fa-magnifying-glass"></i>
    </button>
  </div>
  <div class="kt-quick-search__wrapper">
    <kt-search-result [data]="data"></kt-search-result>
  </div>
</div>
