<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
    <crud-table
            title="Trackday Events"
            [name]="name"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/trackdays-management/trackday-events"
            [readonly]="readonly"
    ></crud-table>
</div>

<ng-template #customActionBtns let-data>
    <a mat-icon-button class="col-action-btn custom-icon-button" 
        [routerLink]="['/trackdays-management/trackday-events/edit', data.id, 'participants']"
        matTooltip="Book Participant"
        [attr.data-pgm-tablebutton]="name + '_tablebutton_' + data.id + '_book'"
        >
        <i class="far fa-book-user"></i>
    </a>
</ng-template>