// Angular
import { Injectable, inject } from "@angular/core";
// RxJS
import { forkJoin, of } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
// NGRX
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
// CRUD
// Services
import { AuthService } from "../../../services/auth.service";
// State
import {
  UserActionTypes,
  UserCreated,
  UserDeleted,
  UserOnServerCreated,
  UserUpdated,
  UsersActionToggleLoading,
  UsersPageLoaded,
  UsersPageRequested,
  UsersPageToggleLoading,
} from "../actions/user.actions";
import { AppState } from "../reducers/app.reducers";

@Injectable()
export class UserEffects {
  actions$ = inject(Actions);
  store = inject(Store<AppState>);
  auth = inject(AuthService);
  showPageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: true });
  hidePageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: false });

  showActionLoadingDistpatcher = new UsersActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new UsersActionToggleLoading({
    isLoading: false,
  });

  loadUsersPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UsersPageRequested>(UserActionTypes.UsersPageRequested),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.auth.findUsers(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: any = response[0];
        return new UsersPageLoaded({
          users: result.data || result,
          totalCount: result.total,
          page: result.current_page,
        });
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserDeleted>(UserActionTypes.UserDeleted),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.auth.deleteUser(payload.id);
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserUpdated>(UserActionTypes.UserUpdated),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.auth.updateUser(payload.user);
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserOnServerCreated>(UserActionTypes.UserOnServerCreated),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.auth.createUser(payload.user).pipe(
          tap((res) => {
            this.store.dispatch(new UserCreated({ user: res }));
          })
        );
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );
}
