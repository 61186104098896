<div>
  <form [formGroup]="addLaptimeForm" class="kt-form kt-form--group">

    <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
      Oh snap! Change a few things up and try submitting again..
    </kt-alert>
    <div class="form-group kt-form__group row">
      <div class="col-lg-3 mt-4 kt-margin-bottom-20-mobile custom-field-holder">
        <!-- <mat-form-field class="mat-form-field-fluid"> -->
          <kt-time-input matInput #time (onTimePicked)="getTime($event)"></kt-time-input>
          <!-- <input matInput type="text" id="laptime" autocomplete="off" placeholder="Laptime Name"/>
          <mat-error>Laptime is
            <strong>required</strong>
          </mat-error>
          <mat-hint align="start">Please enter
            <strong>Laptime</strong>
          </mat-hint>
        </mat-form-field> -->
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
        <mat-form-field class="mat-form-field-fluid custom-input">
          <mat-select placeholder="Location *" (selectionChange)="getVariant($event)">
            <mat-option *ngFor="let loc of location" value="{{ loc.id }}">{{loc.name}}</mat-option>
          </mat-select>
          <mat-error>Location is
            <strong>required</strong>
          </mat-error>
          <mat-hint align="start">Please enter
            <strong>Location</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
        <mat-form-field class="mat-form-field-fluid custom-input">
          <mat-select placeholder="Variant *"  formControlName="variant_id" (selectionChange)="getSelectedVariant($event)">
            <mat-option *ngFor="let vari of variant" value="{{ vari.value }}">{{vari.label}}</mat-option>
          </mat-select>
          <mat-error>Variant Id is
            <strong>required</strong>
          </mat-error>
          <mat-hint align="start">Please enter
            <strong>Variant Id</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 offset-lg-4 kt-margin-bottom-20-mobile">
        <button type="button" mat-raised-button matTooltip="Assign laptime" (click)="assignLaptime()" class="danger-button">
          Assign
        </button>
      </div>
    </div>
  </form>
  <h5 class="mt-5 mb-3">Laptime List</h5>
  <kt-laptime-table #laptimeTable
	  [dataSource]="assignedLaptime"
    (onActionDelete)="unassingLaptime($event)">
  </kt-laptime-table>
</div>
