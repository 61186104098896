// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AppState } from '../../store/reducers/app.reducers';
import { LayoutUtilsService, MessageType } from './layout-utils.service';
import { Store } from '@ngrx/store';
import { LoadingIndicatorService } from './loading-indicator.service';
import { finalize } from 'rxjs/operators';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

	constructor(public loadingIndicatorService: LoadingIndicatorService) { }

	// intercept request and add token
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger

		// modify request
		request = request.clone({
			setHeaders: {
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			}
		});
		// console.log('----request----');
		// console.log(request);
		// console.log('--- end of request---');

		// show loader
		const requestGUID = this.loadingIndicatorService.onRequestStarted();

		return next
			.handle(request)
			.pipe(
				finalize(() => {
					this.loadingIndicatorService.onRequestFinished(requestGUID);
				}),
				tap(
					event => {
						if (event instanceof HttpResponse) {

							// console.log('all looks good');
							// http response status code
							// console.log(event.status);
						}
					},
					(error: HttpErrorResponse) => {
						if (error.status === 401 && window.location.pathname !== '/auth/login') {
							window.location.href = '/auth/login';
						}
						// http response status code
						// console.log('----response----');
						// console.error('status code:');
						// tslint:disable-next-line:no-debugger
						// console.error(error.status);
						// console.error(error.message);
						// console.log('--- end of response---');
					}
				)
			);
	}
}
