import { createAction, props } from "@ngrx/store";

const updateAppGlobalConfig = createAction(
  "[App Global Config] Update App Global Config",
  props<any>()
);

export const appGlobalConfigActions = {
  updateAppGlobalConfig,
};
