<crud-table #crudTable
    [name]="name"
    [title]="title"    
    [columns]="columns"
    [searchableColumns]="searchableColumns"
    [apiUrl]="apiUrl"
    [readonly]="true"
    [hideHeader]="true"
    [hideFormFilters]="true"
    [displayedColumns]="displayedColumns"
    (columnsLoaded)="columnsLoaded.emit($event)"></crud-table>