// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Material
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { CustomSelectComponent } from "./custom-select.component";
import { CoreModule } from "../../../../core/core.module";
import { CrudUtilsService } from "../../../../core/base";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatChipsModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    NgxMatSelectSearchModule,
  ],
  providers: [CrudUtilsService],
  declarations: [CustomSelectComponent],
  exports: [CustomSelectComponent],
})
export class CustomSelectModule {}
