import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslationService } from "../../../../core/base";
import { sendEmailActions, sendEmailSelectors } from "../../store";

@Component({
  selector: "app-template-details-language",
  templateUrl: "./template-details-language.component.html",
  styleUrls: ["./template-details-language.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateDetailsLanguageComponent implements OnInit, OnDestroy {
  @Input() selectedLanguage: string;
  @Output() changeLanguageEvent = new EventEmitter<string>();
  availableTranslations$ = of([]);
  languageButtons$ = new BehaviorSubject([]);
  moreLanguagesButtons$ = new BehaviorSubject([]);
  translations$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentTemplateTranslations)
  );
  formErrors$ = this.store.pipe(
    select(sendEmailSelectors.selectFormToBeSavedError)
  );
  destroyed$ = new Subject<void>();
  constructor(
    private store: Store,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.availableTranslations$ =
      this.translationService.getAvailableLanguages$();

    combineLatest([
      this.availableTranslations$,
      this.translations$,
      this.formErrors$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ([availableTranslations, currentTranslations, errors]: [
          any,
          any,
          any
        ]) => {
          if (currentTranslations?.length && availableTranslations?.length) {
            let languageButtons = [];
            let moreLanguagesButtons = [];
            availableTranslations.forEach((at) => {
              const translation = currentTranslations.find(
                (t) => at.lang === t.lang
              );
              if (translation) {
                languageButtons.push({
                  flag: at.flag,
                  name: at.name,
                  lang: at.lang,
                  hasError: errors.includes(at.lang) ? true : false,
                });
              } else {
                moreLanguagesButtons.push({
                  flag: at.flag,
                  name: at.name,
                  lang: at.lang,
                  hasError: false,
                });
              }
            });
            this.languageButtons$.next(languageButtons);
            this.moreLanguagesButtons$.next(moreLanguagesButtons);
          }
        }
      );
  }

  addTranslation(lang) {
    this.store.dispatch(sendEmailActions.addTranslation({ lang: lang.lang }));
  }
  updateSelectedLanguage(event) {
    this.changeLanguageEvent.emit(event.lang);
  }

  removeTranslation(lang) {
    this.store.dispatch(
      sendEmailActions.removeTranslation({ lang: lang.lang })
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
