/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
 */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sortBy" })
export class SortByPipe implements PipeTransform {
  transform(array: any, sortyField: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    try {
      const fields = sortyField.split(",");
      array.sort((a: any, b: any) => {
        let priority = 0;
        let pow = fields.length;
        for (var field of fields) {
          let val1 = a[field],
            val2 = b[field];
          if (typeof val1 == "string") {
            val1 = val1.toLowerCase();
            val2 = val2.toLowerCase();
          }

          if (val1 < val2) {
            priority -= Math.pow(10, pow);
          } else if (val1 > val2) {
            priority += Math.pow(10, pow);
          } else {
          }

          pow--;
        }

        // s

        if (priority < 0) return -1;
        else if (priority > 0) return 1;
        else return 0;
      });
      return array;
    } catch (e) {
      console.log(e);
    }
  }
}
