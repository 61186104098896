import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { sendEmailActions, sendEmailSelectors } from "../../store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SendEmailComponent } from "../send-email/send-email.component";

import { ConfirmActionDialogComponent } from "../../../../views/partials/content";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: "app-send-email-send-button",
  template: ` <div class="send-email-send-button">
    <button
      mat-raised-button
      class="btn btn-outline-primary reset-button-styles info-outline-button"
      (click)="sendEmail()"
      matTooltip="{{ 'SEND_EMAIL.TITLE' | translate }}"
    >
      <i class="la la-paper-plane"></i>
      <span class="kt-hidden-mobile">
        {{ "SEND_EMAIL.SEND" | translate }}
      </span>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="sendEmail()" mat-menu-item>
        {{ "SEND_EMAIL.SEND" | translate }}
      </button>
      <button (click)="sendTestEmail()" mat-menu-item>
        {{ "SEND_EMAIL.SEND_TEST_EMAIL" | translate }}
      </button>
    </mat-menu>
  </div>`,
  styles: [
    `
      .send-email-send-button {
        display: flex;
        align-items: center;
        width: 150px;
      }
    `,
  ],
})
export class SendEmailSendButton implements OnInit, OnDestroy {
  constructor(private confirmDialog: MatDialog) {}
  store = inject(Store);
  isFormValid$ = this.store.pipe(select(sendEmailSelectors.selectIsFormValid));
  _isFormValid = false;
  destroyed$ = new Subject<void>();
  participantsCount = 0;
  participantsCount$ = this.store.pipe(
    select(sendEmailSelectors.selectParticipantsCount)
  );

  ngOnInit() {
    this.isFormValid$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isFormValid: any) => {
        this._isFormValid = isFormValid;
      });

    this.participantsCount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((count: any) => (this.participantsCount = count));
  }

  sendEmail() {
    if (this._isFormValid) {
      let dialogRef = this.confirmDialog.open(ConfirmActionDialogComponent, {
        data: {
          title: "SEND_EMAIL.ARE_YOU_SURE",
          message: `You are sending ${this.participantsCount} new emails.`,
        },
        width: "400px",
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.store.dispatch(sendEmailActions.sendEmail());
        }
      });
    }
  }

  sendTestEmail() {
    if (this._isFormValid) {
      let dialogRef = this.confirmDialog.open(ConfirmActionDialogComponent, {
        data: {
          title: "Attention",
          message: `You are sending a test email. Are you sure?`,
        },
        width: "400px",
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.store.dispatch(sendEmailActions.sendTestEmail());
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
