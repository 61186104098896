<app-template-details-language
  [selectedLanguage]="selectedLanguage$ | async"
  (changeLanguageEvent)="changeLanguageEventHandler($event)"
></app-template-details-language>

<form
  *ngIf="isAnyTemplateSelected$ | async"
  [formGroup]="templateForms"
  class="mt-2"
  autocomplete="off"
>
  <ng-container *ngFor="let form of templateForms.controls; index as i">
    <div
      [ngClass]="
        form.controls['lang'].value === (selectedLanguage$ | async)
          ? 'show'
          : 'hidden'
      "
    >
      <div class="custom-field-holder">
        <app-send-email-send-button></app-send-email-send-button>
        <mat-form-field
          class="mat-form-field-fluid w-100"
          appearance="fill"
          floatLabel="always"
        >
          <mat-label>{{ "SEND_EMAIL.SUBJECT" | translate }}</mat-label>
          <input
            matInput
            type="text"
            [formControl]="form.controls['subject']"
            autocomplete="off"
          />
          <mat-error *ngIf="form.controls['subject'].hasError('required')">
            {{ "SEND_EMAIL.SUBJECT_MESSAGE" | translate}}
            <strong>{{ "SEND_EMAIL.MANDATORY_MESSAGE" | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <app-preview-image [image]="headerImage$ | async"></app-preview-image>

      <div class="mt-4">
        <ckeditor
          [editor]="templateBodyEditor"
          [formControl]="form.controls['template']"
          name="myCkeditor"
        >
        </ckeditor>
        <mat-error
          class="custom-error"
          *ngIf="form.controls['template'].hasError('required')"
        >
          {{ "SEND_EMAIL.BODY_MESSAGE" | translate }}
          <strong>{{ "SEND_EMAIL.BODY_MESSAGE" | translate }}</strong>
        </mat-error>
      </div>
      <div class="divider"></div>
      <app-preview-signature
        [signature]="signature$ | async"
      ></app-preview-signature>
      <div class="divider"></div>
      <app-preview-image [image]="footerImage$ | async"></app-preview-image>
    </div>
  </ng-container>
</form>
