import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";

@Component({
  selector: "app-preview-body",
  templateUrl: "./preview-body.component.html",
  styleUrls: ["./preview-body.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewBodyComponent implements OnInit {
  @Input() body: string;    
  constructor() {}

  ngOnInit(): void {}
}
