import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ProjectManagementRoutingModule } from "./project-management-routing.module";
import { ProjectManagementComponent } from "./project-management.component";
import { KanbanBoardComponent } from "./components/kanban-board/kanban-board.component";
import { KanbanAllModule } from "@syncfusion/ej2-angular-kanban";
import { GridModule } from "@syncfusion/ej2-angular-grids";
import { Store, StoreModule } from "@ngrx/store";
import { kanbanReducer, KANBAN_FEATURE } from "./store/default.reducer";
import { KanbanStoreModel } from "./store/default.model";
import { KanbanProjectComponent } from "./components/kanban-project/kanban-project.component";
import { EffectsModule } from "@ngrx/effects";
import { DefaultEffect } from "./store/default.effects";
import { KanbanBoardFormComponent } from "./components/kanban-board-form/kanban-board-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { KanbanProjectFormComponent } from "./components/kanban-project-form/kanban-project-form.component";
import { MaterialModule } from "../material/material.module";

@NgModule({
  declarations: [
    ProjectManagementComponent,
    KanbanBoardComponent,
    KanbanProjectComponent,
    KanbanBoardFormComponent,
    KanbanProjectFormComponent,
  ],
  imports: [
    CommonModule,
    ProjectManagementRoutingModule,
    KanbanAllModule,
    GridModule,
    EffectsModule.forFeature([DefaultEffect]),
    StoreModule.forFeature(KANBAN_FEATURE, kanbanReducer),
    ReactiveFormsModule,
    MaterialModule,
  ],
})
export class ProjectManagementModule {
  constructor(private store: Store<KanbanStoreModel>) {
    //this.store.dispatch(ACTIONS.projects.GetAll())
  }
}
