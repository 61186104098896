<div class="kt-form">
  <mat-tab-group class="custom-tabs">
    <mat-tab label="Change Password">
      <mat-card class="custom-mat-card">
        <form [formGroup]="changePasswordForm" class="form-group kt-form__group">
          <mat-form-field class="mat-form-field-fluid custom-input custom-top-margin">
            <mat-label>Current Password</mat-label>
            <input formControlName="oldPassword" matInput />
          </mat-form-field>

          <mat-form-field class="mat-form-field-fluid custom-input custom-top-margin">
            <mat-label>New Password</mat-label>
            <input formControlName="newPassword" matInput />
          </mat-form-field>

          <mat-form-field class="mat-form-field-fluid custom-input custom-top-margin">
            <mat-label>Confirm New Password</mat-label>
            <input formControlName="confirmedNewPassword" matInput />
          </mat-form-field>

          <div class="kt-login__actions">
            <button (click)="changePassword()" id="kt_login_signin_submit" class="btn danger-button custom-button">{{
              'COMMON.SAVE' | translate
              }}</button>
          </div>
        </form>
      </mat-card>
    </mat-tab>
    <mat-tab label="Two Factor Settings">
      <div class="custom-flex">
        <ng-container *ngIf="(hasTwoFactor$ | async) === false">
          <mat-card class="custom-mat-card">
            <form class="form-group kt-form__group">
              <div class="custom-title">
                <div>
                  Status
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="kt-login__actions">
                <button id="kt_login_signin_submit" class="btn danger-button custom-button"
                  (click)="activate()">Activate</button>
              </div>
              <ng-container *ngIf="shouldConfirm$ | async">
                <form [formGroup]="twoFactorConfirmationForm" class="form-group kt-form__group">
                  Two Factor Authentication is not active.                  
                  <div class="custom-qr-code-confirmation">
                    <div #qrCodeImageConfirmation>
                    </div>
                    <mat-form-field style="width: 320px;" class="mat-form-field-fluid custom-input custom-top-margin">
                      <mat-label>Please scan the above QR code and enter in below:</mat-label>
                      <input formControlName="code" matInput />
                    </mat-form-field>
                    <div class="kt-login__actions">
                      <button id="kt_login_signin_submit" class="btn danger-button custom-button"
                        (click)="confirmTwoFactor()">Confirm</button>
                    </div>
                  </div>
                </form>
              </ng-container>
            </form>
          </mat-card>
        </ng-container>

        <ng-container *ngIf="(hasTwoFactor$ | async) === true">
          <mat-card class="custom-mat-card">
            <form class="form-group kt-form__group">
              <div class="custom-title">
                <div>
                  Status
                </div>
              </div>
              <mat-divider></mat-divider>
              <form class="form-group kt-form__group">
                Two Factor Authentication is activated.
                <div class="kt-login__actions">
                  <button id="kt_login_signin_submit" class="btn danger-button custom-button"
                    (click)="deactivate()">Deactivate</button>
                </div>
              </form>
            </form>
          </mat-card>
        </ng-container>

        <ng-container *ngIf="(hasTwoFactor$ | async) === true">
          <mat-card class="custom-mat-card">
            <form class="form-group kt-form__group">
              <div class="custom-title">
                <div>
                  Recovery Codes
                </div>
              </div>
              <mat-divider></mat-divider>
              <mat-list *ngIf="recoveryCodes$ | async as recoveryCodes">
                <mat-list-item *ngFor="let recoveryCode of recoveryCodes">{{recoveryCode}}
                  <button style="margin-left: 4px;" mat-icon-button [cdkCopyToClipboard]="recoveryCode">
                    <i class="fa fa-copy"></i>
                  </button>
                </mat-list-item>

              </mat-list>
            </form>
          </mat-card>

          <mat-card class="custom-mat-card">
            <form class="form-group kt-form__group">
              <div class="custom-title">
                <div>
                  QR Code
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="kt-login__actions">
                <button id="kt_login_signin_submit" (click)="loadQrCode()"
                  class="btn danger-button custom-button">Show</button>
                <div class="custom-qr-code">
                  <div #qrCodeImage>

                  </div>
                </div>
              </div>

            </form>
          </mat-card>
        </ng-container>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>