import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";

@Component({
  selector: "user-address-form",
  templateUrl: "./user-address-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAddressFormComponent implements OnInit {
  apiUrl: string;
  constructor() {}

  ngOnInit() {
    this.apiUrl = ApiConfig.UserAddressURL();
  }
}
