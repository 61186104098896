<!-- begin:: Page -->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <div
    *ngIf="loadingIndicatorService.loading"
    class="page-loader-wrapper"
    style="position: fixed; z-index: 9999; width: 100%"
  >
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <!-- <div class="loading-screen-wrapper" *ngIf="isPageLoader">
		<div class="loading-screen-icon">
		  Loading...
		</div>
	</div> -->

  <!-- begin:: Header Mobile -->
  <kt-header-mobile></kt-header-mobile>
  <!-- end:: Header Mobile -->

  <div
    class="kt-grid kt-grid--hor kt-grid--root"
    [ngClass]="{ 'kt-no-aside': !asideDisplay }"
  >
    <!-- begin::Body -->
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <!-- begin:: Aside Left -->
      <ng-container class="kt-aside-left">
        <button class="kt-aside-close" id="kt_aside_close_btn">
          <i class="la la-close"></i>
        </button>
        <kt-aside-left (onPageLoader)="pageLoader($event)"></kt-aside-left>
      </ng-container>
      <!-- end:: Aside Left -->

      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <!-- begin:: Header -->
        <kt-header *ngIf="desktopHeaderDisplay"></kt-header>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <mat-sidenav-container class="example-container">
          <mat-sidenav
            #drawer
            class="help-sidebar"
            position
            mode="side"
            position="end"
            fixedInViewport
            fixedTopGap="95"
            [opened]="sidebarStatus$ | async"
          >
            <cb-help-viewer></cb-help-viewer>
          </mat-sidenav>
          <mat-sidenav-content>
            <div class="main-wrapper">
              <div
                class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor flex-grow-1"
                id="kt_content"
                [ngClass]="{
                  'kt-content--fit-top': fitTop,
                  'has-menu-horizontal': desktopHeaderDisplay
                }"
              >
                <!-- begin:: Content Head -->
                <kt-subheader *ngIf="subheaderDisplay"></kt-subheader>
                <!-- end:: Content Head -->
                <!-- begin:: Content Body -->
                <div
                  ktContentAnimate
                  class="kt-container kt-grid__item kt-grid__item--fluid"
                  style="padding: 12px"
                  [ngClass]="{ 'kt-container--fluid': fluid }"
                >
                  <router-outlet></router-outlet>
                </div>
                <!-- end:: Content Body -->
              </div>
              <kt-footer></kt-footer>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>

        <!-- end:: Content -->
      </div>
    </div>
    <!-- end:: Body -->

    <!-- kt-quick-panel></kt-quick-panel -->
    <kt-scroll-top></kt-scroll-top>
  </div>
</ng-container>
<!-- end:: Page -->

<ng-template #blankLayout>
  <router-outlet></router-outlet>
</ng-template>
