import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { KanbanBoardModel, KanbanProjectModel, KanbanTaskModel, ResponseContainer } from "./default.model";


const baseUrl = 'http://localhost:8000/api/v1'
const baseUrlExtention = 'pm'



const routes = {
    baseProjects: 'projects',
    baseBoards: 'boards',
    baseTasks: 'tasks',
    getAllKanbanProjects: 'projects',
    getBoardsByProjectId: 'projects',
}
@Injectable({
    providedIn: "root",
})

export class KanbanService {
    constructor(private http: HttpClient) {
    }

    getBaseUrl = (url: string) => {
        return `${baseUrl}/${baseUrlExtention}/${url}`;
    }

    createProject = (payload: any, url: string = routes.baseProjects) => {
        const targetUrl = `${url}`
        return this.http.post<KanbanProjectModel>(this.getBaseUrl(targetUrl), payload);        
    }

    createBoard = (payload: any, url: string = routes.baseBoards) => {
        const targetUrl = `${url}`
        return this.http.post<KanbanBoardModel>(this.getBaseUrl(targetUrl), payload);        
    }

    getBoardsByProjectId = (id: number, url: string = routes.getBoardsByProjectId): Observable<KanbanBoardModel[]> => {        
        const targetUrl = `${url}/${id}/boards`
        return this.http.get<KanbanBoardModel[]>(this.getBaseUrl(targetUrl));
    };

    getAllKanbanProjects = (url: string = routes.getAllKanbanProjects): Observable<KanbanProjectModel[]> => {      
        return this.http.get<KanbanProjectModel[]>(this.getBaseUrl(url));
    };

    getTasksByBoardId = (id: number, url: string = routes.baseBoards): Observable<KanbanTaskModel[]> => {
        const targetUrl = `${url}/${id}/tasks`    
        return this.http.get<KanbanTaskModel[]>(this.getBaseUrl(targetUrl));
    };

    changeTaskState = (id: number, state: string, url: string = routes.baseTasks): Observable<KanbanTaskModel> => {
        const targetUrl = `${url}/${id}/status/${state}`    
        return this.http.put<KanbanTaskModel>(this.getBaseUrl(targetUrl), null);
    };

    updateTaskById = (id: number, payload: any, url: string = routes.baseTasks): Observable<KanbanTaskModel> => {
        const targetUrl = `${url}/${id}`    
        return this.http.put<KanbanTaskModel>(this.getBaseUrl(targetUrl), payload);
    };
}