<div
  class="kt-portlet"
  [ngClass]="{
    'kt-portlet--body-progress kt-portlet--body-progress-overlay':
      (staticTextLoading$ | async)
  }"
  style="min-height: 500px"
>
  <ng-container *ngIf="staticTextLoading$ | async">
    <kt-portlet-header
      [title]="getTitle()"
      [class]="'kt-portlet__head--lg'"
    ></kt-portlet-header>
    <kt-portlet-body>
      <div
        class="kt-portlet__body-progress"
        style="top: 50%; margin-top: -10px"
      >
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    </kt-portlet-body>
  </ng-container>

  <ng-container *ngIf="!(staticTextLoading$ | async)">
    <kt-portlet-header
      [title]="getTitle()"
      [class]="'kt-portlet__head--lg text-nowrap'"
    >
      <ng-container
        ktPortletAlerts
        *ngIf="form && form.dirty && !hideUnsavedChangesWarning"
      >
        <kt-alert
          type="warning"
          [showCloseButton]="true"
          class="flex-grow-1 mt-3 ml-3"
          (close)="hideUnsavedChangesWarning = true"
        >
          {{ "TRANSLATOR.WARNING_UNSAVED_CHANGES" | translate }}
        </kt-alert>
      </ng-container>

      <ng-container ktPortletTools>
        <ng-container *ngIf="customActionBtns">
          <ng-container
            *ngTemplateOutlet="customActionBtns; context: { $implicit: data }"
          >
          </ng-container>
        </ng-container>

        <div
          class="text-nowrap"
          [ngClass]="{ invisible: (isSubFormShown$ | async) }"
        >
          <ng-container *ngIf="!readonly; else readonlyActions">
            <a
              (click)="backClicked()"
              *ngIf="listUrl === '/service-management/additional-services'"
              class="btn btn-outline-primary kt-margin-r-10 reset-button-styles danger-outline-button"
              mat-raised-button
              matTooltip="{{
                staticText.translations[locale || 'en'][staticText.texts.back]
              }}"
              [ngClass]="{ disabled: (formSaving$ | async) }"
              [attr.data-pgm-formbutton]="'formbutton_back'"
            >
              <i class="la la-times-circle"></i>
              <span class="kt-hidden-mobile">{{ 'COMMON.CLOSE' | translate }}</span>
            </a>
            <a
              (click)="backClicked()"
              *ngIf="listUrl !== '/service-management/additional-services'"
              class="btn btn-outline-primary kt-margin-r-10 reset-button-styles danger-outline-button"
              mat-raised-button
              matTooltip="{{ 'COMMON.CLOSE' | translate }}"
              [ngClass]="{ disabled: (formSaving$ | async) }"
              [attr.data-pgm-formbutton]="'formbutton_back'"
            >
              <i class="la la-times-circle"></i>
              <span class="kt-hidden-mobile">
                {{ "COMMON.CLOSE" | translate }}
              </span>
            </a>

            <ng-container *ngIf="config && config.type != 'form_wizard'">
              <!-- button type="button" class="
                  btn btn-outline-primary
                  kt-margin-r-10
                  reset-button-styles
                  warning-outline-button
                " mat-raised-button matTooltip="{{
                  staticText.translations[locale || 'en'][
                    staticText.texts.reset
                  ]
                }}" [disabled]="(form && form.pristine) || (formSaving$ | async)" (click)="reset()"
                [attr.data-pgm-formbutton]="'formbutton_reset'">
                <i class="la la-cog"></i>
                <span class="kt-hidden-mobile">{{
                  staticText.translations[locale || "en"][
                  staticText.texts.reset
                  ]
                  }}</span>
              </button -->

              <a
                href="javascript:;"
                class="btn btn-outline-primary kt-margin-r-10 reset-button-styles success-outline-button"
                (click)="onSubmit(false)"
                mat-raised-button
                matTooltip="{{
                  staticText.translations[locale || 'en'][
                    staticText.texts.saveContinue
                  ]
                }}"
                [ngClass]="{ disabled: (formSaving$ | async) }"
                [attr.data-pgm-formbutton]="'formbutton_save'"
              >
                <span *ngIf="formSaving$ | async">
                  <i class="fa fa-circle-o-notch fa-spin p-0"></i> Saving...
                </span>

                <span *ngIf="!(formSaving$ | async)">
                  <i class="la la-save"></i>
                  <span class="kt-hidden-mobile">{{
                    staticText.translations[locale || "en"][
                      staticText.texts.save
                    ]
                  }}</span>
                </span>
              </a>

              <!-- Info icon -->
              <ng-container *ngIf="infoText && infoText[tabName]">
                <button
                  type="button"
                  *ngIf="!infoText[tabName].showAlert"
                  mat-icon-button
                  (click)="infoText[tabName].showAlert = true"
                  class="info-toggle-btn text-secondary cb-icon-button"
                  [matTooltip]="'Show Info' | translate"
                >
                  <i
                    class="far fa-question-circle text-info info-help-icon"
                  ></i>
                </button>
              </ng-container>

              <!-- Context Menu -->
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                class="menu-icon cb-icon-button"
                [disabled]="formSaving$ | async"
                [attr.data-pgm-formbutton]="'formbutton_more'"
              >
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  color="primary"
                  (click)="onSubmit(true)"
                  [attr.data-pgm-formbutton]="'formbutton_save_exit'"
                >
                  <i class="fas fa-save"></i>
                  <span class="pl-2">
                    {{
                      staticText.translations[locale || "en"][
                        staticText.texts.saveExit
                      ]
                    }}
                  </span>
                </button>

                <ng-container
                  *ngIf="processButtons && processButtons.length > 0"
                >
                  <mat-divider></mat-divider>
                  <ng-container *ngFor="let processButton of processButtons">
                    <ng-container
                      *ngIf="
                        processButton.processType == 'action' ||
                        processButton.processType == 'both'
                      "
                    >
                      <button
                        mat-menu-item
                        class="col-action-btn custom-icon-button"
                        (click)="processButton.process([data])"
                        [disabled]="
                          processButton.isDisabled(data) ||
                          processButton.isProcessing(data)
                        "
                      >
                        <ng-container
                          *ngIf="
                            processButton.isProcessing(data);
                            else btnTitle
                          "
                        >
                          <div class="d-flex">
                            <mat-spinner
                              [diameter]="18"
                              style="margin: 0 5px"
                            ></mat-spinner>
                          </div>
                        </ng-container>

                        <ng-template #btnTitle>
                          <i
                            *ngIf="processButton.icon"
                            class="{{ processButton.getIconClass(data) }}"
                          ></i>
                        </ng-template>

                        <span class="pl-2">{{
                          getConfigTranslate(processButton.title)
                        }}</span>
                      </button>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="hasMoreActions">
                  <mat-divider></mat-divider>
                  <ng-content select="[formMenuMoreActions]"></ng-content>
                </ng-container>

                <ng-container *ngIf="canDelete()">
                  <mat-divider></mat-divider>
                  <button
                    mat-menu-item
                    class="col-action-btn custom-icon-button"
                    matTooltip="Delete {{ title }}"
                    (click)="deleteItem()"
                  >
                    <i class="fas fa-trash text-danger"></i>
                    <span class="pl-2 text-danger">{{
                      "Delete" | translate
                    }}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>
          </ng-container>

          <ng-template #readonlyActions>
            <ng-container *ngIf="!dialogRef">
              <button
                type="button"
                class="btn btn-outline-primary kt-margin-r-10 reset-button-styles danger-outline-button"
                mat-raised-button
                (click)="dialogRef.close(null)"
                *ngIf="data.cancelBtn !== false"
              >
                <i class="la la-times-circle"></i>
                {{ "COMMON.CLOSE" | translate }}</button
              >&nbsp;
            </ng-container>

            <ng-container *ngIf="dialogRef">
              <button
                type="button"
                class="btn btn-outline-primary kt-margin-r-10 reset-button-styles danger-outline-button"
                mat-raised-button
                (click)="dialogRef.close()"
              >
                <i class="la la-arrow-left"></i>
                {{ "COMMON.CANCEL" | translate }}</button
              >&nbsp;

              <button
                type="button"
                class="btn btn-outline-primary kt-margin-r-10 reset-button-styles success-outline-button"
                mat-raised-button
                (click)="dialogRef.close(data)"
              >
                <i class="fas fa-check"></i>
                {{ "COMMON.OK" | translate }}
              </button>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
    </kt-portlet-header>
    <form
      *ngIf="config && form"
      class="kt-form"
      [ngClass]="{ 'readonly-form': readonly }"
      [formGroup]="form"
    >
      <div class="kt-portlet__body">
        <!-- <div class="kt-portlet__body-progress">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div> -->

        <!-- <button type="button" (click)="debug()">Debug [{{ (isSubFormShown$ | async) ? 'shown' : 'hidden' }}]</button> -->

        <kt-alert
          *ngIf="hasFormErrors"
          type="warn"
          [duration]="30000"
          [showCloseButton]="true"
          (close)="onAlertClose($event)"
        >
          {{ "TRANSLATOR.INFO_FORM_ERRORS" | translate }}
        </kt-alert>

        <ng-container *ngIf="getInfoTextVisibilityState(tabName)">
          <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button> -->

          <div class="info-text-wrap">
            <kt-alert
              *ngIf="infoText[tabName].showAlert !== false"
              type="{{ infoText[tabName].color }}"
              (close)="infoText[tabName].showAlert = false"
            >
              <div [innerHTML]="getInfoText(tabName)"></div>
            </kt-alert>
          </div>
        </ng-container>

        <ng-container *ngIf="config.type == 'form_wizard'; else tabGroup">
          <!--begin: Wizard -->
          <div
            #wizard
            class="kt-grid kt-wizard-v3 kt-wizard-v3--white"
            id="kt_wizard_v3"
            data-ktwizard-state="step-first"
          >
            <div class="kt-grid__item">
              <!--begin: Form Wizard Nav -->
              <div class="kt-wizard-v3__nav">
                <div class="kt-wizard-v3__nav-items px-0">
                  <ng-container
                    *ngFor="let tab of config.tabs; let stepIndex = index"
                  >
                    <a
                      class="kt-wizard-v3__nav-item"
                      href="javascript:;"
                      data-ktwizard-type="step"
                      [attr.data-ktwizard-state]="
                        stepIndex == 0 ? 'current' : null
                      "
                    >
                      <div class="kt-wizard-v3__nav-body">
                        <div class="kt-wizard-v3__nav-label">
                          <span>{{ stepIndex + 1 }}</span>
                          {{ getTabCaption(tab) }}
                        </div>
                        <div class="kt-wizard-v3__nav-bar"></div>
                      </div>
                    </a>
                  </ng-container>
                </div>
              </div>
              <!--end: Form Wizard Nav -->
            </div>
            <div
              class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper px-2 justify-content-start"
              style="flex-direction: column"
            >
              <!--begin: Form Wizard Form-->
              <div class="kt-form pb-4" id="kt_form">
                <div
                  class="kt-form__actions p-0 previous"
                  [style.display]="(isSubFormShown$ | async) ? 'none' : 'flex'"
                >
                  <button
                    type="button"
                    data-ktwizard-type="action-prev"
                    class="btn btn-secondary btn-md btn-tall kt-font-bold kt-font-transform-u"
                    [disabled]="formSaving$ | async"
                    [attr.data-pgm-formbutton]="'formbutton_previous_step'"                    
                  >
                    <i class="fa fa-chevron-left"></i>
                  </button>
                </div>

                <!--begin: Form Wizard Steps-->
                <ng-container
                  *ngFor="let tab of config.tabs; let stepIndex = index"
                >
                  <div
                    class="kt-wizard-v3__content"
                    data-ktwizard-type="step-content"
                    [attr.data-ktwizard-state]="
                      stepIndex == 0 ? 'current' : null
                    "
                  >
                    <div class="row">
                      <div
                        [ngClass]="
                          tab.show_step_summary !== false
                            ? 'col-md-12 col-lg-8'
                            : 'col'
                        "
                      >
                        <div
                          class="kt-heading kt-heading--md"
                          *ngIf="!tab.table"
                        >
                          {{ tab.subCaption || getTabCaption(tab) }}
                        </div>

                        <ng-container
                          *ngIf="
                            formWizardApi &&
                            formWizardApi.currentStep == stepIndex + 1
                          "
                        >
                          <ng-container
                            *ngIf="getInfoTextVisible(tab.infotext)"
                          >
                            <kt-alert type="{{ tab.infotext.color }}">
                              <div
                                [innerHTML]="getInfoTextHtml(tab.infotext)"
                              ></div>
                            </kt-alert>
                          </ng-container>

                          <div class="kt-form__section kt-form__section--first">
                            <div
                              class="kt-wizard-v3__form"
                              [ngClass]="{ 'mt-0': tab.table }"
                            >
                              <ng-container
                                *ngTemplateOutlet="
                                  formFields;
                                  context: { $implicit: tab }
                                "
                              ></ng-container>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div
                        class="col-md-12 col-lg-4"
                        *ngIf="tab.show_step_summary !== false"
                      >
                        <ng-container
                          *ngIf="
                            formWizardApi &&
                            formWizardApi.currentStep == stepIndex + 1
                          "
                        >
                          <ng-container *ngIf="tab.show_step_summary !== false">
                            <div class="py-5">
                              <h4 class="mb-5">{{ "Summary" | translate }}</h4>
                              <html-content
                                [apiUrl]="wizardStepSummaryApiUrl$ | async"
                              ></html-content>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!--end: Form Wizard Steps-->
                <div
                  class="kt-form__actions p-0 next"
                  [style.display]="(isSubFormShown$ | async) ? 'none' : 'flex'"
                >
                  <button
                    type="button"
                    data-ktwizard-type="action-next"
                    class="btn btn-danger btn-md btn-tall kt-font-bold kt-font-transform-u"
                    [disabled]="(formSaving$ | async) || (pageLoading$ | async)"
                    [attr.data-pgm-formbutton]="'formbutton_next_step'"                    
                  >
                    <i class="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>

              <!--begin: Form Actions -->
              <div
                class="submit-button-container p-0"
                [style.display]="(isSubFormShown$ | async) ? 'none' : 'flex'"
              >
                <button
                  (click)="onSubmit()"
                  data-ktwizard-type="action-submit"
                  class="btn btn-danger btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                  [disabled]="(formSaving$ | async) || (pageLoading$ | async)"
                  [attr.data-pgm-formbutton]="'formbutton_submit'"
                  [ngStyle]="{'display': getCriteriaToShowSubmitButton() ? 'block' : 'none'}"
                >                                
                  Submit
                </button>
              </div>
              <!--end: Form Actions -->
              <!--end: Form Wizard Form-->
            </div>
          </div>
        </ng-container>

        <ng-template #tabGroup>
          <mat-tab-group
            [(selectedIndex)]="selectedTab"
            animationDuration="0ms"
            class="custom-tabs"
            [ngClass]="{ 'header-less-tabs': config.tabs.length === 1 }"
            (selectedTabChange)="selectedTabChange($event)"
          >
            <ng-container *ngFor="let tab of config.tabs; let tabIndex = index">
              <mat-tab
                [disabled]="
                  tab.disabled ||
                  getTabAttribute(tab.name, 'disabled') ||
                  (isSubFormShown$ | async)
                "
                *ngIf="!getTabAttribute(tab.name, 'hidden')"
              >
                <ng-template class="custom-tab" mat-tab-label>
                  <div
                    (click)="getTabName(tab.name)"
                    [attr.data-pgm-tab]="tab.name"
                  >
                    <i
                      *ngIf="hasTriedToSubmitForm && !getTabValid(tab)"
                      class="fas fa-exclamation-circle text-danger"
                    ></i>
                    {{ getTabCaption(tab) }}
                  </div>
                </ng-template>

                <ng-container *ngIf="tab.activated || tabIndex == 0">
                  <!-- <ng-container
                    *ngIf="
                      name == 'cb-locations' && tab.name == 'variants';
                      else customDropzoneTab
                    "
                  >
                    <kt-variant></kt-variant>
                  </ng-container> -->

                  <ng-container
                    *ngIf="
                      tab.type == 'images' || tab.type == 'documents';
                      else defaultTabContentWrap
                    "
                    [ngClass]="{ 'is-invalid': !getTabValid(tab) }"
                  >
                    <custom-dropzone
                      [name]="tab.name"
                      [model]="tab.model"
                      [modelId]="data.id"
                      [type]="tab.type"
                      [types]="tab.types"
                      [maxUploadCount]="tab.maxUploadCount"
                      [readonly]="readonly"
                      [acceptedFileTypes]="tab.acceptedFileTypes"
                      (onInit)="customDropzoneComponents.push($event)"
                    >
                    </custom-dropzone>
                    <div *ngIf="!getTabValid(tab)" class="invalid-feedback">
                      Please upload a file
                    </div>
                  </ng-container>
                  <ng-template #defaultTabContentWrap>
                    <ng-container
                      *ngTemplateOutlet="
                        formFields;
                        context: { $implicit: tab }
                      "
                    ></ng-container>
                  </ng-template>
                </ng-container>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </ng-template>
      </div>
    </form>
  </ng-container>
</div>

<ng-template #formFields let-tab>
  <ng-container
    *ngIf="
      (name == 'trackday-bookings' || name == 'participants-trackday-items') &&
      (tab.name == 'address info' || tab.name == 'invoice address')
    "
  >
    <div class="px-4 text-right">
      <button
        mat-stroked-button
        type="button"
        (click)="loadUserAddressData(tab)"
        [attr.data-pgm-formbutton]="'formbutton_load_user_info'"
      >
        {{ getConfigTranslate("Load User Data") }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="tab.name == 'translations_lang'; else defaultTabContent">
    <mat-tab-group
      [(selectedIndex)]="selectedTranslationTab"
      animationDuration="0ms"
      class="custom-tabs"
    >
      <mat-tab *ngFor="let langTab of translationTabs">
        <ng-template
          class="custom-tab"
          mat-tab-label
          *ngIf="getTranslationLanguage(langTab.lang); let language"
        >
          <span class="kt-nav__link-icon">
            <img src="{{ language.flag }}" style="width: 16px" class="mr-2" />
          </span>
          <span class="kt-nav__link-text">{{ language.name }}</span>

          <button
            type="button"
            mat-icon-button
            aria-label="Remove"
            (click)="deleteTranslation(langTab.lang)"
            *ngIf="langTab.lang != defaultLang"
          >
            <i class="fa fa-times"></i>
          </button>
        </ng-template>

        <ng-template matTabContent>
          <ng-container
            *ngTemplateOutlet="formFields; context: { $implicit: langTab }"
          ></ng-container>
        </ng-template>
      </mat-tab>

      <mat-tab label="Add Translation" disabled>
        <ng-template mat-tab-label>
          <button
            mat-button
            [matMenuTriggerFor]="langMenu"
            (click)="$event.stopPropagation()"
          >
            <span class="text-dark">
              <span class="mr-2"><i class="fa fa-plus"></i></span>
              Add Translation
            </span>
          </button>
          <mat-menu #langMenu="matMenu">
            <ng-container *ngFor="let language of languages">
              <button
                mat-menu-item
                (click)="addTranslation(language.lang, true)"
                [ngClass]="{ 'd-none': isTranslationAdded(language.lang) }"
              >
                <span class="kt-nav__link-icon">
                  <img
                    src="{{ language.flag }}"
                    style="width: 16px"
                    class="mr-2"
                  />
                </span>
                <span class="kt-nav__link-text">{{ language.name }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <ng-template #defaultTabContent>
    <ng-container *ngFor="let field of getTabFields(tab, false)">
      <div [formGroup]="form">
        <input
          type="hidden"
          formControlName="{{ field.name }}"
          [attr.data-pgm-formelement]="'formitem_' + field.name"
        />
      </div>
    </ng-container>

    <div class="px-4">
      <div class="form-group kt-form__group row">
        <ng-container *ngFor="let field of getTabFields(tab)">
          <ng-container *ngIf="getFieldVisibilityState(field)">
            <div
              class="kt-margin-bottom-20-mobile custom-field-holder"
              [ngClass]="getFieldWidthClass(field)"
            >
              <div class="mb-4 text-center" *ngIf="field.language">
                {{ config.translations[locale || "en"][field.language] }}
                {{ config.translations[locale || "de"][field.language] }}
              </div>

              <ng-container
                *ngIf="field.type == 'html_content'; else crudTable"
              >
                <html-content
                  *ngIf="getApiUrl(field.api_url)"
                  [apiUrl]="getApiUrl(field.api_url)"
                  (onBeforeLoad)="showCheckInBtn.next(false)"
                  (onAfterLoad)="showCheckInBtn.next(true)"
                ></html-content>

                <ng-container
                  *ngIf="
                    name == 'trackday-bookings' &&
                    tab.name == 'summary' &&
                    data.participant_status_enum != '__CHECKEDIN__' &&
                    (showCheckInBtn | async)
                  "
                >
                  <div class="text-center">
                    <button
                      class="btn btn-primary custom-mat-button btn-checkin"
                      mat-button
                      (click)="bookingCheckin()"
                    >
                      {{ "Check-in" | translate }}
                    </button>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #crudTable>
                <ng-container *ngIf="field.type == 'crud-table'; else htmlType">
                  <ng-container *ngIf="canLoadCrudTable(field)">
                    <ng-container *ngIf="hasTriedToSubmitForm && field.error">
                      <kt-alert type="warn">
                        <div [innerHTML]="field.error"></div>
                      </kt-alert>
                    </ng-container>

                    <ng-container
                      *ngIf="field.form && field.form.modal; else nonModalCrud"
                    >
                      <crud-table
                        [title]="field.title"
                        [name]="field.name"
                        [configName]="field.table_config"
                        [relatedId]="field.relatedId"
                        [apiUrl]="getApiUrl(field.api_url)"
                        [formConfig]="field.form"
                        [configActionLoadBtns]="field.action_load_buttons"
                        [masterData]="data"
                        [searchFilterParams]="field.search_filter_params"
                        (onCreate)="crudTableOnCreate(field, $event)"
                        (onEdit)="crudTableOnEdit(field, $event)"
                        (onLoadTableList)="
                          field.items = $event.entitySubject.value
                        "
                      ></crud-table>
                    </ng-container>

                    <ng-template #nonModalCrud>
                      <ng-container *ngIf="!field.showForm; else crudForm">
                        <crud-table
                          [title]="field.title"
                          [name]="field.name"
                          [configName]="field.table_config"
                          [relatedId]="field.relatedId"
                          [apiUrl]="getApiUrl(field.api_url)"
                          [readonly]="!field.form"
                          [hasReadonlyActions]="field.has_readonly_actions"
                          [isSubForm]="true"
                          [formConfig]="field.form"
                          [configActionLoadBtns]="field.action_load_buttons"
                          [masterData]="data"
                          [searchFilterParams]="field.search_filter_params"
                          [allowDelete]="field.allow_delete !== false"
                          (onCreate)="crudTableOnCreate(field, $event)"
                          (onEdit)="crudTableOnEdit(field, $event)"
                          (onBeforeLoad)="pageLoading$.next(true)"
                          (onLoadTableList)="
                            pageLoading$.next(false);
                            field.data = $event.entitySubject.value
                          "
                        ></crud-table>
                      </ng-container>

                      <ng-template #crudForm>
                        <crud-form
                          [title]="field.form.title"
                          [name]="field.form.config"
                          [apiUrl]="getApiUrl(field.form.api_url)"
                          [formConfig]="field.form"
                          [readonly]="(field.form || {}).readonly"
                          [initialData]="field.form.parsedInitialData"
                          [isSubForm]="true"
                          (onExit)="crudTableOnExit(field)"
                        ></crud-form>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </ng-template>
              <ng-template #htmlType>
                <div *ngIf="field.type === 'html'; else component">
                  <div [formGroup]="form">
                    <label for="" class="description-ext">
                      <ng-container *ngIf="!!field.tooltip">
                        <span
                          [matTooltip]="getConfigTranslate(field.tooltip)"
                          matTooltipPosition="above"
                        >
                          <i class="far fa-question-circle"></i>
                        </span>
                      </ng-container>

                      {{ getConfigTranslate(field.title) }}
                      <span *ngIf="getFieldValidator(field) === 'required'"
                        >*</span
                      >
                    </label>
                    <ckeditor
                      [editor]="Editor"
                      [data]="formGet(field.name).value"
                      name="myckeditor"
                      (change)="ckeditorGetData(field, $event)"
                      (ready)="onReady($event)"
                      [attr.data-pgm-formelement]="'formitem_' + field.name"
                      [disabled]="checkReadonly(field)"
                    >
                    </ckeditor>
                    <div
                      class="invalid-feedback"
                      [ngClass]="
                        formGet(field.name).invalid &&
                        formGet(field.name).touched
                          ? 'd-block'
                          : 'd-none'
                      "
                    >
                      <div *ngIf="formGet(field.name).hasError('custom')">
                        {{ formGetCustomError(field.name) | translate }}
                      </div>
                      <div *ngIf="!formGet(field.name).hasError('custom')">
                        {{ field.title }} is <strong>required</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template #component>
                <ng-container
                  *ngIf="field.type == 'component'; else noTableTab"
                >
                  <ng-container
                    *ngIf="field.name == 'cancellation-prices-trackday-items'"
                  >
                    <cancellation-prices-trackday-items-table
                      [title]="field.caption"
                      [trackdayItem]="data"
                    >
                    </cancellation-prices-trackday-items-table>
                  </ng-container>
                  <ng-container
                    *ngIf="field.name == 'dynamic-prices-trackday-items'"
                  >
                    <dynamic-prices-trackday-items-table
                      [title]="field.caption"
                      [trackdayItem]="data"
                    >
                    </dynamic-prices-trackday-items-table>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-template #noTableTab>
                <div [formGroup]="form">
                  <ng-container>
                    <ng-container *ngIf="field.type === 'url'">
                      <mat-form-field
                        class="mat-form-field-fluid custom-input"
                        [ngClass]="{ 'with-tooltip': !!field.tooltip }"
                      >
                        <ng-container *ngIf="!!field.tooltip">
                          <span
                            class="custom-field-tooltip"
                            [matTooltip]="getConfigTranslate(field.tooltip)"
                            matTooltipPosition="above"
                          >
                            <i class="far fa-question-circle"></i>
                          </span>
                        </ng-container>

                        <input
                          matInput
                          [placeholder]="getFieldLabel(field)"
                          [formControlName]="field.name"
                          [required]="getFieldValidator(field) == 'required'"
                          [readonly]="field.readonly === true"
                          [attr.data-pgm-formelement]="'formitem_' + field.name"
                          [matTooltip]="getConfigTranslate(field.tooltip)"
                        />
                        <mat-icon
                          matSuffix
                          fontSet="fa"
                          (click)="goToLink(field)"
                          style="font-size: 16px; cursor: pointer"
                          fontIcon="fa-external-link-alt"
                        ></mat-icon>
                      </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="field.lookup; else customMatSelect">
                      <mat-form-field
                        class="mat-form-field-fluid custom-input"
                        [ngClass]="{ 'with-tooltip': !!field.tooltip }"
                      >
                        <ng-container *ngIf="!!field.tooltip">
                          <span
                            class="custom-field-tooltip"
                            [matTooltip]="getConfigTranslate(field.tooltip)"
                            matTooltipPosition="above"
                          >
                            <i class="far fa-question-circle"></i>
                          </span>
                        </ng-container>

                        <input
                          matInput
                          [placeholder]="getFieldLabel(field)"
                          [value]="getLookupDisplayValue(field)"
                          [required]="getFieldValidator(field) == 'required'"
                          [readonly]="true"
                          [attr.data-pgm-formelement]="'formitem_' + field.name"
                          [matTooltip]="getConfigTranslate(field.tooltip)"
                          (click)="lookupUsers(field)"
                          [disabled]="getFieldDisabledState(field)"
                        />

                        <button
                          mat-icon-button
                          matSuffix
                          (click)="lookupUsers(field)"
                          [disabled]="getFieldDisabledState(field)"
                        >
                          <mat-icon>search</mat-icon>
                        </button>

                        <input
                          type="hidden"
                          [formControlName]="field.name"
                          [required]="getFieldValidator(field) == 'required'"
                        />

                        <mat-error
                          *ngIf="!formGet(field.name).hasError('custom')"
                        >
                          {{ getConfigTranslate(field.title) }} is
                          <strong>
                            required
                            <span *ngIf="field.type == 'number'">
                              <ng-container
                                *ngIf="field.min != null || field.max != null"
                              >
                                between
                                <span>{{ getFieldMinValue(field, "--") }}</span>
                                to
                                <span>{{ getFieldMaxValue(field, "--") }}</span>
                              </ng-container>
                            </span>
                          </strong>
                        </mat-error>
                        <mat-error
                          *ngIf="formGet(field.name).hasError('custom')"
                        >
                          {{ formCustomErrors[field.name] }}
                        </mat-error>
                        <mat-hint *ngIf="!checkReadonly(field)" align="start">
                          {{
                            staticText.translations[locale || "en"][
                              staticText.texts.enter
                            ]
                          }}
                          <strong>
                            {{ getConfigTranslate(field.title) }}
                            <span *ngIf="field.type == 'number'">
                              <ng-container
                                *ngIf="field.min != null || field.max != null"
                              >
                                between
                                <span>{{ getFieldMinValue(field, "--") }}</span>
                                to
                                <span>{{ getFieldMaxValue(field, "--") }}</span>
                              </ng-container>
                            </span>
                          </strong>
                        </mat-hint>
                      </mat-form-field>
                    </ng-container>

                    <ng-template #customMatSelect>
                      <ng-container
                        *ngIf="field.dynamic_options; else defaultControl"
                      >
                        <custom-mat-select
                          [name]="field.name"
                          [control]="formGet(field.name)"
                          [title]="field.title"
                          [placeholder]="
                            getFieldLabelPlaceholder(
                              field,
                              field.dynamic_options.loading
                            )
                          "
                          (selectionChange)="fieldOnChange(field, $event)"
                          [required]="getFieldValidator(field) == 'required'"
                          [disabled]="checkReadonly(field)"
                          [tooltip]="getConfigTranslate(field.tooltip)"
                          [multiple]="field.type === 'multiple_select'"
                          [options]="field.dynamic_options.data"
                          [optionValue]="field.dynamic_options.value_field"
                          [optionLabel]="field.dynamic_options.label_field"
                          [customError]="formGetCustomError(field.name)"
                          matFormFieldClass="mat-form-field-fluid custom-input"
                        >
                        </custom-mat-select>
                      </ng-container>

                      <ng-template #defaultControl>
                        <ng-container
                          *ngIf="
                            field.type == 'single_select' ||
                              field.type == 'multi_select';
                            else inputFields
                          "
                        >
                          <custom-mat-select
                            [control]="formGet(field.name)"
                            [title]="field.title"
                            [placeholder]="getFieldLabel(field)"
                            (selectionChange)="
                              getTrackday($event.value, field.name)
                            "
                            [required]="getFieldValidator(field) == 'required'"
                            [disabled]="checkReadonly(field)"
                            [multiple]="field.type === 'multiple_select'"
                            [options]="dependencies[field.name]"
                            optionValue="value"
                            optionLabel="label"
                            [customError]="formGetCustomError(field.name)"
                            [tooltip]="getConfigTranslate(field.tooltip)"
                            matFormFieldClass="mat-form-field-fluid custom-input"
                          >
                          </custom-mat-select>
                        </ng-container>

                        <ng-template #inputFields>
                          <mat-form-field
                            *ngIf="
                              field.type !== 'url' &&
                                field.type !== 'checkbox' &&
                                field.type !== 'heading';
                              else heading
                            "
                            class="mat-form-field-fluid custom-input"
                            [ngClass]="{ 'with-tooltip': !!field.tooltip }"
                            #formTab
                          >
                            <ng-container *ngIf="!!field.tooltip">
                              <span
                                class="custom-field-tooltip"
                                [matTooltip]="getConfigTranslate(field.tooltip)"
                                matTooltipPosition="above"
                              >
                                <i class="far fa-question-circle"></i>
                              </span>
                            </ng-container>

                            <input
                              *ngIf="field.type == 'password'"
                              matInput
                              type="password"
                              [placeholder]="getFieldLabel(field)"
                              formControlName="{{ field.name }}"
                              [required]="
                                getFieldValidator(field) == 'required'
                              "
                              (change)="fieldOnChange(field, $event.value)"
                              [readonly]="checkReadonly(field)"
                              [attr.data-pgm-formelement]="
                                'formitem_' + field.name
                              "
                              [matTooltip]="getConfigTranslate(field.tooltip)"
                            />

                            <ng-container *ngIf="field.type === 'input'">
                              <input
                                matInput
                                [placeholder]="getFieldLabel(field)"
                                [formControlName]="field.name"
                                [required]="
                                  getFieldValidator(field) == 'required'
                                "
                                (change)="fieldOnChange(field, $event.value)"
                                [readonly]="checkReadonly(field)"
                                [attr.data-pgm-formelement]="
                                  'formitem_' + field.name
                                "
                                [matTooltip]="getConfigTranslate(field.tooltip)"
                                [mask]="field.mask || ''"
                                [dropSpecialCharacters]="!field.masked_value"
                              />
                            </ng-container>

                            <input
                              *ngIf="
                                field.type === 'datetime' ||
                                field.type === 'date'
                              "
                              matInput
                              [matDatepicker]="picker"
                              [placeholder]="getFieldLabel(field)"
                              formControlName="{{ field.name }}"
                              [required]="
                                getFieldValidator(field) == 'required'
                              "
                              (dateInput)="
                                checkValidRange(
                                  $event,
                                  tab,
                                  field.name,
                                  field,
                                  field.title
                                );
                                fieldOnChange(field, $event.value)
                              "
                              [attr.data-pgm-formelement]="
                                'formitem_' + field.name
                              "
                              [matTooltip]="getConfigTranslate(field.tooltip)"
                            />
                            <mat-datepicker-toggle
                              *ngIf="
                                field.type === 'datetime' ||
                                field.type === 'date'
                              "
                              matSuffix
                              [for]="picker"
                            >
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>

                            <textarea
                              *ngIf="field.type === 'textarea'"
                              matInput
                              [placeholder]="getFieldLabel(field)"
                              formControlName="{{ field.name }}"
                              [required]="
                                getFieldValidator(field) == 'required'
                              "
                              [attr.data-pgm-formelement]="
                                'formitem_' + field.name
                              "
                            ></textarea>

                            <input
                              type="number"
                              [min]="getFieldMinValue(field)"
                              [max]="getFieldMaxValue(field)"
                              *ngIf="field.type === 'number'"
                              matInput
                              [placeholder]="getFieldLabel(field)"
                              formControlName="{{ field.name }}"
                              (keypress)="onKeyPress($event)"
                              (change)="
                                checkValidRange(
                                  $event,
                                  tab,
                                  field.name,
                                  field,
                                  field.title
                                );
                                fieldOnChange(field, $event.value)
                              "
                              [readonly]="checkReadonly(field)"
                              [required]="
                                getFieldValidator(field) == 'required'
                              "
                              [attr.data-pgm-formelement]="
                                'formitem_' + field.name
                              "
                              [matTooltip]="getConfigTranslate(field.tooltip)"
                            />

                            <input
                              type="number"
                              [min]="getFieldMinValue(field)"
                              [max]="getFieldMaxValue(field)"
                              *ngIf="field.type === 'decimal'"
                              matInput
                              step="0.01"
                              [placeholder]="getFieldLabel(field)"
                              formControlName="{{ field.name }}"
                              (change)="
                                formatDecimal($event, field.decimals || 2);
                                fieldOnChange(field, $event.value)
                              "
                              [readonly]="checkReadonly(field)"
                              [required]="
                                getFieldValidator(field) == 'required'
                              "
                              [attr.data-pgm-formelement]="
                                'formitem_' + field.name
                              "
                              [matTooltip]="getConfigTranslate(field.tooltip)"
                            />

                            <ng-container *ngIf="field.type === 'color_picker'">
                              <input
                                matInput
                                class="input-color-picker"
                                [placeholder]="getFieldLabel(field)"
                                formControlName="{{ field.name }}"
                                [required]="
                                  getFieldValidator(field) == 'required'
                                "
                                [readonly]="field.readonly === true"
                                [cpAlphaChannel]="'always'"
                                [cpOutputFormat]="'hex'"
                                [colorPicker]="formGet(field.name).value"
                                (colorPickerChange)="
                                  formGet(field.name).setValue($event)
                                "
                                [cpUseRootViewContainer]="true"
                                [cpPosition]="'bottom'"
                                autocomplete="new-color"
                                [attr.data-pgm-formelement]="
                                  'formitem_' + field.name
                                "
                                [matTooltip]="getConfigTranslate(field.tooltip)"
                              />

                              <div
                                class="input-color-picker-hint"
                                [style.background]="formGet(field.name).value"
                              ></div>
                            </ng-container>

                            <mat-error
                              *ngIf="!formGet(field.name).hasError('custom')"
                            >
                              {{ getConfigTranslate(field.title) }} is
                              <strong>
                                required
                                <span *ngIf="field.type == 'number'">
                                  <ng-container
                                    *ngIf="
                                      field.min != null || field.max != null
                                    "
                                  >
                                    between
                                    <span>{{
                                      getFieldMinValue(field, "--")
                                    }}</span>
                                    to
                                    <span>{{
                                      getFieldMaxValue(field, "--")
                                    }}</span>
                                  </ng-container>
                                </span>
                              </strong>
                            </mat-error>
                            <mat-error
                              *ngIf="formGet(field.name).hasError('custom')"
                            >
                              {{ formCustomErrors[field.name] }}
                            </mat-error>
                            <mat-hint
                              *ngIf="!checkReadonly(field)"
                              align="start"
                            >
                              {{
                                staticText.translations[locale || "en"][
                                  staticText.texts.enter
                                ]
                              }}
                              <strong>
                                {{ getConfigTranslate(field.title) }}
                                <span *ngIf="field.type == 'number'">
                                  <ng-container
                                    *ngIf="
                                      field.min != null || field.max != null
                                    "
                                  >
                                    between
                                    <span>{{
                                      getFieldMinValue(field, "--")
                                    }}</span>
                                    to
                                    <span>{{
                                      getFieldMaxValue(field, "--")
                                    }}</span>
                                  </ng-container>
                                </span>
                              </strong>
                            </mat-hint>
                          </mat-form-field>
                        </ng-template>
                        <ng-template #heading>
                          <h4 *ngIf="field.type == 'heading'">
                            {{ field.title }}
                          </h4>
                        </ng-template>
                      </ng-template>
                    </ng-template>

                    <div
                      *ngIf="field.type === 'checkbox'"
                      class="mat-form-field-fluid custom-input"
                    >
                      <mat-checkbox
                        class="custom-checkbox"
                        formControlName="{{ field.name }}"
                        (change)="fieldOnChange(field, $event.checked)"
                        [attr.data-pgm-formelement]="'formitem_' + field.name"
                      >
                        {{ getConfigTranslate(field.title) }}

                        <ng-container *ngIf="!!field.tooltip">
                          <span
                            class="pl-1"
                            [matTooltip]="getConfigTranslate(field.tooltip)"
                            matTooltipPosition="above"
                          >
                            <i class="far fa-question-circle"></i>
                          </span>
                        </ng-container>
                      </mat-checkbox>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-template>
