import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AddReplaceDialogResultType } from "../../../../shared/data/models";
@Component({
  selector: "cb-add-replace-modal",
  templateUrl: "./add-replace-modal.component.html",
  styleUrls: ["./add-replace-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddReplaceModalComponent implements OnInit, OnDestroy {
  @ViewChild("target", { read: ViewContainerRef, static: true })
  vcRef: ViewContainerRef;
  componentRef: ComponentRef<any>;
  constructor(
    public dialogRef: MatDialogRef<AddReplaceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.componentRef = this.vcRef.createComponent(this.data.component);
  }
  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  close(action: AddReplaceDialogResultType["action"]) {
    this.dialogRef.close({
      action: action,
      selection: this.componentRef.instance?.selectedItems$?.value ?? [],
    });
  }
}
