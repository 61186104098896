import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  inject,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import { ITab } from "../../../../shared/data/models";
import { FormConfigService } from "../../form.config.service";
import { takeUntil, tap } from "rxjs/operators";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
  selector: "cb-dynamic-tabs-builder",
  template: `
    <div class="kt-form">
      <mat-tab-group
        [selectedIndex]="selectedTab"
        (selectedTabChange)="onTabChange($event)"
        animationDuration="0"
        [ngClass]="{
          'header-less-tabs':
            tabs.length === 1 || (isSubFormShown$ | async) === true
        }"
        class="custom-tabs"
        #tabsEl
        mat-stretch-tabs="false"
        mat-align-tabs="start"
      >
        <mat-tab
          *ngFor="let tab of tabs; let i = index"
          [disabled]="tab.disabled || (isSubFormShown$ | async)"
        >
          <ng-template mat-tab-label>
            <ng-container *ngIf="asyncErrors | tabError : tab.name as error">
              <span [matTooltip]="error[1]" [matTooltipDisabled]="!error[0]">
                <i
                  *ngIf="tabErrors.includes(tab.name)"
                  class="fas fa-exclamation-circle text-danger mr-2"
                ></i>
                {{ tab.display }}
              </span>
            </ng-container>
          </ng-template>
          <cb-lazy-load-component>
            <ng-template #body>
              <cb-tab-content-builder
                *ngIf="!tab.disabled"
                [tab]="tab"
                [forms]="forms"
                [tabErrors]="tabErrors"
                [selectedTab]="_selectedTab$ | async"
              ></cb-tab-content-builder>
            </ng-template>
          </cb-lazy-load-component>
        </mat-tab>
      </mat-tab-group>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTabsBuilderComponent implements OnInit {
  @Input() tabs: ITab[] = [];
  @Input() forms: UntypedFormGroup = null;
  @Input() tabErrors: string[] = [];
  @Input() asyncErrors: { tab: string; message: string }[] = [];
  _selectedTab$ = new BehaviorSubject(0);
  @Input() set resetSelectedTab(value: boolean) {
    if (value) {
      this._selectedTab$.next(0);
      this.selectedTab = 0;
    }
  }
  @ViewChildren("#tabsEl") tabsEl: QueryList<MatTabGroup>;
  @Output() tabChangeEvent = new EventEmitter();
  formConfigService = inject(FormConfigService);
  isSubFormShown$ = new BehaviorSubject(false);
  destroyed$ = new Subject();

  logger$ = combineLatest([
    this.formConfigService.isSubFormShown$,
    this.formConfigService.tabs$,
  ])
    .pipe(
      takeUntil(this.destroyed$),
      tap(([isSubFormShown, tabs]) => {
        if (this.formConfigService.formName === "users") {
          const toLog = [];
          toLog.push(["isSubFormShown", isSubFormShown]);
          toLog.push(["isSubForm", this.formConfigService.isSubForm]);
          toLog.push(["formMode", this.formConfigService.formMode]);
          toLog.push(["apiUrl", this.formConfigService.apiUrl]);
          toLog.push(["listUrl", this.formConfigService.apiUrl]);
          toLog.push(["name", this.formConfigService.formName]);
          toLog.push([
            "initialData",
            JSON.stringify(this.formConfigService.formInitialData),
          ]);
          //   toLog.push(["Tab Status", tabs.map((tab) => tab.disabled).join(",")]);
          //   console.warn("HEADER");
          //   console.table(toLog);
        }
      })
    )
    .subscribe();

  selectedTab = 0;
  constructor() {
    this.formConfigService.isSubFormShown$.subscribe({
      next: (value) => {
        this.isSubFormShown$.next(value);
      },
    });
  }
  ngOnInit(): void {
    const selectedTabIndex = this.tabs.findIndex((tab) => tab.selected);
    if (selectedTabIndex >= 0) {
      this.selectedTab = selectedTabIndex;
      this._selectedTab$.next(selectedTabIndex);
    }
  }

  onTabChange(event) {
    const targetTab = this.tabs[event.index];
    if (targetTab.shouldRefresh) {
    }

    this.tabChangeEvent.emit({
      index: event.index,
      tab: this.tabs[event.index],
    });
    this._selectedTab$.next(event.index);
    this.selectedTab = event.index;
    this.tabsEl.forEach((tab) => {
      tab.realignInkBar();
    });
  }
}
