<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{ 'TRACKDAYS.BOOKINGS.BOOKING_INFO' | translate }}</h3>
		</div>

		<div class="kt-portlet__head-toolbar">
			<button mat-icon-button matTooltip="Close" type="button"
				[mat-dialog-close]>
				<i class="la la-close"></i>
			</button>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">

			<dl class="row">
                <dt class="col-sm-5 d-flex justify-content-between"><span>{{ 'TRACKDAYS.BOOKINGS.NUMBER_PREFIX' | translate }}</span> <span>:</span></dt>
                <dd class="col-sm-7">{{ data.number_prefix }}</dd>

                <dt class="col-sm-5 d-flex justify-content-between"><span>{{ 'TRACKDAYS.BOOKINGS.NUMBER' | translate }}</span> <span>:</span></dt>
                <dd class="col-sm-7">{{ data.number }}</dd>
                
                <dt class="col-sm-5 d-flex justify-content-between"><span>{{ 'TRACKDAYS.BOOKINGS.BOOKING_DATE' | translate }}</span> <span>:</span></dt>
                <dd class="col-sm-7">{{ data.created_at | localDate: 'date' }}</dd>

                <dt class="col-sm-5 d-flex justify-content-between"><span>{{ 'TRACKDAYS.BOOKINGS.STATUS' | translate }}</span> <span>:</span></dt>
                <dd class="col-sm-7">{{ data.status.name }}</dd>

                <dt class="col-sm-5 d-flex justify-content-between"><span>{{ 'TRACKDAYS.BOOKINGS.TYPE' | translate }}</span> <span>:</span></dt>
                <dd class="col-sm-7">{{ data.type.name }}</dd>

                <dt class="col-sm-5 d-flex justify-content-between"><span>{{ 'TRACKDAYS.BOOKINGS.DESCRIPTION' | translate }}</span> <span>:</span></dt>
                <dd class="col-sm-7">{{ data.description }}</dd>
            </dl>

		</div>
	</div>
</div>