// Angular
import { Injectable } from "@angular/core";
// Partials for CRUD
import { ToastrService } from "ngx-toastr";
import {
  ChangeEntityDialogComponent,
  DeleteEntityDialogComponent,
  ExportEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from "../../../views/partials/content";
import { QueryParamsModel } from "../models";
import { MatDialog } from "@angular/material/dialog";

export enum MessageType {
  Create,
  Read,
  Update,
  Delete,
}

export type toastrMessageTypes = "success" | "error" | "warning" | "info";

@Injectable()
export class LayoutUtilsService {
  /**
   * Service constructor
   *
   * @param snackBar: MatSnackBar
   * @param dialog: MatDialog
   */
  constructor(private toastr: ToastrService, private dialog: MatDialog) {}

  showNotification(type: toastrMessageTypes, message: string, title?: string) {
    if (type === "success") {
      this.toastr.success(message, title);
    } else if (type === "error") {
      this.toastr.error(message, title);
    } else if (type === "warning") {
      this.toastr.warning(message, title);
    } else {
      this.toastr.info(message, title);
    }
  }

  /**
   * Showing (Mat-Snackbar) Notification
   *
   * @param message: string
   * @param type: MessageType
   * @param duration: number
   * @param showCloseButton: boolean
   * @param showUndoButton: boolean
   * @param undoButtonDuration: number
   * @param verticalPosition: 'top' | 'bottom' = 'top'
   */
  showActionNotification(
    _message: string,
    _type: MessageType = MessageType.Create,
    _duration: number = 10000,
    _showCloseButton: boolean = true,
    _showUndoButton: boolean = false,
    _undoButtonDuration: number = 3000,
    _verticalPosition: "top" | "bottom" = "bottom"
  ) {
    if (_message.length === 0) {
      // mo message, no snackbar ;)
      return;
    }
    // const _data = {
    // 	message: _message,
    // 	snackBar: this.snackBar,
    // 	showCloseButton: _showCloseButton,
    // 	showUndoButton: _showUndoButton,
    // 	undoButtonDuration: _undoButtonDuration,
    // 	verticalPosition: _verticalPosition,
    // 	type: _type,
    // 	action: 'Undo'
    // };
    // return this.snackBar.openFromComponent(ActionNotificationComponent, {
    // 	duration: _duration,
    // 	data: _data,
    // 	verticalPosition: _verticalPosition
    // });
    this.toastr.info(_message);
  }

  /**
   * Showing Confirmation (Mat-Dialog) before Entity Removing
   *
   * @param title: stirng
   * @param description: stirng
   * @param waitDesciption: string
   */
  deleteElement(
    title: string = "",
    description: string = "",
    waitDesciption: string = ""
  ) {
    return this.dialog.open(DeleteEntityDialogComponent, {
      data: { title, description, waitDesciption },
      width: "440px",
    });
  }

  changeElement(
    title: string = "",
    description: string = "",
    waitDesciption: string = "",
    button: string = ""
  ) {
    return this.dialog.open(ChangeEntityDialogComponent, {
      data: { title, description, waitDesciption, button },
      width: "440px",
    });
  }

  exportAsFile(
    title: string,
    name: string,
    columns: any[],
    queryParams: QueryParamsModel,
    tableConfigService: any
  ) {
    return this.dialog.open(ExportEntityDialogComponent, {
      data: {
        title,
        name,
        columns,
        queryParams,
        cacheName: title,
        tableConfigService,
      },
      width: "440px",
    });
  }

  /**
   * Showing Fetching Window(Mat-Dialog)
   *
   * @param _data: any
   */
  fetchElements(_data) {
    return this.dialog.open(FetchEntityDialogComponent, {
      data: _data,
      width: "400px",
    });
  }

  /**
   * Showing Update Status for Entites Window
   *
   * @param title: string
   * @param statuses: string[]
   * @param messages: string[]
   */
  updateStatusForEntities(title, statuses, messages) {
    return this.dialog.open(UpdateStatusDialogComponent, {
      data: { title, statuses, messages },
      width: "480px",
    });
  }
}
