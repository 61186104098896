import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild
} from "@angular/core";
import { CbComponentDirective } from "../../directives/cb-component.directive";

@Component({
  selector: "app-tab-content-component",
  templateUrl: "./tab-content-component.component.html",
  styleUrls: ["./tab-content-component.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentComponentComponent implements OnInit {
  @ViewChild(CbComponentDirective, { static: true })
  componentHost!: CbComponentDirective;
  constructor() {}

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent() {
    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();
    // const componentRef =
    //   viewContainerRef.createComponent<CbDynamicComponentType>(
    //     // this.dynamicComponentService.getComponents()[0].component
    //   );
    //   componentRef.instance.data = {}
  }
}
