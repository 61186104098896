// Angular
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, fromEvent } from 'rxjs';
// NGRX
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
// Auth
import { Address, AuthService } from '../../../../../../core/store';
// State
import { AppState } from '../../../../../../core/store/reducers/app.reducers';
// Layout
import { LayoutUtilsService } from '../../../../../../core/base';
import { CrudTableService } from '../../../../../../services';
import { ApiConfig } from '../../../../../../core/config/api.config';
import { UntypedFormControl } from '@angular/forms';
import { ViewRef } from '@angular/core';
import { SimpleChanges } from '@angular/core';

@Component({
	selector: 'kt-address',
	templateUrl: './address.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent implements OnInit {
	// Public properties
	// Incoming data
	@Input() addressSubject: BehaviorSubject<Address>;
	@Input() address: BehaviorSubject<[]>;
	hasFormErrors = false;
	@Input() addressForm: UntypedFormGroup;
	countries: any;
	@Input() subForm = false;
	@Input() name = '';
	@Input() required: boolean = true;

	/**
	 * Component Costructor
	 *
	 * @param fb: FormBuilder
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param layoutUtilsService: LayoutUtilsService
	 */
	constructor(private fb: UntypedFormBuilder,
		private auth: AuthService,
		private store: Store<AppState>,
		private cdRef: ChangeDetectorRef,
		private layoutUtilsService: LayoutUtilsService,
		private crudTableService: CrudTableService) { }

	 
	ngOnInit() {
		if (!this.addressSubject.value) {
			const newAddress = new Address();
			newAddress.clear();
			this.addressSubject.next(newAddress);
		}

		this.createForm();
		this.addressForm.valueChanges
			.pipe(
				// tslint:disable-next-line:max-line-length
				debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
				distinctUntilChanged(), // This operator will eliminate duplicate values
				tap(() => {
					this.updateAddress();
				})
			)
			.subscribe();

		//country list
		const apiUrl = ApiConfig.CountryUrl();
		this.crudTableService.getAllItems(apiUrl).subscribe(res => {
			this.countries = res;
			this.crefDetectChanges();
		});
	}

	crefDetectChanges() {
		if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
			this.cdRef.detectChanges();
		}
	}

	/**
	 * Init form
	 */
	createForm() {

		if (this.addressForm == null) this.addressForm = this.fb.group([]);

		const formControls = {
			street: [this.addressSubject.value.street, this.required ? Validators.required : undefined],
			number: [this.addressSubject.value.number, this.required ? Validators.required : undefined],
			city: [this.addressSubject.value.city, this.required ? Validators.required : undefined],
			state: [this.addressSubject.value.state],
			country_id: [this.addressSubject.value.country_id ? this.addressSubject.value.country_id.toString() : '', this.required ? Validators.required : undefined],
			zip: [this.addressSubject.value.zip, this.required ? Validators.required : undefined],
			phone: [this.addressSubject.value.phone],
			fax: [this.addressSubject.value.fax],
			mobile: [this.addressSubject.value.mobile],
			web: [this.addressSubject.value.web],
			lang: [this.addressSubject.value.lang],
		};

		for (const [name, control] of Object.entries(formControls)) {
			const controlName = this.getformControlName(name);
			this.addressForm.addControl(this.getformControlName(name), new UntypedFormControl(control[0], control[1] as any));
		}

	}

	getformControlName(name) {
		if (this.name) return `${this.name}.${name}`;
		return name;
	}

	ngOnChanges({ addressSubject }: SimpleChanges) {
		if (addressSubject && !addressSubject.firstChange) {
			this.addressSubject.next(addressSubject.currentValue.value);

			const controls = this.addressForm.controls;
			controls[this.getformControlName('street')].setValue(this.addressSubject.value.street);
			controls[this.getformControlName('number')].setValue(this.addressSubject.value.number);
			controls[this.getformControlName('city')].setValue(this.addressSubject.value.city);
			controls[this.getformControlName('zip')].setValue(this.addressSubject.value.zip);
			controls[this.getformControlName('state')].setValue(this.addressSubject.value.state);
			controls[this.getformControlName('country_id')].setValue(this.addressSubject.value.country_id);
			controls[this.getformControlName('phone')].setValue(this.addressSubject.value.phone);
			controls[this.getformControlName('fax')].setValue(this.addressSubject.value.fax);
			controls[this.getformControlName('mobile')].setValue(this.addressSubject.value.mobile);
			controls[this.getformControlName('web')].setValue(this.addressSubject.value.web);
			controls[this.getformControlName('lang')].setValue(this.addressSubject.value.lang);
		}
	}

	/**
	 * Update address
	 */
	updateAddress() {
		this.hasFormErrors = false;
		const controls = this.addressForm.controls;
		/** check form */
		if (this.addressForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;

			return;
		}

		const newAddress = new Address();
		newAddress.clear();
		newAddress.street = controls[this.getformControlName('street')].value;
		newAddress.number = controls[this.getformControlName('number')].value;
		newAddress.city = controls[this.getformControlName('city')].value;
		newAddress.zip = controls[this.getformControlName('zip')].value;
		newAddress.state = controls[this.getformControlName('state')].value;
		newAddress.country_id = controls[this.getformControlName('country_id')].value;
		newAddress.phone = controls[this.getformControlName('phone')].value;
		newAddress.fax = controls[this.getformControlName('fax')].value;
		newAddress.mobile = controls[this.getformControlName('mobile')].value;
		newAddress.web = controls[this.getformControlName('web')].value;
		newAddress.lang = controls[this.getformControlName('lang')].value;
		this.addressSubject.next(newAddress);
	}

	/**
	 * Close alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
