// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../../core/store/reducers/app.reducers';
import { ApiConfig } from "../../../../../core/config/api.config";
import { Validators } from "@angular/forms";
import { SubheaderService } from '../../../../../core/base';
// Auth

@Component({
	selector: 'trackday-items-table',
	templateUrl: './trackday-items-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackdayItemsTableComponent implements OnInit {

	@Input() userId: number;
	@Input() readonly = false;
	name = 'trackday-items';

	columns = [];
	searchableColumns = [
		'name',
		'id',
		'date_start',
		'date_end',
		'max_participants',
		'price',
		'vat'
	];
	apiUrl: string;
	createButtonLabel = 'Create Trackday Items';

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param router: Router
	 */
	constructor(private store: Store<AppState>, private router: Router, private subheaderService: SubheaderService) {
	}

	 
	ngOnInit() {
		this.apiUrl = ApiConfig.TrackdayItemsURL();
		if (this.userId > 0) this.apiUrl += '?user_id=' + this.userId;

		// Set title to page breadCrumbs
		this.subheaderService.setTitle('Trackday Events');
	}
}
