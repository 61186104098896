import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { LabelConfig } from "../models";

@Component({
  selector: "cb-label-component",
  template: `
    <div [formGroup]="form" class="cb-form-label-input">
      <label
        class="cb-form-label"
        [attr.data-pgm-formelement]="config.formControlName"
      >
        {{ config.label }}
      </label>
      <div class="cb-form-input">
        {{ config.value }}
      </div>
      <ng-container *ngIf="config.hint">
        <mat-hint>{{ config.hint }}</mat-hint>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .cb-form-label-input {
        padding: 0.75em 0.75em 0 0.75em;
        display: flex;
        flex-direction: column;
        min-height: 56px;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
      }
      .cb-form-input,
      .cb-form-label {
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .cb-form-label {
        font-size: calc(1rem * 0.75);
        color: rgba(0, 0, 0, 0.6);
      }
      .mat-hint {
        font-size: calc(1rem * 0.75);
        font-size: 0.75rem;
        position: absolute;
        bottom: 0;
      }
    `,
  ],
})
export class CbLabelComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: LabelConfig;
  constructor() {}

  ngOnInit() {
    console.log(this.field, this.form);
  }
}
