import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment_api } from "../../../../environments/environment.api";
import { Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

// TODO - these values most likely should come from server
const urlConfig = {
  templatesUrl: "/email/event/templates",
  themesUrl: "/config/email-themes",
  //   sendEmailUrl: "/email/event/templates/send",
  sendEmailUrl: "/email/trackdayitem/templates/send/1",
  placeholders: "/config/email-templates/info",
  templateTypes: "/config/email-types-manual",
  saveAs: "/email/event/savemanualtemplateas",
  save: "/email/event/savemanualtemplate",
};

export type HttpMethodType = "GET" | "POST" | "PUT" | "DELETE";
@Injectable({
  providedIn: "root",
})
export class SendEmailService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  private createRequest(method: HttpMethodType, url: string, body?) {
    return this.http.request(method, url, {
      body,
      params: {
        lang: this.translate.currentLang,
        is_testdata: localStorage.getItem("is_testdata"),
      },
    });
  }

  getTemplatePlaceholders = (
    workflow: string,
    url: string = ""
  ): Observable<any> => {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );

    let params: any = {
      workflow,
      lang: this.translate.currentLang,
      is_testdata: localStorage.getItem("is_testdata"),
    };
    const apiUrl = `${environment_api.api_url}${urlConfig.placeholders}`;
    return this.http.get(apiUrl, { responseType: "text", params: params });
  };

  getParticipants = (url: string): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${url}`;
    return this.createRequest("GET", apiUrl);
  };

  saveTemplate = (body, url: string): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${url}`;
    return this.createRequest("POST", apiUrl, body);
  };

  saveTemplateAs = (body, url: string): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${url}`;
    return this.createRequest("POST", apiUrl, body);
  };

  getAllTemplateTypes = (): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${urlConfig.templateTypes}`;
    return this.http.get(apiUrl);
  };

  getAllThemes = (): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${urlConfig.themesUrl}`;
    return this.http.get(apiUrl);
  };

  getAllTemplates = (url: string): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${url}`;
    return this.http.get(apiUrl);
  };

  getTemplateDetail = (id: number, url: string): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${url}/${id}`;
    return this.http.get(apiUrl);
  };

  sendEmail = (body: any, url: string): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${url}`;
    return this.http.post(apiUrl, body);
  };

  sendTestEmail = (body: any, url: string): Observable<any> => {
    const apiUrl = `${environment_api.api_url}${url}`;
    return this.http.post(apiUrl, body);
  };
}
