// Angular
import { OverlayModule } from "@angular/cdk/overlay";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
// Angular in memory
// Perfect Scroll bar
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
// NGX Permissions
import { NgxPermissionsModule } from "ngx-permissions";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// State
import { metaReducers, reducers } from "./core/store/reducers/app.reducers";
// Copmponents
import { AppComponent } from "./app.component";
// Modules
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { ThemeModule } from "./views/theme/theme.module";
// Partials
import { PartialsModule } from "./views/partials/partials.module";
// Layout Services
// CRUD
import {
  DashboardConfigService,
  HttpUtilsService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  LayoutUtilsService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
  TypesUtilsService,
} from "./core/base";
// Auth
import { AuthService } from "./core/store";
import { AuthModule } from "./views/pages/auth/auth.module";
// Config
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { ToastrModule } from "ngx-toastr";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiConfig } from "./core/config/api.config";
import { LayoutConfig } from "./core/config/layout.config";
import { EventEmitterService } from "./event-emitter.service";
import { MaterialModule } from "./modules/material/material.module";
import { ProjectManagementModule } from "./modules/project-management/project-management.module";
import { SendEmailModule } from "./modules/send-email/send-email.module";
import { ProjectTemplateManagementModule } from "./views/pages/project-template-management/project-template-management.module";

// router problems

export class CustomTranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: String): Observable<any> {
    return this.http.get(`${ApiConfig.translationResourceURL()}/${lang}`).pipe(
      map((res: any) => {
        return res?.[0]?.data;
      })
    );
  }
}

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

// FullCalendarModule.registerPlugins([
//   // register FullCalendar plugins
//   dayGridPlugin,
//   timeGridPlugin,
// ]);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      //   preventDuplicates: true,
    }),
    AppRoutingModule,
    MaterialModule,
    SendEmailModule,
    ProjectManagementModule,
    ProjectTemplateManagementModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: "router",
      routerState: RouterState.Minimal,
    }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem("language") || "en",
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslationLoader,
        deps: [HttpClient],
      },
    }),
    MatProgressSpinnerModule,
    ThemeModule,
    NgxDropzoneModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [],
  providers: [
    provideNgxMask(),
    AuthService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },

    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    EventEmitterService,
    DashboardConfigService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
