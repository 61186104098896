// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeInputComponent } from './time-input.component'

@NgModule({
  declarations: [
    TimeInputComponent,
  ],
  exports: [
    TimeInputComponent
  ]
})
export class TimeInputModule {}
