import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";

@Component({
  selector: "laptime-levels-form",
  templateUrl: "./laptime-levels-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaptimeLevelsFormComponent implements OnInit {
  apiUrl: string;

  constructor() {}
  ngOnInit() {
    this.apiUrl = ApiConfig.LaptimeLevelsURL();
  }
}
