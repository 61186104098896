<style>
    .subtitle_ {
        vertical-align: top;
        font-weight: 400;
        font-size: 0.5em;
    }
</style>
<div style="background-color: white;padding-top:10px;"
        class="kt-container kt-grid__item kt-grid__item--fluid kt-container--fluid" ktcontentanimate=""
        ng-reflect-klass="kt-container kt-grid__item kt-" ng-reflect-ng-class="[object Object]">

    <h1>Version r_20210217</h1>
    <div class="container-fluid">
        <div class="row"><div class="col-sm"> </div><div class="col-sm">Backoffice</div><div class="col-sm">Admin</div><div class="col-sm">Sysadmin</div></div>

        <div class="row"><div class="col-sm"><h3>Dashboard</h3></div></div>
        <div class="row"><div class="col-sm">Dashboard</div><div class="col-sm">    X      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>

        <div class="row"><div class="col-sm"><h3>Calendar</h3></div></div>
        <div class="row"><div class="col-sm">Calendar</div><div class="col-sm">    X      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>

        <div class="row"><div class="col-sm"><h3>Trackday</h3></div></div>
        <div class="row"><div class="col-sm">Trackdays</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Trackday Events</div><div class="col-sm">    X      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Participant Bookings</div><div class="col-sm">    X      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Group Bookings</div><div class="col-sm">    X      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>

        <div class="row"><div class="col-sm"><h3>Services</h3></div></div>
        <div class="row"><div class="col-sm">Services</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Service Provider</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Coupons</div><div class="col-sm">    X      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>


        <div class="row"><div class="col-sm"><h3>User</h3></div></div>
        <div class="row"><div class="col-sm">Users</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Companies</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>

        <div class="row"><div class="col-sm"><h3>Settings</h3></div></div>
        <div class="row"><div class="col-sm">Cancellation Prices</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>

        <div class="row"><div class="col-sm">Services Types</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Service Provider Type</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>

        <div class="row"><div class="col-sm">Currency</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Vehicle Types</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>


        <div class="row"><div class="col-sm"><h3>Admin</h3></div></div>
        <div class="row"><div class="col-sm"><h4>Authentication</h4></div></div>
        <div class="row"><div class="col-sm">API Token</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Permission Groups</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Permission Keys</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Permission Roles</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Roles</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">User Types</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>

        <div class="row"><div class="col-sm"><h4>Coupons</h4></div></div>
        <div class="row"><div class="col-sm">Assigned Coupons</div><div class="col-sm">    X      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Unassigned Coupons</div><div class="col-sm">    X      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>


        <div class="row"><div class="col-sm"><h3>Statistics</h3></div></div>
        <div class="row"><div class="col-sm">Events</div><div class="col-sm">    &nbsp;     </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Users</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>


        <div class="row"><div class="col-sm"><h3>Circuit Booking</h3></div></div>
        <div class="row"><div class="col-sm">Locations</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">MotoDB</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>



        <div class="row"><div class="col-sm"><h3>Additional</h3></div></div>
        <div class="row"><div class="col-sm">Release Notes</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">External Licenses</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>
        <div class="row"><div class="col-sm">Permissioninfo</div><div class="col-sm">    &nbsp;      </div><div class="col-sm">    X      </div><div class="col-sm">    X      </div></div>


    </div>
</div>
