<ng-container
  *ngIf="button.processType == 'action' || button.processType == 'both'"
>
  <button
    mat-menu-item
    class="col-action-btn custom-icon-button"
    (click)="clickHandler($event)"
  >
    <i *ngIf="button.icon" [ngClass]="button.icon"></i>
    <span class="pl-2">{{ button.title }}</span>
  </button>
</ng-container>
