import {
  crudTableReducer,
  CrudTableState,
  initialCrudTableState,
} from "./../reducers/crud-table.reducers";
import { ComponentStore } from "@ngrx/component-store";
import {
  CrudActionServerError,
  CrudActionToggleLoading,
  CrudTableActions,
  CrudTableActionTypes,
  ItemCreated,
  ItemOnServerDeleted,
  ItemOnServerUpdated,
  PageLoaded,
  PageToggleLoading,
} from "../actions/crud-table.actions";
import { Injectable } from "@angular/core";
import { CrudTableService } from "./../../../services";
import { Actions } from "@ngrx/effects";
import { Observable, forkJoin, of, throwError } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { each } from "lodash";
import {
  HttpExtenstionsModel,
  LayoutUtilsService,
  MessageType,
  QueryResultsModel,
} from "../../base";

@Injectable()
export class CrudTableStore extends ComponentStore<CrudTableState> {
  showPageLoadingDistpatcher = new PageToggleLoading({ isLoading: true });
  hidePageLoadingDistpatcher = new PageToggleLoading({ isLoading: false });

  showActionLoadingDistpatcher = new CrudActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new CrudActionToggleLoading({
    isLoading: false,
  });

  constructor(
    private crudTableService: CrudTableService,
    private layoutUtilsService: LayoutUtilsService
  ) {
    super(initialCrudTableState);
  }

  readonly actionLoading$ = this.select((state) => state.actionsloading);

  readonly pageLoaded$ = this.select(
    this.select((state) => {
      const items: any[] = [];
      each(state.entities, (element) => {
        items.push(element);
      });
      // const httpExtension = new HttpExtenstionsModel();
      // const result: any[] = httpExtension.sortArray(
      //     items,
      //     state.lastQuery.sortField,
      //     state.lastQuery.sortOrder
      // );
      return new QueryResultsModel(items, state.totalCount, "");
    }),
    (result: QueryResultsModel) => result,
    { debounce: true }
  );

  readonly loadPage = this.effect((payload$: Observable<any>) => {
    return payload$.pipe(
      switchMap(({ url, page, resolveData }) => {
        console.log(url, page);
        this.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.crudTableService.findItems(url, page);
        return forkJoin(requestToServer, of(resolveData));
      }),
      map((response) => {
        const result: any = response[0];
        const resolveData: any = response[1];
        let items = result.data || result || [];

        console.log("resolveData", response);
        if (resolveData) {
          items = resolveData(items);
        }

        this.dispatch(
          new PageLoaded({
            items,
            totalCount: result.total,
            page: result.current_page,
          })
        );
      })
    );
  });

  readonly updateItem = this.effect((payload$: Observable<any>) => {
    return payload$.pipe(
      mergeMap(({ item, url }) => {
        this.dispatch(this.showActionLoadingDistpatcher);
        return this.crudTableService.updateItem(url, item).pipe(
          tap((res) => {
            const message = `Item has been saved successfully`;
            this.layoutUtilsService.showNotification("success", message);
            this.dispatch(
              new ItemOnServerUpdated({
                item: res,
                url: url,
              })
            );
          }),
          catchError((res: any) => {
            if (res.status === 400 && res.error) {
              this.dispatch(
                new CrudActionServerError({
                  errors: {
                    statusCode: res.status,
                    message: res.error,
                  },
                })
              );
            } else if (res.status == 500) {
              this.dispatch(
                new CrudActionServerError({
                  errors: `An Unexpected Error Occurred. Please try again.`,
                })
              );
            }

            return throwError(res);
          })
        );
      }),
      map(() => {
        this.dispatch(this.hideActionLoadingDistpatcher);
      })
    );
  });

  readonly createItem = this.effect((payload$: Observable<any>) => {
    return payload$.pipe(
      mergeMap(({ item, url }) => {
        this.dispatch(this.showActionLoadingDistpatcher);

        console.log("this.crudTableService.createItem", payload$);
        return this.crudTableService.createItem(url, item).pipe(
          tap((res) => {
            this.dispatch(new ItemCreated({ item: res, url: url }));
          }),
          catchError((res: any) => {
            this.dispatch(this.hideActionLoadingDistpatcher);
            console.log(res);
            if (res.status === 400 && res.error) {
              this.dispatch(
                new CrudActionServerError({
                  errors: {
                    statusCode: res.status,
                    message: res.error,
                  },
                })
              );
            } else if (res.status == 500) {
              this.dispatch(
                new CrudActionServerError({
                  errors: `An Unexpected Error Occurred. Please try again.`,
                })
              );
            }

            return throwError(res);
          })
        );
      }),
      map(() => {
        this.dispatch(this.hideActionLoadingDistpatcher);
      })
    );
  });

  readonly deleteItem = this.effect((payload$: Observable<any>) => {
    return payload$.pipe(
      mergeMap(({ id, url }) => {
        this.dispatch(this.showActionLoadingDistpatcher);

        return this.crudTableService.deleteItem(url, id).pipe(
          tap((res) => {
            // console.log("deleteItem.tap", res);
            this.dispatch(
              new ItemOnServerDeleted({
                id: id,
                url: url,
              })
            );
          }),
          catchError((res: any) => {
            this.dispatch(this.hideActionLoadingDistpatcher);
            console.log(res);
            if (res.status === 400 && res.error) {
              this.dispatch(
                new CrudActionServerError({
                  errors: {
                    statusCode: res.status,
                    message: res.error,
                  },
                })
              );
            } else if (res.status == 500) {
              this.dispatch(
                new CrudActionServerError({
                  errors: `An Unexpected Error Occurred. Please try again.`,
                })
              );
            }

            return throwError(res);
          })
        );
      }),
      map(() => {
        this.dispatch(this.hideActionLoadingDistpatcher);
      })
    );
  });

  readonly dispatch = (action: CrudTableActions) => {
    switch (action.type) {
      case CrudTableActionTypes.PageRequested:
        this.loadPage(action.payload);
        break;

      case CrudTableActionTypes.ItemDeleted:
        this.deleteItem(action.payload);
        break;

      case CrudTableActionTypes.ItemUpdated:
        this.updateItem(action.payload);
        break;
      case CrudTableActionTypes.ItemOnServerCreated:
        this.createItem(action.payload);
        break;

      default:
        break;
    }

    this.update(action);
  };

  readonly update: (action: CrudTableActions | any) => void = this.updater(
    (state, action: CrudTableActions) => {
      return crudTableReducer(state, action);
    }
  );
}
