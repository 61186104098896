import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  inject,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import {
  distinct,
  distinctUntilChanged,
  filter,
  takeUntil,
} from "rxjs/operators";
import { FormConfigService } from "../../../../form.config.service";

@Component({
  selector: "cb-tab-content-portlet",
  templateUrl: "./tab-content-portlet.component.html",
  styleUrls: ["./tab-content-portlet.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentPortletComponent implements OnInit, OnDestroy {
  private _content: any;
  @Input() set content(value: any) {
    if (value) {
      this._content = value.map((t) => {
        return {
          ...t,
          invisible: !this.formConfigService.getTabVisibilityState(t),
          title: this.formConfigService.parsePlaceholders(t.name),
          showHeader: t?.show_header ?? true,
          collapsibleConfig: {
            collapsibleKey: t?.header?.collapsible_key ?? null,
            collapsible: t?.header?.collapsible ?? true,
            display: t?.header?.display_title ?? true,
          },
          processButtons: this.formConfigService.processButtonParser(
            t?.header?.process_buttons
          ),
        };
      });
    }
  }
  @Input() forms: UntypedFormGroup;
  @Input() layout = {
    columns: [100],
  };
  formConfigService = inject(FormConfigService);

  loadingComponents$ = new BehaviorSubject<
    { status: boolean; componentIdentifier: number }[]
  >([]);
  destroy$ = new Subject();

  get content() {
    return this._content;
  }
  content$ = new BehaviorSubject([]);
  processedContents$ = new BehaviorSubject([]);
  isSubFormShown$ = new BehaviorSubject(false);
  destroyed$ = new Subject();
  constructor() {
    // currently this is only a quick fix for when we are displaying a form and a chart
    // in portlet mode, when the time comes, we need to somehow fix this.
    // basically the goal is to update the dependent data
    // in this case its working since I know that the chart is always a dependent of the form
    this.formConfigService.isSubFormShown$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => this.isSubFormShown$.next(status));
  }
  formName = this.formConfigService.formName;

  ngOnInit(): void {
    combineLatest([of(this.layout), this.content$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([_, content]) => !!content && content.length > 0)
      )
      .subscribe(([layout, content]) => {
        const { columns } = layout;
        const initialLayout = this.getInitialLayout();

        const processedContents = content.reduce((acc, curr, index) => {
          let desiredColumn = curr?.["column"] ? curr["column"] - 1 : 0;
          if (desiredColumn > columns.length - 1) {
            desiredColumn = 0;
          }

          const targetColumn = acc.find(
            (item) => item.column === desiredColumn
          );
          if (targetColumn) {
            targetColumn["items"].push({ ...curr, index });
            targetColumn["order"] = index;
            targetColumn["column"] = desiredColumn;
            return acc;
          } else {
            console.error("targetColumn not found");
          }
        }, initialLayout);
        this.processedContents$.next(processedContents);
      });
    this.content$.next(this.content);
    this.formConfigService.updateTabVisibility$
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe({
        next: (tab) => {
          this.content$.next(
            this._content.map((t) => {
              if (t.name === tab.name) {
                return {
                  ...t,
                  invisible: !this.formConfigService.getTabVisibilityState(t),
                  title: this.formConfigService.parsePlaceholders(t.name),
                };
              }
              return t;
            })
          );
        },
      });
  }
  onChildLoadingChangeEvent(event) {
    const rest = this.loadingComponents$.value.filter(
      (t) => t.componentIdentifier !== event.componentIdentifier
    );
    this.loadingComponents$.next([...rest, event].map((e) => e));
  }
  trackPortlets(index, item) {
    return item?.name;
  }

  parseApiUrl(apiUrl: string) {
    return this.formConfigService.parsePlaceholders(apiUrl);
  }
  private getInitialLayout() {
    return this.layout.columns.reduce((acc, curr, index) => {
      acc.push({
        column: index,
        items: [],
        width: `0 0 ${curr}%`,
        order: index,
      });

      return acc;
    }, []);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
