import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from "@angular/core";
import { Observable } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";
import { FilterService } from "../../services/filter.service";
import { Filter, FilterComponentOutput } from "../../types/types";

// takes in filters config
// takes in searchable fields
// it parses the config
// outputs two events
// 1. ready event
// 2. change event (3 types: raw form data, httpParams, queryString)
// the component using this, should choose which output type to use
// ready state should be use so that the component using this, can wait (or not wait) before their first api call
// NOTES:
// ready state probably should moved to a more concrete spot, currently its being called after the filters
// have been parsed and the form is created (its not waiting for all the dynamic filters to load)
// if we want to display selected filters initially (filters indicators) we need to implement a way to wait
// for each filter to finish loading, then update indicators, and then ready state
@Component({
  selector: "app-filter-shell",
  templateUrl: "./filter-shell.component.html",
  styleUrls: ["./filter-shell.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterService],
})
export class FilterShellComponent implements OnInit {
  @Input() filters: any;
  @Input() searchableFields: string[] = [];
  @Output() onFilterChangeEvent =
    new EventEmitter<FilterComponentOutput | null>();
  @Output() onFiltersReady = new EventEmitter<boolean>();
  filterService = inject(FilterService);
  filterForm = this.filterService.filterForm;

  allFilters$: Observable<Filter[]> = this.filterService.filtersConfig$;
  filterIndicators$: Observable<any> = this.filterService.filterIndicators$;
  hasBeenInitialized$: Observable<boolean> =
    this.filterService.hasBeenInitialized$;
  constructor() {}
  ngOnInit(): void {
    this.filterService.updateFiltersConfig(this.filters, this.searchableFields);
    this.filterForm = this.filterService.extractForm();
    // here seems to be a good place for ready event
    // we might have to wait for all the filters (dynamic ones) to load
    // for now this should be enough
    this.onFiltersReady.emit(true);
    this.filterService.componentOutput$
      .pipe(
        takeUntil(this.filterService.destroyed$),
        filter((val) => val !== null),
        tap((output) => {
          this.onFilterChangeEvent.emit(output);
        })
      )
      .subscribe();
  }

  resetSearch = () => {
    this.filterForm.controls["search"]?.setValue("");
  };

  resetAllFilters(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    // this.filterForm.reset();
    this.filterService.resetAllFilters();
  }
}
