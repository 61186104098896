<mat-menu #menu>
  <ng-container *ngFor="let item of buttons; trackBy: buttonTrackByFn">
    <ng-container *ngIf="item.visibleIf">
      <button
        (click)="clickHandler(item)"
        *ngIf="!item.children.length; else branch"
        mat-menu-item
      >
        <i *ngIf="item.icon" [ngClass]="item.icon"></i>
        <span class="pl-2">{{ item.title }}</span>
      </button>
    </ng-container>
    <ng-template #branch>
      <button
        mat-menu-item
        *ngIf="item.visibleIf"
        [matMenuTriggerFor]="innerPanel.menu"
      >
        <i *ngIf="item.icon" [ngClass]="item.icon"></i>
        <span class="pl-2">{{ item.title }}</span>
      </button>
      <app-context-menu-item
        #innerPanel
        [buttons]="item.children"
      ></app-context-menu-item>
    </ng-template>
  </ng-container>
</mat-menu>
