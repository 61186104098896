import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import { debounceTime, startWith, takeUntil } from "rxjs/operators";
import { RemoveItemTrigger } from "../../animations/remove-item.animation";
import { sendEmailActions, sendEmailSelectors } from "../../store";

@Component({
  selector: "app-recipient-details",
  templateUrl: "./recipient-details.component.html",
  styleUrls: ["./recipient-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [RemoveItemTrigger],
})
export class RecipientDetailsComponent implements OnInit, OnDestroy {
  search$ = new Subject<string>();
  isSearching$ = new BehaviorSubject(false);
  store = inject(Store);
  participants$ = this.store.pipe(
    select(sendEmailSelectors.selectParticipants)
  );
  filteredParticipants$ = new BehaviorSubject<any[]>([]);
  labelTemplate$ = this.store.pipe(
    select(sendEmailSelectors.selectParticipantsLabelValueTemplate)
  );

  isLoadingParticipants$ = this.store.pipe(
    select(sendEmailSelectors.selectIsLoadingParticipants)
  );

  destroyed$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.participants$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (participants: any) => {
        if (participants === null) {
          this.store.dispatch(sendEmailActions.getParticipantsFromServer());
        } else {
          if (!this.isSearching$.value) {
            this.filteredParticipants$.next(participants);
          }
        }
      },
    });
    combineLatest([this.participants$.pipe(startWith([])), this.search$])
      .pipe(takeUntil(this.destroyed$), debounceTime(400))
      .subscribe({
        next: ([participants, searchTerm]) => {
          if (searchTerm) {
            participants = participants.filter((participant) => {
              const res = Object.keys(participant).filter((e) =>
                participant[e]?.toString().toLowerCase().includes(searchTerm)
              );
              return res.length ? true : false;
            });
            this.isSearching$.next(true);
          } else {
            this.isSearching$.next(false);
          }
          this.filteredParticipants$.next(participants);
          //   if (participants === null) {
          //     this.store.dispatch(sendEmailActions.getParticipantsFromServer());
          //   }
        },
      });
  }

  onSearch(search) {
    this.search$.next(search.target.value);
  }
  onSearchReset() {
    this.search$.next("");
  }

  remove(participant) {
    this.store.dispatch(sendEmailActions.removeParticipant({ participant }));
  }

  tracker(index, item) {
    return item.id;
  }
}
