import { AbstractControl, ValidatorFn } from "@angular/forms";
import { CUSTOM_VALIDATIONS } from "../types/customValidations.type";

export function validateMin(
  field: string,
  dependentValue: number
): ValidatorFn {
  return (formControl: AbstractControl) => {
    if (!dependentValue) return null;
    const value = formControl.value;
    if (value === null || value === undefined) return null;
    if (
      (value.length > 0 && isNaN(value)) ||
      formControl.value < dependentValue
    ) {
      return {
        [CUSTOM_VALIDATIONS.MIN]: true,
        dependent: dependentValue,
      };
    }
    return null;
  };
}
export function validateMax(
  field: string,
  dependentValue: number
): ValidatorFn {
  return (formControl: AbstractControl) => {
    if (!dependentValue) return null;
    const value = formControl.value;
    if (value === null || value === undefined) return null;
    if (
      (value.length > 0 && isNaN(value)) ||
      Number(formControl.value) > dependentValue
    ) {
      return {
        [CUSTOM_VALIDATIONS.MAX]: true,
        dependent: dependentValue,
      };
    }
    return null;
  };
}
