// Angular
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { Store } from "@ngrx/store";
import { Injector } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ApiConfig } from "../../../../core/config/api.config";
import { AppState } from "../../../../core/store/reducers/app.reducers";

@Component({
  selector: "virtualgarage-table",
  templateUrl: "./virtualgarage-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualGarageTableComponent implements OnInit {
  columns = [];

  searchableColumns = ["id", "name"];
  @Input() apiUrl: string;

  createButtonLabel = "Create Virtual Garage";
  readonly = true;
  alwaysShowCheckboxes = true;

  selectedItems$ = new BehaviorSubject<any[]>([]);

  private dialogData;

  constructor(
    private injector: Injector,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
  }

  ngOnInit() {
    if (this.dialogData) {
      if (this.dialogData?.crudTable?.apiUrl)
        this.apiUrl = this.dialogData.crudTable.apiUrl;
      if (this.dialogData.showAddBtn !== undefined)
        this.readonly = this.dialogData.readonly;
    }
    if (!this.apiUrl) this.apiUrl = ApiConfig.VirtualGarageURL();
  }

  selectionChange(items: any[]) {
    this.selectedItems$.next(items);
  }
}
