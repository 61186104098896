import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export class HtmlContentDialogData {
  title: string;
  apiUrl: string;
}

@Component({
  selector: "html-content-dialog",
  templateUrl: "./html-content-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlContentDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<HtmlContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HtmlContentDialogData
  ) {}
}
