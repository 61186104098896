import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ACTIONS } from "./default.actions";
import { KanbanStoreModel } from "./default.model";
import { KanbanService } from "./kanban.service";

@Injectable()
export class DefaultEffect {
  actions$ = inject(Actions);
  store = inject(Store<KanbanStoreModel>);
  kanbanService = inject(KanbanService);

  UpdateTaskByIdForPopUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.tasks.UpdateTaskByIdForPopUp),
      switchMap(({ payload }) => {
        return this.kanbanService.updateTaskById(payload.id, payload).pipe(
          map((response) => {
            return ACTIONS.tasks.UpdateTaskByIdForPopUpSuccess();
          }),
          catchError((error) => {
            return of(
              ACTIONS.tasks.UpdateTaskByIdForPopUpFailure({
                error,
              })
            );
          })
        );
      })
    )
  );

  UpdateTaskById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.tasks.UpdateTaskById),
      switchMap(({ payload }) => {
        return this.kanbanService
          .changeTaskState(payload.id, payload.state)
          .pipe(
            map((response) => {
              return ACTIONS.tasks.UpdateTaskByIdSuccess();
            }),
            catchError((error) => {
              return of(
                ACTIONS.tasks.GetTasksByBoardIdFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );

  getTasksByBoardId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.tasks.GetTasksByBoardId),
      switchMap(({ payload }) => {
        return this.kanbanService.getTasksByBoardId(payload).pipe(
          map((response) => {
            return ACTIONS.tasks.GetTasksByBoardIdSuccess({
              payload: response,
            });
          }),
          catchError((error) => {
            return of(
              ACTIONS.tasks.GetTasksByBoardIdFailure({
                error,
              })
            );
          })
        );
      })
    )
  );

  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.projects.CreateProject),
      switchMap(({ title, description, projectId }) => {
        const payload = {
          title,
          description,
          projectId,
        };
        return this.kanbanService.createProject(payload).pipe(
          map((response) => {
            return ACTIONS.projects.CreateProjectSuccess({
              payload: response,
            });
          }),
          catchError((error) => {
            return of(
              ACTIONS.projects.CreateProjectFailure({
                error,
              })
            );
          })
        );
      })
    )
  );

  createBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.boards.CreateBoard),
      switchMap(({ title, description, projectId }) => {
        const payload = {
          title,
          description,
          projectId,
        };
        return this.kanbanService.createBoard(payload).pipe(
          map((response) => {
            return ACTIONS.boards.CreateBoardSuccess({
              payload: response,
            });
          }),
          catchError((error) => {
            return of(
              ACTIONS.boards.CreateBoardFailure({
                error,
              })
            );
          })
        );
      })
    )
  );

  getKanbanProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.projects.GetAll),
      switchMap(() =>
        this.kanbanService.getAllKanbanProjects().pipe(
          map((response) => {
            return ACTIONS.projects.GetAllSuccess({
              payload: response,
            });
          }),
          catchError((error) => of(ACTIONS.projects.GetAllFailure({ error })))
        )
      )
    )
  );

  getKanbanBoardsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.boards.GetBoardsByProjectId),
      switchMap(({ payload }) =>
        this.kanbanService.getBoardsByProjectId(payload).pipe(
          map((response) => {
            return ACTIONS.boards.GetBoardsByProjectIdSuccess({
              payload: response,
            });
          }),
          catchError((error) =>
            of(ACTIONS.boards.GetBoardsByProjectIdFailure({ error }))
          )
        )
      )
    )
  );
}
