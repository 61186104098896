import { createAction, props } from "@ngrx/store";
import { KanbanBoardModel, KanbanProjectModel, KanbanTaskModel } from "./default.model";


const projects = function<T>(context: string) {    
    return {
        GetAll: createAction(
            `[API] GetAll ${context}`
        ),
        GetAllSuccess: createAction(
            `[API] GetAll ${context} Success`,
            props<{ payload: T[] }>()
        ),
        GetAllFailure: createAction(
            `[API] GetAll ${context} Failure`,
            props<{ error: any }>()
        ),

        CreateProject: createAction(
            `[API] Create ${context}`,
            props<{
              title: string;
              description: string;
              projectId: number;
            }>()
        ),
        CreateProjectSuccess: createAction(
            `[API] Create ${context} Success`,
            props<{ payload: T }>()
        ),
        CreateProjectFailure: createAction(
            `[API] Create ${context} Failure`,
            props<{ error: any }>()
        )
    }
} <KanbanProjectModel>('kanbanProjects')



const boards = function<T>(context: string) {    
    return {        
        GetAll: createAction(
            `[API] GetAll ${context}`,            
        ),
        GetAllSuccess: createAction(
            `[API] GetAll ${context} Success`,
            props<{ payload: T[] }>()
        ),
        GetAllFailure: createAction(
            `[API] GetAll ${context} Failure`,
            props<{ error: any }>()
        ),

        GetBoardsByProjectId: createAction(
            `[API] GetBoardsByProjectId ${context}`,
            props<{ payload: number }>()
        ),
        GetBoardsByProjectIdSuccess: createAction(
            `[API] GetBoardsByProjectId ${context} Success`,
            props<{ payload: T[] }>()
        ),
        GetBoardsByProjectIdFailure: createAction(
            `[API] GetBoardsByProjectId ${context} Failure`,
            props<{ error: any }>()
        ),

        CreateBoard: createAction(
            `[API] Create ${context}`,
            props<{
              title: string;
              description: string;
              projectId: number;
            }>()
        ),
        CreateBoardSuccess: createAction(
            `[API] Create ${context} Success`,
            props<{ payload: T }>()
        ),
        CreateBoardFailure: createAction(
            `[API] Create ${context} Failure`,
            props<{ error: any }>()
        )
    }
} <KanbanBoardModel>('kanbanBoards')

const tasks = function<T>(context: string) {    
    return {
        GetTasksByBoardId: createAction(
            `[API] GetTasksByBoardId ${context}`,
            props<{ payload: number }>()
        ),
        GetTasksByBoardIdSuccess: createAction(
            `[API] GetTasksByBoardId ${context} Success`,
            props<{ payload: T[] }>()
        ),
        GetTasksByBoardIdFailure: createAction(
            `[API] GetTasksByBoardId ${context} Failure`,
            props<{ error: any }>()
        ),
        UpdateTaskById: createAction(
            `[API] UpdateTaskById ${context}`,
            props<{ payload: any }>()
        ),
        UpdateTaskByIdSuccess: createAction(
            `[API] UpdateTaskById ${context} Success`,
            //props<{ payload: T[] }>()
        ),
        UpdateTaskByIdFailure: createAction(
            `[API] UpdateTaskById ${context} Failure`,
            props<{ error: any }>()
        ),

        UpdateTaskByIdForPopUp: createAction(
            `[API] UpdateTaskByIdForPopUp ${context}`,
            props<{ payload: any }>()
        ),
        UpdateTaskByIdForPopUpSuccess: createAction(
            `[API] UpdateTaskByIdForPopUp ${context} Success`,
            //props<{ payload: T[] }>()
        ),
        UpdateTaskByIdForPopUpFailure: createAction(
            `[API] UpdateTaskByIdForPopUp ${context} Failure`,
            props<{ error: any }>()
        ),

        ResetTasks: createAction(
            `[API] GetTasksByBoardId ${context} Reset`            
        ),        
    }
} <KanbanTaskModel>('kanbanTasks')


export const ACTIONS = {
    projects,
    boards,
    tasks
}