import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from "@angular/core";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import { catchError, map, startWith, takeUntil } from "rxjs/operators";
import { MenuAsideService } from "../../../../../core/base";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "cb-navigation-toolbox",
  templateUrl: "./toolbox.component.html",
  styleUrls: ["./toolbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("growAnimation", [
      state(
        "loading",
        style({
          width: "36px",
        })
      ),
      state(
        "loaded",
        style({
          width: "*",
        })
      ),
      transition("loading => loaded", [animate("0.675s ease-in-out")]),
    ]),
  ],
})
export class ToolboxComponent implements OnInit {
  menuHorService = inject(MenuAsideService);
  destroyed$ = new Subject<boolean>();
  isLoading$ = new BehaviorSubject(true);
  hasError$ = new BehaviorSubject(false);
  items$ = this.menuHorService.loadToolbar().pipe(
    takeUntil(this.destroyed$),
    map((items) => {
      this.isLoading$.next(false);
      return items?.config?.map((item) => {
        return {
          ...item,
          icon: item.icon,
          title: item.title,
          link: item.page,
          translate: item.translate,
          type: item.type,
          submenu:
            item?.submenu?.map((sub) => {
              return {
                ...sub,
                icon: sub.icon,
                title: sub.title,
                link: sub.page,
                translate: sub.translate,
                type: sub.type,
              };
            }) ?? [],
        };
      });
    }),
    catchError(() => {
      this.isLoading$.next(false);
      this.hasError$.next(true);
      return of([]);
    })
  );

  constructor(private router: Router, public route: ActivatedRoute) {}

  vm$ = combineLatest([this.items$, this.isLoading$]).pipe(
    startWith([[], true]),
    map(([items, isLoading]) => {
      return {
        items,
        isLoading,
      };
    })
  );

  ngOnInit(): void {}
  test() {
    this.router.navigate([{ outlets: { modal: ["check-in"] } }], {
      relativeTo: this.route.root,
    });
  }
}
