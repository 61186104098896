<!--begin::Form-->
<form class="kt-form kt-form--group" *ngIf="isProfileImage">
    <div class="kt-form__section kt-form__section--first">
        <div class="form-group kt-form__group row">
            <div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile custom-field-holder__image">
                <div class="image-column">
                    <figure class="image is-100x100">
                        <img [src]="imageUrl" />
                    </figure>
                </div>
                <div class="image-column">
                    <label for="file" class="custom-file-label">
                        <i class="mat-tab-label-icon fa fa-camera"></i>
                        <span>Add Photo</span>
                    </label>
                    <input type="file"
                           id="file"
                           [hidden]="true"
                           name="image"
                           (change)="handleFileInput($event.target.files)">
                </div>
            </div>
            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                <button type="button" mat-raised-button matTooltip="Save Image" (click)="uploadProfileImage()"
                        class="danger-button w-140">
                    Save
                </button>
            </div>
        </div>
    </div>
</form>
<!--end::Form-->
