import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ACTIONS } from "../../store/default.actions";
import {
  selectActiveProject,
  selectProjects,
} from "../../store/default.selector";
import { KanbanBoardFormComponent } from "../kanban-board-form/kanban-board-form.component";
import { KanbanProjectFormComponent } from "../kanban-project-form/kanban-project-form.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "kanban-project",
  templateUrl: "./kanban-project.component.html",
  styleUrls: ["./kanban-project.component.css"],
})
export class KanbanProjectComponent implements OnInit, OnDestroy {
  store = inject(Store);
  projects$ = this.store.pipe(select(selectProjects));
  activeProject$ = this.store.pipe(select(selectActiveProject));

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  addProject = () => {
    this.dialog.open(KanbanProjectFormComponent, {
      width: "400px",
    });
  };

  addBoard = () => {
    this.dialog.open(KanbanBoardFormComponent, {
      width: "400px",
    });
  };

  getBoardsByProjectId = (projectId: number) => {
    console.log(projectId);
    this.store.dispatch(
      ACTIONS.boards.GetBoardsByProjectId({
        payload: projectId,
      })
    );
  };

  ngOnDestroy(): void {}
}
