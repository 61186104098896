// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import {Observable, of} from 'rxjs';
// Models

import { ApiConfig } from '../core/config/api.config';

@Injectable({
  providedIn: 'root'
})
export class FormConfigService {
  /**
   * Service Constructor
   *
   * @param http: HttpClient
   */
  constructor(private http: HttpClient) { }

  getFormConfig(locale: string, formName: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(ApiConfig.FormConfigURL(locale, formName));
  }
  
  getTextConfig(locale: string, textName: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(ApiConfig.TextConfigURL(locale, textName));
  }
}
