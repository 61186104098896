// Angular
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export class InfoDialogData {
  title: string = "COMMON.INFO";
  message: string;
  icon: string;
}
@Component({
  selector: "kt-info-dialog",
  templateUrl: "./info-dialog.component.html",
})
export class InfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogData
  ) {}
}
