import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, of, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { helpSidebarActions } from "../../../../core/store/actions/help-sidebar.actions";
import { AppState } from "../../../../core/store/reducers/app.reducers";
import { FORM_MODE, FormConfigService } from "../../form.config.service";
import { availableButtons } from "./availableButtons";

@Component({
  selector: "cb-form-header",
  templateUrl: "./form-header.component.html",
  styleUrls: ["./form-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() readonly = false;
  @Input() hasMoreActions = false;
  @Input() customActionButtons: TemplateRef<any> = null;
  @Output() switchFormMode = new EventEmitter();
  @Output() onExitEvent = new EventEmitter();
  @Output() onDeleteEvent = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  @Output() onBreadcrumbClick = new EventEmitter();
  @Output() onRefreshForm = new EventEmitter();
  formConfigService = inject(FormConfigService);
  form: UntypedFormGroup;
  data: any;
  translations: any;
  configLang = "";
  isFormDirty$ = new BehaviorSubject(false);
  destroyed$ = new Subject<void>();
  isFormSaving$ = new BehaviorSubject(false);
  isSubFormShown$ = new BehaviorSubject<boolean>(false);
  title$ = new BehaviorSubject<string>("");
  formMode$ = new BehaviorSubject<FORM_MODE | null>(null);
  infoText$ = this.formConfigService.infoText$.pipe(
    map((infoText) => {
      if (!infoText) return null;
      return {
        ...infoText,
      };
    })
  );
  isWizard = this.formConfigService.isWizard;
  availableButtons$ = of(availableButtons);
  saveButtons$ = combineLatest([
    this.formConfigService._formOptions$,
    this.availableButtons$,
    this.formMode$,
  ]).pipe(
    map(([options, allButtons, formMode]) => {
      if (options && options["excludeActionsButtons"]) {
        const res = allButtons
          .filter((button) => {
            return !options["excludeActionsButtons"].includes(button.name);
          })
          .filter((button) => {
            return button.mode === "ALL" || button.mode === formMode;
          });
        return res;
      }

      return allButtons?.filter((button) => {
        return button.mode === "ALL" || button.mode === formMode;
      });
    })
  );

  constructor(
    private translationService: TranslateService,
    private store: Store<AppState>
  ) {
    this.formConfigService.formMode$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((formMode: any) => this.formMode$.next(formMode));
    this.formConfigService.form$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((form: any) => (this.form = form));
    this.formConfigService.formData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => (this.data = data));
    this.formConfigService.translations$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((translations) => {
        this.translations = translations;
        this.getTitle();
      });
    this.formConfigService.configLang$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((lang: any) => (this.configLang = lang));
    this.formConfigService.isSubFormShown$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status: any) => this.isSubFormShown$.next(status));
  }
  ngAfterViewInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        // here we can decide, if to always display the message or let the user close it
        // or let the user close it, but open it again on next change
        if (!this.form.pristine) {
          this.isFormDirty$.next(this.form.dirty);
        }
      });
  }

  ngOnInit(): void {
    this.getTitle();
  }

  getTitle() {
    this.translationService
      .stream(["COMMON.NEW", "COMMON.LOADING"])
      .subscribe((t) => {
        this.title$.next(this.translationService.instant("COMMON.LOADING"));
        if (this.data?.id > 0 && this.translations) {
          let title = this.translations?.[this.configLang]?.["FORM_TITLE"];
          if (title) {
            this.title$.next(this.formConfigService.parsePlaceholders(title));
          }
        } else {
          if (this.translations) {
            let title = this.translations?.[this.configLang]?.["CREATE_TITLE"];
            if (title) {
              this.title$.next(this.formConfigService.parsePlaceholders(title));
            } else {
              this.title$.next(
                `${this.translationService.instant("COMMON.NEW")}`
              );
            }
          } else {
            this.title$.next(
              `${this.translationService.instant("COMMON.NEW")}`
            );
          }
        }
      });
  }

  submit = ({
    shouldExit = false,
    shouldCreateNew = false,
  }: {
    shouldExit?: boolean;
    shouldCreateNew?: boolean;
  }) => {
    this.onSubmit.emit({ shouldExit, shouldCreateNew });
    this.isFormDirty$.next(false);
  };

  refreshForm = () => {
    this.onRefreshForm.emit();
  };

  deleteItem = () => {
    this.onDeleteEvent.emit();
  };

  backButtonClicked = () => {
    this.onExitEvent.emit();
  };

  handleInfoToggle() {
    const infoText = this.formConfigService.infoText;
    const url = this.formConfigService.getApiUrl(infoText?.["api_url"]);
    // this.dialog.open(HtmlModalComponent, {
    //   data: {
    //     config: { ...infoText, apiUrl: url },
    //     type: "info",
    //   },
    //   maxWidth: "800px",
    // });

    this.store.dispatch(helpSidebarActions.setTargetUrl({ targetUrl: url }));
    this.store.dispatch(helpSidebarActions.openSidebar());
  }

  handleClickOnFormTitle($event) {
    if (this.isSubFormShown$.value) {
      this.onBreadcrumbClick.emit($event);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
