import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment_api } from "../../../../../../environments/environment.api";
import { FormConfigService } from "../../../../cb-forms/form.config.service";
import { FormInputTypes } from "../../../types/form-input-types.type";
import { getThousandSeparator } from "../../../utils/biz.util";
import {
  CheckBoxConfig,
  DropDownConfig,
  LabelConfig,
  LookupConfig,
  TextAreaConfig,
  TextBoxConfig,
} from "../models";
import { ColorPickerConfig } from "../models/color-picker.config.model";
import { DateTimePickerConfig } from "../models/date-time-picker.config.model";
import { DatePickerConfig } from "../models/datepicker.config.model";
import { HiddenInputConfig } from "../models/hiddenInput.config.model";
import { HtmlConfig } from "../models/html.config.model";
import { TimePickerConfig } from "../models/timepicker.config";
import { UrlConfig } from "../models/url.config.model";
import { RadioGroupConfig } from "../models/radio-group.config.model";
@Component({
  selector: "field-builder",
  template: `
    <div [formGroup]="form">
      <div [ngSwitch]="field.type">
        <cb-label-component
          *ngSwitchCase="formInputType.Label"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        >
        </cb-label-component>
        <cb-date-time
          *ngSwitchCase="formInputType.DateTime"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        >
        </cb-date-time>
        <cb-textbox
          *ngSwitchCase="formInputType.Input"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-textbox>
        <cb-textbox
          *ngSwitchCase="formInputType.Password"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-textbox>
        <cb-url
          *ngSwitchCase="formInputType.Url"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-url>
        <cb-dropdown
          *ngSwitchCase="formInputType.SingleSelect"
          [field]="field$ | async"
          [form]="form"
          [config]="config$ | async"
        ></cb-dropdown>
        <cb-dropdown
          *ngSwitchCase="formInputType.MultipleSelect"
          [field]="field$ | async"
          [form]="form"
          [config]="config$ | async"
        ></cb-dropdown>
        <cb-checkbox
          *ngSwitchCase="formInputType.Checkbox"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-checkbox>
        <cb-html-input
          *ngSwitchCase="formInputType.Html"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-html-input>
        <cb-color-picker
          *ngSwitchCase="formInputType.ColorPicker"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-color-picker>
        <cb-datePicker
          *ngSwitchCase="formInputType.Date"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-datePicker>
        <cb-timePicker
          *ngSwitchCase="formInputType.Time"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-timePicker>
        <cb-lookup
          *ngSwitchCase="formInputType.Lookup"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-lookup>
        <cb-textArea
          *ngSwitchCase="formInputType.TextArea"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        ></cb-textArea>
        <cb-radio
          *ngSwitchCase="formInputType.RadioGroup"
          [config]="config$ | async"
          [field]="field"
          [form]="form"
        ></cb-radio>
        <cb-hidden-input
          *ngSwitchCase="formInputType.Hidden"
          [field]="field"
          [form]="form"
          [config]="config$ | async"
        >
        </cb-hidden-input>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldBuilderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() field: any;
  @Input() form: UntypedFormGroup;
  formInputType = FormInputTypes;
  field$ = new BehaviorSubject({});
  thousandSeparator$ = new BehaviorSubject(",");
  shouldUpdateConfig$ = new BehaviorSubject(false);
  destroyed$ = new Subject();
  config$ = new BehaviorSubject<
    | TextBoxConfig
    | DropDownConfig
    | DatePickerConfig
    | CheckBoxConfig
    | RadioGroupConfig
    | ColorPickerConfig
    | HtmlConfig
    | UrlConfig
    | TimePickerConfig
    | LookupConfig
    | TextAreaConfig
    | HiddenInputConfig
    | DateTimePickerConfig
    | LabelConfig
    | void
  >(null);

  constructor(
    private formConfigService: FormConfigService,
    private translationService: TranslateService
  ) {}
  ngAfterViewInit(): void {
    this.formConfigService.updateFields$
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (value) => {
          if (this.field.name === value) {
            this.prepareField();
          }
        },
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.thousandSeparator$.next(
      getThousandSeparator(this.translationService.currentLang)
    );
    this.prepareField();
    this.field$.next(this.field);

    // this.loadDynamicOptions();
  }

  prepareField = () => {
    switch (this.field.type) {
      case this.formInputType.Label:
        this.prepareLabel();
        break;
      case this.formInputType.Input:
      case this.formInputType.Password:
        this.prepareTextInput();
        break;
      case this.formInputType.TextArea:
        this.prepareTextArea();
        break;
      case this.formInputType.Lookup:
        this.prepareLookup();
        break;
      case this.formInputType.Url:
        this.prepareUrlInput();
        break;
      case this.formInputType.Checkbox:
        this.prepareCheckbox();
        break;
      case this.formInputType.Html:
        this.prepareHtml();
        break;
      case this.formInputType.ColorPicker:
        this.prepareColorPicker();
        break;
      case this.formInputType.Date:
        this.prepareDatePicker();
        break;
      case this.formInputType.RadioGroup:
        this.prepareRadioGroup();
        break;
      case this.formInputType.Time:
        this.prepareTimePicker();
        break;
      case this.formInputType.Hidden:
        this.prepareHiddenInput();
        break;
      case this.formInputType.SingleSelect:
      case this.formInputType.MultipleSelect:
        this.prepareDropDown();
        break;
      case this.formInputType.DateTime:
        this.prepareDateTime();
        break;

      default:
        break;
    }
  };

  prepareDateTime = () => {
    this.config$.next({
      date: {
        formControlName: "date",
        placeholder: `${this.formConfigService.getFieldLabel(
          this.field.title
        )} (${this.translationService.instant("COMMON.DATE")})`,
        required: this.field.validator === "required" ? true : false,
        testingAttribute: `formitem_${this.field.name}`,
        tooltip:
          this.formConfigService.getFieldTooltip(this.field.tooltip) || "",
      },
      time: {
        formControlName: "time",
        placeholder: `${this.formConfigService.getFieldLabel(
          this.field.title
        )} (${this.translationService.instant("COMMON.TIME")})`,
        required: this.field.validator === "required" ? true : false,
        testingAttribute: `formitem_${this.field.name}`,
        tooltip:
          this.formConfigService.getFieldTooltip(this.field.tooltip) || "",
      },
      formControlName: this.field.name,
    } as DateTimePickerConfig);
  };

  prepareHiddenInput = () => {
    this.config$.next({
      formControlName: this.field.name,
      required: this.field.validator === "required" ? true : false,
    } as HiddenInputConfig);
  };

  prepareRadioGroup() {
    this.config$.next({
      formControlName: this.field.name,
      title: this.formConfigService.getFieldLabel(this.field.title),
      name: this.field.name,
      options: this.field.dynamic_options.data,
      optionValue: this.field.dynamic_options
        ? this.field.dynamic_options?.value_field
        : "value",
      optionLabel: this.field.dynamic_options
        ? this.field.dynamic_options?.label_field
        : "label",
      columns: this.field?.["columns"] || 1,
    } as RadioGroupConfig);
  }

  prepareTimePicker = () => {
    this.config$.next({
      formControlName: this.field.name,
      placeholder: this.formConfigService.getFieldLabel(this.field.title),
      required: this.field.validator === "required" ? true : false,
      testingAttribute: `formitem_${this.field.name}`,
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip) || "",
    } as TimePickerConfig);
  };
  prepareDatePicker = () => {
    this.config$.next({
      formControlName: this.field.name,
      placeholder: this.formConfigService.getFieldLabel(this.field.title),
      required: this.field.validator === "required" ? true : false,
      testingAttribute: `formitem_${this.field.name}`,
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip) || "",
    } as DatePickerConfig);
  };

  prepareCheckbox = () => {
    this.config$.next({
      placeholder: this.formConfigService.getFieldLabel(this.field.title),
      formControlName: this.field.name,
      testingAttribute: `formitem_${this.field.name}`,
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip),
      label: this.formConfigService.getTranslationOf(this.field.title),
      //   disabled: this.formConfigService.parsePlaceholders(this.field.disabled),
    } as CheckBoxConfig);
  };

  prepareTextArea = () => {
    this.config$.next({
      placeholder: this.formConfigService.getFieldLabel(this.field.title),
      required: this.field?.validator === "required" ? true : false,
      readonly: this.formConfigService.checkReadonly(this.field),
      testingAttribute: `formitem_${this.field.name}`,
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip) || "",
      formControlName: this.field.name,
      visible: this.formConfigService.getFieldVisibilityState(this.field),
      hint: this.formConfigService.getTranslationOf(this.field.title),
    } as TextAreaConfig);
  };

  prepareLabel() {
    this.config$.next({
      label: this.formConfigService.getFieldLabel(this.field.title),
      formControlName: this.field.name,
      hint: this.formConfigService.getTranslationOf(this.field.hint),
      value: this.field["format_data"]
        ? this.formConfigService.parsePlaceholders(this.field["format_data"])
        : this.form.value[this.field.name] ?? "",
    } as LabelConfig);
  }
  prepareTextInput = () => {
    this.config$.next({
      placeholder: this.formConfigService.getFieldLabel(this.field.title),
      required: this.field?.validator === "required" ? true : false,
      readonly: this.formConfigService.checkReadonly(this.field),
      testingAttribute: `formitem_${this.field.name}`,
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip) || "",
      mask: this.field.mask || "",
      dropSpecialCharacter:
        "dropSpecialCharacter" in this.field
          ? this.field["dropSpecialCharacter"]
          : true,
      formControlName: this.field.name,
      visible: this.formConfigService.getFieldVisibilityState(this.field),
      hint: this.formConfigService.getTranslationOf(this.field.title),
      visibleIf:
        this.formConfigService.validationVisibleIf(
          this.field?.["visible_if"]
        ) || null,
      useNewUi: this.field?.["useNewUi"] || false,
      thousandSeparator: this.thousandSeparator$,
      inputType:
        this.field.type === FormInputTypes.Password ? "password" : "text",
    } as TextBoxConfig);
  };

  prepareColorPicker = () => {
    this.config$.next({
      placeholder: this.formConfigService.getFieldLabel(this.field.title) || "",
      required: this.field?.validator === "required" ? true : false,
      readonly: this.formConfigService.checkReadonly(this.field),
      testingAttribute: `formitem_${this.field.name}`,
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip),
      formControlName: this.field.name,
      color: this.form.controls["color"].value,
    } as ColorPickerConfig);
  };

  prepareUrlInput = () => {
    this.config$.next({
      placeholder: this.formConfigService.getFieldLabel(this.field.title) || "",
      required: this.field?.validator === "required" ? true : false,
      readonly: this.formConfigService.checkReadonly(this.field),
      testingAttribute: `formitem_${this.field.name}`,
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip),
      formControlName: this.field.name,
    } as UrlConfig);
  };

  prepareHtml = () => {
    this.config$.next({
      placeholder: this.formConfigService.getFieldLabel(this.field.title) || "",
      required: this.field?.validator === "required" ? true : false,
      readonly: this.formConfigService.checkReadonly(this.field),
      testingAttribute: `formitem_${this.field.name}`,
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip),
      formControlName: this.field.name,
    } as HtmlConfig);
  };

  prepareDropDown = () => {
    this.config$.next({
      name: this.field.name,
      control: this.form.controls[this.field.name],
      title: this.field.title,
      placeholder: this.formConfigService.getFieldLabel(this.field.title),
      required: this.field?.validator === "required" ? true : false,
      disabled: this.formConfigService.checkReadonly(this.field),
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip),
      multiple: this.field.type === this.formInputType.MultipleSelect,
      options: this.field.dynamic_options ? this.loadDynamicOptions() : [],
      optionValue: this.field.dynamic_options
        ? this.field.dynamic_options?.value_field
        : "value",
      optionLabel: this.field.dynamic_options
        ? this.field.dynamic_options?.label_field
        : "label",
      customError: "",
    } as DropDownConfig);
  };

  prepareLookup = () => {
    this.config$.next({
      name: this.field.name,
      control: this.form.controls[this.field.name],
      title: this.field.title,
      formControlName: this.field.name,
      value: this.formConfigService.getLookupDisplayValue(this.field),
      testingAttribute: `formitem_${this.field.name}`,
      placeholder: this.formConfigService.getFieldLabel(this.field.title),
      required: this.field?.validator === "required" ? true : false,
      disabled: this.formConfigService.parsePlaceholders(this.field.disabled),
      readonly: this.formConfigService.checkReadonly(this.field),
      tooltip: this.formConfigService.getFieldTooltip(this.field.tooltip),
      customError: "",
    } as LookupConfig);
  };

  checkAndSetDefaultValue = () => {
    const defaultValue = this.formConfigService.parsePlaceholders(
      this.field["default_value"],
      true
    );
    let fieldValue = this.formConfigService.parseDefaultOption(
      this.field,
      defaultValue
    );
    if (fieldValue) {
      this.formConfigService.updateFormData({
        ...this.formConfigService.formData,
        [this.field.name]: fieldValue,
      });
      const formField = this.formConfigService.getFormElement(this.field.name);
      formField.setValue(fieldValue);
    }
  };

  loadDynamicOptions = () => {
    if (this.field.dynamic_options) {
      if (this.field.dynamic_options.url) {
        const path = this.formConfigService.parsePlaceholders(
          this.field.dynamic_options.url
        );
        if (path === null) return [];
        if (path.indexOf("//") >= 0) return [];
        this.field$.next({ ...this.field, loading: true });
        let data = [];
        const urlComp = new URL(environment_api.api_url + path);
        urlComp.searchParams.append("lang", this.formConfigService.configLang);
        let url = urlComp.toString();
        if (!this.formConfigService.formData?.["id"]) {
          const defaultValue = this.formConfigService.parsePlaceholders(
            this.field["default_value"]
          );
          if (defaultValue !== undefined) {
            let fieldValue = this.formConfigService.parseDefaultOption(
              this.field,
              defaultValue
            );
            if (fieldValue) {
              this.formConfigService.updateFormData({
                ...this.formConfigService.formData,
                [this.field.name]: fieldValue,
              });
            }
          }
        }
        if (this.formConfigService.formMode === "CREATE") {
          const value = this.formConfigService.parseDefaultOption(
            this.field,
            this.field.default_value
          );
        }

        // this.field.dynamic_options.loading = true;
        this.field$.next({ ...this.field, loading: true });
        this.formConfigService
          .getDropDownItems(url)
          .subscribe((response: any) => {
            data = Array.isArray(response) ? response : response.data;
            this.field = {
              ...this.field,
              dynamic_options: { ...this.field.dynamic_options, data },
              loading: false,
            };
            if (!this.formConfigService.formData?.["id"]) {
              this.checkAndSetDefaultValue();
            }
            // this.field.dynamic_options.loading = false;
            this.field$.next({ ...this.field, data, loading: false });
            return data;
          });
      } else {
        // dynamic options is providing the data statically
        if (this.formConfigService.formMode === "CREATE") {
          this.checkAndSetDefaultValue();
        }
        this.field$.next({
          ...this.field,
          data: this.field.dynamic_options.data,
          loading: false,
        });
        return this.field.dynamic_options.data;
      }
    }
    return [];
  };
}
