// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../../core/base";
// Components
import { CoreModule } from "../../../../core/core.module";

import { NgbAlertModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDropzoneModule } from "ngx-dropzone";
import { CrudTableEffects, crudTableReducer } from "../../../../core/store";
import { CustomDropzonePreviewComponent } from "./custom-dropzone-preview/custom-dropzone-preview.component";
import { CustomDropzoneComponent } from "./custom-dropzone.component";
import { MaterialModule } from "../../../../modules/material/material.module";

@NgModule({
  imports: [
    CoreModule,
    MaterialModule,
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature("crudTable", crudTableReducer),
    EffectsModule.forFeature([CrudTableEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    NgbTooltipModule,
    NgbAlertModule,
    RouterModule,
    NgxDropzoneModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [CustomDropzonePreviewComponent, CustomDropzoneComponent],
  exports: [CustomDropzonePreviewComponent, CustomDropzoneComponent],
})
export class CustomDropzoneModule {}
