// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials/partials.module";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../core/base";
// Shared
// Components

import { UserEffects, usersReducer } from "../../../core/store";
import { MaterialModule } from "../../../modules/material/material.module";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ExternalLicensesComponent } from "./external-licenses/external-licenses.component";
import { PermissionInfoComponent } from "./permission-info/permission-info.component";
import { ReleaseNotesComponent } from "./release-notes/release-notes.component";

const routes: Routes = [];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature("users", usersReducer),
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MaterialModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },

    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [
    ContactUsComponent,
    ReleaseNotesComponent,
    ExternalLicensesComponent,
    PermissionInfoComponent,
  ],
})
export class StaticPagesModule {}
