import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
  template: `
    <div class="image-card">
      <ng-container *ngIf="data?.header?.title?.value">
        <div class="card-title">{{ data.header.title.value }}</div>
      </ng-container>
      <img
        class="card-image"
        [ngStyle]="data.imageCustomCss"
        [src]="
          (displayPlaceholderImage$ | async) === false
            ? data.image
            : data.placeholderImage
        "
        [alt]="data.header.title"
        (error)="displayPlaceholder()"
      />
    </div>
  `,
  styles: [
    `
      .image-card {
        height: 100%;
        position: relative;
        /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12); */
        /* border: 1px solid rgba(0, 0, 0, 0.125); */
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        align-items: center;
      }
      img {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        height: 100%;
      }

      .card-title {
        position: absolute;
        top: 0;
        font-size: 24px;
        width: 100%;
        background: linear-gradient(180deg, black, transparent);
        color: white;
        padding: 12px;
      }
    `,
  ],
})
export class FormImageCardComponent implements OnInit {
  @Input() data;
  displayPlaceholderImage$ = new BehaviorSubject<boolean>(false);

  displayPlaceholder() {
    this.displayPlaceholderImage$.next(true);
  }

  ngOnInit() {}
}
