import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormConfigService } from "../../../form.config.service";
import { TextBoxConfig } from "../models/textbox.config.model";

// text,email,tel,textarea,password,
@Component({
  selector: "cb-textbox",
  template: `
    <!-- *ngIf="config.visible" -->
    <div [formGroup]="form">
      <mat-form-field
        appearance="fill"
        floatLabel="always"
        class="mat-form-field-fluid"
        #formTab
      >
        <mat-label>{{ config.placeholder }}</mat-label>
        <input
          matInput
          [placeholder]="config.placeholder"
          [formControlName]="config.formControlName"
          [required]="config.required"
          [readonly]="config.readonly"
          (change)="onChange($event)"
          [attr.data-pgm-formelement]="config.testingAttribute"
          [matTooltip]="config.tooltip"
          [mask]="config.mask || ''"
          [thousandSeparator]="(config.thousandSeparator | async) || null"
          [dropSpecialCharacters]="config.dropSpecialCharacter"
          [allowNegativeNumbers]="true"
          [type]="config.inputType"
        />
        <span matTextPrefix *ngIf="!!config.tooltip">
          <i
            class="far fa-question-circle"
            matTooltipPosition="above"
            [matTooltip]="config.tooltip"
            style="margin-right: 4px;"
          ></i>
        </span>
        <mat-hint
          *ngIf="!config.readonly"
          align="start"
          [innerHtml]="
            'COMMON.PLEASE_ENTER' | translate : { title: config.hint }
          "
        >
        </mat-hint>
        <mat-error
          *ngIf="form.controls[this.config.formControlName].hasError"
          [innerHtml]="
            form.controls[this.config.formControlName].errors
              | inputErrors : field
          "
        >
        </mat-error>
      </mat-form-field>
    </div>
  `,
})
export class CbTextBoxComponent implements OnInit, OnDestroy {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: TextBoxConfig;

  destroyed$ = new Subject();

  constructor(private formConfigService: FormConfigService) {}
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
  ngOnInit(): void {
    // if (this.config.readonly) {
    //   const formElement = this.formConfigService.getFormElement(
    //     this.field.name
    //   );
    //   if (formElement) {
    //     formElement.disable();
    //   }
    // }
  }

  onChange = (event) => {
    this.formConfigService.updateFormConfigs(event.target.value, this.field);
  };
}
