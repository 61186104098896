import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Subject, of } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { HtmlModalComponent } from "../../../../views/partials/content/html-modal/html-modal.component";
import { FormConfigService } from "../../form.config.service";
import { availableButtons } from "../form-header/availableButtons";
@Component({
  selector: "app-modal-header",
  templateUrl: "./modal-header.component.html",
  styleUrls: ["./modal-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() config: any;
  @Output() onExitEvent = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  message$ = new BehaviorSubject<string>("");
  form: UntypedFormGroup;
  isFormDirty$ = new BehaviorSubject(false);
  isFormSaving$ = new BehaviorSubject(false);
  displayWarningMessage$ = new BehaviorSubject(false);
  destroyed$ = new Subject<void>();
  displayHelpButton$ = new BehaviorSubject(false);
  saveButtons$ = of(availableButtons).pipe(
    takeUntil(this.destroyed$),
    map((buttons) => {
      if (this.config.buttons) {
        const res = buttons.map((button) => {
          const buttonConfig = this.config.buttons[button.name];
          if (buttonConfig) {
            if (!buttonConfig.show) {
              return null;
            }
            return {
              ...button,
              icon: buttonConfig.icon,
              label: buttonConfig.label,
            };
          }
          return button;
        });

        return res.filter((button) => button !== null);
      }
      return buttons;
    })
  );

  constructor(
    private formConfigService: FormConfigService,
    private dialog: MatDialog
  ) {
    this.formConfigService.form$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((form: any) => (this.form = form));
  }

  ngOnInit(): void {
    this.prepareHeader();
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (!this.form.pristine) {
        this.isFormDirty$.next(this.form.dirty);
      }
    });
  }

  submit = ({
    shouldExit = false,
    shouldCreateNew = false,
  }: {
    shouldExit?: boolean;
    shouldCreateNew?: boolean;
  }) => {
    this.onSubmit.emit({ shouldExit, shouldCreateNew });
    this.isFormDirty$.next(false);
  };

  close = () => {
    this.onExitEvent.emit();
  };

  refresh = () => {
    this.onRefresh.emit();
  };

  prepareHeader() {
    if (this.config) {
      this.displayWarningMessage$.next(
        this.config.displayWarningMessage ?? false
      );
      if (this.config.message) {
        this.message$.next(this.config.message);
      } else {
        this.message$.next("Generic Message or empty");
      }

      if (this.config.buttons) {
        const buttonConfig = this.config.buttons;
        this.displayHelpButton$.next(buttonConfig?.help_button ?? false);
      }
    }
  }

  handleInfoToggle() {
    const infoText = this.formConfigService.infoText;
    const url = this.formConfigService.getApiUrl(infoText?.["api_url"]);
    this.dialog.open(HtmlModalComponent, {
      data: {
        config: { ...infoText, apiUrl: url },
        type: "info",
      },
      maxWidth: "800px",
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

// if (this.config.header.buttons) {
//   this.buttons$.next(this.config.header.buttons);
// }
