import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit
} from "@angular/core";

import { BehaviorSubject, Subscription } from "rxjs";
import { SubheaderService } from "./../../../../core/base/services/subheader.service";
import { ApiConfig } from "./../../../../core/config/api.config";
import { User } from "./../../../../core/store";
import { AuthService } from "./../../../../services/auth.service";

@Component({
  selector: "profile-info",
  templateUrl: "./profile-info.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileInfoComponent implements OnInit, OnDestroy {
  apiUrl: string;
  formData: any;
  subs: Subscription;
  cdRef: any;
  user$ = new BehaviorSubject<User>(null);

  constructor(
    private auth: AuthService,
    private subheaderService: SubheaderService
  ) {}

  ngOnInit() {
    // Set title to page breadCrumbs
    this.subheaderService.setTitle("My Profile Info");

		this.apiUrl = ApiConfig.UserProfileInfoURL();
		this.subs = this.auth.getUserByToken().subscribe(res => {
			if (res) {
				this.formData = { id: res.id };
				this.user$.next(res);
			}
		});
	}

  ngOnDestroy() {
    if (this.subs) this.subs.unsubscribe();
  }
}
