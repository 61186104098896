// Angular
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// RxJS
import { Observable } from "rxjs";
// NGRX
// AppState
import { ApiConfig } from "../../../../../core/config/api.config";
// Auth
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../../../../app/services";
import { SubheaderService } from "../../../../../core/base/services/subheader.service";
import {
  ConfirmActionDialogComponent,
  ConfirmActionDialogData,
} from "./../../../../partials/content/confirm-action-dialog/confirm-action-dialog.component";

@Component({
  selector: "laptime-imports-form",
  templateUrl: "./laptime-imports-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaptimeImportsFormComponent implements OnInit {
  apiUrl: string;

  constructor(
    private router: Router,
    private subheaderService: SubheaderService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.apiUrl = ApiConfig.LaptimeImportsURL();

    this.subheaderService.setTitle("Laptime Imports");
  }

  isViewOnly() {
    let url = this.activatedRoute.snapshot.url.join().split(",");
    return url.indexOf("show") > 0;
  }

  dockImport = (data: any): Observable<any> => {
    const apiUrl = this.apiUrl + `/dock-import/${data.id}`;
    return this.apiService.post(apiUrl);
  };

  confirmImport(data: any) {
    const dialogData: ConfirmActionDialogData = {
      title: this.translate.instant("Import all Data"),
      message: {
        DEFAULT: this.translate.instant("COMMON.ARE_YOU_SURE"),
        SUCCESS: this.translate.instant("COMMON.PROCESS_SUCCESS"),
        ERROR: this.translate.instant("COMMON.UNEXPECTED_ERROR"),
      },
      callbackYes: () => {
        return this.import(data);
      },
    };

    let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      data: dialogData,
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      console.log("dialogResult", dialogResult);
      if (dialogResult !== false) {
        this.router.navigate([
          "/user-management/laptime-imports/show",
          data.id,
          "import_data",
        ]);
      }
    });
  }

  import(data: any) {
    const apiUrl = this.apiUrl + `/process-import/${data.id}`;
    return this.apiService.post(apiUrl);
  }
}
