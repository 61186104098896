// Angular
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../../services/api.service';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from '../../../core/base';
// Auth
import { AuthNoticeService } from '../../../core/store';
import { ApiConfig } from '../../../core/config/api.config';
import { get } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	headerLogo: string;
	sidebarImage: string;

	private unsubscribe: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param layoutConfigService: LayoutConfigService
	 * @param authNoticeService: authNoticeService
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2,
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private splashScreenService: SplashScreenService,
		private apiService: ApiService,
		private cdRef: ChangeDetectorRef,) {

		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
			this.sidebarImage = get(cfg, 'login.sidebarimage');
		});
		this.unsubscribe.push(subscr);
	}

	 
	ngOnInit(): void {
		this.translationService.setLanguage(this.translationService.getSelectedLanguage());
		this.headerLogo = this.layoutConfigService.getLogo();

		this.splashScreenService.lock();

		// get frontend config from api
		this.apiService.get(ApiConfig.FrontendConfigURL()).subscribe(result => {
			console.log('FrontendConfigURL', result);
			if (result && result.layout) {
				if (result.layout.logo) this.layoutConfigService.setConfigSetting('self.logo', result.layout.logo);
				if (result.layout.loader_logo) this.layoutConfigService.setConfigSetting('loader.logo', result.layout.loader_logo);
				if (result.layout.login_bg) {
					this.layoutConfigService.setConfigSetting('login.sidebarimage', result.layout.login_bg);
					this.sidebarImage = result.layout.login_bg;
					this.cdRef.detectChanges();
				}
			}
			this.splashScreenService.unlock();
			this.splashScreenService.hide();
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * @param styleUrl
	 */
	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
