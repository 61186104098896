// Angular
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// RxJS
import { Subject } from "rxjs";
// NGRX
import { Store } from "@ngrx/store";
// AppState
import { DatePipe } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { EChartsOption } from "echarts";
import { switchMap } from "rxjs/operators";
import { LocalDatePipe, LocalNumberPipe } from "../../../../../../../core/base";
import { ApiConfig } from "../../../../../../../core/config/api.config";
import { AppState } from "../../../../../../../core/store/reducers/app.reducers";
// Auth
const discountedPrice = "discountedPrice";
const normalPrice = "normalPrice";
@Component({
  selector: "dynamic-prices-trackday-items-table",
  templateUrl: "./dynamic-prices-trackday-items-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicPricesTrackdayItemsTableComponent
  implements OnInit, OnDestroy
{
  apiUrl: string;
  formConfig = [];

  langChangeSubs$: any;
  dataChartLoader$: Subject<void> = new Subject();
  chartLocale: string;

  // chart
  chart;
  updateFromInput = false;
  chartConstructor = "chart";
  chartCallback;
  mergeOptions: EChartsOption;

  chartOption: EChartsOption = {
    xAxis: {
      type: "category",
      data: [],
      axisLabel: {
        rotate: -45,
        formatter: (value: string) => {
          return this.localDate.transform(+value, "date");
        },
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif",
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      axisLabel: {
        fontSize: "13px",
        formatter: (value: string) => {
          return this.localNumber.transform(+value, null);
        },
      },
    },
    series: [
      {
        data: [],
        type: "bar",
        barCategoryGap: 0,
      },
    ],
  };

  // destroy
  private destroy$: any;

  showForm = false;
  initialFormData: any = {};

  @Input() title: string = "Dynamic Prices";
  @Input() trackdayItem: any;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   * @param router: Router
   */
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private datePipe: DatePipe,
    private localDate: LocalDatePipe,
    private localNumber: LocalNumberPipe,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef
  ) {
    const self = this;

    // const routeSubscription = this.activatedRoute.params.subscribe(params => {
    // 	self.trackdayItemId = params.id;
    // });

    this.langChangeSubs$ = this.translateService.onLangChange.subscribe(
      (lang: LangChangeEvent) => {
        self.chartLocale = lang.lang;
        self.updateFromInput = true;
        self.chart.reflow();
      }
    );
  }

  ngOnInit() {
    this.apiUrl = ApiConfig.TrackdayItemsDynamicPricesURL(this.trackdayItem.id);
    this.initChart();
  }

  ngOnDestroy() {
    this.langChangeSubs$.unsubscribe();
    this.dataChartLoader$.unsubscribe();
    if (this.destroy$) this.destroy$.unsubscribe();
  }

  onCreate() {
    this.showForm = true;
    this.initialFormData = null;
  }

  onEdit(item) {
    this.showForm = true;
    this.initialFormData = item;
  }

  initChart() {
    const dataChartUrl = ApiConfig.DynamicPricesTrackdayItemsDataChartURL(
      this.trackdayItem.id
    );

    this.destroy$ = this.dataChartLoader$
      .pipe(
        switchMap(() => {
          const isTestdata = localStorage.getItem("is_testdata");

          let params = new HttpParams();
          params = params.set("isTestdata", isTestdata.toString());
          return this.http.get<any>(dataChartUrl, { params: params });
        })
      )
      .subscribe((results) => {
        const startDate = this.trackdayItem.date_start;
        const price = this.trackdayItem.price;
        let requiredDaysToFill = 0;
        const oneDayInSeconds = 60 * 60 * 24;

        //@ts-ignore
        const diff = new Date(startDate) - new Date();
        if (diff > 0) {
          const diffInSeconds = diff / 1000;
          if (diffInSeconds > oneDayInSeconds) {
            // today is before the track day start date
            requiredDaysToFill = diffInSeconds / oneDayInSeconds;
          }
        }

        let parsedData = Object.entries(results.data_chart)
          .map((e) => {
            // @ts-ignore
            return [...e[1], discountedPrice];
          })
          .map((r) => [Date.parse(r[0]), r[1]])
          .sort((a, b) => a[0] - b[0]);
        const chartDate = [];
        if (requiredDaysToFill > 0) {
          const date = new Date();

          while (true) {
            // @ts-ignore
            let dayDiff = (new Date(startDate) - date) / 1000;
            if (dayDiff > 60 * 60 * 9) {
              const today = `${date.getFullYear()}-${
                date.getMonth() + 1
              }-${date.getDate()}`;
              if (results.data_chart[today]) {
                const dicountedItem = results.data_chart[today];
                chartDate.push([
                  new Date(dicountedItem[0]).getTime(),
                  dicountedItem[1],
                  discountedPrice,
                ]);
              } else {
                chartDate.push([date.getTime(), price, normalPrice]);
              }
            } else {
              break;
            }

            date.setDate(date.getDate() + 1);
          }
          parsedData = chartDate;
        }

        this.mergeOptions = {
          series: {
            data: parsedData.map((e) => {
              return {
                value: e[1],
                itemStyle: {
                  color: e[2] !== discountedPrice ? "#007bff" : "#e3001b",
                },
              };
            }),
          },
          xAxis: {
            data: parsedData.map((e) => e[0]),
          },
          tooltip: {
            formatter: (params) => {
              const currencyPrefix =
                results.currency_unit || results.currency_name;
              const header = this.localDate.transform(
                +params.name,
                "date",
                "fullDate"
              );
              const footer = this.translateService.instant(
                "CHART.TRACKDAY_ITEM.CANCELLATION_PRICES.TOOLTIP",
                {
                  price:
                    "<b>" +
                    currencyPrefix +
                    this.localNumber.transform(params.value, null) +
                    "</b>",
                }
              );
              return header + "<br>" + footer;
            },
          },
          yAxis: {
            name: this.translateService.instant(
              "CHART.TRACKDAY_ITEM.CANCELLATION_PRICES.YAXIS_TITLE",
              { currency: results.currency_name }
            ),
            nameLocation: "middle",
            nameRotate: 90,
            nameGap: 70,
          },
        };

        this.cdRef.markForCheck();
      });
  }

  loadChartData() {
    this.dataChartLoader$.next();
  }
}
