import { ProcessButton, ConfirmModel } from "../../../shared/data/models";
import { get } from "lodash";
// note about the context menu
// currently there are 3 places that use context menu
// 1. crud-table
// 2. form shell
// 3. cb-cards (view of the table)
// currently the crud-table does not use the context menu (it has its own implementation)
// as for the form, and cb-cards they both use the same implementation
// and when it comes to the issue of the `selected` (selected is the entity that the current button is working on),
// form and cards, can only have 1 selected item
// so passing the `itemId`, and `selected`, should suffice and mandatory
// later on if we need to support multiple selected items, we can add it to the api somehow

/**
 *
 * @param buttons button config from the api
 * @param data an object containing the id of the selected item and the form data
 * @param placeholderParser parse placeholders, when ever there is a placeholder in the config it will be replaced with the value from the selected item
 * @param urlBuilder helper function, might be redundant
 * @returns array of buttons
 */
export const contextMenuButtonParser = (
  buttons,
  data: { id: string; formData: any },
  placeholderParser: Function,
  urlBuilder?: Function
): ProcessButton[] => {
  if (!buttons) return [];
  if (!placeholderParser || !urlBuilder) return buttons;
  const processButtons = buttons.map((button) => {
    return {
      itemId: data.id,
      selected: [data.formData],
      children: button["children"]
        ? contextMenuButtonParser(
            button["children"],
            data,
            placeholderParser,
            urlBuilder
          )
        : [],
      icon: getButtonIconClass(button, data.formData),
      processType: button["process_type"] ?? "",
      type: button["type"] ?? "primary",
      title: button["title"] ?? "",
      tooltip: button["tooltip"] ?? "",
      contextMenu: button["context_menu"] ?? false,
      confirm: parseConfirmModal(button),
      emailDialog: button["email_dialog"]
        ? {
            display_field: placeholderParser(button["display_field"]),
            id_fields: button["email_dialog"]["id_field"] ?? [
                button["id_field"],
              ] ?? ["id"],
            callbacks: button["email_dialog"]["callbacks"],
          }
        : null,
      form: button?.["form"]
        ? {
            config: button?.["form"]["config"],
            callback: {
              url: urlBuilder(button?.["form"]["callback"]["url"]),
              method: button?.["form"]["callback"]["method"],
            },
            buttons: button?.["form"]["buttons"],
            displayWaringMessage: button?.["form"]["display_warning_message"],
            message: button?.["form"]["message"],
            viewMode: button?.["form"]["view_mode"],
          }
        : null,
      actionLinkConfig: button["action_link"] ?? null,
      infoDialog: button["info_dialog"]
        ? {
            title: button["info_dialog"]["title"],
            apiUrl: placeholderParser(button["info_dialog"]["api_url"]),
            display_refresh_button:
              button["info_dialog"]["display_refresh_button"] ?? true,
            id_fields: button["info_dialog"]["id_field"] ?? ["id"],
          }
        : null,
      callback: button["callback"]
        ? {
            url: urlBuilder(placeholderParser(button["callback"]["url"], data)),
            method: button["callback"]["method"],
            idField: button["callback"]["id_field"] ?? "id",
          }
        : null,
      visibleIf: button?.["visible_if"]
        ? placeholderParser(button?.["visible_if"])
        : true,
      isBeingProcessed: false,
      isContextMenuButton: button?.context_menu ?? false,
      isSpecialType: button?.["isSpecialType"] ?? null,
    } as ProcessButton;
  });

  return processButtons;
};

const parseConfirmModal = (button): ConfirmModel | null => {
  if (!button["confirm"]) {
    return null;
  }

  return {
    cancelLabel: button["confirm"]["cancel_label"] || "",
    message: button["confirm"]["message"],
    okLabel: button["confirm"]["ok_label"],
    paramDisplayName: button["confirm"]["param_display_name"] || null,
    paramName: button["confirm"]["param_name"] || null,
    paramType: button["confirm"]["param_type"] || null,
    method: "POST", // TODO SHAAHIN this is hardcoded // not sure what it is
  };
};

export const prepareExtraPayloadForForm = (button: ProcessButton) => {
  const data = button.selected;
  if (!data.length) return null;
  const idField = button.form?.callback?.idField ?? "id";
  return {
    selected: data.map((item) => item[idField]).join(","),
  };
};

export const getButtonIconClass = (button: ProcessButton, item) => {
  const { icon } = button;
  if (icon?.indexOf("{") == 0) {
    const evalStr = icon.slice(1, icon.length - 1);
    return (function (item, get) {
      return eval(evalStr);
    })(item, get);
  }
  return icon;
};
