// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { CrudTableActions, CrudTableActionTypes } from "../actions/crud-table.actions";
// CRUD
import { QueryParamsModel } from '../../base';


// tslint:disable-next-line:no-empty-interface
export interface CrudTableState extends EntityState<any> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedItemId: number;
  lastUpdatedItemId: number;
  lastDeletedItemId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  serverErrors: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialCrudTableState: CrudTableState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  lastCreatedItemId: undefined,
  lastUpdatedItemId: undefined,
  lastDeletedItemId: undefined,
  showInitWaitingMessage: true,
  serverErrors: undefined

});

export function crudTableReducer(state = initialCrudTableState, action: CrudTableActions): CrudTableState {
  switch (action.type) {
    case CrudTableActionTypes.PageToggleLoading: return {
      ...state, listLoading: action.payload.isLoading, lastCreatedItemId: undefined
    };
    case CrudTableActionTypes.CrudActionToggleLoading: return {
      ...state, actionsloading: action.payload.isLoading
    };
    case CrudTableActionTypes.ItemOnServerCreated: return {
      ...state
    };
    case CrudTableActionTypes.ItemOnServerUpdated: return adapter.addOne(action.payload, {
      ...state, lastUpdatedItemId: action.payload.item.id
    });
    case CrudTableActionTypes.ItemCreated: return adapter.addOne(action.payload, {
      ...state, lastCreatedItemId: action.payload.item.id
    });
    case CrudTableActionTypes.ItemUpdated: return adapter.updateOne(action.payload.item.id, state);
    case CrudTableActionTypes.ItemDeleted: return adapter.removeOne(action.payload.id, state);
    case CrudTableActionTypes.ItemOnServerDeleted: return adapter.removeOne(action.payload.id, state);
    case CrudTableActionTypes.PageCancelled: return {
      ...state, listLoading: false, lastQuery: new QueryParamsModel({})
    };
    case CrudTableActionTypes.PageLoaded: {
      return adapter.addMany(action.payload.items, {
        ...initialCrudTableState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false
      });
    };
    case CrudTableActionTypes.CrudActionServerError:
      const errors = action.payload.errors;
      console.log('reducer', action, { ...errors });
      return {
        ...state,
        serverErrors: { ...errors }
      };
      break;
    default: return state;
  }
}

export const getCrudTableState = createFeatureSelector<CrudTableState>('crudTable');

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();
