import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewRef,
  inject,
} from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatSort } from "@angular/material/sort";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

// Translate Module
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { merge as _merge, get, has, set } from "lodash";
import * as moment from "moment";
import {
  BehaviorSubject,
  Subject,
  Subscription,
  empty,
  fromEvent,
  merge,
  of,
} from "rxjs";
// RXJS
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";

import { HtmlContentDialogComponent } from "..";
import { QueryParamsModel } from "../../../../core/base/models";
// CRUD
import {
  CrudUtilsService,
  HttpUtilsService,
  LayoutUtilsService,
} from "../../../../core/base/utils";
import { ApiConfig } from "../../../../core/config/api.config";
import { CrudTableStore } from "../../../../core/store";
// Services and Models
import { ItemDeleted } from "../../../../core/store/actions/crud-table.actions";
import { EventEmitterService } from "../../../../event-emitter.service";
import { I18nService } from "../../../../services/i18n.service";
import { TableConfigService } from "../../../../services/table.config.service";
import { PermissionGroupDescriptionComponent } from "../../../pages/permission-keys-management/permission-groups/table/_subs/permission-group-description/permission-group-description.component";
import { ServiceFormComponent } from "../../../pages/services-management/service/service-form/service-form.component";
// Components
import { Store, select } from "@ngrx/store";
import { TableUserConfigService } from "../../../../core/base/utils/table.user-config.service";
import { helpSidebarActions } from "../../../../core/store/actions/help-sidebar.actions";
import { reloadControllerActions } from "../../../../core/store/actions/reload-controller.actions";
import { AppState } from "../../../../core/store/reducers/app.reducers";
import { reloadControllerSelectors } from "../../../../core/store/selectors/reload-controller.selectors";
import { FormsShellModalComponent } from "../../../../modules/cb-forms/components/forms-shell-modal/forms-shell-modal.component";
import { KanbanBoardComponent } from "../../../../modules/project-management/components/kanban-board/kanban-board.component";
import { SendEmailComponent } from "../../../../modules/send-email/components/send-email/send-email.component";
import { CommonService } from "../../../../services/common.service";
import { AddReplaceDialogResultType } from "../../../../shared/data/models";
import { CrudFormComponent } from "../crud-form/crud-form.component";
import { ExportColumnConfigComponent } from "../export-column-config/export-column-config.component";
import { HtmlModalComponent } from "../html-modal/html-modal.component";
import { InfoDialogComponent } from "../info-dialog/info-dialog.component";
import { environment_api } from "./../../../../../environments/environment.api";
import { CrudTableService } from "./../../../../services";
import { ApiService } from "./../../../../services/api.service";
import { LocalStorageService } from "./../../../../services/local-storage.service";
import { PresetCancellationPricesTableComponent } from "./../../../pages/preset-cancellation-price-management/preset-cancellation-prices/preset-cancellation-prices-table/preset-cancellation-prices-table.component";
import { PresetDynamicPricesTableComponent } from "./../../../pages/preset-dynamic-price-management/preset-dynamic-prices/preset-dynamic-prices-table/preset-dynamic-prices-table.component";
import { TrackdayBookingsInfoDialogComponent } from "./../../../pages/trackdays-management/trackday-bookings/trackday-bookings-info-dialog/trackday-bookings-info-dialog.component";
import { VirtualGarageTableComponent } from "./../../../pages/virtualgarage-management/table/virtualgarage-table.component";
import { ConfirmActionDialogComponent } from "./../confirm-action-dialog/confirm-action-dialog.component";
import { CrudFormDialogComponent } from "./../crud-form/_sub/crud-form-dialog.component";
import { TableColumnConfigComponent } from "./../table-column-config/table-column-config.component";
import { CrudTableDataSource2 } from "./crud-table.datasource";
import { MatColumnDef, MatTable } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { appGlobalConfigSelectors } from "../../../../core/store/selectors/app.global.config.selectors";

// Table with EDIT item in MODAL
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/compgetItemCssClassByStatusonents/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
  // tslint:disable-next-line:component-selector
  selector: "crud-table",
  templateUrl: "./crud-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./crud-table.component.scss"],
  providers: [CrudTableStore, TableUserConfigService],
})
export class CrudTableComponent implements OnInit, OnDestroy, AfterViewInit {
  hasResult = null;
  filteredString = "";
  appStore = inject(Store<AppState>);
  shouldTableDisplayZeroValue$ = this.appStore.pipe(
    select(appGlobalConfigSelectors.selectShouldTablesDisplayZero)
  );
  shouldTableDisplayZeroValue: boolean;

  @Input() apiUrl: string;
  @Input() baseUrl: string;
  @Input() columns: any[] = [];
  @Input() createButtonLabel: string;
  @Input() dataSource: CrudTableDataSource2;
  @Input() searchableColumns: string[] = [];
  @Input() name: string;
  @Input() configName: string;
  @Input() configNameClient: string | null = null;
  @Input() resolveData: any;

  @Input() allowMultiSelect: boolean = true;
  @Input() readonly: boolean;
  @Input() hasReadonlyActions = false;
  @Input() hideHeader: boolean;
  @Input() hideFormFilters: boolean;
  @Input() hideTitle = false;
  @Input() relatedId: string;
  @Input() relatedField: string;
  @Input() showAddBtn = true;
  @Input() allowEdit = true;
  @Input() allowExport = false;
  @Input() allowDelete = true;
  @Input() allowAdd = true;
  @Input() masterData: any;
  @Input() searchFilterParams: string;
  @Input() alwaysShowCheckboxes = false;
  @Input() displayLayoutSwitcher = false;
  @Input() disableFilters = false;
  @Input() portletComponentIdentifier: number = null;

  maxPaginationSize = 100;

  // fix: tooltip issue
  private _title: string;
  @Input() get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
    this.elementRef.nativeElement.title = "";
  }

  //
  @Input() formConfig: any;

  // sub-form indicates that is rendered inside tabs
  // Pls check participants-trackday-items for sample implementation
  @Input() isSubForm = false;

  customFilters: CrudTableCustomFilter[];
  processButtons: CrudTableProcessButton[];
  childrenProcessButtons: any = {};
  tableMenuButtons: CrudTableProcessButton[];
  notes: any;

  htmlHeader$ = new BehaviorSubject<any>(null);
  htmlSidebar$ = new BehaviorSubject<any>(null);

  @ViewChild("paginatorElement", { read: ElementRef, static: true })
  paginatorHtmlElement: ElementRef;
  @ViewChild("table") table: MatTable<any>;
  private _customActionBtns: TemplateRef<any>;
  get customActionBtns(): TemplateRef<any> {
    return this._customActionBtns;
  }
  @Input()
  set customActionBtns(value: TemplateRef<any>) {
    this._customActionBtns = value;
  }

  // visible columns
  @Input() displayedColumns: string[] = [];
  @Output() columnsLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoadTableList: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBeforeLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLayoutChange: EventEmitter<any> = new EventEmitter<string>();
  @Output() onLoadingStateChange = new EventEmitter<{
    status: boolean;
    componentIdentifier: number;
  }>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  lastVisitedTablePage$: BehaviorSubject<number> = new BehaviorSubject(0);

  @ViewChild("sort1", { static: true }) sort: MatSort;

  // Filter fields
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;

  // column selection option
  @ViewChild("gridColSelection", { static: true }) gridColSelection: any;

  @ViewChild("matColumnActions", { read: MatColumnDef, static: true })
  columnDef: MatColumnDef;

  @HostListener("document:click", ["$event"])
  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      if (element.className === "cb-router-link") {
        event.preventDefault();
        const route = element?.getAttribute("href");
        if (route) {
          this.router.navigate([`/${route}`]);
        }
      }
    }
  }

  isInQuickColumnEditorMode$ = new BehaviorSubject(false);

  columnNames: string[] = [];
  filterType = "";
  isTestdata: any;
  docType = "";
  exportLink = "";
  actionColumnWidth: "auto";

  // Selection
  selection: SelectionModel<any>;
  selectedItems$ = new BehaviorSubject<any[]>([]);
  tableList: any[] = [];
  isLoadingResults = false;
  isLoadingTranslations = false;
  relationId: any;
  invertebrates = "invertebrates";

  // Subscriptions
  private subscriptions: Subscription[] = [];
  private initDisplayColumns: any;
  pageSize: any;
  pageSizeOptions: any;
  langChangeSubs$: any;
  infotext: any;
  translations: any;
  info: boolean = true;
  lang: string;
  showInfoText = false;

  private dialogRef = null;
  private dialogData;
  filterStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  filterBadgeObject = [];

  tableConfigUrl: string = null;

  // column reordering
  columnPreviousIndex: number;
  destroyed$ = new Subject<void>();
  reloadController$ = this.appStore.pipe(
    takeUntil(this.destroyed$),
    select(reloadControllerSelectors.selectTargetComponent)
  );
  /**
   * Component constructor
   *
   * @param dialog: MatDialog
   * @param layoutUtilsService: LayoutUtilsService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   */
  constructor(
    private elementRef: ElementRef,
    private store: CrudTableStore,
    public crudTableService: CrudTableService,
    private dateAdapter: DateAdapter<any>,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    public i18nService: I18nService,
    public tableConfigService: TableConfigService,
    public router: Router,
    private eventEmitterService: EventEmitterService,
    private cRef: ChangeDetectorRef,
    private http: HttpClient,
    private injector: Injector,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    public crudUtilsService: CrudUtilsService,
    private titleService: Title,
    public httpUtilService: HttpUtilsService,
    private activatedRoute: ActivatedRoute,
    private tableUserConfigService: TableUserConfigService,
    private commonService: CommonService
  ) {
    this.isTestdata = localStorage.getItem("is_testdata") || 0;

    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);

    if (this.dialogData && this.dialogData.crudTable) {
      this.title = this.dialogData.crudTable.title;
      this.name = this.dialogData.crudTable.name;
      this.configName = this.dialogData.crudTable.configName;
      this.relatedId = this.dialogData.crudTable.relatedId;
      this.apiUrl = this.dialogData.crudTable.apiUrl;
      this.baseUrl = this.dialogData.crudTable.baseUrl;
      this.readonly = this.dialogData.crudTable.readonly;
      this.alwaysShowCheckboxes =
        this.dialogData.crudTable.alwaysShowCheckboxes;
      if (this.dialogData.crudTable.allowMultiSelect != null)
        this.allowMultiSelect = this.dialogData.crudTable.allowMultiSelect;
      this.formConfig = this.dialogData.crudTable.formConfig;
      //this.setPageTitle(this.title);
    }

    this.selection = new SelectionModel<any>(this.allowMultiSelect, []);
  }

  setPageTitle(title: string) {
    if (title) {
      this.titleService.setTitle(title);
    } else {
      this.titleService.setTitle("PGMware");
    }
  }

  // shortHand: parsePlaceholder
  // __p(text: string|any, data: any) {
  //     return this.crudUtilsService.parsePlaceholders(text, data);
  // }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  ngAfterViewInit() {
    this.shouldTableDisplayZeroValue$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.shouldTableDisplayZeroValue = res;
      });
    this.reloadController$.subscribe((target) => {
      if (target === this.name) {
        this.refresh();
        this.appStore.dispatch(reloadControllerActions.resetTargetComponent());
      }
    });
  }

  print(model) {
    console.log(model);
  }

  /**
   * On init
   */
  ngOnInit() {
    // if (!this.hideHeader)
    //this.subheader.setTitle(this.translate.instant(this.title));
    this.onBeforeLoad.emit(null);

    if (!this.configName) this.configName = this.name;
    if (!this.columns) this.columns = [];
    if (!this.searchableColumns) this.searchableColumns = [];

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadTableList(false, true)))
      .subscribe();
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      "keyup"
    )
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((e) => {
          this.paginator.pageIndex = 0;
          this.onFilterChange("search");
        })
      )
      .subscribe();
    let entitiesSubscription;

    // selection change
    this.subscriptions.push(
      this.selection.changed.subscribe((r) => {
        this.selectedItems$.next(r.source.selected);
        this.onSelectionChange.emit(r.source.selected);
      })
    );

    this.tableConfigService
      .getTableConfig(this.translate.currentLang, this.configName)
      .subscribe((res) => {
        const config = { translations: {} };
        let active = "" as const;
        let direction = "" as const;
        this.tableUserConfigService.initiateConfig({
          tableName: this.configNameClient ?? this.configName,
          tableConfig: res,
        });

        // load pagination cache
        const paginationValues = this.getPaginationCache();
        if (paginationValues == null) {
          this.tableConfigService
            .getTablePaginationConfig("en", "pagination")
            .subscribe((res) => {
              const pagination = res.config[0];
              this.pageSize = pagination.pageSize;
              this.paginator.pageSize = pagination.pageSize;
              this.pageSizeOptions = pagination.pageSizeOptions;
            });
        } else {
          this.pageSize = paginationValues.pageSize;
          this.paginator.pageSize = paginationValues.pageSize;
          this.paginator.pageIndex = 0;
          this.pageSizeOptions = paginationValues.pageSizeOptions;
        }

        if (res.config.default_title) this.title = res.config.default_title;
        //this.setPageTitle(this.title);
        this.customFilters = (res.config.filters || []).map((r) => {
          return new CrudTableCustomFilter(
            this,
            this.translate.currentLang,
            r,
            this.localStorageService.getItem(this.configName)
          );
        });

        this.processButtons = (res.config.process_buttons || []).map(
          (r, index) => {
            r.indexForChildrenProcessButtons = `sub_menu_${index}`;
            let children = [];
            if (r?.children && r.children.length > 0) {
              this.childrenProcessButtons[r.indexForChildrenProcessButtons] =
                r.children.map((g) => {
                  const funcTranslate = (h: string): string => {
                    return h;
                  };
                  const funcCrefDetectChanges = () => this.crefDetectChanges();
                  return new CrudTableProcessButton(
                    this,
                    this.http,
                    this.dialog,
                    this.translate,
                    this.layoutUtilsService,
                    g,
                    this.crudUtilsService,
                    (t) => this.getConfigTranslate(t),
                    () => this.crefDetectChanges()
                  );
                });
              children =
                this.childrenProcessButtons[r.indexForChildrenProcessButtons];
            }
            const funcTranslate = (s: string): string => {
              return s;
            };
            const funcCrefDetectChanges = () => this.crefDetectChanges();
            return new CrudTableProcessButton(
              this,
              this.http,
              this.dialog,
              this.translate,
              this.layoutUtilsService,
              r,
              this.crudUtilsService,
              (s) => this.getConfigTranslate(s),
              () => this.crefDetectChanges(),
              children
            );
          }
        );

        this.tableMenuButtons = (res.config.table_menu || []).map((r) => {
          const funcTranslate = (s: string): string => {
            return s;
          };
          const funcCrefDetectChanges = () => this.crefDetectChanges();
          return new CrudTableProcessButton(
            this,
            this.http,
            this.dialog,
            this.translate,
            this.layoutUtilsService,
            r,
            this.crudUtilsService,
            (s) => this.getConfigTranslate(s),
            () => this.crefDetectChanges()
          );
        });

        this.notes = res.config.notes;

        if (res.config.searchable_fields)
          this.searchableColumns = res.config.searchable_fields;

        if (res.config.action_column_width) {
          this.actionColumnWidth = res.config.action_column_width;
        }

        if (res.config.allow_add != null) {
          this.allowAdd = !!res.config.allow_add;
        }

        if (res.config.allow_edit != null) {
          this.allowEdit = !!res.config.allow_edit;
        }

        if (res.config.allow_export != null) {
          this.allowExport = !!res.config.allow_export;
        }

        if (res.config.allow_delete != null) {
          this.allowDelete = !!res.config.allow_delete;
        }

        if (res.config.disable_filters != null) {
          this.allowDelete = !!res.config.disable_filters;
        }

        if (res.config.show_heading != null) {
          this.hideTitle = !res.config.show_heading;
        }
        config.translations = res.config.translations;
        this.translations = res.config.translations;

        res.config.field.forEach((response, idx) => {
          if (this.columns.find((r) => r.name == response.name)) return;

          this.columns.push({
            ...response,
            name: response.name,
            text: response.title,
            type: response.type,
            format:
              response.format ||
              (response.digits || "0") +
                "." +
                (response.decimals || "0") +
                "-" +
                (response.decimals || "0"),
            prefix: response.prefix,
            suffix: response.suffix,
            html: response.html,
            // translate: this.getConfigTranslate(response.title),
            translate: response.translate,
            translatedColumnName: this.getConfigTranslate(response.title),
            order: this.tableUserConfigService.getColumnInitialOrder(
              response.name,
              idx
            ),
            visible: this.tableUserConfigService.getColumnInitialVisibility(
              response.name
            ),
          });

          //get order default
          if (response.orderby !== undefined) {
            console.log("getTableConfig", response.orderby);
            active = response.orderby;
            direction = response.direction;
          }
        });

        this.columns.sort((a, b) => (a.order > b.order ? 1 : -1));
        this.tableUserConfigService.setTableColumns(this.columns);
        this.prepareInitialColumns();
        this.columnsLoaded.emit(this.columns);
        const count = this.countNumberOfEligibleButtons();
        const visibleColumnNames = this.columns
          //   .filter((r) => r.visible)
          .map((r) => r.name);
        if (this.readonly) {
          if (count === 0) {
            this.columnNames = [...visibleColumnNames];
          } else {
            this.columnNames = ["select", ...visibleColumnNames];
          }

          if (this.hasReadonlyActions) {
            this.columnNames.push("actions");
          }
        } else {
          if (count === 0) {
            this.columnNames = [...visibleColumnNames, "actions"];
          } else {
            this.columnNames = ["select", ...visibleColumnNames, "actions"];
          }
        }

        if (!this.initDisplayColumns && !this.displayedColumns)
          this.displayedColumns = this.columnNames;

        // this.i18nService.translations.subscribe((res) => {
        //     if (res == null) return;
        //     const translations = Object.values(res);

        //     translations.forEach((text, index) => {
        //         this.columns[index].text = text;
        //     });

        //     this.isLoadingTranslations = false;
        // });

        // this.i18nService
        //     .getTranslation(
        //         this.columns.map((column) => column.text),
        //         "en"
        //     )
        //     .subscribe((res) => {
        //         const translations = Object.values(res);

        //         translations.forEach((text, index) => {
        //             this.columns[index].text = text;
        //         });

        //         this.isLoadingTranslations = false;
        //     });
        // trigger value change
        this.columns = [...this.columns];

        //get order default
        if (!res.config.default) res.config.default = {};
        const { orderby, dir } = this.getSortOrderAndDirection({
          currentActive: active,
          currentDirection: direction,
          orderBy: res.config.default.orderby,
          direction: res.config.default.direction,
        });

        this.sort.active = orderby;
        this.sort.direction = dir;

        this.loadTableList(true, false); // this seems to be the first time this function is called
        //infotext
        this.infotext = res.config.infotext;

        if ("header_content" in res.config) {
          this.htmlHeader$.next(
            this.parseHtmlSectionConfig({
              config: res.config.header_content,
              position: "header",
            })
          );
        }
        if ("sidebar_content" in res.config) {
          this.htmlSidebar$.next(
            this.parseHtmlSectionConfig({
              config: res.config.sidebar_content,
              position: "sidebar",
            })
          );
        }
      });

    this.initTableListLoader(this.name);
    this.dataSource = new CrudTableDataSource2(this.store);
    this.dataSource.loading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        if (this.portletComponentIdentifier != null) {
          // emit
          this.onLoadingStateChange.emit({
            status: res,
            componentIdentifier: this.portletComponentIdentifier,
          });
        }
      });
    // entitiesSubscription = this.dataSource.entitySubject
    //     //.pipe(skip(1), distinctUntilChanged())
    //     .subscribe((res) => {
    //         console.log('entitiesSubscription.res', res);
    //         this.tableList = res;
    //         this.isLoadingResults = false;
    //         if (res.length === 0) {
    //             this.dataSource.hasItems = false;
    //         }
    //         console.log('this.dataSource', this.dataSource);
    //         this.crefDetectChanges();
    //     });
    this.subscriptions.push(sortSubscription);
    this.subscriptions.push(paginatorSubscriptions);
    this.subscriptions.push(searchSubscription);
    //this.subscriptions.push(entitiesSubscription);

    //switch data
    if (this.eventEmitterService.subsVar == undefined) {
      let entitiesSubscription =
        this.eventEmitterService.invokeSwitchTheData.subscribe(
          (name: string) => {
            this.switchTheData();
          }
        );
      this.subscriptions.push(entitiesSubscription);
    }

    // set initial displayed columns if given
    if (this.initDisplayColumns)
      this.displayedColumns = this.initDisplayColumns;

    // selected language change
    this.langChangeSubs$ = this.translate.onLangChange.subscribe(
      (lang: LangChangeEvent) => {
        this.lang = lang.lang;
        this.loadTableList();
        this.dateAdapter.setLocale(lang.lang);
      }
    );
    this.lang = this.translate.currentLang;

    this.dateAdapter.setLocale(this.translate.currentLang || "en");
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    if (this.langChangeSubs$) this.langChangeSubs$.unsubscribe();
    this.subscriptions.forEach((el) => el.unsubscribe());
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  handleLayoutChange(layout: string) {
    this.tableUserConfigService.updateCrudLayoutState(layout, () =>
      this.onLayoutChange.emit(layout)
    );
  }

  private parseHtmlSectionConfig = (configuration: {
    config: any;
    position: "header" | "sidebar";
  }) => {
    const { config, position } = configuration;
    if (config["api_url"] === "") {
      return {
        show: false,
      };
    }
    const width =
      config["width"] || (position === "sidebar" ? "300px" : "100%");
    const apiUrl =
      environment_api.api_url +
      this.crudUtilsService.parsePlaceholders(config.api_url, this.masterData);
    return {
      show: true,
      apiUrl: apiUrl,
      width: width,
      height: config["height"] || "100%",
      shouldDisplayRefreshButton:
        "display_refresh_button" in config
          ? config["display_refresh_button"]
          : true,
    };
  };

  showInfo() {
    const url =
      environment_api.api_url +
      this.crudUtilsService.parsePlaceholders(this.infotext?.["api_url"], {
        lang: this.translate.currentLang,
      });
    // this.dialog.open(HtmlModalComponent, {
    //   data: {
    //     config: { ...this.infotext, apiUrl: url },
    //     type: "info",
    //   },
    //   maxWidth: "800px",
    // });
    this.appStore.dispatch(helpSidebarActions.setTargetUrl({ targetUrl: url }));
    this.appStore.dispatch(helpSidebarActions.openSidebar());
  }

  /**
   * on first load, we are using this function to determine the initial columns
   * previously, these were calculated in the btnDropdown component
   */
  private prepareInitialColumns() {
    let columns = this.columns.filter((r) => r.visible).map((r) => r.name);
    if (columns.length === 0) {
      columns = this.columns.map((r) => r.name);
    }
    this.updateDisplayColumns(columns);
  }
  onQuickColumnReorder(event: CdkDragDrop<any>) {
    if (event.currentIndex === event.previousIndex) return;

    if (event) {
      let { previousIndex, currentIndex } = event;
      if (previousIndex === currentIndex) return;

      const order = this.columnNames.indexOf(event.item.data.name) || 0;
      moveItemInArray(this.displayedColumns, order, currentIndex);
      //   this.tableUserConfigService.updateColumnsOrder(this.columnNames);
      let columns = this.displayedColumns;
      //   let columns = this.columnNames;
      let allColumns = this.tableUserConfigService
        .getTableColumns()
        .map((e) => {
          const found = columns.find((c) => c === e.name);
          if (found) {
            return {
              ...e,
              order: columns.indexOf(found),
            };
          }
          return { ...e, order: 1000 };
        })
        .sort((a, b) => a.order - b.order);

      this.columnNames = allColumns.map((r) => r.name);
      this.updateDisplayColumns(columns.map((r) => r));

      this.cRef.detectChanges();
      this.tableUserConfigService.updateColumns({
        columns: columns,
        columnOrder: allColumns.map((r) => r.name),
        allColumns,
      });
    }
  }

  quickHideColumn(column) {
    let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        //title: this.crudComponent.getConfigTranslate(this.title),
        title: this.getConfigTranslate("COMMON.ARE_YOU_SURE"),
        message: this.getConfigTranslate("COMMON.DELETE_COLUMN_MESSAGE"),
        //waitDesciption: this.translate.instant('COMMON.PROCESSING_PLS_WAIT'),
        btnYesLabel: this.getConfigTranslate("COMMON.YES"),
        btnNoLabel: this.getConfigTranslate("COMMON.NO"),
        // paramName: this.confirm.param_name,
        // paramType: this.confirm.param_type,
        // paramDisplayName: this.confirm.param_display_name,
      },
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      //
      if (dialogResult) {
        let columns = this.displayedColumns.filter((r) => r !== column);
        let allColumns = this.tableUserConfigService
          .getTableColumns()
          .map((r) => {
            if (r.name === column) r.visible = false;
            return r;
          });

        this.updateDisplayColumns(columns);
        this.columnNames = columns;
        this.tableUserConfigService.updateColumns({
          columns: columns,
          columnOrder: allColumns.map((r) => r.name),
          allColumns,
        });
      }
    });
  }

  private getSortOrderAndDirection({
    currentActive,
    currentDirection,
    orderBy,
    direction,
  }) {
    let locallyStoredSortSettings = localStorage.getItem(this.name);
    let storedSortField = orderBy;
    let storedSortDirection = direction;
    const localStoredSettingsParsed = JSON.parse(locallyStoredSortSettings);
    if (locallyStoredSortSettings) {
      if (localStoredSettingsParsed.sortField) {
        if (
          this.doesSortFieldExistsInCurrentColumns(
            localStoredSettingsParsed.sortField
          )
        ) {
          storedSortField = localStoredSettingsParsed.sortField;
        }
      }
      if (localStoredSettingsParsed.sortOrder) {
        storedSortDirection = localStoredSettingsParsed.sortOrder;
      }
    }

    const toReturn = {
      orderby: currentActive ? currentActive : storedSortField || "",
      dir: currentDirection ? currentDirection : storedSortDirection,
    };
    if (localStoredSettingsParsed) {
      localStorage.setItem(
        this.name,
        JSON.stringify({
          ...localStoredSettingsParsed,
          sortField: toReturn.orderby,
          sortOrder: toReturn.dir,
        })
      );
    }

    return toReturn;
  }

  private doesSortFieldExistsInCurrentColumns(sortField: string): boolean {
    return this.columns.some((column) => column.name === sortField);
  }
  public tableListLoader$: BehaviorSubject<QueryParamsModel> =
    new BehaviorSubject(null);

  /**
   * Load Table List from service through data-source
   */
  initTableListLoader(tableName?: string) {
    this.subscriptions.push(
      this.tableListLoader$
        .pipe(
          switchMap((queryParams) => {
            this.hasResult = null;
            if (!queryParams) return empty();

            const payload = {
              page: queryParams,
              url: this.apiUrl,
              resolveData: this.resolveData,
            };

            this.updateFilteredString(queryParams);

            this.store.dispatch(this.store.showPageLoadingDistpatcher);
            this.tableConfigUrl = payload.url;
            return this.crudTableService
              .findItems(payload.url, payload.page, tableName)
              .pipe(catchError((err) => of(err)));
          })
        )
        .subscribe((response: any) => {
          this.store.dispatch(this.store.hidePageLoadingDistpatcher);
          if (response.error) {
            this.layoutUtilsService.showNotification(
              "error",
              this.translate.instant("COMMON.UNEXPECTED_ERROR")
            );
            return;
          }

          const result: any = response;
          let items = result.data || result || [];
          this.dataSource.paginatorTotalSubject.next(result.total);
          this.dataSource.entitySubject.next(items);
          this.lastVisitedTablePage$.next(result.current_page - 1 || 0);
          this.hasResult = items && items.length > 0;
          // checking for filter status here
          this.getFilterAccordionStatus();
          this.onLoadTableList.emit(this.dataSource);
        })
    );
  }

  // with the new version of the table, this function should be refactored
  // as we don't need to check if local storage values exist or not
  loadTableList(initialCall = false, shouldUpdate = false) {
    //
    let queryParams;
    let savedFilters = this.localStorageService.getItem(this.configName);

    if (initialCall) {
      if (savedFilters) {
        queryParams = savedFilters;
        this.filterBadgeObject = savedFilters?.filterBadge;
        // updating current page select (not a proper solution, should be fixed later)
        this.lastVisitedTablePage$.next(savedFilters.pageNumber);
      } else {
        queryParams = this.getQueryParamsModel();
        this.localStorageService.saveFilters(this.name, queryParams);
        savedFilters = this.localStorageService.getItem(this.configName);
        this.updateFilteredString(queryParams);
      }
    }

    if (shouldUpdate) {
      queryParams = this.getQueryParamsModel();
      this.localStorageService.saveFilters(this.name, queryParams);
      this.tableUserConfigService.updateTableState(queryParams, {
        pageSize: this.pageSize,
        pageSizeOptions: this.pageSizeOptions,
      });
    } else {
      queryParams = savedFilters;
    }
    this.tableListLoader$.next(queryParams);

    // cache pagination values
    // this.setPaginationCache(this.paginator.pageSize);
  }

  getQueryParamsModel() {
    return new QueryParamsModel(
      this.getFilterParams(),
      this.searchConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.isTestdata,
      this.getFilterRangeParams(),
      this.translate.currentLang,
      this.filterBadgeObject
    );
  }

  setPaginationCache(pageSize, pageSizeOptions = [5, 10, 25, 50, 100]) {
    const paginationValues = {
      pageSize:
        pageSize > this.maxPaginationSize ? this.maxPaginationSize : pageSize,
      pageSizeOptions,
    };
    // this.tableUserConfigService.updatePaginationState(paginationValues);
    const crudTablePaginationCacheName = `pagination-${this.name}`;
    localStorage.setItem(
      crudTablePaginationCacheName,
      JSON.stringify(paginationValues)
    );
    return paginationValues;
  }

  getPaginationCache() {
    const crudTablePaginationCacheName = `pagination-${this.name}`;
    const cachePagination = localStorage.getItem(crudTablePaginationCacheName);

    try {
      if (cachePagination != null) {
        let pagination = JSON.parse(cachePagination);
        if (typeof pagination == "object" && !Array.isArray(pagination)) {
          if (pagination.pageSize > this.maxPaginationSize) {
            pagination = { ...pagination, pageSize: this.maxPaginationSize };
            this.setPaginationCache(pagination.pageSize);
          }
          return pagination;
        }
      }
    } catch (e) {}

    // there's an error if we  get this far, so return defaults
    // return this.setPaginationCache(10);
  }

  getSearchableColumnHintText() {
    const columnNames = [];
    this.searchableColumns.forEach((name) => {
      const col = this.columns.find((c) => c.name == name);
      if (col) {
        columnNames.push(this.getConfigTranslate(col.text));
      }
    });
    return "Search " + columnNames.join(",");
  }

  getPageTitle() {
    const title = this.crudUtilsService.parsePlaceholders(this.title, {
      ...this.masterData,
    });
    const ret = this.getConfigTranslate(title);

    return ret;
  }

  switchTheData() {
    this.isTestdata = localStorage.getItem("is_testdata");
    this.loadTableList();
    this.customFilters.forEach((c) => c.initDynamicOptions());
  }

  /**
   * Returns object for filter
   */
  searchConfiguration(): any {
    const search: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    // if (this.filterStatus && this.filterStatus.length > 0) {
    // 	filter.status = +this.filterStatus;
    // }
    //
    // if (this.filterType && this.filterType.length > 0) {
    // 	filter.type = +this.filterType;
    // }

    var pageURL = window.location.href;
    this.relationId = pageURL.substr(pageURL.lastIndexOf("/") + 1);

    if (this.relatedId !== undefined && this.relatedId !== "") {
      this.searchableColumns.forEach((column) => {
        //search[this.relatedId] = this.relationId;
      });
    }

    if (searchText != null && searchText !== "") {
      this.searchableColumns.forEach((column) => {
        search[column] = searchText;
      });
    }

    return search;
  }

  refresh() {
    this.loadTableList();
  }

  /** ACTIONS */
  /**
   * Delete
   *
   * @param item: any
   */
  deleteItem(item: any) {
    this.crudUtilsService.deleteItem(
      this.name,
      this.title,
      item,
      this.apiUrl,
      () => {
        this.store.dispatch(new ItemDeleted({ id: item.id, url: this.apiUrl }));
        this.loadTableList();
      }
    );

    // const _title = `Delete ${this.name}`;
    // const _description = `Are you sure to permanently delete this ${this.name}`;
    // const _waitDesciption = `Deleting ${this.name}...`;
    // const _deleteMessage = `Deleted ${this.name}`;

    // // PGMTODO: refactor later
    // if (this.name == 'general-services-trackday-items' || this.name == 'location-services-trackday-items') {
    //     if (_item.booked_services_count > 0) {
    //         this.dialog.open(InfoDialogComponent, {
    //             data: {
    //                 title: _title,
    //                 message: this.translate.instant('CANNOT_DELETE_BOOKED_SERVICES', {
    //                     booked_services_count: _item.booked_services_count
    //                 }),
    //             },
    //             width: '500px'
    //         });
    //         return;
    //     }
    // }

    // const dialogRef = this.layoutUtilsService.deleteElement(
    //     _title,
    //     _description,
    //     _waitDesciption
    // );
    // dialogRef.afterClosed().subscribe((res) => {
    //     if (!res) {
    //         return;
    //     }

    //     this.store.dispatch(
    //         new ItemDeleted({ id: _item.id, url: this.apiUrl })
    //     );
    //     this.layoutUtilsService.showActionNotification(
    //         _deleteMessage,
    //         MessageType.Delete
    //     );

    //     this.loadTableList();
    // });
  }

  /** ACTIONS */
  /**
   * Delete
   *
   * @param _item: any
   */
  deleteItems() {
    const ids = [];
    this.selection.selected.forEach((element) => {
      ids.push(element.id);
    });
    const _title = `Delete ${this.name}`;
    const _description = `Are you sure to permanently delete this ${this.name}`;
    const _waitDesciption = `Deleting ${this.name}...`;
    const _deleteMessage = `Selected ${this.name} item(s) has been deleted.`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      const urlSegments = this.apiUrl.split("?");
      const apiUrl = urlSegments[0] + "/bulkDelete?" + (urlSegments[1] || "");
      const routeSubscription = this.crudTableService
        .deleteItems(apiUrl, ids)
        .subscribe((res) => {
          this.loadTableList();
          this.layoutUtilsService.showNotification("info", _deleteMessage);
        });
      //this.setPageTitle(this.title);
    });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.entitySubject.value.length;
    return numSelected === numRows;
  }

  /**
   * Toggle all selections
   */
  // masterToggle() {
  //     if (this.selection.selected.length === this.tableList.length) {
  //         this.selection.clear();
  //     } else {
  //         this.tableList.forEach((row) => this.selection.select(row));
  //     }
  // }

  /**
   * Toggle all selections
   */
  masterToggle(event) {
    if (event.checked) {
      this.dataSource.entitySubject.value.forEach((row) =>
        this.selection.select(row)
      );
    } else {
      this.selection.clear();
    }
    this.crefDetectChanges();
    // if (this.selection.hasValue()) {
    //     this.selection.clear();
    //     this.crefDetectChanges();
    // } else {
    // 	console.log('masterToggle', this.dataSource.entitySubject.value);
    // 	this.dataSource.entitySubject.value.forEach(row => this.selection.select(row));
    // 	this.crefDetectChanges();
    // }
  }

  isSelected(row) {
    return this.selection.selected.find((r) => r.id == row.id);
  }

  getColumnCssStyles(column) {
    const styles = {};

    if (column.width) {
      set(styles, "width", column.width);
      set(styles, "min-width", column.width);
    }

    return styles;
  }

  showDetail(detail) {
    const dialogRef = this.dialog.open(PermissionGroupDescriptionComponent, {
      data: detail,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      //this.setPageTitle(this.title);
    });
  }

  showDialog(item) {
    // const _saveMessage = `Copuon items successfully has been saved.`;
    // const _messageType = MessageType.Create;
    // var pageURL = window.location.href;
    // const page = pageURL.substr(pageURL.lastIndexOf("/") + 1);
    // if (page === "create") {
    //     return;
    // }
    // const dialogRef = this.dialog.open(CouponItemsDialogComponent, {
    //     width: "450px",
    //     data: { item: item, name: this.name },
    // });
    // dialogRef.afterClosed().subscribe((res) => {
    //     if (!res) {
    //         return;
    //     }
    //     this.layoutUtilsService.showActionNotification(
    //         _saveMessage,
    //         _messageType,
    //         10000,
    //         true,
    //         true
    //     );
    //     this.loadTableList();
    // });
  }

  // selected: array of selected keys
  showNotes(item: any) {
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: "Notes",
        message: get(item, this.notes.field),
      },
      width: this.notes.width || "500px",
    });
  }

  hasNotes(item: any) {
    return get(item, this.notes.field);
  }

  // selected: array of selected keys
  showActivityLogs(item: any) {
    const apiUrl = ApiConfig.ActivityLogsUrl();
    console.log("showActivityLogs", item);
    this.dialog.open(HtmlContentDialogComponent, {
      data: {
        title: "Activity Logs",
        apiUrl: `${apiUrl}/${item.id}/summary`,
      },
      width: "80%",
    });
  }

  updateDisplayColumns(columns: string[]) {
    this.displayedColumns = this.initDisplayColumns = columns;
    setTimeout(() => {
      if (this.cRef && !(this.cRef as ViewRef).destroyed) {
        this.cRef.detectChanges();
      }
    });
  }

  btnDebug() {
    console.log("this.readonly", this.readonly);
    console.log(
      "btnDebug",
      this.allowDelete,
      this.readonly,
      this.columnNames,
      this.displayedColumns,
      this.getDisplayColumns()
    );
  }

  getDisplayColumns() {
    let columns = this.columnNames || [];
    if (this.columnNames) {
      // here we check the count of processType of the available buttons, if they are 0, then we remove the select column
      const count = this.countNumberOfEligibleButtons();
      if (this.displayedColumns && this.displayedColumns.length > 0) {
        columns = this.columnNames.filter((name) =>
          this.displayedColumns.includes(name)
        );
      }
      if (this.readonly) {
        if (this.alwaysShowCheckboxes) {
          columns = ["select", ...columns];
        }
        if (count === 0) {
          columns = [...columns];
        } else {
          columns = ["select", ...columns];
        }
        if (this.hasReadonlyActions) columns = [...columns, "actions"];
      } else {
        if (count === 0) {
          columns = [...columns, "actions"];
        } else {
          columns = ["select", ...columns, "actions"];
        }
      }
    }
    return new Set([...columns]);
  }

  countNumberOfEligibleButtons() {
    let count = 0;
    if (this.processButtons?.length) {
      for (let index = 0; index < this.processButtons.length; index++) {
        const element = this.processButtons[index];
        if (element.processType === "mass" || element.processType === "both") {
          count++;
        }
      }
    }

    return count;
  }

  // this is the new event for the cb-paginator component
  // this is currently very ugly, but it works, everything is extremely coupled, so
  // instead of removing the paginator, we just hide it and call the changePageSize event when this event is emitted
  // mat table will implement this feature in the future, we can use that instead of this
  // for now this should suffice.
  paginationChange(paginationDetails) {
    this.paginator.pageIndex = paginationDetails.pageIndex;
    this.paginator.pageSize = this.pageSize = paginationDetails.pageSize;
    this.paginator.page.next(paginationDetails);
    this.setPaginationCache(paginationDetails.pageSize);
  }

  export() {
    const { pageNumber, pageSize } =
      this.tableUserConfigService.getCurrentPaginationStatus();
    const currentTotalRecords = this.dataSource.paginatorTotalSubject.value;
    const currentPageSize =
      pageSize > currentTotalRecords ? currentTotalRecords : pageSize;

    let savedFilters = this.localStorageService.getItem(this.configName);
    this.dialog
      .open(ExportColumnConfigComponent, {
        data: {
          columns: this.tableUserConfigService.getTableColumns(),
          tableName: this.name,
          currentTableQueryParams: savedFilters ?? this.getQueryParamsModel(),
          tableConfigUrl: this.tableConfigUrl,
          currentPage: pageNumber,
          currentPageSize: currentPageSize,
          currentTotalRecords: currentTotalRecords,
        },
        panelClass: ["new-email-dialog", "fullscreen-modal"],
        disableClose: false,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        console.log(res);
      });
  }

  getFormattedValue(item, columnInfo) {
    let formatData = columnInfo.format_data;
    if (formatData)
      return this.crudUtilsService.parsePlaceholders(formatData, item);
    else return get(item, columnInfo.name);
  }

  getDescription(text) {
    return text.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "");
  }

  checkValue(value) {
    return value.indexOf(".") !== -1;
  }

  parseLinkCell(item, column) {
    const name = column.name;
    const link = column?.link;
    if (!link) {
      return null;
    }

    const linkType = link.type || "internal";
    const url = this.crudUtilsService.parsePlaceholders(link.url, item);
    const text = this.crudUtilsService.parsePlaceholders(link.text, item);
    const tooltip = this.crudUtilsService.parsePlaceholders(link.tooltip, item);
    return {
      type: linkType,
      url,
      text,
      tooltip,
    };
  }
  onTableCellClickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onTableCellClickHandler(item, column) {
    if (column.callback) {
      if (column.confirm) {
        const confirmSettings = column.confirm;
        let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
          data: {
            title: this.getConfigTranslate(confirmSettings?.title),
            message: this.getConfigTranslate(confirmSettings?.message),
            btnYesLabel: this.getConfigTranslate(confirmSettings.ok_label),
            btnNoLabel: this.getConfigTranslate(confirmSettings.cancel_label),
          },
          width: "500px",
        });
        dialogRef.afterClosed().subscribe((dialogRes) => {
          if (dialogRes) {
            this.callApiForCellClickHandler(column.callback, item);
          }
        });
      } else {
        this.callApiForCellClickHandler(column.callback, item);
      }
    }
  }

  private callApiForCellClickHandler(callbackSettings, item) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    let currentFilters = this.httpUtilService.convertQueryParams(
      this.getQueryParamsModel()
    );
    let params = currentFilters;
    if (currentFilters === null) {
      params = new HttpParams();
    }
    const idField = callbackSettings?.id_field || "id";
    params = params.append("selected", [get(item, idField)].join(","));
    params = params.append("is_testdata", localStorage.getItem("is_testdata"));

    let callbackUrl =
      environment_api.api_url +
      this.crudUtilsService.parsePlaceholders(callbackSettings.url, {
        lang: this.translate.currentLang,
      });

    this.http
      .request(callbackSettings.method, callbackUrl, { params: params })
      .subscribe((response: any) => {
        if (response.refresh_table) {
          this.loadTableList();
        }
      });
  }

  getData(item, column) {
    const name = column.name;
    const value = get(item, name);
    if (column.type === "checkbox") {
      return !!value;
    }
   // if (column.type === "decimal") {
   //   if (!this.shouldTableDisplayZeroValue) {
   //     // this is a super weird code, this should not be here,
   //     // but it is, so we need to check if the value is 0 or 0.00 or "0" or "0.00" and return an empty string
   //     // we need to fix the server to either return the int value (as currently it mostly and randomly returns string '0')
   //     // or, format the value in the server (simply return empty string if the value is 0)
   //     if (Number(value) === 0) {
   //       return null;
   //     }
   //   }
   // }

    if (column.html) {
      let htmlValue = column.html === true ? value : column.html;
      let formattedValue = this.crudUtilsService.parsePlaceholders(
        htmlValue,
        item
      );

      if (column.translate) {
        return KTUtil.replaceInnerHtmlOfString(
          formattedValue,
          this.translate.instant(KTUtil.getHtmlInnerText(formattedValue))
        );
      } else {
        return formattedValue;
      }
    } else {
      let formattedValue = this.crudUtilsService.parsePlaceholders(value, item);
      //   if (column.type === "boolean") {
      //     this.translate.instant(formattedValue?.toString());
      //   }
      //   return formattedValue;
      return column.translate
        ? this.translate.instant(formattedValue?.toString())
        : formattedValue;
    }
  }

  getPrefix(item, prefix) {
    if (has(item, prefix)) return get(item, prefix);
    else return prefix;
  }

  getSuffix(item, suffix) {
    if (has(item, suffix)) return get(item, suffix);
    else return suffix;
  }

  isDeletable(item) {
    if ("deletable" in item) {
      return item.deletable === 1;
    } else {
      return true;
    }
  }

  /** Alert Close event */
  onAlertClose($event) {
    this.showInfoText = false;
  }

  canEdit(item: any) {
    return (
      this.allowEdit &&
      (!this.formConfig ||
        this.formConfig.can_edit == null ||
        this.formConfig.can_edit === true ||
        this.crudUtilsService.parsePlaceholders(this.formConfig.can_edit, item))
    );
  }

  canDelete(item: any) {
    return (
      this.allowDelete &&
      !item.is_system &&
      (!this.formConfig ||
        this.formConfig.can_delete == null ||
        this.formConfig.can_delete === true ||
        this.crudUtilsService.parsePlaceholders(
          this.formConfig.can_delete,
          item
        ))
    );
  }

  canAdd() {
    if (this.allowAdd) {
      return (
        !this.formConfig ||
        this.formConfig.can_add == null ||
        this.crudUtilsService.parsePlaceholders(
          this.formConfig.can_add,
          this.masterData
        )
      );
    }

    return false;
  }

  updateItem(item) {
    // link issue: https://circuit-booking.atlassian.net/browse/SRS-512
    // TODO: make this confiugration

    // if (this.name == "participants-trackday-items") {
    //   const data: any = {
    //     id: item.id,
    //     name: this.name,
    //   };
    //   localStorage.setItem("data", JSON.stringify(data));
    //   this.router.navigateByUrl(
    //     "/trackdays-management/bookings/edit/" + item.id
    //   );
    //   return;
    // }

    if (this.isSubForm) {
      this.onEdit.emit(item);
      return;
    }

    if (this.formConfig) {
      if (this.formConfig.modal) {
        const dialogRef = this.dialog.open(CrudFormDialogComponent, {
          data: {
            id: item.id,
            name: this.name,
            title: this.title,
            config: this.formConfig.config,
            apiUrl: this.apiUrl,
            //listUrl: this.formConfig.listUrl,
            initialData: this.formConfig.initial_data,
          },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (!res) {
            return;
          }
          this.loadTableList();
        });
        //this.setPageTitle(this.title);
        return;
      }
    }

    if (
      this.name === "service" &&
      this.router.url !== "/service-management/additional-services"
    ) {
      const dialogRef = this.dialog.open(ServiceFormComponent, {
        data: { id: item.id, name: this.name },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (!res) {
          return;
        }
        //this.setPageTitle(this.title);
        this.loadTableList();
      });
    } else if (
      this.name === "coupons" &&
      this.router.url !== "/admin-management/coupons"
    ) {
      // const dialogRef = this.dialog.open(
      //     CouponsTrackdayItemsFormComponent,
      //     {
      //         data: { id: item.coupon_id, name: this.name },
      //     }
      // );
      // dialogRef.afterClosed().subscribe((res) => {
      //     if (!res) {
      //         return;
      //     }
      //     this.loadTableList();
      // });
    } else if (this.name === "coupon-items") {
      // //const pageURL = window.location.href;
      // //const couponId = pageURL.substr(pageURL.lastIndexOf("/") + 1);
      // const dialogRef = this.dialog.open(CouponItemsFormComponent, {
      //     data: {
      //         id: item.id,
      //         apiUrl: ApiConfig.CouponItemsURL(),
      //         name: this.name,
      //         coupon_id: item.coupon_id,
      //     },
      // });
      // dialogRef.afterClosed().subscribe((res) => {
      //     if (!res) {
      //         return;
      //     }
      //     this.loadTableList();
      // });
      // } else if (this.name === "preset-cancellation-prices") {
      //   const dialogRef = this.dialog.open(
      //     PresetCancellationPricesFormComponent,
      //     {
      //       data: { id: item.id, name: this.name },
      //     }
      //   );
      //   dialogRef.afterClosed().subscribe((res) => {
      //     if (!res) {
      //       return;
      //     }
      //     //this.setPageTitle(this.title);
      //     this.loadTableList();
      //   });
    } else {
      const data: any = {
        id: item.id,
        name: this.name,
      };
      localStorage.setItem("data", JSON.stringify(data));
      this.router.navigateByUrl(this.baseUrl + "/edit/" + item.id);
    }
  }

  openLink(item, action_link) {
    const url = action_link.url + "/" + item[action_link.link_id];
    console.log(url);
    this.router.navigateByUrl(url);
  }

  createItem(item?: any) {
    if (this.onCreate) this.onCreate.emit(item);

    if (this.isSubForm) return;

    if (this.formConfig) {
      if (this.formConfig.modal) {
        const dialogRef = this.dialog.open(CrudFormDialogComponent, {
          data: {
            title: this.title,
            config: this.formConfig.config,
            apiUrl: this.apiUrl,
            //listUrl: this.formConfig.listUrl,
            initialData: this.formConfig.parsedInitialData,
          },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (!res) {
            return;
          }
          //this.setPageTitle(this.title);
          this.loadTableList();
        });
        return;
      }
    }

    if (
      this.name === "service" &&
      this.router.url !== "/service-management/additional-services"
    ) {
      const dialogRef = this.dialog.open(ServiceFormComponent, {
        data: { name: this.name },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (!res) {
          return;
        }
        //this.setPageTitle(this.title);
        this.loadTableList();
      });
    } else if (
      this.name === "coupons" &&
      this.router.url !== "/admin-management/coupons"
    ) {
      // const dialogRef = this.dialog.open(
      //     CouponsTrackdayItemsFormComponent,
      //     {
      //         data: { name: this.name },
      //     }
      // );
      // dialogRef.afterClosed().subscribe((res) => {
      //     if (!res) {
      //         return;
      //     }
      //     this.loadTableList();
      // });
    } else if (
      (this.name === "coupon-assigned-items" &&
        this.router.url !== "/admin-management/assigned-coupons") ||
      (this.name === "coupon-unassigned-items" &&
        this.router.url !== "/admin-management/unassigned-coupons")
    ) {
      var pageURL = window.location.href;
      const itemsId = pageURL.substr(pageURL.lastIndexOf("/") + 1);
      const items = {
        id: itemsId,
        is_testdata: this.isTestdata,
      };
      this.showDialog(items);
    } else if (this.name === "coupon-items") {
      // const pageURL = window.location.href;
      // const couponId = pageURL.substr(pageURL.lastIndexOf("/") + 1);
      // const dialogRef = this.dialog.open(CouponItemsFormComponent, {
      //     data: {
      //         apiUrl:
      //             ApiConfig.CouponItemsURL() + `?&coupon_id=${couponId}`,
      //         name: this.name,
      //         coupon_id: couponId,
      //     },
      // });
      // dialogRef.afterClosed().subscribe((res) => {
      //     if (!res) {
      //         return;
      //     }
      //     this.loadTableList();
      //});
      // } else if (this.name === "preset-cancellation-prices") {
      //   const dialogRef = this.dialog.open(
      //     PresetCancellationPricesFormComponent,
      //     {
      //       data: { name: this.name },
      //     }
      //   );
      //   dialogRef.afterClosed().subscribe((res) => {
      //     if (!res) {
      //       return;
      //     }
      //     //this.setPageTitle(this.title);
      //     this.loadTableList();
      //   });
    } else {
      let apiUrl = this.baseUrl + "/create";
      if (item && item.id) {
        if (apiUrl.indexOf("?") >= 0) apiUrl += "&item_id=" + item.id;
        else apiUrl += "?item_id=" + item.id;
      }

      this.router.navigateByUrl(apiUrl);
    }
  }

  newItem(item?: any) {
    //console.log('newItem', this.eventEmitterService.invokeCrudTableCreate.observers.length);
    console.log("invokeCrudTableCreate", this.name);
    const event = this.eventEmitterService.invokeCrudTableCreate[this.name];
    if (event && event.before && event.before.observers) {
      const pauseAction = event.before.observers.length > 0;
      if (pauseAction) {
        event.before.emit({
          name: this.name,
          masterData: this.masterData,
        });
        event.after.subscribe((result) => {
          if (result) {
            this.createItem(item);
          }
        });
        return;
      }
    }
    this.createItem(item);
  }

  dateRangeBadgeFormatter(value) {
    let toReturn = "";
    if (value) {
      if (value?.from) {
        toReturn = formatDate(
          value.from,
          "yyyy-MM-dd",
          this.translate.currentLang || "en"
        );
      }

      if (value?.to) {
        toReturn +=
          " - " +
          formatDate(
            value.to,
            "yyyy-MM-dd",
            this.translate.currentLang || "en"
          );
      }
    }
    return toReturn;
  }

  onFinishedLoading() {
    this.crefDetectChanges();
  }

  onFilterChange(filter, data?: any) {
    if (filter === "search") {
      const searchTerm = this.searchInput.nativeElement.value;
      if (searchTerm) {
        this.updateFilterBadges("Search", {
          key: "search",
          text: searchTerm,
        });
      }
      if (!!data) this.paginator.pageIndex = 0;
      this.loadTableList(false, true);
      return;
    }
    this.customFilters = this.customFilters.map((c) => {
      if (c.field === filter.field) {
        if (filter.type === "daterange") {
          if (filter.value?.from || filter.value?.to) {
            const text = this.dateRangeBadgeFormatter(filter?.value);
            data = {
              key: filter.title,
              text,
            };
          } else {
            data = null;
          }

          return c;
        } else if (filter.type === "date") {
          if (filter.value) {
            const text = formatDate(
              filter.value,
              "yyyy-MM-dd",
              this.translate.currentLang || "en"
            );
            data = {
              key: filter.title,
              text,
            };
          }
        } else {
          c.value = data?.value;
        }
      }
      return c;
    });

    this.updateFilterBadges(filter.title, data);
    if (!!data) this.paginator.pageIndex = 0;
    this.loadTableList(false, true);
  }
  updateFilterBadges(filter: string, value: any, type = "select") {
    const newArray = this.filterBadgeObject.filter((b) => b.key !== filter);
    if (value) {
      const toPush = { key: filter, value: value.text };
      this.filterBadgeObject = [...newArray, toPush];
      return;
    }

    this.filterBadgeObject = [...newArray];
  }

  prepareDefaultFiltersForFilterBadge(r) {
    try {
      if (r.type === "single_select") {
        if (r.dynamic_options && r.dynamic_options.data) {
          const data = r.dynamic_options.data;
          // has to be a '==' instead of '===', server sometimes returns string and sometimes number
          const selected = data.find((d) => d.value == r.value);
          if (selected) {
            this.updateFilterBadges(r.title, {
              key: r.title,
              text: selected.label,
            });
          }
        }
      }
      //   this.updateFilterBadges(r.title, r.value);
    } catch (ex) {}
  }

  getFilterParams() {
    let params = {};
    if (this.customFilters && this.customFilters.length > 0) {
      this.customFilters
        .filter((r) => r.type != "daterange")
        .forEach((r) => {
          if (r.value == null) return;
          if (r.value === "") return;
          if (r.type === "date") {
            params[r.field] = formatDate(
              r.value,
              "yyyy-MM-dd",
              this.translate.currentLang || "en"
            );
          } else {
            params[r.field] = r.value;
            this.prepareDefaultFiltersForFilterBadge(r);
          }
        });
    }
    return params;
  }

  getFilterRangeParams() {
    let params = {};
    if (this.customFilters && this.customFilters.length > 0) {
      this.customFilters.forEach((r) => {
        if (r.type == "daterange") {
          if (!r.value.from && !r.value.to) return;

          params[r.field] = "";
          if (r.value.from)
            params[r.field] += formatDate(
              r.value.from,
              "yyyy-MM-dd",
              this.translate.currentLang || "en"
            );
          params[r.field] += ",";
          if (r.value.to)
            params[r.field] += formatDate(
              r.value.to,
              "yyyy-MM-dd",
              this.translate.currentLang || "en"
            );
        }
      });
    }

    return params;
  }

  private _onFilterInputChangeTimeout: NodeJS.Timeout = null;
  onFilterInputChange() {
    if (this._onFilterInputChangeTimeout != null)
      clearTimeout(this._onFilterInputChangeTimeout);
    this._onFilterInputChangeTimeout = setTimeout(() => {
      this.loadTableList();
    }, 1500);
  }

  crefDetectChanges() {
    if (this.cRef && !(this.cRef as ViewRef).destroyed) {
      this.cRef.detectChanges();
    }
  }

  getConfigTranslate(key) {
    if (!key) return key;
    const path = this.translate.currentLang + "." + key;
    //if (key == 'TOOLTIP_PRICE_GROSS') console.log('getConfigTranslate', path, this.translations);
    let text = get(this.translations, path);
    if (text == null) text = this.translate.instant(key);
    return text || key;
  }

  // getConfigTranslate(key) {
  //     const path = this.translate.currentLang + '.' + key;
  //     return get(this.translations, path) || key;
  // }

  //
  // Custom actions toggler
  //

  toggledItems = {
    datetime_active_until: {
      subject: new Subject(),
      items: [],
    },
    is_usereditable: {
      //subject: new Subject(),
      items: [],
    },
    is_highlightevent: {
      //subject: new Subject(),
      items: [],
    },
  };

  toggleItem(action, id, status?: boolean) {
    const itemIndex = this.toggledItems[action].items.findIndex(
      (r) => r.id == id
    );

    if (itemIndex >= 0) {
      this.toggledItems[action].items[itemIndex].toggle(status);
    } else {
      // TODO: refactor
      let toggleUrl = this.apiUrl + "/" + id;

      if (action == "is_usereditable") toggleUrl += "/toggle-is-usereditable";
      else if (action == "datetime_active_until")
        toggleUrl += "/toggle-datetime-active-until";
      else if (action == "is_highlightevent")
        toggleUrl += "/toggle-is-highlightevent";

      const toggleItem = new CrudTableCustomActionToggleItem(
        id,
        status,
        toggleUrl,
        this.http,
        this.layoutUtilsService,
        this.cRef
      );
      this.toggledItems[action].items.push(toggleItem);
      toggleItem.toggle(status);
    }

    //if (this.cRef) this.cRef.detectChanges();
  }

  getToggleItemStatus(action, id, defaultStatus) {
    const item = this.toggledItems[action].items.find((r) => r.id == id);
    if (item) return item.status;
    else return defaultStatus;
  }

  //   toggleIsUserEditable(_item: any) {
  //     console.log(_item);
  //     const newStatus = !_item.is_usereditable;
  //     this.toggleItem("is_usereditable", _item.id, newStatus);

  //     this.toggledItems["is_usereditable"].subject;
  //   }

  unlockDatetimeActiveUntil(_item: any) {
    this.toggleItem("datetime_active_until", _item.id, true);

    // const url = this.apiUrl + "/" + _item.id + "/toggle-datetime-active-until";
    // this.http.get(url).subscribe(
    //   (res) => {},
    //   (err) => {
    //     this.toggleItem("datetime_active_until", _item.id, false);
    //     this.layoutUtilsService.showActionNotification(
    //       "The server was unable to process the request. Please retry the operation.",
    //       MessageType.Update
    //     );
    //   }
    // );
  }

  // Custom actions
  getDatetimeActiveUntilStatus(_item: any) {
    if (_item.datetime_active_until == null) return true;

    const status = this.getToggleItemStatus(
      "datetime_active_until",
      _item.id,
      null
    );
    if (status !== null) return status;

    let dateActive = new Date(_item.datetime_active_until);
    let dateNow = new Date();
    return dateActive.getTime() >= dateNow.getTime();
  }

  changeUserInfo(item, value, key) {
    const apiUrl = ApiConfig.UsersInfoURL();
    const _createMessage = "User info status changed successfully!";
    const items = {};
    if (item.user_info !== null) {
      items["id"] = item.user_id;
      items[key] = value;
      this.crudTableService.updateItem(apiUrl, items).subscribe((res) => {
        this.loadTableList();
      });
    } else {
      items["user_id"] = item.user_id;
      items[key] = value;
      items["is_testdata"] = this.isTestdata;
      this.crudTableService.createItem(apiUrl, items).subscribe((res) => {
        this.loadTableList();
      });
    }
    this.layoutUtilsService.showNotification("success", _createMessage);
  }

  items: any = [];

  assignedStartnumber(event, item) {
    const trackdayItemId = this.getRelationId();
    const items = {
      start_number: item.number,
      trackday_items_id: trackdayItemId,
      user_id: item.user_id,
      is_testdata: this.isTestdata,
    };
    //console.log('assignedStartnumber', event, item, items);
    this.items.push(items);
  }

  onSubmit() {
    const apiUrl = ApiConfig.StartNumberTrackdayItemsUpdateURL();
    const _createMessage = "Startnumber assigned successfully!";
    this.crudTableService.createItem(apiUrl, this.items).subscribe(
      (res) => {
        this.loadTableList();
        this.layoutUtilsService.showNotification("success", _createMessage);
      },
      (error) => {
        const _createMessage = "Number already exists!";
        this.loadTableList();
        this.layoutUtilsService.showNotification("info", _createMessage);
      }
    );
  }

  showBookingInfo(item) {
    this.dialog.open(TrackdayBookingsInfoDialogComponent, {
      data: item.booking,
      width: "500px",
    });
  }
  getRelationId() {
    if (this.activatedRoute.snapshot.params.id) {
      this.relationId = this.activatedRoute.snapshot.params.id;
    } else {
      window.location.href
        .split("/")
        .reverse()
        .filter(Boolean)
        .slice(0, 3) // check only last 3 segments
        .forEach((n) => {
          if (!Number.isNaN(Number(n))) {
            this.relationId = n;
          }
        });
    }
    return this.relationId;
  }

  usePresetCancellationPrices() {
    const trackdayItemId = this.getRelationId();
    this.commonService
      .addReplaceDialog({
        data: {
          component: PresetCancellationPricesTableComponent,
          title: "Preset Cancellation Prices",
          showSecondaryButton: true,
        },
        width: "100%",
      })
      .subscribe((dialogResult: AddReplaceDialogResultType) => {
        if (dialogResult) {
          if (dialogResult.action === "CANCEL") return;
          if (dialogResult.action === "SECONDARY") {
            this.loadPresetCancellationPrices(
              trackdayItemId,
              true,
              dialogResult.selection
            );
            return;
          }
          if (dialogResult.action === "PRIMARY") {
            this.loadPresetCancellationPrices(
              trackdayItemId,
              false,
              dialogResult.selection
            );
            return;
          }
        }
      });
  }

  loadPresetCancellationPrices(trackdayItemId, replace, selected) {
    this.isLoadingResults = true;

    const selectedIds = selected.map((r) => r.id);

    this.http
      .post<any>(
        ApiConfig.LoadPresetCancellationPricesDataURL(trackdayItemId, replace),
        {
          selected: selectedIds,
        }
      )
      .subscribe((response) => {
        this.isLoadingResults = false;
        this.loadTableList();
      });
  }

  usePresetDynamicPrices() {
    const trackdayItemId = this.getRelationId();

    this.commonService
      .addReplaceDialog({
        data: {
          component: PresetDynamicPricesTableComponent,
          title: "Preset Cancellation Prices",
          showSecondaryButton: true,
        },
        width: "100%",
      })
      .subscribe((dialogResult: AddReplaceDialogResultType) => {
        if (dialogResult) {
          if (dialogResult.action === "CANCEL") return;
          if (dialogResult.action === "SECONDARY") {
            this.loadPresetDynamicPrices(
              trackdayItemId,
              true,
              dialogResult.selection
            );
          } else if (dialogResult.action === "PRIMARY") {
            this.loadPresetDynamicPrices(
              trackdayItemId,
              false,
              dialogResult.selection
            );
          }
        }
      });

    // let dialogRef = this.dialog.open(PresetDynamicPricesTableComponent, {
    //   data: {},
    // });

    // dialogRef.afterClosed().subscribe((dialogResult) => {
    //   if (dialogResult.action === "REPLACE") {
    //     this.loadPresetDynamicPrices(
    //       trackdayItemId,
    //       true,
    //       dialogResult.selected
    //     );
    //   } else if (dialogResult.action === "ADD") {
    //     this.loadPresetDynamicPrices(
    //       trackdayItemId,
    //       false,
    //       dialogResult.selected
    //     );
    //   } else {
    //     // cancel
    //   }
    //   //this.setPageTitle(this.title);
    // });
  }

  loadPresetDynamicPrices(trackdayItemId, replace, selected) {
    this.isLoadingResults = true;

    const selectedIds = selected.map((r) => r.id);

    this.http
      .post<any>(
        ApiConfig.LoadPresetDynamicPricesDataURL(trackdayItemId, replace),
        {
          selected: selectedIds,
        }
      )
      .subscribe((response) => {
        this.isLoadingResults = false;
        this.loadTableList();
      });
  }

  /**
   * Action Load Btn Callback
   *
   * @remarks
   * TODO: moved to seperate utitily class
   *
   * @param config - The configuration of action load btn
   *
   * @beta
   */
  @Input() configActionLoadBtns: any[] = [];
  actionLoadBtnCallback(config: any) {
    const apiUrl =
      environment_api.api_url +
      this.crudUtilsService.parsePlaceholders(
        config.table.api_url,
        this.masterData
      );

    this.commonService
      .addReplaceDialog({
        data: {
          component: VirtualGarageTableComponent,
          title: config.table.title,
          crudTable: {
            name: config.table.name,
            title: config.table.title,
            configName: config.table.table_config,
            apiUrl: apiUrl,
            readonly: true,
            selection: true,
            alwaysShowCheckboxes: true,
          },
          primaryTitle: "Select",
        },
        width: "100%",
      })
      .subscribe((dialogResult: AddReplaceDialogResultType) => {
        if (dialogResult) {
          if (dialogResult.action === "CANCEL") return;

          if (dialogResult.action === "PRIMARY") {
            const postUrl =
              environment_api.api_url +
              this.crudUtilsService.parsePlaceholders(
                config.post_url,
                this.masterData
              );
            this.apiService
              .post(postUrl, {
                selected: dialogResult.selection.map((r) => r.id),
              })
              .subscribe(() => this.loadTableList());
          }
        }
      });

    // let dialogRef = this.dialog.open(CrudTableComponent, {
    //   data: {
    //     crudTable: {
    //       name: config.table.name,
    //       title: config.table.title,
    //       configName: config.table.table_config,
    //       apiUrl: apiUrl,
    //       readonly: true,
    //       selection: true,
    //       alwaysShowCheckboxes: true,
    //     },
    //   },
    // });

    // dialogRef.afterClosed().subscribe((dialogResult) => {
    //   if (dialogResult) {
    //     const postUrl =
    //       environment_api.api_url +
    //       this.crudUtilsService.parsePlaceholders(
    //         config.post_url,
    //         this.masterData
    //       );
    //     this.apiService
    //       .post(postUrl, {
    //         selected: dialogResult.selected.map((r) => r.id),
    //       })
    //       .subscribe(() => this.loadTableList());
    //   }
    // });
  }

  actionLoadVirtualGarage() {
    const participantTrackdayItemId = this.masterData.id;
    const userId = this.masterData.user_id;
    const apiUrl = ApiConfig.VirtualGarageURL() + `?filter[user_id]=${userId}`;

    this.commonService
      .addReplaceDialog({
        data: {
          component: VirtualGarageTableComponent,
          title: "Virtual Garage",
          crudTable: {
            apiUrl: apiUrl,
            readonly: true,
            selection: true,
            alwaysShowCheckboxes: true,
          },
          primaryTitle: "Select",
        },
        width: "100%",
      })
      .subscribe((dialogResult: AddReplaceDialogResultType) => {
        if (dialogResult) {
          if (dialogResult.action === "CANCEL") return;

          if (dialogResult.action === "PRIMARY") {
            this.apiService
              .post(
                ApiConfig.TrackdayBookingVehiclesUrl() +
                  `/${participantTrackdayItemId}/load-virtual-garage`,
                {
                  selected: dialogResult.selection.map((r) => r.id),
                }
              )
              .subscribe(() => this.loadTableList());
          }
        }
      });
  }

  actionAddPrivateServices() {
    const trackdayItemId = this.getRelationId();
    const apiUrl =
      ApiConfig.ServiceTypesURL() +
      `?unassigned=true&trackday_item_id=${trackdayItemId}&filter[can_private]=1`;

    this.commonService
      .addReplaceDialog({
        data: {
          component: CrudTableComponent,
          title: "Unassigned Service Types",
          crudTable: {
            baseUrl: "/service-management/additional-service-types",
            apiUrl: apiUrl,
            readonly: true,
            alwaysShowCheckboxes: true,
            configName: "service-type",
            selection: true,
          },
          primaryTitle: "Select",
        },
        width: "100%",
      })
      .subscribe((dialogResult: AddReplaceDialogResultType) => {
        if (dialogResult) {
          const apiUrl =
            ApiConfig.TrackdayItemServiceTypesURL() +
            "/assign-services?trackday_item_id=" +
            trackdayItemId;
          this.apiService
            .post(apiUrl, {
              selected: dialogResult.selection.map((r) => r.id),
            })
            .subscribe(() => this.loadTableList());
        }
      });
  }

  actionAddGeneralServices() {
    const trackdayItemId = this.getRelationId();
    const apiUrl =
      ApiConfig.ServicesURL() +
      `?unassigned=true&tab=general&trackday_item_id=${trackdayItemId}`;

    this.commonService
      .addReplaceDialog({
        data: {
          component: CrudTableComponent,
          title: "Unassigned Services",
          crudTable: {
            baseUrl: "/service-management/additional-services",
            apiUrl: apiUrl,
            configName: "service",
            readonly: true,
            alwaysShowCheckboxes: true,
            selection: true,
          },
          primaryTitle: "Select",
        },
        width: "100%",
      })
      .subscribe((dialogResult: AddReplaceDialogResultType) => {
        if (dialogResult) {
          if (dialogResult.action === "CANCEL") return;

          if (dialogResult.action === "PRIMARY") {
            const apiUrl =
              ApiConfig.AssignedServicesURL() +
              "?trackday_item_id=" +
              trackdayItemId +
              "&tab=general";
            this.apiService
              .post(apiUrl, {
                selected: dialogResult.selection.map((r) => r.id),
              })
              .subscribe(() => this.loadTableList());
          }
        }
      });
  }

  actionAddLocationServices() {
    const trackdayItemId = this.getRelationId();
    const apiUrl =
      ApiConfig.ServicesURL() +
      `?unassigned=true&tab=location&trackday_item_id=${trackdayItemId}`;
    let dialogRef = this.dialog.open(CrudTableComponent, {
      data: {
        crudTable: {
          title: "Unassigned Services",
          name: "unassigned-services",
          configName: "service",
          apiUrl: apiUrl,
          baseUrl: "/service-management/additional-services",
          readonly: true,
          alwaysShowCheckboxes: true,
        },
        selection: true,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const apiUrl =
          ApiConfig.AssignedServicesURL() +
          "?trackday_item_id=" +
          trackdayItemId +
          "&tab=location";
        this.apiService
          .post(apiUrl, {
            selected: dialogResult.selected.map((r) => r.id),
          })
          .subscribe(() => this.loadTableList());
      }
      //this.setPageTitle(this.title);
    });
  }

  showBtnFetchItems() {
    return false;
    // return this.dialogRef;
  }

  fetchItems() {
    // TODO: will make this generic
    if (this.dialogRef) {
      //if (this.dialogData.selection) {
      this.dialogRef.close({
        selected: this.selection.selected,
      });
      //}
    }
  }

  showDeleteButton(item: any) {
    return (
      this.baseUrl != "/circuit-booking/locations" &&
      this.canDelete(item) &&
      !item.is_system &&
      this.name !== "coupon-items"
    );
  }

  processButtonCallback(this) {
    this.selection.clear();
    this.crefDetectChanges();
  }

  processButtonClickEventHandler(event: any, items) {
    event.process(
      items,
      this.processButtonCallback.bind(this),
      this.httpUtilService.convertQueryParams(this.getQueryParamsModel())
    );
  }

  tableMenuButtonClickEventHandler(event: CrudTableProcessButton, items) {
    event.process(
      items,
      this.processButtonCallback.bind(this),
      this.httpUtilService.convertQueryParams(this.getQueryParamsModel())
    );
  }

  resetLocallySavedFilters(e) {
    e.preventDefault();
    e.stopPropagation();
    this.filteredString = "";
    this.localStorageService.clear(this.name);
    this.searchInput.nativeElement.value = "";
    this.filterBadgeObject = [];
    this.customFilters.map((filter) => {
      if (filter.type === "daterange") {
        filter.value.from = null;
        filter.value.to = null;
      } else {
        filter.value = null;
      }
      return filter;
    });

    this.loadTableList(false, true);
  }

  updateFilteredString(queryParams: QueryParamsModel) {
    if (!this.customFilters || this.customFilters?.length === 0) return;
    this.filteredString = ``;
    let selectedFiltersKey = Object.entries(queryParams.filter)
      .map(([key, value]) => {
        return { key, value };
      })
      .filter((key) => Boolean(key));

    let selectedSearch = Object.entries(queryParams.search);

    let selectedDateFiltersKeys = Object.entries(queryParams.filterRange)
      .map(([key, value]) => {
        return { key, value };
      })
      .filter((key) => Boolean(key));

    let allSelectedFilterKeys = [
      ...selectedFiltersKey,
      ...selectedDateFiltersKeys,
    ];

    if (!allSelectedFilterKeys) {
      return 0;
    }

    allSelectedFilterKeys = allSelectedFilterKeys.filter((s) =>
      this.customFilters?.map((e) => e.field).includes(s.key)
    );

    let filteredArray = [];
    // this.customFilters[0].value = allSelectedFilterKeys[0].value;

    if (selectedSearch.length) {
      this.searchInput.nativeElement.value = selectedSearch[0][1];
      filteredArray.push(this.translate.instant("Search"));
    }

    if (this.customFilters) {
      allSelectedFilterKeys.forEach((filter) => {
        let target = this.customFilters.find((f) => f.field === filter.key);
        if (target) {
          if (target.type === "daterange") {
            if (typeof filter.value === "string") {
              const [startDate, endDate] = filter.value.split(",");
              let payload = { from: null, to: null };
              if (startDate) {
                payload = { ...payload, from: new Date(startDate) };
              }
              if (endDate) {
                payload = { ...payload, to: new Date(endDate) };
              }
              this.customFilters.find((f) => f.field === filter.key).value =
                payload;
            }
          } else {
            this.customFilters.find((f) => f.field === filter.key).value =
              filter.value;
          }

          filteredArray.push(target.title);
        }
      });
    }

    if (filteredArray.length > 0) {
      let prepend = "<span class='filtered-indicator-text'> </span>";
      let append = "<span class='filtered-indicator-text'></span>";
      let filteredItems = queryParams.filterBadge
        .filter((v) => filteredArray.includes(v.key))
        .map((val) => {
          return `<span class="badge badge-info ml-2">${val.key} : ${val.value}</span>`;
        })
        .join("");
      this.filteredString = `${prepend}${filteredItems}`;
    }

    // this.crefDetectChanges();
  }

  toggleFilterAccordionStatus(state: boolean) {
    this.tableUserConfigService.updateFilterAccordionState(state);
  }

  getFilterAccordionStatus() {
    this.filterStatus.next(
      !!this.tableUserConfigService.getFilterAccordionState()
    );
  }

  columnDrop(event: CdkDragDrop<any>) {
    // console.log(event);
    if (event) {
      let { previousIndex, currentIndex } = event;
      if (previousIndex === currentIndex) return;

      // we check if our columns start with 'select' and if so, we need to skip it
      if (this.columnNames[0].startsWith("select")) {
        previousIndex = previousIndex + 1;
        currentIndex = currentIndex + 1;
      }
      //   console.log(this.getDisplayColumns())
      //   console.log(event.item.data["order"], currentIndex);
      const order = this.columnNames.indexOf(event.item.data.name) || 0;
      moveItemInArray(this.columnNames, order, currentIndex);
      this.cRef.detectChanges();
      this.tableUserConfigService.updateColumnsOrder(this.columnNames);
    }
  }

  openColumnEditor() {
    this.dialog
      .open(TableColumnConfigComponent, {
        data: {
          tableName: this.name,
          columns: this.tableUserConfigService.getTableColumns(),
        },
        // panelClass: ["fullscreen", "fullscreen-modal"],
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) return;

        this.columnNames = res.columnOrder;
        this.updateDisplayColumns(res.columns);
        this.tableUserConfigService.updateColumns({
          columns: res.columns,
          columnOrder: res.columnOrder,
          allColumns: res.allColumns,
        });
      });
  }

  test(event) {
    console.log(event);
  }
}

class CrudTableCustomActionToggleItem {
  subject = new Subject<void>();

  constructor(
    public id: any,
    public status: boolean,
    private toggleUrl,
    private http: HttpClient,
    private layout: LayoutUtilsService,
    private cdRef: ChangeDetectorRef
  ) {
    this.subject
      .pipe(
        switchMap(() => {
          return this.http.get(toggleUrl + "?status=" + (this.status ? 1 : 0));
        })
      )
      .subscribe(
        (res) => {},
        (err) => {
          this.status = !this.status; // revert
          this.layout.showNotification(
            "error",
            "The server was unable to process the request. Please retry the operation."
          );

          this.crefDetectChanges();
        }
      );
  }

  toggle(status?: boolean) {
    if (status === true) this.status = true;
    else if (status === false) this.status = false;
    else this.status = !this.status;
    this.subject.next(null);

    this.crefDetectChanges();
  }

  crefDetectChanges() {
    if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }
}

class CrudTableCustomFilter {
  field: string;
  title: string;
  type: string;
  value: any;
  validator: any;
  setMinDateDefaultValueToday: boolean;
  setDateDefaultValueFrom: string;
  setDateDefaultValueTo: string;
  lang: string;
  width: any;
  tableName = "";

  dynamic_options: any;

  crudTableService: any;

  // dynamicOptions: any = {
  //     url: null,
  //     data: [],
  //     value_field: 'value',
  //     label_field: 'label',
  // };

  constructor(
    public crudTableComponent: CrudTableComponent,
    lang,
    data,
    lsItem
  ) {
    this.field = data.field;
    this.title = data.title;
    this.type = data.type;
    this.width = data.width;
    this.setMinDateDefaultValueToday = data.set_min_date_default_value_today;
    if (this.setMinDateDefaultValueToday) {
      this.setDateDefaultValueFrom = moment().format();
    } else {
      this.setDateDefaultValueFrom = data.date_default_value_from
        ? moment(data.date_default_value_from).format()
        : null;
    }
    this.setDateDefaultValueTo = data.date_default_value_to
      ? moment(data.date_default_value_to).format()
      : null;

    this.lang = lang;

    this.value = lsItem ? undefined : data.value;

    if (this.type == "daterange") {
      this.value = {
        from: lsItem ? null : this.setDateDefaultValueFrom,
        to: lsItem ? null : this.setDateDefaultValueTo,
      };
      this.validator = {
        from: data.validator ? data.validator.from : null,
        to: data.validator ? data.validator.to : null,
      };
    } else {
      this.validator = data.validator;
    }

    if (data.dynamic_options) {
      this.dynamic_options = data.dynamic_options;
      this.dynamic_options.value_field =
        data.dynamic_options.value_field || "value";
      this.dynamic_options.label_field =
        data.dynamic_options.label_field || "label";
    }

    this.initDynamicOptions();
  }

  initDynamicOptions() {
    if (this.dynamic_options) {
      this.dynamic_options.value = null;
      if (!this.dynamic_options.parent_field) {
        if (this.dynamic_options.url) {
          this.dynamic_options.loading = true;
          this.dynamic_options.data = [];

          const isTestdata = this.crudTableComponent.isTestdata;

          let url =
            environment_api.api_url +
            this.parsePlaceholderValue(this.dynamic_options.url);
          url = KTUtil.updateURLParam(url, "lang", this.lang);
          url = KTUtil.updateURLParam(url, "isTestdata", isTestdata);

          if (this.crudTableComponent.searchFilterParams) {
            url =
              url +
              "&" +
              this.crudTableComponent.crudUtilsService.parsePlaceholders(
                this.crudTableComponent.searchFilterParams,
                this.crudTableComponent.masterData
              );
          }

          this.crudTableComponent.crudTableService
            .findItems(url, null)
            .subscribe((response: any) => {
              this.dynamic_options.loading = false;
              this.dynamic_options.data = Array.isArray(response)
                ? response
                : response.data;
            });
        }
      }
    }
  }

  // get parsed placeholders
  parsePlaceholderValue(placeholderValue) {
    let formattedValue = placeholderValue;

    if (formattedValue == null || formattedValue === "") return formattedValue;
    if (typeof formattedValue !== "string") return formattedValue;

    const placeholders = formattedValue.match(/{[^}]+}/g);

    if (!placeholders) return formattedValue;

    placeholders.forEach((placeholder) => {
      const fieldName = placeholder.substr(1, placeholder.length - 2);
      let fieldValue = get(this.dynamic_options.data, fieldName);
      if (fieldValue === undefined || fieldValue === null) fieldValue = "";
      formattedValue = formattedValue.replaceAll(placeholder, fieldValue);
    });

    return formattedValue;
  }

  getDynamicOptionsLabel(option) {
    let label = this.dynamic_options.label_field || "label";
    const placeholders = label.match(/{[^}]+}/g);
    if (!placeholders) return option[label];

    placeholders.forEach((element) => {
      const value = get(option, element.substr(1, element.length - 2));
      label = label.replaceAll(element, value);
    });

    return label;
  }

  getFieldWidthClass() {
    if (typeof this.width == "string") {
      return this.width;
    }
    if (typeof this.width == "number") {
      return `col-md-${this.width}`;
    } else {
      if (this.type == "daterange") return "col-md-8";
      else return "col-sm-6 col-md-4";
    }
  }
}

export class CrudTableProcessButton {
  processType: string; // both|action|mass
  actionLinkConfig: string;
  title: string;
  type: string;
  tooltip: string;
  icon: string;
  confirm = {
    message: "Are you sure?",
    ok_label: "Yes",
    cancel_label: "Cancel",
    param_name: null,
    param_type: null,
    param_display_name: null,
  };
  callback = {
    url: null,
    method: null,
    id_field: "id",
    map_field: null,
  };
  redirectUrl: string;

  form = null;

  btnClass: string;
  disabled: string;
  visible: string;
  contextMenu = false;

  isMassProcessing$ = new Subject<boolean>();
  selectedItems = [];
  currentFilters: HttpParams = null;
  isSendEmailForm = false;
  isInfoDialog = false;
  sendEmailConfig = null;
  isBoardDialogForm = false;
  boardDialogConfig = null;
  infoDialogConfig = null;
  hasChildren = false;
  indexForChildrenProcessButtons = "";
  constructor(
    public crudComponent: CrudTableComponent | CrudFormComponent,
    public http: HttpClient,
    public dialog: MatDialog,
    private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    settings,
    public crudUtilsService: CrudUtilsService = null,
    public funcTranslate: (text: string) => string = null,
    public funcCrefDetectChanges: () => void = null,
    public children = []
  ) {
    this.indexForChildrenProcessButtons =
      settings.indexForChildrenProcessButtons;
    if (settings?.children) {
      this.hasChildren = settings?.children.length > 0 ? true : false;
    }
    this.processType = settings.process_type || "both";
    this.actionLinkConfig = settings.action_link || null;
    this.title = settings.title;
    this.type = settings.type || "primary";
    this.tooltip = settings.tooltip;
    this.icon = settings.icon;
    this.disabled = settings.disabled;
    this.visible = settings.visible_if ?? null;
    this.contextMenu = settings.context_menu;
    if (settings.confirm !== null) this.confirm = settings.confirm;

    this.callback = settings.callback;
    this.redirectUrl = settings.redirect_url;
    this.form = settings.form;
    if (settings.hasOwnProperty("email_dialog")) {
      this.isSendEmailForm = true;
      this.sendEmailConfig = {
        display_field: settings.display_field,
        id_fields: settings.email_dialog.id_field || [settings.id_field] || [
            "id",
          ],
        callbacks: settings.email_dialog.callbacks,
      };
    }
    if (settings.hasOwnProperty("board_dialog")) {
      this.isBoardDialogForm = true;
      this.boardDialogConfig = {};
    }
    if (settings.hasOwnProperty("info_dialog")) {
      this.isInfoDialog = true;

      this.infoDialogConfig = {
        title: settings["info_dialog"].title || settings.title || "",
        displayRefreshButton:
          "display_refresh_button" in settings["info_dialog"]
            ? settings["info_dialog"]["display_refresh_button"]
            : true,
        apiUrl: settings["info_dialog"].api_url || "",
        id_fields: settings?.["info_dialog"]?.["id_field"] || ["id"],
      };
    }
    //
    this.btnClass = `mat-button-mt-4 ${this.type}-button`;
  }

  getIconClass(item) {
    if (this.icon.indexOf("{") == 0) {
      const evalStr = this.icon.substr(1, this.icon.length - 2);
      const val = (function (item, get) {
        return eval(evalStr);
      })(item, get);
      //if (item.user_id == 9) console.log('getIconClass', evalStr, val, item);
      return val;
    }
    return this.icon;
  }

  isProcessing(item: any) {
    return this.selectedItems.find((r) => r.id == item.id);
  }
  // selected: array of selected keys
  process(selected: any[], callback = null, currentFilters: HttpParams = null) {
    if (this.hasChildren) {
      return;
    }
    const item = selected[0];
    if (currentFilters !== null) {
      this.currentFilters = currentFilters;
    }
    if (this.form) {
      const dialogRef = this.dialog.open(FormsShellModalComponent, {
        data: {
          item: item,
          id: item?.id ?? null,
          name: this.crudUtilsService.parsePlaceholders(this.form.config, {
            ...item,
            lang: this.translate.currentLang,
          }),

          config: this.crudUtilsService.parsePlaceholders(this.form.config, {
            ...item,
            lang: this.translate.currentLang,
          }),
          apiUrl:
            environment_api.api_url +
            this.crudUtilsService.parsePlaceholders(this.form.callback.url, {
              ...item,
              lang: this.translate.currentLang,
            }),
          initialData: this.form.initial_data,
          modalMode: {
            message: this.form.message,
            buttons: this.form.buttons,
            displayWarningMessage: this.form.display_warning_message,
            extraPayload: this.prepareExtraPayloadForForm(selected),
          },
        },
        minWidth: "1200px",
      });

      dialogRef.afterClosed().subscribe((result) => {
        callback && callback();
      });
      return;
    }

    if (this.redirectUrl) {
      const url = this.crudUtilsService.parsePlaceholders(this.redirectUrl, {
        ...item,
        lang: this.translate.currentLang,
      });

      //console.log("1 Redirect URL: " + this.redirectUrl);
      if (this.redirectUrl.indexOf("mailto:") == 0) {
        window.location.href = "mailto:" + url;
      } else {
        window.open(url, "_blank");
      }
      return;
    }

    if (this.isSendEmailForm) {
      this.dialog.open(SendEmailComponent, {
        panelClass: ["fullscreen-modal"],
        data: {
          participants: selected,
          sendEmailConfig: { ...this.sendEmailConfig },
        },
        disableClose: true,
      });

      return;
    }

    if (this.isBoardDialogForm) {
      this.dialog.open(KanbanBoardComponent, {
        panelClass: ["new-email-dialog", "fullscreen-modal"],
        data: {
          board: selected,
        },
        disableClose: true,
      });

      return;
    }

    if (this.isInfoDialog) {
      const url =
        environment_api.api_url +
        this.crudUtilsService.parsePlaceholders(this.infoDialogConfig.apiUrl, {
          ...item,
          lang: this.translate.currentLang,
        });
      this.dialog.open(HtmlModalComponent, {
        panelClass: ["fullscreen-modal"],
        data: {
          selected,
          config: { ...this.infoDialogConfig, apiUrl: url },
        },
        disableClose: true,
      });
      return;
    }

    if (this.confirm) {
      let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
        data: {
          //title: this.crudComponent.getConfigTranslate(this.title),
          title: this.funcTranslate(this.title),
          message: this.funcTranslate(this.confirm.message),
          //waitDesciption: this.translate.instant('COMMON.PROCESSING_PLS_WAIT'),
          btnYesLabel: this.funcTranslate(this.confirm.ok_label),
          btnNoLabel: this.funcTranslate(this.confirm.cancel_label),
          paramName: this.confirm.param_name,
          paramType: this.confirm.param_type,
          paramDisplayName: this.confirm.param_display_name,
        },
        width: "500px",
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        //
        if (dialogResult) {
          if (dialogResult?.value !== "") {
            this.newProcessCallback(selected, dialogResult?.value, callback);
          } else {
            this.processCallback(selected, callback);
          }
        }
      });
    } else {
      this.processCallback(selected, callback);
    }
  }

  prepareExtraPayloadForForm(item: any[]) {
    if (!item?.length) return null;
    const idField = this.form.callback.id_field ?? "id";
    return {
      selected: item.map((r) => get(r, idField)).join(","),
    };
  }

  // PGMTODO - this is a new function that does the same as processCallback()
  // but I have added this so that nothing breaks and the old code works
  // selected is all the selected row, value is the value entered by the user
  newProcessCallback(selected: any[], value: any, callback = null) {
    this.isMassProcessing$.next(true);
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");

    let httpOptions = {};
    const idField = this.callback.id_field || "id";

    if (this.callback.method === "POST") {
      httpOptions = {
        body: JSON.stringify({
          [this.confirm.param_name]: value,
          selected: selected.map((r) => get(r, idField)).join(","),
        }),
      };
    } else {
      let params = new HttpParams();
      params = params.append(this.confirm.param_type, value);
      if (selected.length > 0) {
        const idField = this.callback.id_field || "id";
        params = params.append(
          "selected",
          selected.map((r) => get(r, idField)).join(",")
        );
      }

      httpOptions = {
        params: params,
      };
    }

    // including all the selected filters as well

    let callbackUrl =
      environment_api.api_url +
      this.crudUtilsService.parsePlaceholders(this.callback.url, {
        lang: this.translate.currentLang,
      });

    this.http.request(this.callback.method, callbackUrl, httpOptions).subscribe(
      (response: any) => {
        if (response.modal === true) {
          const _title = response.title || "Info";
          const _icon =
            response.icon || '<i class="fas fa-car-crash fa-9x"></i>';
          this.dialog.open(InfoDialogComponent, {
            data: {
              title: _title,
              icon: _icon,
              message: response.message,
            },
            width: "500px",
          });
        } else {
          const _url = response.toastrurl || "";
          if (response.message) {
            this.layoutUtilsService.showNotification("info", response.message);
          } else {
            // this.layoutUtilsService.showNotification("info", 'EMPTY 3234');
          }
        }

        if (this.crudComponent instanceof CrudTableComponent) {
          if (response.refresh_table || response.refresh_data) {
            this.crudComponent.loadTableList();
          }
        } else {
          this.crudComponent.reloadFormComponent();
        }

        if (response.redirect) {
          const url = response.redirect.url;

          if (url.indexOf("http") == 0) {
            setTimeout(() => {
              window.open(
                response.redirect.url,
                response.redirect.target || "_self"
              );
            }, 1500);
          } else if (url.indexOf("mailto") == 0) {
            setTimeout(() => {
              window.open(
                response.redirect.url,
                response.redirect.target || "_self"
              );
            }, 1500);
          } else {
            if (this.crudComponent instanceof CrudTableComponent) {
              this.crudComponent.router.navigateByUrl(url);
            } else {
              this.crudComponent.reloadFormComponent();
            }
          }
        }
        if (callback) {
          callback();
        }
        this.funcCrefDetectChanges();
      },
      (error) => {
        // remove loader on mass button
        this.isMassProcessing$.next(false);
        this.dialog.open(InfoDialogComponent, {
          data: {
            title: "Error",
            icon: '<i class="fas fa-bomb  fa-9x"></i>',
            message: this.translate.instant("COMMON.UNEXPECTED_ERROR"),
          },
          width: "500px",
        });
        // this.layoutUtilsService.showActionNotification(this.translate.instant('COMMON.UNEXPECTED_ERROR'));

        this.funcCrefDetectChanges();
      },
      () => {
        this.isMassProcessing$.next(false);
        this.funcCrefDetectChanges();
      }
    );
  }

  processCallback(selected: any[], callback = null) {
    if (!this.hasChildren) {
      this.isMassProcessing$.next(true);
    }
    this.selectedItems = this.selectedItems.concat(selected);
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    let params = this.currentFilters;

    if (this.currentFilters === null) {
      params = new HttpParams();
    }
    const idField = this.callback.id_field || "id";
    const mapField = this.callback.map_field;

    params = params.append(
      "selected",
      selected.map((r) => get(r, idField)).join(",")
    );

    params = params.append("is_testdata", localStorage.getItem("is_testdata"));

    let callbackUrl =
      environment_api.api_url +
      this.crudUtilsService.parsePlaceholders(this.callback.url, {
        lang: this.translate.currentLang,
      });

    this.http
      .request(this.callback.method, callbackUrl, { params: params })
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.modal === true) {
            const _title = response.title || "Info";
            const _icon =
              response.icon || '<i class="fas fa-car-crash fa-9x"></i>';
            this.dialog.open(InfoDialogComponent, {
              data: {
                title: _title,
                icon: _icon,
                message: response.message,
              },
              width: "500px",
            });
          } else {
            if (response.message) {
              this.layoutUtilsService.showNotification(
                "info",
                response.message
              );
            } else {
              // this.layoutUtilsService.showNotification("info", 'EMPTY 3343');
            }
          }
          if (response.processed_data) {
            response.processed_data.forEach((data) => {
              let itemIdx = selected.findIndex(
                (r) => get(r, idField) == data.id
              );
              let item = mapField
                ? get(selected[itemIdx], mapField)
                : selected[itemIdx];
              _merge(item, data);
            });
          }

          if (this.crudComponent instanceof CrudTableComponent) {
            if (response.refresh_table || response.refresh_data) {
              this.crudComponent.loadTableList();
            }
          } else {
            this.crudComponent.reloadFormComponent();
          }

          if (response.redirect) {
            const url = response.redirect.url;

            if (url.indexOf("http") == 0) {
              setTimeout(() => {
                window.open(
                  response.redirect.url,
                  response.redirect.target || "_self"
                );
              }, 1500);
            } else if (url.indexOf("mailto") == 0) {
              setTimeout(() => {
                window.open(
                  response.redirect.url,
                  response.redirect.target || "_self"
                );
              }, 1500);
            } else {
              if (this.crudComponent instanceof CrudTableComponent) {
                this.crudComponent.router.navigateByUrl(url);
              } else {
                this.crudComponent.reloadFormComponent();
              }
            }
          }
          if (callback) {
            callback();
          }
          this.funcCrefDetectChanges();
        },
        (error) => {
          // remove loader on mass button
          this.isMassProcessing$.next(false);
          // remove loader on action button
          this.selectedItems = this.selectedItems.filter(
            (r) => !selected.find((s) => s.id === r.id)
          );
          //
          this.dialog.open(InfoDialogComponent, {
            data: {
              title: "Error",
              icon: '<i class="fas fa-bomb  fa-9x"></i>',
              message: this.translate.instant("COMMON.UNEXPECTED_ERROR"),
            },
            width: "500px",
          });
          // this.layoutUtilsService.showActionNotification(this.translate.instant('COMMON.UNEXPECTED_ERROR'));

          this.funcCrefDetectChanges();
        },
        () => {
          this.isMassProcessing$.next(false);
          this.selectedItems = this.selectedItems.filter(
            (r) => !selected.find((s) => s.id === r.id)
          );

          this.funcCrefDetectChanges();
        }
      );
  }

  // get parsed placeholders
  evalStr(evalStr) {
    if (evalStr.indexOf("{") == 0)
      return eval(evalStr.substr(1, evalStr.length - 2));

    return evalStr;
  }

  getID() {
    return this.title.toLowerCase().replace(/ /g, "-");
  }

  isDisabled(item) {
    if (this.disabled == null) return false;
    return this.crudUtilsService.parsePlaceholders(this.disabled, item);
  }

  isVisible(item) {
    if (this.visible == null) return true;
    return this.crudUtilsService.parsePlaceholders(this.visible, item);
  }

  getTitle(item) {
    if (!this.title) return "";
    return this.crudUtilsService.parsePlaceholders(this.title, item);
  }

  getTooltip(item) {
    if (!this.tooltip) return "";
    return this.crudUtilsService.parsePlaceholders(this.tooltip, item);
  }

  /**
   * PGM attribute selector
   */
  getPGMAttr(item) {
    if (this.crudComponent instanceof CrudTableComponent) {
      return (
        this.crudComponent.name + "_tablebutton_" + item.id + "_" + this.getID()
      );
    } else {
      return "formbutton_" + item.id + "_" + this.getID();
    }
  }

  processTableButton(callback = null) {
    console.log(callback);
  }
}
