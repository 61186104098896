<mat-expansion-panel *ngIf="templateExtraInfo$ | async as info">
  <mat-expansion-panel-header>
    <mat-panel-title> Information </mat-panel-title>    
  </mat-expansion-panel-header>
  <div class="info-container">
    <div class="line">
      <!-- <div *ngIf="info.cc" class="cell"> -->
      <div class="cell">
        <p class="label">CC:</p>
        <p>
          {{ info?.cc }}
        </p>
      </div>
      <!-- <div *ngIf="info.bcc" class="cell"> -->
      <div class="cell">
        <p class="label">BCC:</p>
        <p>
          {{ info?.bcc }}
        </p>
      </div>
    </div>
    <!-- <div class="line" *ngIf="info.attachments"> -->
    <div class="line">
      <div class="cell">
        <p class="label">Attached Documents:</p>
        <p>
          {{ info?.attachments }}
        </p>
      </div>
    </div>
  </div>
</mat-expansion-panel>
