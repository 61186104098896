<div>
	<div class="form-group kt-form__group row align-items-baseline" *ngIf="unassignedRoles.length > 0">
		<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
			<mat-form-field class="mat-form-field-fluid custom-input">
				<mat-select placeholder="Role" [(ngModel)]="roleIdForAdding">
					<mat-option *ngFor="let role of unassignedRoles" value="{{ role.id }}">{{role.role_name}}</mat-option>
				</mat-select>
				<mat-hint align="start">
					<strong>Select Role</strong>
				</mat-hint>
			</mat-form-field>
		</div>
		<div class="col-lg-4 custom-field-holder">
			<button type="button" mat-raised-button matTooltip="Assign role" (click)="assignRole()" class="mat-button-mt-4 danger-button">
				Assign
			</button>
		</div>
	</div>
	<h6 class="kt-section__heading" *ngIf="assignedRoles.length > 0">
		Assigned roles:
	</h6>
	<div class="form-group kt-form__group row" *ngIf="assignedRoles.length > 0">
		<div class="col-lg-8 kt-margin-bottom-20-mobile">
			<div class="kt-list-timeline kt-list-timeline--user-role">
				<div class="kt-list-timeline__items">
					<div class="kt-list-timeline__item m-0 p-0" *ngFor="let _role of assignedRoles">
						<span class="kt-list-timeline__badge kt-list-timeline__badge--danger"></span>
						<span class="kt-list-timeline__text">{{ _role.role_name }}</span>
						<span class="kt-list-timeline__time">
							<button mat-icon-button color="warn" matTooltip="Remove role" type="button" (click)="unassingRole(_role)">
								<mat-icon>delete</mat-icon>
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
