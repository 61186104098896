<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
  <crud-table
    title="Invoices"
    name="invoices"
    [columns]="columns"
    [createButtonLabel]="createButtonLabel"
    [apiUrl]="apiUrl"
    baseUrl="/trackdays-management/invoices"
    [readonly]="false"
  ></crud-table>
</div>
