// Angular
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
// RxJS
import { Observable, of } from "rxjs";
// Models

import { HttpUtilsService } from "../core/base";

import { ApiConfig } from "../core/config/api.config";

@Injectable({
  providedIn: "root",
})
export class TableConfigService {
  /**
   * Service Constructor
   *
   * @param http: HttpClient
   */
  constructor(
    private http: HttpClient,
    private httpUtilsService: HttpUtilsService
  ) {}

  getTableConfig(locale: string, tableName: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    //console.log(ApiConfig.TableConfigURL(locale, tableName));
    return this.http.get<any>(ApiConfig.TableConfigURL(locale, tableName));
  }

  getTablePaginationConfig(locale: string, tableName: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.get<any>(
      ApiConfig.TablePaginationConfigURL(locale, tableName)
    );
  }

  getTableExportConfig(
    docType: string,
    name: string,
    quaryParam: any,
    columns: string[],
    isApplyFilter: boolean,
    orientation = "Portrait"
  ): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");

    let params = this.httpUtilsService.convertQueryParams(quaryParam);
    if (columns && columns.length > 0)
      params = params.set("columns", columns.join(","));
    params = params.set("apply_filters", isApplyFilter ? "1" : "0");
    params = params.set("orientation", orientation);

    return this.http.get<any>(ApiConfig.GenerateDocUrl(docType, name), {
      params: params,
    });
  }

  getTableExportConfig2({
    documentFormat,
    columnFormat,
    dataFormat,
    tableQueryParams,
    columns,
    isApplyFilter,
    orientation,
    tableName,
    tableConfigUrl,
  }: {
    documentFormat: string;
    columnFormat: string;
    dataFormat: string;
    tableQueryParams: any;
    columns: any[];
    // not sure if needed any more
    // keeping it for now, just in case
    isApplyFilter: boolean;
    orientation: "portrait" | "landscape";
    tableName: string;
    tableConfigUrl: string;
  }): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");

    let params = this.httpUtilsService.convertQueryParams(tableQueryParams);
    if (tableConfigUrl) {
      // here we try to extract initial query params from the table config URL
      // and add them to the request
      const url = new URL(tableConfigUrl);
      const urlSearchParams = new URLSearchParams(url.search);
      urlSearchParams.forEach((value, key) => {
        params = params.set(key, value);
      });
    }
    params = params.set("apply_filters", isApplyFilter ? "1" : "0");
    params = params.set("orientation", orientation);
    params = params.set("column_format", columnFormat);
    params = params.set("data_format", dataFormat);
    return this.http.post<any>(
      ApiConfig.GenerateDocUrl(documentFormat.toLowerCase(), tableName),
      {
        // passing columns in the body because of the limitation of the URL length
        columns,
      },
      {
        params: params,
      }
    );
  }

  loadExportTemplates(configName: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.get<any>(
      `${ApiConfig.SaveExportTemplateUrl()}/${configName}`,
      {
        headers: httpHeaders,
      }
    );
  }

  deleteExportTemplate(id: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.delete<any>(
      `${ApiConfig.SaveExportTemplateUrl()}/${id}`,
      {
        headers: httpHeaders,
      }
    );
  }

  saveExportTemplate(payload: any, id?: any): Observable<any> {
    const preparedPayload = {
      name: payload.name,
      private: payload.private,
      column_config: JSON.stringify(payload.columns),
      table_config_name: payload.tableName,
    };

    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    let url = `${ApiConfig.SaveExportTemplateUrl()}/${payload.tableName}`;
    if (id) {
      url = `${ApiConfig.SaveExportTemplateUrl()}/${id}`;
    }
    return this.http.request(id ? "put" : "post", url, {
      body: preparedPayload,
      headers: httpHeaders,
    });
    // return this.http.post<any>(ApiConfig.SaveExportTemplateUrl(), payload, {
    //   headers: httpHeaders,
    // });
  }
}
