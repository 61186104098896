import { createAction, props } from "@ngrx/store";

export const closeSidebar = createAction("[Help Sidebar] Close Sidebar");

export const openSidebar = createAction("[Help Sidebar] Open Sidebar");

export const setTargetUrl = createAction(
  "[Help Sidebar] Set Target Url",
  props<{ targetUrl: string }>()
);

export const helpSidebarActions = {
  closeSidebar,
  openSidebar,
  setTargetUrl,
};
