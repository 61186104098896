import { ComponentType } from "@angular/cdk/portal";
import { Injectable, NgZone } from "@angular/core";
import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from "@angular/material/bottom-sheet";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import {
  AddReplaceDialogDataType,
  ConfirmModalDataModel,
} from "../shared/data/models";
import {
  ConfirmActionDialogComponent,
  DeleteEntityDialogComponent,
} from "../views/partials/content";
import { AddReplaceModalComponent } from "../views/partials/content/add-replace-modal/add-replace-modal.component";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private zone: NgZone,
    private translationService: TranslateService
  ) {}

  // at least the component should be passed
  addReplaceDialog(options: MatDialogConfig<AddReplaceDialogDataType>) {
    return new Observable((observer) => {
      this.zone.run(() =>
        this.dialog
          .open(AddReplaceModalComponent, {
            width: "440px",
            ...options,
          })
          .afterClosed()
          .subscribe({
            next: (result) => observer.next(result),
            error: (err) => observer.error(err),
            complete: () => observer.complete(),
          })
      );
    });
  }

  confirm<T>(model: Partial<ConfirmModalDataModel>): Observable<T> {
    return new Observable((observer) => {
      this.zone.run(() =>
        this.dialog
          .open(ConfirmActionDialogComponent, {
            data: {
              title: model.title,
              message: model.message,
              btnYesLabel: model.btnYesLabel,
              btnNoLabel: model.btnNoLabel,
              paramName: model.paramName,
              paramType: model.paramType,
              paramDisplayName: model.paramDisplayName,
            },
            maxWidth: "500px",
            width: "100%",
          })
          .afterClosed()
          .subscribe({
            next: (result) => observer.next(result),
            error: (err) => observer.error(err),
            complete: () => observer.complete(),
          })
      );
    });
  }

  delete<T>({
    title,
    description,
    waitDescription,
  }: {
    title: string;
    description: string;
    waitDescription: string;
  }): Observable<T> {
    return new Observable((observer) => {
      this.zone.run(() =>
        this.dialog
          .open(DeleteEntityDialogComponent, {
            data: { title, description, waitDescription },
            width: "440px",
          })
          .afterClosed()
          .subscribe({
            next: (result) => observer.next(result),
            error: (err) => observer.error(err),
            complete: () => observer.complete(),
          })
      );
    });
  }

  openDialog(component: ComponentType<any>, config?: MatDialogConfig) {
    return new Observable((observer) => {
      this.zone.run(() =>
        this.dialog
          .open(component, {
            maxWidth: "600px",
            width: "100%",
            ...config,
          })
          .afterClosed()
          .subscribe({
            next: (result) => observer.next(result),
            error: (err) => observer.error(err),
            complete: () => observer.complete(),
          })
      );
    });
  }

  openBottomSheet(
    component: ComponentType<any>,
    config?: MatBottomSheetConfig
  ) {
    return new Observable((observer) => {
      this.zone.run(() =>
        this.bottomSheet
          .open(component, {
            panelClass: "fullscreen-modal",
            disableClose: true,
            ...config,
          })
          .afterDismissed()
          .subscribe({
            next: (result) => observer.next(result),
            error: (err) => observer.error(err),
            complete: () => observer.complete(),
          })
      );
    });
  }
}
