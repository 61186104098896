<div class="actions">
    <button mat-raised-button (click)="addBoard()" [disabled]="!(activeProject$ | async)" color="accent">Add Board</button>
</div>
<div class="wrapper">
    <div class="sidebar">
        <div class="project-header">
            <h1>Projects</h1>
            <button mat-icon-button (click)="addProject()">
                <span class="fa fa-plus"></span>
            </button>            
        </div>
        <div>
            <mat-selection-list [multiple]="false" #projectSelection>
                <mat-list-option *ngFor="let project of projects$ | async" role="listitem" [value]="project.id"
                    (click)="getBoardsByProjectId(project.id)">
                    {{project.title}}
                </mat-list-option>
            </mat-selection-list>
        </div>

    </div>
    <div class="main">
        <div class="boards" *ngFor="let board of (activeProject$ | async)?.boards"
        [routerLink]="['../board']">
            <mat-card class="">
                <mat-card-title-group>
                    <mat-card-title>{{board.title}}</mat-card-title>
                    <div class="boxed-color" [ngStyle]="{'background-color': board.color}"></div>
                </mat-card-title-group>
                <mat-card-content>
                    {{board.description}}
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>