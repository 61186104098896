import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { FormConfigService } from "../../../form.config.service";
import { CheckBoxConfig } from "../models";

@Component({
  selector: "cb-checkbox",
  template: `
    <div [formGroup]="form" class="mat-form-field-fluid custom-input">
      <mat-checkbox
        class="custom-checkbox"
        color="primary"
        [formControlName]="config.formControlName"
        (change)="clickHandler($event)"
        [attr.data-pgm-formelement]="config.testingAttribute"
      >
        {{ config.label }}
        <ng-container *ngIf="!!field.tooltip">
          <span
            class="pl-1"
            [matTooltip]="config.tooltip"
            matTooltipPosition="above"
          >
            <i class="far fa-question-circle"></i>
          </span>
        </ng-container>
      </mat-checkbox>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CbCheckBoxComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: CheckBoxConfig;
  constructor(private formConfigService: FormConfigService) {}

  clickHandler(event: MatCheckboxChange) {
    this.formConfigService.updateFormConfigs(event.checked, this.field);
  }

  ngOnInit(): void {
    // if (this.config.disabled) {
    //   const formElement = this.formConfigService.getFormElement(
    //     this.field.name
    //   );
    //   if (formElement) {
    //     formElement.disable();
    //   }
    // }
    // this.form.controls[this.field.name].valueChanges
    //   .pipe(
    //     distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    //   )
    //   .subscribe({
    //     next: (value) => {
    //       if (value !== undefined) {
    //         setTimeout(() => {
    //           this.formConfigService.updateFormConfigs(value, this.field);
    //         });
    //       }
    //     },
    //   });
  }
}
