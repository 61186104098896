<style>
    table.dt_table.display{
        border: 1px solid;
        margin: 15px;
    }

    table.dt_table.display th, table.dt_table.display td {
        padding: 5px;
    }
</style>
<div><h3>SRS/1.38.20230628</h3>
    <table style="width:100%" class="dt_table display">
        <thead>
        <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Release Date</th>
            <th>Released</th>
            <th>Fixed Issues</th>
            <th>Affected Issues</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1.38.20230628</td>
            <td>10127</td>
            <td>2023-06-28</td>
            <td><span class="badge badge-danger">No</span></td>
            <td>5</td>
            <td>0</td>
        </tr>
        </tbody>
    </table>
    <table class="dt_table display" style="width:100%">
        <thead>
        <tr>
            <th>Key</th>
            <th>Type</th>
            <th>Status</th>
            <th>Summary</th>
            <th>Version</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1631">SRS-1631</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-danger">To Do</span></td>
            <td>Add possibility to sell coupons from the website</td>
            <td>1.38.20230628</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1629">SRS-1629</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-secondary">Keep watching</span></td>
            <td>Create coupon PDF</td>
            <td>1.38.20230628</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1618">SRS-1618</a></td>
            <td><span class="badge badge-warning">New Feature</span></td>
            <td><span class="badge badge-danger">To Do</span></td>
            <td>Add Stripe Logging for all Stripe API-Calls</td>
            <td>1.38.20230628</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1591">SRS-1591</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-danger">To Do</span></td>
            <td>Create booking confirmation PDF file</td>
            <td>1.38.20230628</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1619">SRS-1619</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-secondary">Unestimated</span></td>
            <td>Improve service list of placeholder $booking_services_summary</td>
            <td>1.38.20230628</td>
        </tr>
        </tbody>
    </table>
</div>
<div><h3>SRS/1.37.20230614</h3>
    <table style="width:100%" class="dt_table display">
        <thead>
        <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Release Date</th>
            <th>Released</th>
            <th>Fixed Issues</th>
            <th>Affected Issues</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1.37.20230614</td>
            <td>10124</td>
            <td>2023-06-14</td>
            <td><span class="badge badge-success">Yes</span></td>
            <td>17</td>
            <td>0</td>
        </tr>
        </tbody>
    </table>
    <table class="dt_table display" style="width:100%">
        <thead>
        <tr>
            <th>Key</th>
            <th>Type</th>
            <th>Status</th>
            <th>Summary</th>
            <th>Version</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1727">SRS-1727</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add analytics api-call to be called on each menu-item-click</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1726">SRS-1726</a></td>
            <td><span class="badge badge-secondary">Sub-task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Card component: Add width to attributes instead of using a new layout</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1724">SRS-1724</a></td>
            <td><span class="badge badge-secondary">Sub-task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Card component: move edit pen to the last position</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1723">SRS-1723</a></td>
            <td><span class="badge badge-secondary">Sub-task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Card component: Make header/subheader sortable</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1722">SRS-1722</a></td>
            <td><span class="badge badge-secondary">Sub-task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Card component: MOve badges to the bottom for 2-column layout</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1721">SRS-1721</a></td>
            <td><span class="badge badge-secondary">Sub-task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Card component: Add additional two-column layout</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1720">SRS-1720</a></td>
            <td><span class="badge badge-secondary">Sub-task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Card Component: Add sort silluette to all sortable properties</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1719">SRS-1719</a></td>
            <td><span class="badge badge-secondary">Sub-task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Card component: Add Refresh</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1715">SRS-1715</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Child form-shells should hide parent header</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1714">SRS-1714</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Update TeamVehiclesController::index to sort and search all columns</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1711">SRS-1711</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Replace snackbar by toastr</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1710">SRS-1710</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD Table Championships/Seasons/Team: Update</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1709">SRS-1709</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add option to remove filters from crud-table-portlets</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1708">SRS-1708</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add card to all form portlets</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1706">SRS-1706</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Table Buttons: Show only Icon if container has a width of max 600</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1702">SRS-1702</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Update Table and Form Documentation</td>
            <td>1.37.20230614</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1570">SRS-1570</a></td>
            <td><span class="badge badge-warning">New Feature</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add a CRUD-Card componet</td>
            <td>1.37.20230614</td>
        </tr>
        </tbody>
    </table>
</div>
<div><h3>SRS/1.36.20230531</h3>
    <table style="width:100%" class="dt_table display">
        <thead>
        <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Release Date</th>
            <th>Released</th>
            <th>Fixed Issues</th>
            <th>Affected Issues</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1.36.20230531</td>
            <td>10123</td>
            <td>2023-05-31</td>
            <td><span class="badge badge-success">Yes</span></td>
            <td>21</td>
            <td>0</td>
        </tr>
        </tbody>
    </table>
    <table class="dt_table display" style="width:100%">
        <thead>
        <tr>
            <th>Key</th>
            <th>Type</th>
            <th>Status</th>
            <th>Summary</th>
            <th>Version</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1712">SRS-1712</a></td>
            <td><span class="badge badge-danger">Bug</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>CRUD-Table: Process Buttons are not updated</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1705">SRS-1705</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add export-config save API</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1704">SRS-1704</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add slug to TrackdayItem</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1703">SRS-1703</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>PLease rename Excel export Checkboxnames</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1701">SRS-1701</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>The Excel-Export should be able to save presets</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1700">SRS-1700</a></td>
            <td><span class="badge badge-danger">Bug</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Child CRUD-Table: Wrong pagesize is displayed in an initial request</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1699">SRS-1699</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add coupon search to global search</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1694">SRS-1694</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Frontend: Create Dialog based on form-config</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1693">SRS-1693</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add Active Start End to Coupon Table</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1692">SRS-1692</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add Company Info (Name, Legal Form, Tax ID) to booking-list</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1690">SRS-1690</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add a Job-Log to the Dashboard</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1687">SRS-1687</a></td>
            <td><span class="badge badge-warning">New Feature</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Admin->Settings/User->Configuration: Please convert it to a modal fullscreen dialog</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1686">SRS-1686</a></td>
            <td><span class="badge badge-warning">New Feature</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Add Theming to PGMware</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1685">SRS-1685</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Form-Shell should show the same menu that the corresponding CRUD-table</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1683">SRS-1683</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Remove all HTML from Excel-Export</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1682">SRS-1682</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Update API-Call tracking</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1679">SRS-1679</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>optimizing Data Pagination</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1665">SRS-1665</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Coupon Export: Include relative and active start-end</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1614">SRS-1614</a></td>
            <td><span class="badge badge-success">Task</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>pricesController::index</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1516">SRS-1516</a></td>
            <td><span class="badge badge-danger">Bug</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Admin->Settings/User->Configuration: Add indicator to show that settings shoud be saved</td>
            <td>1.36.20230531</td>
        </tr>
        <tr>
            <td><a target="_blank" href="https://circuit-booking.atlassian.net/browse/SRS-1099">SRS-1099</a></td>
            <td><span class="badge badge-danger">Bug</span></td>
            <td><span class="badge badge-success">Done</span></td>
            <td>Frontend shows english although set to german</td>
            <td>1.36.20230531</td>
        </tr>
        </tbody>
    </table>
</div>