import { Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  inject,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import { distinctUntilChanged, map, takeUntil, tap } from "rxjs/operators";
import { ITab } from "../../../../shared/data/models";
import { FormConfigService } from "../../form.config.service";

// this should be in charge of submitting the form
// this contains the `shell form` which includes all other forms groups
@Component({
  selector: "app-forms-shell",
  templateUrl: "./forms-shell.component.html",
  styleUrls: ["./forms-shell.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormConfigService],
})
export class FormsShellComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() apiUrl: string; // this is the config file url
  @Input() listUrl: string; // the route the form should redirect after close and save
  @Input() name: string;
  @Input() initialData?: any;
  @Input() parentData?: any;
  @Input() isSubForm? = false;
  @Input() customActionButtons: TemplateRef<any> = null;
  @Input() modalMode = null;
  @Input() initialTab = null;
  //   private _customActionButtons: TemplateRef<any>;
  //   get customActionButtons(): TemplateRef<any> {
  //     return this._customActionButtons;
  //   }
  //   @Input()
  //   set customActionButtons(value: TemplateRef<any>) {
  //     this._customActionButtons = value;
  //   }

  @Output() onExit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReset: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBreadCrumb: EventEmitter<any> = new EventEmitter<any>();
  formConfig = inject(FormConfigService);
  activatedRoute = inject(ActivatedRoute);
  location = inject(Location);
  router = inject(Router);
  toastr = inject(ToastrService);
  translateService = inject(TranslateService);

  // we need to be able to pass a optional parameter, which indicate
  // this form is being opened as a subForm (which means we cannot rely on query parameter for ID)
  // if that is the case, instead of reading id from url, we get it as a input

  shellForm: UntypedFormGroup = new UntypedFormGroup({});
  //   config = configFromApi;
  allTabs$ = new BehaviorSubject<ITab[]>([]);
  tabErrors$ = new BehaviorSubject<string[]>([]);
  asyncErrors$ = new BehaviorSubject<{ tab: string; message: string }[]>([]);
  resetSelectedTab$ = new Subject();
  isLoading$ = new BehaviorSubject(true);
  destroyed$ = new Subject<void>();
  requestedTab: string;
  blockUI$ = this.formConfig.blockUI$;
  isWizard = false;
  isSubFormShown$ = this.formConfig.isSubFormShown$;

  logger$ = combineLatest([
    this.formConfig.isSubFormShown$,
    this.formConfig.tabs$,
  ])
    .pipe(
      takeUntil(this.destroyed$),
      tap(([isSubFormShown, tabs]) => {
        if (this.formConfig.formName === "users") {
          const toLog = [];
          toLog.push(["isSubFormShown", isSubFormShown]);
          toLog.push(["isSubForm", this.isSubForm]);
          toLog.push(["formMode", this.formConfig.formMode]);
          toLog.push(["apiUrl", this.apiUrl]);
          toLog.push(["listUrl", this.listUrl]);
          toLog.push(["name", this.name]);
          toLog.push(["requestedTab", this.requestedTab]);
          toLog.push(["initialData", JSON.stringify(this.initialData)]);
          toLog.push(["parentData", JSON.stringify(this.parentData)]);
          toLog.push(["Tab Status", tabs.map((tab) => tab.disabled).join(",")]);
          //   console.clear();
          //   console.warn("SHELL");
          //   console.warn(
          //     "%c Please send a screenshot of tables below",
          //     "color: #ee244b; font-size: 20px;"
          //   );
          //   console.table(toLog);
        }
      })
    )
    .subscribe();

  constructor() {
    this.formConfig.isWizard$.subscribe({
      next: (value) => {
        this.isWizard = value;
      },
    });
  }
  htmlHeader$ = this.formConfig.htmlHeader$.pipe(
    takeUntil(this.destroyed$),
    map((value) => {
      if (value) {
        return this.parseHtmlSectionConfig({
          config: value,
          position: "header",
        });
      }
      return {
        show: false,
      };
    })
  );
  htmlSidebar$ = this.formConfig.htmlSidebar$.pipe(
    takeUntil(this.destroyed$),
    map((value) => {
      if (value) {
        return this.parseHtmlSectionConfig({
          config: value,
          position: "sidebar",
        });
      }
      return {
        show: false,
      };
    })
  );
  styleOverrides$ = this.formConfig.styleOverrides$;

  ngOnInit(): void {
    if (this.apiUrl) {
      this.formConfig.apiUrl = this.apiUrl;
      this.formConfig.listUrl = this.listUrl;
      this.formConfig.formName = this.name;
      this.formConfig.isSubForm = this.isSubForm;
      this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (params) => {
          this._init(params);
        },
        error: (error) => {},
      });
    }
  }

  private _init(params: Params) {
    // if initial data is given, it means that user has clicked on edit/create
    // button of a table that is inside a tab (a table inside the form)
    // else, we read the value from url which means it is a top level form
    this.requestedTab = params?.["tab"]?.toLowerCase() || this.initialTab;
    let id;
    if (this.initialData) {
      this.formConfig.formInitialData = this.initialData;
      id = this.initialData.id;
    } else if (params && params["id"]) {
      id = params["id"];
    }

    if (id) {
      this.shellForm = new UntypedFormGroup({});
      this.allTabs$.next([]);
      this.tabErrors$.next([]);
      this.editModeInitialization(id);
    } else {
      this.formConfig.formMode = "CREATE";
      this.formConfig.id = "";

      this.formConfig.updateFormData({
        ...this.initialData,
      });
      this.getFormConfigAndData();
    }
  }

  getFormConfigAndData() {
    this.isLoading$.next(true);
    this.formConfig
      .getFormConfig(this.name)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (config: any) => {
          // TODO:
          // this might break some forms,  use with caution, changing the order might cause issues
          //   this.initiateForm(config);
          const parentData = config.config.parent_data;
          if (parentData) {
            this.formConfig
              .getFormParentData(parentData.api_url)
              .subscribe((resp) => {
                // set(this.data, parentData.map_to_data, resp.data);
                this.formConfig.updateFormData({
                  ...this.initialData,
                  ...this.formConfig.formData,
                  [parentData.map_to_data]: resp.data,
                });
              });
          } else if (this.parentData) {
            this.formConfig.updateFormData({
              ...this.initialData,
              ...this.formConfig.formData,
              _parent: this.parentData,
            });
          }
          this.initiateForm(config);
        },
        error: (error) => {},
        complete: () => {
          this.isLoading$.next(false);
        },
      });
  }

  ngAfterViewInit(): void {
    this.subscribeToFormStatusChange();
  }

  editModeInitialization = (id) => {
    this.isLoading$.next(true);
    this.formConfig.formMode = "EDIT";
    this.formConfig.id = id;
    this.formConfig
      .getItemById(this.apiUrl, id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (config: any) => {
          this.formConfig.updateFormData({
            ...this.initialData,
            ...config.data,
            // ...this.parsedInitialData,
          });
          this.initiateForm(config);
        },
        error: (error) => {},
        complete: () => {
          this.isLoading$.next(false);
        },
      });
  };

  private subscribeToFormStatusChange() {
    this.shellForm.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe((value) => {
        const status = this.shellForm.status;
        if (status === "VALID") {
          this.tabErrors$.next([]);
        }
        if (status === "INVALID") {
          if (this.shellForm.dirty) {
            this.updateTabErrorStatus();
          }
        }
      });
  }

  initiateForm(config) {
    this.formConfig.updateConfigFromServer(config);
    this.formConfig.initiateForm();
    let allTabs = this.formConfig.configParser();
    if (!this.isWizard) {
      if (this.requestedTab) {
        this.allTabs$.next(
          allTabs.map((tab) => {
            if (
              this.requestedTab === tab.name.split(" ").join("_").toLowerCase()
            ) {
              return {
                ...tab,
                selected: true,
              };
            }
            return tab;
          })
        );
      } else {
        this.allTabs$.next(allTabs);
      }
      const formGroups = this.formConfig.extractForms();
      for (const form of formGroups) {
        this.shellForm.addControl(form[0], form[1]);
      }
      this.formConfig.formInitialValues = this.shellForm.value;
      this.formConfig.updateForm(this.shellForm);
      this.formConfig.getDefaultValuesFromServer();
      //   this.formConfig.addAsyncValidatorsToForm();
    }
  }

  onRefreshForm = () => {
    this.resetFormInEditMode({ id: this.formConfig.id });
  };

  onExitEventHandler = (event) => {
    if (this.formConfig.isSubForm) {
      this.onExit.emit();
    } else {
      this.router.navigateByUrl(this.formConfig.listUrl);
    }
  };

  // event handler for when the form shell is opened from a modal
  // currently it is being used in a narrow use case
  // but later with more use cases, it can be made more generic
  onModalSubmitEventHandler = ({
    shouldExit = false,
    shouldCreateNew = false,
  }: {
    shouldExit?: boolean;
    shouldCreateNew?: boolean;
  }) => {
    this.toastr.clear();
    this.updateTabErrorStatus();
    if (this.formConfig.form.valid) {
      this.formConfig
        .tryToSubmitFromModal(this.modalMode.extraPayload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (data) => {
            if (shouldExit) {
              this.onExitEventHandler(null);
            }
          },
          complete: () => {
            this.toastr.success(
              this.translateService.instant("COMMON.PROCESS_SUCCESS")
            );
          },
          error: (error) => {
            if (error?.internal) {
              this.toastr.error(
                `Please record this code "${error.internal}", and contact support.`,
                "Error",
                {
                  disableTimeOut: true,
                }
              );
            } else {
              this.toastr.error(
                this.translateService.instant("COMMON.UNEXPECTED_ERROR"),
                null,
                {
                  disableTimeOut: true,
                }
              );
            }
          },
        });
    } else {
      this.toastr.info(
        this.translateService.instant("VALIDATION_ERRORS.PLEASE_FILL_FIELDS")
      );
    }
  };

  onSubmitHandler = async ({
    shouldExit = false,
    shouldCreateNew = false,
  }: {
    shouldExit?: boolean;
    shouldCreateNew?: boolean;
  }) => {
    this.toastr.clear();
    const serverValidation = await this.formConfig.validateAllFormFields();

    if (!serverValidation || serverValidation === "null") {
      this.updateTabErrorStatus();
      const serverConfirmed =
        await this.formConfig.checkForServerConfirmation();

      if (serverConfirmed) {
        if (this.formConfig.form.valid) {
          const initialDataSnapshot = this.initialData;
          this.formConfig
            .tryToSubmit(serverConfirmed)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: (data: any) => {
                if (data?.["refresh_form"]) {
                  if (this.formConfig.formMode === "EDIT") {
                    this.resetFormInEditMode(data);
                    return;
                  }
                }
                if (shouldExit) {
                  this.onExitEventHandler(null);
                }
                this.formConfig.checkForFieldsToBeRefreshed(data);
                if (shouldCreateNew) {
                  this.formConfig.form.reset(this.formConfig.formInitialValues);
                  this.formConfig.formMode = "CREATE";
                  //   this.updateTabErrorStatus();
                  this.resetSelectedTab$.next(true);
                  if (!this.formConfig.isSubForm) {
                    this.location.replaceState(
                      `${this.formConfig.listUrl}/create`
                    );
                  }
                  // resetting everything
                  this.reset(initialDataSnapshot);
                  this.getFormConfigAndData();
                  this.subscribeToFormStatusChange();
                  return;
                }
                // if save was successful and we were in the CREATE mode, we switch to edit mode
                if (data?.id && this.formConfig.formMode === "CREATE") {
                  // resetting everything
                  this.reset(initialDataSnapshot);
                  this.editModeInitialization(data.id);
                  this.subscribeToFormStatusChange();
                  if (!this.formConfig.isSubForm) {
                    this.location.replaceState(
                      `${this.formConfig.listUrl}/edit/${data.id}`
                    );
                  }
                  this.shellForm.markAsPristine();
                }
                //this.isFormDirty$.next(false);
                //this.toastr.success("successfully updated");
              },
              complete: () => {
                this.toastr.success(
                  this.translateService.instant("COMMON.PROCESS_SUCCESS")
                );
              },
              error: (err) => {
                if (err.status === 400 && err.error) {
                  this.formConfig.updateFormServerErrors(err.error);
                } else {
                  this.toastr.error(
                    this.translateService.instant("COMMON.UNEXPECTED_ERROR"),
                    null,
                    {
                      disableTimeOut: true,
                    }
                  );
                }
              },
            });
        } else {
          this.toastr.info(
            this.translateService.instant(
              "VALIDATION_ERRORS.PLEASE_FILL_FIELDS"
            )
          );
        }
      }
    } else {
      // if validator returns anything but `null` it means that there are some async validators that are not resolved yet
      try {
        this.asyncErrors$.next([]);
        Object.entries(serverValidation).map(([table, message]) => {
          this.asyncErrors$.next([
            ...this.asyncErrors$.value,
            { tab: table, message: message.toString() },
          ]);
          const currentErrors = this.formConfig.form.errors || {};
          this.toastr.error(message.toString(), table, {
            disableTimeOut: true,
          });
          this.formConfig.form.setErrors({
            ...currentErrors,
            [table]: true,
          });
        });
        this.updateTabErrorStatus();
      } catch (ex) {}
    }
  };

  onDeleteEventHandler = (_) => {
    this.formConfig.deleteItem();
  };

  onBreadcrumbClickEventHandler = (breadcrumb) => {
    if (this.formConfig.isSubForm) {
      this.onBreadCrumb.emit(breadcrumb);
    } else {
      this.formConfig.onBreadcrumbClick(breadcrumb);
      //   this.router.navigateByUrl(this.formConfig.listUrl);
    }
  };

  private resetFormInEditMode = (data) => {
    const id = data.id || data.data.id;
    this.reset(this.initialData);
    this.editModeInitialization(id);
    this.subscribeToFormStatusChange();
    this.shellForm.markAsPristine();
    console.log("refreshing early");
  };

  private reset = (initialDataSnapshot) => {
    this.allTabs$.next([]);
    this.shellForm = new UntypedFormGroup({});
    this.tabErrors$.next([]);
    this.formConfig.reset({ initialData: initialDataSnapshot });
  };

  private updateTabErrorStatus = () => {
    this.tabErrors$.next([]);
    Object.keys(this.shellForm.controls).forEach((key) => {
      const matchingTab = this.checkPortletTabsForErrors(key) || key;
      //   if (this.shellForm.controls[key].valid) {
      //     this.tabErrors$.next(
      //       this.tabErrors$.value.filter((e) => e !== matchingTab)
      //     );
      //   }
      if (this.shellForm.controls[key].invalid) {
        this.tabErrors$.next([...this.tabErrors$.value, matchingTab]);
      }
    });
    // this is a special case to validate tables (in booking form)
    const formTableErrors = Object.keys(this.shellForm?.errors || []);
    if (formTableErrors.length > 0) {
      //   alert("dependent: " + dependent);
      this.tabErrors$.next([...this.tabErrors$.value, ...formTableErrors]);
    }
  };

  private checkPortletTabsForErrors = (key) => {
    const res = this.formConfig.tabs
      ?.filter((t) => t.type === "portlet")
      .flatMap((t) => ({
        parent: t.name,
        content: t.content,
      }))
      .find((t) => t.content.some((c) => c.name === key));

    return res?.parent || undefined;
  };

  private parseHtmlSectionConfig = (configuration: {
    config: any;
    position: "header" | "sidebar";
  }) => {
    const { config, position } = configuration;
    if (config["api_url"] === "") {
      return {
        show: false,
      };
    }
    const width =
      config["width"] || (position === "sidebar" ? "300px" : "100%");
    return {
      show: this.formConfig.formMode === "EDIT" ? true : false,
      apiUrl: this.formConfig.getApiUrl(config["api_url"]),
      width: width,
      height: config["height"] || "100%",
      shouldDisplayRefreshButton:
        "display_refresh_button" in config
          ? config["display_refresh_button"]
          : true,
    };
  };

  onTabChangeHandler = ({ index, tab }: { index: number; tab: ITab }) => {
    if (tab) {
      if (tab.shouldRefresh) {
        this.formConfig.forceRefreshTab$.next(tab.name);
      }

      if (
        this.formConfig.isStickyTabUrl &&
        this.formConfig.formMode === "EDIT" &&
        !this.isSubForm
      ) {
        const tabName = tab.name.split(" ").join("_").toLowerCase();
        if (tabName) {
          this.location.replaceState(
            `${this.formConfig.listUrl}/edit/${this.formConfig.id}/${tabName}`
          );
        }
      }
      // console.log(this.location.path())
      // // this.location.replaceState(`${this.formConfig.listUrl}/create`);
    }
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
