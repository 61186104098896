import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppGlobalConfigState } from "../reducers/app.global.config.reducer";

const selectAppGlobalConfig =
  createFeatureSelector<AppGlobalConfigState>("appGlobalConfig");

const selectShouldTablesDisplayZero = createSelector(
  selectAppGlobalConfig,
  (state) => state.shouldTablesDisplayZero
);
export const appGlobalConfigSelectors = {
  selectShouldTablesDisplayZero,
};
