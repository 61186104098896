// Angular
import { Location } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
  ViewRef,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DateAdapter } from "@angular/material/core";

// Material
// RxJS
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  Subscription,
  throwError,
} from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import {
  CrudUtilsService,
  TypesUtilsService,
} from "../../../../core/base/utils";

import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, CrudTableStore } from "../../../../core/store";
import {
  CrudActionServerError,
  ItemDeleted,
} from "../../../../core/store/actions/crud-table.actions";
import {
  ApiService,
  CrudTableService,
  DataService,
  FormConfigService,
  I18nService,
} from "../../../../services";
//Layout
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from "../../../../core/base";
import { ApiConfig } from "../../../../core/config/api.config";

//ckeditor
import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { get, merge, reject, set } from "lodash";
import * as moment from "moment";
import { Subject } from "rxjs/internal/Subject";
import { tap } from "rxjs/operators";
import {
  ConfirmActionDialogComponent,
  ConfirmActionDialogData,
} from "../confirm-action-dialog/confirm-action-dialog.component";
import { CrudTableProcessButton } from "../crud-table/crud-table.component";
import { CustomDropzoneComponent } from "../custom-dropzone/custom-dropzone.component";
import { environment_api } from "./../../../../../environments/environment.api";
import { LocalDatePipe } from "./../../../../core/base/pipes/local-date.pipe";
import { TranslationService } from "./../../../../core/base/services/translation.service";
import { ItemCreated } from "./../../../../core/store/actions/crud-table.actions";
import { TableConfigService } from "./../../../../services/table.config.service";
import { UsersTableComponent } from "./../../../../views/pages/user-management/users/users-table/users-table.component";
import { CrudFormDialogComponent } from "./_sub/crud-form-dialog.component";

declare var $: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: "crud-form",
  templateUrl: "./crud-form.component.html",
  styleUrls: ["./crud-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [CrudTableStore],
})
export class CrudFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("wizard") formWizard: ElementRef;

  // Public properties
  form: UntypedFormGroup;
  @Input() formConfig: any;
  @Input() apiUrl: string;
  @Input() relatedUrl: string;
  @Input() listUrl: string;
  @Input() name: string;
  @Input() title: string;
  @Input() nameField: string; // if present, display in readonly form title i.e (User: John Doe)
  @Input() locale: string;
  @Input() isSubForm = false;
  @Input() initialData = null;
  @Input() onAfterSave: (data: any) => Observable<any>;

  @Output() onExit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReset: EventEmitter<any> = new EventEmitter<any>();

  @Input() readonly = false;
  @Input() hasMoreActions = false;

  private _customActionBtns: TemplateRef<any>;
  get customActionBtns(): TemplateRef<any> {
    return this._customActionBtns;
  }
  @Input()
  set customActionBtns(value: TemplateRef<any>) {
    this._customActionBtns = value;
  }

  selectedTranslationTab: number = 0;

  startnumberObject = new BehaviorSubject<[]>([]);

  selectedTab: number = 0; // selected tab index
  columns = [];
  data: any = {};
  config: any = null;
  staticText: any = null;
  dependencies: any = {};
  hasFormErrors = false;
  hasTriedToSubmitForm = false;
  viewLoading = false;
  langChangeSubs$: any;
  formSaving$ = new Subject<boolean>();
  pageLoading$ = new Subject<boolean>();
  hideUnsavedChangesWarning = false;

  private componentSubscriptions: Subscription[] = [];
  private subscriptions: Subscription[] = [];
  unsubscribe$: Subject<void> = new Subject<void>();

  staticTextLoading$ = new BehaviorSubject<boolean>(true);
  isSubFormShown$ = new BehaviorSubject<boolean>(false);

  isTestdata: any;
  currentUser: any = [];

  public Editor = ClassicEditor;
  tabName: string = "default";
  infoText: any[] = [];
  info: boolean = true;
  signature: string;

  // fileupload contents
  customDropzoneComponents: CustomDropzoneComponent[] = [];

  processButtons: CrudTableProcessButton[];

  formCustomErrors: {};

  relationId: any;

  public dialogRef = null;
  public dialogData;

  defaultLang: string;
  languages: any;

  /**
   * Component constructor
   *
   * @param data: any
   * @param fb: FormBuilder
   * @param store: Store<AppState>
   * @param typesUtilsService: TypesUtilsService
   */
  constructor(
    private fb: UntypedFormBuilder,
    private store: CrudTableStore,
    private crudUtilsService: CrudUtilsService,
    private router: Router,
    private crudTableService: CrudTableService,
    public tableConfigService: TableConfigService,
    private formConfigService: FormConfigService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private auth: AuthService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private translationService: TranslationService,
    private injector: Injector,
    private dataService: DataService,
    private http: HttpClient,
    private localDate: LocalDatePipe,
    private dateAdapter: DateAdapter<any>,
    private apiService: ApiService // private titleService: Title
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);

    this.languages = this.translationService.getAvailableLanguages();
    this.defaultLang = this.translate.getDefaultLang();

    // temp fix to [ERROR TypeError: Cannot read property 'translations' of null] on load
    //this.staticText = JSON.parse(localStorage.getItem('config.staticText'));
  }

  debug() {
    console.log("debug", this.isSubFormShown$);
  }

  ngOnChanges({ initialData }: SimpleChanges) {
    if (initialData) {
      this.initialData = initialData.currentValue || {};
      console.log("ngOnChanges.initialData", { ...this.data });
    }
  }

  ngOnInit() {
    const pageURL = window.location.href;
    this.relationId = pageURL.substr(pageURL.lastIndexOf("/") + 1);

    this.data = { ...this.initialData };
    console.log("ngOnInit.initialData", { ...this.data });

    this.store
      .select((state) => state.actionsloading)
      .subscribe((res) => (this.viewLoading = res));

    this.componentSubscriptions.push(
      this.store
        .select((state) => state.serverErrors)
        .pipe()
        .subscribe((error) => {
          this.formSaving$.next(false);

          console.log("error", error);

          if (!error) return;

          // 400 bad request inidicates invalid inputs
          if (error.statusCode == 400 || error.statusCode == 422) {
            this.hasFormErrors = true;
            // display field error messages
            if (
              error.message &&
              typeof error.message == "object" &&
              Object.keys(error.message).length > 0
            ) {
              this.formCustomErrors =
                get(error, "message.errors") || get(error, "message");
              Object.entries(this.formCustomErrors).forEach(
                ([fieldName, errorMsg]) => {
                  this.form.get(fieldName).setErrors({ custom: true });
                }
              );
            } else {
              this.layoutUtilsService.showActionNotification(error.message);
            }
            this.crefDetectChanges();
          } else {
            const message = `An Unexpected Error Occurred. Please try again.`;
            this.layoutUtilsService.showActionNotification(
              message,
              MessageType.Create,
              5000,
              true,
              true
            );
          }
        })
    );

    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      let id;
      if (this.data && this.data.id) id = this.data.id;
      else if (!this.isSubForm) {
        id = this.dialogData === null ? params.id : this.dialogData.id;
      }

      if (params && params.id) {
        if (params.id != id && !this.isSubForm) {
          id = params.id;
          this.selectedTab = 0;
        }
      }

      if (id) {
        this.data.id = id;
        this.crudTableService
          .getItemById(this.apiUrl, id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            const config = {
              ...res.config,
              type: "tabs",
              tabs: [],
              translations: {},
              tabAttributes: res.config.tab_attributes,
            };
            let data = res.data;

            // process buttons
            if (res.config.process_buttons) {
              this.processButtons = (res.config.process_buttons || []).map(
                (r) => {
                  return new CrudTableProcessButton(
                    this,
                    this.http,
                    this.dialog,
                    this.translate,
                    this.layoutUtilsService,
                    r,
                    this.crudUtilsService,
                    (s) => this.getConfigTranslate(s),
                    () => this.crefDetectChanges()
                  );
                }
              );
            } else {
              this.tableConfigService
                .getTableConfig(this.translate.currentLang, this.name)
                .subscribe(({ config }) => {
                  this.processButtons = (config.process_buttons || []).map(
                    (r) => {
                      return new CrudTableProcessButton(
                        this,
                        this.http,
                        this.dialog,
                        this.translate,
                        this.layoutUtilsService,
                        r,
                        this.crudUtilsService,
                        (s) => this.getConfigTranslate(s),
                        () => this.crefDetectChanges()
                      );
                    }
                  );
                });
            }

            if (res.config.form_wizard) {
              config.type = "form_wizard";
              let tabs = res.config.form_wizard;
              tabs.forEach((tab) => {
                config.tabs.push({
                  ...tab,
                  caption: tab.caption,
                  subCaption: tab.sub_caption,
                  fields: tab.fields || tab.table || [tab.html_content],
                  table: tab.table,
                });
              });
            } else if (res.config.tabs) {
              let tabs = res.config.tabs;

              Object.keys(tabs).forEach((key) => {
                const tab = tabs[key];

                let tabType = null;
                if (key == "images" || tab.type == "images") tabType = "images";
                else if (key == "documents" || tab.type == "documents")
                  tabType = "documents";

                if (tabType == "images" || tabType == "documents") {
                  config.tabs.push({
                    name: key,
                    type: tabType,
                    fields: [],
                    model: tab.model,
                    types: tab.types, // image types selection
                    caption: tab.caption,
                    maxUploadCount: tab.max_upload_count,
                    acceptedFileTypes: tab.accepted_file_types,
                    required: tab.required,
                  });
                } else if (
                  !Array.isArray(tab) &&
                  tab.hasOwnProperty("portlets")
                ) {
                  config.tabs.push({
                    name: key,
                    caption: get(tabs[key], "[0].caption") || key,
                    portlets: tab.portlets,
                  });
                } else {
                  config.tabs.push({
                    name: key,
                    caption: get(tabs[key], "[0].caption") || key,
                    fields: tabs[key],
                  });
                }
              });
            }

            if (config.type == "form_wizard") {
              config.tabs.forEach((tab) => {
                tab.fields.forEach((field) => {
                  set(data, field.name, get(res.data, field.name));
                });
              });
            } else {
              config.tabs.forEach((tab) => {
                if (!!tab["portlets"]?.length) {
                  console.log("here");
                } else {
                  tab.fields.forEach((field) => {
                    if (tab.name === "default") {
                      const getDate = moment(
                        res.data[field.name],
                        "YYYY-MM-DD H:m:s",
                        true
                      ).isValid();
                      if (getDate) {
                        data[field.name] = new Date(res.data[field.name]);
                      } else {
                        data[field.name] = get(res.data, field.name);
                      }
                    }
                    if (field.type == "crud-table") {
                      //console.log('crud-table', field);
                    } else if (field.type == "html_content") {
                      //console.log('crud-table', field);
                    } else {
                      if (res.data.translations) {
                        if (field.lang !== undefined) {
                          const translation = res.data.translations.find(
                            (trans) =>
                              (trans.lang || "").substr(0, 2) ===
                              (field.lang || "").substr(0, 2)
                          );

                          if (translation) {
                            data[field.name] =
                              translation[
                                field.name.replace("_" + field.lang, "")
                              ];
                          }
                        }
                      }
                      if (
                        tab.name === "group" ||
                        tab.name === "roles" ||
                        tab.name === "signature"
                      ) {
                        data[field.name] = res.data[field.name];
                      }
                    }
                  });
                }
              });
            }

            config.translations = res.config.translations;

            this.config = config;
            this.data = merge(this.data, data);
            this.dependencies = res.dependencies;

            // set translations tab
            this.loadTranslations();
            // if (this.data.translations) {
            // 	this.data.translations.forEach(trans => {
            // 		this.addTranslation(trans.lang);
            // 	});
            // }
            // console.log('translationTabs', this.translationTabs);

            // console.log('config.tabs', this.config);
            // console.log('this.data', this.data);

            //
            // initialize each fields
            // - tab name
            // - load dynamic options
            Object.keys(config.tabs).forEach((tabKey) => {
              const tab = config.tabs[tabKey];
              if (!!tab["portlets"]?.length) {
                tab["portlets"].forEach((portlet) => {
                  if (Array.isArray(portlet["content"])) {
                    Object.keys(portlet["content"]).forEach((fieldKey: any) => {
                      const field = portlet["content"][fieldKey];
                      field._tabName = tabKey;
                      if (field?.dynamic_options) {
                        this.loadFieldDynamicOptions(field);
                      }
                    });
                  }
                });
              } else {
                Object.keys(tab.fields).forEach((fieldKey) => {
                  const field = tab.fields[fieldKey];
                  field._tabName = tabKey;
                  if (field.dynamic_options) {
                    this.loadFieldDynamicOptions(field);
                  }
                });
              }
            });

            this.createForm();

            this.infoText = res.config.infotext;
            this.crefDetectChanges();

            //this.staticTextLoading$.next(false);
            this.initFormWizard();

            // selected tab
            //console.log('params', params, this.config.tabs);
            const selectedTabName = (params.tab || "").toLowerCase();
            if (selectedTabName) {
              const selectedTabIndex = this.config.tabs.findIndex(
                (tab) => tab.name.toLowerCase() == selectedTabName
              );
              //console.log('selectedTabIndex', selectedTabIndex);
              if (selectedTabIndex >= 0) {
                this.selectedTab = selectedTabIndex;
                const selectedTab = this.config.tabs[this.selectedTab];
                selectedTab.activated = true;
                this.crefDetectChanges();

                // PGMTODO: make this a global handler
                // book participant
                if (selectedTabName == "participants") {
                  const selectedConfigTab = this.config.tabs[selectedTabIndex];
                  this.crudTableOnCreate(selectedConfigTab.fields[0]);
                }
              }
            }
          });
        //this.loadTexts();
      } else {
        this.formConfigService
          .getFormConfig(this.translate.currentLang || "en", this.name)
          .subscribe((res) => {
            const config = {
              ...res.config,
              type: "tabs",
              tabs: [],
              translations: {},
              tabAttributes: [],
            };
            let data = {};

            if (res && res.config) {
              //
              config.tabAttributes = res.config.tab_attributes;

              // load parent data
              const parentData = res.config.parent_data;
              if (parentData) {
                this.crudTableService
                  .get(this.getApiUrl(parentData.api_url))
                  .subscribe((resp) => {
                    set(this.data, parentData.map_to_data, resp.data);

                    this.createForm();
                  });
              }

              if (res.config.form_wizard) {
                config.type = "form_wizard";
                let tabs = res.config.form_wizard;
                tabs.forEach((tab) => {
                  config.tabs.push({
                    ...tab,
                    caption: tab.caption,
                    subCaption: tab.sub_caption,
                    fields: tab.fields || tab.table || [tab.html_content],
                    table: tab.table,
                  });
                });
              } else if (res.config.tabs) {
                let tabs = res.config.tabs;

                Object.keys(tabs).forEach((key) => {
                  const tab = tabs[key];

                  let tabType = null;
                  if (key == "images" || tab.type == "images")
                    tabType = "images";
                  else if (key == "documents" || tab.type == "documents")
                    tabType = "documents";

                  if (tabType == "images" || tabType == "documents") {
                    config.tabs.push({
                      name: key,
                      type: tabType,
                      fields: [],
                      model: tab.model,
                      types: tab.types, // image types selection
                      caption: tab.caption,
                      disabled: !tab.allow_upload_on_create,
                      maxUploadCount: tab.max_upload_count,
                      acceptedFileTypes: tab.accepted_file_types,
                      required: tab.required,
                    });
                  } else {
                    config.tabs.push({
                      name: key,
                      caption: tabs[key][0].caption || key,
                      disabled: tabs[key][0].require_master_data,
                      fields: tabs[key],
                    });
                  }
                });
              }

              if (this.name === "trackday-items") {
                const routeSubscription = this.auth
                  .getUserByToken()
                  .subscribe((response) => {
                    config.tabs.forEach((tab) => {
                      tab.fields.forEach((field) => {
                        const name = field.name;
                        if (tab.name === "default") {
                          data["company_id"] = response["company_id"];
                        }
                      });
                    });
                    this.createForm();
                  });
              }

              const staticText = { texts: {}, translations: {} };
              staticText.texts = res.texts.texts;
              staticText.translations = res.texts.translations;
              this.staticText = staticText;
              config.translations = res.config.translations;
              this.config = config;
              this.data = { ...this.data, ...data };
              this.dependencies = res.dependencies;

              this.addTranslation(this.defaultLang);
              // console.log('translationTabs', this.translationTabs);
              // console.log('this.config', this.config);

              //
              // initialize each fields
              // - tab name
              // - load dynamic options
              Object.keys(config.tabs).forEach((tabKey) => {
                const tab = config.tabs[tabKey];
                Object.keys(tab.fields).forEach((fieldKey) => {
                  const field = tab.fields[fieldKey];
                  field._tabName = tabKey;
                  if (field.dynamic_options) {
                    const defaultValue = this.parsePlaceholderValue(
                      field.default_value
                    );
                    this.loadFieldDynamicOptions(field, defaultValue);
                    this.fieldOnChange(field, defaultValue);
                  }
                });
              });

              this.createForm();
              this.infoText = res.config.infotext;
              this.crefDetectChanges();

              this.staticTextLoading$.next(false);

              this.initFormWizard();
            }
          });
      }
      // this.loadTexts();
    });
    this.subscriptions.push(routeSubscription);

    //switch data
    //this.isTestdata = localStorage.getItem('is_testdata') === '0' ? 0 : 1;

    //change language
    this.langChangeSubs$ = this.translate.onLangChange.subscribe(
      (lang: LangChangeEvent) => {
        this.locale = lang.lang;
        this.loadTexts(this.locale);
        this.dateAdapter.setLocale(lang.lang);
      }
    );

    this.locale = this.translate.currentLang;
    this.loadTexts(this.translate.currentLang);
    this.dateAdapter.setLocale(this.translate.currentLang);
  }

  ngAfterViewInit() {
    this.initFormWizard();
  }

  // PGMTODO: create seperate component for dynamic options

  getDynamicOptionsSelectedLabel(field, value) {
    const option = (field.dynamic_options.data || []).find(
      (r) => r[field.dynamic_options.value_field] == value
    );
    if (option) return this.getDynamicOptionsLabel(field, option);
  }

  getDynamicOptionsLabel(field, option) {
    let label = field.dynamic_options.label_field;
    const placeholders = label.match(/{[^}]+}/g);

    if (!placeholders) return option[label];

    placeholders.forEach((placeholder) => {
      let fieldName = placeholder.substr(1, placeholder.length - 2);
      let fieldValue = null;

      // parse formmater
      const formatter = fieldName.split("|");
      fieldName = formatter[0];

      if (fieldName.indexOf("translations.") >= 0) {
        const langIdx = option.translations.findIndex(
          (r) => r.lang == this.locale
        );
        if (langIdx < 0) fieldName = fieldName.replace("translations.", "");
        else
          fieldName = fieldName.replace(
            "translations.",
            `translations[${langIdx}].`
          );
        //console.log('getDynamicOptionsLabel', fieldName);
      }

      fieldValue = get(option, fieldName);

      if (formatter.length > 1) {
        const formatType = formatter[1];
        fieldValue = this.parsePlaceHolderFormatter(fieldValue, formatType);
      }

      label = label.replaceAll(placeholder, fieldValue);
    });

    return label;
  }

  loadTexts(lang) {
    this.viewLoading = true;
    this.formConfigService
      .getTextConfig(lang, "text")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        const staticText = { texts: {}, translations: {} };
        staticText.texts = res.config.texts;
        staticText.translations = res.config.translations;
        this.staticText = staticText;
        this.defaultLang = res.config.default_language;
        this.viewLoading = false;
        this.crefDetectChanges();

        //
        this.staticTextLoading$.next(false);

        // temp fix to [ERROR TypeError: Cannot read property 'translations' of null] on load
        localStorage.setItem(
          "config.staticText",
          JSON.stringify(this.staticText)
        );
      });
  }

  crefDetectChanges() {
    if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this.dataService.cleardata();

    if (this.componentSubscriptions && this.componentSubscriptions.length) {
      this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
    }

    this.subscriptions.forEach((sb) => sb.unsubscribe());

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.langChangeSubs$.unsubscribe();
  }

  getConfigTranslate(key) {
    if (!key) return key;
    let text = get(
      this.config,
      "translations." + (this.locale || "en") + "." + key
    );
    if (text == null) text = this.translate.instant(key);
    return text || key;
  }

  createFormControl(field, fieldValue): any {
    const parentTab = this.config.tabs.find((t) => {
      if (!!t["portlets"]?.length) {
        t["portlets"].forEach((portlet) => {
          if (Array.isArray(portlet["content"])) {
            return portlet["content"]?.find((f) => f.name == field.name);
          }
        });
        return null;
      } else {
        return t.fields.find((f) => f.name == field.name);
      }
    });
    field._tab = parentTab;

    // get default value
    if (fieldValue == null) {
      let defaultValue = field.default_value;
      if (defaultValue != null) {
        if (field.dynamic_options) {
          defaultValue = this.parseDefaultOption(field, defaultValue);
          //console.log('createFormControl', field, defaultValue);
        } else {
          defaultValue = this.parsePlaceholderValue(defaultValue);
        }

        //if (defaultValue == "{_parent} ? {_parent.currency_id} : 'name:Euro'")
        //console.log('createFormControl', field.name, fieldValue, defaultValue);

        fieldValue = defaultValue;
      }
    }

    // get initial state

    if (field.name == "location_guid") {
      console.log("createFormControl", field, fieldValue);
    }

    // add attributes for 'same_as_tab' config
    if (!field.same_as_tab) {
      if (parentTab) {
        const sameAsTabField = parentTab.fields.find((f) => f.same_as_tab);
        if (sameAsTabField) {
          field._same_as_tab_field = sameAsTabField;
          //console.log('field._same_as_tab_field', field.name, field._same_as_tab_field);
        }
      }
    }

    let isDisabled = this.getFieldDisabledState(field);

    //if (fieldValue == null) fieldValue = '';
    let fieldValidator = this.getFieldValidator(field);
    //field.validator = fieldValidator;

    // if (field.name == 'invoice_address.gender') {
    // 	console.log('createFormControl.getFieldDisabledState', isDisabled, field);
    // }

    if (fieldValue === undefined) fieldValue = null;

    if (field.type === "number") {
      return [
        { value: fieldValue, disabled: isDisabled },
        fieldValidator && [
          Validators[fieldValidator],
          Validators.min(this.getFieldMinValue(field)),
          Validators.max(this.getFieldMaxValue(field)),
        ],
      ];
    } else if (field.type === "decimal") {
      if (!isNaN(fieldValue))
        fieldValue = parseFloat(fieldValue).toFixed(field.decimals || 2);
      return [
        { value: fieldValue, disabled: isDisabled },
        fieldValidator && [Validators[fieldValidator]],
      ];
    } else {
      if (field.type == "checkbox") fieldValue = !!fieldValue;

      return [
        { value: fieldValue, disabled: isDisabled },
        fieldValidator && Validators[fieldValidator],
      ];
    }
  }

  createForm() {
    const formData = {};
    this.config.tabs.forEach((tab) => {
      if (tab.name == "translations_lang") {
        (this.translationTabs || []).forEach((transTab) => {
          transTab.fields.forEach((field) => {
            const fieldName = field.name;
            let fieldValue = null;
            if (this.data && this.data.translations) {
              const dataTrans = this.data.translations.find(
                (r) => r.lang == field.lang
              );
              if (dataTrans) fieldValue = dataTrans[field.configName];
            }
            formData[fieldName] = this.createFormControl(field, fieldValue);
          });
        });
      } else {
        if (!!tab["portlets"]?.length) {
          tab["portlets"].forEach((portlet) => {
            if (Array.isArray(portlet["content"])) {
              portlet["content"]?.forEach((field) => {
                let fieldValue = get(this.data, field.name);
                formData[field.name] = this.createFormControl(
                  field,
                  fieldValue
                );
              });
            }
          });
        } else {
          tab.fields.forEach((field) => {
            let fieldValue = get(this.data, field.name);
            if (field.name == "location_guid")
              console.log("createForm", { ...this.data }, fieldValue);
            formData[field.name] = this.createFormControl(field, fieldValue);
          });
        }
      }
    });

    this.form = this.fb.group(formData);

    this.config.tabs.forEach((tab) => {
      if (!!tab["portlets"]?.length) {
        tab["portlets"].forEach((portlet) => {
          if (Array.isArray(portlet["content"])) {
            portlet["content"]?.forEach((field) => {
              this.setFieldDisabledState(field);
              const formField = this.formGet(field.name);
              if (formField) {
                formField.valueChanges.subscribe((value) => {
                  set(this.data, field.name, value);
                });
              }
            });
          }
        });
      } else {
        tab.fields.forEach((field) => {
          this.setFieldDisabledState(field);
          const formField = this.formGet(field.name);
          if (formField) {
            formField.valueChanges.subscribe((value) => {
              set(this.data, field.name, value);
            });
          }
        });
      }
    });

    // Object.keys(this.form.controls).forEach(key => {

    // 	this.form.controls[key].valueChanges.subscribe(value => {
    // 		set(this.data, key, value);
    // 	});
    // });
  }

  /**
   * Returns prepared customer
   */
  prepareFormData() {
    const controls = this.form.controls;
    const { ...formData } = this.initialData || {};
    formData._parent = undefined;
    formData.id = this.data.id;
    //formData['is_testdata'] = Number(localStorage.getItem('is_testdata'));
    formData["tabName"] = this.tabName;

    this.config.tabs.forEach((tab) => {
      if (tab.name == "translations_lang") {
        this.translationTabs.forEach((transTab) => {
          transTab.fields.forEach((field) => {
            let path = `translations_lang.${field.lang}.${field.configName}`;
            let value = controls[field.name].value;
            set(formData, path, value);
          });
        });
      } else {
        tab.fields.forEach((field) => {
          if (!this.getFieldVisibilityState(field)) return;

          let value;
          if (field.name === "startnumber") {
            value = this.startnumberObject.value;
          } else if (field.type == "datetime" || field.type == "date") {
            value = controls[field.name].value;
            if (moment(value).isValid()) {
              value = moment(value).format();
            }
          } else {
            value = this.getFieldValue(field.name);
          }

          const fieldName = field.payload_name || field.name;

          set(formData, fieldName, value);

          if (
            this.name === "service" ||
            this.name === "participants-trackday-items" ||
            this.name === "cancellation-prices-trackday-items" ||
            this.name === "dynamic-prices-trackday-items" ||
            (this.name === "coupons" &&
              this.router.url !== "/admin-management/coupons/create") ||
            (this.name === "coupon-assigned-items" &&
              this.router.url !== "/admin-management/assigned-coupons")
          ) {
            formData["data"] = localStorage.getItem("data");
          }
        });
      }
    });

    return formData;
  }

  // Get form control
  formGet(fieldName) {
    return this.form.controls[fieldName];
  }

  formRemove(fieldName) {
    return this.form.controls[fieldName];
  }

  formGetCustomError(fieldName) {
    return get(this.formCustomErrors, fieldName);
  }

  getTitle() {
    const configTitle = get(this.config, "title");

    let tabTitle = "";
    if (
      this.data.id > 0 &&
      this.config &&
      this.config.translations &&
      this.config.translations[this.locale || "en"]
    ) {
      tabTitle = this.config.translations[this.locale || "en"]["TAB_TITLE"];
      if (tabTitle) {
        tabTitle = this.parsePlaceholderValue(tabTitle);
        // this.titleService.setTitle(tabTitle);
      }
    }
    //  else {
    // 	this.titleService.setTitle('Test');
    // }

    if (this.readonly) {
      let title = this.parsePlaceholderValue(configTitle);
      if (this.nameField) {
        const nameTitle = get(this.data, this.nameField);
        if (title) title = title + ": " + nameTitle;
        else title = "...";
      }
      return title;
    }

    if (
      this.data.id > 0 &&
      this.config &&
      this.config.translations &&
      this.config.translations[this.locale || "en"]
    ) {
      let formTitle =
        this.config.translations[this.locale || "en"]["FORM_TITLE"];
      if (formTitle) {
        let formatTitle = this.parsePlaceholderValue(formTitle);
        return formatTitle;
      }
    } else {
      let formTitle = get(
        this.config,
        "translations." + (this.locale || "en") + ".FORM_TITLE_ADD"
      );
      if (formTitle) {
        let formatTitle = this.parsePlaceholderValue(formTitle);
        return formatTitle;
      }
    }

    let title = configTitle || this.title || this.name;
    if (this.staticTextLoading$.getValue()) {
      return this.translate.instant("COMMON.LOADING");
    } else {
      if (this.staticText) {
        title = this.data.id
          ? this.staticText.translations[this.locale || "en"][
              this.staticText.texts ? this.staticText.texts.edit : "edit"
            ] +
            " " +
            title
          : this.staticText.translations[this.locale || "en"][
              this.staticText.texts ? this.staticText.texts.new : "new"
            ] +
            " " +
            title;
      }
    }

    return title;
  }

  goToLink(field) {
    window.open(this.formGet(field.name).value, "_blank");
  }

  shortenedBooking(goBack: boolean = true, callback?: (data: any) => boolean) {
    const formData = this.prepareFormData();

    if (!this.data.id) {
      this.crudTableService.createItem(this.apiUrl, formData).subscribe(
        (response) => {
          this.data = { ...this.data, ...response };
          console.log("this.crudTableService.createItem", this.data);
          this.onSubmit(goBack, callback, true);
        },
        (res: any) => {
          this.formSaving$.next(false);
          this.catchServerError(res);
        }
      );
      //this.createItem(editedFormData, false);
    }
  }

  /**
   * On Submit
   */
  onSubmit(
    goBack: boolean = true,
    callback?: (data: any) => boolean,
    isShortend = false
  ) {
    if (this.wizardStep$.value === 4 && !isShortend) {
      this.shortenedBooking(goBack, callback);
      return;
    }
    this.hasTriedToSubmitForm = true;
    this.hasFormErrors = false;
    const controls = this.form.controls;

    /** check form */

    Object.keys(controls).forEach((controlName) => {
      const ctrl = controls[controlName];
      const field = this.getFieldByName(controlName);

      // check visible
      if (field) {
        const visible = this.getFieldVisibilityState(field);
        const disabled = this.getFieldDisabledState(field);
        if (disabled || visible == false) {
          ctrl.markAsPristine();
          ctrl.setErrors(null);
          return;
        }
      }

      ctrl.markAsTouched();

      if (ctrl.invalid) this.hasFormErrors = true;
    });

    if (this.hasFormErrors) return;
    const hasInvalidTab =
      this.config.tabs.find((tab) => !this.getTabValid(tab)) != null;
    if (hasInvalidTab) return;

    const editedFormData = this.prepareFormData();
    editedFormData._submit = true;

    if (editedFormData.id) {
      this.updateItem(editedFormData, goBack, callback);
    } else {
      if (
        this.customDropzoneComponents &&
        this.customDropzoneComponents.length > 0 &&
        this.customDropzoneComponents.some((r) => r.hasPendingItems())
      ) {
        const uploadCallback = (data: any): boolean => {
          this.formSaving$.next(true);

          const joins = {};
          this.customDropzoneComponents.forEach((comp) => {
            comp.modelId = data.id;
            const uploadJoins = comp.uploadFiles();
            if (uploadJoins) joins[this.name] = uploadJoins;
          });

          forkJoin(joins).subscribe((result) => {
            if (this.onAfterSave) {
              this.onAfterSave(data).subscribe(() => {
                this.formSaving$.next(false);
                this.afterSubmit(goBack, data.id);
              });
              return;
            }

            this.formSaving$.next(false);
            if (callback && callback(data) === false) {
              return;
            }
            this.afterSubmit(goBack, data.id);
          });

          return false;
        };
        this.createItem(editedFormData, goBack, uploadCallback);
      } else {
        this.createItem(editedFormData, goBack, callback);
      }
    }
  }

  goBack() {
    this.router.navigateByUrl(this.listUrl);
  }

  /**
   * Update customer
   *
   * @param _customer: CustomerModel
   */
  updateItem(formData, goBack, callback?: (data: any) => boolean) {
    this.formSaving$.next(true);

    //this.store.dispatch(this.store.showActionLoadingDistpatcher);

    this.crudTableService
      .updateItem(this.apiUrl, formData)
      .pipe(
        tap((res) => {
          //this.store.dispatch(new ItemOnServerUpdated({ item: res, url: this.apiUrl }));
        }),
        catchError((res: any) => {
          this.catchServerError(res);

          // this.store.dispatch(this.store.hideActionLoadingDistpatcher);

          // console.log('catchError', res);

          // if ((res.status == 400 || res.status === 422) && res.error) {
          // 	this.store.dispatch(
          // 		new CrudActionServerError({
          // 			errors: {
          // 				statusCode: res.status,
          // 				message: res.error,
          // 			},
          // 			_ts: Date.now()
          // 		})
          // 	);
          // } else if (res.status == 500) {
          // 	this.store.dispatch(
          // 		new CrudActionServerError({
          // 			errors: `An Unexpected Error Occurred. Please try again.`,
          // 			_ts: Date.now()
          // 		})
          // 	);
          // }

          return throwError(res);
        })
      )
      .subscribe(
        (res) => {
          this.formSaving$.next(false);

          const id = res.id;
          if (!id) return;

          if (callback && callback(res) === false) return;

          this.afterSubmit(goBack, id);
        },
        (err) => {
          this.formSaving$.next(false);
        },
        () => {}
      );

    return;
  }

  /**
   * Create customer
   *
   * @param _customer: CustomerModel
   */
  createItem(formData, goBack, callback?: (data: any) => boolean) {
    this.formSaving$.next(true);

    this.store.dispatch(this.store.showActionLoadingDistpatcher);

    this.crudTableService
      .createItem(this.apiUrl, formData)
      .pipe(
        tap((res) => {
          this.store.dispatch(new ItemCreated({ item: res, url: this.apiUrl }));
        }),
        catchError((res: any) => {
          this.store.dispatch(this.store.hideActionLoadingDistpatcher);

          if ((res.status == 400 || res.status == 422) && res.error) {
            this.store.dispatch(
              new CrudActionServerError({
                errors: {
                  statusCode: res.status,
                  message: res.error,
                },
              })
            );
          } else if (res.status == 500) {
            this.store.dispatch(
              new CrudActionServerError({
                errors: `An Unexpected Error Occurred. Please try again.`,
              })
            );
          }

          return throwError(res);
        })
      )
      .subscribe(
        (res) => {
          this.formSaving$.next(false);

          const id = res.id;
          if (!id) return;

          if (callback && callback(res) === false) return;

          this.afterSubmit(goBack, id);
        },
        (err) => {
          this.formSaving$.next(false);
        },
        () => {}
      );

    return;
  }

  afterSubmit(goBack, id) {
    const message = `${this.title || this.name} has been successfully saved.`;
    this.layoutUtilsService.showActionNotification(
      message,
      MessageType.Update,
      5000,
      true,
      true
    );

    // modal form
    if (this.isSubForm) {
      this.onExit.emit();
    } else if (this.dialogData !== null) {
      this.dialogRef.close({
        isEdit: false,
      });
    } else {
      // save and exit
      goBack && this.goBack();

      // save and continue
      !goBack && this.goEdit(id);
    }
  }

  reset() {
    let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        title: this.translate.instant("TRANSLATOR.TITLE_RESET_FORM"),
        message: this.translate.instant("TRANSLATOR.CONFIRM_RESET_FORM"),
      },
      width: "800px",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) this.reloadFormComponent();
    });
  }

  reloadFormComponent() {
    if (this.isSubForm) {
      this.onReset.emit();
    } else {
      let currentUrl = this.router.url;
      this.router
        .navigateByUrl(this.listUrl, { skipLocationChange: true })
        .then(() => this.router.navigate([currentUrl]));
    }
  }

  /** Alect Close event */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  /** info Alert Close event */
  onInfoAlertClose($event) {
    //this.info = false;
  }

  /**
   * number validation
   */
  onKeyPress(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // format decimal input
  formatDecimal($event, decimal) {
    $event.target.value = parseFloat($event.target.value).toFixed(decimal);
  }

  parsePlaceHolderFormatter(fieldValue, formatType) {
    // parse formmater
    if (formatType == "date") {
      fieldValue = this.localDate.transform(fieldValue, "date");
    } else if (formatType == "datetime") {
      fieldValue = this.localDate.transform(fieldValue, "datetime");
    } else if (formatType == "translate") {
      fieldValue = this.translate.instant(fieldValue);
    }

    return fieldValue;
  }

  // get parsed placeholders
  // strict: if true, return null if variable undefiend or null
  parsePlaceholderValue(placeholderValue, strict = false) {
    return this.crudUtilsService.parsePlaceholders(
      placeholderValue,
      this.data,
      this.form,
      strict
    );
  }

  parseDefaultOption(field, defaultValue) {
    const valueKey = field.dynamic_options.value_field || "value";

    if (defaultValue != null) {
      if (typeof defaultValue != "string") return defaultValue;

      const parts = defaultValue.split(":");
      if (parts.length >= 2) {
        if (field.dynamic_options) {
          //console.log('parseDefaultOption', defaultValue, parts, field.dynamic_options.data);
          if (
            field.dynamic_options.data &&
            field.dynamic_options.data.length > 0
          ) {
            const defaultOption = field.dynamic_options.data.find(
              (r) => r[parts[0]] == parts[1]
            );
            //console.log('parseDefaultOption', field.name, field.default_value, field.dynamic_options.data, defaultOption);
            if (defaultOption) defaultValue = defaultOption[valueKey];
            // else {
            // 	const defaultOptionItem = field.dynamic_options.data.find(r => r.is_default);
            // 	if (defaultOptionItem) defaultValue = defaultOptionItem[valueKey];
            // }
          }
        } else {
          const defaultOption = this.dependencies[field.name].find(
            (r) => r[parts[0]] == parts[1]
          );
          if (defaultOption) defaultValue = defaultOption.value;
        }
      } else {
        defaultValue = this.parsePlaceholderValue(defaultValue);
      }

      return defaultValue;
    } else {
      if (field.type === "multiple_select") {
        return field.dynamic_options.data
          .filter((r) => r.is_default)
          .map((r) => r[valueKey]);
      } else {
        const option = field.dynamic_options.data.find((r) => r.is_default);
        return option ? option[valueKey] : null;
      }
    }
  }

  /**
   * get trackday
   */
  getTrackday(value, name) {
    const api = ApiConfig.TrackdaysURL();
    if (name === "trackday_id") {
      this.crudTableService.getItemById(api, value).subscribe((res) => {
        this.data = { ...res.data, ...this.data };
        const routeSubscription = this.activatedRoute.params.subscribe(
          (params) => {
            this.data["id"] = params.id ? params.id : "";
          }
        );
        this.data["trackday_id"] = value;
        this.createForm();
        this.crefDetectChanges();
      });
    }
    if (name === "users_id") {
      const api = ApiConfig.UserConfigUrl();
      this.crudTableService.getItemById(api, value).subscribe((res) => {
        this.data["description"] = res.data === null ? "" : res.data.signature;
        this.data["users_id"] = value;
        this.createForm();
        this.crefDetectChanges();
      });
    }
    if (this.name === "participants-trackday-items" && name === "user_id") {
      const api = ApiConfig.UsersURL();
      this.crudTableService.getItemById(api, value).subscribe((res) => {
        this.data["user_id"] = res.id;
        this.data["user.email"] = res.email;
        this.data["user.firstname"] = res.firstname;
        this.data["user.lastname"] = res.lastname;
        this.data["user.address.street"] = res.address.street;
        this.data["user.address.number"] = res.address.number;
        this.data["user.address.zip"] = res.address.zip;
        this.data["user.address.city"] = res.address.city;
        this.data["user.address.phone"] = res.address.phone;
        this.data["user.address.fax"] = res.address.fax;
        this.data["user.address.mobile"] = res.address.mobile;
        this.data["user.address.country_id"] = res.address.country_id;

        this.config.tabs.forEach((tab) => {
          tab.fields.forEach((field) => {
            // if (!field.default_value) return;
            // if (this.data[field.default_value] === undefined) return;
            //const value = this.getData(res, field.name) || this.data[field.default_vaue];
            //this.form.controls[field.name].setValue(value);
            //this.data[field.name] = this.data[field.default_value];
          });
        });
        this.createForm();
        this.crefDetectChanges();
      });
    } else if (this.name === "trackday-items" && name === "variant_id") {
      const _title = ` Change Location Variant`;
      const _description = `
				<p>Do you really want to change variant?</p>
				<ul>
					<li>All participant's laptime will be zeroed out.</li>
				</ul>`;
      const _button = "Update";
      this.layoutUtilsService.changeElement(
        _title,
        _description,
        _description,
        _button
      );
    }
  }

  getData(item, field) {
    return get(item, field);
  }

  getTabCaption(tab) {
    let text = this.parsePlaceholderValue(tab.caption);
    return text ? this.getConfigTranslate(text) : tab.name;
  }

  getTabName(tabName) {
    this.tabName = tabName;
    //this.crefDetectChanges();
  }

  getTabValid(tab) {
    if (tab.name == "translations_lang") {
      for (let transTab of this.translationTabs) {
        for (let field of transTab.fields) {
          const ctrl = get(this.form.controls, field.name);
          if (ctrl && ctrl.touched && ctrl.invalid) return false;
        }
      }
    }
    if (["images", "documents"].indexOf(tab.type) >= 0) {
      if (this.hasTriedToSubmitForm) {
        if (tab.required) {
          const fileComp = this.customDropzoneComponents.find(
            (r) => r.name == tab.name
          );
          if (!fileComp) {
            if (!this.data.id) return false;
          } else {
            if (!fileComp.hasItemsToDisplay()) return false;
          }
        }
      }
    } else {
      const fields = tab.fields;
      for (let field of fields) {
        const ctrl = get(this.form.controls, field.name);
        if (ctrl && ctrl.touched && ctrl.invalid) return false;

        // check required tab
        if (field.type == "crud-table") {
          field.error = null;

          if (field.data) {
            const required = this.parsePlaceholderValue(field.required);
            if (required) {
              if (!field.data || !field.data.length) {
                field.error = this.translate.instant(
                  `VALIDATION.LIST_REQUIRED`,
                  { name: field.title }
                );
                return false;
              }
            }
          }
        }
      }
    }
    return true;
  }

  getTabFields(tab, visible = true) {
    return tab.fields.filter((field) => (field.type == "hidden") != visible);
  }

  getFieldValidator(field) {
    return this.parsePlaceholderValue(field.validator);
  }

  onReady(eventData) {
    eventData.plugins.get("FileRepository").createUploadAdapter = function (
      loader
    ) {
      return new UploadAdapter(loader);
    };
  }

  backClicked() {
    if (this.isSubForm) {
      this.onExit.emit();
    } else if (this.dialogData !== null) {
      this.dialogRef.close({
        isEdit: false,
      });
    } else {
      //this.go;
      this.goBack();
    }
  }

  compareSingleSelectOptions(o1: any, o2: any): boolean {
    return o1 + "" === o2 + "";
  }

  // redirect to edit page
  goEdit(id) {
    let url = this.router.url;
    if (url.indexOf("/create") > 0) {
      url = url.replace("/create", "/edit") + "/" + id;
      this.router.navigateByUrl(url);
    } else {
      this.reloadFormComponent();
    }
  }

  getParams(field) {
    if (field.name == "general services") {
      return {
        company_id: this.data.company_id,
      };
    } else if (field.name == "location services") {
      return {
        location_id: this.data.location_id,
      };
    }
  }

  getFieldLabelPlaceholder(field, loading) {
    if (loading) {
      return this.translate.instant("COMMON.LOADING");
    } else {
      return this.getFieldLabel(field);
    }
  }

  loadFieldDynamicOptions(field, defaultValue = undefined) {
    // let currentValue;
    // if (defaultValue !== undefined) {
    // 	const fieldValue = this.parsePlaceholderValue(defaultValue);
    // 	this.setFieldValue(field.name, fieldValue);
    // }
    // else currentValue = this.form ? this.form.controls[field.name].value : get(this.data, field.name);

    if (field.dynamic_options && field.dynamic_options.url) {
      // clear options / show loading
      // if (this.form) this.form.controls[field.name].setValue(null);
      field.dynamic_options.data = [];

      // construct url
      const lang = this.translate.currentLang;
      let path = this.parsePlaceholderValue(field.dynamic_options.url);

      if (path == null) return;
      if (path.indexOf("//") >= 0) return;

      // let url = environment_api.api_url
      // 	+ path
      // 	+ `?&lang=${lang}`
      // 	+ `&is_testdata=${this.isTestdata}`;

      //   const urlComp = new URL('http://localhost:8000/api/v1' + path);
      const urlComp = new URL(environment_api.api_url + path);
      urlComp.searchParams.append("lang", lang);
      //urlComp.searchParams.append('is_testdata', this.isTestdata);
      let url = urlComp.toString();

      if (field.dynamic_options.parent_field) {
        const parentFieldName = field.dynamic_options.parent_field;
        const parentFieldAlias =
          field.dynamic_options.parent_field_alias || parentFieldName;
        let parentValue = this.getFieldValue(parentFieldName);
        if (!parentValue) return;
        url += `&filter[${parentFieldAlias}]=${parentValue}`;
      }

      if (!this.data.id) {
        if (defaultValue !== undefined) {
          let fieldValue = this.parseDefaultOption(field, defaultValue);
          this.setFieldValue(field.name, fieldValue);
        }
      }

      field.dynamic_options.loading = true;
      this.crudTableService.findItems(url, null).subscribe((response: any) => {
        field.dynamic_options.loading = false;
        field.dynamic_options.data = Array.isArray(response)
          ? response
          : response.data;

        if (!this.data.id) {
          //if (defaultValue !== undefined) {
          let fieldValue = this.parseDefaultOption(field, defaultValue);
          this.setFieldValue(field.name, fieldValue);
          //}
        }

        this.crefDetectChanges();
      });
    }
  }

  getTabAttribute(tabName, attr) {
    const attrVal = get(this.config, `tabAttributes.${tabName}.${attr}`);

    return this.parsePlaceholderValue(attrVal);
  }

  getInfoText(tabName) {
    const infoText =
      this.config.translations[this.locale || "en"][
        this.infoText[tabName].text
      ];
    return this.parsePlaceholderValue(infoText);
  }

  getInfoTextVisibilityState(tabName) {
    //console.log('getInfoTextVisibilityState', tabName, this.infoText);
    if (!this.infoText) return false;
    if (!this.infoText[tabName]) return false;

    if (this.infoText[tabName].visible_if) {
      const visibleIf = this.infoText[tabName].visible_if;
      return this.parsePlaceholderValue(visibleIf);
    }
    return true;
  }

  getInfoTextHtml(infoText) {
    return this.parsePlaceholderValue(this.getConfigTranslate(infoText.text));
  }

  getInfoTextVisible(infoText) {
    if (infoText) {
      const visibleIf = infoText.visible_if;
      if (visibleIf) return this.parsePlaceholderValue(visibleIf);
      return true;
    }
    return false;
  }

  getFieldVisibilityState(field) {
    if (field._tabName && this.getTabAttribute(field._tabName, "hidden"))
      return false;
    if (field.visible_if && !this.parsePlaceholderValue(field.visible_if))
      return false;
    return true;
  }

  getFieldDisabledState(field) {
    let disabled = false;
    if (field.disabled) {
      return this.parsePlaceholderValue(field.disabled);
    }

    // under 'same_as_tab' config
    if (field._same_as_tab_field) {
      // if (field.name == 'invoice_address.gender') {
      // 	console.log('getFieldDisabledState', get(this.data, field._same_as_tab_field.name), field);
      // }
      return !!get(this.data, field._same_as_tab_field.name);
      //return this.formGet(field._same_as_tab_field.name).value;
    }

    return false;
  }

  getFieldMinValue(field, nullValue = null) {
    const val = this.parsePlaceholderValue(field.min);
    return val == null ? nullValue : val;
  }

  getFieldMaxValue(field, nullValue = null) {
    const val = this.parsePlaceholderValue(field.max);
    return val == null ? nullValue : val;
  }

  removeFieldArrayValue(field, value) {
    const values = this.formGet(field.name).value || [];
    const newValues = reject(values, (r) => r == value);
    this.formGet(field.name).setValue(newValues);
    //this.crefDetectChanges();
    //remove((this.formGet(field.name).value || []), (r) => r==value);
  }

  setFieldDisabledState(field, disabled = null) {
    if (!this.form) return;

    if (disabled == null) disabled = this.getFieldDisabledState(field);

    // if (field.name == 'invoice_address.gender') {
    // 	console.log('setFieldDisabledState', field.name, disabled, field);
    // }

    let formField = this.formGet(field.name);
    if (formField) {
      disabled ? formField.disable() : formField.enable();
    }
  }

  setFieldValue(fieldName: string, value: any) {
    set(this.data, fieldName, value);
    const formField = this.form ? this.form.controls[fieldName] : null;
    if (formField) formField.setValue(value);

    //console.log('setFieldValue', fieldName, value, formField);
  }

  getFieldValue(fieldName) {
    let value;
    const formField = this.form ? this.formGet(fieldName) : null;

    if (formField) value = formField.value;
    if (value == null) value = get(this.data, fieldName);

    return value;

    //console.log('setFieldValue', fieldName, value, formField);
  }

  getFieldWidthClass(field) {
    if (typeof field.width == "string") {
      return field.width;
    }
    if (typeof field.width == "number") {
      return `col-md-${field.width}`;
    } else {
      return "col-md-12";
    }
  }

  // sync this.form values to current this.data
  reloadFormData() {
    this.config.tabs.forEach((tab) => {
      tab.fields.forEach((field) => {
        if (!field.name) return;
        const value = get(this.data, field.name);
        this.setFieldValue(field.name, value);
        this.setFieldDisabledState(field);
      });
    });
  }

  reloadMappedData(mapKey) {
    this.config.tabs.forEach((tab) => {
      tab.fields.forEach((field) => {
        if (!field.name) return;
        if (field.name.indexOf(mapKey + ".") == 0) {
          const mappedValue = get(this.data, field.name);
          //console.log('reloadMappedData', field.name, mappedValue);
          this.setFieldValue(field.name, mappedValue);
          this.setFieldDisabledState(field);
        } else {
          if (field.default_value) {
            const currentValue = get(this.data, field.name);

            if (!currentValue) {
              if (
                typeof field.default_value == "string" &&
                field.default_value.indexOf("{" + mapKey + ".") >= 0
              ) {
                const defaultValue = this.parsePlaceholderValue(
                  field.default_value
                );
                this.setFieldValue(field.name, defaultValue);
                this.setFieldDisabledState(field);
              }
            }
          }
        }
      });
    });
  }

  checkPlaceholderExists(evalStr: string, placeholder: string) {
    if (!placeholder || !evalStr) return false;

    let exists = false;
    if (placeholder.indexOf(".") < 0) {
      exists =
        typeof evalStr == "string" && evalStr.indexOf(`{${placeholder}}`) >= 0;
    } else {
      exists =
        typeof evalStr == "string" &&
        (evalStr.indexOf(`{${placeholder}`) >= 0 ||
          evalStr.indexOf(`${placeholder}`) == 0);
    }
    return exists;
  }

  sameAsConfig(field, isSame) {
    const name = field.name;

    // "same_as" config
    const tabName = field.same_as_tab;
    if (tabName) {
      const parentTab = this.config.tabs.find((tab) =>
        tab.fields.find((field) => field.name == name)
      );

      parentTab.fields.forEach((field) => {
        if (field.name == name) return;

        if (isSame) {
          // get full path name of the field to be copied
          let copyFieldName = field.name;
          if (field.name.indexOf(".") > 0) {
            copyFieldName = copyFieldName.substring(
              copyFieldName.indexOf(".") + 1
            );
          }
          copyFieldName = tabName + "." + copyFieldName;

          const copyFieldValue = get(this.data, copyFieldName);
          this.setFieldValue(field.name, copyFieldValue);
        }

        this.setFieldDisabledState(field, isSame);
      });
    }
  }

  fieldOnChange(thisField, inputValue) {
    const value = inputValue;
    const name = thisField.name;
    let mapKey = null;

    //console.log('fieldOnChange', name, value);

    // map selected to data
    //console.log('field.dynamic_options', field.dynamic_options);
    if (thisField.dynamic_options) {
      if (thisField.dynamic_options.map_to_data) {
        mapKey = thisField.dynamic_options.map_to_data;

        const valueKey = thisField.dynamic_options.value_field || "value";
        const optionData = thisField.dynamic_options.data.find(
          (r) => r[valueKey] == value.value
        );
        this.data[mapKey] = optionData;

        //console.log('map_to_data', mapKey, optionData);

        //this.reloadMappedData(mapKey);
      }

      // map only on create
      if (!this.data.id) {
        if (thisField.dynamic_options.map_to_translations) {
          //console.log('map_to_translations', thisField);

          const mapKey = thisField.dynamic_options.map_to_translations;
          const valueKey = thisField.dynamic_options.value_field || "value";
          const optionData = thisField.dynamic_options.data.find(
            (r) => r[valueKey] == value.value
          );

          if (optionData) {
            const translationData = optionData[mapKey];

            //console.log('map_to_translations', mapKey, translationData);
            if (translationData) {
              const confTransTab = this.config.tabs.find(
                (tab) => tab.name == "translations_lang"
              );
              this.data.translations = translationData.map((source) => {
                const data = {
                  lang: source.lang,
                };
                confTransTab.fields.forEach((dest) => {
                  data[dest.name] = source[dest.name];
                });
                return data;
              });
              //console.log('translationData', this.data.translations);
              this.loadTranslations(true);
            }
          }
        }
      }
    }

    // map selected to data
    //console.log('field.dynamic_options', field.dynamic_options);
    if (thisField.lookup) {
      if (thisField.lookup.map_to_data) {
        mapKey = thisField.lookup.map_to_data;
      }

      const valueKey = thisField.lookup.value_field || "id";
      const labelKey = thisField.lookup.label_field || valueKey;

      this.setFieldValue(thisField.name, inputValue[valueKey]);
      //this.setFieldValue(thisField.name + '-label', label);

      //this.data[thisField.name] = inputValue[valueKey];

      if (mapKey) this.data[mapKey] = inputValue;

      thisField.label = this.parsePlaceholderValue(labelKey);

      console.log(
        "thisField.lookup.map_to_data",
        mapKey,
        thisField.label,
        this.data[mapKey]
      );
    }

    //
    Object.keys(this.config.tabs).forEach((tabKey) => {
      const tab = this.config.tabs[tabKey];
      Object.keys(tab.fields).forEach((fieldKey) => {
        const field = tab.fields[fieldKey];

        if (field.name == name) return;

        const toggleDisabled =
          this.checkPlaceholderExists(field.disabled, name) ||
          this.checkPlaceholderExists(field.disabled, mapKey + ".");
        const toggleVisible =
          this.checkPlaceholderExists(field.visible_if, name) ||
          this.checkPlaceholderExists(field.visible_if, mapKey + ".");

        if (toggleDisabled || toggleVisible) this.setFieldDisabledState(field);

        if (field.dynamic_options && field.dynamic_options.url) {
          const toggleDynamicOptions =
            this.checkPlaceholderExists(field.dynamic_options.url, name) ||
            this.checkPlaceholderExists(
              field.dynamic_options.url,
              mapKey + "."
            );
          if (
            toggleDynamicOptions ||
            field.dynamic_options.parent_field == name
          ) {
            console.log("fieldOnChange2", name, field.name);

            this.setFieldValue(field.name, null);
            this.loadFieldDynamicOptions(field);
            //this.fieldOnChange(field, '');
          }
        }

        // set mapped value

        const toggleMappedVaue = this.checkPlaceholderExists(
          field.name,
          mapKey + "."
        );
        // if (field.name == 'user.email') {
        // 	console.log('toggleMappedVaue', toggleMappedVaue, get(this.data, field.name));
        // }
        if (toggleMappedVaue) {
          const mappedValue = get(this.data, field.name);
          this.setFieldValue(field.name, mappedValue);
        }

        const toggleDefaultValue =
          this.checkPlaceholderExists(field.default_value, name) ||
          this.checkPlaceholderExists(field.default_value, mapKey + ".");
        if (toggleDefaultValue) {
          // set default value
          (() => {
            const formField = this.form ? this.form.controls[field.name] : null;

            const defaultValueUpdate = field.default_value_update
              ? this.parsePlaceholderValue(field.default_value_update)
              : false;

            //console.log('toggleDefaultValue', field.name, defaultValueUpdate, formField);

            if (!defaultValueUpdate && formField && formField.dirty) return;

            const fieldValue = this.parsePlaceholderValue(field.default_value);

            //console.log('field.default_value_update', value, fieldValue, field.default_value, field.default_value_update, defaultValueUpdate, get(this.data, 'service.price_type'));

            this.setFieldValue(field.name, fieldValue);

            this.crefDetectChanges();
          })();
        }

        // "same_as" config
        if (field.same_as_tab) {
          const fieldValue = get(this.data, field.name);
          this.sameAsConfig(field, fieldValue);
        }

        // if (typeof (field.default_value) == 'string' && field.default_value.indexOf(`{${name}}`) >= 0 && !this.data.id) {

        // 	const formField = (this.form) ? this.form.controls[field.name] : null;

        // 	if (formField && formField.dirty && !field.default_value_update)

        // 	const value = this.parsePlaceholderValue(field.default_value);
        // 	set(this.data, field.name, value);
        // 	if (formField) formField.setValue(value);
        // }
      });
    });

    // map_data_fields
    if (thisField.map_data_fields) {
      if (thisField.type == "checkbox") {
        if (inputValue) {
          Object.keys(thisField.map_data_fields).forEach((fieldName) => {
            const mapFromFieldName = thisField.map_data_fields[fieldName];
            const mappedValue = get(this.data, mapFromFieldName);
            this.form.controls[fieldName].setValue(mappedValue);
          });
        }
      }
    }

    this.crefDetectChanges();
  }

  selectedTabChange(event) {
    let tab = this.config.tabs[event.index];
    tab.activated = true;

    for (let i = 0; i < tab.fields.length; i++) {
      if (tab.fields[i].type == "html_content") {
        tab.fields[i].api_url = this.crudTableService.getVersionedUrl(
          tab.fields[i].api_url
        );
        this.crefDetectChanges();

        // tab.fields[i].loading = true;
        // this.crefDetectChanges();
        // this.crudTableService.getHtml(this.getApiUrl(tab.fields[i].api_url)).subscribe(response => {
        // 	tab.fields[i].innerHtml = response;
        // 	tab.fields[i].loading = false;
        // 	this.crefDetectChanges();
        // }, (err) => {
        // 	tab.fields[i].loading = false;
        // 	tab.fields[i].innerHtml = `An unexpected error occured while loading content.`;
        // 	this.crefDetectChanges();
        // });
      }
    }
  }

  //
  // Translations Tab
  //

  translationTabs: any[] = [];

  loadTranslations(addControl = false) {
    // set translations tab
    if (this.data.translations && this.data.translations.length > 0) {
      this.translationTabs = [];
      this.data.translations.forEach((trans) => {
        this.addTranslation(trans.lang, addControl);
      });
    } else {
      this.addTranslation(this.defaultLang, true);
    }
  }

  deleteTranslation(lang) {
    let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        title: this.translate.instant("TRANSLATOR.DELETE_TRANSLATION"),
        message: this.translate.instant("COMMON.ARE_YOU_SURE"),
      },
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const idx = this.translationTabs.findIndex((r) => r.lang == lang);
        const transTab = this.translationTabs[idx];

        // remove form fields
        transTab.fields.forEach((field) => {
          this.form.removeControl(field.name);
        });

        if (idx >= 0) this.translationTabs.splice(idx, 1);
        this.selectedTranslationTab = this.translationTabs.length - 1;
        this.form.markAsDirty();
        this.crefDetectChanges();
      }
    });
  }

  addTranslation(lang, addControl = false) {
    const confTransTab = this.config.tabs.find(
      (tab) => tab.name == "translations_lang"
    );
    if (!confTransTab) return;

    const transTab = {
      caption: confTransTab.caption,
      name: confTransTab.name + "_" + lang,
      lang: lang,
      fields: confTransTab.fields.map((field) => {
        return {
          ...field,
          configName: field.name,
          name: field.name + "_" + lang,
          lang: lang,
        };
      }),
    };
    if (this.defaultLang == lang) {
      this.translationTabs.unshift(transTab);
      const tabIndex =
        this.translationTabs.findIndex((t) => t.lang == transTab.lang) - 1;
      this.selectedTranslationTab = tabIndex;
    } else {
      this.translationTabs.push(transTab);
      const tabIndex = this.translationTabs.findIndex(
        (t) => t.lang == transTab.lang
      );
      this.selectedTranslationTab = tabIndex;
    }

    if (addControl && this.form) {
      transTab.fields.forEach((field) => {
        const fieldName = field.name;
        let fieldValue = null;
        if (this.data && this.data.translations) {
          const dataTrans = this.data.translations.find(
            (r) => r.lang == field.lang
          );
          if (dataTrans) fieldValue = dataTrans[field.configName];
        }
        const fbControl = this.createFormControl(field, fieldValue);
        //console.log('addTranslation', field.name, field, fbControl);
        //this.form.removeControl(field.name);
        this.form.addControl(
          field.name,
          this.fb.control(fbControl[0], fbControl[1])
        );
      });
      this.form.markAsDirty();
    }
  }

  getTranslationLanguage(langCode) {
    const langs = this.translationService.getAvailableLanguages();
    return langs.find((r) => r.lang == langCode);
  }

  isTranslationAdded(lang) {
    const currentList = this.translationTabs || [];
    return currentList.find((r) => r.lang == lang);
  }

  getFieldLabel(field) {
    return this.getConfigTranslate(field.title);
  }

  getFieldByName(name, tab = null) {
    if (tab == null) {
      for (let configTab of this.config.tabs) {
        const field = configTab.fields.find((r) => r.name == name);
        if (field) return field;
      }
      return null;
    } else {
      return tab.fields.find((r) => r.name == name);
    }
  }

  getLookupDisplayValue(field) {
    if (field.lookup) {
      const labelKey = field.lookup.label_field;
      field.label = this.parsePlaceholderValue(labelKey);
      return field.label || "";
    } else {
      throw new Error("Invalid lookup field");
    }
  }

  getFieldTitleByName(tab, name) {
    const field = this.getFieldByName(name, tab);
    return (
      this.config.translations[this.locale || "en"][field.title] ||
      this.config.translations["en"][field.title]
    );
  }

  checkReadonly(field) {
    if (this.readonly) return true;
    if (field.readonly && this.parsePlaceholderValue(field.readonly))
      return true;
    if (field.readonly_create && (!this.data || !this.data.id)) return true;
    if (field.readonly_edit && this.data && this.data.id) return true;
    return this.getFieldDisabledState(field);
  }

  checkValidRange(event, tab, name, before, title) {
    const message = {};
    const validate = [
      "validate_ge",
      "validate_le",
      "validate_eq",
      "validate_lt",
      "validate_gt",
    ];

    validate.forEach((element) => {
      if (before[element] !== undefined) {
        let validate = "";
        let checkCondition = false;
        let msg = "";
        let relatedFieldName = before[element];

        let value1, value2;

        const ctrlValue = this.form.controls[name].value;
        const ctrlBeforeValue = this.form.controls[before[element]].value;
        if (ctrlValue == null || ctrlBeforeValue == null) return;

        console.log(
          "checkValidRange",
          before[element],
          ctrlBeforeValue,
          this.form.controls[before[element]]
        );
        console.log("before type ", before.type);

        if (before.type == "date") {
          value1 = new Date(ctrlValue);
          value2 = new Date(ctrlBeforeValue);
        } else if (before.type == "datetime") {
          value1 = new Date(ctrlValue).getTime();
          value2 = new Date(ctrlBeforeValue).getTime();
        } else if (before.type == "decimal" || before.type == "number") {
          value1 = parseFloat(ctrlValue);
          value2 = parseFloat(ctrlBeforeValue);
        }

        if (value1 === "" || isNaN(value1) || value2 === "" || isNaN(value2)) {
          // skip validation
        } else {
          if (element == "validate_ge") {
            if (value1 < value2) {
              checkCondition = !checkCondition;
              msg = " must be greater or equal to ";
            }
          } else if (element == "validate_le") {
            if (value1 >= value2) {
              checkCondition = !checkCondition;
              msg = " must be less or equal to ";
            }
          } else if (element == "validate_eq") {
            if (value1 !== value2) {
              checkCondition = !checkCondition;
              msg = " must be equal to ";
            }
          } else if (element == "validate_lt") {
            if (value1 >= value2) {
              checkCondition = !checkCondition;
              msg = " must be less than ";
            }
          } else if (element == "validate_gt") {
            if (value1 <= value2) {
              checkCondition = !checkCondition;
              msg = " must be greater than ";
            }
          }
        }
        console.log(value1, element, value2);
        console.log(msg);

        if (checkCondition) {
          this.hasFormErrors = true;
          message[name] =
            title + msg + this.getFieldTitleByName(tab, relatedFieldName);
          this.formCustomErrors = message;
          this.form.get(name).setErrors({ custom: null });
          //this.form.get(before[element]).setErrors({ custom: true });
        } else {
          this.hasFormErrors = !this.form.valid;
          this.form.get(name).setErrors({ custom: null });
          this.form.get(before[element]).setErrors({ custom: null });
          this.form.get(name).updateValueAndValidity({ onlySelf: true });
          this.form
            .get(before[element])
            .updateValueAndValidity({ onlySelf: true });
        }
      }
    });
  }

  ckeditorGetData(field, event: ChangeEvent) {
    if (!event.editor) return;
    const data = event.editor.getData();
    const fieldName = field.name;
    if (data.length > 50000) {
      console.log("data.length", fieldName, data.length);
      if (this.formCustomErrors == null) this.formCustomErrors = {};
      set(this.formCustomErrors, fieldName, "CKEDITOR_MAX_CONTENT_LIMIT");
      this.form.get(fieldName).setErrors({ custom: true });
    } else {
      if (this.formCustomErrors == null) this.formCustomErrors = {};
      set(this.formCustomErrors, fieldName, null);
      this.form.get(fieldName).setErrors({ custom: null });
      this.formGet(fieldName).setValue(event.editor.getData());
    }
    this.crefDetectChanges();
  }

  hasFormShown() {
    if (this.config && this.config.tabs) {
      const tab = this.config.tabs.find((tab) =>
        tab.fields.find((field) => field.showForm)
      );
      return !!tab;
    }
    return false;
  }

  canDelete() {
    return (
      this.data.id > 0 &&
      !this.readonly &&
      !this.data.is_system &&
      (!this.formConfig ||
        this.formConfig.can_delete == null ||
        this.formConfig.can_delete === true ||
        this.crudUtilsService.parsePlaceholders(
          this.formConfig.can_delete,
          this.data
        ))
    );
  }

  /** ACTIONS */
  /**
   * Delete
   *
   */
  deleteItem() {
    this.crudUtilsService.deleteItem(
      this.name,
      this.name,
      this.data,
      this.apiUrl,
      () => {
        this.store.dispatch(
          new ItemDeleted({ id: this.data.id, url: this.apiUrl })
        );

        // modal form
        if (this.isSubForm) {
          this.onExit.emit();
        } else if (this.dialogData !== null) {
          this.dialogRef.close({
            isEdit: false,
          });
        } else {
          this.goBack();
        }
      }
    );
  }

  /* Form Wizard  */

  formWizardApi: any;
  showFormWizardStepSummaryPanel$ = new BehaviorSubject<boolean>(true);
  wizardStepSummaryApiUrl$ = new BehaviorSubject<string>(null);
  wizardStep$ = new BehaviorSubject<number>(1);

  updateWizardStepSummaryApiUrl(wizardObj) {
    // const step = this.formWizardApi.
    const step = wizardObj.currentStep;
    const currentTab = this.config.tabs[wizardObj.currentStep - 1];

    if (currentTab.show_step_summary !== false) {
      const url =
        ApiConfig.TrackdayBookingsUrl() +
        `/wizard-step-summary?id=${this.data.id || 0}&step=${step}`;
      console.log("updateWizardStepSummaryApiUrl", currentTab, step, url);
      this.wizardStepSummaryApiUrl$.next(url);
    } else {
      this.wizardStepSummaryApiUrl$.next(null);
    }
  }

  updateWizardStep(value) {
    this.wizardStep$.next(value);
  }

  getCriteriaToShowSubmitButton() {
    try {
      const currentStep = this.formWizardApi.currentStep;
      const isOwnVahicleMandatory = this.parsePlaceholderValue(
        this.initialData._parent.is_own_vehicle_mandatory
      );
      const isPrivateServiceMandatory = this.parsePlaceholderValue(
        this.initialData._parent.is_private_services_mandatory
      );

      const isServiceRequired = isPrivateServiceMandatory === 1 ? true : false;

      const isVehicleRequired = isOwnVahicleMandatory === 1 ? true : false;

      const startNumberValidator = this.config.tabs[9].fields[0]["validator"];
      const isStartNumberRequired =
        this.parsePlaceholderValue(startNumberValidator) === null
          ? false
          : true;

      const lapTimeValidator = this.config.tabs[10].fields[0]["validator"];
      const isLapTimeRequired =
        this.parsePlaceholderValue(lapTimeValidator) === null ? false : true;
      if (isLapTimeRequired) {
        return currentStep > 11 ? true : false;
      }
      if (isStartNumberRequired) {
        return currentStep > 10 ? true : false;
      }

      if (isServiceRequired && currentStep < 9) {
        //return currentStep > 7 ? true : false
        return false;
      }

      if (isVehicleRequired && currentStep < 6) {
        //return currentStep > 4 ? true : false
        return false;
      }

      return currentStep > 4 ? true : false;
    } catch (error) {
      return false;
    }
  }

  initFormWizard() {
    if (!this.config) return;
    if (this.config.type != "form_wizard") return;

    if (this.formWizardApi) return;

    this.crefDetectChanges();

    if (!this.formWizard) {
      var _self = this;
      setTimeout(function () {
        _self.initFormWizard();
      }, 500);
      return;
    }

    // Initialize form wizard
    this.formWizardApi = new KTWizard(this.formWizard.nativeElement, {
      startStep: 1,
      clickableSteps: false,
    });

    const _this = this;

    this.formWizardApi.on("change", (wizardObj) => {
      this.updateWizardStep(wizardObj.currentStep - 1);
      console.log("this.formWizardApi", this.formWizardApi);
      this.hasTriedToSubmitForm = false;
      this.pageLoading$.next(false);
      this.updateWizardStepSummaryApiUrl(wizardObj);

      // scroll to wizard tabs
      setTimeout(() => {
        this.crefDetectChanges();
        $("html, body").animate(
          { scrollTop: $("#kt_wizard_v3").offset().top - 80 },
          400
        );
      });

      const currentTab = this.config.tabs[wizardObj.currentStep - 1];

      console.log("currentTab", currentTab);

      if (currentTab.html_content) {
        const idx = currentTab.fields.findIndex(
          (r) => r.type == "html_content"
        );
        currentTab.fields[idx].api_url = this.crudTableService.getVersionedUrl(
          currentTab.fields[idx].api_url
        );
        this.crefDetectChanges();
      }

      if (this.name == "participants-trackday-items") {
        // reload wizard-step-summary panel

        if (currentTab.name == "start_number") {
          currentTab.fields.forEach((field) => {
            if (
              field.dynamic_options &&
              (field.dynamic_options.data || []).length == 0
            ) {
              this.loadFieldDynamicOptions(field, this.data.start_number);
              this.setFieldValue(
                "start_number",
                get(this.data, "start_number")
              );
            }
          });
        } else if (currentTab.name == "summary") {
          // summary
          // const field = currentStep.fields[0];
          // currentStep.fields[0].loading = true;
          // this.crefDetectChanges();
          // this.crudTableService.getHtml(this.getApiUrl(field.api_url)).subscribe(response => {
          // 	currentStep.fields[0].innerHtml = response;
          // 	currentStep.fields[0].loading = false;
          // 	this.crefDetectChanges();
          // });
        }
      }
    });

    // Validation before going to next page
    this.formWizardApi.on("beforeNext", (wizardObj) => {
      this.hasTriedToSubmitForm = true;
      // scroll to wizard tabs
      setTimeout(() => {
        this.crefDetectChanges();
        $("html, body").animate(
          { scrollTop: $("#kt_wizard_v3").offset().top - 80 },
          400
        );
      });

      // https://angular.io/guide/forms
      // https://angular.io/guide/form-validation

      // validate the form and use below function to stop the wizard's step

      let tabIsValid = true;
      let firstInvalidFieldName = null;
      _this.config.tabs[wizardObj.currentStep - 1].fields.forEach((field) => {
        if (field.type == "crud-table") {
          field.error = null;
          const required = this.parsePlaceholderValue(field.required);
          //console.log('field.required', field.required, required, this.data);
          if (required) {
            if (!field.data || !field.data.length) {
              field.error = this.translate.instant(`VALIDATION.LIST_REQUIRED`, {
                name: field.title,
              });
              tabIsValid = false;
            }
          }
        } else {
          const control = _this.form.controls[field.name];
          control.markAsTouched();
          control.updateValueAndValidity();
          if (control.invalid) {
            //console.log('field.name', field.name);
            tabIsValid = false;
            if (!firstInvalidFieldName) firstInvalidFieldName = field.name;
          }
        }
      });

      //console.log('tabIsValid', tabIsValid);

      if (!tabIsValid) {
        wizardObj.stop();
        // const offsetTop = $(`[ng-reflect-name='${firstInvalidFieldName}']`, _this.formWizard.nativeElement).offset().top - 200;
        // $('html, body').animate({
        // 	scrollTop: offsetTop
        // }, 400);
      } else {
        const currentStep = this.config.tabs[wizardObj.currentStep - 1];
        this.updateWizardStep(wizardObj.currentStep);
        if (this.name == "participants-trackday-items") {
          //console.log('wizard.beforeNext', wizardObj, currentStep);
          if (currentStep.name == "user_info") {
            wizardObj.stop();
            this.formSaving$.next(true);

            const draftBookingsApiUrl = ApiConfig.DraftBookingsUrl();
            const queryParams = new QueryParamsModel({
              user_id: this.data.user_id,
              trackday_items_id: this.data.trackday_items_id,
            });

            this.crudTableService
              .findItems(draftBookingsApiUrl, queryParams)
              .subscribe(
                (response: any) => {
                  this.formSaving$.next(false);

                  if (response && response.total > 0) {
                    const draftBooking = response.data[0];
                    const createdAt = this.localDate.transform(
                      draftBooking.created_at,
                      "date"
                    );

                    if (draftBooking.id == this.data.id) {
                      wizardObj.goNext();
                      wizardObj.start();
                      return;
                    }

                    let dialogRef = this.dialog.open(
                      ConfirmActionDialogComponent,
                      {
                        data: {
                          title: "Continue draft booking?",
                          message: `This user has draft booking created at ${createdAt}. Continue this draft booking?`,
                        },
                        width: "500px",
                      }
                    );

                    dialogRef.afterClosed().subscribe((dialogResult) => {
                      if (dialogResult) {
                        this.data = { ...this.data, ...draftBooking };
                        this.reloadFormData();
                        wizardObj.goNext();
                        wizardObj.start();
                      } else {
                        this.formSaving$.next(true);
                        this.crudTableService
                          .deleteItem(draftBookingsApiUrl, draftBooking.id)
                          .subscribe((response: any) => {
                            this.formSaving$.next(false);
                            wizardObj.goNext();
                            wizardObj.start();
                          });
                      }
                    });
                  } else {
                    wizardObj.goNext();
                    wizardObj.start();
                  }
                },
                (res: any) => {
                  this.formSaving$.next(false);
                  this.catchServerError(res);
                }
              );
          } else if (currentStep.name == "price_and_status") {
            wizardObj.stop();
            this.formSaving$.next(true);
            const formData = this.prepareFormData();

            if (!this.data.id) {
              this.crudTableService.createItem(this.apiUrl, formData).subscribe(
                (response) => {
                  this.data = { ...this.data, ...response };

                  console.log("this.crudTableService.createItem", this.data);

                  this.formSaving$.next(false);
                  wizardObj.goNext();
                  wizardObj.start();

                  this.updateWizardStepSummaryApiUrl(wizardObj);
                },
                (res: any) => {
                  this.formSaving$.next(false);
                  this.catchServerError(res);
                }
              );
              //this.createItem(editedFormData, false);
            } else {
              this.crudTableService
                .updateItem(this.apiUrl, { ...formData, info_only: true })
                .subscribe(
                  (response) => {
                    this.data = { ...this.data, ...response };

                    this.formSaving$.next(false);
                    wizardObj.goNext();
                    wizardObj.start();

                    this.updateWizardStepSummaryApiUrl(wizardObj);
                  },
                  (res: any) => {
                    this.formSaving$.next(false);
                    this.catchServerError(res);
                  }
                );
            }
          } else if (currentStep.name == "qualifications") {
            wizardObj.stop();
            this.formSaving$.next(true);
            const formData = this.prepareFormData();

            this.crudTableService.updateItem(this.apiUrl, formData).subscribe(
              (res) => {
                this.formSaving$.next(false);
                wizardObj.goNext();
                wizardObj.start();
              },
              (res: any) => {
                this.formSaving$.next(false);
                this.catchServerError(res);
              }
            );
          } else if (currentStep.name == "start_number") {
            wizardObj.stop();
            this.formSaving$.next(true);

            const apiUrl = ApiConfig.ParticipantsTrackdayItemsStartnumberURL();
            this.crudTableService
              .updateItem(apiUrl, {
                id: this.data.id,
                start_number: this.data.start_number,
              })
              .subscribe(
                (res) => {
                  this.formSaving$.next(false);
                  wizardObj.goNext();
                  wizardObj.start();
                },
                (res: any) => {
                  this.formSaving$.next(false);
                  this.catchServerError(res);
                }
              );
          } else if (currentStep.name == "laptime") {
            wizardObj.stop();
            this.formSaving$.next(true);
            const formData = this.prepareFormData();

            this.crudTableService.updateItem(this.apiUrl, formData).subscribe(
              (res) => {
                this.formSaving$.next(false);
                wizardObj.goNext();
                wizardObj.start();
              },
              (res: any) => {
                this.formSaving$.next(false);
                this.catchServerError(res);
              }
            );
          } else {
            //wizardObj.start();
          }
        }
      }
    });
  }

  catchServerError(res: any) {
    this.store.dispatch(this.store.hideActionLoadingDistpatcher);

    if ((res.status == 400 || res.status == 422) && res.error) {
      this.store.dispatch(
        new CrudActionServerError({
          errors: {
            statusCode: res.status,
            message: res.error,
          },
        })
      );
    } else if (res.status == 500) {
      this.store.dispatch(
        new CrudActionServerError({
          errors: `An Unexpected Error Occurred. Please try again.`,
        })
      );
    }
  }

  canLoadCrudTable(field) {
    if (!field.api_url) return false;
    if (field.api_url.indexOf("{id}") >= 0 && !this.data.id) return false;
    return true;
  }

  // TODO: move to seperate component class
  getApiUrl(apiUrl: string) {
    const url = this.parsePlaceholderValue(apiUrl, true);
    if (url == null) return null;
    return environment_api.api_url + url;
  }

  crudTableOnExit(field) {
    field.showForm = false;
    this.isSubFormShown$.next(field.showForm);
    this.crefDetectChanges();
  }

  crudTableOnCreate(field, item?: any) {
    const data = this.crudTableParseFormData(field.form.initial_data);

    // field.formData = { ...data, ...item };
    // field.formData._parent = this.data;

    field.error = null;

    field.form.parsedInitialData = { ...data, ...item };
    field.form.parsedInitialData._parent = this.data;
    field.form.parsedInitialData._init = data;

    field.showForm = !field.form.modal;
    this.isSubFormShown$.next(field.showForm);
    this.crefDetectChanges();
  }

  crudTableOnEdit(field, item: any) {
    const data = this.crudTableParseFormData(field.form.initial_data);

    field.form.parsedInitialData = { ...data, ...item };
    field.form.parsedInitialData._parent = this.data;
    field.form.parsedInitialData._init = data;

    // field.formData = { ...data, ...item };
    // field.formData._parent = this.data;
    // console.log('field.formData', field.formData);

    field.showForm = !field.form.modal;
    this.isSubFormShown$.next(field.showForm);
    this.crefDetectChanges();
  }

  crudTableParseFormData(data) {
    const initialData = data;
    const formData = {};
    // map form data placehoders
    if (initialData) {
      const _this = this;
      Object.keys(initialData).map(function (key, index) {
        set(formData, key, _this.parsePlaceholderValue(get(initialData, key)));
      });
    }

    return formData || {};
  }

  loadUserAddressData(tab) {
    const tabName = tab.name;
    const addressId = get(this.data, "user.address_id");
    const invoiceAddressId =
      get(this.data, "user.invoice_address_id") || addressId;

    let dialogRef = this.dialog.open(CrudFormDialogComponent, {
      data: {
        title: "Load User Data",
        name: "user-address",
        apiUrl: ApiConfig.UserAddressURL(),
        id: tabName == "invoice address" ? invoiceAddressId : addressId,
        readonly: true,
        okBtn: true,
        cancelBtn: true,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        tab.fields.forEach((field) => {
          const value = get(data, field.name.split(".").pop());
          set(this.data, field.name, value);
          this.form.controls[field.name].setValue(value);
        });
      }
    });
  }

  // checkValidDate(event, name, before, title) {
  // 	this.checkValidRange(event, tab, name, before, title);
  // }

  getTimeLoaded() {
    return new Date();
  }

  // PGMTODO:: moved to seperate component
  showCheckInBtn = new BehaviorSubject<boolean>(false);
  bookingCheckin() {
    const dialogData: ConfirmActionDialogData = {
      title: this.translate.instant("Check-in User"),
      message: {
        DEFAULT: this.translate.instant("COMMON.ARE_YOU_SURE"),
        SUCCESS: this.translate.instant("Check-in successful"),
        ERROR: this.translate.instant("COMMON.UNEXPECTED_ERROR"),
      },
      callbackYes: () => {
        const url = ApiConfig.TrackdayBookingsUrl();
        return this.apiService.post(url + `/${this.data.id}/checkin`);
      },
    };

    let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      data: dialogData,
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      //console.log('dialogResult', dialogResult);
      if (dialogResult !== false) {
        this.reloadFormComponent();
      }
    });
  }

  /**
   *
   */
  lookupUsers(field) {
    console.log("lookupUsers", field);

    if (this.checkReadonly(field)) return;

    const apiUrl = field.lookup.url;
    let dialogRef = this.dialog.open(UsersTableComponent, {
      data: {
        crudTable: {
          apiUrl: apiUrl,
          readonly: true,
          selection: true,
          allowMultiSelect: false,
          alwaysShowCheckboxes: true,
        },
      },
      width: "80%",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        console.log("dialogResult", dialogResult);
        this.fieldOnChange(field, dialogResult.selected[0]);
      }
    });
  }
}

export class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = (e) => {
            resolve({ default: myReader.result });
          };

          myReader.readAsDataURL(file);
        })
    );
  }
}
