import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SubheaderService } from "../../../../../core/base";
import { ApiConfig } from "../../../../../core/config/api.config";

// this component seems be used only as a user lookup
// if any component is intended to be used with addReplaceModal, it should contain a selectedItems$ property
// later, we should find a way to make this component more generic or type safe
@Component({
  selector: "users-table",
  templateUrl: "./users-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableComponent implements OnInit {
  columns = [];
  searchableColumns = ["name", "user_identifier", "color", "price_gross"];
  apiUrl: string;
  createButtonLabel = "Create User";

  selectedItems$ = new BehaviorSubject([]);

  constructor(private subheaderService: SubheaderService) {}

  selectionChange(event) {
    this.selectedItems$.next(event);
  }

  ngOnInit() {
    this.apiUrl = ApiConfig.UsersURL();

    // Set title to page breadCrumbs
    this.subheaderService.setTitle("User Management");
  }
}
