// Angular
import { Injectable, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { BehaviorSubject, Subject } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Lodash
import { get, merge } from 'lodash';
import { AuthService } from '../../../core/store/';
import { GenericInquiryParams, GenericInquiryComponent } from '../../../../app/views/partials/content/generic-inquiry/generic-inquiry.component';
import { ApiConfig } from '../../config/api.config';
import { DatePipe, formatDate } from '@angular/common';
import { MenusConfigService } from '../../../services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable()
export class DashboardConfigService {
	// Public properties
	onAddRow$: BehaviorSubject<DashboardConfigRow>;
	onAddPortlet$: BehaviorSubject<DashboardConfigPortlet>;

	editMode$: BehaviorSubject<boolean>;
	saveChanges$: Subject<any>;
	cancelChanges$: Subject<any>;

	saving$ = new BehaviorSubject(false);

	locale: string;
	translations$: BehaviorSubject<any[]>;
	portletMenus$: BehaviorSubject<DashboardConfigPortletType[]>;

	/**
	 * Service Constructor
	 *
	 * @param router: Router
	 */
	constructor(private translate: TranslateService) {
		// register on config changed event and set default config
		this.onAddRow$ = new BehaviorSubject<DashboardConfigRow>(null);
		this.onAddPortlet$ = new BehaviorSubject<DashboardConfigPortlet>(null);
		this.editMode$ = new BehaviorSubject<boolean>(false);
		this.translations$ = new BehaviorSubject<any[]>([]);
		this.portletMenus$ = new BehaviorSubject<DashboardConfigPortletType[]>([]);
		this.saveChanges$ = new Subject();
		this.cancelChanges$ = new Subject();

		this.locale = this.translate.currentLang;
		this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
			this.locale = lang.lang;
		});
	}

	loadPorletMenus(portletMenus?: DashboardConfigPortletType[]) {
		this.portletMenus$.next(portletMenus);
	}

	loadTranslations(translations: any[]) {
		this.translations$.next(translations);
	}

	addRow(totalColumns: number, portlets?: DashboardConfigPortlet[]) {
		let row = new DashboardConfigRow({
			columns: []
		});
		for (let i = 0; i < totalColumns; i++) {
			row.columns.push(new DashboardConfigColumn({
				portlet: portlets ? portlets[i] : null
			}));
		}

		this.onAddRow$.next(row);
	}

	addPortlet(portletType: DashboardConfigPortletType) {
		//console.log('addPortlet.portletType', portletType);
		let portlet = new DashboardConfigPortlet({
			name: portletType.name,
			title: portletType.title,
			config: portletType.config,
		});

		if (portlet.name == DashboardConfigPortletName.Calendar) {
		} else if (portlet.name == DashboardConfigPortletName.HtmlPortlet) {
		}
		this.onAddPortlet$.next(portlet);
	}

	// get localized portlet title
	getPortletTitle(portlet: DashboardConfigPortlet) {
		const trans = get(this.translations$.value, this.locale) || {};
		return trans && trans[portlet.title] || portlet.title;
	}

}

export class DashboardConfigIdentifier {
	public _id: string;
	constructor() { this._id = (Math.random() * 1e32).toString(36); }
}

// 
export class DashboardConfig {
	rows: DashboardConfigRow[];

	public constructor(init?: Partial<DashboardConfig>) {
		Object.assign(this, init);
	}
}

export class DashboardConfigRow extends DashboardConfigIdentifier {
	columns: DashboardConfigColumn[];

	public constructor(init?: Partial<DashboardConfigRow>) {
		super();
		Object.assign(this, init);
	}
}

export class DashboardConfigColumn extends DashboardConfigIdentifier {
	portlet: DashboardConfigPortlet;

	public constructor(init?: Partial<DashboardConfigColumn>) {
		super();
		Object.assign(this, init);
	}
}

export class DashboardConfigPortlet extends DashboardConfigIdentifier {
	name: DashboardConfigPortletName; // unique
	title: string;
	icon: string;
	params: any | GenericInquiryParams;
	config: any;

	public constructor(init?: Partial<DashboardConfigPortlet>) {
		super();
		Object.assign(this, init);
	}
}

export class DashboardConfigPortletType {
	name: DashboardConfigPortletName; // unique
	title: string;
	config: any;
}

export enum DashboardConfigPortletName {
	CustomerList = 'Customer List',
	SalesReport = 'Sales Report',
	Calendar = 'Calendar',
	//OrderTrackdays = 'Order Trackdays',
	HtmlPortlet = 'Html Portlet',
	CrudTablePortlet = 'CrudTable Portlet',
	HighChartPortlet = 'HighChart Portlet',
    GenericChart = 'generic-chart',
}
