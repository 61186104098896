<div>
  <ng-container *ngIf="!showForm; else form">
    <crud-table
      [title]="title | translate"
      name="cancellation-prices-trackday-items"
      relatedId="trackday_items_id"
      [apiUrl]="apiUrl"
      baseUrl="/trackdays-management/cancellation-prices-trackday-events"
      [isSubForm]="true"
      (onCreate)="onCreate()"
      (onEdit)="onEdit($event)"
      (onLoadTableList)="loadChartData()"
    ></crud-table>
  </ng-container>

  <ng-template #form>
    <crud-form
      [title]="title | translate"
      name="cancellation-prices-trackday-items"
      [apiUrl]="apiUrl"
      [formConfig]="formConfig"
      listUrl="/trackdays-management/cancellation-prices-trackday-events"
      [initialData]="initialFormData"
      [isSubForm]="true"
      (onExit)="showForm=false"
      ></crud-form>
  </ng-template>

  <ng-container *ngIf="!showForm">
    <kt-portlet>
      <kt-portlet-header [class]="'kt-portlet__head--lg'">
        <ng-container ktPortletTitle>
            <h3 class="kt-portlet__head-title table-subtitle">
                <span>{{'CHART.TRACKDAY_ITEM.CANCELLATION_PRICES.TITLE' | translate}}</span>
            </h3>
        </ng-container>
      </kt-portlet-header>

      <kt-portlet-body>
        <div>
          <div echarts [merge]="mergeOptions" [options]="chartOption" class="demo-chart"></div>
        </div>
      </kt-portlet-body>
    </kt-portlet>
  </ng-container>

</div>