import { animate, style, transition, trigger } from "@angular/animations";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-template-list-item",
  templateUrl: "./template-list-item.component.html",
  styleUrls: ["./template-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(275, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class TemplateListItemComponent implements OnInit {
  @Input() template: any;
  @Output() onDeleteEvent = new EventEmitter<string>();
  shouldConfirm$ = new BehaviorSubject(false);
  constructor() {}

  ngOnInit(): void {}

  onDeleteTemplate(e: Event) {
    this.stopPropagation(e);
    this.shouldConfirm$.next(true);
    // this.onDeleteEvent.emit(this.template.id);
  }

  cancelDelete(e: Event) {
    this.stopPropagation(e);
    this.shouldConfirm$.next(false);
  }

  doDelete(e: Event) {
    this.stopPropagation(e);
    this.onDeleteEvent.emit(this.template.id);
  }

  stopPropagation(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }
}
