<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<!-- <div>
  <crud-form
    name="laptime-imports"
    title="Laptime Import"
    nameField="name"
    [apiUrl]="apiUrl"
    [readonly]="isViewOnly()"
    listUrl="/user-management/laptime-imports"
    [customActionBtns]="customActionBtns"
    [onAfterSave]="dockImport"
  ></crud-form>
</div> -->

<app-forms-shell
    name="laptime-imports"
  [apiUrl]="apiUrl"
  listUrl="/user-management/laptime-imports"
></app-forms-shell>

