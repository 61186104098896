// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials/partials.module";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../core/base";
// Shared
// Components
import { CrudFormModule } from "../../partials/content/crud-form/crud-form.module";
import { CrudTableModule } from "../../partials/content/crud-table/crud-table.module";
import { StatisticsFormComponent } from "./form/statistics-form.component";
import { StatisticsTableComponent } from "./table/statistics-table.component";

import { MaterialModule } from "../../../modules/material/material.module";
import { UserEffects, usersReducer } from "../../../core/store";
import { CbFormsModule } from "../../../modules/cb-forms/cb-forms.module";

const routes: Routes = [
  // {
  //    path: '',
  //    component: StatisticsTableComponent,
  // },
  {
    //  path: 'edit/:id',
    path: "",
    component: StatisticsFormComponent,
  },
  //  {
  //      path: 'create',
  //      component: StatisticsFormComponent
  //  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature("users", usersReducer),
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),

    CrudTableModule,
    CrudFormModule,
    CbFormsModule,
    MaterialModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },

    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [StatisticsTableComponent, StatisticsFormComponent],
})
export class StatisticsModule {}
