<!--begin::Form-->
<form
  *ngIf="user"
  [formGroup]="changePasswordForm"
  class="kt-form kt-form--group"
>
  <kt-alert
    *ngIf="hasFormErrors"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    Oh snap! Password not match.
  </kt-alert>
  <div class="kt-form__section kt-form__section--first">
    <div class="form-group kt-form__group row mb-0">
      <div class="col-lg-8 kt-margin-bottom-20-mobile custom-field-holder">
        <mat-form-field class="mat-form-field-fluid custom-input">
          <input
            matInput
            type="password"
            id="password"
            autocomplete="off"
            placeholder="Password *"
            formControlName="password"
          />
          <mat-error
            >Password is
            <strong>required</strong>
          </mat-error>
          <mat-hint align="start"
            >Please enter
            <strong>Password</strong>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group kt-form__group row">
      <div class="col-lg-8 kt-margin-bottom-20-mobile custom-field-holder">
        <mat-form-field class="mat-form-field-fluid custom-input">
          <input
            matInput
            type="password"
            id="confirmPassword"
            autocomplete="off"
            placeholder="Confirm password *"
            formControlName="confirmPassword"
          />
          <mat-error
            >Confirm password is
            <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="changePasswordForm.hasError('notSame')">
            Passwords do not match
          </mat-error>
          <mat-hint align="start"
            >Please conirm
            <strong>Password</strong>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row justify-content-center align-items-center">
    <button
      type="button"
      [disabled]="changePasswordForm.invalid"
      mat-raised-button
      class="danger-button"
      (click)="onSubmit()"
      [disabled]="viewLoading"
      matTooltip="Save changes"
    >
      Change password
    </button>
  </div>
</form>
<!--end::Form-->
