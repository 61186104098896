<div>
    <crud-table
            title="Snippets"
            name="snippet-directbooking-trackday"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/snippet/directbooking-trackday"
    ></crud-table>
</div>
