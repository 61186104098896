/**
  * Usage: number | localNumber:'format'
  * If no format is provided 2 decimals will be used.
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
	name: 'localNumber',
})
export class LocalNumberPipe implements PipeTransform {

	constructor(
		private translationService: TranslationService) {
	}

	transform(value: any, format: string) {
		if (value == null) { return ''; } // !value would also react to zeros.
		if (!format) { format = '.2-2'; }

		let locale = this.translationService.getSelectedLanguage();
		if(locale == 'ch') locale = 'zh'; 
		else if(locale == 'jp') locale = 'ja';

		return formatNumber(value, locale, format);
	}
}
