import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { sendEmailActions, sendEmailSelectors } from "../../store";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  debounceTime,
  filter,
  takeUntil,
  withLatestFrom,
  take,
  distinctUntilChanged,
} from "rxjs/operators";

@Component({
  selector: "app-template-details",
  templateUrl: "./template-details.component.html",
  styleUrls: ["./template-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateDetailsComponent implements OnInit {
  @Output() templateFormStatusEvent = new EventEmitter();
  public templateBodyEditor = ClassicEditor;

  subject$ = this.store.pipe(select(sendEmailSelectors.selectCurrentSubject));
  body$ = this.store.pipe(select(sendEmailSelectors.selectCurrentBody));
  availableLanguages$ = this.store.pipe(
    select(sendEmailSelectors.selectAllTemplateDetails)
  );
  selectedLanguage$ = this.store.pipe(
    select(sendEmailSelectors.selectSelectedLanguage)
  );
  isAnyTemplateSelected$ = this.store.pipe(
    select(sendEmailSelectors.isAnyTemplateSelected)
  );

  headerImage$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentHeaderImage)
  );
  footerImage$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentFooterImage)
  );
  signature$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentSignature)
  );

  destroyed$ = new Subject();

  templateForms: UntypedFormArray = new UntypedFormArray([]);

  constructor(private store: Store, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.templateForms.valueChanges
      .pipe(takeUntil(this.destroyed$), withLatestFrom(this.selectedLanguage$))
      .subscribe(([form, lang]) => {
        this.templateFormStatusEvent.emit({
          status: this.templateForms.valid,
        });
        this.store.dispatch(
          sendEmailActions.updateTempForm({
            templates: this.templateForms.getRawValue(),
          })
        );
        this.store.dispatch(
          sendEmailActions.setFormState({ state: this.templateForms.valid })
        );
        this.store.dispatch(sendEmailActions.setFormDirty());
      });
    this.availableLanguages$
      .pipe(
        filter((translations) => translations.length > 0),
        distinctUntilChanged(
          (pre, cur) => JSON.stringify(pre) === JSON.stringify(cur)
        )
      )
      .subscribe((translations) => {
        this.templateForms.clear();
        translations.forEach((translation) => {
          this.templateForms.push(
            this.fb.group(
              {
                subject: this.fb.control(translation["subject"], [
                  Validators.required,
                ]),
                template: this.fb.control(translation["template"], [
                  Validators.required,
                ]),
                lang: translation["lang"],
              },
              { updateOn: "blur" }
            )
          );
        });
      });
  }

  markAllAsTouched() {
    let errors = this.templateForms.controls
      .map((form: UntypedFormGroup) => {
        if (!form.valid) {
          return form.controls["lang"].value;
        }
      })
      .filter(Boolean);

    if (errors.length > 0) {
      this.store.dispatch(sendEmailActions.updateFormErrors({ errors }));
    }

    this.templateForms.updateValueAndValidity();
    this.templateForms.markAllAsTouched();
  }

  changeLanguageEventHandler(event) {
    this.store.dispatch(
      sendEmailActions.updateSelectedTemplateDetails({
        templates: this.templateForms.getRawValue(),
        newLang: event,
      })
    );
  }
}
