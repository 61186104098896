<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
  <crud-table
    title="Laptime Levels"
    name="laptime-levels"
    [columns]="columns"
    [createButtonLabel]="createButtonLabel"
    [searchableColumns]="searchableColumns"
    [apiUrl]="apiUrl"
    baseUrl="/user-management/laptime-levels"
  ></crud-table>
</div>
