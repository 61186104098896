import { Action, createReducer, on } from "@ngrx/store";
import { AppState } from "./app.reducers";
import { reloadControllerActions } from "../actions/reload-controller.actions";

export interface ReloadControllerState {
  connectionStatus: boolean;
  target: string;
  lastUpdated: Date;
}

export const reloadControllerInitialState: ReloadControllerState = {
  connectionStatus: false,
  target: null,
  lastUpdated: null,
};

const reducer = createReducer(
  reloadControllerInitialState,
  on(
    reloadControllerActions.updateConnectionStatus,
    (state, { connectionStatus }) => ({
      ...state,
      connectionStatus,
    })
  ),
  on(
    reloadControllerActions.updateTargetComponent,
    (state, { targetComponent }) => ({
      ...state,
      target: targetComponent,
      lastUpdated: new Date(),
    })
  ),
  on(reloadControllerActions.resetTargetComponent, (state) => ({
    ...state,
    target: null,
  }))
);

export function reloadControllerReducer(
  state: ReloadControllerState | undefined,
  action: Action
) {
  return reducer(state, action);
}

export interface State extends AppState {
  reloadController: ReloadControllerState;
}
