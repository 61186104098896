import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormConfigService } from "../../form.config.service";

@Component({
  selector: "dynamic-form-builder",
  template: `
    <form
      (ngSubmit)="onSubmit.emit(this.form.value)"
      [formGroup]="form"
      class="form-group kt-form__group row cb-paper"
    >
      <ng-container *ngFor="let field of fields$ | async">
        <div
          *ngIf="field.invisible !== true"
          class="kt-margin-bottom-20-mobile custom-field-holder"
          [ngClass]="field.width | dynamicComponentWidth"
          [class.hiddenType]="field.type === 'hidden'"
        >
          <field-builder [field]="field" [form]="form"></field-builder>
        </div>
      </ng-container>
    </form>
  `,
  styles: [
    `
      :host {
        display: block;
        overflow: hidden;
      }
      .hiddenType {
        margin: 0;
        padding: 0;
        display: none;
      }
      /* .cb-paper {
        background: var(--background);
        border-radius: var(--border-radius);
      } */
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormBuilderComponent implements OnInit, OnDestroy {
  @Output() onSubmit = new EventEmitter();
  @Input() form: UntypedFormGroup;
  @Input() fields: any[] = [];
  destroyed$ = new Subject();
  fields$ = new BehaviorSubject([]);
  constructor(private formServiceConfig: FormConfigService) {}
  ngOnInit(): void {
    this.fields$.next(
      this.fields.map((f) => {
        return {
          ...f,
          invisible: !this.formServiceConfig.getFieldVisibilityState(f),
        };
      })
    );
    this.formServiceConfig.updateFieldVisibility$
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (value) => {
          const updatedFields = this.fields$.value.map((e) => {
            if (e.name === value) {
              return {
                ...e,
                invisible: !this.formServiceConfig.getFieldVisibilityState(e),
              };
            }
            return e;
          });
          this.fields$.next(updatedFields);
        },
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
