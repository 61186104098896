import { Component, Input, OnInit } from "@angular/core";
import { MultiSelectFilter, SingleSelectFilter } from "../../types/types";
import { FormControl } from "@angular/forms";
import { FilterService } from "../../services/filter.service";

@Component({
  template: `<cb-custom-select
    [allowMultiple]="true"
    [selectConfig]="filter.dynamic_options"
    [title]="filter?.title"
    [name]="filter?.field"
    [control]="control"
    (onSelectionChange)="handleSelectionChange($event)"
  ></cb-custom-select>`,
})
export class FilterMultiSelectComponent implements OnInit {
  @Input() filter: MultiSelectFilter;
  @Input() control: FormControl;
  constructor(private filterService: FilterService) {}

  ngOnInit() {}

  handleSelectionChange(event: { value: string; label: string }) {
    this.filterService.setAppliedFilters({
      component: this.filter,
      ...event,
    });
  }
}
