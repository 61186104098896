import { Injectable, OnDestroy } from "@angular/core";
import { SelectDynamicOption } from "../../../../modules/shared/types/types";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment_api } from "../../../../../environments/environment.api";
import { GlobalConfigParser } from "../../../../modules/shared/services/global-config-parser.service";

@Injectable()
export class CustomSelectService implements OnDestroy {
  private readonly _selectConfig = new BehaviorSubject<
    SelectDynamicOption | undefined
  >(undefined);
  readonly selectConfig$ = this._selectConfig.asObservable();

  private readonly _name = new BehaviorSubject<string | undefined>(undefined);
  readonly name$ = this._name.asObservable();

  destroyed$ = new Subject();
  isLoading$ = new BehaviorSubject(false);
  allOptions$ = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private globalConfigParser: GlobalConfigParser
  ) {
    combineLatest([this.selectConfig$, this.name$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([config, name]) => !!config && !!name),
        // tap(() => this.isLoading$.next(true)),
        switchMap(([config]) => {
          if (config.url) {
            this.isLoading$.next(true);
            if (config?.parent_field) {
              if (!config?.supportingData) {
                this.isLoading$.next(false);
                this.allOptions$.next([]);
                return of(true);
              }
            }
            const url = this.globalConfigParser.parsePlaceholders(
              config.url,
              config.supportingData
            );
            return this.get(url).pipe(
              finalize(() => this.isLoading$.next(false)),
              map((res) =>
                this.allOptions$.next(this.parseDynamicOptions(config, res))
              ),
              catchError((error) => of(false))
            );
          }

          if (config.data?.length > 0) {
            this.allOptions$.next(this.parseStaticOptions(config));
            return of(true);
          }

          return of(true);
        })
      )
      .subscribe();
  }

  initiateSelectName(name) {
    this._name.next(name);
  }

  initiateSelectConfig(selectConfig) {
    this._selectConfig.next(selectConfig);
  }

  parseStaticOptions(config: SelectDynamicOption) {
    const { data } = config;
    const valueField = config?.value_field ?? "value";
    const labelField = config?.label_field ?? "label";

    return data.map((d) => {
      return {
        value: d[valueField] ?? "",
        label: d[labelField] ?? "",
      };
    });
  }

  parseDynamicOptions(config: SelectDynamicOption, data) {
    const valueField = config?.value_field ?? "value";
    const labelField = config?.label_field ?? "label";

    return data.map((d) => {
      return {
        value: d[valueField] ?? "",
        label: d[labelField] ?? "",
      };
    });
  }

  get(url: string) {
    const urlComp = new URL(environment_api.api_url + url);
    urlComp.searchParams.append(
      "lang",
      localStorage.getItem("language") || "en"
    );
    return this.http.get(urlComp.toString());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
