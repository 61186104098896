import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TextBoxConfig } from "../models/textbox.config.model";

@Component({
  selector: "cb-textArea",
  template: `
    <div [formGroup]="form" *ngIf="config.visible">
      <mat-form-field
        appearance="fill"
        class="mat-form-field-fluid"
        #formTab
      >
        <mat-label>{{ config.placeholder }}</mat-label>
        <textarea
          *ngIf="field.type === 'textarea'"
          matInput
          [placeholder]="config.placeholder"
          [readonly]="config.readonly"
          [formControlName]="config.formControlName"
          [required]="config.required"
          [attr.data-pgm-formelement]="config.testingAttribute"
        ></textarea>
        <span matPrefix *ngIf="!!config.tooltip">
          <i
            class="far fa-question-circle"
            matTooltipPosition="above"
            [matTooltip]="config.tooltip"
            style="margin-right: 4px;"
          ></i>
        </span>
        <mat-hint
          *ngIf="!config.readonly"
          align="start"
          [innerHtml]="
            'COMMON.PLEASE_ENTER' | translate : { title: config.hint }
          "
        >
          <!-- <strong>{{ config.hint | translate }}</strong> -->
        </mat-hint>
        <mat-error
          *ngIf="form.controls[this.config.formControlName].hasError"
          [innerHtml]="
            form.controls[this.config.formControlName].errors
              | inputErrors : field
          "
        >
        </mat-error>
      </mat-form-field>
    </div>
  `,
})
export class CbTextAreaComponent {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: TextBoxConfig;
}
