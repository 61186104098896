import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { TableConfigService } from "../../../../services/table.config.service";
import { FormAnchorDirective } from "./directives/form-anchor.directive";
import { SaveTemplateAsFormComponent } from "./components/save-template-as-form/save-template-as-form.component";
import { RemoveItemTrigger } from "../../../../modules/send-email/animations/remove-item.animation";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSelectionList, MatSelectionListChange } from "@angular/material/list";
import { MatMenuTrigger } from "@angular/material/menu";

export type ExportColumnConfigComponentData = {
  columns: any[];
  tableName: string;
  currentTableQueryParams: any;
  tableConfigUrl?: string;
  currentPageSize?: number;
  currentPage?: number;
  currentTotalRecords?: number;
};

const SORT_FIELD = "text";
type EXPORT_COLUMN_TYPE = "ALL" | "VISIBLE" | "CUSTOM" | "TEMPLATE";
type EXPORT_FORMAT = "CSV" | "EXCEL";
type EXPORT_DATA = "ALL_FILTERED" | "CURRENT_PAGE" | "ALL";

@Component({
  selector: "cb-export-column-config",
  templateUrl: "./export-column-config.component.html",
  styleUrls: ["./export-column-config.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [RemoveItemTrigger],
})
export class ExportColumnConfigComponent implements OnInit, OnDestroy {
  @ViewChild("templatesList") templatesList: MatSelectionList;
  @ViewChild("menuTrigger") saveAsButton: MatMenuTrigger;
  @ViewChild(FormAnchorDirective, { static: true })
  formAnchor!: FormAnchorDirective;
  exportFormats: { value: EXPORT_FORMAT; text: string }[] = [
    { value: "EXCEL", text: "COMMON.EXCEL" },
    { value: "CSV", text: "COMMON.CSV" },
  ];

  // add translations
  columnExportTypes: { value: EXPORT_COLUMN_TYPE; text: string }[] = [
    { value: "CUSTOM", text: "COMMON.CUSTOM" },
    { value: "VISIBLE", text: "COMMON.VISIBLE" },
    { value: "ALL", text: "COMMON.ALL" },
    { value: "TEMPLATE", text: "COMMON.TEMPLATE" },
  ];

  columnExportData: { value: EXPORT_DATA; text: string }[] = [
    {
      value: "CURRENT_PAGE",
      text: `Current, visible page (${this.formatNumberOfRecords(
        this.data.currentPageSize,
        this.data.currentPage,
        this.data.currentTotalRecords
      )})`,
    },
    {
      value: "ALL_FILTERED",
      text: `All filtered data (1 - ${this.data.currentTotalRecords})`,
    },
    /* { value: "ALL", text: "All data" },*/
  ];

  dndReadonly$ = new BehaviorSubject(false);
  isLoadingTemplates$ = new BehaviorSubject(false);
  showTemplates$ = new BehaviorSubject(false);
  selectedTemplate$ = new BehaviorSubject(null);
  templates$ = new BehaviorSubject([]);

  allColumns = [];
  remainingColumns$ = new BehaviorSubject([]);
  displayedColumns$ = new BehaviorSubject([]);
  filteredRemainingColumns$ = new BehaviorSubject([]);
  showClearSearchButton$ = new BehaviorSubject(false);
  showColumnSelection$ = new BehaviorSubject(false);
  searchControl = new UntypedFormControl("");
  destroyed$ = new Subject<void>();
  columnExportTypeControl = new UntypedFormControl("");
  columnExportFormatControl = new UntypedFormControl("");
  columnExportDataControl = new UntypedFormControl("");
  constructor(
    public dialogRef: MatDialogRef<ExportColumnConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExportColumnConfigComponentData,
    public dialog: MatDialog,
    // not creating a new service, using the old existing one.
    private tableConfigService: TableConfigService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getTemplates();
    combineLatest([
      this.remainingColumns$,
      this.searchControl.valueChanges.pipe(startWith("")),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([remainingColumns, searchValue]: [any, any]) => {
        if (!searchValue) {
          this.showClearSearchButton$.next(false);
          this.filteredRemainingColumns$.next(remainingColumns);
          return;
        }

        this.showClearSearchButton$.next(true);
        this.filteredRemainingColumns$.next(
          remainingColumns
            .filter(
              (r) =>
                r.translate?.toLowerCase().includes(searchValue.toLowerCase()) ||
                r.text?.toLowerCase().includes(searchValue.toLowerCase()) ||
                r.name?.toLowerCase().includes(searchValue.toLowerCase())
            )
            .sort(this.sortByNames)
        );
      });

    this.allColumns = this.data.columns.sort(this.sortByNames) || [];

    this.columnExportTypeControl.valueChanges.subscribe(
      (value: EXPORT_COLUMN_TYPE) => {
        this.showTemplates$.next(false);
        this.selectedTemplate$.next(null);
        this.templatesList?.deselectAll();
        this.dndReadonly$.next(false);
        if (value === "ALL") {
          this.displayedColumns$.next(this.allColumns);
          this.remainingColumns$.next([]);
          this.showColumnSelection$.next(true);
          return;
        }
        if (value === "VISIBLE") {
          this.displayedColumns$.next(
            this.allColumns
              .filter((r) => r.visible)
              .sort((a, b) => a.order - b.order)
          );
          this.remainingColumns$.next([]);
          this.dndReadonly$.next(true);
          this.showColumnSelection$.next(true);
          return;
        }
        if (value === "CUSTOM") {
          this.remainingColumns$.next(
            this.allColumns.filter((r) => !r.visible)
          );
          this.displayedColumns$.next(
            this.allColumns
              .filter((r) => r.visible)
              .sort((a, b) => a.order - b.order)
          );
          this.showColumnSelection$.next(true);
          return;
        }
        if (value === "TEMPLATE") {
          this.displayedColumns$.next([]);
          this.remainingColumns$.next([]);
          this.showColumnSelection$.next(false);
          this.showTemplates$.next(true);
          return;
        }
      }
    );

    this.columnExportTypeControl.patchValue("CUSTOM");
    this.columnExportFormatControl.patchValue("EXCEL");
    this.columnExportDataControl.patchValue("CURRENT_PAGE");
  }

  getTemplates() {
    this.isLoadingTemplates$.next(true);
    this.tableConfigService
      .loadExportTemplates(this.data.tableName)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (templates: any) => {
          if (templates.length) {
            this.templates$.next(
              templates
                .map((t) => ({
                  ...t,
                  columns: JSON.parse(t.column_config),
                }))
                .sort(this.sortTemplates)
            );
          }
          this.isLoadingTemplates$.next(false);
        },
        error: () => {
          this.isLoadingTemplates$.next(false);
        },
      });
  }

  sortTemplates(template1, template2) {
    if (template1.user_id === template2.user_id) {
      return 0;
    }
    if (template1.user_id === null) {
      return 1;
    }
    if (template2.user_id === null) {
      return -1;
    }

    return template1.user_id < template2.user_id ? -1 : 1;
  }

  saveConfig() {
    if (!this.selectedTemplate$.value) {
      return;
    }
    const columns = this.prepareColumnsForSave();
    const payload = {
      name: this.selectedTemplate$.value?.name,
      mode: this.selectedTemplate$.value?.mode,
      columns: [...columns.displayedColumns, ...columns.remainingColumns],
      tableName: this.data.tableName,
      id: this.selectedTemplate$.value?.id,
    };
    this.tableConfigService
      .saveExportTemplate(payload, this.selectedTemplate$.value.id)
      .subscribe({
        next: (response) => {
          this.templates$.next(
            this.templates$.value.map((t) => {
              if (t.id === response.id) {
                return {
                  ...t,
                  columns: JSON.parse(response.column_config),
                };
              }
              return t;
            })
          );
          this.toastrService.success("Template saved successfully");
        },
        error: () => {},
      });
  }

  saveConfigAs() {
    const columns = this.prepareColumnsForSave();
    const viewContainerRef = this.formAnchor.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(
      SaveTemplateAsFormComponent
    );
    componentRef.instance.tableName = this.data.tableName;
    componentRef.instance.remainingColumns = columns.remainingColumns;
    componentRef.instance.displayedColumns = columns.displayedColumns;
    componentRef.instance.saveTemplateAsEvent.subscribe((event) => {
      this.saveTemplateAsHandler(event);
    });

    componentRef.instance.closeTemplateAsEvent.subscribe((event) => {
      this.closeTemplateAsHandler(event);
    });

    this.saveAsButton.openMenu();
  }

  // this prepares te data to be passed for save template modal
  // its changes orders of the columns to start from 1
  prepareColumnsForSave() {
    const displayedColumns = this.displayedColumns$.value;

    return this.allColumns.reduce(
      (acc, column) => {
        const foundColumn = displayedColumns.find(
          (c) => c.name === column.name
        );
        if (foundColumn) {
          const currentDisplayedColumns = acc.displayedColumns;
          currentDisplayedColumns.push({
            ...foundColumn,
            order: displayedColumns.indexOf(foundColumn),
            visible: true,
          });
          // immutable way
          //   const currentDisplayedColumns = [
          //     ...acc["displayedColumns"],
          //     {
          //       ...foundColumn,
          //       order: displayedColumns.indexOf(foundColumn),
          //       visible: true,
          //     },
          //   ];
          return {
            ...acc,
            displayedColumns: currentDisplayedColumns,
          };
        }
        const currentRemainingColumns = acc.remainingColumns;
        currentRemainingColumns.push({
          ...column,
          order: 1000,
          visible: false,
        });
        return {
          ...acc,
          remainingColumns: currentRemainingColumns,
        };
      },
      {
        displayedColumns: [],
        remainingColumns: [],
      }
    );
  }

  onDeleteTemplate(templateId: string) {
    this.tableConfigService.deleteExportTemplate(templateId).subscribe({
      next: (res) => {
        this.templates$.next(
          this.templates$.value.filter((template) => template.id !== templateId)
        );
        this.templateChange(null, null);
        this.toastrService.success("Template has been removed");
      },
      error: (err) => {
        this.toastrService.error(err.error.message);
      },
    });
  }

  templateChange(template: MatSelectionListChange, id?: number) {
    let templateId = id;
    if (template) {
      templateId = template.source.selectedOptions.selected[0].value;
    }

    if (templateId) {
      const templateDetails = this.templates$.value.find(
        (t) => t.id === templateId
      );
      this.selectedTemplate$.next(templateDetails);
      this.remainingColumns$.next(
        templateDetails.columns.filter((r) => !r.visible)
      );
      this.displayedColumns$.next(
        templateDetails.columns
          .filter((r) => r.visible)
          .sort((a, b) => a.order - b.order)
      );
      this.showColumnSelection$.next(true);
      return;
    }

    this.showColumnSelection$.next(false);
    this.selectedTemplate$.next(null);
    this.displayedColumns$.next([]);
    this.remainingColumns$.next([]);
  }

  export(close: boolean) {
    this.tableConfigService
      .getTableExportConfig2({
        documentFormat: this.columnExportFormatControl.value.toLowerCase(),
        columnFormat: this.columnExportTypeControl.value.toLowerCase(),
        dataFormat: this.columnExportDataControl.value.toLowerCase(),
        columns: this.displayedColumns$.value,
        tableQueryParams: this.data.currentTableQueryParams,
        isApplyFilter: true,
        orientation: "landscape",
        tableName: this.data.tableName.toLowerCase(),
        tableConfigUrl: this.data.tableConfigUrl,
      })
      .subscribe({
        next: (res) => {
          console.log(res);
          if (close) {
            this.dialogRef.close();
          }
        },
        error: (err) => {
          this.toastrService.error(err.error.message);
        },
      });
  }

  closeTemplateAsHandler(event) {
    this.saveAsButton.closeMenu();
  }
  saveTemplateAsHandler(event) {
    this.columnExportTypeControl.patchValue("TEMPLATE");
    this.templates$.next([...this.templates$.value, event]);
    this.saveAsButton.closeMenu();
    this.templateChange(null, event.id);
  }
  clearSearch() {
    this.searchControl.setValue("");
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      if (event.previousIndex === event.currentIndex) return;
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const item = event.item.data;
      const prevIndex = event.previousContainer.data.indexOf(item);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        prevIndex,
        event.currentIndex
      );
      // sort drop list data only when the item is dropped in the first list
      if (event.container.id === "remainingColumns") {
        event.container.data.sort(this.sortByNames);
      }
      this.searchControl.updateValueAndValidity();
      //   this.filteredRemainingColumns$.next(event.previousContainer.data);
    }
  }
  close() {
    this.dialogRef.close();
  }

  forceClose() {
    this.dialogRef.close();
  }
  cancelClose() {}

  remove(item) {
    const arraySnapshot = [...this.displayedColumns$.value];
    const index = arraySnapshot.indexOf(item);
    if (index > -1) {
      arraySnapshot.splice(index, 1);
      this.remainingColumns$.next(
        [...this.remainingColumns$.value, item].sort(this.sortByNames)
      );
      this.displayedColumns$.next(arraySnapshot);
    }
  }

  add(item) {
    const arraySnapshot = [...this.remainingColumns$.value];
    const index = arraySnapshot.indexOf(item);
    if (index > -1) {
      arraySnapshot.splice(index, 1);
      this.remainingColumns$.next(arraySnapshot);
      this.displayedColumns$.next([...this.displayedColumns$.value, item]);
    }
  }

  private sortByNames(a, b) {
    if (a?.[SORT_FIELD] < b?.[SORT_FIELD]) {
      return -1;
    }
    if (a?.[SORT_FIELD] > b?.[SORT_FIELD]) {
      return 1;
    }
    return 0;
  }

  private formatNumberOfRecords(
    pageSize: number,
    pageIndex: number,
    currentTotal: number
  ): string {
    const index = pageIndex + 1;
    const start = pageSize * (index - 1) + 1;
    const end = pageSize * index;
    return `${start} - ${end > currentTotal ? currentTotal : end}`;
  }

  stopEvent(e: MouseEvent) {
    if (!e.defaultPrevented) {
      e.stopPropagation();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
