<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
  <crud-table
    title="Groupbooking participants detached from the groupbooking"
    name="group-booking-zombies"
    [columns]="columns"
    [createButtonLabel]="createButtonLabel"
    [apiUrl]="apiUrl"
    baseUrl="/group-booking-zombies"
    [readonly]="false"
  ></crud-table>
</div>
