import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { distinctUntilChanged } from "rxjs/operators";
import { FormConfigService } from "../../../form.config.service";
import { DropDownConfig } from "../models";

@Component({
  selector: "cb-dropdown",
  template: `
    <div [formGroup]="form">
      <custom-mat-select
        [name]="config.name"
        [control]="form.controls[field.name]"
        [title]="config.title"
        [placeholder]="
          field.loading ? ('COMMON.LOADING' | translate) : config.placeholder
        "
        (selectionChange)="handleSelectionChange($event)"
        [required]="config.required"
        [disabled]="config.disabled"
        [tooltip]="config.tooltip"
        [multiple]="config.multiple"
        [options]="field.data || config.options"
        [optionValue]="config.optionValue"
        [optionLabel]="config.optionLabel"
        matFormFieldClass="mat-form-field-fluid"
      >
      </custom-mat-select>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// ////////////////////////////////////////////////////////////////////////////////////////////////////
// /// this component needs the `selectionChange`, as there are cases when
// /// a select has a lookup field, (or something else), which means we need to
// /// act on its status change,
// /// currently changes can also be tracked from shell component, as we have access to the forms there
// ////////////////////////////////////////////////////////////////////////////////////////////////////
export class CbDropDownComponent implements OnInit {
  constructor(private formConfigService: FormConfigService) {}
  ngOnInit(): void {
    // if (
    //   this.field.name === "participant_status_id" ||
    //   this.field.name === "participant_type_id"
    // ) {
    //   console.log("config", this.config);
    //   console.log("form", this.form);
    //   console.log("field", this.field);
    // }
    // this.form.controls[this.field.name].valueChanges
    //   .pipe(
    //     distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    //   )
    //   .subscribe({
    //     next: (value) => {
    //       if (this.form.dirty) {
    //         setTimeout(() => {
    //           this.formConfigService.updateFormConfigs(value, this.field);
    //         });
    //       }
    //     },
    //   });
  }
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: DropDownConfig;

  handleSelectionChange(event) {
    if (this.form.dirty) {
      setTimeout(() => {
        this.formConfigService.updateFormConfigs(
          event ? event.value : null,
          this.field
        );
      }, 0);
    }
  }
}
