// Angular
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";

import { ViewRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { get, reject } from "lodash";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CrudUtilsService } from "../../../../core/base/utils";
@Component({
  selector: "custom-mat-select",
  templateUrl: "./custom-mat-select.component.html",
  styleUrls: ["./custom-mat-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomMatSelectComponent implements OnInit, OnChanges {
  // @Input() form: FormGroup;
  @Input() name: string;

  @Input() control: UntypedFormControl;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() tooltip: string;
  @Input() multiple: boolean;
  @Input() options: any[];
  @Input() optionValue: string;
  @Input() optionLabel: string;
  @Input() customError: any;
  @Input() allowClear: boolean = true;
  @Input() matFormFieldClass: string;
  @Input() matFormFieldFloatLabel: string;

  @Input() selection: any;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() finishedLoading: EventEmitter<boolean> =
    new EventEmitter<boolean>();
//   @ViewChild("matSelect") matSelect: MatSelect;
  public parsedOptions: any[];

  /** control for the MatSelect filter keyword */
  public filterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of options filtered by search keyword */
  public filteredOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>();

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  langChangeSubs$: any;
  lang: string;

  /**
   * Component constructor
   */
  constructor(
    private translate: TranslateService,
    private crudUtilsService: CrudUtilsService,
    private cdRef: ChangeDetectorRef
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // selected language change
    this.langChangeSubs$ = this.translate.onLangChange.subscribe(
      (lang: LangChangeEvent) => {
        this.loadOptions();
      }
    );

    //console.log('ngOnInit', this.control);

    if (this.control == null) {
      this.control = new UntypedFormControl();
      this.control.setValue(this.selection);
    }

    // this.loadOptions();
    // this.filterOptions();

    // listen for search field value changes
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOptions();
      });

    if (this.disabled) this.control.disable();
    else this.control.enable();
  }

  /**
   * On init
   */
  ngOnChanges({
    selection,
    title,
    placeholder,
    required,
    disabled,
    tooltip,
    multiple,
    options,
    customError,
  }: SimpleChanges) {
    if (selection) {
      this.selection = selection.currentValue;
      if (this.control == null) this.control = new UntypedFormControl();
      this.control.setValue(this.selection);
      if (this.options.length) {
        this.finishedLoading.emit(true);
      }
    }

    if (title) this.title = title.currentValue;
    if (placeholder) this.placeholder = placeholder.currentValue;
    if (required) this.required = required.currentValue;
    if (disabled) this.disabled = disabled.currentValue;
    if (tooltip) this.tooltip = tooltip.currentValue;
    if (multiple) this.multiple = multiple.currentValue;
    if (options) {
      this.options = options.currentValue;
      this.loadOptions();
    }
    if (customError) this.customError = customError.currentValue;
  }

  ngOnDestroy() {
    if (this.langChangeSubs$) this.langChangeSubs$.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  loadOptions() {
    this.parsedOptions = this.options.map((option) => {
      return {
        value: get(option, this.optionValue),
        label: this.getOptionLabel(option),
        isBookmarkedOption: option.is_bookmarked_option ? -1 : 0,
        isInactive: option.is_active != null && !option.is_active,
      };
    });
    this.filterCtrl.setValue(null);
    this.filterOptions();
    //this.crefDetectChanges();
  }

  filterOptions() {
    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      const options = this.parsedOptions.slice();
      this.filteredOptions.next(options);
      return;
    } else {
      search = (search || "").toLowerCase();
    }
    // filter the banks
    const options = this.parsedOptions.filter(
      (option) => (option.label || "").toLowerCase().indexOf(search) > -1
    );
    this.filteredOptions.next(options);
  }

  removeFieldArrayValue(value) {
    const values = this.control.value || [];
    const newValues = reject(values, (r) => r == value);
    this.control.setValue(newValues);
  }

  getLabelAsString(label: any) {
    return label == null ? "" : label + "";
  }

  getOptionLabel(option) {
    //console.log('getOptionLabel', this.name, option, this.optionLabel);
    let label = option[this.optionLabel];
    if (label != null) return label;
    label = this.crudUtilsService.parsePlaceholders(this.optionLabel, option);
    return label != null ? label : "";
  }

  getSelectedLabel(value) {
    const option = (this.options || []).find(
      (r) => r[this.optionValue] == value
    );
    if (option) return this.getLabelAsString(this.getOptionLabel(option));
  }

  compareSingleSelectOptions(o1: any, o2: any): boolean {
    return o1 + "" === o2 + "";
  }

  clear() {
    this.control.setValue(null);
    this.selection = null;
    this.selectionChange.emit(null);
  }

  crefDetectChanges() {
    if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

  handleSelectionChange(event) {
    this.selectionChange.emit({
      value: event.value,
      text: event.source.triggerValue,
    });
  }
}
