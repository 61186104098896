<div>
    <crud-table
            title="Startnumberpool List"
            name="startnumberpool"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/trackdays-management/startnumberpool"
            [readonly]="false"
            [hasReadonlyActions]="true"
    ></crud-table>
</div>
