import { Subscription } from "rxjs";
// Angular
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { LayoutConfigService, SplashScreenService } from "./core/base";
import { reloadControllerActions } from "./core/store/actions/reload-controller.actions";
import { AppState } from "./core/store/reducers/app.reducers";
import { WsService } from "./services";
@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Metronic";
  loader: boolean;

  private unsubscribe: Subscription[] = [];

  constructor(
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    public viewContainerRef: ViewContainerRef,
    private wsService: WsService,
    private store: Store<AppState>
  ) {
    // let currentLanguage = localStorage.getItem("language");
    // if (!currentLanguage) {
    //   currentLanguage = "en";
    // }
    // this.translate.addLangs([currentLanguage]);
    // this.translate.use(currentLanguage);
  }
  ngOnInit(): void {
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig("loader.enabled");

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          // hide splash screen
          this.splashScreenService.hide();

          // scroll to top on every route change
          window.scrollTo(0, 0);

          // to display back the body content
          setTimeout(() => {
            document.body.classList.add("kt-page--loaded");
          }, 500);
        }, 1500);
      }
    });
    this.unsubscribe.push(routerSubscription);
    this.wsService.init();
  }

  test(table) {
    this.store.dispatch(
      reloadControllerActions.updateTargetComponent({ targetComponent: table })
    );
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
