// Anglar
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
// locales
import localeDe from "@angular/common/locales/de";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeIt from "@angular/common/locales/it";
import localeJa from "@angular/common/locales/ja";
import localeNl from "@angular/common/locales/nl";
import localeZh from "@angular/common/locales/zh";
import { NgModule } from "@angular/core";
import { TokenExpiredDialogComponent } from "../views/partials/content/token-expired/token-expired-dialog.component";
// Layout Directives
// Services
import {
  BooleanPipe,
  ConfirmActionButtonDirective,
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  RunScriptsDirective,
  SafePipe,
  ScrollTopDirective,
  SortByPipe,
  StickyDirective,
  StripHtmlPipe,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective,
  WhenEmptyDirective,
} from "./base";
import { FilterByPipe } from "./base/pipes/filter-by.pipe";
import { LocalDatePipe } from "./base/pipes/local-date.pipe";
import { LocalNumberPipe } from "./base/pipes/local-number.pipe";
import { ParseLinkPipe } from "./base/pipes/parse-link.pipe";

registerLocaleData(localeDe);
registerLocaleData(localeZh);
registerLocaleData(localeEs);
registerLocaleData(localeJa);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeNl);

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    WhenEmptyDirective,
    MenuDirective,
    TabClickEventDirective,
    ContentAnimateDirective,
    StickyDirective,
    RunScriptsDirective,
    ConfirmActionButtonDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    LocalNumberPipe,
    LocalDatePipe,
    ParseLinkPipe,
    SortByPipe,
    FilterByPipe,
    BooleanPipe,
    StripHtmlPipe,
    TokenExpiredDialogComponent,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    WhenEmptyDirective,
    MenuDirective,
    TabClickEventDirective,
    ContentAnimateDirective,
    StickyDirective,
    RunScriptsDirective,
    ConfirmActionButtonDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    LocalNumberPipe,
    LocalDatePipe,
    ParseLinkPipe,
    SortByPipe,
    FilterByPipe,
    BooleanPipe,
    StripHtmlPipe,
    TokenExpiredDialogComponent,
  ],
  providers: [DatePipe, LocalDatePipe, LocalNumberPipe],
})
export class CoreModule {}
