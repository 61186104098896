import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SEND_EMAIL_FEATURE_NAME } from "./send-email.reducer";
import { SendEmailState } from "./send-email.model";

export const selectSendEmailState = createFeatureSelector<SendEmailState>(
  SEND_EMAIL_FEATURE_NAME
);

export const selectIsFormValid = createSelector(
  selectSendEmailState,
  (state) => state.isFormValid
);

export const selectIsFormDirty = createSelector(
  selectSendEmailState,
  (state) => state.isFormDirty
);

export const selectCurrentTemplateDetail = createSelector(
  selectSendEmailState,
  (state) => state.templateDetails
);
export const selectTemplateTypes = createSelector(
  selectSendEmailState,
  (state) => state.templateTypes
);
export const selectThemes = createSelector(
  selectSendEmailState,
  (state) => state.themes
);
export const selectEmailSentStatus = createSelector(
  selectSendEmailState,
  (state) => state.emailSent
);

export const selectSelectedLanguage = createSelector(
  selectSendEmailState,
  ({ templateDetailsSelectedLanguage }) => {
    if (templateDetailsSelectedLanguage === "") {
      return "en";
    }
    return templateDetailsSelectedLanguage;
  }
);

export const selectTemplates = createSelector(
  selectSendEmailState,
  (state) => state.templates
);

export const selectLoadingStatus = createSelector(
  selectSendEmailState,
  (state) => state.isLoading
);

export const selectCurrentTemplateId = createSelector(
  selectSendEmailState,
  (state) => state.currentTemplateId
);

export const selectCurrentThemeId = createSelector(
  selectSendEmailState,
  (state) => state.currentThemeId
);

export const selectCurrentTemplate = createSelector(
  selectTemplates,
  selectCurrentTemplateId,
  (templates, id) => {
    return templates.find((t) => t.id === id);
  }
);

export const selectCurrentTheme = createSelector(
  selectThemes,
  selectCurrentThemeId,
  (themes, id) => {
    if (id) {
      return themes.find((t) => t.id === id);
    }
    return null;
  }
);

export const selectCurrentHeaderImage = createSelector(
  selectCurrentTheme,
  (theme) => {
    if (theme) {
      return theme.images.find((image) => {
        if (image.type === "header") return true;
        return false;
      });
    }
    return null;
  }
);

export const selectCurrentFooterImage = createSelector(
  selectCurrentTheme,
  (theme) => {
    if (theme) {
      return theme.images.find((image) => {
        if (image.type === "footer") return true;
        return false;
      });
    }
    return null;
  }
);

export const selectCurrentSignature = createSelector(
  selectCurrentTheme,
  (theme) => {
    if (theme) {
      return theme.signatures;
    }
    return "";
  }
);

export const selectCurrentTranslationObject = createSelector(
  selectCurrentTemplateDetail,
  selectSelectedLanguage,
  (template, lang) => {
    if (template?.data) {
      return template.data.translations?.find((t) => t.lang === lang);
    }
  }
);

export const selectCurrentSubject = createSelector(
  selectCurrentTranslationObject,
  (translation) => {
    if (translation) {
      return translation.subject;
    }
  }
);

export const selectCurrentBody = createSelector(
  selectCurrentTranslationObject,
  (translation) => {
    if (translation) {
      return translation.template;
    }
  }
);

export const selectCurrentTemplateTranslations = createSelector(
  selectCurrentTemplateDetail,
  (details) => {
    if (details) {
      return details.data?.translations;
    }
  }
);

// export const selectAvailableLanguages = createSelector(
//   selectCurrentTemplate,
//   (template) => {
//     template.translation;
//   }
// );

export const isAnyTemplateSelected = createSelector(
  selectCurrentTemplateDetail,
  (template) => !!template
);

export const selectAllTemplateDetails = createSelector(
  selectCurrentTemplateDetail,
  (template) => template?.data?.translations || []
);

export const selectParticipants = createSelector(
  selectSendEmailState,
  (state) => state?.participants
);

export const selectParticipantsCount = createSelector(
  selectParticipants,
  (state) => state?.length || 0
);

export const selectSendConfig = createSelector(
  selectSendEmailState,
  (state) => state?.sendConfig
);

export const selectParticipantsLabelValueTemplate = createSelector(
  selectSendConfig,
  (state) => state?.display_field || ""
);
export const selectSaveTemplateAsState = createSelector(
  selectSendEmailState,
  (state) => state?.saveTemplateAsState
);

export const selectSaveTemplateAsErrorState = createSelector(
  selectSaveTemplateAsState,
  (state) => state?.error
);

export const selectTemplateWorkflow = createSelector(
  selectCurrentTemplateDetail,
  (state) => state?.data.workflow || ""
);

export const selectFormToBeSaved = createSelector(
  selectSendEmailState,
  (state) => state.tempForm
);
export const selectFormToBeSavedError = createSelector(
  selectSendEmailState,
  (state) => state.tempFormErrors
);

export const selectIsNewTemplateMode = createSelector(
  selectSendEmailState,
  (state) => state.isNewTemplateMode
);

export const selectIsLoadingParticipants = createSelector(
  selectSendEmailState,
  (state) => state.isLoadingParticipants
);

export const selectHasTemplateExtraInfo = createSelector(
  selectCurrentTemplateDetail,
  (currentTemplate) => {
    if (currentTemplate) {
      return true;
      //   return !!(
      //     currentTemplate.data?.cc ||
      //     currentTemplate.data?.bcc ||
      //     currentTemplate.data?.documents.length
      //   );
    }

    return false;
  }
);

export const selectTemplateExtraInfo = createSelector(
  selectCurrentTemplateDetail,
  (currentTemplate) => {
    if (currentTemplate) {
      return {
        cc: currentTemplate.data?.cc ?? "",
        bcc: currentTemplate.data?.bcc ?? "",
        attachments:
          currentTemplate.data?.documents
            ?.map((doc) => doc.description)
            .join(", ") ?? [],
      };
    }

    return {
      cc: "",
      bcc: "",
      attachments: "",
    };
  }
);
