<div class="wrapper">
  <div class="header">
    <div class="header__title">
      <h1>Help Center</h1>
    </div>
    <div class="close">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="content">
    <ng-container *ngIf="vm$ | async as vm">      
      <html-content
        [apiUrl]="vm.targetUrl"
        [displayRefreshButton]="false"
      ></html-content>
    </ng-container>
  </div>
</div>
