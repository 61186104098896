<div
  class="kt-portlet"
  [ngClass]="{ isChildForm: isSubForm, blockUI: blockUI$ | async }"
  [ngStyle]="styleOverrides$ | async"
  *ngIf="(isLoading$ | async) === false; else loading"
>
  <cb-form-header
    *ngIf="!modalMode; else modalHeader"
    (onSubmit)="onSubmitHandler($event)"
    (onExitEvent)="onExitEventHandler($event)"
    (onDeleteEvent)="onDeleteEventHandler($event)"
    (onBreadcrumbClick)="onBreadcrumbClickEventHandler($event)"
    [customActionButtons]="customActionButtons"
    (onRefreshForm)="onRefreshForm()"
  ></cb-form-header>
  <ng-template #modalHeader>
    <app-modal-header
      [config]="modalMode"
      (onSubmit)="onModalSubmitEventHandler($event)"
      (onExitEvent)="onExitEventHandler($event)"
      (onRefresh)="onRefreshForm()"
    >
    </app-modal-header>
  </ng-template>
  <div
    class="wrapper"
    [ngClass]="{
      'no-y-padding': (isSubFormShown$ | async) === true,
      isModal: modalMode !== null
    }"
  >
    <ng-container *ngIf="htmlHeader$ | async as header">
      <ng-container *ngIf="header.show === true">
        <div
          class="html_header-container"
          [ngClass]="{ subFormIsShown: (isSubFormShown$ | async) === true }"
          [ngStyle]="{
            minWidth: header.width,
            maxWidth: header.width,
            height: header.height
          }"
        >
          <html-content
            [apiUrl]="header.apiUrl"
            [displayRefreshButton]="header.shouldDisplayRefreshButton"
          >
          </html-content></div
      ></ng-container>
    </ng-container>
    <div class="main">
      <div class="main-wrapper-form">
        <ng-container *ngIf="htmlSidebar$ | async as sidebar">
          <ng-container *ngIf="sidebar.show === true">
            <div
              class="html_sidebar-container"
              [ngStyle]="{ minWidth: sidebar.width, maxWidth: sidebar.width }"
            >
              <html-content
                [apiUrl]="sidebar.apiUrl"
                [displayRefreshButton]="sidebar.shouldDisplayRefreshButton"
              >
              </html-content></div
          ></ng-container>
          <!-- [ngStyle]="{ width: 'calc(100% - ' + sidebar.width + ')' }" -->
          <div
            class="kt-portlet__body"
            [ngStyle]="{
              maxWidth: 'calc(100% - 16px - ' + sidebar.width + ')',
              minWidth: 'calc(100% - 16px - ' + sidebar.width + ')'
            }"
          >
            <!-- *ngIf="isWizard === false; else wizard" -->
            <cb-dynamic-tabs-builder
              [tabs]="allTabs$ | async"
              [forms]="shellForm"
              [tabErrors]="tabErrors$ | async"
              [asyncErrors]="asyncErrors$ | async"
              [resetSelectedTab]="resetSelectedTab$ | async"
              (tabChangeEvent)="onTabChangeHandler($event)"
            ></cb-dynamic-tabs-builder>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div
    style="
      width: 100%;
      height: 100%;
      display: grid;
      place-content: center;
      min-height: 300px;
    "
  >
    <div class="kt-portlet__body-progress">
      <cb-loading></cb-loading>
    </div>
  </div>
</ng-template>

<!-- <ng-template #wizard>
  <cb-wizard-builder
    (closeFormEvent)="onExitEventHandler($event)"
  ></cb-wizard-builder>
</ng-template> -->
