import { BaseModel } from '../../base';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';
import { VirtualGarage } from './virtual-garage.model';

export class UserConfig extends BaseModel {
    id: number;
    dashboard_config: string;
    testdata_config: boolean;
    lang_config: string;
    is_testdata: number;
}
