export enum FormInputTypes {
  Input = "input",
  Url = "url",
  SingleSelect = "single_select",
  MultipleSelect = "multiple_select",
  Checkbox = "checkbox",
  Html = "html",
  ColorPicker = "color_picker",
  Date = "date",
  DateTime = "datetime",
  Time = "time",
  Lookup = "lookup",
  TextArea = "textarea",
  Hidden = "hidden",
  Password = 'password',
  RadioGroup = 'radio_group',
  Label = 'label',
}
