import { EventEmitter, Injectable } from '@angular/core';
import * as _ from "lodash";

@Injectable({
    providedIn: 'root'
})
export class LoadingIndicatorService {

    private _idCtr = 0;
    private _loading: string[] = [];

    allRequestFinished: EventEmitter<void> = new EventEmitter();

    get loading(): boolean {
        return this._loading.length > 0;
    }

    onRequestStarted(): string {
        const id = `id_${++this._idCtr}`;
        this._loading.push(id);
        return id;
    }

    onRequestFinished(name: string): void {
        _.remove(this._loading, item => item == name);

        if (this._loading.length == 0) this.allRequestFinished.emit();
    }

    debug() {
        return this._loading;
    }
}