<kt-portlet-header
  [title]="message$ | async"
  [class]="'kt-portlet__head--lg text-nowrap'"
>
  <ng-container
    ktPortletAlerts
    *ngIf="(displayWarningMessage$ | async) && form && (isFormDirty$ | async)"
  >
    <div class="flex-grow-1 m-3 d-flex align-items-center">
      <div class="align-items-center d-flex bg-warning p-1 px-3 rounded">
        <i class="la la-warning"></i>
        <span>{{ "TRANSLATOR.WARNING_UNSAVED_CHANGES" | translate }}</span>
      </div>
    </div>
  </ng-container>
  <ng-container ktPortletTools>
    <div class="action-button-container">
      <!-- <ng-container *ngIf="customActionButtons">
        <ng-container
          *ngTemplateOutlet="customActionButtons; context: { $implicit: data }"
        >
        </ng-container>
      </ng-container> -->
      <ng-container>
        <button
          type="button"
          *ngIf="displayHelpButton$ | async"
          mat-icon-button
          (click)="handleInfoToggle()"
          class="info-toggle-btn text-secondary"
          [matTooltip]="'Show Info' | translate"
        >
          <i class="far fa-question-circle text-info info-help-icon"></i>
        </button>
        <a
          (click)="close()"
          color="warn"
          mat-flat-button
          matTooltip="{{ 'COMMON.CLOSE' | translate }}"
          [attr.data-pgm-formbutton]="'formbutton_back'"
        >
          <i class="fa-solid fa-circle-x"></i>
          <span class="kt-hidden-mobile">
            {{ "COMMON.CLOSE" | translate }}
          </span>
        </a>
        <ng-container *ngFor="let button of saveButtons$ | async">
          <a
            [class]="button.classList"
            color="success"
            mat-flat-button
            (click)="
              submit({
                shouldExit: button.shouldExit,
                shouldCreateNew: button.shouldCreateNew
              })
            "
            [ngClass]="{ disabled: (isFormSaving$ | async) === true }"
            [attr.data-pgm-formbutton]="button.testAttribute"
          >
            <span *ngIf="isFormSaving$ | async; else defaultText">
              <i class="fa fa-circle-o-notch fa-spin p-0"></i> Saving...
            </span>
            <ng-template #defaultText>
              <i [class]="button.icon"></i>
              <span class="kt-hidden-mobile">
                {{ button.label | translate }}
              </span>
            </ng-template>
          </a>
        </ng-container>
        <!-- <app-process-buttons
            (onDelete)="deleteItem()"
            (onSubmit)="submit($event)"
          ></app-process-buttons> -->
      </ng-container>
    </div>
  </ng-container>
</kt-portlet-header>
