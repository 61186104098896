import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dynamicComponentWidth",
})
export class DynamicComponentWidthPipe implements PipeTransform {
  transform(width: any, ...args: any[]): any {    
    if (typeof width === "string") {
      return width;
    }
    if (typeof width === "number") {
      return `col-md-${width}`;
    }
    return "col-md-12";
  }
}
