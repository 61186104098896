import { Action, createReducer, on } from "@ngrx/store";
import { AppState } from "../../../core/store/reducers/app.reducers";
import { ApiProgressStatus } from "../../../shared/data/models";
import * as sendEmailActions from "./send-email.actions";
import { SendEmailState } from "./send-email.model";

export const SEND_EMAIL_FEATURE_NAME = "send-email";
export const initialState: SendEmailState = {
  currentTemplateId: null,
  currentThemeId: null,
  isLoading: ApiProgressStatus.Initial,
  templates: [],
  themes: [],
  templateTypes: [],
  templateDetails: null,
  templateDetailsSelectedLanguage: "en",
  participants: [],
  sendConfig: null,
  emailSent: false,
  saveTemplateAsState: {
    error: [],
    status: -1,
  },
  tempForm: null,
  tempFormErrors: [],
  isNewTemplateMode: false,
  isLoadingParticipants: false,
  isFormDirty: false,
  isFormValid: false,
};

const reducer = createReducer(
  initialState,
  on(sendEmailActions.setFormState, (state, { state: isValid }) => {
    return {
      ...state,
      isFormValid: isValid,
    };
  }),

  on(sendEmailActions.setFormState, (state) => {
    return {
      ...state,
      isFormDirty: true,
    };
  }),
  on(sendEmailActions.getParticipantsFromServer, (state) => {
    return {
      ...state,
      isLoadingParticipants: true,
    };
  }),
  on(sendEmailActions.getParticipantsFromServerFailure, (state) => {
    return {
      ...state,
      isLoadingParticipants: false,
    };
  }),
  on(
    sendEmailActions.getParticipantsFromServerSuccess,
    (state, { participants }) => {
      return {
        ...state,
        participants: participants?.map((p) => ({ ...p, sent: 0 })) ?? [],
        isLoadingParticipants: false,
      };
    }
  ),
  on(sendEmailActions.removeTranslation, (state, { lang }) => {
    return {
      ...state,
      templateDetails: {
        ...state.templateDetails,
        data: {
          ...state.templateDetails.data,
          translations: state.templateDetails.data.translations.filter(
            (t) => t.lang !== lang
          ),
        },
      },
      tempFormErrors: state.tempFormErrors.filter((e) => e !== lang),
      templateDetailsSelectedLanguage: "en",
    };
  }),
  on(sendEmailActions.newTemplateMode, (state) => {
    return {
      ...state,
      isNewTemplateMode: true,
      currentTemplateId: -1,
      templateDetails: {
        config: {},
        data: {
          workflow: state.sendConfig?.callbacks?.newtemplate_workflow || "",
          translations: [
            {
              lang: "en",
              subject: "",
              template: "",
            },
          ],
        },
      },
      tempForm: {
        lang: "en",
        subject: "",
        template: "",
      },
    };
  }),
  on(sendEmailActions.addTranslation, (state, { lang }) => {
    return {
      ...state,
      templateDetails: {
        ...state.templateDetails,
        data: {
          ...state.templateDetails.data,
          translations: [
            ...state.tempForm,
            { lang: lang, subject: "", template: "" },
          ],
        },
      },
      templateDetailsSelectedLanguage: lang,
    };
  }),
  on(sendEmailActions.saveTemplateAsFailure, (state, { error }) => {
    let customError = [];
    if (!Array.isArray(error)) {
      customError.push(error);
    } else {
      error.map((e) => customError.push(e));
    }
    return {
      ...state,
      isLoading: ApiProgressStatus.DoneWithError,
      saveTemplateAsState: {
        status: 0,
        error: customError,
      },
    };
  }),
  on(sendEmailActions.saveTemplateAsSuccess, (state, { template }) => {
    return {
      ...state,
      isLoading: ApiProgressStatus.Done,
      templates: [...state.templates, template],
      currentTemplateId: template.id,
      saveTemplateAsState: {
        status: -1,
        error: [],
      },
      tempFormErrors: [],
      isNewTemplateMode: false,
    };
  }),
  on(sendEmailActions.saveTemplateSuccess, (state, { template }) => {
    return {
      ...state,
      isLoading: ApiProgressStatus.Done,
      templates: state.templates.map((t) => {
        if (t.id === template.id) {
          return {
            ...template,
          };
        }
        return t;
      }),
      tempFormErrors: [],
    };
  }),
  on(sendEmailActions.sendEmailSuccess, (state, { response }) => {
    let emailNotSendIds = JSON.parse(response.mailsNotSent);
    return {
      ...state,
      isLoading: ApiProgressStatus.Done,
      participants: state.participants.map((p) => {
        return {
          ...p,
          sent: emailNotSendIds.includes(p.id) ? -1 : 1, // 0 initial, -1 not sent, 1 sent
        };
      }),
      emailSent: true,
      tempFormErrors: [],
    };
  }),
  on(sendEmailActions.sendTestEmailSuccess, (state, { response }) => {
    return {
      ...state,
      isLoading: ApiProgressStatus.Done,
    };
  }),
  on(sendEmailActions.updateFormErrors, (state, { errors }) => {
    return {
      ...state,
      tempFormErrors: errors,
      templateDetailsSelectedLanguage: errors[0],
    };
  }),
  on(sendEmailActions.sendEmail, (state) => {
    return {
      ...state,
      isLoading: ApiProgressStatus.InProgress,
    };
  }),
  on(sendEmailActions.sendTestEmail, (state) => {
    return {
      ...state,
      isLoading: ApiProgressStatus.InProgress,
    };
  }),
  on(sendEmailActions.removeParticipant, (state, { participant }) => {
    if (state.participants.length === 1) {
      return { ...state };
    }

    return {
      ...state,
      participants: state.participants.filter((p) => p.id !== participant.id),
    };
  }),
  on(sendEmailActions.updateTempForm, (state, { templates }) => {
    return {
      ...state,
      tempForm: templates,
    };
  }),
  on(
    sendEmailActions.updateSelectedTemplateDetails,
    (state, { templates, newLang }) => {
      return {
        ...state,
        templateDetails: {
          ...state.templateDetails,
          data: {
            ...state.templateDetails.data,
            translations: [...templates],
          },
        },
        templateDetailsSelectedLanguage: newLang,
      };
    }
  ),
  on(sendEmailActions.updateParticipants, (state, { participants }) => {
    return {
      ...state,
      participants,
    };
  }),
  on(
    sendEmailActions.initiateSendEmailDialog,
    (state, { participants, config }) => {
      return {
        ...state,
        sendConfig: config,
        participants: participants
          ? participants.map((p) => ({ ...p, sent: 0 }))
          : null,
      };
    }
  ),
  on(sendEmailActions.updateTemplateSelectedLanguage, (state, { lang }) => {
    return {
      ...state,
      templateDetailsSelectedLanguage: lang,
    };
  }),
  on(
    sendEmailActions.getSelectedTemplateDetailSuccess,
    (state, { templateDetails }) => {
      return {
        ...state,
        templateDetails,
        templateDetailsSelectedLanguage: "en",
      };
    }
  ),
  on(sendEmailActions.resetSelectedTemplateDetail, (state) => {
    return {
      ...state,
      templateDetails: null,
    };
  }),
  on(sendEmailActions.updateSelectedTemplate, (state, { id }) => {
    return {
      ...state,
      currentTemplateId: id,
    };
  }),
  on(sendEmailActions.updateSelectedTheme, (state, { id }) => {
    return {
      ...state,
      currentThemeId: id,
    };
  }),
  on(sendEmailActions.getTemplateTypesSuccess, (state, { templateTypes }) => {
    return {
      ...state,
      templateTypes: templateTypes,
      isLoading: ApiProgressStatus.Done,
    };
  }),
  on(sendEmailActions.getTemplatesSuccess, (state, { templates }) => {
    return {
      ...state,
      templates: templates,
      isLoading: ApiProgressStatus.Done,
    };
  }),
  on(sendEmailActions.getThemesSuccess, (state, { themes }) => {
    return {
      ...state,
      themes: themes,
      isLoading: ApiProgressStatus.Done,
    };
  }),
  on(sendEmailActions.getThemes, sendEmailActions.getTemplates, (state) => {
    return {
      ...state,
      isLoading: ApiProgressStatus.InProgress,
    };
  }),
  on(sendEmailActions.closeNewTemplateMode, (state) => {
    return {
      ...state,
      currentThemeId: state.currentThemeId,
      currentTemplateId: null,
      isLoading: ApiProgressStatus.Initial,
      saveTemplateAsState: {
        status: -1,
        error: [],
      },
      templateDetails: null,
      tempForm: null,
      tempFormErrors: [],
      isNewTemplateMode: false,
    };
  }),
  on(sendEmailActions.resetStore, (state) => {
    return {
      currentTemplateId: null,
      currentThemeId: null,
      isLoading: ApiProgressStatus.Initial,
      templates: state.templates,
      themes: state.themes,
      templateTypes: state.templateTypes,
      templateDetails: null,
      templateDetailsSelectedLanguage: "en",
      participants: [],
      sendConfig: null,
      emailSent: false,
      saveTemplateAsState: {
        status: -1,
        error: [],
      },
      tempForm: null,
      tempFormErrors: [],
      isNewTemplateMode: false,
      isLoadingParticipants: false,
      isFormDirty: false,
      isFormValid: false,
    };
  }),
  on(
    sendEmailActions.getThemesFailure,
    sendEmailActions.getTemplatesFailure,
    sendEmailActions.getSelectedTemplateDetailFailure,
    sendEmailActions.getTemplateTypesFailure,
    sendEmailActions.saveTemplateFailure,
    (state, { error }) => {
      return {
        ...state,
        isLoading: ApiProgressStatus.DoneWithError,
      };
    }
  )
);

export function sendEmailReducer(
  state: SendEmailState | undefined,
  action: Action
) {
  return reducer(state, action);
}

export interface State extends AppState {
  [SEND_EMAIL_FEATURE_NAME]: SendEmailState;
}
