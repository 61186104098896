import { environment_api } from "../../../environments/environment.api";

export class ApiConfig {
  static translationResourceURL(): string {
    return environment_api.api_url + "/i18n/translations";
  }
  static projectTemplatesURL(): string {
    return environment_api.api_url + "/pm/project-templates";
  }
  static ApiURL(): string {
    return environment_api.api_url;
  }
  static tasksURL(): string {
    return environment_api.api_url + "/pm/tasks";
  }
  static boardsURL(): string {
    return environment_api.api_url + "/pm/boards";
  }
  static projectsURL(): string {
    return environment_api.api_url + "/pm/projects";
  }
  static ApiLoginAuthTypeURL(): string {
    return environment_api.api_url + "/login/type";
  }
  static ApiLoginURL(): string {
    return environment_api.api_url + "/login";
  }
  static ApiSignupURL(): string {
    return environment_api.api_url + "/signup";
  }
  static ApiSendPasswordResetLink(): string {
    return environment_api.api_url + "/sendpasswordresetlink";
  }
  static ApiChangePasswordURL(): string {
    return environment_api.api_url + "/changepassword";
  }
  static ApiChangePasswordEmailURL(): string {
    return environment_api.api_url + "/changepasswordemail";
  }

  static ConfigURLGroupsUpdate(): string {
    return environment_api.api_url + "/config/system/groups/all";
  }

  static ConfigURL(): string {
    return environment_api.api_url + "/config/system";
  }

  static UserConfigURLGroupsUpdate(): string {
    return environment_api.api_url + "/config/user/groups/all";
  }

  static UserConfigURL(): string {
    return environment_api.api_url + "/config/user";
  }
  static InfoURL(): string {
    return environment_api.api_url + "/info";
  }
  static ActivityLogsUrl(): string {
    return environment_api.api_url + "/activitylogs";
  }
  static I18nURL(): string {
    return environment_api.api_url + "/i18n";
  }
  static FormConfigURL(locale, formName): string {
    return environment_api.api_url + "/" + locale + "/form_config/" + formName;
  }
  static TableConfigURL(locale, tableName): string {
    return (
      environment_api.api_url + "/" + locale + "/table_config/" + tableName
    );
  }
  static TextConfigURL(locale, textName): string {
    return environment_api.api_url + "/" + locale + "/text_config/" + textName;
  }
  static MenuConfigURL(locale, menuName): string {    
    return environment_api.api_url + "/" + locale + "/menu_config/" + menuName;
  }
  static TablePaginationConfigURL(locale, paginationName): string {
    return (
      environment_api.api_url +
      "/" +
      locale +
      "/pagination_config/" +
      paginationName
    );
  }

  static CartURL(): string {
    return environment_api.api_url + "/cart";
  }

  static PermissionsURL(): string {
    return environment_api.api_url + "/permissions";
  }
  static ProfileURL(): string {
    return environment_api.api_url + "/me";
  }

  static ChangePasswordURL(): string {
    return environment_api.api_url + "/password/change";
  }
  static UsersURL(): string {
    return environment_api.api_url + "/users";
  }
  static UserProfileInfoURL(): string {
    return environment_api.api_url + "/user-profile-info";
  }
  static UsersImageURL(): string {
    return environment_api.api_url + "/users/uploadImage";
  }
  static UserTypesURL(): string {
    return environment_api.api_url + "/usertypes";
  }

  static UserVirtualGarageURL(): string {
    return environment_api.api_url + "/uservirtualgarage";
  }
  static UserUserTypesURL(): string {
    return environment_api.api_url + "/userusertypes";
  }
  static RolesURL(): string {
    return environment_api.api_url + "/roles";
  }
  static TrackdayItemsURL(): string {
    return environment_api.api_url + "/trackdayitems";
  }

  static SeasonTrackdayItemsURL(): string {
    return environment_api.api_url + "/seasonevents";
  }
  static TrackdaysURL(): string {
    return environment_api.api_url + "/trackdays";
  }
  static TrackdayItemTypesURL(): string {
    return environment_api.api_url + "/trackdayitemtypes";
  }
  static ServiceTypesURL(): string {
    return environment_api.api_url + "/servicetype";
  }
  static ServiceProviderURL(): string {
    return environment_api.api_url + "/serviceprovider";
  }
  static ServiceProviderTypesURL(): string {
    return environment_api.api_url + "/serviceprovidertype";
  }
  static FixedStartNumbersURL(): string {
    return environment_api.api_url + "/startnumberfixed";
  }
  static VehicleTypesURL(): string {
    return environment_api.api_url + "/vehicletypes";
  }
  static CurrenciesURL(): string {
    return environment_api.api_url + "/currencies";
  }
  static BookingTypesURL(): string {
    return environment_api.api_url + "/bookingstype";
  }
  static ParticipantStatuesesURL(): string {
    return environment_api.api_url + "/participantstatus";
  }
  static CompaniesURL(): string {
    return environment_api.api_url + "/companies";
  }
  static DynamicAttributesURL(): string {
    return environment_api.api_url + "/dynamicattributes";
  }
  static LaptimeURL(): string {
    return environment_api.api_url + "/laptimes";
  }
  static LaptimeLevelsURL(): string {
    return environment_api.api_url + "/laptime-levels";
  }
  static UsersInfoURL(): string {
    return environment_api.api_url + "/usersinfo";
  }
  static LocationURL(): string {
    return environment_api.api_url + "/locations";
  }
  static TrackdayBookingsUrl(): string {
    return environment_api.api_url + "/trackday-bookings";
  }

  static SeasonEventBookingsUrl(): string {
    return environment_api.api_url + "/season-event-bookings";
  }
  static TrackdayBookingsShortUrl(): string {
    return environment_api.api_url + "/trackday-bookings-short";
  }
  static StartnumberpoolTrackdayitemUrl(): string {
    return environment_api.api_url + "/startnumberpool/trackdayitem";
  }
  static TrackdayDirectbookingSnippetUrl(): string {
    return environment_api.api_url + "/snippet/directbooking";
  }

  static TranslatesUrl(): string {
    return environment_api.api_url + "/translates";
  }
  static DraftBookingsUrl(): string {
    return environment_api.api_url + "/draft-bookings";
  }
  static SeasonBookingsUrl(): string {
    return environment_api.api_url + "/seasonbookings";
  }
  static InvoicesUrl(): string {
    return environment_api.api_url + "/invoices";
  }

  static PaymentUrl(): string {
    return environment_api.api_url + "/payments";
  }
  static PricesUrl(): string {
    return environment_api.api_url + "/prices";
  }
  static LostServicesUrl(): string {
    return environment_api.api_url + "/lostservices";
  }
  static LostCouponsUrl(): string {
    return environment_api.api_url + "/lostcoupons";
  }
  static PricesTrashedInvoicesUrl(): string {
    return environment_api.api_url + "/lostprices";
  }
  static GroupBookingZombies(): string {
    return environment_api.api_url + "/group-booking-zombies";
  }
  static CouponItemsUrl(): string {
    return environment_api.api_url + "/couponitems";
  }
  static TrackdayBookingVehiclesUrl(): string {
    return environment_api.api_url + "/trackday-booking-vehicles";
  }
  static TrackdayGroupBookingsUrl(): string {
    return environment_api.api_url + "/trackday-group-bookings";
  }

  static TrackdaySeasonBookingsUrl(): string {
    return environment_api.api_url + "/trackday-season-bookings";
  }
  static StartNumberOrderTrackdayURL(): string {
    return environment_api.api_url + "/startnumbertrackdayitems";
  }
  static StartNumberParticipantsURL(): string {
    return environment_api.api_url + "/startnumberparticipants";
  }
  static StartNumberUnassignedParticipantsURL(): string {
    return environment_api.api_url + "/startnumberunassignedparticipants";
  }
  static StartNumberBulkDeleteURL(): string {
    return environment_api.api_url + "/startnumbertrackdayitems/delete";
  }
  static ParticipantsOrderTrackdayURL(): string {
    return environment_api.api_url + "/participantstrackdayitems";
  }
  static ParticipantsTrackdayItemsURL(): string {
    return environment_api.api_url + "/participantstrackdayitems";
  }
  static ParticipantsTrackdayItemsStartnumberURL(): string {
    return environment_api.api_url + "/participantstrackdayitems/startnumbers";
  }
  static StartNumberTrackdayItemsUpdateURL(): string {
    return environment_api.api_url + "/startnumbertrackdayitems/update";
  }
  static TrackdayItemsCancellationPricesURL(trackdayItemId): string {
    return (
      environment_api.api_url +
      `/trackdayitems/${trackdayItemId}/cancellation-prices`
    );
  }
  static TrackdayItemsDynamicPricesURL(trackdayItemId): string {
    return (
      environment_api.api_url +
      `/trackdayitems/${trackdayItemId}/dynamic-prices`
    );
  }
  static sendMailToParticipantURL(): string {
    return environment_api.api_url + "/participantstrackdayitems/sendMail";
  }
  static ServicesURL(): string {
    return environment_api.api_url + "/services";
  }
  static UnassignedServicesURL(): string {
    return environment_api.api_url + "/unassignedservices";
  }
  static AssignedServicesURL(): string {
    return environment_api.api_url + "/assignedservices";
  }
  static ServicesOrderTrackdayURL(): string {
    return environment_api.api_url + "/servicestrackdayitems";
  }
  static TrackdayItemServiceTypesURL(): string {
    return environment_api.api_url + "/trackdayitems-service-types";
  }
  static ApiTokenURL(): string {
    return environment_api.api_url + "/apitoken";
  }
  static ApiTokenExpiredURL(): string {
    return environment_api.api_url + "/token/istokenexpired";
  }
  static NewsletterURL(): string {
    return environment_api.api_url + "/newsletter";
  }

  static LicencesURL(): string {
    return environment_api.api_url + "/licences";
  }
  static UserAddressURL(): string {
    return environment_api.api_url + "/useraddress";
  }
  static CouponsURL(): string {
    return environment_api.api_url + "/coupons";
  }
  static CouponItemsURL(): string {
    return environment_api.api_url + "/couponitems";
  }
  static AdminStatsUsageURL(): string {
    return environment_api.api_url + "/admin/stats/usage";
  }
  static CouponsTrackdayItemsURL(): string {
    return environment_api.api_url + "/couponstrackdayitems";
  }
  static DownloadsURL(): string {
    return environment_api.api_url + "/downloads";
  }
  static MessagingURL(): string {
    return environment_api.api_url + "/messaging";
  }
  static PushnotificationsURL(): string {
    return environment_api.api_url + "/pushnotifications";
  }

  static VirtualGarageURL(): string {
    return environment_api.api_url + "/virtualgarage";
  }
  static StatisticsURL(): string {
    return environment_api.api_url + "/statistics";
  }
  static PermissionKeysURL(): string {
    return environment_api.api_url + "/permissionkeys";
  }
  static PermissionPresetsURL(): string {
    return environment_api.api_url + "/permissionpresets";
  }
  static PermissionGroupsURL(): string {
    return environment_api.api_url + "/permissiongroups";
  }
  static PermissionRolesURL(): string {
    return environment_api.api_url + "/permissionroles";
  }
  static PermissionRoutesURL(): string {
    return environment_api.api_url + "/permissionroutes";
  }

  // Circuit-Booking
  static VariantsURL(): string {
    return environment_api.api_url + "/variants";
  }
  static getRelatedVariant(): string {
    return environment_api.api_url + "/variants/related";
  }
  static LocationsURL(): string {
    return environment_api.api_url + "/locations";
  }
  static LocationInfoURL(): string {
    return environment_api.api_url + "/locationinfo";
  }

  static MotoDBGenerationsURL(): string {
    return environment_api.api_url + "/generations";
  }
  static MotoDBMakesURL(): string {
    return environment_api.api_url + "/makes";
  }
  static MotoDBModelsURL(): string {
    return environment_api.api_url + "/models";
  }
  static MotoDBSeriesURL(): string {
    return environment_api.api_url + "/series";
  }
  static MotoDBTrimsURL(): string {
    return environment_api.api_url + "/trims";
  }
  static MotoDBTrimsAllURL(): string {
    return environment_api.api_url + "/trimsall";
  }
  static MotoDBTypesURL(): string {
    return environment_api.api_url + "/types";
  }
  static MotoDBURL(): string {
    return environment_api.api_url + "/motodb";
  }

  static UserConfigUrl(): string {
    return environment_api.api_url + "/usersconfig";
  }
  static MyConfigUrl(): string {
    return environment_api.api_url + "/myconfig";
  }
  static GenerateDocUrl(docType, name): string {
    return environment_api.api_url + "/generatedoc/" + docType + "/" + name;
  }
  static CalendarUrl(): string {
    return environment_api.api_url + "/calendar";
  }
  static CountryUrl(): string {
    return environment_api.api_url + "/countries";
  }
  static CancellationPricesTrackdayItemsDataChartURL(trackdayItemId): string {
    return (
      environment_api.api_url +
      "/trackdayitems/" +
      trackdayItemId +
      "/cancellation-prices-data-chart"
    );
  }
  static DynamicPricesTrackdayItemsDataChartURL(trackdayItemId): string {
    return (
      environment_api.api_url +
      "/trackdayitems/" +
      trackdayItemId +
      "/dynamic-prices-data-chart"
    );
  }
  static PresetCancellationPricesURL(): string {
    return environment_api.api_url + "/preset-cancellation-prices";
  }
  static PresetDynamicPricesURL(): string {
    return environment_api.api_url + "/preset-dynamic-prices";
  }
  static LoadPresetCancellationPricesDataURL(trackdayItemId, replace): string {
    return (
      environment_api.api_url +
      "/trackdayitems/" +
      trackdayItemId +
      "/load-preset-cancellation-prices-data?replace=" +
      (replace ? "1" : "0")
    );
  }
  static LoadPresetDynamicPricesDataURL(trackdayItemId, replace): string {
    return (
      environment_api.api_url +
      "/trackdayitems/" +
      trackdayItemId +
      "/load-preset-dynamic-prices-data?replace=" +
      (replace ? "1" : "0")
    );
  }
  static MediaLibraryUrl(): string {
    return environment_api.api_url + "/media-library";
  }

  static EmailConfigURL(): string {
    return environment_api.api_url + "/config/email-config";
  }

  static DayliteConfigURL(): string {
    return environment_api.api_url + "/config/daylite-config";
  }

  static EmailTemplatesURL(): string {
    return environment_api.api_url + "/config/email-templates";
  }
  static EmailWorkflowsURL(): string {
    return environment_api.api_url + "/config/email-events";
  }
  static EmailThemesURL(): string {
    return environment_api.api_url + "/config/email-themes";
  }
  static EventCronjobsURL(): string {
    return environment_api.api_url + "/config/event-cronjobs";
  }
  static PDFTemplatesURL(): string {
    return environment_api.api_url + "/config/pdf-templates";
  }
  static PDFThemesURL(): string {
    return environment_api.api_url + "/config/pdf-themes";
  }
  static PDFWorkflowsURL(): string {
    return environment_api.api_url + "/config/pdf-events";
  }
  static FrontendConfigURL(): string {
    return environment_api.api_url + "/config/frontend";
  }

  static PrivateLocationsURL(): string {
    return environment_api.api_url + "/config/private-locations";
  }

  static LaptimeImportsURL(): string {
    return environment_api.api_url + "/laptime-imports";
  }

  static SearchURL(): string {
    return environment_api.api_url + "/search";
  }

  static WsUrl(): string {
    return environment_api.api_url + "/wsconfig";
  }

  static SeasonUrl(): string {
    return environment_api.api_url + "/season";
  }
  static TeamsUrl(): string {
    return environment_api.api_url + "/team";
  }
  static InvitationsUrl(): string {
    return environment_api.api_url + "/invitations";
  }

  static TeammemberUrl(): string {
    return environment_api.api_url + "/teammember";
  }
  static TeamvehiclesUrl(): string {
    return environment_api.api_url + "/teamvehicles";
  }
  static VehicleClassesUrl(): string {
    return environment_api.api_url + "/vehicleclasses";
  }
  static ChampionshipUrl(): string {
    return environment_api.api_url + "/championship";
  }

  static TeamStatusUrl(): string {
    return environment_api.api_url + "/teamstatus";
  }
  static TeamFunctionUrl(): string {
    return environment_api.api_url + "/teamfunction";
  }

  static SaveExportTemplateUrl(): string {
    return environment_api.api_url + "/export-config";
  }
}
