import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  inject,
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { sendEmailActions, sendEmailSelectors } from "../../store";
import { selectSaveTemplateAsErrorState } from "../../store/send-email.selector";

export interface TSaveAsComponent {
  title: string;
}

@Component({
  selector: "app-save-as",
  templateUrl: "./save-as.component.html",
  styleUrls: ["./save-as.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveAsComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  store = inject(Store);
  templateTypes$ = this.store.pipe(
    select(sendEmailSelectors.selectTemplateTypes)
  );

  saveTemplateAsErrorState$ = this.store.pipe(
    select(selectSaveTemplateAsErrorState)
  );
  constructor(
    public dialogRef: MatDialogRef<SaveAsComponent>,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: TSaveAsComponent
  ) {
    this.store.dispatch(sendEmailActions.nGetTemplateTypes());
  }

  templateForm = this.fb.group({
    templateTypeId: [null, [Validators.required]],
    templateName: ["", [Validators.required]],
    templateDescription: ["", [Validators.required]],
  });
  ngOnInit(): void {
    this.saveTemplateAsErrorState$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((errors: any) => {
        if (errors.length > 0) {
          errors.map((e) => {
            let targetEl = Object.keys(e)[0];
            if (!!targetEl) {
              this.templateForm
                .get(targetEl)
                .setErrors({ invalid: e[targetEl] }, { emitEvent: true });
              this.templateForm.get(targetEl).markAsTouched();
              this.cdRef.markForCheck();
            }
          });
        }
      });
  }

  saveTemplateAs() {
    if (this.templateForm.valid) {
      this.store.dispatch(
        sendEmailActions.saveTemplateAs({
          ...this.templateForm.getRawValue(),
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
