// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../core/store/reducers/app.reducers';
import { ApiConfig } from "../../../../core/config/api.config";
import { Validators } from "@angular/forms";
import { Injector } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
// Auth

@Component({
    selector: 'statistics-table',
    templateUrl: './statistics-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticsTableComponent implements OnInit {
    columns = [];

    searchableColumns = ['id', 'name'];
    @Input() apiUrl: string;

    createButtonLabel = 'Create Statistics';
    readonly = true;
    alwaysShowCheckboxes = true;

     

    private dialogData;

    constructor(
        private injector: Injector,
        private store: Store<AppState>,
        private router: Router) {

        this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    }


    ngOnInit() {
        if (this.dialogData) {
            if (this.dialogData.apiUrl) this.apiUrl = this.dialogData.apiUrl;
            if (this.dialogData.showAddBtn !== undefined) this.readonly = this.dialogData.readonly;
        }
        if (!this.apiUrl) this.apiUrl = ApiConfig.StatisticsURL();
    }
}
