import { BaseModel } from '../../base';

export class Role extends BaseModel {
    id: number;
    role_name: string;
    is_testdata: number;
    permissions: number[];
    isCoreRole = false;

    clear(): void {
        this.id = undefined;
        this.role_name = '';
        this.is_testdata = 0;
        this.permissions = [];
        this.isCoreRole = false;
	}
}
