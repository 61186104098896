import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { get, isArray, set } from "lodash";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { take, tap } from "rxjs/operators";
import { environment_api } from "../../../environments/environment.api";
import { LocalDatePipe, QueryResultsModel } from "../../core/base";
import { ApiConfig } from "../../core/config/api.config";
import { CommonService } from "../../services/common.service";
import { ITab, WizardTab } from "../../shared/data/models";
import { GenericModalComponent } from "./components/generic-modal/generic-modal.component";
import { CUSTOM_VALIDATIONS } from "./types/customValidations.type";
import { FormInputTypes } from "./types/form-input-types.type";
import { ProcessButton } from "../../shared/data/models";
import { validateGreaterEqualThan } from "./validators/greaterThan.validator";
import { validateLessEqualThan } from "./validators/lessThan.validator";
import { validateMax, validateMin } from "./validators/minMax.validator";
import { serverValidator } from "./validators/server.validator";
import { ERR_FORM_100010 } from "../../error-list";
import { contextMenuButtonParser } from "../context-menu/utils/helper";

export type FORM_MODE = "EDIT" | "CREATE";
@Injectable()
export class FormConfigService {
  private readonly _tabs = new BehaviorSubject<ITab[]>([]);
  readonly tabs$ = this._tabs.asObservable();

  private readonly _wizardTabs = new BehaviorSubject<WizardTab[]>([]);
  readonly wizardTabs$ = this._wizardTabs.asObservable();

  private readonly _configFromServer = new BehaviorSubject<any>({});
  readonly configFromServer$ = this._configFromServer.asObservable();

  private readonly _translations = new BehaviorSubject<any[]>([]);
  readonly translations$ = this._translations.asObservable();

  private readonly _processButtons = new BehaviorSubject<any[]>([]);
  readonly processButtons$ = this._processButtons.asObservable();

  private readonly _form = new BehaviorSubject<UntypedFormGroup>(null);
  readonly form$ = this._form.asObservable();

  private readonly _formData = new BehaviorSubject<any>(null);
  readonly formData$ = this._formData.asObservable();

  private readonly _dependencies = new BehaviorSubject<any>(null);
  readonly dependencies$ = this._dependencies.asObservable();

  private readonly _translationLangs = new BehaviorSubject<any>(null);
  readonly translationLangs$ = this._translationLangs.asObservable();

  // true if `this` is displaying a sub form
  private readonly _isSubFormShown = new BehaviorSubject<boolean>(false);
  readonly isSubFormShown$ = this._isSubFormShown.asObservable();

  // true if `this` is itself a subform
  private readonly _isSubForm = new BehaviorSubject<boolean>(false);
  readonly isSubForm$ = this._isSubForm.asObservable();

  private readonly _configLang = new BehaviorSubject<string>("");
  readonly configLang$ = this._configLang.asObservable();

  private readonly _isWizard = new BehaviorSubject<boolean>(false);
  readonly isWizard$ = this._isWizard.asObservable();

  // this is used to store the form values when initially it was created
  private readonly _formInitialValues = new BehaviorSubject<any>({});

  private readonly _formMode = new BehaviorSubject<FORM_MODE>("CREATE");
  readonly formMode$ = this._formMode.asObservable();

  private readonly _formOptions = new BehaviorSubject<any>({});
  readonly _formOptions$ = this._formOptions.asObservable();

  private readonly _htmlHeader = new BehaviorSubject<any>(null);
  readonly htmlHeader$ = this._htmlHeader.asObservable();

  private readonly _htmlSidebar = new BehaviorSubject<any>(null);
  readonly htmlSidebar$ = this._htmlSidebar.asObservable();

  private readonly _infoText = new BehaviorSubject<any>(null);
  readonly infoText$ = this._infoText.asObservable();

  public updateFields$ = new Subject<any>();
  public updateFieldVisibility$ = new Subject<any>();
  public updateTabVisibility$ = new Subject<any>();
  public forceRefreshTab$ = new Subject<string>();
  // this is used to show the public blockUI when the form is loading the latest values for the fields
  public blockUI$ = new BehaviorSubject<boolean>(false);
  public styleOverrides$ = new BehaviorSubject<Record<string, string>>({});

  private _apiUrl: string; // this is the url of the current page config file
  private _listUrl: string; // this is the url which the page should redirect to (table)
  private _formName: string;
  private _id: string;
  private _flattenTabs = [];
  private _isStickyTabForm = true;
  private _customValidatorsReferences = {}; // this keeps the custom validators function references, so we can only remove the server errors when the field is valid
  private readonly _formInitialData = new BehaviorSubject({});
  readonly formInitialData$ = this._formInitialData.asObservable();

  destroyed$ = new Subject();

  constructor(
    private translateService: TranslateService,
    private localDate: LocalDatePipe,
    private http: HttpClient,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {}

  processCallback(button: ProcessButton, value?: any) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    let httpOptions = {};
    let params = new HttpParams();

    if (button.callback.method === "POST") {
      if (value) {
        params = params.append(button.confirm.paramType, value);
        httpOptions = {
          body: JSON.stringify({
            [button.confirm.paramName]: value,
            selected: this.id,
          }),
        };
      }
    }
    params = params.append("selected", this.id);
    params = params.append("is_testdata", localStorage.getItem("is_testdata"));
    httpOptions = {
      params,
    };

    let callbackUrl = `${environment_api.api_url}${this.parsePlaceholders(
      button.callback.url
    )}`;

    return this.http.request(button.callback.method, callbackUrl, httpOptions);
  }

  // this function is only used for form wizard,
  // this patches the wizard form with data
  patchWizardFormWithData(input, field = null) {
    let data = { ...this.formData };
    if (field) {
      let valueKey = field.lookup.value_field || "id";
      let mapKey = field.lookup.map_to_data;
      //   ...input,
      data = {
        ...data,
        [mapKey]: { ...input },
        [field.name]: input[valueKey],
      };
    }
    this.formData = data;
    data = { ...data, ...data._parent };
    Object.keys(this.form.controls).forEach((control: string) => {
      const typedControl: AbstractControl = this.form.controls[control];
      Object.keys((typedControl as UntypedFormGroup).controls).map(
        (formField: string) => {
          (typedControl as UntypedFormGroup).controls[formField].setValue(
            get(data, formField) ||
              get(input, formField) ||
              this.parsePlaceholders(
                (typedControl as UntypedFormGroup).controls[formField].value
              )
          );
        }
      );
    });
  }
  patchFormDataWithLookupData(input, field = null) {
    let data = { ...this.formData };
    if (field) {
      let valueKey = field.lookup.value_field || "id";
      let mapKey = field.lookup.map_to_data;
      //   ...input,
      data = {
        ...data,
        [mapKey]: { ...input },
        [field.name]: input[valueKey],
      };
      this.updateFormData(data);
      this.updateFormConfigs(input, field);
    }
  }

  checkPlaceholderExists(evalStr: string, placeholder: string) {
    if (!placeholder || !evalStr) return false;

    let exists = false;
    if (placeholder.indexOf(".") < 0) {
      exists =
        typeof evalStr == "string" && evalStr.indexOf(`{${placeholder}}`) >= 0;
    } else {
      exists =
        typeof evalStr == "string" &&
        (evalStr.indexOf(`{${placeholder}`) >= 0 ||
          evalStr.indexOf(`${placeholder}`) == 0);
    }
    return exists;
  }
  updateFormConfigs(value, field) {
    let mapKey = field?.dynamic_options?.map_to_data || null;
    if (field?.lookup) {
      mapKey = field?.lookup?.map_to_data || null;
      const formElement = this.getFormElement(field.name);
      const valueKey = field.lookup.value_field || "id";
      const labelKey = field.lookup.label_field || valueKey;
      formElement.setValue(value[valueKey]);
    } else {
      set(this.formData, field.name, value);
    }
    this.checkTabVisibilityStatus(field, value);
    this.formOnChangeHandleDynamicOptions(value, field, mapKey);
    if (this.isWizard) {
      this.wizardTabs.forEach((tab) => {
        if (tab.fields) {
          tab.fields.forEach((f) => {
            // this.formOnChangeHandleDisableState(element, f);
            const formElement = this.getFormElement(f.name);
            this.formOnChangeHandleValues(formElement, field, f, mapKey);
          });
        }
      });
    }
    // since we have a portlet type, and it can contain forms inside, for this case,
    // we use the flatten version
    this._flattenTabs.forEach((tab) => {
      if (Array.isArray(tab.content)) {
        tab.content.forEach((f) => {
          const formElement = this.getFormElement(f.name);
          const validations = this.checkFormFieldForValidations(f);
          if (validations.length) {
            this.updateFieldValidators(formElement, validations);
          }
          this.formOnChangeHandleValues(formElement, field, f, mapKey);
        });
      }
    });
    this.checkForStyleOverrides();
  }

  formOnChangeHandleValues(
    formElement: UntypedFormControl,
    sourceField: any,
    targetField: any,
    mapKey: any
  ) {
    if (sourceField.name === targetField.name) {
      return;
    }
    const toggleDisabled =
      this.checkPlaceholderExists(targetField?.disabled, sourceField.name) ||
      this.checkPlaceholderExists(targetField?.disabled, `${mapKey}.`);

    const toggleVisible =
      this.checkPlaceholderExists(targetField.visible_if, sourceField.name) ||
      this.checkPlaceholderExists(targetField.visible_if, `${mapKey}.`);

    // if (toggleVisible) {
    //   this.updateFieldVisibility$.next(targetField.name);
    // }

    if (toggleDisabled || toggleVisible) {
      this.formOnChangeHandleDisableState(formElement, targetField);
    }

    if (targetField?.dynamic_options && targetField.dynamic_options?.url) {
      const toggleDynamicOptions =
        this.checkPlaceholderExists(
          targetField.dynamic_options.url,
          sourceField.name
        ) ||
        this.checkPlaceholderExists(
          targetField.dynamic_options.url,
          `${mapKey}.`
        );
      if (
        toggleDynamicOptions ||
        targetField.dynamic_options.parent_field == targetField.name
      ) {
        formElement.setValue(null);
        this.updateFields$.next(targetField.name);
      }
    }
    const toggleMappedValue = this.checkPlaceholderExists(
      targetField.name,
      `${mapKey}.`
    );
    if (toggleMappedValue) {
      const mappedValue = get(this.formData, targetField.name);
      // this.setFieldValue(field.name, mappedValue);
      formElement.setValue(mappedValue);
    }

    const toggleDefaultValue =
      this.checkPlaceholderExists(
        targetField.default_value,
        sourceField.name
      ) || this.checkPlaceholderExists(targetField.default_value, `${mapKey}.`);
    if (toggleDefaultValue) {
      const defaultValueUpdate = targetField?.default_value_update
        ? this.parsePlaceholders(targetField.default_value_update)
        : false;

      if (!defaultValueUpdate && formElement && formElement.dirty) return;
      const fieldValue = this.parsePlaceholders(targetField.default_value);
      formElement.setValue(fieldValue);
    }
  }

  formOnChangeHandleDynamicOptions(value, field, mapKey) {
    if (field?.dynamic_options) {
      if (field?.dynamic_options.map_to_data) {
        const valueKey = field.dynamic_options.value_field || "value";
        const optionData = field.dynamic_options.data?.find(
          (r) => r[valueKey] === value
        );
        if (optionData) {
          this.updateFormData({
            ...this.formData,
            [mapKey]: optionData,
          });
        }
      } else {
        this.updateFormData({
          ...this.formData,
          [field.name]: value,
        });
      }
    }
  }

  checkTabVisibilityStatus(field, value) {
    this._flattenTabs.map((tab) => {
      if (tab?.visible_if) {
        const shouldToggle = this.checkPlaceholderExists(
          tab.visible_if,
          field.name
        );
        if (shouldToggle) {
          this.updateTabVisibility$.next({ ...tab, value });
        }
      }
    });
  }

  formOnChangeHandleDisableState(formElement: UntypedFormControl, field: any) {
    const shouldBeDisabled = this.parsePlaceholders(field.disabled);
    const shouldBeVisible = this.getFieldVisibilityState(field);
    if (formElement) {
      if (shouldBeDisabled) {
        formElement.disable();
        formElement.setErrors(null);
      } else if (shouldBeVisible === false) {
        formElement.disable();
        formElement.setErrors(null);
      } else {
        formElement.enable();
      }
    }
    this.updateFields$.next(field.name);
    this.updateFieldVisibility$.next(field.name);
  }

  tryToSubmitFromModal(extraPayload = {}) {
    if (this.form.valid) {
      const payload = { ...this.getFormRawData(), ...extraPayload };
      if (this.formMode === "CREATE") {
        return this.createItem(this.apiUrl, payload);
      } else {
        return this.updateItem(this.apiUrl, payload);
        console.error(`Please record this error: ${ERR_FORM_100010.code}`);
        return throwError({
          internal: ERR_FORM_100010.code,
        });
      }
    }
  }

  tryToSubmit(extraPayload = {}) {
    if (this.form.valid) {
      const payload = { ...this.getFormRawData(), ...extraPayload };
      //   if (this.isWizard) {
      //     return this.updateItem(this.apiUrl, payload);
      //   }
      if (this.formMode === "CREATE") {
        return this.createItem(this.apiUrl, payload);
      } else {
        return this.updateItem(this.apiUrl, payload);
      }
    }
  }

  getFormRawData() {
    let payload = { ...this.formInitialData };
    payload._parent = undefined;
    if (this.form) {
      Object.keys(this.form.controls).forEach((control: string) => {
        const typedControl: AbstractControl = this.form.controls[control];
        if (control === "translations") {
          let translationPayload = {};
          Object.keys((typedControl as UntypedFormGroup).controls).forEach(
            (translation: string) => {
              const typedTranslation: AbstractControl = (
                typedControl as UntypedFormGroup
              ).controls[translation];
              translationPayload = {
                ...translationPayload,
                [translation]: typedTranslation.value,
              };
            }
          );
          payload = {
            ...payload,
            _submit: true,
            is_testdata: false,
            translations_lang: translationPayload,
          };
        } else {
          Object.keys((typedControl as UntypedFormGroup).controls).forEach(
            (formField: string) => {
              const typeFormField: AbstractControl = (typedControl as UntypedFormGroup)
                .controls[formField];

              // this section here, checks if the field is visible, if not, it doesn't add it to the payload
              // we should probably find a better way to reduce the number of iterations
              // maybe instead of going over the form, we should go over the tabs
              // we need to make sure formData is updated with the latest values
              const formFieldObject = this.getFieldFromTabsByName(formField);
              if (formFieldObject) {
                if (!this.getFieldVisibilityState(formFieldObject)) {
                  return;
                }
              }
              const payloadName =
                formFieldObject?.["payload_name"] ??
                // formFieldObject?.["dynamic_options"]?.["map_to_data"] ??
                formField;
              if (moment.isMoment(typeFormField.value)) {
                set(payload, payloadName, moment(typeFormField.value).format());
              } else {
                set(payload, payloadName, typeFormField.value);
              }
            }
          );

          payload = {
            ...payload,
            // ...transformedValues,
            _submit: true,
            is_testdata: false,
            id: this.id,
          };
        }
      });
    }
    return payload;
  }

  updateItem(baseUrl: string, payload: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.put(baseUrl + `/${payload.id}`, payload, {
      headers: httpHeaders,
      params: {
        is_testdata: localStorage.getItem("is_testdata"),
      },
    });
  }

  getDropDownItems(baseUrl: string): Observable<QueryResultsModel> {
    // save the query params in the local storage
    // this.localStorageService.saveFilters(tableName, queryParams);
    return this.http.get<QueryResultsModel>(baseUrl);
  }

  createItem(baseUrl: string, item: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.post<any>(baseUrl, item, { headers: httpHeaders });
  }

  getTextConfig(locale: string, textName: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.get<any>(ApiConfig.TextConfigURL(locale, textName));
  }

  getChartData(url: string): Observable<any> {
    const isTestData = localStorage.getItem("is_testdata");
    let params = new HttpParams();
    params = params.set("isTestdata", isTestData.toString());
    return this.http.get<any>(url, { params });
  }

  // this is called on `EDIT` mode
  getItemById(baseUrl: string, itemId: number): Observable<any> {
    let url = `${baseUrl}/${itemId}?lang=${
      this.configLang || localStorage.getItem("is_testdata") || "en"
    }&is_testdata=${localStorage.getItem("is_testdata")}`;
    // const sampleUrl = `http://localhost:3000/services/161`;
    return this.http.get<any>(url);
  }

  deleteItem() {
    this.commonService
      .confirm({
        btnNoLabel: "No",
        btnYesLabel: "Yes",
        message: this.translateService.instant("DELETE_MODAL.DESCRIPTION"),
        title: this.translateService.instant("DELETE_MODAL.TITLE", {
          title: this.formName,
        }),
        paramDisplayName: "",
        paramName: "",
        paramType: "",
      })
      .subscribe({
        next: (dialogResult) => {
          if (dialogResult) {
            // call delete here
          }
        },
        error: (value) => {},
        complete: () => {},
      });
  }

  // this is called on `CREATE` mode
  getFormConfig(formName: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.get<any>(
      ApiConfig.FormConfigURL(
        this.configLang || localStorage.getItem("is_testdata") || "en",
        formName
      )
    );
  }

  getFormParentData(url): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.get<any>(this.getApiUrl(url));
  }

  getDataFromUrl(url): Observable<any> {
    const parsedUrl = this.getApiUrl(url);
    return this.http.get<any>(parsedUrl);
  }

  validationVisibleIf = (text?: string) => {
    if (!text) return null;
    const placeholder = text.match(/{[^}]+}/g);
    if (!placeholder) return null;

    let value = placeholder[0].slice(1, -1);
    return value;
  };

  // ///////////////////////////////////////////////////////////////////////////////////
  // this is working now
  // I don't think we can make huge improvements on it, but we might be able to add
  // typings to make it robust
  // basically every value that comes from server, might contain a string like
  // `{xxx}|{xxx}`, which this will in turn extract them and either applies translations
  // or apply specific value to it. like `Date now`.
  // doNotIncludeData is there only for a the special case of `user lookup` (updateLabel)
  // ///////////////////////////////////////////////////////////////////////////////////
  parsePlaceholders = (
    text: string | any,
    doNotIncludeFormData = false,
    customData = null
  ) => {
    let data = {};
    if (customData) {
      data = customData;
    } else {
      data = { ...this.formData };
    }
    // if (!doNotIncludeFormData && !this.isSubForm) {
    //   data = {
    //     ...data,
    //     ...this.getFormRawData(),
    //   };
    // }
    let formattedValue: any = text;
    if (formattedValue == null || formattedValue === "") return formattedValue;
    if (typeof formattedValue !== "string") return formattedValue;

    let formattedValueEval: any = formattedValue + "";

    const staticContentPattern = /{%[\s\S]*%}/;
    const staticContent = formattedValue.match(staticContentPattern);
    if (staticContent) {
      const refinedText = staticContent[0]
        .replace("{%", "")
        .replace("%}", "")
        .trim();
      return refinedText;
    }

    const placeholders = formattedValue.match(/{[^}]+}/g);
    if (!placeholders) return formattedValue;

    let isString = false;
    for (let placeholder of placeholders) {
      let fieldName = placeholder.slice(1, -1);

      const formatter = fieldName.split("|");
      fieldName = formatter[0];

      let fieldValue = null;

      // special placeholders
      if (fieldName == "DATE_NOW") {
        fieldValue = moment().format();
      } else {
        const segments = fieldName.split(".");
        if (segments.indexOf("translations") >= 0) {
          const transFieldName = segments.pop();
          const attrPath = segments.join(".");
          const transData = get(data, attrPath);

          //if (!transData) console.log(attrPath, transData, data);
          const translate = transData.find(
            (r) => r.lang == (this.translateService.currentLang || "en")
          );

          if (translate) {
            fieldValue = get(translate, transFieldName);
          }
        } else {
          //   fieldValue = !!get(data, fieldName)
          //     ? get(data, fieldName)
          //     : undefined;
          fieldValue = get(data, fieldName);
          if (fieldValue === undefined && this.form) {
            let formField = null;
            Object.keys(this.form.controls).map((control: string) => {
              const typedControl: AbstractControl = this.form.controls[control];
              formField = (typedControl as UntypedFormGroup).controls[formatter[0]];
              if (formField) fieldValue = formField.value;
            });
          }
          if (moment(fieldValue, "YYYY-MM-DD HH:mm:ss", true).isValid())
            fieldValue = moment(fieldValue).format();
        }
      }
      // parse formatter
      if (formatter.length > 1) {
        const formatType = formatter[1];
        fieldValue = this.translateByType(fieldValue, formatType);
      }

      //   if (strict && fieldValue === undefined) return null;

      if (fieldValue instanceof Date) fieldValue = moment(fieldValue).format();

      // plain string
      formattedValue = formattedValue.replaceAll(
        placeholder,
        ((val) => {
          if (val == null) return "";
          else if (!isNaN(Number(val)) && !isNaN(parseFloat(val)))
            return parseFloat(val);
          else return val;
        })(fieldValue)
      );

      // eval
      formattedValueEval = formattedValueEval.replaceAll(
        placeholder,
        ((val, placeholder) => {
          const res = this.checkForSpecialBooleanPlaceholder(placeholder, val);
          if (res !== null) {
            return res;
          }
          if (val === undefined) return "undefined";
          else if (val === null) return "null";
          else if (typeof val === "boolean") return val;
          //   else if (!isNaN(Number(val)) && !isNaN(parseFloat(val)))
          //     return parseFloat(val) + "";
          else {
            isString = true;
            return `"${val}"`;
          }
        })(fieldValue, placeholder)
      );
    }

    try {
      let val = (window as any).eval.call(
        window,
        "(function (that, moment) {return " + formattedValueEval + "})"
      )(data, moment);
      return val;
    } catch (e) {
      // this is a temp fix, we need to find a better way to handle this
      if (e.message === "Unexpected number") {
        return eval(formattedValue);
      }
      return formattedValue;
    }
  };
  checkForSpecialBooleanPlaceholder(placeholder: string, value) {
    placeholder = placeholder.slice(1, -1);
    const res = placeholder.split(".");
    const ph = res[res.length - 1];

    if (
      ph === "same_as_main_address" ||
      ph.startsWith("is_") ||
      ph.startsWith("has_") ||
      ph.startsWith("can_") ||
      ph === "id"
    ) {
      return Number(value);
    }

    return null;
  }
  getFormElement(fieldName): UntypedFormControl {
    let element = null;
    Object.keys(this.form.controls).forEach((control: string) => {
      const typedControl: AbstractControl = this.form.controls[control];
      Object.keys((typedControl as UntypedFormGroup).controls).forEach((formField) => {
        if (formField === fieldName) {
          element = (typedControl as UntypedFormGroup).controls[fieldName];
        }
      });
    });

    return element;
  }

  parseDefaultOption(field, defaultValue) {
    const valueKey = field?.dynamic_options?.value_field || "value";

    if (defaultValue != null) {
      if (typeof defaultValue != "string") return defaultValue;

      const parts = defaultValue.split(":");
      if (parts.length >= 2) {
        if (field.dynamic_options) {
          if (
            field.dynamic_options.data &&
            field.dynamic_options.data.length > 0
          ) {
            const defaultOption = field.dynamic_options.data.find(
              (r) => r[parts[0]] == parts[1]
            );
            if (defaultOption) defaultValue = defaultOption[valueKey];
          }
        } else {
          const defaultOption = this.dependencies[field.name]?.find(
            (r) => r[parts[0]] == parts[1]
          );
          if (defaultOption) defaultValue = defaultOption.value;
        }
      } else {
        defaultValue = this.parsePlaceholders(defaultValue);
      }

      return defaultValue;
    } else {
      if (field.type === FormInputTypes.MultipleSelect) {
        return field.dynamic_options?.data
          ?.filter((r) => r.is_default)
          .map((r) => r[valueKey]);
      } else {
        const option = field.dynamic_options?.data?.find((r) => r.is_default);
        return option ? option[valueKey] : null;
      }
    }
  }

  // /////////////////////////////////////////////////////////////////////
  // returns translations based on the given key
  // here, the translations are the ones provided in the API call (config)
  // /////////////////////////////////////////////////////////////////////
  getTranslationOf(key: string) {
    if (!key) return key;
    const locale = this._configLang.value || "en";
    let text = get(this.translations, `${locale}.${key}`);
    if (text == null) text = this.translateService.instant(key);
    return text || key;
  }

  getFieldControl = (
    form: UntypedFormGroup,
    fieldName: string
  ): AbstractControl | null => {
    return form.controls[fieldName];
  };

  getFieldLabel = (fieldName: string): string => {
    return this.getTranslationOf(fieldName);
  };

  getLookupDisplayValue(field) {
    if (field.lookup) {
      const labelKey = field.lookup.label_field;
      field.label = this.parsePlaceholders(labelKey);
      return field.label || "";
    } else {
      throw new Error("Invalid lookup field");
    }
  }
  // /////////////////////////////////////////////////////////////////////
  // DONE
  // /////////////////////////////////////////////////////////////////////
  getFieldTooltip = (fieldName: string): string => {
    return this.getTranslationOf(fieldName);
  };

  // /////////////////////////////////////////////////////////////////////////
  // it seems that we can open up the forms component in 'read only' mode
  // which changes the behavior of the component (I have implemented it)
  // this function goes through parser,
  //
  // /////////////////////////////////////////////////////////////////////////
  checkReadonly(field) {
    // if (this.readonly) return true;
    if (field.readonly && this.parsePlaceholders(field.readonly)) return true;
    if (field.readonly_create && (!this.formData || !this.formData.id))
      return true;
    if (field.readonly_edit && this.formData && this.formData.id) return true;
    return this.getFieldDisabledState(field);
  }

  getFieldDisabledState(field) {
    let disabled = false;
    if (field.disabled) {
      //   console.log(field.name, this.parsePlaceholders(field.disabled));
      return !!this.parsePlaceholders(field.disabled);
    }

    if (field._same_as_tab_field) {
      return !!get(this.formData, field._same_as_tab_field.name);
    }

    return false;
  }

  getFieldVisibilityState(field) {
    // if (field._tabName && this.getTabAttribute(field._tabName, "hidden"))
    //   return false;
    if (field?.visible_if && !this.parsePlaceholders(field.visible_if))
      return false;
    return true;
  }

  getTabVisibilityState(tab) {
    if (tab?.visible_if && !this.parsePlaceholders(tab.visible_if)) {
      return false;
    }
    return true;
  }

  getDropDownDependentOptions(fieldName: string) {
    return get(this._dependencies.getValue(), fieldName);
  }

  translateByType(text: string, type: string) {
    switch (type.toLowerCase()) {
      case "date":
        return this.localDate.transform(text, "date");
      case "datetime":
        return this.localDate.transform(text, "datetime");
      default:
        return text;
    }
  }

  createFormGroupForWizard = (data: WizardTab): UntypedFormGroup => {
    let fb = new UntypedFormGroup({});
    let validations = [];

    if (data.fields) {
      data.fields.map((c) => {
        const formFieldValue =
          get(this.formData, c.name) ||
          this.parseDefaultOption(c, c?.["default_value"]) ||
          null;
        const value = this.getFieldValue(c, formFieldValue);
        set(this.formData, c.name, this.parsePlaceholders(value));
        const disableState = this.getFieldDisabledState(c);
        fb.addControl(
          c.name,
          new UntypedFormControl({ value, disabled: disableState })
        );
        validations = [...validations, ...this.checkFormFieldForValidations(c)];
      });
      if (validations.length) {
        fb = this.addCustomValidators(fb, validations);
      }
    }
    return fb;
  };

  createFormGroup = (data: ITab): UntypedFormGroup => {
    let fb = new UntypedFormGroup({});
    let validations = [];
    data.content.map((c) => {
      //   let parsedDefaultOptions = null;
      //   if (c?.['dynamic_options']) {
      //     parsedDefaultOptions = this.parseDefaultOption(c, c?.["default_value"]);
      //   }
      const formFieldValue = get(this.formData, c.name);
      const value = this.getFieldValue(c, formFieldValue);
      set(this.formData, c.name, this.parsePlaceholders(value));
      const disableState = this.getFieldDisabledState(c);
      if (c.type === FormInputTypes.Checkbox) {
        fb.addControl(
          c.name,
          new UntypedFormControl({ value: !!value, disabled: disableState })
        );
      } else {
        fb.addControl(
          c.name,
          new UntypedFormControl({ value, disabled: disableState })
        );
      }

      validations = [...validations, ...this.checkFormFieldForValidations(c)];
    });
    if (validations.length) {
      fb = this.addCustomValidators(fb, validations);
    }
    // this indicates that this form requires server validation (e.g. TrackDay booking)
    return fb;
  };

  getFieldValue(field, formFieldValue) {
    if (formFieldValue == null) {
      let defaultValue = field?.["default_value"];
      if (defaultValue !== null) {
        defaultValue = this.parsePlaceholders(defaultValue);
        if (field?.["dynamic_options"]) {
          defaultValue = this.parseDefaultOption(field, defaultValue);
        }
        // else {
        //   defaultValue = this.parsePlaceholders(defaultValue);
        // }
        return defaultValue || null;
      }
    }
    return formFieldValue;
  }

  async validateAllFormFields() {
    Object.keys(this.form.controls).map((control: string) => {
      const typedControl: AbstractControl = this.form.controls[control];
      if (control === "translations") {
        Object.keys((typedControl as UntypedFormGroup).controls).map(
          (formGroup: string) => {
            const currentFormGroup = (typedControl as UntypedFormGroup).controls[
              formGroup
            ];

            Object.keys((currentFormGroup as UntypedFormGroup).controls).map(
              (formField: string) => {
                const currentField = (currentFormGroup as UntypedFormGroup).controls[
                  formField
                ];
                this.touchAndValidateFormElement(currentField, formField);
              }
            );
          }
        );
      } else {
        Object.keys((typedControl as UntypedFormGroup).controls).map(
          (formField: string) => {
            const currentField = (typedControl as UntypedFormGroup).controls[
              formField
            ];
            const res = this._flattenTabs
              .map((tab) => {
                if (isArray(tab.content)) {
                  return tab?.content?.find((c) => c.name === formField);
                }
              })
              .filter(Boolean);
            if (res.length) {
              if (!this.getFieldDisabledState(res[0])) {
                this.touchAndValidateFormElement(currentField, formField);
              } else {
                currentField.disable();
              }
            } else {
              this.touchAndValidateFormElement(currentField, formField);
            }
          }
        );
      }
    });

    if (this.form.invalid) {
      return this.form.invalid;
    }
    // check if any table has validations
    if (this.form.valid) {
      return await this.checkForAsyncServerValidation();
    }
    return null;

    // this.form.updateValueAndValidity();
    // this._flattenTabs.map((tab) => {
    //   if (tab.type === "crud-table") {
    //     const table = tab.content[0];
    //     if (this.parsePlaceholders(table?.required)) {
    //       if (!table?.["items"] || table["items"]?.length <= 0) {
    //         this.form.errors;
    //         this.form.setErrors({
    //           ...(this.form.errors || {}),
    //           [table.title.toString().toLowerCase()]: true,
    //         });
    //       }
    //     }
    //   }
    // });
  }

  async checkForServerConfirmation() {
    if (this.formMode === "CREATE") {
      return true;
    }

    if ("server_confirmation_url" in this.configFromServer?.config) {
      return await this.http
        .post(
          this.getApiUrl(
            this.configFromServer.config["server_confirmation_url"]
          ),
          this.getFormRawData()
        )
        .toPromise()
        .then(async (modalConfig) => {
          // all through this file, and the form shell, we are using string null, which is weird
          // this should be addressed at some point
          if (modalConfig !== "null") {
            return await this.commonService
              .openDialog(GenericModalComponent, {
                data: modalConfig,
              })
              .toPromise()
              .then((dialogRes) => {
                if (dialogRes) {
                  return dialogRes;
                }
                return false;
              });
          }
          return true;
        });
    }

    return true;
  }

  private async checkForAsyncServerValidation() {
    let url = null;
    if (this.formMode === "CREATE") {
      if ("server_create_validation_url" in this.configFromServer?.config) {
        url = this.configFromServer.config["server_create_validation_url"];
      }
    } else {
      if ("server_edit_validation_url" in this.configFromServer?.config) {
        url = this.configFromServer.config["server_edit_validation_url"];
      }
    }
    if (url) {
      return await this.http
        .post(this.getApiUrl(url), this.getFormRawData())
        .toPromise()
        .then((res) => {
          return res;
        });
    } else {
      return null;
    }
  }
  private touchAndValidateFormElement(control: AbstractControl, formField) {
    if (control) {
      control.setErrors({});
      control.markAsTouched();
      control.updateValueAndValidity({ onlySelf: false });
    }
  }

  checkFormFieldForValidations = (field) => {
    let validations = [];
    Object.values(CUSTOM_VALIDATIONS).forEach((validator) => {
      if (field?.[validator] || typeof field?.[validator] === "number") {
        validations.push({
          fieldName: field.name,
          fieldTitle: field.title,
          type: validator,
          dependent: this.parsePlaceholders(field?.[validator]) || null,
        });
      }
    });
    if (
      field?.["validator"] &&
      this.parsePlaceholders(field["validator"]) === "required"
    ) {
      validations.push({
        fieldName: field.name,
        fieldTitle: field.title,
        type: CUSTOM_VALIDATIONS.REQUIRED,
        dependent: null,
      });
    }

    return validations;
  };

  addCustomValidators = (fb: UntypedFormGroup, validations) => {
    validations.forEach((element) => {
      this.updateFieldValidator(
        fb.controls[element["fieldName"]] as UntypedFormControl,
        element
      );
    });
    fb.updateValueAndValidity({
      emitEvent: false,
    });

    return fb;
  };

  updateFormServerErrors(errors) {
    if (errors) {
      Object.keys(errors).forEach((key) => {
        const element = this.getFormElement(key);
        if (element) {
          let message = errors[key];
          if (isArray(message)) {
            message = message[0];
          }
          // create a new validator function, and keep a reference to it
          const validator = serverValidator(message);
          this._customValidatorsReferences = {
            ...this._customValidatorsReferences,
            [key]: validator,
          };
          element.addValidators(validator);
          //   this.touchAndValidateFormElement(element);
          element.updateValueAndValidity({ onlySelf: false });
        }
      });
    }
  }

  updateFieldValidators(formElement: UntypedFormControl, validations: any[]) {
    if (!formElement) return;
    // remove all custom validators (server validators)
    Object.keys(this._customValidatorsReferences).forEach((key) => {
      formElement.removeValidators(this._customValidatorsReferences[key]);
    });
    formElement.setErrors(null);
    formElement.clearValidators();
    validations.forEach((validation) => {
      this.updateFieldValidator(formElement, validation);
    });

    formElement.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  updateFieldValidator(formElement: UntypedFormControl, validation: any) {
    if (validation["type"] === CUSTOM_VALIDATIONS.GREATER_EQUAL_THAN) {
      formElement.addValidators(
        validateGreaterEqualThan(
          validation["fieldName"],
          validation["dependent"],
          this.getFieldLabel(validation["fieldTitle"])
        )
      );
    } else if (validation["type"] === CUSTOM_VALIDATIONS.LESS_EQUAL_THAN) {
      formElement.addValidators(
        validateLessEqualThan(validation["fieldName"], validation["dependent"])
      );
    } else if (validation["type"] === CUSTOM_VALIDATIONS.MIN) {
      formElement.addValidators(
        validateMin(validation["fieldName"], validation["dependent"])
      );
    } else if (validation["type"] === CUSTOM_VALIDATIONS.MAX) {
      formElement.addValidators(
        validateMax(validation["fieldName"], validation["dependent"])
      );
    } else if (validation["type"] === CUSTOM_VALIDATIONS.REQUIRED) {
      formElement.addValidators(Validators.required);
    }
  }
  createFormGroupForTranslationsTab = (data: ITab): UntypedFormGroup => {
    let fb = new UntypedFormGroup({});
    let validations = [];
    const translations = this.formData?.translations || [];
    if (translations.length) {
      translations.forEach((translation) => {
        let langForm = new UntypedFormGroup({});
        data.content.map((c) => {
          langForm.addControl(
            c.name,
            new UntypedFormControl(get(translation, c.name))
          );
          validations = [
            ...validations,
            ...this.checkFormFieldForValidations(c),
          ];
        });
        fb.addControl(translation.lang, langForm);
        if (validations.length) {
          this.addCustomValidators(langForm, validations);
        }
      });
    } else {
      let langForm = new UntypedFormGroup({});
      data.content.map((c) => {
        langForm.addControl(c.name, new UntypedFormControl(""));
        validations = [...validations, ...this.checkFormFieldForValidations(c)];
      });
      fb.addControl(this.configLang, langForm);
      if (validations.length) {
        this.addCustomValidators(langForm, validations);
      }
    }
    return fb;
  };

  extractWizardForm = (data) => {
    let allForms = new Map<string, UntypedFormGroup>();
    if (Array.isArray(data)) {
      for (let tab of data) {
        allForms = allForms.set(tab.name, this.createFormGroupForWizard(tab));
      }

      let shellForm: UntypedFormGroup = new UntypedFormGroup({});
      for (const form of allForms) {
        shellForm.addControl(form[0], form[1]);
      }

      this.form = shellForm;
    }
  };

  // gets the root tabs from config, then find all the forms (recursively)
  // currently type => form, tab, portlet can have forms inside
  // returning a map of forms with their names
  extractForms = (data?) => {
    let tabs;
    if (!data) {
      //   tabs = this.configFromServer.config.tabs;
      tabs = this._flattenTabs;
    } else {
      tabs = data;
    }

    if (this.configFromServer.config?.["process_buttons"]) {
      this.extractProcessButtons();
    }

    let allForms = new Map<string, UntypedFormGroup>();
    if (Array.isArray(tabs)) {
      for (let tab of tabs) {
        if (tab.type === "form") {
          allForms = allForms.set(tab.name, this.createFormGroup(tab));
        }
        if (tab.type === "translation") {
          allForms = allForms.set(
            tab.name,
            this.createFormGroupForTranslationsTab(tab)
          );
        }
        if (tab.type === "portlet" || tab.type === "tab") {
          let res = this.extractForms(tab.content);
          for (let entry of res) {
            allForms.set(entry[0], entry[1]);
          }
        }
      }
    } else {
      // for some reason, server returns an object if there is only one tab
      // here we take care of that - for now I am assuming the one tab will only be
      // of type form
      Object.values(tabs).forEach((tab, index) => {});
    }
    return allForms;
  };

  extractProcessButtons = (buttons = null) => {
    let btns = [];
    if (buttons) {
      btns = buttons;
    } else {
      btns = this.configFromServer.config?.["process_buttons"];
    }

    const processButtons = btns.map((button) => {
      return {
        ...this.processButtonParser([button])[0],
      } as ProcessButton;
    });

    this.processButtons = processButtons;
  };

  processButtonParser = (buttons) => {
    const processButtons = contextMenuButtonParser(
      buttons,
      {
        id: this.id,
        formData: this.formData,
      },
      this.parsePlaceholders.bind(this),
      this.getApiUrl.bind(this)
    );
    return processButtons;
  };

  // this gets the config from the server and extracts the tabs
  configParser = () => {
    const config = this.configFromServer.config;
    // DEPRECATED - remove after wizard migration is done
    if (config?.["form_wizard"]) {
      if (this.formMode === "CREATE") {
        this.isWizard = true;
        this.updateWizardTabs(config?.["form_wizard"]);
        this.extractWizardForm(config?.["form_wizard"]);
        return;
      }
    }
    let tabs: ITab[] = [];
    if (config.tabs.length > 0) {
      config.tabs.map((tab: any) => {
        tabs.push({
          name: tab.name,
          display: this.getTranslationOf(tab.name),
          type: tab.type,
          content: Array.isArray(tab.content)
            ? tab.content.map((field) => {
                if (field.type === "single_select") {
                  if (!field.type.dynamic_options) {
                    return {
                      ...field,
                      data: get(this.dependencies, field.name),
                    };
                  }
                }
                return field;
              })
            : tab.content,
          hasError: false,
          selected: false,
          shouldRefresh: tab["should_refresh_on_focus"] ?? false,
          disabled:
            this.formMode === "CREATE" ? tab["require_master_data"] : false,
          layout: tab.layout ?? { columns: [100] },
        });
      });
    }
    this.updateTabs(tabs);
    return tabs;
  };

  checkForFieldsToBeRefreshed = (data) => {
    if (data?.["refresh_fields"]) {
      const newData = this.formData;
      data?.["refresh_fields"].forEach((field) => {
        newData[field] = data[field];
      });
      this.formData = newData;
    }
    // if (data) {
    //   for (let key in data) {
    //     if (data.hasOwnProperty(key)) {
    //       if (data[key]?.["refresh_fields"]) {
    //         this.refreshFields(data[key]["refresh_fields"]);
    //       }
    //     }
    //   }
    // }
  };

  getApiUrl(apiUrl: string) {
    const url = this.parsePlaceholders(apiUrl, true);
    if (url === null) return null;
    return environment_api.api_url + url;
  }

  crudTableParseFormData(data) {
    const initialData = data;
    const formData = {};
    // map form data placehoders
    if (initialData) {
      Object.keys(initialData).map((key, index) => {
        set(formData, key, this.parsePlaceholders(get(initialData, key)));
      });
    }

    return formData || {};
  }

  private getFieldFromTabsByName(fieldName) {
    let field: any = null;
    this._flattenTabs.forEach((tab) => {
      if (tab.type === "form") {
        tab.content.forEach((content) => {
          if (content.name === fieldName) {
            field = content;
          }
        });
      }
    });
    return field;
  }

  reset({ initialData }) {
    this.updateConfigFromServer(null);
    this.updateConfigLang(null);
    this.updateTranslations(null);
    this.updateDependencies(null);
    this.updateTabs([]);
    this.updateWizardTabs([]);
    this.updateIsStickyTabForm(null);
    this.updateFormData(initialData);
    this.formInitialData = initialData;
    this.updateForm(null);
  }

  initiateForm() {
    if (this.configFromServer) {
      this.updateDependencies(this.configFromServer.dependencies);
      this.updateConfigLang(
        localStorage.getItem("language") ||
          this.configFromServer.config.default_language ||
          "en"
      );
      this.updateTranslations(this.configFromServer.config.translations);
      if ("sticky_tab_urls" in this.configFromServer.config) {
        this.updateIsStickyTabForm(
          !!this.configFromServer.config["sticky_tab_urls"]
        );
      }

      if ("header_content" in this.configFromServer.config) {
        this.htmlHeader = this.configFromServer.config["header_content"];
      }
      if ("sidebar_content" in this.configFromServer.config) {
        this.htmlSidebar = this.configFromServer.config["sidebar_content"];
      }

      if ("infotext" in this.configFromServer.config) {
        this.infoText = this.configFromServer.config["infotext"];
      }

      this.checkForStyleOverrides();

      this.formOptions = this.configFromServer.config.options || {};
    }
  }

  checkForStyleOverrides() {
    if ("styleOverrides" in this.configFromServer.config) {
      this.styleOverrides$.next(
        this.parseStyleOverrides(this.configFromServer.config["styleOverrides"])
      );
    }
  }

  parseStyleOverrides(styleOverrides) {
    const res = {};
    Object.entries(styleOverrides).forEach(([key, value]) => {
      if (typeof value === "string") {
        res[key] = this.parsePlaceholders(value);
      }
    });
    return res;
  }

  getDefaultValuesFromServer() {
    if (
      this.configFromServer.config?.["default_value_url"] &&
      this.formMode === "CREATE"
    ) {
      this.blockUI$.next(true);
      const apiUrl = this.configFromServer.config["default_value_url"];
      const message = this.toastr.info(
        "Loading default values...",
        "Please wait",
        {
          timeOut: 10000,
          closeButton: false,
          progressBar: true,
          progressAnimation: "increasing",
        }
      );
      this.http
        .get(this.getApiUrl(apiUrl))
        .pipe(
          take(1),
          tap((data) => {})
        )
        .subscribe({
          next: (data) => {
            Object.keys(data).map((key, index) => {
              const formElement = this.getFormElement(key);
              if (formElement) {
                set(this.formData, key, data[key]);
                formElement.setValue(data[key]);
              }
              // this.form.enable();
            });
            setTimeout(() => {
              this.toastr.remove(message.toastId);
              this.toastr.success("Default values loaded successfully");
            }, 1000);
          },
          error: (error) => {
            this.blockUI$.next(false);
            this.toastr.error(
              "Error while loading default values",
              "Please try reopening the form",
              {
                disableTimeOut: true,
              }
            );
          },
          complete: () => {
            this.blockUI$.next(false);
          },
        });
    }
  }

  onBreadcrumbClick(event) {
    const parent = event?.currentTarget
      ?.closest(".kt-portlet")
      ?.querySelector(".main");
    if (parent) {
      const childHeaderContainer = parent.querySelector(".kt-portlet__head");
      const childHeader = childHeaderContainer?.querySelector(
        "[data-breadcrumbClose]"
      );

      if (childHeader) {
        childHeader.click();
        return;
      }
    }

    this.toastr.info("something went wrong, please try reopening the form");
  }

  get htmlHeader() {
    return this._htmlHeader.getValue();
  }
  set htmlHeader(htmlHeader) {
    this._htmlHeader.next(htmlHeader);
  }

  get htmlSidebar() {
    return this._htmlSidebar.getValue();
  }
  set htmlSidebar(htmlSidebar) {
    this._htmlSidebar.next(htmlSidebar);
  }
  get infoText() {
    return this._infoText.getValue();
  }
  set infoText(infoText) {
    this._infoText.next(infoText);
  }

  //#region wizard tabs
  get wizardTabs() {
    return this._wizardTabs.getValue();
  }
  private set wizardTabs(tabs) {
    this._wizardTabs.next(tabs);
  }
  updateWizardTabs(tabs) {
    this.wizardTabs = tabs;
  }
  //#endregion
  //#region tabs
  get tabs() {
    return this._tabs.getValue();
  }
  private set tabs(tabs) {
    this._tabs.next(tabs);
  }
  updateTabs(tabs) {
    this.tabs = tabs;
    this._flattenTabs = tabs.flatMap((tab) => {
      if (tab.type === "portlet") {
        return tab?.content;
      }
      return tab;
    });
  }
  //#endregion
  //#region processButtons
  get processButtons() {
    return this._processButtons.getValue();
  }
  private set processButtons(processButtons) {
    this._processButtons.next(processButtons);
  }
  //#endregion
  //#region configFromServer
  get configFromServer() {
    return this._configFromServer.getValue();
  }
  private set configFromServer(configFromServer) {
    this._configFromServer.next(configFromServer);
  }
  updateConfigFromServer(configFromServer) {
    this.configFromServer = configFromServer;
  }
  //#endregion
  //#region translations
  get translations() {
    return this._translations.getValue();
  }
  private set translations(translations) {
    this._translations.next(translations);
  }
  updateTranslations(translations) {
    this.translations = translations;
  }
  //#endregion
  //#region isStickyTabUrls
  get isStickyTabUrl() {
    return this._isStickyTabForm;
  }
  private set isStickyTabForm(isStickyTabForm) {
    this._isStickyTabForm = isStickyTabForm;
  }
  updateIsStickyTabForm(isStickyTabForm: boolean) {
    this._isStickyTabForm = isStickyTabForm;
  }
  //#endregion

  //#region form
  get form() {
    return this._form.getValue();
  }
  private set form(form: UntypedFormGroup) {
    this._form.next(form);
  }
  updateForm(form: UntypedFormGroup) {
    this.form = form;
  }
  //#endregion

  //#region formData
  get formData() {
    return this._formData.getValue();
  }
  private set formData(formData) {
    this._formData.next(formData);
  }
  updateFormData(formData) {
    this.formData = formData;
  }
  //#endregion

  //#region dependencies
  get dependencies() {
    return this._dependencies.getValue();
  }
  private set dependencies(dependencies) {
    this._dependencies.next(dependencies);
  }
  updateDependencies(dependencies) {
    this.dependencies = dependencies;
  }
  //#endregion

  //#region translationLangs
  get translationLangs() {
    return this._translationLangs.getValue();
  }
  private set translationLangs(translationLangs) {
    this._translationLangs.next(translationLangs);
  }
  updateTranslationLangs(langs) {
    this.translationLangs = langs;
  }
  //#endregion
  //#region configLang
  get configLang() {
    return this._configLang.getValue();
  }
  private set configLang(lang: string) {
    this._configLang.next(lang);
  }
  updateConfigLang(lang: string) {
    this.configLang = lang;
  }
  //#endregion
  //#region SubForm display status
  get isSubFormShown() {
    return this._isSubFormShown.getValue();
  }
  set isSubFormShown(value) {
    this._isSubFormShown.next(value);
  }
  //#endregion
  //#region SubForm  status
  get isSubForm() {
    return this._isSubForm.getValue();
  }
  set isSubForm(value) {
    this._isSubForm.next(value);
  }
  //#endregion
  //#region API URL
  public get listUrl() {
    return this._listUrl;
  }
  public set listUrl(value: string) {
    this._listUrl = value;
  }
  //#endregion
  //#region API URL
  public get formName() {
    return this._formName;
  }
  public set formName(value: string) {
    this._formName = value;
  }
  //#endregion
  //#region API URL
  public get apiUrl() {
    return this._apiUrl;
  }
  public set apiUrl(value: string) {
    this._apiUrl = value;
  }
  //#endregion
  //#region ID
  public get id() {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  //#endregion
  //#region FormMode
  public get formMode() {
    return this._formMode.getValue();
  }
  public set formMode(value: FORM_MODE) {
    this._formMode.next(value);
  }
  //#endregion
  //#region isWizard
  public get isWizard() {
    return this._isWizard.getValue();
  }
  public set isWizard(value: boolean) {
    this._isWizard.next(value);
  }
  //#region initialData
  public get formInitialData() {
    return this._formInitialData.getValue();
  }
  public set formInitialData(value: any) {
    this._formInitialData.next(value);
  }
  //#region formInitialValue
  public get formInitialValues() {
    return this._formInitialValues.getValue();
  }
  public set formInitialValues(value: any) {
    this._formInitialValues.next(value);
  }
  //#region formOptions
  public get formOptions() {
    return this._formOptions;
  }
  public set formOptions(value: any) {
    this._formOptions.next(value);
  }
}
