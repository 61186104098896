import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NumberConfig, TextBoxConfig } from "../models/textbox.config.model";

// text,email,tel,textarea,password,
@Component({
  selector: "cb-number",
  template: `
    <div [formGroup]="form" *ngIf="config.visible">
      <mat-form-field
        class="mat-form-field-fluid"        
        #formTab
      >
        <input
          matInput
          [placeholder]="config.placeholder"
          [min]="config.min"
          [max]="config.max"
          [formControlName]="config.formControlName"
          [required]="config.required"
          (change)="(null)"
          [readonly]="config.readonly"
          [attr.data-pgm-formelement]="config.testingAttribute"
          [matTooltip]="config.tooltip"
          [mask]="config.mask || ''"
          [dropSpecialCharacters]="!config.dropSpecialCharacter"
        />
        <mat-hint *ngIf="!config.readonly" align="start">
          {{ "COMMON.PLEASE_ENTER" | translate }}
          <strong>{{ config.hint | translate }}</strong>
        </mat-hint>
      </mat-form-field>
    </div>
  `,
})
export class CbNumberComponent implements OnInit, OnDestroy {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: NumberConfig;

  destroyed$ = new Subject();

  get isValid() {
    return this.form.controls[this.config.formControlName].valid;
  }
  get isDirty() {
    return this.form.controls[this.config.formControlName].dirty;
  }

  constructor() {}
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
  ngOnInit(): void {
    if (this.config.visibleIf) {
      console.log(this.field, this.form, this.config);
      this.config = {
        ...this.config,
        visible: this.form.controls[this.config.visibleIf].value,
      };

      this.form.controls[this.config.visibleIf]?.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value: boolean) => {
          this.config = {
            ...this.config,
            visible: value,
          };
        });
    }
  }
}
