<ng-container *ngIf="settings$ | async as settings">
  <header [ngClass]="settings.type" class="border-bottom">
    <ng-container *ngIf="settings.type === 'fullscreen'">
      <span> {{ settings.title }} </span>
      <div class="actions">
        <button class="btn kt-subheader__btn-primary" (click)="closeDialog()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="settings.type === 'info'">
      <span> {{ settings.title }} </span>
      <div class="actions">
        <button
          color="warn"
          mat-flat-button
          (click)="closeDialog()"
        >
          <i class="fa-solid fa-circle-x"></i>
          {{ "COMMON.CLOSE" | translate }}
        </button>
      </div>
    </ng-container>
  </header>
  <mat-dialog-content
    class="mat-typography"
    [ngClass]="settings.type"
    *ngIf="vm$ | async as vm; else loading"
  >
    <div class="wrapper">
      <html-content
        [apiUrl]="vm.apiUrl"
        [displayRefreshButton]="vm.displayRefreshButton"
      ></html-content>
    </div>
  </mat-dialog-content>
</ng-container>

<ng-template #loading>
  <div style="width: 100%; height: 100vh; display: grid; place-content: center">
    <div class="kt-portlet__body-progress">
      <cb-loading></cb-loading>
    </div>
  </div>
</ng-template>
