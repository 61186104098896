import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ACTIONS } from '../../store/default.actions';
import { selectActiveProject } from '../../store/default.selector';

@Component({
  selector: 'app-kanban-board-form',
  templateUrl: './kanban-board-form.component.html',
  styleUrls: ['./kanban-board-form.component.css']
})
export class KanbanBoardFormComponent implements OnInit, OnDestroy {

  activeProject$ = this.store.pipe(select(selectActiveProject))
  activeProject: any;
  activeProjectSubscription: Subscription;
  constructor(private store: Store,
    private fb: UntypedFormBuilder) { }  

  boardForm = this.fb.group({
    title: [null, [Validators.required]],
    description: ["", [Validators.required]],
  })

  ngOnInit(): void {
    this.activeProjectSubscription = this.activeProject$.subscribe(e => {
      this.activeProject = e
    })
  }

  createBoard = (projectId: number) => {
    if (projectId && this.boardForm.valid) {
      this.store.dispatch(
        ACTIONS.boards.CreateBoard(
          {
            ...this.boardForm.getRawValue(),
            projectId            
          }
        )
      );
    }    
  }

  ngOnDestroy(): void {
    this.activeProjectSubscription.unsubscribe();
  }
}
