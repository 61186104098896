import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";
// Auth

@Component({
  selector: "trackday-bookings-form",
  templateUrl: "./trackday-bookings-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackdayBookingsFormComponent implements OnInit {
  apiUrl: string;
  relatedUrl: string;
  constructor() {}

  ngOnInit() {
    this.apiUrl = ApiConfig.TrackdayBookingsUrl();
  }
}
