/**
  * Usage: value | boolean: 'yesno' or  boolean: 'truefalse'
  * If no format is provided `shortDate` will be used.
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: "boolean"
})
export class BooleanPipe implements PipeTransform {
	constructor(
		private translate: TranslateService) {
	}

	transform(value: any, yesLabel: string = "COMMON.YES", noLabel: string = "COMMON.NO"): any {
		if (yesLabel == null) yesLabel = "COMMON.YES";
		if (noLabel == null) noLabel = "COMMON.NO";
		let text = value ? yesLabel : noLabel;
		//console.log('BooleanPipe', value, text);
		return this.translate.instant(text);
	}
}