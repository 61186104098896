
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Store } from "@ngrx/store";
// AppState
import { ApiConfig } from "../../../../../core/config/api.config";
import { AppState } from "../../../../../core/store/reducers/app.reducers";
// Auth

@Component({
  selector: "snippet-directbooking-trackday-form",
  templateUrl: "./trackday-directbooking-snippet-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackdayDirectbookingSnippetFormComponent implements OnInit {
  apiUrl: string;
  constructor(private store: Store<AppState>, private router: Router) {}
  ngOnInit() {
    this.apiUrl = ApiConfig.TrackdayDirectbookingSnippetUrl();
  }
}
