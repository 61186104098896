import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "../../../../../../core/base";
import { FormConfigService } from "../../../../form.config.service";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
  selector: "cb-tab-content-translation",
  templateUrl: "./tab-content-translation.component.html",
  styleUrls: ["./tab-content-translation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentTranslationComponent implements OnInit {
  @Input() content: any;
  @Input() forms: UntypedFormGroup;
  @ViewChildren("#tabsEl") tabsEl: QueryList<MatTabGroup>;
  availableLanguages = [];
  translationTabs = [];
  moreTranslationButton = [];
  defaultLang: string = "en";
  data = null;

  selectedTab = 0;
  constructor(
    private translationService: TranslationService,
    private formConfigService: FormConfigService,
    private translate: TranslateService
  ) {
    this.availableLanguages = this.translationService.getAvailableLanguages();
    this.data = this.formConfigService.formData;
    this.defaultLang = this.translate.getDefaultLang();
  }

  // here we create a tab each element present in data.translations
  //
  ngOnInit(): void {
    if (this.data?.translations && this.data?.translations.length > 0) {
      this.availableLanguages.forEach((at) => {
        const translation = this.data.translations.find(
          (t) => at.lang === t.lang
        );
        if (translation) {
          this.translationTabs.push({
            content: this.content,
            caption: at.name,
            name: at.lang,
            type: "form",
            flag: at.flag,
            lang: at.lang,
          });
        } else {
          this.moreTranslationButton.push({
            caption: at.name,
            flag: at.flag,
            name: at.lang,
            lang: at.lang,
          });
        }
      });
    } else {
      this.availableLanguages.forEach((at) => {
        if (at.lang === this.formConfigService.configLang) {
          this.translationTabs.push({
            content: this.content,
            caption: at.name,
            name: at.lang,
            type: "form",
            flag: at.flag,
            lang: at.lang,
          });
        } else {
          this.moreTranslationButton.push({
            caption: at.name,
            flag: at.flag,
            name: at.lang,
            lang: at.lang,
          });
        }
      });
      //   const translation = this.availableLanguages.find(
      //     (t) => t.lang === this.formConfigService.configLang
      //   );
      //   this.translationTabs.push({
      //     content: this.content,
      //     caption: translation.name,
      //     name: translation.lang,
      //     type: "form",
      //     flag: translation.flag,
      //     lang: translation.lang,
      //   });
    }
  }

  addTranslation(lang) {
    let langForm = new UntypedFormGroup({});
    this.content.map((c) => {
      langForm.addControl(c.name, new UntypedFormControl(""));
    });
    (this.forms.get("translations") as UntypedFormGroup).addControl(
      lang.lang,
      langForm
    );
    this.translationTabs.push({
      content: this.content,
      caption: lang.caption,
      name: lang.lang,
      type: "form",
      flag: lang.flag,
      lang: lang.lang,
    });

    this.selectedTab = this.translationTabs.length - 1;

    this.moreTranslationButton = this.moreTranslationButton.filter(
      (e) => e.lang !== lang.lang
    );
  }

  removeTranslation(lang: string) {
    this.translationTabs = this.translationTabs.filter((t) => t.lang !== lang);
    (this.forms.get("translations") as UntypedFormGroup).removeControl(lang);
    const language = this.availableLanguages.find((at) => at.lang === lang);
    if (language) {
      this.moreTranslationButton = [
        ...this.moreTranslationButton,
        {
          caption: language.name,
          flag: language.flag,
          name: language.lang,
          lang: language.lang,
        },
      ];
    }

    this.selectedTab = 0;
  }

  onTabChange(event) {
    this.selectedTab = event.index;
    this.tabsEl.forEach((tab) => {
      tab.realignInkBar();
    });
  }
}
