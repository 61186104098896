import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { helpSidebarActions } from "../../../../core/store/actions/help-sidebar.actions";
import { AppState } from "../../../../core/store/reducers/app.reducers";
import { helpSidebarSelectors } from "../../../../core/store/selectors/help-sidebar.selectors";

@Component({
  selector: "cb-help-viewer",
  templateUrl: "./help-viewer.component.html",
  styleUrls: ["./help-viewer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpViewerComponent implements OnInit {
  store = inject(Store<AppState>);

  targetUrl$ = this.store.pipe(select(helpSidebarSelectors.selectTargetUrl));
  sidebarStatus$ = this.store.pipe(
    select(helpSidebarSelectors.selectSidebarStatus)
  );

  vm$ = combineLatest([this.targetUrl$, this.sidebarStatus$]).pipe(
    map(([targetUrl, sidebarStatus]) => {
      return {
        targetUrl,
        sidebarStatus,
      };
    })
  );

  ngOnInit(): void {}

  close() {
    this.store.dispatch(helpSidebarActions.closeSidebar());
  }
}
