<div
  class="template-row"
  *ngIf="(shouldConfirm$ | async) === false; else confirm"
>
  <span class="text">{{ template.name }}</span>
  <!-- below if statement, is weird, the server should return the proper `mode` -->
  <button
    mat-icon-button
    color="warn"
    (click)="onDeleteTemplate($event)"
    *ngIf="template.user_id !== null"
  >
    <mat-icon>delete</mat-icon>
  </button>
</div>

<ng-template #confirm>
  <div class="confirm-container" @fade (click)="stopPropagation($event)">
    <button
      class="btn btn-outline-primary reset-button-styles danger-outline-button"
      mat-icon-button
      (click)="cancelDelete($event)"
      [matTooltip]="'EXPORT_TABLE.CANCEL_BUTTON' | translate"
    >
    <i class="fa-solid fa-circle-x"></i>
    </button>
    <button
      class="btn btn-outline-primary reset-button-styles success-outline-button"
      mat-icon-button
      (click)="doDelete($event)"
      [matTooltip]="'EXPORT_TABLE.DELETE_BUTTON' | translate"
    >
      <i class="fa-solid fa-check"></i>
    </button>
    <p>{{ "EXPORT_TABLE.CONFIRM_DELETE_MESSAGE" | translate }}</p>
  </div>
</ng-template>
