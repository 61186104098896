// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// Models
import { QueryParamsModel } from '../../base';

export enum CrudTableActionTypes {
    AllItemsRequested = '[Crud Module] All Items Requested',
    AllItemsLoaded = '[Crud API] All Items Loaded',
    ItemOnServerCreated = '[Edit Item Component] Item On Server Created',
    ItemOnServerUpdated = '[Edit Item Component] Item On Server Updated',
    ItemCreated = '[Edit Item Dialog] Item Created',
    ItemUpdated = '[Edit Item Dialog] Item Updated',
    ItemDeleted = '[List Page] Item Deleted',
    ItemOnServerDeleted = '[List Page] Item On Server Deleted',
    ItemsDeleted = '[List Page] Items Deleted',
    PageRequested = '[List Page] Page Requested',
    PageLoaded = '[List API] Page Loaded',
    PageCancelled = '[List API] Page Cancelled',
    PageToggleLoading = '[Items] Page Toggle Loading',
    CrudActionToggleLoading = '[Items] Action Toggle Loading',
    CrudActionServerError = '[Crud Module] Server Error',
}

export class ItemOnServerCreated implements Action {
    readonly type = CrudTableActionTypes.ItemOnServerCreated;
    constructor(public payload: { item: any, url: string }) { }
}

export class ItemCreated implements Action {
    readonly type = CrudTableActionTypes.ItemCreated;
    constructor(public payload: { item: any, url: string }) { }
}

export class ItemUpdated implements Action {
    readonly type = CrudTableActionTypes.ItemUpdated;
    constructor(public payload: {
        partialItem: Update<any>,
        item: any,
        url: string,
    }) { }
}

export class ItemOnServerUpdated implements Action {
    readonly type = CrudTableActionTypes.ItemOnServerUpdated;
    constructor(public payload: { item: any, url: string }) { }
}

export class ItemDeleted implements Action {
    readonly type = CrudTableActionTypes.ItemDeleted;
    constructor(public payload: { id: number, url: string }) {}
}

export class ItemOnServerDeleted implements Action {
    readonly type = CrudTableActionTypes.ItemOnServerDeleted;
    constructor(public payload: { id: number, url: string }) {}
}

export class ItemsDeleted implements Action {
  readonly type = CrudTableActionTypes.ItemsDeleted;
  constructor(public payload: { ids: number[], url: string }) {}
}

export class PageRequested implements Action {
    readonly type = CrudTableActionTypes.PageRequested;
    constructor(public payload: { page: QueryParamsModel, url: string, resolveData: any }) { }
}

export class PageLoaded implements Action {
    readonly type = CrudTableActionTypes.PageLoaded;
    constructor(public payload: { items: any[], totalCount: number, page: QueryParamsModel }) { }
}


export class PageCancelled implements Action {
    readonly type = CrudTableActionTypes.PageCancelled;
}

export class PageToggleLoading implements Action {
    readonly type = CrudTableActionTypes.PageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class CrudActionToggleLoading implements Action {
    readonly type = CrudTableActionTypes.CrudActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class CrudActionServerError implements Action {
    readonly type = CrudTableActionTypes.CrudActionServerError;
    constructor(public payload: { errors: any }) { }
}

export type CrudTableActions = 
ItemCreated
| ItemUpdated
| ItemDeleted
| ItemOnServerCreated
| ItemOnServerUpdated
| ItemOnServerDeleted
| PageLoaded
| PageCancelled
| PageToggleLoading
| PageRequested
| CrudActionToggleLoading
| CrudActionServerError;
