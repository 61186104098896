// Angular
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from "@angular/core";
import { ApiConfig } from "./../../../../../core/config/api.config";
import { ApiService } from "./../../../../../services/api.service";

@Component({
  selector: "kt-search-dropdown",
  templateUrl: "./search-dropdown.component.html",
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        /* max-width: clamp(var(--searchBar-max-width), 50%, 1200px);         */
        position: relative;
      }
      .search-container {
        display: flex;
        width: 100%;
        gap: 4px;
        align-items: center;
      }
    `,
  ],
})
export class SearchDropdownComponent {
  // Public properties

  // Set icon class name
  @Input() icon = "flaticon2-search-1";
  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;
  @Input() type: "brand" | "success" | "warning" = "success";
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;

  data: any[];
  loading: boolean;
  isInFocus = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private elRef: ElementRef
  ) {}

  search() {
    this.data = null;
    const searchText: string = this.searchInput.nativeElement.value;
    if (searchText.length > 1) {
      this.loading = true;
      this.cdr.markForCheck();

      const url = ApiConfig.SearchURL();
      this.apiService
        .get(url, { search_key: encodeURIComponent(searchText) })
        .subscribe((result) => {
          this.data = result;
          this.loading = false;
          this.isInFocus = true;
          this.cdr.markForCheck();
        });
    }
  }

  /**
   * Clear search
   *
   * @param e: Event
   */
  clear(e) {
    this.data = null;
    this.searchInput.nativeElement.value = "";
  }

  openChange() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  @HostListener("document:click", ["$event"])
  clickOut(event) {
    if (this.elRef.nativeElement.contains(event.target)) {
      if (this.data?.length) {
        this.isInFocus = true;
      } else {
        this.isInFocus = false;
      }
    } else {
      this.isInFocus = false;
    }
  }
}
