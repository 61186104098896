import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ProcessButton } from "../../../../shared/data/models";
import { ContextMenuService } from "../../service/context.menu.service";
import { environment_api } from "../../../../../environments/environment.api";
import { CommonService } from "../../../../services/common.service";
import { HtmlModalComponent } from "../../../../views/partials/content/html-modal/html-modal.component";
import { filter, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import { InfoDialogComponent } from "../../../../views/partials/content";
import { TranslateService } from "@ngx-translate/core";
import { SendEmailComponent } from "../../../../modules/send-email/components/send-email/send-email.component";
import { FormsShellModalComponent } from "../../../../modules/cb-forms/components/forms-shell-modal/forms-shell-modal.component";
import { prepareExtraPayloadForForm } from "../../utils/helper";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
// import { CrudShellService } from "../../../crud-shell/crud-shell.service";

@Component({
  selector: "app-context-menu",
  templateUrl: "./context-menu.component.html",
  styleUrls: ["./context-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContextMenuService],
})
export class ContextMenuComponent implements OnInit {
  _contextMenuItems$ = new BehaviorSubject<ProcessButton[]>([]);
  @Input("contextMenuItems") set contextMenuItems(buttons: ProcessButton[]) {
    this._contextMenuItems$.next(buttons);
  }
  @Input() displayMode = "context"; // "context" | "action;
  @Output() updatedDataEvent = new EventEmitter<any>();
  @Output() refreshFormEvent = new EventEmitter<any>();
  destroyed$ = new Subject();
  constructor(
    private contextMenuService: ContextMenuService,
    // private crudShellService: CrudShellService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.contextMenuService.buttonClickEvent$
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event !== null)
      )
      .subscribe((event) => {
        this.handleContextMenuClick(event);
      });
  }

  handleContextMenuClick(button: ProcessButton) {
    if (button.isSpecialType) {
      if (button.isSpecialType === "edit") {
        this.handleEdit(button);
        return;
      }
    }
    if (button.form) {
      this.handleForm(button);
      return;
    }

    if (button.emailDialog) {
      this.handleEmailDialog(button);
      return;
    }

    if (button.infoDialog) {
      this.handleInfoDialog(button);
      return;
    }

    if (button.confirm) {
      this.handleConfirm(button);
      return;
    }

    this.handleCallback(button);
  }

  private toggleButtonLoading(button: ProcessButton) {
    this._contextMenuItems$.next(
      this._contextMenuItems$.value.map((btn) => {
        if (btn.title === button.title) {
          return {
            ...btn,
            isBeingProcessed: !btn.isBeingProcessed,
          };
        }
        return btn;
      })
    );
  }

  handleEdit(button: ProcessButton) {
    const id = button.selected?.[0]?.id;
    // const id = item?.id;
    if (!id) {
      console.log("No ID found for the selected item");
      return;
    }

    this.router.navigate([this.router.url, "edit", id]);
  }

  handleCallback(button: ProcessButton) {
    this.toggleButtonLoading(button);
    this.contextMenuService.processCallback(button).subscribe({
      next: (response: any) => {
        this.refreshFormEvent.emit();
        if (response.processed_data?.length) {
          this.updatedDataEvent.emit(response.processed_data[0]);
        }

        if (response?.message) {
          //   this.toastr.success(response.message);
        }
        if (response?.["refresh_form"]) {
          console.log("test");
          this.refreshFormEvent.emit();
        }
      },
      complete: () => {
        this.toggleButtonLoading(button);
      },
    });
  }

  handleForm(button: ProcessButton) {
    this.commonService
      .openDialog(FormsShellModalComponent, {
        data: {
          selected: button.selected,
          item: button.selected?.[0] ?? null,
          id: button.itemId ?? null,
          name: button.form.config,
          config: button.form.config,
          apiUrl: button.form.callback.url,
          initialData: null,
          modalMode: {
            message: button.form.message,
            buttons: button.form.buttons,
            displayWarningMessage: button.form.displayWaringMessage,
            extraPayload: prepareExtraPayloadForForm(button),
          },
        },
        disableClose: true,
        minWidth: button.form.viewMode?.config?.width ?? "1200px",
        height: button.form.viewMode?.config?.height ?? "auto",
      })
      .subscribe();
  }

  handleEmailDialog(button: ProcessButton) {
    this.commonService
      .openDialog(SendEmailComponent, {
        panelClass: ["new-email-dialog", "fullscreen-modal"],
        data: {
          participants: button.selected,
          sendEmailConfig: { ...button.emailDialog },
        },
        disableClose: true,
      })
      .subscribe();
  }

  handleInfoDialog(button: ProcessButton) {
    const infoDialogConfig = button.infoDialog;
    const url = `${environment_api.api_url}${infoDialogConfig.apiUrl}`;
    this.commonService
      .openDialog(HtmlModalComponent, {
        panelClass: ["fullscreen-modal"],
        data: {
          selected: button.selected,
          config: {
            ...infoDialogConfig,
            apiUrl: url,
          },
        },
        disableClose: true,
      })
      .subscribe();
  }

  handleConfirm(button: ProcessButton) {
    this.commonService
      .confirm({
        btnNoLabel: button.confirm.cancelLabel,
        btnYesLabel: button.confirm.okLabel,
        message: button.confirm.message,
        title: button.title,
        paramDisplayName: button.confirm.paramDisplayName,
        paramName: button.confirm.paramName,
        paramType: button.confirm.paramType,
      })
      .subscribe({
        next: (dialogResult) => {
          if (dialogResult) {
            this.callback(button, dialogResult?.["value"]);
          }
        },
        error: (value) => {},
        complete: () => {},
      });
  }

  callback(button: ProcessButton, value?: any) {
    this.contextMenuService.processCallback(button, value).subscribe({
      next: (response: any) => {
        if (response?.["refresh_form"]) {
          this.refreshFormEvent.emit();
        }
        if (response?.modal === true) {
          this.commonService
            .openDialog(InfoDialogComponent, {
              data: {
                title: response?.title || "Info",
                icon:
                  response?.icon || '<i class="fas fa-car-crash fa-9x"></i>',
                message: response.message,
              },
              width: "500px",
            })
            .subscribe();
        } else {
          this.toastr.success(response?.message);
          //   this.crudShellService.refresh();
        }
        if (response?.redirect) {
          const url = response.redirect.url;
          if (url.indexOf("http") === 0 || url.indexOf("mailto") === 0) {
            setTimeout(() => {
              window.open(
                response.redirect.url,
                response.redirect.target || "_self"
              );
            }, 0);
          }
          //   else {
          //     if (this.crudComponent instanceof CrudTableComponent) {
          //       this.crudComponent.router.navigateByUrl(url);
          //     } else {
          //       this.crudComponent.reloadFormComponent();
          //     }
          //   }
        }
      },
      error: (error) => {
        this.commonService
          .openDialog(InfoDialogComponent, {
            data: {
              title: "Error",
              icon: '<i class="fas fa-bomb  fa-9x"></i>',
              message: this.translateService.instant("COMMON.UNEXPECTED_ERROR"),
            },
            width: "500px",
          })
          .subscribe();
      },
      complete: () => {},
    });
  }
}
