// Angular
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SubheaderService } from '../../../../core/base';

@Component({
	selector: 'static-page-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent implements OnInit, OnDestroy {
	
	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	constructor(private subheaderService: SubheaderService) {

		// Set title to page breadCrumbs
		this.subheaderService.setTitle('Contact Us');
	}

	/**
	 * On destroy
	 */
	ngOnInit(): void {
	
	}
	
	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
	
	}
}
