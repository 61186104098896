// Angular
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
// Layout
import {
  LayoutConfigService,
  MenuAsideService,
  SubheaderService,
} from "../../../core/base";

// Object-Path
import * as objectPath from "object-path";
import { BehaviorSubject } from "rxjs";
import { VERSION } from "../../../../environments/version";
import { ApiConfig } from "./../../../core/config/api.config";
import { DOCUMENT } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { ApiService } from "../../../services";
import { take } from "rxjs/operators";

@Component({
  selector: "kt-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, AfterViewInit {
  // Public properties
  @ViewChild("scriptContainer") scriptContainer: ElementRef<HTMLDivElement>;
  today: number = Date.now();
  fluid: boolean;
  version = VERSION;
  pageInfoUrl$ = new BehaviorSubject<string>(null);

  constructor(
    private layoutConfigService: LayoutConfigService,
    public subheaderService: SubheaderService,
    private menusAsideService: MenuAsideService,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService
  ) {}
  ngAfterViewInit(): void {
    // this.subheaderService.staticInfo$.subscribe((d) => {
    //   if (d) {
    //     const sc = this.document.createElement("script");
    //     sc.innerHTML = d;
    //     this.testDiv.nativeElement.appendChild(sc);
    //   }
    // });

    this.subheaderService.dynamicScripts$.subscribe((s) => {
      if (s.length) {
        s.forEach((script) => {
          const tag = this.document.createElement("script");
          tag.innerHTML = script;
          this.scriptContainer.nativeElement.appendChild(tag);
        });
      }
    });
  }
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    const apiBaseUrl = ApiConfig.InfoURL() + "/dynamic-scripts";
    this.apiService
      .get(apiBaseUrl)
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp) {
          this.subheaderService.setDynamicScripts(resp);
        }
      });

    // footer width fluid
    this.fluid = objectPath.get(config, "footer.self.width") === "fluid";

    // Load page info
    this.menusAsideService.currenMenuItem$.subscribe((menu) => {
      if (menu && menu.page) {
        const apiBaseUrl = ApiConfig.InfoURL() + "/page?menu=" + menu.page;
        this.pageInfoUrl$.next(apiBaseUrl);
      }
    });
  }
}
