import { HttpHeaders } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { InfoDialogComponent } from "../../../../../../views/partials/content";
import { CommonService } from "../../../../../../services/common.service";
import { FormConfigService } from "../../../../../cb-forms/form.config.service";
import { ProcessButton } from "../../../../../../shared/data/models";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-process-button",
  templateUrl: "./process-button.component.html",
  styleUrls: ["./process-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessButtonComponent {
  @Input() button: ProcessButton;
  constructor(
    private formConfigService: FormConfigService,
    private commonService: CommonService,
    private translateService: TranslateService
  ) {}

  clickHandler(event) {
    if (this.button.confirm) {
      this.handleConfirm();
    } else {
      this.callback();
    }
  }

  handleConfirm() {
    this.commonService
      .confirm({
        btnNoLabel: this.button.confirm.cancelLabel,
        btnYesLabel: this.button.confirm.okLabel,
        message: this.button.confirm.message,
        title: this.button.title,
        paramDisplayName: this.button.confirm.paramDisplayName,
        paramName: this.button.confirm.paramName,
        paramType: this.button.confirm.paramType,
      })
      .subscribe({
        next: (dialogResult) => {
          if (dialogResult) {
            this.callback(dialogResult?.["value"]);
          }
        },
        error: (value) => {},
        complete: () => {},
      });
  }

  callback(value?: any) {
    this.formConfigService.processCallback(this.button, value).subscribe({
      next: (response: any) => {
        if (response?.modal === true) {
          this.commonService
            .openDialog(InfoDialogComponent, {
              data: {
                title: response?.title || "Info",
                icon:
                  response?.icon || '<i class="fas fa-car-crash fa-9x"></i>',
                message: response.message,
              },
              width: "500px",
            })
            .subscribe();
        }
        if (response.redirect) {
          const url = response.redirect.url;
          if (url.indexOf("http") === 0 || url.indexOf("mailto") === 0) {
            setTimeout(() => {
              window.open(
                response.redirect.url,
                response.redirect.target || "_self"
              );
            }, 0);
          }
          //   else {
          //     if (this.crudComponent instanceof CrudTableComponent) {
          //       this.crudComponent.router.navigateByUrl(url);
          //     } else {
          //       this.crudComponent.reloadFormComponent();
          //     }
          //   }
        }
      },
      error: (error) => {
        this.commonService
          .openDialog(InfoDialogComponent, {
            data: {
              title: "Error",
              icon: '<i class="fas fa-bomb  fa-9x"></i>',
              message: this.translateService.instant("COMMON.UNEXPECTED_ERROR"),
            },
            width: "500px",
          })
          .subscribe();
      },
      complete: () => {},
    });
  }
}
