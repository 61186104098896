<div>
    <crud-table
            title="Trackday Bookings"
            name="trackday-bookings-short"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/trackdays-management/bookings"
            [readonly]="false"
            [hasReadonlyActions]="true"
    ></crud-table>
</div>
