import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";
@Component({
  selector: "service-form",
  templateUrl: "./service-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceFormComponent implements OnInit {
  apiUrl: string;
  constructor() {}

  ngOnInit() {
    this.apiUrl = ApiConfig.ServicesURL();
  }
}
