<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
    <crud-table
            title="Draft Bookings"
            name="draft-bookings"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [apiUrl]="apiUrl"
            baseUrl="/trackdays-management/draft-bookings"
            [searchableColumns]="searchableColumns"
            [hasReadonlyActions]="true"
    ></crud-table>
</div>
