import { Injectable } from "@angular/core";
import { QueryParamsModel } from "../core/base/models/query-params.model";
@Injectable({ providedIn: "root" })
export class LocalStorageService {
  constructor() {}

  setItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  getItem = (key: string) => {
    return JSON.parse(localStorage.getItem(key));
  };

  clear = (key: string) => {
    return localStorage.removeItem(key);
  };

  saveFilters = (key: string, value: QueryParamsModel) => {
    if (key && value) {
      this.setItem(key, value);
    }
  };
}
