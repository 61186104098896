import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { LocalDatePipe, QueryParamsModel } from "../../../../../core/base";
import { ApiConfig } from "../../../../../core/config/api.config";
import { CrudTableService } from "../../../../../services";
import { CommonService } from "../../../../../services/common.service";
import { WizardTab } from "../../../../../shared/data/models";
import { ConfirmActionDialogComponent } from "../../../../../views/partials/content";
import { FormConfigService } from "../../../form.config.service";

@Component({
  selector: "cb-wizard-builder",
  templateUrl: "./wizard-builder.component.html",
  styleUrls: ["./wizard-builder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardBuilderComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private wizardElement: ElementRef;
  @ViewChild("wizard") set content(content: ElementRef) {
    if (content) {
      this.wizardElement = content;
    }
  }

  @Output() closeFormEvent = new EventEmitter();

  isFormSaving$ = new BehaviorSubject(false);
  destroyed$ = new Subject<void>();
  wizardStepSummaryApiUrl$ = new BehaviorSubject<string>(null);
  currentWizardStep$ = new BehaviorSubject(1);
  wizardTabs: WizardTab[] = [];
  wizardForms: UntypedFormGroup = null;
  formWizardApi: any;
  bookingSummaryUrl$ = new BehaviorSubject("");
  shouldShowSubmitButton$ = new BehaviorSubject(false);
  currentStepErrors$ = new BehaviorSubject<string>("");
  constructor(
    private formConfigService: FormConfigService,
    private crudTableService: CrudTableService,
    private localDate: LocalDatePipe,
    private commonService: CommonService
  ) {}
  ngAfterViewInit(): void {
    // const invoiceAddressForm = this.formConfigService.form.controls[
    //   "invoice address"
    // ] as FormGroup;
    this.initiateWizard();

    (this.formConfigService.form.controls['price_and_status'] as UntypedFormGroup).controls['price_gross'].valueChanges.subscribe(console.log)
  }

  ngOnInit(): void {
    this.wizardTabs = this.formConfigService.wizardTabs;
    this.wizardForms = this.formConfigService.form;
  }

  updateWizardStepSummaryApiUrl(wizardObj) {
    const currentStep = wizardObj.currentStep;
    const currentTab = this.wizardTabs[currentStep - 1];
    this.currentWizardStep$.next(currentStep);    
    if (currentTab && !!currentTab.show_step_summary) {
      const url = `${ApiConfig.TrackdayBookingsUrl()}/wizard-step-summary?id=${
        this.formConfigService.id
      }&step=${currentStep}`;
      this.wizardStepSummaryApiUrl$.next(url);
    } else {
      this.wizardStepSummaryApiUrl$.next(null);
    }
  }

  initiateWizard() {
    this.formWizardApi = new KTWizard(this.wizardElement.nativeElement, {
      startStep: 1,
      clickableSteps: false,
    });

    this.formWizardApi.on("change", (wizardObj) => {
      this.updateWizardStepSummaryApiUrl(wizardObj);
      this.updateSubmitButtonStatus();
      this.currentStepErrors$.next("");
    });

    this.formWizardApi.on("beforeNext", (wizardObj) => {
      // wizardObj.stop();      
      const currentTab = this.wizardTabs[wizardObj.currentStep - 1];
      const isFormValid = this.validateTab(wizardObj);
      if (!isFormValid) {
        wizardObj.stop();
        this.formConfigService.validateAllFormFields();
        return;
      }
      if (currentTab.name === "user_info") {
        this.draftBooking(wizardObj);
      } else if (currentTab.name === "price_and_status") {
        this.priceAndStatus(wizardObj);
      } else if (currentTab.name === "qualifications") {
        this.qualifications(wizardObj);
      } else if (currentTab.name === "start_number") {
        this.startNumber(wizardObj);
      } else if (currentTab.name === "laptime") {
        this.laptime(wizardObj);
      }

      this.updateWizardStepSummaryApiUrl(wizardObj);
      this.updateSubmitButtonStatus();
    });
  }

  laptime(wizardObj) {
    let formData = { ...this.getFormData(), _submit: false };
    wizardObj.stop();
    this.isFormSaving$.next(true);
    this.formConfigService
      .updateItem(this.formConfigService.apiUrl, formData)
      .subscribe(
        (res) => {
          this.isFormSaving$.next(false);
          wizardObj.goNext();
          wizardObj.start();
          this.updateWizardStepSummaryApiUrl(wizardObj);
          this.bookingSummaryUrl$.next(
            this.formConfigService.getApiUrl(
              this.wizardTabs[this.wizardTabs.length - 1]?.["html_content"]?.[
                "api_url"
              ]
            )
          );          
          this.updateSubmitButtonStatus();
        },
        (res: any) => {
          this.isFormSaving$.next(false);
          //   this.catchServerError(res);
        }
      );
  }

  startNumber(wizardObj) {
    let formData = { ...this.getFormData(), _submit: false };
    wizardObj.stop();
    this.isFormSaving$.next(true);

    const apiUrl = ApiConfig.ParticipantsTrackdayItemsStartnumberURL();
    this.formConfigService
      .updateItem(apiUrl, {
        id: formData["id"],
        start_number: formData["start_number"],
      })
      .subscribe(
        (res) => {
          this.isFormSaving$.next(false);
          wizardObj.goNext();
          wizardObj.start();
          this.updateWizardStepSummaryApiUrl(wizardObj);
          this.updateSubmitButtonStatus();
        },
        (res: any) => {
          this.isFormSaving$.next(false);
          //   this.catchServerError(res);
        }
      );
  }

  qualifications(wizardObj) {
    let formData = { ...this.getFormData(), _submit: false };
    wizardObj.stop();
    this.isFormSaving$.next(true);
    this.formConfigService
      .updateItem(this.formConfigService.apiUrl, formData)
      .subscribe(
        (res) => {
          this.isFormSaving$.next(false);
          wizardObj.goNext();
          wizardObj.start();
          this.updateWizardStepSummaryApiUrl(wizardObj);
          this.updateSubmitButtonStatus();
        },
        (res: any) => {
          this.isFormSaving$.next(false);
          //   this.catchServerError(res);
        }
      );
  }

  draftBooking(wizardObj) {
    wizardObj.stop();
    this.isFormSaving$.next(true);
    const userId = this.formConfigService.formData?.["user_id"];

    const draftBookingsApiUrl = ApiConfig.DraftBookingsUrl();

    const queryParams = new QueryParamsModel({
      user_id: userId,
      trackday_items_id: this.formConfigService.formData?.["trackday_items_id"],
    });
    this.crudTableService
      .findItems(draftBookingsApiUrl, queryParams)
      .pipe(finalize(() => this.isFormSaving$.next(false)))
      .subscribe({
        next: (response: any) => {
          if (response && response.total > 0) {
            const booking = response.data[0];
            const createdAt = this.localDate.transform(
              booking["created_at"],
              "date"
            );

            if (booking.id === userId) {
              wizardObj.goNext();
              wizardObj.start();
              return;
            }

            this.commonService
              .openDialog(ConfirmActionDialogComponent, {
                data: {
                  title: "Continue draft booking?",
                  message: `This user has draft booking created at ${createdAt}. Continue this draft booking?`,
                },
                width: "500px",
              })
              .subscribe({
                next: (dialogResult) => {
                  if (dialogResult) {
                    this.formConfigService.updateFormData({
                      ...this.formConfigService.formData,
                      ...booking,
                      _submit: false,
                    });
                    this.formConfigService.id = booking?.id;
                    wizardObj.goNext();
                    wizardObj.start();
                  } else {
                    this.isFormSaving$.next(true);
                    this.crudTableService
                      .deleteItem(draftBookingsApiUrl, booking.id)
                      .subscribe((response: any) => {
                        this.isFormSaving$.next(false);
                        wizardObj.goNext();
                        wizardObj.start();
                      });
                  }
                },
              });
          } else {
            wizardObj.goNext();
            wizardObj.start();
          }
        },
        error: (res: any) => {
          //handle error
        },
      });
  }

  priceAndStatus(wizardObj) {
    wizardObj.stop();
    this.isFormSaving$.next(true);
    let formData = { ...this.getFormData(), _submit: false };

    if (!this.formConfigService.formData["id"]) {
      this.formConfigService
        .createItem(this.formConfigService.apiUrl, formData)
        .subscribe(
          (response) => {
            this.formConfigService.id = response["id"];
            this.formConfigService.updateFormData({
              ...this.formConfigService.formData,
              ...response,
            });
            // this.formConfigService.patchWizardFormWithData({
            //   ...this.formConfigService.formData,
            //   ...response,
            // });

            this.wizardTabs = [...this.wizardTabs];

            this.isFormSaving$.next(false);
            wizardObj.goNext();
            wizardObj.start();
            this.updateWizardStepSummaryApiUrl(wizardObj);
            this.updateSubmitButtonStatus();
          },
          (res: any) => {
            this.isFormSaving$.next(false);
            // this.catchServerError(res);
          }
        );
    } else {
      this.formConfigService
        .updateItem(this.formConfigService.apiUrl, {
          ...formData,
          info_only: true,
        })
        .subscribe(
          (response) => {
            this.formConfigService.updateFormData({
              ...this.formConfigService.formData,
              ...response,
            });

            this.isFormSaving$.next(false);
            wizardObj.goNext();
            wizardObj.start();

            this.updateWizardStepSummaryApiUrl(wizardObj);
            this.updateSubmitButtonStatus();
          },
          (res: any) => {
            this.isFormSaving$.next(false);
            // this.catchServerError(res);
          }
        );
    }
  }

  updateSubmitButtonStatus() {
    const currentStep = this.formWizardApi.currentStep;
    const vehicleValidator = this.wizardTabs[4].table?.[0].required;
    const privateServiceValidator = this.wizardTabs[7].table?.[0].required;
    const startNumberValidator = this.wizardTabs[9].fields?.[0]?.["validator"];
    const lapTimeValidator = this.wizardTabs[10].fields?.[0]?.["validator"];

    const isOwnVehicleMandatory = !!this.formConfigService.parsePlaceholders(
      vehicleValidator,
      true
    );
    const isPrivateServiceMandatory =
      !!this.formConfigService.parsePlaceholders(privateServiceValidator, true);
    const isStartNumberRequired =
      this.formConfigService.parsePlaceholders(startNumberValidator, true) ===
      null
        ? false
        : true;

    const isLapTimeRequired =
      this.formConfigService.parsePlaceholders(lapTimeValidator) === null
        ? false
        : true;

    if (isLapTimeRequired) {
      return currentStep > 11
        ? this.shouldShowSubmitButton$.next(true)
        : this.shouldShowSubmitButton$.next(false);
    }
    if (isStartNumberRequired) {
      return currentStep > 10
        ? this.shouldShowSubmitButton$.next(true)
        : this.shouldShowSubmitButton$.next(false);
    }

    if (isPrivateServiceMandatory && currentStep < 9) {
      return this.shouldShowSubmitButton$.next(false);
    }

    if (isOwnVehicleMandatory && currentStep < 6) {
      return this.shouldShowSubmitButton$.next(false);
    }

    return currentStep > 4
      ? this.shouldShowSubmitButton$.next(true)
      : this.shouldShowSubmitButton$.next(false);
  }

  validateTab(wizardObj) {
    const currentStep = this.wizardTabs[wizardObj.currentStep - 1];
    this.currentStepErrors$.next("");
    if (currentStep["table"]) {
      const table = currentStep["table"][0];
      const required = this.formConfigService.parsePlaceholders(
        table["required"]
      );
      if (required) {
        if (!table.items || !table.items.length) {
          this.currentStepErrors$.next("Please add at least one item.");
          return false;
        }
        return true;
      }
      return true;
    }
    const currentForm = this.wizardForms.controls[
      currentStep.name
    ] as UntypedFormGroup;
    return currentForm.disabled ? true : currentForm.valid;
  }

  debug() {
    console.log(this.wizardForms);
  }

  getFormData() {
    let formData = this.formConfigService.getFormRawData();
    formData = {
      ...this.formConfigService.formData,
      ...formData,
    };
    return formData;
  }

  submit() {
    this.isFormSaving$.next(true);
    let formData = this.getFormData();
    this.formConfigService
      .updateItem(this.formConfigService.apiUrl, {
        ...formData,
        info_only: true,
      })
      .subscribe(
        (response) => {
          this.formConfigService.updateFormData({
            ...this.formConfigService.formData,
            ...response,
          });

          this.isFormSaving$.next(false);
          this.closeFormEvent.emit();
        },
        (res: any) => {
          this.isFormSaving$.next(false);
          // this.catchServerError(res);
        }
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
