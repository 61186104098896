import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import Pusher from "pusher-js";
import { HttpUtilsService } from "../core/base";
import { environment } from "../../environments/environment";
import { ApiConfig } from "../core/config/api.config";
import { WsConfigModel, WsChannel } from "../core/base/models/ws.config.model";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "../core/store/reducers/app.reducers";
import { reloadControllerActions } from "../core/store/actions/reload-controller.actions";

export enum WS_CONNECTION_STATUS {
  connected = "connected",
  disconnect = "disconnect",
  connecting = "connecting",
}

@Injectable({ providedIn: "root" })
export class WsService {
  pusher: Pusher = null;
  userToken = localStorage.getItem(environment.authTokenKey);
  wsConfig: WsConfigModel = null;
  connectionStatus$ = new BehaviorSubject<WS_CONNECTION_STATUS>(
    WS_CONNECTION_STATUS.disconnect
  );
  constructor(
    private http: HttpClient,
    private httpUtilsService: HttpUtilsService,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {}

  init() {
    // TEMP - should be removed from here
    this.store.dispatch(
      reloadControllerActions.updateConnectionStatus({
        connectionStatus: true,
      })
    );

    if (this.pusher !== null) return;
    const httpHeaders = new HttpHeaders();
    this.http.get<WsConfigModel>(ApiConfig.WsUrl()).subscribe(
      (res) => {
        this.wsConfig = res;
        this._init();
      },
      (error) => {
        console.log(
          "There seems to be a problem getting WebSocket config. Maybe you are not logged in."
        );
      }
    );
  }

  private _init() {
    // this.wsConfig.PUSHER_APP_USE_TLS = true;
    this.pusher = new Pusher(this.wsConfig.PUSHER_APP_KEY, {
      wsHost: this.wsConfig.PUSHER_HOST,
      // wssHosts: this.wsConfig.PUSHER_HOST,
      wsPort: this.wsConfig.PUSHER_PORT,
      wssPort: this.wsConfig.PUSHER_PORT,
      forceTLS: this.wsConfig.PUSHER_APP_USE_TLS,
      disableStats: true,
      enabledTransports: ["ws", "wss", "xhr_polling"],
    });

    this.pusher.connection.bind("state_change", (state) =>
      this._updateConnectionStatus(state)
    );

    Object.values(this.wsConfig.PUSHER_CHANNEL).forEach((channel) => {
      // console.log("Connecting to ", channel);
      this._subscribeTo(channel);
    });
    // this._subscribeTo(this.wsConfig.PUSHER_CHANNEL["messaging"]);
  }

  _subscribeTo({ channel, event, type }: WsChannel) {
    let that = this;
    const room = this.pusher.subscribe(channel).bind(event, (message) => {
      const channelType = that.wsChannelNameParser(channel);
      if (channelType === "message") {
        that.buildNotification(message["class"], message["message"], message["title"]);
      }
      // we should test this
      if (channelType === "update") {
        that.store.dispatch(
          reloadControllerActions.updateTargetComponent({
            targetComponent: message["target"],
          })
        );
      }
    });
  }

  _connect() {
    if (!this.pusher) {
      this.pusher.connect();
    }
  }

  disconnect() {
    this.pusher.disconnect();
  }

  private _updateConnectionStatus(states) {
    const currentState = states.current;
    // console.log("Channels current state is " + currentState);
    switch (currentState) {
      case "connecting":
        this.connectionStatus$.next(WS_CONNECTION_STATUS.connecting);
        break;
      case "connected": {
        this.store.dispatch(
          reloadControllerActions.updateConnectionStatus({
            connectionStatus: true,
          })
        );
        this.connectionStatus$.next(WS_CONNECTION_STATUS.connected);
        break;
      }
      default: {
        this.store.dispatch(
          reloadControllerActions.updateConnectionStatus({
            connectionStatus: false,
          })
        );
        this.connectionStatus$.next(WS_CONNECTION_STATUS.disconnect);
        break;
      }
    }
  }

  buildNotification(type: string | void, message: string, title?: string) {
    switch (type) {
      case "success":
        this.toastr.success(message, title, {
          enableHtml: true,
          progressBar: true,
          extendedTimeOut: 5000
        });
        break;
      case "sticky_success":
        this.toastr.success(message, title, {
          enableHtml: true,
          //disableTimeOut: true,
          timeOut: 20000,
          progressBar: true,
        });
        break;
      case "warning":
        this.toastr.warning(message, title, {
          enableHtml: true,
          progressBar: true,
        });
        break;
      case "sticky_warning":
        this.toastr.warning(message, title, {
          //disableTimeOut: true,
          timeOut: 20000,
          enableHtml: true,
          progressBar: true,
        });
        break;
      case "error":
        this.toastr.error(message, title, {
          enableHtml: true,
          progressBar: true,
        });
        break;
        case "sticky_error":
        this.toastr.error(message, title, {
         // disableTimeOut: true,
          timeOut: 20000,
          enableHtml: true,
          progressBar: true,
        });
        break;
      case "sticky_info":
        this.toastr.info(message, title, {
          // disableTimeOut: true,
          timeOut: 20000,
          enableHtml: true,
          progressBar: true,
        });
        break;
      case "info":
        this.toastr.info(message, title, {
          enableHtml: true,
          progressBar: true,
        });
        break;
      default:
        this.toastr.info(message);
        break;
    }
  }

  getCurrentWsStatus(): BehaviorSubject<WS_CONNECTION_STATUS> {
    return this.connectionStatus$;
  }

  wsChannelNameParser(channelName: string) {
    return channelName.split(".")[0];
  }

  ngOnDestroy() {
    this.pusher.unbind_all();
    this.disconnect();
  }
}
