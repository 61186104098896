// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials/partials.module";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../core/base";
// Shared
// Components
import { CrudFormModule } from "../../partials/content/crud-form/crud-form.module";
import { CrudTableModule } from "../../partials/content/crud-table/crud-table.module";
import { TimeInputModule } from "../../partials/content/time-input/time-input.module";

import { UserEffects, usersReducer } from "../../../core/store";
import { CbFormsModule } from "../../../modules/cb-forms/cb-forms.module";
import { MaterialModule } from "../../../modules/material/material.module";
import { TemplatesFormComponent } from "./templates/templates-form/templates-form.component";
import { TemplatesTableComponent } from "./templates/templates-table/templates-table.component";

const routes: Routes = [
  {
    path: "templates",
    component: TemplatesTableComponent,
  },
  {
    path: "templates/edit/:id",
    component: TemplatesFormComponent,
  },
  {
    path: "templates/edit/:id/:tab",
    component: TemplatesFormComponent,
  },
  {
    path: "templates/create",
    component: TemplatesFormComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature("user", usersReducer),
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),

    CrudTableModule,
    CrudFormModule,
    TimeInputModule,
    CbFormsModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },

    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [TemplatesTableComponent, TemplatesFormComponent],
})
export class ProjectTemplateManagementModule {}
