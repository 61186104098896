// Angular
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials/partials.module";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../core/base";
// Shared
// Components
import { ChangePasswordComponent } from "./profile-info/_subs/change-password/change-password.component";
import { ProfileImageComponent } from "./profile-info/_subs/profile-image/profile-image.component";
import { UserRolesListComponent } from "./profile-info/_subs/user-roles/user-roles-list.component";
import { ProfileInfoComponent } from "./profile-info/profile-info.component";
import { ProfileComponent } from "./profile.component";

import { ClipboardModule } from "@angular/cdk/clipboard";
import { CoreModule } from "../../../core/core.module";
import { UserEffects, usersReducer } from "../../../core/store";
import { CbFormsModule } from "../../../modules/cb-forms/cb-forms.module";
import { ConfigurationShellModule } from "../../../modules/configuration-shell/configuration-shell.module";
import { MotoDbService } from "../../../services";
import { CrudFormModule } from "../../partials/content/crud-form/crud-form.module";
import { CrudTableModule } from "../../partials/content/crud-table/crud-table.module";
import { TimeInputModule } from "../../partials/content/time-input/time-input.module";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { UserConfigurationsPageComponent } from "./configuration/user-configurations.page.component";
import { LaptimeListComponent } from "./profile-info/_subs/laptime/laptime-list.component";
import { LaptimeTableComponent } from "./profile-info/_subs/laptime/table/laptime-table.component";
import { ProfilePasswordTwoFactorComponent } from "./profile-password-two-factor/profile-password-two-factor.component";
import { MaterialModule } from "../../../modules/material/material.module";

const routes: Routes = [
  {
    path: "",
    component: ProfileComponent,
    children: [
      {
        path: "",
        redirectTo: "roles",
        pathMatch: "full",
      },
      {
        path: "info",
        component: ProfileInfoComponent,
      },
      {
        path: "password-two-factor",
        component: ProfilePasswordTwoFactorComponent,
      },
      {
        path: "configuration",
        component: UserConfigurationsPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature("users", usersReducer),
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MaterialModule,
    CrudTableModule,
    CrudFormModule,
    TimeInputModule,
    ClipboardModule,
    CbFormsModule,
    ConfigurationShellModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    MotoDbService,
  ],
  declarations: [
    ProfileComponent,
    ProfileInfoComponent,
    UserRolesListComponent,
    ChangePasswordComponent,
    ProfileImageComponent,
    LaptimeListComponent,
    LaptimeTableComponent,
    ProfilePasswordTwoFactorComponent,
    ConfigurationComponent,
  ],
})
export class ProfileModule {}
