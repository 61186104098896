// Angular
import { Component, Inject } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "kt-token-expired-dialog",
  templateUrl: "./token-expired-dialog.component.html",
})
export class TokenExpiredDialogComponent {
  selectedStatusForUpdate = new UntypedFormControl("");
  constructor(
    public dialogRef: MatDialogRef<TokenExpiredDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  onSubmit() {
    this.router.navigateByUrl("/auth/login");
    this.dialogRef.close();
  }
}
