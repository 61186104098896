// Angular
import { Component, HostBinding, OnInit, Input } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
// RxJS
import { filter } from "rxjs/operators";
import { LanguageFlag } from "../../../../../core/base/services/translation.service";
// Translate
import { TranslationService } from "../../../../../core/base";
import { AuthService } from "../../../../../core/store";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../../core/store/reducers/app.reducers";
import { appGlobalConfigActions } from "../../../../../core/store/actions/app.global.config.actions";

@Component({
  selector: "kt-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
})
export class LanguageSelectorComponent implements OnInit {
  // Public properties
  @HostBinding("class") classes = "";
  @Input() iconType: "" | "brand";

  language: LanguageFlag;
  languages: LanguageFlag[];

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   */
  constructor(
    private translationService: TranslationService,
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>
  ) {
    this.languages = this.translationService.getAvailableLanguages();
  }

  ngOnInit() {
    this.setSelectedLanguage();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        this.setSelectedLanguage();
      });
  }

  /**
   * Set language
   *
   * @param lang: any
   */
  setLanguage(lang) {
    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
    this.translationService.setLanguage(lang);
  }

  onSelectLanguage(language: LanguageFlag) {
    this.setLanguage(language.lang);

    // save testdata switched to user config
    this.authService
      .saveUserConfig({
        lang_config: language.lang,
      })
      .subscribe((result) => {
        // redirect to dashboard
        let currentUrl = this.router.url;
        this.router
          .navigateByUrl("/profile/info", { skipLocationChange: true })
          .then(() => this.router.navigate(["/"]));
      });
  }

  /**
   * Set selected language
   */
  setSelectedLanguage(): any {
    if (this.language == null) {
      // we check testdata from user config
      this.authService.getUserConfig().subscribe((config) => {
        this.store.dispatch(
          appGlobalConfigActions.updateAppGlobalConfig(
            config["user_config"] || null
          )
        );
        config.lang_config;
        let lang =
          config.lang_config || this.translationService.getSelectedLanguage();
        this.setLanguage(lang);
      });
    }
  }
}
