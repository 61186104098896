<header matDialogTitle>
  <div class="text">
    <span class="title">
      <i class="fa-light fa-arrow-up-1-9"></i>
      <span>Save template</span>
    </span>
  </div>
  <div class="modal-actions">
    <button      
      mat-flat-button
      color="warn"
      (click)="close()"
      cdkFocusInitial
    >
    <i class="fa-solid fa-circle-x"></i>
      Close
    </button>
    <button
      color="success"
      mat-flat-button
      (click)="save()"
      cdkFocusInitial
    >
      <i class="fa-solid fa-floppy-disk"></i>
      Save
    </button>
  </div>
</header>
<div class="dialog-body" matDialogContent>
  <form class="form" [formGroup]="form">
    <mat-form-field class="mat-form-field-fluid custom-input">
      <input
        matInput
        [placeholder]="'Enter name of the template'"
        formControlName="name"
        appAutoFocus
      />
    </mat-form-field>
    <div class="radio-container">
      <div>
        <h3>Mode</h3>
      </div>
      <div class="radio-group-container">
        <mat-radio-group formControlName="mode">
          <mat-radio-button
            color="primary"
            *ngFor="let mode of templateModes"
            class="columns-export-type-radio-button"
            [value]="mode.value"
          >
            {{ mode.text }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>
  <div class="preview-container">
    <div
      class="preview-wrapper"
      *ngIf="displayedColumns$ | async as displayedColumns"
    >
      <div class="header">
        <h3 class="text">Columns and orders to be saved</h3>
        <span class="count">{{ displayedColumns.length }}</span>
      </div>

      <div class="preview">
        <div class="pill" *ngFor="let column of displayedColumns">
          <div class="order">
            {{ column.order + 1 }}
          </div>
          <div class="separator"></div>
          <div class="text">
            {{ column.title }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="preview-wrapper"
      *ngIf="remainingColumns$ | async as remainingColumns"
    >
      <div class="header">
        <h3 class="text">Excluded in this template</h3>
        <span class="count excluded">{{ remainingColumns.length }}</span>
      </div>
      <div class="preview">
        <div class="pill excluded" *ngFor="let column of remainingColumns">
          {{ column.title }}
        </div>
      </div>
    </div>
  </div>
</div>
