import { Pipe, PipeTransform } from "@angular/core";
import { FormConfigService } from "../form.config.service";

@Pipe({
  name: "translationFromConfig",
})
export class TranslationFromConfigPipe implements PipeTransform {
  constructor(private formConfigService: FormConfigService) {}
  transform(value: any): any {
    return this.formConfigService.getTranslationOf(value);
  }
}
