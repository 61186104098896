<ng-container *ngIf="!(loading$ | async); else loading">
  <div *ngIf="displayRefreshButton" class="d-flex justify-content-end mb-4">
    <a
      (click)="loadHtml()"
      class="btn btn-outline-primary reset-button-styles success-outline-button"
      mat-raised-button
      matTooltip="{{ 'COMMON.REFRESH' | translate }}"
      [attr.data-pgm-formbutton]="'formbutton_refresh'"
    >
      <i class="fa-solid fa-arrows-rotate"></i>
      <span class="kt-hidden-mobile">
        {{ "COMMON.REFRESH" | translate }}
      </span>
    </a>
  </div>
  <div *ngIf="innerHtml" [innerHTML]="innerHtml | safe" runScripts></div>
</ng-container>
<ng-template #loading>
  <div class="loadingContainer">    
    <cb-loading [isAbsolute]="false"></cb-loading>
  </div>
</ng-template>
