// Angular
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewRef,
} from "@angular/core";
// RxJS
import { Observable, Subscription } from "rxjs";
// Object-Path
import * as objectPath from "object-path";
// Layout
import {
  LayoutConfigService,
  MenuConfigService,
  PageConfigService,
} from "../../../core/base";
import { LayoutConfig } from "../../../core/config/layout.config";
import { PageConfig } from "../../../core/config/page.config";
import { HtmlClassService } from "../html-class.service";
// User permissions
import { select, Store } from "@ngrx/store";
import { NgxPermissionsService } from "ngx-permissions";
import { currentUserPermissions, Permission } from "../../../core/store";
import { AppState } from "../../../core/store/reducers/app.reducers";
import { MenusConfigService } from "../../../services";
// Auth
import { environment_api } from "../../../../environments/environment.api";
import { LoadingIndicatorService } from "../../../core/base";
import { AuthService } from "../../../core/store/";
import { helpSidebarSelectors } from "../../../core/store/selectors/help-sidebar.selectors";
@Component({
  selector: "kt-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BaseComponent implements OnInit, OnDestroy {
  store = inject(Store<AppState>);
  // Public variables
  selfLayout: string;
  asideDisplay: boolean = true;
  asideSecondary: boolean;
  subheaderDisplay: boolean;
  desktopHeaderDisplay: boolean = false;
  fitTop: boolean;
  fluid: boolean;

  // Private properties
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private currentUserPermissions$: Observable<Permission[]>;
  isPageLoader: boolean = true;
  sidebarStatus$ = this.store.pipe(
    select(helpSidebarSelectors.selectSidebarStatus)
  );

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param menuConfigService: MenuConfifService
   * @param pageConfigService: PageConfigService
   * @param htmlClassService: HtmlClassService
   * @param store
   * @param permissionsService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private permissionsService: NgxPermissionsService,
    private menusConfigService: MenusConfigService,
    private cRef: ChangeDetectorRef,
    private auth: AuthService,
    public loadingIndicatorService: LoadingIndicatorService
  ) {
    this.loadRolesWithPermissions();
    // this.menuConfigService.loadConfigs(new MenuConfig().configs);
    // this.menus = new MenuConfig().configs;
    // this.menusConfigService.getMenuConfig('en', 'menu').subscribe(res => {
    //     this.auth.getAllPermissions().subscribe(response => {
    //         response.forEach(data => {
    //             if(data['role_name'] === 'Administrators') {
    //               res.config.Administrators.forEach(element => {
    //                   this.menus.aside.items.push(element);
    //               });
    //             }else if(data['role_name'] === 'TrackdayUsers') {
    //               res.config.TrackdayUsers.forEach(element => {
    //                   this.menus.aside.items.push(element);
    //               });
    //             }
    //         });
    //     });
    // });
    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    // this.menuConfigService.loadConfigs(this.menus);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(
      (layoutConfig) => {
        // reset body class based on global and page level layout config, refer to html-class.service.ts
        document.body.className = "";
        this.htmlClassService.setConfig(layoutConfig);
      }
    );
    this.unsubscribe.push(subscr);

    this.unsubscribe.push(
      this.loadingIndicatorService.allRequestFinished.subscribe(() => {
        console.log('cdr called');
        // this.crefDetectChanges();
      })
    );
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    // save environment config to be access by js script later
    localStorage.setItem("env_api_url", environment_api.api_url);
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, "self.layout");
    this.subheaderDisplay = objectPath.get(config, "subheader.display");
    this.fitTop = objectPath.get(config, "content.fit-top");
    this.fluid = objectPath.get(config, "content.width") === "fluid";

    const frontendNavs = (
      localStorage.getItem("frontend_navigation") || "topbar"
    ).split(",");
    if (frontendNavs) {
      this.desktopHeaderDisplay = frontendNavs.indexOf("topbar") >= 0;
      this.asideDisplay = frontendNavs.indexOf("sidebar") >= 0;
    }

    // let the layout type change
    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(
      (cfg) => {
        setTimeout(() => {
          this.selfLayout = objectPath.get(cfg, "self.layout");
        });
      }
    );
    this.unsubscribe.push(subscr);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  /**
   * NGX Permissions, init roles
   */
  loadRolesWithPermissions() {
    this.currentUserPermissions$ = this.store.pipe(
      select(currentUserPermissions)
    );
    const subscr = this.currentUserPermissions$.subscribe((res) => {
      if (!res || res.length === 0) {
        return;
      }

      this.permissionsService.flushPermissions();
      res.forEach((pm: Permission) =>
        this.permissionsService.addPermission(pm.name)
      );
    });
    this.unsubscribe.push(subscr);
  }

  pageLoader(loader) {
    // setTimeout(() => {
    this.isPageLoader = false;
    // }, 1000);
  }

  debug() {
    console.log(this.loadingIndicatorService.debug());
  }

  crefDetectChanges() {
    if (this.cRef && !(this.cRef as ViewRef).destroyed) {
      this.cRef.detectChanges();
    }
  }
}
