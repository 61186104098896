<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
      <div class="kt-portlet__head-progress"></div>
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title">
            Create Board for Project ({{activeProject?.title}})
        </h3>
        <span class="kt-portlet__head-icon kt-hide">
          <i class="la la-gear"></i>
        </span>
      </div>
    </div>
    <div class="kt-form">
      <div class="kt-portlet__body">
        <div class="form-group kt-form__group w-all">
          <form
          [formGroup]="boardForm">
            <div class="form-row">
                <mat-form-field class="w-all w-100">
                  <mat-label>Title</mat-label>
                  <input
                    matInput
                    autocomplete="do_not_fill"
                    formControlName="title"
                    [placeholder]="'title'"
                  />
                  <mat-error *ngIf="!boardForm.get['title']?.valid">
                    {{ boardForm.get("title").getError("invalid") }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="w-all w-100">
                  <mat-label>Description</mat-label>
                  <input
                    matInput
                    autocomplete="do_not_fill"
                    formControlName="description"                    
                    [placeholder]="'description'"
                  />
                  <mat-error *ngIf="!boardForm.get['description']?.valid">
                    {{ boardForm.get("description").getError("invalid") }}
                  </mat-error>
                </mat-form-field>
              </div>
          </form>
        </div>
      </div>
      <div
        class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right"
      >
        <div class="kt-form__actions kt-form__actions--sm" style="height: 64px;">
          <button
            mat-raised-button
            class="btn btn-outline-primary reset-button-styles info-outline-button pull-left"            
            [mat-dialog-close]="false"
          >
            Cancel
          </button>
          <button
            mat-raised-button
            [disabled]="!boardForm.valid"
            (click)="createBoard(activeProject?.id)"
            class="btn btn-outline-primary reset-button-styles success-outline-button pull-right"           
            
          >
            <i class="la la-save"></i>
            <span class="kt-hidden-mobile">
              Create
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  