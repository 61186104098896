import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from "rxjs/operators";
import { FilterService } from "../../services/filter.service";
import { searchFilter } from "../../types/types";

@Component({
  template: `
    <mat-form-field class="mat-form-field-fluid" appearance="fill">
      <mat-label>{{ "Search" | translate }}</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        maxlength="50"
        [formControl]="search"
        autocomplete="do_not_fill"
        #searchInput
        [placeholder]="'COMMON.SEARCH' | translate"
      />
      <button
        mat-icon-button
        matSuffix
        *ngIf="searchInput.value"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  `,
})
export class FilterSearchComponent implements OnInit, OnDestroy {
  @Input() filter: searchFilter;
  @Input() control: UntypedFormControl;
  destroyed$ = new Subject<void>();
  //   searchableFields$ = combineLatest([
  //     this.crudShellService.searchableFields$,
  //     this.crudShellService.allFields$,
  //   ]).pipe(
  //     map(([searchableFields, allFields]) =>
  //       searchableFields.map((field) => {
  //         const target = allFields.find((f) => f.name === field);
  //         if (target) {
  //           return this.crudShellService.getTranslatedStaticText(target.title);
  //         }
  //       })
  //     ),
  //     map((fields) => fields.filter(Boolean)),
  //     map((fields) => {
  //       return `${this.translateService.instant("COMMON.SEARCH")} ${fields.join(
  //         ", "
  //       )}`;
  //     })
  //   );
  search = new UntypedFormControl();
  constructor(
    private translateService: TranslateService,
    private filterService: FilterService
  ) {}

  clearSearch() {
    this.search.setValue("");
  }

  ngOnInit() {
    this.subscribeToReset();
    this.search.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged((prev, curr) => prev === curr)
      )
      .subscribe((value: any) => {
        if (value === null) {
          return;
        }
        this.control.setValue(value);
        this.filterService.setAppliedFilters({
          component: this.filter,
          label: value,
          value: value,
        });
      });
  }

  subscribeToReset() {
    this.filterService.resetFiltersSignal$
      .pipe(takeUntil(this.filterService.destroyed$))
      .subscribe(() => {
        this.search.setValue(null);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
