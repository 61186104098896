// Angular
import { formatDate } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { merge } from "lodash";

export enum CrudTableCustomFilterConfirmationDialogParamType {
  INPUT = "input",
  NUMBER = "number",
  DATE = "date",
  DATERANGE = "daterange",
}

export enum ConfirmActionDialogStatus {
  DEFAULT = "DEFAULT",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  LOADING = "LOADING",
}

export class ConfirmActionDialogData {
  title: string = "COMMON.CONFIRM_ACTION";
  message: any | string = "COMMON.ARE_YOU_SURE";
  paramName?: string;
  paramType?: string;
  paramDisplayName?: string;

  hideBtnNo?: boolean;
  showBtnCancel?: boolean = false;
  btnYesLabel?: string;
  btnNoLabel?: string;

  callbackYes?: (param: any) => any;
  callbackNo?: (param: any) => any;
  callbackCancel?: (param: any) => any;
}
@Component({
  selector: "kt-confirm-action-dialog",
  templateUrl: "./confirm-action-dialog.component.html",
})
export class ConfirmActionDialogComponent implements OnInit {
  ConfirmActionDialogStatus = ConfirmActionDialogStatus;
  valueForm: UntypedFormGroup;
  // Public properties
  status: ConfirmActionDialogStatus = ConfirmActionDialogStatus.DEFAULT;
  paramTypes = CrudTableCustomFilterConfirmationDialogParamType;
  message: any = {};
  errorMessage: string;
  paramType = null;
  paramName: string;
  paramDisplayName: string;

  value = "";

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmActionDialogData
  ) {
    this.message = {
      DEFAULT: "COMMON.ARE_YOU_SURE",
      ERROR: "COMMON.PROCESS_FAILED",
      SUCCESS: "COMMON.PROCESS_SUCCESS",
      LOADING: "COMMON.PROCESSING_PLS_WAIT",
    };

    if (typeof this.data.message == "string") {
      this.message[ConfirmActionDialogStatus.DEFAULT] = this.data.message;
    } else {
      this.message = merge(this.message, this.data.message);
    }
    this.paramName = this.data?.paramName;
    this.paramType = this.data?.paramType;
    this.paramDisplayName = this.data?.paramDisplayName;
  }
  ngOnInit() {
    this.valueForm = this.fb.group({
      value: ["", [Validators.required]],
    });
  }

  onNoClick(): void {
    if (!this.data.callbackNo) return this.dialogRef.close(false);
    this.data.callbackNo(false);
  }

  onYesClick(): void {
    if (this.paramType) {
      return this.dialogRef.close(this.valueForm.getRawValue());
    }

    if (!this.data.callbackYes) {
      return this.dialogRef.close(true);
    }

    this.status = ConfirmActionDialogStatus.LOADING;
    this.data.callbackYes(true).subscribe(
      (resp) => {
        this.status = ConfirmActionDialogStatus.SUCCESS;
      },
      ({ error }) => {
        this.status = ConfirmActionDialogStatus.ERROR;
        this.errorMessage = error;
      }
    );
  }

  onCancelClick(): void {
    if (!this.data.callbackCancel) return this.dialogRef.close();
    this.data.callbackCancel(null);
  }

  getMessage() {
    let message = this.message[this.status];
    if (this.status == ConfirmActionDialogStatus.ERROR) {
      if (this.errorMessage) {
        message = this.translate.instant(message, {
          message: this.errorMessage,
        });
      }
    }
    return message;
  }

  isStatus(status) {
    return status == this.status;
  }

  onDateChangeHandler(event) {
    const formattedDate = formatDate(
      event.value,
      "yyyy-MM-dd",
      this.translate.currentLang || "en"
    );

    this.valueForm.patchValue({
      value: formattedDate,
    });
  }

  dateRangeChangeHandler(event, type) {
    let [start, end] = this.valueForm.value.value.split(",");
    if (end === undefined) {
      end = "";
    }
    const formattedDate = formatDate(
      event.value,
      "yyyy-MM-dd",
      this.translate.currentLang || "en"
    );

    if (type == "start") {
      this.valueForm.patchValue({
        value: `${formattedDate},${end}`,
      });
    } else {
      this.valueForm.patchValue({
        value: `${start},${formattedDate}`,
      });
    }
  }

  onChangeValue(value) {
    this.valueForm.patchValue({ value });
  }

  isButtonDisabled() {
    // check if the this dialog contains a required input from the user
    // then check if the input is valid
    if (this.status == ConfirmActionDialogStatus.LOADING) {
      return true;
    }

    if (this.paramType) {
      if (this.valueForm.valid) {
        return false;
      }

      return true;
    }

    return false;
  }
}
