import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "kt-laptime-table",
  templateUrl: "./laptime-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaptimeTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dataSource: [];
  @Output() onActionDelete: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns = ["laptime", "location", "variant", "actions"];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private cRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnChanges({ dataSource }: SimpleChanges) {
    if (dataSource) {
      console.log("dataSource", dataSource);
      this.dataSource = dataSource.currentValue || [];
      // this.dataSource.forEach(r => r._internalId || r._setInternalId());
      this.cRef.detectChanges();
    }
  }
  ngOnDestroy() {}

  deleteLaptime(laptime) {
    this.onActionDelete.emit(laptime);
  }
}
