import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeSwitchTheData = new EventEmitter();
  invokeUploadImage = new EventEmitter();
  invokeMedialLibrary = new EventEmitter<any>();

  /**
   * Object of EventEmitter<any>
   */
  invokeCrudTableCreate: any = {};

  subsVar: Subscription;

  constructor() { }

  onSwitchTheData() {
    this.invokeSwitchTheData.emit();
  }

  onUploadImage(data) {
    this.invokeUploadImage.emit(data);
  }

  onMediaLibrary(data) {
    this.invokeMedialLibrary.emit(data);
  }

  // onCrudTableCreate(name, data) {
  //   this.invokeCrudTableCreate[name] = 
  //   this.invokeCrudTableCreate.emit(data);
  // }
}
