<div>
  <crud-table
    title="Laptime Imports"
    name="laptime-imports"
    [columns]="columns"
    [createButtonLabel]="createButtonLabel"
    [apiUrl]="apiUrl"
    baseUrl="/user-management/laptime-imports"

  ></crud-table>
</div>
