// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../../core/store/reducers/app.reducers';
import {ApiConfig} from "../../../../../core/config/api.config";
import {Validators} from "@angular/forms";
import {SubheaderService} from '../../../../../core/base';
// Auth
import { AuthService } from '../../../../../core/store/';

@Component({
	selector: 'trackday-group-bookings-table',
	templateUrl: './trackday-group-bookings-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackdayGroupBookingsTableComponent implements OnInit {
	columns = [];
	searchableColumns = [
		'booking.number',
		'trackdayitem.name',
		'name',
	];
	apiUrl: string;
	createButtonLabel = 'Create Booking';

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param router: Router
	 */
	constructor(private store: Store<AppState>, private router: Router, private subheaderService: SubheaderService,
		private auth: AuthService) {
	}

	 
	ngOnInit() {
		this.apiUrl = ApiConfig.TrackdayGroupBookingsUrl();

        // Set title to page breadCrumbs
        this.subheaderService.setTitle('Group Bookings');
	}
}
