export enum CUSTOM_VALIDATIONS {
  REQUIRED = "required",
  GREATER_THAN = "validate_gt",
  GREATER_EQUAL_THAN = "validate_ge",
  LESS_THAN = "validate_lt",
  LESS_EQUAL_THAN = "validate_le",
  EQUAL = "validate_eq",
  MAX = "max",
  MIN = "min",
}
