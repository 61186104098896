import {
  Directive,
  DoCheck,
  EmbeddedViewRef,
  Input,
  NgIterable,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";

@Directive({
  selector: "[ngForOf][ngFor][ngForWhenEmpty]",
})
export class WhenEmptyDirective<T, U extends NgIterable<T> = NgIterable<T>>
  implements DoCheck
{
  constructor(private _viewContainer: ViewContainerRef) {}

  private _emptyView: EmbeddedViewRef<void> | null = null;

  @Input()
  ngForOf: (U & NgIterable<T>) | undefined | null;

  @Input("ngForWhenEmpty")
  whenEmpty: TemplateRef<void>;

  ngDoCheck(): void {
    if (this.ngForOf) {
      const value = Array.from(this.ngForOf);
      if (value.length > 0 && this._emptyView !== null) {
        this._viewContainer.remove(
          this._viewContainer.indexOf(this._emptyView)
        );
        this._emptyView = null;
      } else if (this._emptyView === null && this._viewContainer.length === 0) {
        this._emptyView = this._viewContainer.createEmbeddedView(
          this.whenEmpty
        );
      }
    }
  }
}
