import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ApiConfig } from "../../../../core/config/api.config";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  template: `
    <header class="fullscreen">
      <span> User Configurations </span>
      <div class="actions">
        <button class="btn kt-subheader__btn-primary" (click)="closeDialog()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </header>
    <div class="content-wrapper">
      <cb-configuration [apiURL]="apiUrl" [saveURL]="saveUrl">
      </cb-configuration>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationComponent {
  apiUrl = ApiConfig.UserConfigURL();
  saveUrl = ApiConfig.UserConfigURLGroupsUpdate();
  constructor(public dialogRef: MatDialogRef<ConfigurationComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
