import { type } from "os";

export enum ApiProgressStatus {
  Initial = 0,
  Done = 1,
  InProgress = 2,
  DoneWithError = 3,
}

export interface ITab {
  type: string;
  display: string;
  name: string;
  content: any;
  hasError: boolean;
  disabled?: boolean;
  selected?: boolean;
  shouldRefresh?: boolean;
  layout?: {
    columns: number[];
  };
}

export interface ConfirmModalDataModel {
  title: string;
  message: string;
  btnYesLabel: string;
  btnNoLabel: string;
  paramName?: string;
  paramType?: string;
  paramDisplayName?: string;
}

export interface WizardTab {
  name: string;
  caption: string;
  sub_caption: string;
  icon: string;
  show_step_summary?: boolean;
  fields: any[];
  table?: any;
}

export type AddReplaceDialogResultType = {
  action: "PRIMARY" | "SECONDARY" | "CANCEL";
  selection: any[];
};

export type AddReplaceDialogDataType = {
  crudTable?: any;
  component?: any;
  title?: string;
  showSecondaryButton?: boolean;
  primaryTitle?: string;
  secondaryTitle?: string;
};

export interface ProcessButton {
  itemId?: number | string;
  processType: processButtonType;
  callback?: ProcessButtonLink;
  actionLinkConfig?: ProcessButtonLink;
  contextMenu?: boolean;
  confirm?: ConfirmModel;
  title: string;
  type: string;
  tooltip: string;
  icon: string;
  visibleIf?: any;
  emailDialog?: any;
  id_field?: string;
  display_field: string;
  children?: ProcessButton[];
  infoDialog?: InfoModal;
  selected?: any[]; // this is used to pass selected items to the process button if it is applicable
  form?: processButtonFormModel;
  isBeingProcessed?: boolean;
  isSpecialType?: "email_dialog" | "board_dialog" | "info_dialog" | "edit";
  isContextMenuButton?: boolean;
}

export interface processButtonFormModel {
  config: string;
  message?: string;
  buttons?: any[];
  displayWaringMessage?: boolean;
  callback: ProcessButtonLink;
  viewMode: {
    config: Record<string, any>;
  };
}

export type InfoModal = {
  title: string;
  apiUrl: string;
  display_refresh_button: boolean;
  id_fields: string[];
};

export interface ConfirmModel {
  message: string;
  // TODO SHAAHIN labels are most likely not used at all (confirm model uses translations inside)
  okLabel: string;
  cancelLabel: string;
  method: HTTPMethods;
  paramDisplayName: string;
  paramName: string;
  paramType: confirmModalParamType;
}

export type processButtonType = "both" | "action" | "mass";

export type confirmModalParamType = "input" | "daterange" | "number" | "date";

export type HTTPMethods = "POST" | "GET" | "PUT" | "DELETE";

export interface ProcessButtonLink {
  url: string;
  method: HTTPMethods;
  idField?: string;
}
