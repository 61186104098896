import { Injectable } from "@angular/core";
import { get } from "lodash";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class GlobalConfigParser {
  parsePlaceholders(text: string, data: any) {
    let formattedValue: any = text;
    if (formattedValue == null || formattedValue === "") return formattedValue;
    if (typeof formattedValue !== "string") return formattedValue;

    let formattedValueEval: any = formattedValue + "";

    //static content that should not be replaced
    const staticContentPattern = /{%[\s\S]*%}/;
    const staticContent = formattedValue.match(staticContentPattern);
    if (staticContent) {
      const refinedText = staticContent[0]
        .replace("{%", "")
        .replace("%}", "")
        .trim();
      return refinedText;
    }

    const placeholders = formattedValue.match(/{[^}]+}/g);

    if (!placeholders) return formattedValue;

    if (text == "{_parent.id} ? {_parent.currency_id} : 'name:Euro'") {
      console.log(text, placeholders);
    }
    let isString = false;
    for (let placeholder of placeholders) {
      let fieldName = placeholder.substr(1, placeholder.length - 2);

      const formatter = fieldName.split("|");
      fieldName = formatter[0];

      let fieldValue = null;

      // special placeholders
      if (fieldName == "DATE_NOW") {
        fieldValue = moment().format();
      } else {
        const segments = fieldName.split(".");
        if (segments.indexOf("translations") >= 0) {
          const transFieldName = segments.pop();
          const attrPath = segments.join(".");
          const transData = get(data, attrPath);

          //console.log('parsePlaceholders', transFieldName, attrPath, transData, translate, fieldValue);
        } else {
          fieldValue = get(data, fieldName);

          if (text == "user_id={id}") {
            console.log(
              "fieldValue = get(data, fieldName);",
              fieldName,
              fieldValue,
              data
            );
          }

          if (moment(fieldValue, "YYYY-MM-DD HH:mm:ss", true).isValid())
            fieldValue = moment(fieldValue).format();
        }
      }

      // parse formmater
      //   if (formatter.length > 1) {
      //     const formatType = formatter[1];
      //     fieldValue = this.translateByType(fieldValue, formatType);
      //   }

      if (fieldValue instanceof Date) fieldValue = moment(fieldValue).format();

      //if (text == '{location_guid}') console.log('{location_guid}:fieldValue', fieldValue);

      // plain string
      formattedValue = formattedValue.replaceAll(
        placeholder,
        ((val) => {
          if (val == null) return "";
          else if (!isNaN(Number(val)) && !isNaN(parseFloat(val)))
            return parseFloat(val);
          else return val;
        })(fieldValue)
      );

      // eval
      formattedValueEval = formattedValueEval.replaceAll(
        placeholder,
        ((val) => {
          if (val === undefined) return "undefined";
          else if (val === null) return "null";
          else if (!isNaN(Number(val)) && !isNaN(parseFloat(val)))
            return parseFloat(val) + "";
          else {
            //if (text == '{location_guid}') console.log('{location_guid}', val, `"${val}"`);
            isString = true;
            return `"${val}"`;
          }
        })(fieldValue)
      );
    }

    try {
      if (text == "user_id={id}") {
        console.log("formattedValueEval", formattedValueEval);
      }

      let val = (window as any).eval.call(
        window,
        "(function (that, moment) {return " + formattedValueEval + "})"
      )(data, moment);
      return val;
    } catch (e) {
      return formattedValue;
    }
  }
}
