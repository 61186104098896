import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { HiddenInputConfig } from "../models/hiddenInput.config.model";

@Component({
  selector: "cb-hidden-input",
  template: ` <div [formGroup]="form">
    <input
      type="hidden"
      [formControlName]="config.formControlName"
      [required]="config.required"
    />
  </div>`,
})
export class CbHiddenInputComponent implements OnInit {
  ngOnInit(): void {}
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: HiddenInputConfig;
}
