export function getThousandSeparator(lang: string) {  
  switch (lang) {
    case "en":
      return ",";
    case "de":
      return ".";

    default:
      return ",";
  }
}
