// Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// Material
import { SelectionModel } from "@angular/cdk/collections";

import { MatSort } from "@angular/material/sort";

// RXJS
import { BehaviorSubject, Subscription } from "rxjs";
// LODASH
// NGRX
import { Store } from "@ngrx/store";
import { AppState } from "../../../../../../core/store/reducers/app.reducers";

// Services
import {
  LayoutUtilsService,
  QueryParamsModel,
} from "../../../../../../core/base";
// Models
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { SubheaderService } from "../../../../../../core/base";
import { AuthService } from "../../../../../../core/store";
import { EventEmitterService } from "../../../../../../event-emitter.service";

//ckeditor
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CrudTableService } from "../../../../../../../app/services";
import { ConfirmActionDialogComponent } from "../../../../../../../app/views/partials/content";
import { ApiConfig } from "./../../../../../../core/config/api.config";
import { VariantLaptimeDialogComponent } from "./variant-laptime-dialog/variant-laptime-dialog.component";

// Table with EDIT item in MODAL
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
  selector: "kt-variant",
  templateUrl: "./variant.component.html",
  styleUrls: ["variant.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class VariantComponent implements OnInit, OnDestroy {
  // Table fields
  dataSource: MatTableDataSource<[]>;
  displayedColumns = [
    "select",
    "value",
    "label",
    "length",
    "fia_grade_valid_before_date",
    "bookable",
    "grade_id",
    "actions",
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("sort1", { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  lastQuery: QueryParamsModel;
  // Selection
  selection = new SelectionModel<[]>(true, []);
  isTestdata: any;
  variants: any = [];
  expandedElement = [
    "name",
    "variant_type_id",
    "length",
    "fia_grade_valid_before_date",
    "grade_id",
    "description",
  ];
  loading$ = new BehaviorSubject<boolean>(false);
  paginatorTotal$ = new BehaviorSubject<number>(0);

  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   *
   * @param activatedRoute: ActivatedRoute
   * @param store: Store<AppState>
   * @param router: Router
   * @param layoutUtilsService: LayoutUtilsService
   * @param subheaderService: SubheaderService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private auth: AuthService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public crudTableService: CrudTableService
  ) {}

  public Editor = ClassicEditor;

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      this.loading$.next(true);
      const id = params.id;
      this.auth.getRelatedVariant(id).subscribe((res) => {
        this.variants = res;
        this.dataSource = new MatTableDataSource(this.variants);
        this.paginatorTotal$.next(this.variants.length);
        this.loading$.next(false);
        this.cdRef.detectChanges();
      });
    });

    // Set title to page breadCrumbs
    this.subheaderService.setTitle("Locations");
    // Init DataSource
    this.dataSource = new MatTableDataSource(this.variants);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.variants.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.variants.length) {
      this.selection.clear();
    } else {
      this.variants.forEach((row) => this.selection.select(row));
    }
  }

  showVariant(element) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  addLaptime(variant) {
    console.log("addLaptime", variant);

    const dialogRef = this.dialog.open(VariantLaptimeDialogComponent, {
      data: { variant: variant },
      width: "450px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      this.reloadLaptimes(variant.id);
    });
  }

  reloadLaptimes(variantId) {
    this.crudTableService
      .getAllItems(`${ApiConfig.VariantsURL()}/${variantId}/laptimes`)
      .subscribe(
        (results) => {
          console.log("reloadLaptimes", results);
          const index = this.variants.findIndex((r) => r.id == variantId);
          this.variants[index].laptimes = results;
          this.cdRef.detectChanges();
        },
        (err) => {}
      );
  }

  deleteLaptime(item) {
    let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      data: {
        title: "Delete Variant Laptime",
        message: "Are you sure you want to delete?",
      },
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.crudTableService
          .deleteItem(
            `${ApiConfig.VariantsURL()}/${item.variant_id}/laptimes`,
            item.id
          )
          .subscribe(
            (response) => {
              this.reloadLaptimes(item.variant_id);
            },
            (err) => {}
          );
      }
    });
  }
}
