// Angular
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/store/reducers/app.reducers';
import { currentUser, Logout, User } from '../../../../../core/store';
import { environment } from '../../../../../../environments/environment';
import { EventEmitterService } from '../../../../../event-emitter.service';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;
	storageUrl: string;
	currentUser: User;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;
	profileImage: string = '';

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>, private eventEmitterService: EventEmitterService, private cdr: ChangeDetectorRef) {
	}

	 
	ngOnInit(): void {
		this.storageUrl = environment.storageUrl;
		this.user$ = this.store.pipe(select(currentUser));
		this.user$.subscribe(res => this.currentUser = res);

		//Change image when upload

		this.eventEmitterService.invokeMedialLibrary.subscribe(data => {
			if (data.type == 'profile' &&
				data.model == "App\\Pgm\\Users" &&
				data.model_id == this.currentUser.id) {
				if (data.action == 'upload') {
					this.profileImage = data.url;
				} else if (data.action == 'delete') {
					this.profileImage = '';
				}
			}
		});

		// if (this.eventEmitterService.subsVar==undefined) {    
		//   let entitiesSubscription = this.eventEmitterService.    
		//   invokeUploadImage.subscribe((data) => {
		//     this.profileImage = data.pic;
		//     this.cdr.detectChanges();
		//   });  
		// }
	}

	/**
	 * Log out
	 */
	logout() {
		localStorage.removeItem('is_testdata');
		this.store.dispatch(new Logout());
	}
}
