// Angular
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// RxJS
import { Observable, of } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { HttpUtilsService } from "../core/base/utils/http-utils.service";
import { QueryParamsModel } from "../core/base/models/query-params.model";
import { QueryResultsModel } from "../core/base/models/query-results.model";
import { LocalStorageService } from "./local-storage.service";

// Models

@Injectable({
  providedIn: "root",
})
export class CrudTableService {
  /**
   * Service Constructor
   *
   * @param http: HttpClient
   */
  constructor(
    private http: HttpClient,
    private httpUtilsService: HttpUtilsService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  get(baseUrl: string): Observable<any> {
    return this.http.get<any>(baseUrl + `?lang=` + this.translate.currentLang);
  }

  getAllItems(baseUrl: string): Observable<any[]> {
    return this.http.get<any[]>(baseUrl);
  }

  getItemById(baseUrl: string, itemId: number): Observable<any> {
    let url = baseUrl + `/${itemId}` + `?lang=` + this.translate.currentLang;
    if (url.indexOf("is_testdata") < 0)
      url = url + "&is_testdata=" + localStorage.getItem("is_testdata");
    return this.http.get<any>(url);
  }

  getHtml(baseUrl: string): Observable<string> {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );

    let params: any = {
      lang: this.translate.currentLang,
    };

    return this.http.get(baseUrl, {
      headers,
      responseType: "text",
      params: params,
    });
  }

  // DELETE => delete the user from the server
  deleteItem(baseUrl: string, itemId: number) {
    let deleteUrl;
    if (baseUrl.indexOf("?") > 0)
      deleteUrl = baseUrl.replace("?", `/${itemId}?`);
    else deleteUrl = baseUrl + `/${itemId}`;
    return this.http.delete(deleteUrl);
  }

  // UPDATE => PUT: update the user on the server
  updateItem(baseUrl: string, item: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.put(baseUrl + `/${item.id}`, item, {
      headers: httpHeaders,
      params: {
        is_testdata: localStorage.getItem("is_testdata"),
      },
    });
  }

  // CREATE =>  POST: add a new user to the server
  createItem(baseUrl: string, item: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    console.log("createItem: " + baseUrl);
    return this.http.post<any>(baseUrl, item, { headers: httpHeaders });
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findItems(
    baseUrl: string,
    queryParams: QueryParamsModel,
    tableName?: string
  ): Observable<QueryResultsModel> {
    // save the query params in the local storage
    // this.localStorageService.saveFilters(tableName, queryParams);
    return this.http.get<QueryResultsModel>(baseUrl, {
      params: this.httpUtilsService.convertQueryParams(queryParams),
    });
  }

  //get variant
  getRelatedVariant(baseUrl: string, locationId: number): Observable<Object> {
    return this.http.get(baseUrl + `/${locationId}`);
  }

  // UPDATE => PUT: update the user on the server
  updateItems(baseUrl: string, item: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.post(baseUrl, item, { headers: httpHeaders });
  }

  deleteItems(baseUrl: string, ids: number[]): Observable<Object> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.post(baseUrl, ids, { headers: httpHeaders });
  }

  //get variant
  getRelatedData(baseUrl: string, itemId: any): Observable<Object> {
    return this.http.get(baseUrl + `/${itemId}`);
  }

  getVersionedUrl(apiUrl: string) {
    const arrUrl = apiUrl.split("?");
    let params = arrUrl[1];
    let arrParams = params ? params.split("&") : [];

    const idx = arrParams.findIndex((r) => r.startsWith("__version"));
    if (idx < 0) arrParams.push("__version=" + moment().valueOf());
    else arrParams[idx] = "__version=" + moment().valueOf();

    return arrUrl[0] + "?" + arrParams.join("&");
  }

  /*
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
