// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
// Partials
import { PartialsModule } from "../partials/partials.module";
// Pages
import { CoreModule } from "../../core/core.module";
import { ProfileModule } from "./profile/profile.module";
//import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from "./user-management/user-management.module";
import { StoreModule } from "@ngrx/store";
import { CrudTableEffects, crudTableReducer } from "../../core/store";
import { EffectsModule } from "@ngrx/effects";
import { StaticPagesModule } from "./static/static-pages.module";
import { TrackdayDirectbookingSnippetFormComponent } from "./snippet/trackday-directbooking-snippet/trackday-directbooking-snippet-form/trackday-directbooking-snippet-form.component";
import { CbFormsModule } from "../../modules/cb-forms/cb-forms.module";

@NgModule({
  declarations: [
    TrackdayDirectbookingSnippetFormComponent
  ],
  exports: [
    TrackdayDirectbookingSnippetFormComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    ProfileModule,
    //	ECommerceModule,
    UserManagementModule,
    StaticPagesModule,
    StoreModule.forFeature("crudTable", crudTableReducer),
    EffectsModule.forFeature([CrudTableEffects]),
    CbFormsModule,
  ],
  providers: [],
})
export class PagesModule {}
