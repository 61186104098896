import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ReloadControllerState } from "../reducers/reload-controller.reducer";
import { HelpSidebarState } from "../reducers/help-sidebar.reducer";

export const selectHelpSidebar =
  createFeatureSelector<HelpSidebarState>("helpSidebar");

export const selectSidebarStatus = createSelector(
  selectHelpSidebar,
  (state) => state.sidebarStatus
);

export const selectTargetUrl = createSelector(
  selectHelpSidebar,
  (state) => state.url
);

export const helpSidebarSelectors = {
  selectHelpSidebar,
  selectSidebarStatus,
  selectTargetUrl,
};
