import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KanbanBoardComponent } from './components/kanban-board/kanban-board.component';
import { ProjectManagementComponent } from './project-management.component';

const routes: Routes = [
  { path: 'kanban', component: ProjectManagementComponent },
  { path: 'board', component: KanbanBoardComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectManagementRoutingModule { }
