import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FormConfigService } from "../../../../../cb-forms/form.config.service";

@Component({
  selector: "cb-tab-content-html",
  templateUrl: "./tab-content-html.component.html",
  styleUrls: ["./tab-content-html.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentHtmlComponent implements OnInit {
  @Input() content: any;
  @Input() form: UntypedFormGroup;
  apiUrl = "";
  shouldDisplayRefreshButton = true;
  constructor(private formConfigService: FormConfigService) {}

  ngOnInit(): void {
    this.apiUrl = this.formConfigService.getApiUrl(this.content?.api_url);
    this.shouldDisplayRefreshButton =
      "display_refresh_button" in this.content
        ? this.content["display_refresh_button"]
        : true;
  }
}
