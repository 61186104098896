<form class="form" [formGroup]="form" cdkTrapFocus>
  <div class="loading" *ngIf="isLoading$ | async">
    <cb-loading [isAbsolute]="false"></cb-loading>
  </div>
  <div class="form-header">{{ "EXPORT_TABLE.SAVE_CONFIG_AS" | translate }}</div>
  <mat-form-field class="mat-form-field-fluid custom-input">
    <input
      matInput
      [placeholder]="'EXPORT_TABLE.NEW_TEMPLATE_PLACEHOLDER' | translate"
      formControlName="name"
      tabindex="1"
    />
  </mat-form-field>
  <div class="radio-container">
    <div>
      <h3>{{ "EXPORT_TABLE.MODE" | translate }}</h3>
    </div>
    <div class="radio-group-container">
      <mat-radio-group formControlName="mode">
        <mat-radio-button
          tabIndex="2"
          color="primary"
          *ngFor="let mode of templateModes"
          class="columns-export-type-radio-button"
          [value]="mode.value"
        >
          {{ mode.text }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="actions">
    <button
      type="submit"
      color="success"
      mat-flat-button
      (click)="save()"
      tabindex="3"
    >
      <i class="fa-light fa-file-export"></i>
      {{ "COMMON.SAVE" | translate }}
    </button>
    <button mat-flat-button color="warn" (click)="close()" tabindex="4">
      <i class="fa-solid fa-circle-x"></i>
      {{ "COMMON.CLOSE" | translate }}
    </button>
  </div>
</form>
