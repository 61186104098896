<header class="font-weight-bold">
  {{ "SEND_EMAIL.PLACEHOLDERS_TITLE" | translate }}
</header>
<ng-container *ngIf="(htmlContent$ | async) !== ''; else empty">
  <div [innerHtml]="htmlContent$ | async | safe" class="htmlContent"></div>
</ng-container>
<div class="loading-container" *ngIf="(isLoading$ | async) === true">
    <cb-loading [diameter]="25" [isAbsolute]="false"></cb-loading>  
</div>

<ng-template #empty>
  <div class="htmlContent">
    <h4>
      {{ "SEND_EMAIL.PLACEHOLDERS_EMPTY_MESSAGE" | translate }}
    </h4>
  </div>
</ng-template>
