<!-- begin: Header -->
<div
  ktHeader
  #ktHeader
  class="kt-header kt-grid__item"
  [ngClass]="htmlClassService.getClasses('header', true)"
  id="kt_header"
>
  <ngb-progressbar
    class="kt-loading-bar"
    *ngIf="(loader.progress$ | async) > 0"
    [value]="loader.progress$ | async"
    height="3px"
  ></ngb-progressbar>

  <!-- begin: Header Menu -->
  <kt-menu-horizontal *ngIf="menuHeaderDisplay"></kt-menu-horizontal>
  <div class="d-none d-xl-inline-block">
    <kt-topbar></kt-topbar>
  </div>
  <!-- end: Header Menu -->
  <ng-container *ngIf="!menuSideDisplay">
    <div class="header-logo-wrap">
      <!-- begin:: Brand -->
      <kt-brand></kt-brand>
      <!-- end:: Brand -->

      <kt-testdata-switch></kt-testdata-switch>
    </div>
  </ng-container>
  <!-- begin:: Header Topbar -->
  <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
  <div *ngIf="!menuHeaderDisplay"></div>
  <!-- <div class="d-none d-lg-block"><kt-topbar></kt-topbar></div> -->
  <!-- end:: Header Topbar -->
</div>
<!-- end: Header -->
