import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { sendEmailSelectors } from "../../store";

@Component({
  selector: "app-email-preview-pane",
  templateUrl: "./email-preview-pane.component.html",
  styleUrls: ["./email-preview-pane.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailPreviewPaneComponent implements OnInit {
  currentTheme$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentTheme)
  );

  headerImage$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentHeaderImage)
  );
  footerImage$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentFooterImage)
  );
  signature$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentSignature)
  );

  subject$ = this.store.pipe(select(sendEmailSelectors.selectCurrentSubject));

  body$ = this.store.pipe(select(sendEmailSelectors.selectCurrentBody));

  constructor(private store: Store) {}

  ngOnInit(): void {}
}
