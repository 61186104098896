import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { FormConfigService } from "../../../../form.config.service";
import { FormConfigForm } from "../../../../types/form.config.type";
import { CommonService } from "../../../../../../services/common.service";
import { FormsShellModalComponent } from "../../../forms-shell-modal/forms-shell-modal.component";
import { Store } from "@ngrx/store";
import { reloadControllerActions } from "../../../../../../core/store/actions/reload-controller.actions";

@Component({
  selector: "cb-tab-content-crud-table",
  templateUrl: "./tab-content-crud-table.component.html",
  styleUrls: ["./tab-content-crud-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentCrudTableComponent implements OnInit {
  @Input() content: any;
  @Input() form: UntypedFormGroup;
  @Input() componentIdentifier: number;
  // this is used to show | hide table header
  // if the table is used in a portlet, the header by default becomes hidden, unless told otherwise
  @Input() parentIsPortlet?: boolean | null;
  @Output() onLoadingChangeEvent = new EventEmitter<{
    status: boolean;
    componentIdentifier: number;
  }>();
  apiUrl = "";
  formConfigForm: FormConfigForm;
  parentFormData$ = new BehaviorSubject<any>(null);
  isSubFormShown$ = new BehaviorSubject(false);
  isFormShown$ = new BehaviorSubject(false);
  parentData$ = new BehaviorSubject(null);
  tableAllowedActions;

  loadingComponents$ = new BehaviorSubject<
    { status: boolean; componentIdentifier: number }[]
  >([]);
  destroy$ = new Subject();

  constructor(
    private formConfigService: FormConfigService,
    private commonService: CommonService,
    private store: Store
  ) {
    // this.apiUrl = formConfigService.getApiUrl(this.content?.api_url);
    this.isSubFormShown$.next(this.formConfigService.isSubFormShown);
    this.parentFormData$.next(this.formConfigService.formData);
  }

  ngOnInit(): void {
    this.parentData$.next(this.formConfigService.formData);
    this.apiUrl = this.formConfigService.getApiUrl(this.content?.api_url);
    // temporary not used, as the original issue was something else
    // usage in html is removed
    this.tableAllowedActions = {
      allow_add: "allow_add" in this.content ? this.content?.allow_add : true,
      allow_edit:
        "allow_edit" in this.content ? this.content?.allow_edit : true,
      allow_delete:
        "allow_delete" in this.content ? this.content?.allow_delete : true,
      allow_export:
        "allow_export" in this.content ? this.content?.allow_export : true,
    };
    if ("show_heading" in this.content) {
      this.parentIsPortlet = this.content.show_heading;
    } else {
      if (this.parentIsPortlet === undefined) {
        this.parentIsPortlet = false;
      }
    }

    if (this.content?.["form"]) {
      // it means that this has a form (refers to the edit/create form of the table)
      this.extractTableFormConfig();
    }
  }

  onCreateHandler = (_, item) => {
    const data = this.formConfigService.crudTableParseFormData(
      this.content.form.initial_data
    );

    let initialData = {
      ...data,
      ...item,
      _parent: { ...this.parentFormData$.value },
      _init: data,
    };

    this.formConfigForm = {
      ...this.formConfigForm,
      initial_data: initialData,
    };

    if (this.formConfigForm.view_mode.mode === "inline") {
      this.formConfigService.isSubFormShown = true;
      this.isFormShown$.next(true);
    } else {
      this.openFormInDialog();
    }
  };

  onEditHandler = (_, item) => {
    const data = this.formConfigService.crudTableParseFormData(
      this.content.form.initial_data
    );

    let initialData = {
      ...data,
      ...item,
      _parent: { ...this.parentFormData$.value },
      _init: data,
    };

    this.formConfigForm = {
      ...this.formConfigForm,
      initial_data: initialData,
    };

    if (this.formConfigForm.view_mode.mode === "inline") {
      this.formConfigService.isSubFormShown = true;
      this.isFormShown$.next(true);
    } else {
      this.openFormInDialog();
    }
  };

  openFormInDialog = () => {
    this.commonService
      .openDialog(FormsShellModalComponent, {
        data: {
          name: this.formConfigForm.config,
          apiUrl: this.formConfigForm.api_url,
          listUrl: this.formConfigForm.api_url,
          isSubForm: true,
          initialData: this.formConfigForm.initial_data,
          parentData: this.parentData$.value,
        },
        disableClose: true,
        minWidth: this.determineModalWidth(
          this.formConfigForm.view_mode?.config?.width,
          !!this.formConfigForm.view_mode?.config.fullscreen
        ),
        height: this.determineModalHeight(
          this.formConfigForm.view_mode?.config?.height,
          !!this.formConfigForm.view_mode?.config.fullscreen
        ),
      })
      .subscribe((res) => {
        this.store.dispatch(
          reloadControllerActions.updateTargetComponent({
            targetComponent: this.content.table_config,
          })
        );
      });
  };

  determineModalWidth = (width?: string, isFullscreen: boolean = false) => {
    if (isFullscreen) {
      return "99vw";
    }
    if (width) {
      return width;
    }

    return "1200px";
  };

  determineModalHeight = (height?: string, isFullscreen: boolean = false) => {
    if (isFullscreen) {
      return "99vh";
    }
    if (height) {
      return height;
    }

    return "80vh";
  };

  extractTableFormConfig = () => {
    const {
      api_url: table_api_url,
      config,
      initial_data,
      can_add,
      can_edit,
      can_delete,
      view_mode,
    } = this.content?.["form"];
    this.formConfigForm = {
      ...this.formConfigForm,
      api_url: this.formConfigService.getApiUrl(table_api_url),
      config: config,
      initial_data: this.parentFormData$.value,
      can_add: can_add ? can_add : true,
      can_edit: can_edit ? can_edit : true,
      can_delete: can_delete ? can_delete : true,
      view_mode: view_mode ? view_mode : { mode: "inline" },
    };
  };

  onExit = () => {
    // console.log(this.formConfigService.formName);
    this.formConfigService.isSubFormShown = false;
    this.isFormShown$.next(false);
  };

  onChildLoadingChangeEvent(event) {
    this.onLoadingChangeEvent.emit(event);
  }

  // temporary fix, we should not JS
  onBreadCrumb = (event) => {
    this.formConfigService.onBreadcrumbClick(event);
  };

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
