<div class="custom-container">
  <div
    *ngFor="let pc of processedContents$ | async; trackBy: trackPortlets"
    [ngStyle]="{ flex: pc.width }"
  >
    <div class="container-fluid">
      <div class="row">
        <ng-container *ngFor="let portlet of pc.items">
          <ng-container
            *ngIf="portlet.invisible !== true"
            [ngSwitch]="portlet?.type"
          >
            <div
              class="portlet-separator"
              [ngClass]="portlet.width | dynamicComponentWidth"
            >
              <cb-collapsible-portal
                [showHeader]="portlet.showHeader"
                [title]="portlet.title"
                [config]="portlet.collapsibleConfig"
                [formConfigName]="formName"
                [loadingComponents]="loadingComponents$ | async"
                [componentIdentifier]="portlet.index"
                [processButtons]="portlet.processButtons"
              >
                <ng-container *ngSwitchCase="'card'">
                  <cb-tab-content-card
                    [content]="portlet.content"
                    (onLoadingChangeEvent)="onChildLoadingChangeEvent($event)"
                    [componentIdentifier]="portlet.index"
                  ></cb-tab-content-card>
                </ng-container>
                <ng-container *ngSwitchCase="'html'">
                  <cb-tab-content-html
                    [content]="portlet.content?.[0]"
                    [form]="forms?.controls[portlet.name]"
                  ></cb-tab-content-html>
                </ng-container>
                <ng-container *ngSwitchCase="'form'">
                  <cb-tab-content-form
                    [content]="portlet.content"
                    [form]="forms?.controls[portlet.name]"
                  ></cb-tab-content-form>
                </ng-container>
                <ng-container *ngSwitchCase="'crud-table'">
                  <cb-tab-content-crud-table
                    [content]="portlet.content"
                    [form]="forms?.controls[portlet.name]"
                    (onLoadingChangeEvent)="onChildLoadingChangeEvent($event)"
                    [componentIdentifier]="portlet.index"
                    [parentIsPortlet]="true"
                  ></cb-tab-content-crud-table>
                </ng-container>
                <ng-container *ngSwitchCase="'tab'">
                  <cb-tab-content-tab
                    [tabs]="portlet.content"
                  ></cb-tab-content-tab>
                </ng-container>
                <ng-container *ngSwitchCase="'portlet'">
                  <cb-tab-content-portlet
                    [content]="content"
                  ></cb-tab-content-portlet>
                </ng-container>
                <ng-container *ngSwitchCase="'upload'">
                  <cb-tab-content-upload
                    [content]="portlet.content"
                    [forms]="forms?.controls[portlet.name]"
                  ></cb-tab-content-upload>
                </ng-container>
                <ng-container *ngSwitchCase="'chart'">
                  <cb-tab-content-chart
                    *ngIf="(isSubFormShown$ | async) === false"
                    [content]="portlet.content"
                    [chartName]="portlet.name"
                  ></cb-tab-content-chart>
                </ng-container>
                <ng-container *ngSwitchCase="'generic-chart'">
                  <app-generic-chart
                    [apiUrl]="parseApiUrl(portlet.content?.api_url)"
                    [filters]="portlet.content?.filters"
                    [searchableFields]="
                      portlet.content?.searchable_fields ?? []
                    "
                  ></app-generic-chart>
                </ng-container>
                <div *ngSwitchDefault>this should not happen</div>
              </cb-collapsible-portal>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
