// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { BehaviorSubject, Observable, of } from 'rxjs';
// Models

import { ApiConfig } from '../core/config/api.config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  translations = new BehaviorSubject<any>(null);

  /**
   * Service Constructor
   *
   * @param http: HttpClient
   */
  constructor(
    private http: HttpClient, 
    private translate: TranslateService,) {

    //this.loadTranslation(this.translate.currentLang);
  }

  loadTranslation(locale: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    this.http.post<any>(ApiConfig.I18nURL(), []).subscribe(res => this.translations.next(res));
  }

  getTranslation(texts: string[], locale: string): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(ApiConfig.I18nURL(), texts);
  }
}
