import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ACTIONS } from '../../store/default.actions';

@Component({
  selector: 'app-kanban-project-form',
  templateUrl: './kanban-project-form.component.html',
  styleUrls: ['./kanban-project-form.component.css']
})
export class KanbanProjectFormComponent implements OnInit {

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder
  ) { }

  projectForm = this.fb.group({
    title: [null, [Validators.required]],
    description: ["", [Validators.required]],
  })

  ngOnInit(): void {
    
  }

  createProject = () => {
    if (this.projectForm.valid) {
      this.store.dispatch(
        ACTIONS.projects.CreateProject(
          {
            ...this.projectForm.getRawValue()               
          }
        )
      );
    }    
  }

}
