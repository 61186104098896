import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ImageType } from "../../store/send-email.model";

@Component({
  selector: "app-preview-image",
  templateUrl: "./preview-image.component.html",
  styleUrls: ["./preview-image.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewImageComponent {
  @Input() image: ImageType | void;
  constructor() {}
}
