<ng-container *ngIf="displayMode === 'context'; else actionTemplate">
  <ng-container *ngIf="_contextMenuItems$ | async as contextMenuItems">
    <button
      mat-icon-button
      [mat-menu-trigger-for]="contextMenu.menu"
      class="menu-icon cb-icon-button"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <app-context-menu-item
      #contextMenu
      [buttons]="contextMenuItems"
    ></app-context-menu-item>
  </ng-container>
</ng-container>

<ng-template #actionTemplate>
  <app-action-menu-items
    [buttons]="_contextMenuItems$ | async"
  ></app-action-menu-items>
</ng-template>
