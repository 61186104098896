import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
  template: `<mat-card class="card">
    <mat-card-header>
      <mat-card-title>
        {{ data.header.title.value }}
      </mat-card-title>
      <mat-card-subtitle> {{ data.header.subtitle.value }}</mat-card-subtitle>
    </mat-card-header>
    <img
      *ngIf="data.image"
      [ngStyle]="data.imageCustomCss"
      mat-card-image
      [src]="
        (displayPlaceholderImage$ | async) === false
          ? data.image
          : data.placeholderImage
      "
      [alt]="data.header.title"
      (error)="displayPlaceholder()"
    />

    <mat-card-content>
      <div class="main-body row">
        <div
          class="body-item sortable"
          *ngFor="let el of data.body"
          [ngClass]="el.width"
        >
          <ng-container *ngIf="!el.isHtml; else html">
            <div class="label">{{ el.label }}:</div>
            <div class="value">{{ el.value }}</div>
          </ng-container>
          <ng-template #html>
            <div class="label">{{ el.label }}:</div>
            <div class="value" [innerHTML]="el.value"></div>
          </ng-template>
        </div>
      </div>
    </mat-card-content>
  </mat-card> `,
  styles: [
    `
      .mat-mdc-card {
        height: 100%;
        background: var(--background) !important;
      }
      .body-item {
        display: flex;
        gap: 8px;
        position: relative;
        padding-left: 24px;
        padding-block: 4px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .value {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .label {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
      }
    `,
  ],
})
export class FormGenericCardComponent {
  displayPlaceholderImage$ = new BehaviorSubject<boolean>(false);
  @Input() data;
  displayPlaceholder() {
    this.displayPlaceholderImage$.next(true);
  }
}
