import {
  AbstractControl,
  UntypedFormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import * as moment from "moment";
import { CUSTOM_VALIDATIONS } from "../types/customValidations.type";

export function validateGreaterThan(
  field1: string,
  field2: string
): ValidatorFn {
  return (formControl: UntypedFormControl): ValidationErrors | null => {
    const source = formControl.parent.get(field1);
    const target = formControl.parent.get(field2);

    if (!source || !target) {
      return null;
    }

    const sourceValue = source.value;
    const targetValue = target.value;

    if (!sourceValue || !targetValue) {
      return null;
    }

    if (moment.isMoment(sourceValue) && moment.isMoment(targetValue)) {
      if (!sourceValue.isAfter(targetValue)) {
        return {
          [CUSTOM_VALIDATIONS.GREATER_THAN]: true,
          dependent: field2,
        };
      }
    } else if (Number(sourceValue) <= Number(targetValue)) {
      return {
        [CUSTOM_VALIDATIONS.GREATER_THAN]: true,
        dependent: field2,
      };
    }

    formControl.parent.get(field2).setErrors(null);
    return null;
  };
}

export function validateGreaterEqualThan(
  field1: string,
  field2: string,
  field2Title: string,
): ValidatorFn {
  return (formControl: UntypedFormControl) => {
    const source = formControl.parent.get(field1);
    const target = formControl.parent.get(field2);

    if (!source || !target) {
      return null;
    }

    const sourceValue = source.value;
    const targetValue = target.value;

    if (!sourceValue || !targetValue) {
      return null;
    }

    // validation for date
    if (moment.isMoment(sourceValue) && moment.isMoment(targetValue)) {
      if (!sourceValue.isSameOrAfter(targetValue)) {
        return {
          [CUSTOM_VALIDATIONS.GREATER_EQUAL_THAN]: true,
          dependent: field2,
        };
      }
    } else if (Number(sourceValue) < Number(targetValue)) {
      return {
        [CUSTOM_VALIDATIONS.GREATER_EQUAL_THAN]: true,
        dependent: field2,
      };
    }

    formControl.parent.get(field2).setErrors(null);
    return null;
  };
}
