<div>
    <crud-table
            title="Race Event Bookings"
            name="trackday-season-bookings"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/trackdays-management/season-bookings"
    ></crud-table>
</div>
