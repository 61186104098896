<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
  <kt-user-profile></kt-user-profile>
  <!--end: User bar -->
  <div class="inline-seperator"></div>
  <!--begin: Language bar -->
  <kt-language-selector></kt-language-selector>
  <!--end: Language bar -->
</div>
<!-- end:: Header Topbar -->
