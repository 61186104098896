import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CoreModule } from "../../core/core.module";
import { EmailPreviewPaneComponent } from "./components/email-preview-pane/email-preview-pane.component";
import { PreviewBodyComponent } from "./components/preview-body/preview-body.component";
import { PreviewImageComponent } from "./components/preview-image/preview-image.component";
import { PreviewSignatureComponent } from "./components/preview-signature/preview-signature.component";
import { PreviewSubjectComponent } from "./components/preview-subject/preview-subject.component";
import { RecipientDetailsComponent } from "./components/recipient-details/recipient-details.component";
import { SendEmailComponent } from "./components/send-email/send-email.component";
import { TemplateDetailsLanguageComponent } from "./components/template-details-language/template-details-language.component";
import { TemplateDetailsComponent } from "./components/template-details/template-details.component";

import { LabelValuePipe } from "./pipes/label-value.pipe";
import { SEND_EMAIL_FEATURE_NAME, sendEmailReducer } from "./store";
import { SendEmailEffect } from "./store/send-email.effects";

import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../material/material.module";
import { SharedModule } from "../shared/shared.module";
import { SaveAsComponent } from "./components/save-as/save-as.component";
import { SendEmailSendButton } from "./components/send-button/send-button.component";
import { TemplateExtraInformationComponent } from "./components/template-extra-information/template-extra-information.component";
import { TemplatePlaceholdersComponent } from "./components/template-placeholders/template-placeholders.component";

@NgModule({
  declarations: [
    SendEmailComponent,
    EmailPreviewPaneComponent,
    PreviewImageComponent,
    PreviewSubjectComponent,
    PreviewBodyComponent,
    PreviewSignatureComponent,
    TemplateDetailsComponent,
    TemplateDetailsLanguageComponent,
    RecipientDetailsComponent,
    LabelValuePipe,
    TemplatePlaceholdersComponent,
    SaveAsComponent,
    TemplateExtraInformationComponent,
    SendEmailSendButton,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialModule,
    CKEditorModule,
    SharedModule,
    StoreModule.forFeature(SEND_EMAIL_FEATURE_NAME, sendEmailReducer),
    EffectsModule.forFeature([SendEmailEffect]),
  ],
})
export class SendEmailModule {}
