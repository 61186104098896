<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">Token Expired</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form kt-form--label-align-right kt-form--group-seperator-dashed">
		<div class="kt-portlet__body">
			<div class="kt-list-timeline kt-list-timeline--skin-light">
				<div class="kt-list-timeline__items">
					<div class="kt-list-timeline__item">
						<span class="kt-list-timeline__text">
							Token is expired please login!
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border">
			<div class="kt-form__actions kt-form__actions--solid">
				<div class="row text-right">
					<div class="col-lg-12">
						<button type="button" mat-raised-button class="danger-button" (click)="onSubmit()" matTooltip="Login">
							Login
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
