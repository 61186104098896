<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ data.title | translate }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <html-content [apiUrl]="data.apiUrl"></html-content>
    </div>
    <div
      class="
        kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit
        text-right
      "
    >
      <div class="kt-form__actions kt-form__actions--sm">
        <ng-container>
          <button mat-button (click)="dialogRef.close()">
            {{ "COMMON.CLOSE" | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
