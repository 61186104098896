import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "kt-change-entity-dialog",
  templateUrl: "./change-entity-dialog.component.html",
})
export class ChangeEntityDialogComponent implements OnInit {
  viewLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ChangeEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    setTimeout(() => {
      this.dialogRef.close(true); // Keep only this row
    }, 2500);
  }
}
