/** Angular */
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { get } from "lodash";
import * as moment from "moment";
import { InfoDialogComponent } from "./../../../../app/views/partials/content";
import { LocalDatePipe } from "./../pipes/local-date.pipe";
import { LayoutUtilsService } from "./layout-utils.service";

@Injectable()
export class CrudUtilsService {
  /**
   * Component constructor
   */
  constructor(
    private translate: TranslateService,
    private localDate: LocalDatePipe,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog
  ) {}

  /**
   * Delete
   */
  deleteItem(
    name: string,
    title: string,
    item: any,
    apiUrl: string,
    callback: () => void = null
  ) {
    const _title = `Delete ${title}`;
    const _description = `Are you sure to permanently delete this ${title}`;
    const _waitDesciption = `Deleting ${title}...`;
    const _deleteMessage = `Deleted ${title}`;

    // PGMTODO: refactor later
    if (
      name == "general-services-trackday-items" ||
      name == "location-services-trackday-items"
    ) {
      if (item.booked_services_count > 0) {
        this.dialog.open(InfoDialogComponent, {
          data: {
            title: _title,
            message: this.translate.instant("CANNOT_DELETE_BOOKED_SERVICES", {
              booked_services_count: item.booked_services_count,
            }),
          },
          width: "500px",
        });
        return;
      }
    }

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      //  this.layoutUtilsService.showNotification("success", _deleteMessage);

      if (callback) callback();
      //this.loadTableList();
    });
  }

  /**
   * Translate string value based on type
   * @param {string} value text
   * @returns {number}
   */
  translateByType(text, type) {
    switch (type.toLowerCase()) {
      case "date":
        return this.localDate.transform(text, "date");
      case "datetime":
        return this.localDate.transform(text, "datetime");
      default:
        return this.translate.instant(text);
    }
  }

  /**
   * Parse placeholders from an object data
   *
   * @param {string} text text to be parsed
   * @param {any} data object data to substitute
   * @param {FormGroupp} form form to substitute
   * @param {boolean} strick if true, return null if variable undefiend or null
   *
   * @returns {any}
   */
  parsePlaceholders(
    text: string | any,
    data: any,
    form?: UntypedFormGroup,
    strict?: boolean
  ) {
    let formattedValue: any = text;
    if (formattedValue == null || formattedValue === "") return formattedValue;
    if (typeof formattedValue !== "string") return formattedValue;

    let formattedValueEval: any = formattedValue + "";

    //static content that should not be replaced
    const staticContentPattern = /{%[\s\S]*%}/;
    const staticContent = formattedValue.match(staticContentPattern);
    if (staticContent) {
      const refinedText = staticContent[0]
        .replace("{%", "")
        .replace("%}", "")
        .trim();
      return refinedText;
    }

    const placeholders = formattedValue.match(/{[^}]+}/g);

    if (!placeholders) return formattedValue;

    if (text == "{_parent.id} ? {_parent.currency_id} : 'name:Euro'") {
      console.log(text, placeholders);
    }
    let isString = false;
    for (let placeholder of placeholders) {
      let fieldName = placeholder.substr(1, placeholder.length - 2);

      const formatter = fieldName.split("|");
      fieldName = formatter[0];

      let fieldValue = null;

      // special placeholders
      if (fieldName == "DATE_NOW") {
        fieldValue = moment().format();
      } else {
        const segments = fieldName.split(".");
        if (segments.indexOf("translations") >= 0) {
          const transFieldName = segments.pop();
          const attrPath = segments.join(".");
          const transData = get(data, attrPath);

          //if (!transData) console.log(attrPath, transData, data);
          const translate = transData.find(
            (r) => r.lang == (this.translate.currentLang || "en")
          );

          if (translate) {
            fieldValue = get(translate, transFieldName);
          }

          //console.log('parsePlaceholders', transFieldName, attrPath, transData, translate, fieldValue);
        } else {
          fieldValue = get(data, fieldName);

          if (text == "user_id={id}") {
            console.log(
              "fieldValue = get(data, fieldName);",
              fieldName,
              fieldValue,
              data
            );
          }

          //if (text == '{_init} && {_init.variant.location_guid}') console.log('{_init.variant.location_guid}:get', fieldValue, formatter[0], data.location_guid);
          if (fieldValue === undefined && form) {
            const formField = form.controls[formatter[0]];
            if (formField) fieldValue = formField.value;
          }

          if (moment(fieldValue, "YYYY-MM-DD HH:mm:ss", true).isValid())
            fieldValue = moment(fieldValue).format();
        }
      }

      // // special placeholders
      // if (fieldName == 'DATE_NOW') {
      // 	fieldValue = moment().format();
      // } else if (fieldName.indexOf('translations') >= 0) {
      // 	const transFieldName = fieldName.replace(/translations.*\./, '');
      // 	const translate = data.translations.find(r => r.lang == (this.translate.currentLang || 'en'));
      // 	//console.log('parsePlaceholders', transFieldName, translate);
      // 	if (translate) {
      // 		fieldValue = get(translate, transFieldName);
      // 	}
      // } else {
      // 	fieldValue = get(data, formatter[0]);
      // 	if (fieldValue === undefined && form) {
      // 		const formField = form.controls[formatter[0]];
      // 		if (formField) fieldValue = formField.value;
      // 	}

      // 	if (moment(fieldValue, 'YYYY-MM-DD HH:mm:ss', true).isValid()) fieldValue = moment(fieldValue).format();
      // }

      // parse formmater
      if (formatter.length > 1) {
        const formatType = formatter[1];
        fieldValue = this.translateByType(fieldValue, formatType);
      }

      if (strict && fieldValue == undefined) return null;

      if (fieldValue instanceof Date) fieldValue = moment(fieldValue).format();

      //if (text == '{location_guid}') console.log('{location_guid}:fieldValue', fieldValue);

      // plain tring
      formattedValue = formattedValue.replaceAll(
        placeholder,
        ((val) => {
          if (val == null) return "";
          else if (!isNaN(Number(val)) && !isNaN(parseFloat(val)))
            return parseFloat(val);
          else return val;
        })(fieldValue)
      );

      // eval
      formattedValueEval = formattedValueEval.replaceAll(
        placeholder,
        ((val) => {
          if (val === undefined) return "undefined";
          else if (val === null) return "null";
          else if (!isNaN(Number(val)) && !isNaN(parseFloat(val)))
            return parseFloat(val) + "";
          else {
            //if (text == '{location_guid}') console.log('{location_guid}', val, `"${val}"`);
            isString = true;
            return `"${val}"`;
          }
        })(fieldValue)
      );
    }

    try {
      if (text == "user_id={id}") {
        console.log("formattedValueEval", formattedValueEval);
      }

      let val = (window as any).eval.call(
        window,
        "(function (that, moment) {return " + formattedValueEval + "})"
      )(data, moment);
      return val;
    } catch (e) {
      return formattedValue;
    }
  }
}
