<!--begin::Head-->
<!-- div class="kt-login__head">
	<span class="kt-login__signup-label">Don't have an account yet?</span>&nbsp;&nbsp;
	<a href="javascript:;" routerLink="/auth/register" class="kt-link kt-login__signup-link kt-font-bold">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</a>
</div -->
<!--end::Head-->

<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="kt-login__title">
      <h3>Sign In</h3>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <div *ngIf="authStep === 1" [@inOutAnimation]>
      <form class="kt-form" [formGroup]="loginForm" autocomplete="off">
        <div class="form-group mb-4">
          <mat-form-field appearance="fill" class="mat-form-field-fluid">
            <mat-label>{{ "AUTH.INPUT.USERNAME" | translate }}</mat-label>
            <input
              matInput
              type="email"
              placeholder="{{ 'AUTH.INPUT.USERNAME' | translate }}"
              formControlName="username"
              autocomplete="off"
            />
            <mat-error *ngIf="isControlHasError('username', 'required')">
              <strong>{{
                "AUTH.VALIDATION.REQUIRED_FIELD" | translate
              }}</strong>
            </mat-error>
            <mat-error *ngIf="isControlHasError('username', 'minlength')">
              <strong
                >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
              >
            </mat-error>
            <mat-error *ngIf="isControlHasError('username', 'maxlength')">
              <strong
                >{{
                  "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate
                }}
                320</strong
              >
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="mat-form-field-fluid">
            <mat-label>{{ "AUTH.INPUT.PASSWORD" | translate }}</mat-label>
            <input
              matInput
              type="password"
              placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
              formControlName="password"
              autocomplete="off"
            />
            <mat-error *ngIf="isControlHasError('password', 'required')">
              <strong>{{
                "AUTH.VALIDATION.REQUIRED_FIELD" | translate
              }}</strong>
            </mat-error>
            <mat-error *ngIf="isControlHasError('password', 'minlength')">
              <strong
                >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
              >
            </mat-error>
            <mat-error *ngIf="isControlHasError('password', 'maxlength')">
              <strong
                >{{
                  "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate
                }}
                100</strong
              >
            </mat-error>
          </mat-form-field>
        </div>

        <!--begin::Action-->
        <div class="kt-login__actions">
          <!-- a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot">
					{{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
				</a -->
          <button
            (click)="submit()"
            mat-flat-button
            [loading]="loading$ | async"
            id="kt_login_signin_submit"
            color="primary"
            class="w-100"
          >
            {{ "AUTH.LOGIN.BUTTON" | translate }}
          </button>
        </div>
        <hr />
        <p>
          © {{ today | date : "yyyy" }} HTA Circuit Booking - v{{
            version.release
          }}
        </p>
        <!--end::Action-->
      </form>
    </div>
    <div *ngIf="authStep === 2" [@inOutAnimation]>
      <form class="kt-form" [formGroup]="codeForm" autocomplete="off">
        <div style="text-align: center">
          <div class="form-group custom-input">
            <mat-form-field appearance="fill" class="mat-form-field-fluid">
              <mat-label>{{ "AUTH.INPUT.PASSWORD" | translate }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
                formControlName="code"
                autocomplete="off"
              />
              <mat-error *ngIf="isControlHasError('password', 'required')">
                <strong>{{
                  "AUTH.VALIDATION.REQUIRED_FIELD" | translate
                }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('password', 'minlength')">
                <strong
                  >{{
                    "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate
                  }}
                  3</strong
                >
              </mat-error>
              <mat-error *ngIf="isControlHasError('password', 'maxlength')">
                <strong
                  >{{
                    "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate
                  }}
                  100</strong
                >
              </mat-error>
            </mat-form-field>
          </div>
          <div class="kt-login__actions">
            <button
              (click)="login()"
              mat-flat-button
              [loading]="loading$ | async"
              color="success"
              id="kt_login_signin_submit"
            >
              {{ "AUTH.LOGIN.BUTTON" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <!--begin::Divider-->
    <!-- div class="kt-login__divider">
			<div class="kt-divider">
				<span></span>
				<span>OR</span>
				<span></span>
			</div>
		</div -->
    <!--end::Divider-->

    <!--begin::Options-->
    <!-- div class="kt-login__options">
			<a href="https://www.facebook.com/circuitbooking/" target="_blank" class="btn btn-facebook kt-btn">
				<i class="fab fa-facebook-f"></i>
				Facebook
			</a>

			<a href="https://twitter.com/circuitbooking/" target="_blank" class="btn btn-twitter kt-btn">
				<i class="fab fa-twitter"></i>
				Twitter
			</a -->

    <!-- a href="javascript:;" class="btn btn-danger kt-btn">
				<i class="fab fa-google"></i>
				Google
			</a -->
    <!-- /div -->
    <!--end::Options-->
  </div>
  <!--end::Signin-->
</div>
<!--end::Body-->
