<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
  <crud-table
    title="Users"
    name="users"
    [columns]="columns"
    [createButtonLabel]="createButtonLabel"
    [searchableColumns]="searchableColumns"
    [apiUrl]="apiUrl"
    baseUrl="/user-management/users"
    (onSelectionChange)="selectionChange($event)"
  ></crud-table>
</div>
