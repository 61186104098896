<ng-container *ngIf="translations$ | async as translations">
  <mat-button-toggle-group
    name="templateLanguage"
    click
    [value]="selectedLanguage"
  >
    <mat-button-toggle
      *ngFor="let lang of languageButtons$ | async; let index"
      [value]="lang.lang"
      (change)="updateSelectedLanguage(lang)"
    >
      <i
        *ngIf="lang.hasError"
        class="fas fa-exclamation-circle text-danger mr-2"
      ></i>
      <img [src]="lang.flag" style="width: 16px" class="mr-2" />
      <span>{{ lang.name }}</span>
      <button
        *ngIf="lang.lang !== 'en'"
        mat-icon-button
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          removeTranslation(lang)
        "
      >
        <i class="fa fa-times"></i>
      </button>
    </mat-button-toggle>
    <mat-button-toggle
      *ngIf="(moreLanguagesButtons$ | async).length > 0"
      [matMenuTriggerFor]="langMenu"
      (click)="$event.preventDefault(); $event.stopPropagation()"
    >
      <span class="text-dark">
        Add Translation
        <span class="ml-2"><i class="fa fa-plus"></i></span>
      </span>

      <mat-menu #langMenu="matMenu">
        <ng-container *ngFor="let lang of moreLanguagesButtons$ | async">
          <button mat-menu-item (click)="addTranslation(lang)">
            <span class="kt-nav__link-icon">
              <img src="{{ lang.flag }}" style="width: 16px" class="mr-2" />
            </span>
            <span class="kt-nav__link-text">{{ lang.name }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </mat-button-toggle>
  </mat-button-toggle-group>
</ng-container>
