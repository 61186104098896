<div class="example-container mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource">
        <!-- Name Column -->
        <ng-container matColumnDef="laptime">
             <mat-header-cell class="table-header-cell" *matHeaderCellDef> Laptime </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.laptime}} </mat-cell>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="location">
             <mat-header-cell class="table-header-cell" *matHeaderCellDef> Location </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.variant.location.name}} </mat-cell>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="variant">
             <mat-header-cell class="table-header-cell" *matHeaderCellDef> Variant </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.variant.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
             <mat-header-cell class="table-header-cell" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">

                <button mat-icon-button
                    color="danger"
                    matTooltip="Delete item"
                    type="button"
                    (click)="deleteLaptime(i)">
                    <mat-icon>delete</mat-icon>
                </button>&nbsp;

            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
