// Angular
import { Injectable, inject } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { defer, Observable, of } from "rxjs";
// NGRX
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
// Auth actions
import {
  AuthActionTypes,
  Login,
  Logout,
  Register,
  UserLoaded,
  UserRequested,
} from "../actions/auth.actions";
import { AuthService } from "../../../services/auth.service";
import { AppState } from "../reducers/app.reducers";
import { environment } from "../../../../environments/environment";
import { isUserLoaded } from "../selectors/auth.selectors";

@Injectable()
export class AuthEffects {
  actions$ = inject(Actions);
  store = inject(Store<AppState>);
  auth = inject(AuthService);
  router = inject(Router);
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap((action) => {
          localStorage.setItem(
            environment.authTokenKey,
            action.payload.authToken
          );
          this.store.dispatch(new UserRequested());
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
          localStorage.removeItem(environment.authTokenKey);
          this.router.navigate(["/auth/login"], {
            queryParams: { returnUrl: this.returnUrl },
          });
        })
      ),
    { dispatch: false }
  );

  register$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Register>(AuthActionTypes.Register),
        tap((action) => {
          localStorage.setItem(
            environment.authTokenKey,
            action.payload.authToken
          );
        })
      ),
    { dispatch: false }
  );

  loadUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserRequested>(AuthActionTypes.UserRequested),
        withLatestFrom(this.store.pipe(select(isUserLoaded))),
        filter(([action, _isUserLoaded]) => !_isUserLoaded),
        mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
        tap((_user) => {
          if (_user) {
            this.store.dispatch(new UserLoaded({ user: _user }));

            // test data
            const testDataShowSwitch =
              _user.default_config.test_data_show_switch === null
                ? environment.testDataShowSwitch
                : _user.default_config.test_data_show_switch;
            const testData = testDataShowSwitch
              ? _user.default_config.test_data
              : false;
            localStorage.setItem("is_testdata", testData ? "1" : "0");
            localStorage.setItem(
              "is_test_data_show_switch",
              testDataShowSwitch ? "1" : "0"
            );

            // left,top,both
            localStorage.setItem(
              "frontend_navigation",
              _user.default_config.frontend_navigation
            );
          } else {
            this.store.dispatch(new Logout());
          }
        })
      ),
    { dispatch: false }
  );

  init$: Observable<Action> = createEffect(() =>
    defer(() => {
      const userToken = localStorage.getItem(environment.authTokenKey);
      let observableResult = of({ type: "NO_ACTION" });
      if (userToken) {
        observableResult = of(new Login({ authToken: userToken }));
      }
      return observableResult;
    })
  );

  private returnUrl: string;

  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });
  }
}
