import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TableConfigService } from "../../../../../../services/table.config.service";
import { BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";

type TEMPLATE_MODE_TYPE = "private" | "public";

@Component({
  selector: "app-save-template-as-form",
  templateUrl: "./save-template-as-form.component.html",
  styleUrls: ["./save-template-as-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveTemplateAsFormComponent implements OnInit {
  @Input() tableName: string;
  @Input() remainingColumns: any[];
  @Input() displayedColumns: any[];
  @Output() saveTemplateAsEvent = new EventEmitter();
  @Output() closeTemplateAsEvent = new EventEmitter();

  isLoading$ = new BehaviorSubject<boolean>(false);
  templateModes: { value: TEMPLATE_MODE_TYPE; text: string }[] = [
    { value: "private", text: "COMMON.PRIVATE" },
    { value: "public", text: "COMMON.PUBLIC" },
  ];
  form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private tableConfigService: TableConfigService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      mode: ["private", [Validators.required]],
    });
  }

  save(e?: Event) {
    e?.preventDefault();
    // this.saveTemplateAsEvent.emit({
    //   name: this.form.get("name").value,
    //   mode: this.form.get("mode").value,
    // });
    if (this.form.invalid) {
      this.form.get("name").markAsTouched();
      return;
    }
    this.isLoading$.next(true);
    const payload = {
      name: this.form.get("name").value,
      private: this.form.get("mode").value === "private" ? true : false,
      columns: [...this.displayedColumns, ...this.remainingColumns],
      tableName: this.tableName,
    };
    this.tableConfigService.saveExportTemplate(payload).subscribe({
      next: (response) => {
        this.isLoading$.next(false);
        this.saveTemplateAsEvent.emit({ ...payload, id: response.id, user_id: response.user_id });
        this.toastrService.success("Template saved successfully.");
        this.resetForm();
        this.close();
      },
      error: () => {
        this.toastrService.error(
          "Something went wrong while saving the template."
        );
        this.isLoading$.next(false);
      },
    });
  }

  close() {
    this.resetForm();
    this.closeTemplateAsEvent.emit();
  }

  resetForm() {
    this.form.reset({ name: "", mode: "private" });
  }
}
