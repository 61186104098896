<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-progress"></div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "SEND_EMAIL.SAVE_AS_MODAL.TITLE" | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <div class="form-group kt-form__group w-all">
        <form
          [formGroup]="templateForm"
          class="custom-input mt-2 w-all"
          autocomplete="off"
        >
          <div class="form-row">
            <custom-mat-select
              [name]="'template-type-selector'"
              [control]="templateForm.controls['templateTypeId']"
              [title]="'Template types'"
              [placeholder]="
                'SEND_EMAIL.SAVE_AS_MODAL.TEMPLATE_TYPES' | translate
              "
              (selectionChange)="(null)"
              [required]="true"
              [disabled]="false"
              [tooltip]="null"
              [multiple]="false"
              [options]="templateTypes$ | async"
              [optionValue]="'id'"
              [optionLabel]="'name'"
              [customError]="null"
              matFormFieldClass="mat-form-field-fluid custom-input"
            >
            </custom-mat-select>
          </div>
          <div class="form-row">
            <mat-form-field class="w-all w-100">
              <mat-label>{{
                "SEND_EMAIL.SAVE_AS_MODAL.INPUT_PLACEHOLDER" | translate
              }}</mat-label>
              <input
                matInput
                autocomplete="do_not_fill"
                formControlName="templateName"
                [placeholder]="
                  'SEND_EMAIL.SAVE_AS_MODAL.INPUT_PLACEHOLDER' | translate
                "
              />
              <mat-error *ngIf="!templateForm.get['templateName']?.valid">
                {{ templateForm.get("templateName").getError("invalid") }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="w-all w-100">
              <mat-label>{{
                "SEND_EMAIL.SAVE_AS_MODAL.DESCRIPTION_PLACEHOLDER" | translate
              }}</mat-label>
              <textarea
                matInput
                [placeholder]="
                  'SEND_EMAIL.SAVE_AS_MODAL.DESCRIPTION_PLACEHOLDER' | translate
                "
                formControlName="templateDescription"
              ></textarea>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
    <div
      class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right"
    >
      <div class="kt-form__actions kt-form__actions--sm" style="height: 64px;">
        <button
          mat-raised-button
          class="btn btn-outline-primary reset-button-styles info-outline-button pull-left"
          matTooltip="{{ 'COMMON.CANCEL' | translate }}"
          [mat-dialog-close]="false"
        >
          {{ "COMMON.CANCEL" | translate }}
        </button>
        <button
          mat-raised-button
          class="btn btn-outline-primary reset-button-styles success-outline-button pull-right"
          [disabled]="!templateForm.valid"
          (click)="saveTemplateAs()"
          [matTooltip]="'COMMON.SAVE' | translate"
        >
          <i class="la la-save"></i>
          <span class="kt-hidden-mobile">
            {{ "COMMON.SAVE" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
