<!-- begin:: Content Head -->
<div
  class="kt-subheader custom-subheader kt-grid__item"
  [ngClass]="{
    'kt-container--clear': clear,
    'has-menu-horizontal': hasMenuHorizontal
  }"
  [hidden]="subheaderService.disabled$ | async"
  id="kt_subheader"
>
  <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
    <div class="kt-subheader__toolbar">
        <cb-navigation-toolbox></cb-navigation-toolbox>        
      <kt-search-dropdown
        [icon]="'./assets/media/icons/svg/General/Search.svg'"
        [useSVG]="true"
      ></kt-search-dropdown>
    </div>
    <div class="kt-subheader__wrapper">
      <ng-container *ngIf="isDashboardPage()">
        <a
          [matMenuTriggerFor]="configDashboardMenus"
          href="javascript:;"
          class="btn kt-subheader__btn-primary"
          *ngIf="dashboardConfigService.editMode$ | async"
        >
          <i class="fa fa-wrench" aria-hidden="true"></i> Config Dashboard
        </a>

        <mat-menu #configDashboardMenus="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="addRowMenus">
            Add Row
          </button>
          <button mat-menu-item [matMenuTriggerFor]="addPortletMenus">
            Add Portlet
          </button>
        </mat-menu>
        <mat-menu #addRowMenus="matMenu">
          <button mat-menu-item (click)="addRow(1)">1 Column</button>
          <button mat-menu-item (click)="addRow(2)">2 Columns</button>
          <button mat-menu-item (click)="addRow(3)">3 Columns</button>
        </mat-menu>
        <mat-menu #addPortletMenus="matMenu">
          <button
            mat-menu-item
            *ngFor="let portlet of dashboardConfigService.portletMenus$ | async"
            (click)="addPortlet(portlet)"
          >
            <i class="{{ portlet.icon }}"></i>
            {{ dashboardConfigService.getPortletTitle(portlet) }}
          </button>
        </mat-menu>

        <a
          href="javascript:;"
          class="btn kt-subheader__btn-primary"
          [ngClass]="{ disabled: (dashboardConfigService.saving$ | async) }"
          (click)="cancelDashboardConfig()"
          *ngIf="dashboardConfigService.editMode$ | async"
        >
          <i class="fa fa-refresh" aria-hidden="true"></i> Cancel
        </a>

        <a
          href="javascript:;"
          class="btn kt-subheader__btn-primary kt-margin-r-10"
          [ngClass]="{ disabled: (dashboardConfigService.saving$ | async) }"
          (click)="saveDashboardConfig()"
          *ngIf="dashboardConfigService.editMode$ | async"
        >
          <span *ngIf="!(dashboardConfigService.saving$ | async)"
            ><i class="fa fa-check" aria-hidden="true"></i> Save</span
          >
          <span *ngIf="dashboardConfigService.saving$ | async">Saving...</span>
        </a>

        <a
          href="javascript:;"
          class="btn kt-subheader__btn-primary kt-margin-r-10"
          (click)="editDashboardConfig()"
          *ngIf="!(dashboardConfigService.editMode$ | async)"
        >
          <i class="fa fa-pencil" aria-hidden="true"></i> Customize
        </a>
      </ng-container>      
      <!--end:Config Dashboard-->      
    </div>
  </div>
</div>
<!-- end:: Content Head -->
