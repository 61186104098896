<div>
    <crud-table
            title="VirtualGarage"
            name="virtual-garage"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/user-management/virtualgarage"
            (onSelectionChange)="selectionChange($event)"
    ></crud-table>
</div>