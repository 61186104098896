<p *ngIf="canUpload()">
  <ngb-alert
    type="info"
    [dismissible]="false"
    class="alert alert-info d-flex align-items-center"
  >
    <i class="fas fa-info-square mr-2" style="font-size: 2em"></i>
    <span> Drag & drop files into box below or</span>
    <button
      class="ml-2"
      mat-raised-button
      (click)="dropzone.showFileSelector()"
    >
      click here to browse files
    </button>
  </ngb-alert>
</p>

<ng-container *ngIf="loadingMediaItems">
  <mat-progress-bar mode="buffer"></mat-progress-bar>
  <ngb-alert type="primary"> Loading list. Please wait... </ngb-alert>
</ng-container>

<ngx-dropzone
  #dropzone
  (change)="onSelect($event)"
  [expandable]="true"
  [accept]="getAcceptedFileTypes()"
  [disableClick]="true"
  [multiple]="maxUploadCount > 1"
  style="background-color: var(--background-1);border: none;"
>
  <ngx-dropzone-label>Drop {{ type }} here</ngx-dropzone-label>

  <!-- <ng-container *ngIf="type =='documents'; else images">
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ng-container> -->

  <div class="container" *ngIf="hasItemsToDisplay()">
    <div class="row row-cols-1 row-cols-md-3 mx-lg-n5">
      <div class="col py-3 px-lg-3" *ngFor="let f of files">
        <custom-dropzone-preview
          [model]="model"
          [modelId]="modelId"
          ngProjectAs="ngx-dropzone-preview"
          [file]="f"
          [description]="getFileDesc(f)"
          [removable]="true"
          (removed)="onRemove(f)"
          [groupType]="type"
          [type]="types[0]"
          [types]="types"
          (onInit)="previewItems.push($event)"
          (onDestroy)="previewItemOnDestroy($event)"
        >
        </custom-dropzone-preview>
      </div>
      <div class="col py-3 px-lg-3" *ngFor="let img of mediaItems">
        <ng-container *ngIf="type == 'images'; else documents">
          <custom-dropzone-preview
            [groupType]="type"
            [id]="img.id"
            [imageSrc]="img.thumb_url"
            [fullImageUrl]="img.url"
            [type]="img.type"
            [types]="types"
            [description]="img.description"
            [model]="model"
            [modelId]="modelId"
            [language]="img.language"
            ngProjectAs="ngx-dropzone-preview"
            [removable]="true"
            [readonly]="readonly"
            (removed)="onRemove(img)"
          >
          </custom-dropzone-preview>
        </ng-container>
        <ng-template #documents>
          <custom-dropzone-preview
            [groupType]="type"
            [id]="img.id"
            [imageSrc]="img.thumb_url"
            [fullImageUrl]="img.url"
            [type]="img.type"
            [types]="types"
            [description]="img.description"
            [model]="model"
            [modelId]="modelId"
            ngProjectAs="ngx-dropzone-preview"
            [removable]="true"
            [language]="img.language"
            [readonly]="readonly"
            (removed)="onRemove(img)"
          >
          </custom-dropzone-preview>
        </ng-template>
      </div>
    </div>
  </div>
</ngx-dropzone>
