import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { ProcessButton } from "../../../../shared/data/models";
import { ContextMenuService } from "../../service/context.menu.service";

@Component({
  selector: "app-action-menu-items",
  templateUrl: "./action-menu-items.component.html",
  styleUrls: ["./action-menu-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMenuItemsComponent implements OnInit {
  @Input() buttons: ProcessButton[];
  constructor(private contextMenuService: ContextMenuService) {}

  ngOnInit(): void {}

  clickHandler(event: ProcessButton) {
    this.contextMenuService.buttonClickEvent$.next(event);
  }

  buttonTrackByFn(index: number, item: ProcessButton) {
    return index;
  }
}
