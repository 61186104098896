import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../core/store/models/user.model';
import { Permission } from '../core/store/models/permission.model';
import { Role } from '../core/store/models/role.model';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../core/base';
import { environment } from '../../environments/environment';
import { ApiConfig } from '../core/config/api.config';
import { Router } from '@angular/router';
import { VirtualGarage } from '../core/store';
import { UserConfig } from '../core/store/models/user-config.model';
import { HttpUtilsService } from './../core/base/utils/http-utils.service';

const API_LOGIN_URL = 'api/v1/login';
const API_SIGNUP_URL = 'api/v1/signup';
const API_RESET_PASSWORD_URL = 'api/v1/sendpasswordresetlink';
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';
const API_USERS_URL = 'api/v1/users';

@Injectable()
export class AuthService {
    constructor(private http: HttpClient, private httpUtilsService: HttpUtilsService) { }
    // Authentication/Authorization

    login(username: string, password: string, code: string): Observable<User> {
        return this.http.post<User>(ApiConfig.ApiLoginURL(), { username, password, code });
    }

    getAuthType(username: string, password: string): Observable<User> {
        return this.http.post<User>(ApiConfig.ApiLoginAuthTypeURL(), { username, password });
    }

    isTokenExpired(): Observable<boolean> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        return this.http.get<boolean>(ApiConfig.ApiTokenExpiredURL());
    }

    getUserByToken(): Observable<User> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Authorization', 'Bearer ' + userToken);
        return this.http.post<User>(ApiConfig.ProfileURL(), {}, { headers: httpHeaders });
    }

    register(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(ApiConfig.ApiSignupURL(), user, { headers: httpHeaders })
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
        return this.http.post(ApiConfig.ApiSendPasswordResetLink(), { email })
            .pipe(catchError(this.handleError('forgot-password', []))
            );
    }

    //change password
    public changePassword(oldPassword: string, newPassword: string, confirmedNewPassword: string): Observable<any> {
        return this.http.put(ApiConfig.ChangePasswordURL(), {
            oldPassword,
            newPassword,
            confirmedNewPassword
        })
            .pipe(catchError(this.handleError('change-password', []))
            );
    }

    //reset password
    public resetPassword(password: string, email: string, password_confirmation: string, resetToken: string): Observable<any> {
        return this.http.post(ApiConfig.ApiChangePasswordURL(), { password, email, password_confirmation, resetToken })
            .pipe(catchError(this.handleError('reset-password', []))
            );
    }

    //reset password Email
    public resetPasswordEmail(token: string): Observable<any> {
        return this.http.post(ApiConfig.ApiChangePasswordEmailURL(), { token })
            .pipe(catchError(this.handleError('reset-password', []))
            );
    }


    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(API_USERS_URL);
    }

    getUserById(userId: number): Observable<User> {
        return this.http.get<User>(ApiConfig.UsersURL() + `/${userId}`);
    }


    // DELETE => delete the user from the server
    deleteUser(userId: number) {
        const url = `${ApiConfig.UsersURL()}/${userId}`;
        return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
    updateUser(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(ApiConfig.UsersURL() + `/${_user.id}`, _user, { headers: httpHeaders });
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: User): Observable<User> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(ApiConfig.UsersURL(), user, { headers: httpHeaders });
    }

    // UPDATE => PUT: update the user image on the server
    updateUserImage(_image: any, userId: Number): Observable<any> {
        var formData = new FormData();
        formData.append("image", _image);
        formData.append("user_id", userId.toString());
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'multipart/form-data');
        return this.http.post(ApiConfig.UsersImageURL(), formData, { headers: httpHeaders });
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        return this.http.get<QueryResultsModel>(ApiConfig.UsersURL(), { params: this.httpUtilsService.convertQueryParams(queryParams) });
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
        const url = `${ApiConfig.PermissionsURL()}`;
        return this.http.get<Permission[]>(url);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        const url = `${ApiConfig.PermissionsURL()}/getRolePermission/${roleId}`;
        return this.http.get<Permission[]>(url);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(ApiConfig.RolesURL());
    }

    getRoleById(roleId: number): Observable<Role> {
        return this.http.get<Role>(ApiConfig.RolesURL() + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
    createRole(role: Role): Observable<Role> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<Role>(ApiConfig.RolesURL(), role, { headers: httpHeaders });
    }

    // UPDATE => PUT: update the role on the server
    updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(ApiConfig.RolesURL() + `/${role.id}`, role, { headers: httpHeaders });
    }

    // DELETE => delete the role from the server
    deleteRole(roleId: number): Observable<Role> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const url = `${ApiConfig.RolesURL()}/${roleId}`;
        return this.http.delete<Role>(url, { headers: httpHeaders });
    }

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get<QueryResultsModel>(ApiConfig.RolesURL(), { params: this.httpUtilsService.convertQueryParams(queryParams) });
    }

    //Laptime
    getLaptimeByUserId(userId: number): Observable<Object> {
        return this.http.get(ApiConfig.LaptimeURL() + `/${userId}`);
    }

    //location
    getLocation(): Observable<Object> {
        return this.http.get(ApiConfig.LocationURL());
    }

    //get variant
    getRelatedVariant(locationId: number): Observable<Object> {
        return this.http.get(ApiConfig.getRelatedVariant() + `/${locationId}`);
    }

    //user info
    getUsersInfo(userId: number): Observable<Object> {
        return this.http.get(ApiConfig.UsersInfoURL() + `/${userId}`);
    }

    // DELETE => delete the items from the server
    deleteItems(ids: number[]): Observable<Object> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(ApiConfig.StartNumberBulkDeleteURL(), ids, { headers: httpHeaders });
    }

    //send mail to participants
    sendMailToParticipant(email: string): Observable<Object> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(ApiConfig.sendMailToParticipantURL(), email, { headers: httpHeaders });
    }

    //service List
    getServiceList(): Observable<Object> {
        return this.http.get(ApiConfig.ServicesURL());
    }


    // Roles
    getUserVirtualGarages(userId: number, isTestData = 0): Observable<VirtualGarage[]> {
        let params = new HttpParams();
        params = params.set('filter[user_id]', userId.toString());
        params = params.set('isTestdata', isTestData.toString());
        return this.http.get<VirtualGarage[]>(ApiConfig.VirtualGarageURL(), { params: params });
    }

    getUserConfig(): Observable<UserConfig> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Authorization', 'Bearer ' + userToken);
        return this.http.get<UserConfig>(ApiConfig.MyConfigUrl(), { headers: httpHeaders });
    }

    saveUserConfig(config: UserConfig | any): Observable<UserConfig> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Authorization', 'Bearer ' + userToken);

        return this.http.put<UserConfig>(ApiConfig.MyConfigUrl(), config, { headers: httpHeaders });
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
   *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
