import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { FormConfigService } from "../../../form.config.service";
import { DateTimePickerConfig } from "../models/date-time-picker.config.model";

@Component({
  selector: "cb-date-time",
  template: `
    <div [formGroup]="localForm" class="d-flex flex-column">
      <div class="d-flex date-time-container">
        <cb-datePicker
          [field]="this.field"
          [config]="this.config.date"
          [form]="localForm"
        ></cb-datePicker>
        <cb-timePicker
          [field]="this.field"
          [config]="this.config.time"
          [form]="localForm"
        ></cb-timePicker>
      </div>
      <mat-error
        style="font-size: 75%;"
        *ngIf="
          form.controls[this.config.formControlName].hasError &&
          form.controls[this.config.formControlName].touched
        "
      >
        {{
          form.controls[this.config.formControlName].errors
            | inputErrors : field
        }}
      </mat-error>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [".date-time-container { gap: 8px; }"],
})
export class CbDateTimeComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: DateTimePickerConfig;
  localForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private formConfigService: FormConfigService
  ) {}
  ngOnInit() {
    const formControl = this.formConfigService.getFormElement(
      this.config.formControlName
    );
    const [date, time] = (formControl.value || "").split(" ");
    this.localForm = this.fb.group({
      date: [moment(date), [Validators.required]],
      time: [time ? time.slice(0, 5) : "", [Validators.required]],
    });
    // if (this.localForm.valid) {
    //   formControl.setErrors(null);
    // } else {
    //   formControl.setErrors({ required: true });
    // }
    // this.localForm.statusChanges.subscribe((value) => {
    //   this.form.controls[this.config.formControlName].setErrors({
    //     required: value,
    //   });
    // });

    this.localForm.valueChanges.subscribe((value) => {
      if (value["date"] && value["time"]) {
        const { date, time } = value;
        const [hour, minute] = time.split(":");
        if (moment.isMoment(date)) {
          date.set({ hour, minute });
          formControl.setValue(date.format("Y-M-D H:m"));
          formControl.setErrors(null);
        }
      }
    });
  }
}
