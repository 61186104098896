// Angular
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// RxJS
import { Observable, of } from "rxjs";
import { QueryParamsModel, QueryResultsModel } from "../core/base";
import { HttpUtilsService } from "../core/base";
import { TranslateService } from "@ngx-translate/core";

// Models

@Injectable({
  providedIn: "root",
})
export class ApiService {
  /**
   * Service Constructor
   *
   * @param http: HttpClient
   */
  constructor(
    private http: HttpClient,
    private httpUtilsService: HttpUtilsService,
    private translate: TranslateService
  ) {}

  get(baseUrl: string, params?: any): Observable<any> {
    return this.http.get<any>(baseUrl, {
      params: {
        ...params,
        lang: this.translate.currentLang,
        is_testdata: localStorage.getItem("is_testdata"),
      },
    });
  }

  getAll(baseUrl: string): Observable<any[]> {
    return this.http.get<any[]>(baseUrl);
  }

  getById(baseUrl: string, slug: number | string): Observable<any> {
    return this.http.get<any>(baseUrl + `/${slug}`, {
      params: {
        lang: this.translate.currentLang,
        is_testdata: localStorage.getItem("is_testdata"),
      },
    });
  }

  // DELETE
  delete(baseUrl: string, slug: number | string) {
    let deleteUrl;
    if (baseUrl.indexOf("?") > 0) deleteUrl = baseUrl.replace("?", `/${slug}?`);
    else deleteUrl = baseUrl + `/${slug}`;
    return this.http.delete(deleteUrl);
  }

  // UPDATE
  put(baseUrl: string, item: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.put(baseUrl + `/${item.id}`, item, {
      headers: httpHeaders,
      params: {
        is_testdata: localStorage.getItem("is_testdata"),
      },
    });
  }

  // CREATE
  post(baseUrl: string, data?: any, options: any = {}): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http.post<any>(baseUrl, data, {
      ...options,
      headers: httpHeaders,
      params: {
        is_testdata: localStorage.getItem("is_testdata"),
      },
    });
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findAll(
    baseUrl: string,
    queryParams: QueryParamsModel
  ): Observable<QueryResultsModel> {
    return this.http.get<QueryResultsModel>(baseUrl, {
      params: this.httpUtilsService.convertQueryParams(queryParams),
    });
  }

  getHtml(baseUrl: string): Observable<string> {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );

    let params: any = {
      lang: this.translate.currentLang,
    };

    return this.http.get(baseUrl, {
      headers,
      responseType: "text",
      params: params,
    });
  }

//   test() {
//     return of([
//       `(function (w, d, s) {
//                 var a = d.getElementsByTagName('head')[0];
//                 var r = d.createElement('script');
//                 r.async = 1;
//                 r.src = s;
//                 r.setAttribute('id', 'usetifulScript');
//                 r.dataset.token = "{!! $key !!}";
//                 a.appendChild(r);
//             })(window, document, "https://www.usetiful.com/dist/usetiful.js");`,
//       `
//             (function() {
//                 alert('test')
//             })()
//             `,
//     ]);
//   }
}
