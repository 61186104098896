// Angular
import { Injectable, inject } from "@angular/core";
// RxJS
import { Observable, defer, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
// NGRX
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
// Services
import { AuthService } from "../../../services/auth.service";
// Actions
import {
  AllPermissionsLoaded,
  AllPermissionsRequested,
  PermissionActionTypes,
} from "../actions/permission.actions";
// Models
import { Permission } from "../models/permission.model";

@Injectable()
export class PermissionEffects {
  actions$ = inject(Actions);
  auth = inject(AuthService);
  loadAllPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AllPermissionsRequested>(
        PermissionActionTypes.AllPermissionsRequested
      ),
      mergeMap(() => this.auth.getAllPermissions()),
      map((result: Permission[]) => {
        return new AllPermissionsLoaded({
          permissions: result,
        });
      })
    )
  );

  init$: Observable<Action> = createEffect(() =>
    defer(() => {
      return of(new AllPermissionsRequested());
    })
  );
}
