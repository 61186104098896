import {
  animate,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const RemoveItemTrigger = trigger("removeItem", [
  transition(
    ":decrement",
    [
      query(
        ":leave",
        [
          animate(`{{fadeDuration}} ease-in`, style({ opacity: 0 })),
          animate(`{{slideDuration}} ease-in-out`, style({ height: 0 })),
        ],
        { optional: true }
      ),
    ],
    { params: { fadeDuration: "300ms", slideDuration: "100ms" } }
  ),
]);
    