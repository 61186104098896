import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged } from "rxjs/operators";
import { FormConfigService } from "../../../form.config.service";
import { DatePickerConfig } from "../models/datepicker.config.model";

@Component({
  selector: "cb-datePicker",
  template: `
    <div [formGroup]="form">
      <mat-form-field class="mat-form-field-fluid" appearance="fill">
        <mat-label>{{ config.placeholder }}</mat-label>
        <input
          matInput
          [formControlName]="config.formControlName"
          [placeholder]="config.placeholder"
          [matDatepicker]="picker1"
          [required]="config.required"
          [attr.data-pgm-formelement]="config.testingAttribute"
          [matTooltip]="config.tooltip"
        />
        <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1 touchUi></mat-datepicker>
        <mat-error
          *ngIf="form.controls[this.config.formControlName].hasError"
          [innerHtml]="
            form.controls[this.config.formControlName].errors
              | inputErrors : field
          "
        >
        </mat-error>
      </mat-form-field>
    </div>
  `,
  styles: [":host {display: block; width: 100%}"],
})
export class CbDatePickerComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: DatePickerConfig;
  constructor(
    private dateAdapter: DateAdapter<any>,
    private translateService: TranslateService,
    private formConfigService: FormConfigService
  ) {}

  ngOnInit() {
    this.dateAdapter.setLocale(this.translateService.currentLang || "en");
    this.form.controls[this.field.name]?.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe({
        next: (value) => {
          if (this.form.dirty) {
            setTimeout(() => {
              this.formConfigService.updateFormConfigs(value, this.field);
            });
          }
        },
      });
  }
}
