import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "tabError",
})
export class TabErrorPipe implements PipeTransform {
  transform(errors: any, tabName): any {
    if (!errors) {
      return [false, ""];
    }
    const error = errors.find((error) => error.tab === tabName);
    if (error) {
      return [true, error.message];
    }
    return [false, ""];
  }
}
