import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-generic-modal",
  templateUrl: "./generic-modal.component.html",
  styleUrls: ["./generic-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GenericModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeModal(res: boolean) {
    this.dialogRef.close({
      [this.data?.paramName || "paramName"]: res,
    });
  }

  ngOnInit(): void {}
}
