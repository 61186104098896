// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../../core/store/reducers/app.reducers';
import { ApiConfig } from "../../../../../core/config/api.config";
import { Validators } from "@angular/forms";
import { SubheaderService } from '../../../../../core/base';
// Auth
import { AuthService } from '../../../../../core/store/';

@Component({
	selector: 'invoices-table',
	templateUrl: './invoices-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesTableComponent implements OnInit {
	columns = [];
	apiUrl: string;
	createButtonLabel = 'Create Invoices';

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param router: Router
	 */
	constructor(
		private store: Store<AppState>,
		private router: Router,
		private subheaderService: SubheaderService,
		private auth: AuthService) {
	}

	 
	ngOnInit() {
		this.apiUrl = ApiConfig.InvoicesUrl();

		// Set title to page breadCrumbs
		this.subheaderService.setTitle('Invoices');
	}
}
