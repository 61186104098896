import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { ApiConfig } from "../../../../../core/config/api.config";

@Component({
  selector: "preset-dynamic-prices-table",
  templateUrl: "./preset-dynamic-prices-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresetDynamicPricesTableComponent implements OnInit {
  columns = [];

  searchableColumns = ["name", "email", "vat"];
  apiUrl: string;
  createButtonLabel = "Create Dynamic Price";

  dialogRef: any;
  dialogData: any;

  readonly = true;
  alwaysShowCheckboxes = true;

  selection: any[];
  selectedItems$ = new BehaviorSubject([]);

  constructor(private injector: Injector) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
  }

  ngOnInit() {
    this.apiUrl = ApiConfig.PresetDynamicPricesURL();
  }

  selectionChange(event) {
    this.selection = event;
    this.selectedItems$.next(event);
  }

  close(action: string) {
    const data = {
      action: action,
      selected: this.selection,
    };
    this.dialogRef.close(data);
  }
}
