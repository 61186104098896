// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../../core/store/reducers/app.reducers';
import { ApiConfig } from "../../../../../core/config/api.config";
import { Validators } from "@angular/forms";
// Auth

@Component({
    selector: 'templates-table',
    templateUrl: './templates-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesTableComponent implements OnInit {
    columns = [];
    apiUrl: string;
    createButtonLabel = null;

    /**
    * Component constructor
    *
    * @param  store: Store<AppState>
    * @param  router: Router
    */

    constructor(private store: Store<AppState>, private router: Router) { }


    ngOnInit() {

        this.apiUrl = ApiConfig.projectTemplatesURL();

    }
}
