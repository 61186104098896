<div
  #wizard
  class="kt-grid kt-wizard-v3 kt-wizard-v3--white"
  id="kt_wizard_v3"
  data-ktwizard-state="step-first"
>
  <div class="kt-grid__item">
    <div class="kt-wizard-v3__nav">
      <div class="kt-wizard-v3__nav-items px-0">
        <ng-container *ngFor="let tab of wizardTabs; let stepIndex = index">
          <a
            class="kt-wizard-v3__nav-item"
            href="javascript:;"
            data-ktwizard-type="step"
            [attr.data-ktwizard-state]="stepIndex == 0 ? 'current' : null"
          >
            <div class="kt-wizard-v3__nav-body">
              <div class="kt-wizard-v3__nav-label">
                <span>{{ stepIndex + 1 }}</span>
                {{ tab.caption | parsePlaceholder | translationFromConfig }}
              </div>
              <div class="kt-wizard-v3__nav-bar"></div>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper px-2 justify-content-start"
    style="flex-direction: column"
  >
    <!--begin: Form Wizard Form-->
    <div class="kt-form pb-4" id="kt_form">
      <div class="kt-form__actions p-0 previous">
        <!-- [style.display]="(isSubFormShown$ | async) ? 'none' : 'flex'" -->
        <button
          type="button"
          data-ktwizard-type="action-prev"
          class="btn btn-secondary btn-md btn-tall kt-font-bold kt-font-transform-u"
          [attr.data-pgm-formbutton]="'formbutton_previous_step'"
          [disabled]="(isFormSaving$ | async) === true"
        >
          <i class="fa fa-chevron-left"></i>
        </button>
      </div>

      <!--begin: Form Wizard Steps-->
      <ng-container *ngFor="let tab of wizardTabs; let stepIndex = index">
        <div
          class="kt-wizard-v3__content"
          data-ktwizard-type="step-content"
          [attr.data-ktwizard-state]="stepIndex == 0 ? 'current' : null"
        >
          <div class="row">
            <div
              [ngClass]="
                tab.show_step_summary !== false ? 'col-md-12 col-lg-8' : 'col'
              "
            >
              <div
                class="kt-heading kt-heading--md d-flex justify-content-between"
                *ngIf="!tab.table"
              >
                <span *ngIf="tab['sub_caption']; else defaultCaption">
                  {{
                    tab["sub_caption"]
                      | parsePlaceholder
                      | translationFromConfig
                  }}
                </span>
                <ng-template #defaultCaption>
                  <span>
                    {{
                      tab["caption"] | parsePlaceholder | translationFromConfig
                    }}
                  </span>
                </ng-template>
              </div>
              <div class="kt-form__section kt-form__section--first">
                <div
                  class="kt-wizard-v3__form"
                  [ngClass]="{ 'mt-0': tab.table }"
                >
                  <kt-alert
                    type="warn"
                    *ngIf="(currentStepErrors$ | async) !== ''"
                  >
                    <div [innerHTML]="currentStepErrors$ | async"></div>
                  </kt-alert>
                  <!--
                     this is added because start_number tab, requires dynamic data
                    which simply put, means that we need to have the id of the participant
                    in order to get the start number. This is a problem because the id is
                    only available after the participant is created. So we need to lazy load
                    the start_number tab.
                    // TODO: find a better solution for this                
                -->
                  <cb-lazy-load-component
                    *ngIf="tab.name === 'start_number'; else eagerLoad"
                  >
                    <ng-template #body>
                      <dynamic-form-builder
                        *ngIf="tab.fields"
                        [fields]="tab.fields"
                        [form]="wizardForms?.controls[tab.name]"
                        (onSubmit)="(null)"
                      ></dynamic-form-builder>
                    </ng-template>
                  </cb-lazy-load-component>

                  <ng-template #eagerLoad>
                    <dynamic-form-builder
                      *ngIf="tab.fields"
                      [fields]="tab.fields"
                      [form]="wizardForms?.controls[tab.name]"
                      (onSubmit)="(null)"
                    ></dynamic-form-builder>
                  </ng-template>

                  <div
                    *ngIf="
                      tab.table &&
                      (currentWizardStep$ | async) === stepIndex + 1
                    "
                  >
                    <cb-tab-content-crud-table
                      [content]="tab.table[0]"
                      [form]="wizardForms?.controls[tab.name]"
                    >
                    </cb-tab-content-crud-table>
                  </div>
                </div>
              </div>
              <!-- </ng-container> -->
            </div>
            <ng-container
              *ngIf="(currentWizardStep$ | async) === stepIndex + 1"
            >
              <div
                class="col-md-12 col-lg-4"
                *ngIf="(wizardStepSummaryApiUrl$ | async) !== null"
              >
                <div class="py-5">
                  <h4 class="mb-5">{{ "Summary" | translate }}</h4>
                  <html-content
                    [apiUrl]="wizardStepSummaryApiUrl$ | async"
                  ></html-content>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="stepIndex == 11">
              <html-content
                *ngIf="(currentWizardStep$ | async) == 12"
                [apiUrl]="bookingSummaryUrl$ | async"
              ></html-content>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!--end: Form Wizard Steps-->
      <div class="kt-form__actions p-0 next">
        <!-- [style.display]="(isSubFormShown$ | async) ? 'none' : 'flex'" -->
        <button
          type="button"
          data-ktwizard-type="action-next"
          class="btn btn-danger btn-md btn-tall kt-font-bold kt-font-transform-u"
          [attr.data-pgm-formbutton]="'formbutton_next_step'"
          [disabled]="isFormSaving$ | async"
        >
          <i class="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>

    <!--begin: Form Actions -->
    <div class="submit-button-container d-flex p-0">
      <!-- [style.display]="(isSubFormShown$ | async) ? 'none' : 'flex'" -->
      <button
        (click)="submit()"
        data-ktwizard-type="action-submit"
        class="btn btn-danger btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
        [attr.data-pgm-formbutton]="'formbutton_submit'"
        [disabled]="isFormSaving$ | async"
        [ngStyle]="{
          display: (shouldShowSubmitButton$ | async) === true ? 'block' : 'none'
        }"
      >
        Submit
      </button>
    </div>
    <!--end: Form Actions -->
    <!--end: Form Wizard Form-->
  </div>
</div>
