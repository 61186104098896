<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{getTitle()}}</h3>
        </div>
    </div>
    <div>
        <div class="kt-form">
            <div>
                <div class="kt-portlet__body">

                    <div class="kt-portlet__body-progress">                        
                        <cb-loading [diameter]="20"></cb-loading>
                    </div>

                    <div class="clearfix mb-5">
                        <kt-time-input matInput #time (onTimePicked)="getTime($event)"></kt-time-input>
                    </div>

                    <mat-form-field class="mat-form-field-fluid custom-input">
                        <mat-select [(ngModel)]="type" placeholder="Type"
                            [required]="true">
                            <mat-option
                                *ngFor="let type of laptimeTypes"
                                [value]="type">{{ 'ENUM.LAPTIME_TYPE.'+ type | translate }}</mat-option>
                        </mat-select>
                        <mat-error>Type is <strong>required</strong></mat-error>
                        <mat-hint align="start">Please select <strong>Type</strong></mat-hint>
                    </mat-form-field>

                    <div class="clearfix mb-5"></div>
                    
                    <mat-form-field class="mat-form-field-fluid custom-input">
                        <input matInput placeholder="Description" [(ngModel)]="description" [required]="true" />
                        <mat-error>Description is <strong>required</strong>
                        </mat-error>
                        <mat-hint align="start">Please enter <strong>Description</strong>
                        </mat-hint>
                    </mat-form-field>

                </div>
                <div class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border">
                    <div class="kt-form__actions kt-form__actions--solid">
                        <div class="row text-right">
                            <div class="col-lg-12">
                                <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial
                                    matTooltip="Cancel changes" class="danger-button-inverse">
                                    Cancel
                                </button>&nbsp;
                                <button type="button" [disabled]="!isValid() || viewLoading" mat-raised-button
                                    class="danger-button" (click)="onSubmit()" [disabled]="viewLoading"
                                    matTooltip="Add laptime">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>