import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { HtmlConfig } from "../models/html.config.model";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
@Component({
  selector: "cb-html-input",
  template: `
    <ng-container *ngIf="!!config">
      <div [formGroup]="form">
        <label class="description-ext">
          <ng-container *ngIf="!!config.tooltip">
            <span [matTooltip]="config.tooltip" matTooltipPosition="above">
              <i class="far fa-question-circle"></i>
            </span>
          </ng-container>

          {{ config?.placeholder }}
          <span *ngIf="config.required">*</span>
        </label>
        <ckeditor
          [editor]="Editor"
          [formControlName]="config.formControlName"
          name="myCkeditor"
          (ready)="onReady($event)"
          [attr.data-pgm-formelement]="config.testingAttribute"
          [disabled]="config.readonly"
        >
        </ckeditor>
        <!-- <div
        class="invalid-feedback"
        [ngClass]="
          formGet(field.name).invalid && formGet(field.name).touched
            ? 'd-block'
            : 'd-none'
        "
      >
        <div *ngIf="formGet(field.name).hasError('custom')">
          {{ formGetCustomError(field.name) | translate }}
        </div>
        <div *ngIf="!formGet(field.name).hasError('custom')">
          {{ field.title }} is <strong>required</strong>
        </div>
      </div> -->
      </div>
    </ng-container>
  `,
})
export class CbHtmlInput implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: HtmlConfig;
  public Editor = ClassicEditor;
  constructor() {}

  ckeditorGetData(field, event: ChangeEvent) {
    if (!event.editor) return;
    const data = event.editor.getData();
    this.form.controls[this.field.formControlName].setValue(
      event.editor.getData()
    );
    // const fieldName = field.name;
    // if (data.length > 50000) {
    //   console.log("data.length", fieldName, data.length);
    //   if (this.formCustomErrors == null) this.formCustomErrors = {};
    //   set(this.formCustomErrors, fieldName, "CKEDITOR_MAX_CONTENT_LIMIT");
    //   this.form.get(fieldName).setErrors({ custom: true });
    // } else {
    //   if (this.formCustomErrors == null) this.formCustomErrors = {};
    //   set(this.formCustomErrors, fieldName, null);
    //   this.form.get(fieldName).setErrors({ custom: null });
    //   this.formGet(fieldName).setValue(event.editor.getData());
    // }
  }

  onReady(eventData) {
    eventData.plugins.get("FileRepository").createUploadAdapter = function (
      loader
    ) {
      return new UploadAdapter(loader);
    };
  }

  ngOnInit() {}
}

export class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = (e) => {
            resolve({ default: myReader.result });
          };

          myReader.readAsDataURL(file);
        })
    );
  }
}
