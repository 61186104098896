// Angular
import { Injectable, inject } from "@angular/core";
// RxJS
import { Observable, defer, forkJoin, of } from "rxjs";
import { filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
// NGRX
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
// CRUD
// Services
import { AuthService } from "../../../services/auth.service";
// State
import { AppState } from "../reducers/app.reducers";
// Selectors
import { allRolesLoaded } from "../selectors/role.selectors";
// Actions
import {
  AllRolesLoaded,
  AllRolesRequested,
  RoleActionTypes,
  RoleCreated,
  RoleDeleted,
  RoleOnServerCreated,
  RoleUpdated,
  RolesActionToggleLoading,
  RolesPageLoaded,
  RolesPageRequested,
  RolesPageToggleLoading,
} from "../actions/role.actions";

@Injectable()
export class RoleEffects {
  actions$ = inject(Actions);
  auth = inject(AuthService);
  store = inject(Store<AppState>);

  showPageLoadingDistpatcher = new RolesPageToggleLoading({ isLoading: true });
  hidePageLoadingDistpatcher = new RolesPageToggleLoading({ isLoading: false });

  showActionLoadingDistpatcher = new RolesActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new RolesActionToggleLoading({
    isLoading: false,
  });

  loadAllRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AllRolesRequested>(RoleActionTypes.AllRolesRequested),
      withLatestFrom(this.store.pipe(select(allRolesLoaded))),
      filter(([action, isAllRolesLoaded]) => !isAllRolesLoaded),
      mergeMap(() => this.auth.getAllRoles()),
      map((roles) => {
        return new AllRolesLoaded({ roles });
      })
    )
  );

  loadRolesPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RolesPageRequested>(RoleActionTypes.RolesPageRequested),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.auth.findRoles(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: any = response[0];
        this.store.dispatch(this.hidePageLoadingDistpatcher);

        return new RolesPageLoaded({
          roles: result.data || result,
          totalCount: result.total,
          page: result.current_page,
        });
      })
    )
  );

  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RoleDeleted>(RoleActionTypes.RoleDeleted),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.auth.deleteRole(payload.id);
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );

  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RoleUpdated>(RoleActionTypes.RoleUpdated),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.auth.updateRole(payload.role);
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );

  createRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RoleOnServerCreated>(RoleActionTypes.RoleOnServerCreated),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.auth.createRole(payload.role).pipe(
          tap((res) => {
            this.store.dispatch(new RoleCreated({ role: res }));
          })
        );
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      })
    )
  );

  init$: Observable<Action> = createEffect(() =>
    defer(() => {
      return of(new AllRolesRequested());
    })
  );
}
