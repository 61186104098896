// Angular
import { Component, OnInit, Input } from "@angular/core";
// RxJS
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
// NGRX
import { Store, select } from "@ngrx/store";
import { Update } from "@ngrx/entity";
// Lodash
import { each, find, remove, isEmpty } from "lodash";
// State
import { AppState } from "../../../../../../core/store/reducers/app.reducers";
// Auth
import {
  User,
  Role,
  selectAllRoles,
  UserUpdated,
  AuthService,
} from "../../../../../../core/store";
import { FormGroup } from "@angular/forms";
//Layout
import { LayoutUtilsService, MessageType } from "../../../../../../core/base";
import { EventEmitterService } from "../../../../../../event-emitter.service";

@Component({
  selector: "kt-profile-image",
  templateUrl: "./profile-image.component.html",
})
export class ProfileImageComponent implements OnInit {
  user: User;
  // Incoming data
  @Input() loadingSubject = new BehaviorSubject<boolean>(false);
  @Input() userId: number;

  isProfileImage: Boolean;
  fileToUpload: File = null;
  imageUrl: any = "assets/media/users/default.jpg";

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private auth: AuthService,
    private AuthService: AuthService,
    private eventEmitterService: EventEmitterService
  ) {}

  ngOnInit() {
    this.isProfileImage = true;
    this.auth.getUserById(this.userId).subscribe((res) => {
      this.user = res;
      var imageDataUrl = res.pic.toString();
      this.imageUrl = imageDataUrl != "" ? imageDataUrl : this.imageUrl;
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    // this.user.pic = this.fileToUpload;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
    };
  }

  uploadProfileImage() {
    var updatedUserImage = {
      id: this.user.id,
      image: this.imageUrl,
    };

    this.AuthService.updateUserImage(this.fileToUpload, this.user.id)
      .pipe(
        tap((res) => {
          this.eventEmitterService.onUploadImage(res);
          const message = `User successfully has been saved.`;
          this.layoutUtilsService.showNotification("info", message);
        })
      )
      .subscribe();
  }
}
