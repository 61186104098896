<header>
  <h3>{{ data?.title || "" }}</h3>
</header>
<div class="body">
  <div class="icon" *ngIf="data?.['icon']">
    <i [class]="data['icon']"></i>
  </div>
  <div class="message" [innerHTML]="data?.message || ''"></div>
</div>
<div class="dialogActions">
  <div>
    <button
      *ngIf="data?.['cancel_label']"
      mat-flat-button
      color="warn"
      mat-dialog-close
    >
      <i class="fa-solid fa-circle-x"></i>
      {{data?.['cancel_label']}}
    </button>
  </div>
  <div>
    <button
      *ngIf="data?.['no_label']"
      mat-flat-button
      color="warn"
      (click)="closeModal(false)"
    >
      <i class="fa-solid fa-circle-x"></i>
      {{data?.['no_label']}}
    </button>
    <button
      *ngIf="data?.['yes_label']"
      color="success"
      mat-flat-button
      (click)="closeModal(true)"
      cdkFocusInitial
    >
      <i class="fa-solid fa-check"></i>
      {{data?.['yes_label']}}
    </button>
  </div>
</div>
