// Angular
import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
// CRUD
import { QueryResultsModel } from '../models/query-results.model';
import { QueryParamsModel } from '../models/query-params.model';
import { HttpExtenstionsModel } from '../models/http-extentsions-model';

@Injectable()
export class HttpUtilsService {
	/**
	 * Prepare query http params
	 * @param queryParams: QueryParamsModel
	 */
	getFindHTTPParams(queryParams): HttpParams {
		const params = new HttpParams()
			.set('lastNamefilter', queryParams.filter)
			.set('sortOrder', queryParams.sortOrder)
			.set('sortField', queryParams.sortField)
			.set('pageNumber', queryParams.pageNumber.toString())
			.set('pageSize', queryParams.pageSize.toString())
			.set('isTestdata', queryParams.isTestdata.toString());

		return params;
	}

	convertQueryParams(queryParams: QueryParamsModel) {
		const httpHeaders = new HttpHeaders();
		let params = new HttpParams();

		if (!queryParams) return params;

		httpHeaders.set('Content-Type', 'application/json');

		Object.keys(queryParams.search).map(key => {
			if (key && queryParams.search[key] != null) params = params.set(`search[${key}]`, encodeURIComponent(queryParams.search[key]));
		});

		Object.keys(queryParams.filter).map(key => {
			if (key && queryParams.filter[key] != null) params = params.set(`filter[${key}]`, queryParams.filter[key]);
		});

		Object.keys(queryParams.filterRange || {}).map(key => {
			if (key && queryParams.filterRange[key] != null) params = params.set(`filterRange[${key}]`, queryParams.filterRange[key]);
		});

		params = params.set('sortOrder', queryParams.sortOrder);
		params = params.set('sortField', queryParams.sortField);
		params = params.set('pageNumber', queryParams.pageNumber.toString());
		params = params.set('pageSize', queryParams.pageSize.toString());
		params = params.set('isTestdata', queryParams.isTestdata.toString());
		if (queryParams.lang) params = params.set('lang', queryParams.lang);

		return params;
	};

	/**
	 * get standard content-type
	 */
	getHTTPHeaders(): HttpHeaders {
		const result = new HttpHeaders();
		result.set('Content-Type', 'application/json');
		return result;
	}

	baseFilter(_entities: any[], _queryParams: QueryParamsModel, _filtrationFields: string[] = []): QueryResultsModel {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.baseFilter(_entities, _queryParams, _filtrationFields);
	}

	sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.sortArray(_incomingArray, _sortField, _sortOrder);
	}

	searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.searchInArray(_incomingArray, _queryObj, _filtrationFields);
	}
}
