import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { EventEmitterService } from "../../../../../../app/event-emitter.service";
import {
  ConfirmActionDialogComponent,
  InfoDialogComponent,
} from "../../../../../../app/views/partials/content";
import { ApiConfig } from "../../../../../core/config/api.config";

@Component({
  selector: "trackday-items-form",
  templateUrl: "./trackday-items-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackdayItemsFormComponent implements OnInit, OnDestroy {
  apiUrl: string;
  relatedUrl: string;

  constructor(
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.apiUrl = ApiConfig.TrackdayItemsURL();
    this.relatedUrl = ApiConfig.getRelatedVariant();

    const name = "trackday-items";
    const event = (this.eventEmitterService.invokeCrudTableCreate[name] = {
      before: new EventEmitter<any>(),
      after: new EventEmitter<any>(),
    });

    event.before.subscribe((data) => {
      console.log("invokeCrudTableCreate", data);
      if (data.name == name) {
        const masterData = data.masterData;

        if (masterData.is_fully_booked) {
          if (masterData.has_waitinglist) {
            const title = `FULLY_BOOKED`;
            const message = `SET_USER_STATUS_WAITINGLIST_MSG`;

            let dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
              data: {
                title: this.translate.instant(title),
                message: this.translate.instant(message),
              },
              width: "50%",
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              event.after.emit(dialogResult);
            });
          } else {
            const title = `FULLY_BOOKED`;
            const message = `EVENT_FULLY_BOOKED_MSG`;
            let dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                title: this.translate.instant(title),
                message: this.translate.instant(message),
              },
              width: "50%",
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              event.after.emit(false);
            });
          }

          return;
        }
      }

      event.after.emit(true);
    });
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    const name = "trackday-items";
    const { before, after } =
      this.eventEmitterService.invokeCrudTableCreate[name];
    before.unsubscribe();
    after.unsubscribe();
  }
}
