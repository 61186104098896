// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../base';
// State
import { CrudTableState } from '../reducers/crud-table.reducers';
import { each } from 'lodash';

export const selectItemsState = createFeatureSelector<CrudTableState>('crudTable');

export const selectItemById = (itemId: number) => createSelector(
    selectItemsState,
    crudTableState => crudTableState.entities[itemId]
);

export const selectPageLoading = createSelector(
    selectItemsState,
    crudTableState => crudTableState.listLoading
);

export const selectCrudActionLoading = createSelector(
    selectItemsState,
    crudTableState => crudTableState.actionsloading
);

export const selectLastCreatedItemId = createSelector(
    selectItemsState,
    crudTableState => crudTableState.lastCreatedItemId
);

export const selectLastUpdatedItemId = createSelector(
    selectItemsState,
    crudTableState => crudTableState.lastUpdatedItemId
);

export const selectLastDeletedItemId = createSelector(
    selectItemsState,
    crudTableState => crudTableState.lastDeletedItemId
);

export const selectServerError = createSelector(
    selectItemsState,
    crudTableState => crudTableState.serverErrors
);

export const selectPageLastQuery = createSelector(
    selectItemsState,
    crudTableState => crudTableState.lastQuery
);

export const selectItemsInStore = createSelector(
    selectItemsState,
    crudTableState => {
        const items: any[] = [];
        each(crudTableState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: any[] = httpExtension.sortArray(items, crudTableState.lastQuery.sortField, crudTableState.lastQuery.sortOrder);
        return new QueryResultsModel(result, crudTableState.totalCount, '');
    }
);

export const selectItemsShowInitWaitingMessage = createSelector(
    selectItemsState,
    crudTableState => crudTableState.showInitWaitingMessage
);

export const selectHasItemsInStore = createSelector(
    selectItemsState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
