import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

export type HtmlModalSettingsType = {
  config: {
    apiUrl: string;
    title: string;
    displayRefreshButton: boolean;
    id_fields: string[];
  };
  selected?: any[];
  type?: "info" | "fullscreen";
};

// this component takes in a url and the selected rows, calls the url,
// the url returns another url, which in turn returns the html to be displayed
@Component({
  selector: "app-html-modal",
  templateUrl: "./html-modal.component.html",
  styleUrls: ["./html-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public settings: HtmlModalSettingsType,
    private _dialogRef: MatDialogRef<HtmlModalComponent>,
    private http: HttpClient
  ) {}
  isLoading$ = new BehaviorSubject<boolean>(false);

  settings$ = of(this.settings).pipe(
    tap((_) => {
      this.isLoading$.next(true);
    }),
    map((settings) => {
      return {
        idFields: settings.config.id_fields,
        apiUrl: settings.config.apiUrl,
        title: settings.config.title,
        displayRefreshButton: settings.config.displayRefreshButton,
        httpMethod: settings.selected?.length > 0 ? "POST" : "GET",
        type: settings.type ?? "fullscreen",
      };
    })
  );

  vm$ = this.settings$.pipe(
    map((settings) => {
      if (!this.settings?.selected) {
        return {
          ...settings,
          selected: [],
        };
      }
      return {
        ...settings,
        selected: this.settings.selected.map((row) => {
          return settings.idFields.map((field) => row[field]);
        }),
      };
    }),
    switchMap((settings) => {
      if (settings.httpMethod === "GET") {
        return of({
          ...settings,
          apiUrl: settings.apiUrl,
        });
      }

      return this.postCall(settings).pipe(
        map((res: any) => {
          this.isLoading$.next(false);
          return {
            ...settings,
            apiUrl: res.url,
          };
        })
      );
    })
  );

  postCall(settings) {
    return this.http.post(settings.apiUrl, {
      selected: settings?.selected,
    });
  }

  getCall(settings) {
    return this.http.get(settings.apiUrl);
  }

  ngOnInit(): void {
    console.log("settings", this.settings);
  }
  closeDialog() {
    this._dialogRef.close();
  }
}
