import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { sendEmailSelectors } from "../../store";
import { SendEmailService } from "../../store/send-email.service";

@Component({
  selector: "app-template-placeholders",
  templateUrl: "./template-placeholders.component.html",
  styleUrls: ["./template-placeholders.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatePlaceholdersComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private sendEmailService: SendEmailService
  ) {}

  htmlContent$ = new BehaviorSubject("");
  isLoading$ = new BehaviorSubject(false);
  destroyed$ = new Subject<void>();

  ngOnInit(): void {
    combineLatest([
      this.store.pipe(select(sendEmailSelectors.selectTemplateWorkflow)),
      this.store.pipe(select(sendEmailSelectors.selectSendConfig)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([workflow, config]) => {
        if (workflow) {
          this.isLoading$.next(true);
          this.sendEmailService
            .getTemplatePlaceholders(workflow)
            .subscribe((htmlContent) => {
              this.htmlContent$.next(htmlContent);
              this.isLoading$.next(false);
            });
        } else {
          this.htmlContent$.next("");
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
