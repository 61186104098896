<div>
    <crud-table
            title="Statistics"
            name="statistics"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/statistics"
            [readonly]="readonly"
            [alwaysShowCheckboxes]="alwaysShowCheckboxes"
    ></crud-table>
</div>
