import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "app-crud-layout-switcher",
  templateUrl: "./crud-layout-switcher.component.html",
  styleUrls: ["./crud-layout-switcher.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrudLayoutSwitcherComponent {
  constructor() {}
  @Input() defaultValue = "table";
  @Output() change = new EventEmitter<string>();

  handleChange(event: string) {
    this.change.emit(event);
  }
}
