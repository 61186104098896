<ng-container *ngIf="(isLoading$ | async) !== true; else loading">
  <ng-container *ngIf="selectedConfiguration$ | async as selectedConfiguration">
    <div class="category-selector__container mat-elevation-z1">
      <div
        *ngFor="let configuration of configurations$ | async"
        (click)="changeConfiguration(configuration)"
        class="category-selector__item"
        [ngClass]="{
          active: configuration.title == selectedConfiguration.title
        }"
      >
        <span
          >{{ configuration.title | translate }} ({{
            configuration.count
          }})</span
        >
        <i
          [ngClass]="{
            'text-white': configuration.title === selectedConfiguration.title,
            'text-danger': configuration.title !== selectedConfiguration.title
          }"
          class="fas fa-exclamation-circle ng-star-inserted pl-2"
          *ngIf="configuration.hasError"
          aria-hidden="true"
        ></i>
      </div>
    </div>
    <div class="result-container">
      <form [formGroup]="configForm">
        <div class="results">
          <mat-card>
            <mat-card-header>
              <mat-card-title>
                <div class="form-header">
                  <div class="text-header">
                    <i
                      *ngIf="selectedConfiguration?.icon"
                      [ngClass]="selectedConfiguration.icon"
                    ></i>
                    <div class="text-header__title">
                      {{ selectedConfiguration?.title }}
                    </div>
                    <ng-container ktPortletAlerts *ngIf="configForm.dirty">
                      <div class="align-items-center">
                        <div
                          class="align-items-center d-flex bg-warning px-3 rounded"
                        >
                          <i class="la la-warning"></i>
                          <span style="font-size: small">{{
                            "TRANSLATOR.WARNING_UNSAVED_CHANGES" | translate
                          }}</span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="actions">
                    <button mat-flat-button color="success" (click)="update()">
                      <i class="fa-floppy-disk fa-solid"></i>
                      <span class="kt-hidden-mobile">
                        {{ "COMMON.SAVE" | translate }}
                      </span>
                    </button>
                    <button (click)="closeModal()" color="warn" mat-flat-button>
                      <i class="fa-solid fa-circle-x"></i>
                      <span class="kt-hidden-mobile">
                        {{ "COMMON.CLOSE" | translate }}
                      </span>
                    </button>
                    <div>
                      <span *ngIf="(isSaving$ | async) === true">
                        <cb-loading
                          [diameter]="20"
                          [isAbsolute]="false"
                        ></cb-loading>
                      </span>
                    </div>
                  </div>
                </div>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-divider></mat-divider>
              <div class="form-container">
                <div *ngFor="let item of currentConfigurations$ | async">
                  <ng-container *ngIf="item.type == 1">
                    <div class="input-row">
                      <div class="config-label">{{ item.key }}</div>
                      <div class="config-input">
                        <mat-form-field
                          class="mat-form-field-fluid"
                          appearance="fill"
                        >
                          <input
                            matInput
                            [formControlName]="item.key"
                            [value]="item.value"
                          />
                          <mat-error
                            *ngIf="
                              configForm.get(item.key).hasError('required')
                            "
                            >{{
                              "AUTH.VALIDATION.REQUIRED_FIELD" | translate
                            }}</mat-error
                          >
                        </mat-form-field>
                      </div>
                      <div class="config-description">
                        <div class="flex-1 gap-1 item-description-container">
                          <div class="flex-1 item-description">
                            {{ item.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="item.type == 4">
                    <div class="input-row">
                      <div class="config-label">{{ item.key }}</div>
                      <div class="config-input">
                        <mat-form-field
                          class="mat-form-field-fluid"
                          appearance="fill"
                        >
                          <input
                            matInput
                            [formControlName]="item.key"
                            [value]="item.value"
                          />
                          <mat-error
                            *ngIf="
                              configForm.get(item.key).hasError('required')
                            "
                            >{{
                              "AUTH.VALIDATION.REQUIRED_FIELD" | translate
                            }}</mat-error
                          >
                        </mat-form-field>
                      </div>
                      <div class="config-description">
                        <div class="flex-1 gap-1 item-description-container">
                          <div class="flex-1 item-description">
                            {{ item.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="item.type == 2">
                    <div class="input-row">
                      <div class="config-label">{{ item.key }}</div>
                      <div class="config-input">
                        <mat-form-field
                          class="mat-form-field-fluid"
                          appearance="fill"
                        >
                          <input
                            matInput
                            type="number"
                            [placeholder]="item.key"
                            [formControlName]="item.key"
                            [value]="item.value"
                          />
                          <mat-error
                            *ngIf="
                              configForm.get(item.key).hasError('required')
                            "
                            >{{
                              "AUTH.VALIDATION.REQUIRED_FIELD" | translate
                            }}</mat-error
                          >
                        </mat-form-field>
                      </div>
                      <div class="config-description">
                        <div class="flex-1 gap-1 item-description-container">
                          <div class="flex-1 item-description">
                            {{ item.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="item.type == 16">
                    <div class="flex-1 gap-1 slider-label">
                      <div class="config-label">{{ item.key }}</div>
                      <div class="config-input">
                        <mat-slide-toggle
                          [checked]="false"
                          color="primary"
                          [formControlName]="item.key"
                        >
                        </mat-slide-toggle>
                      </div>
                      <div class="config-description">
                        <div class="flex-1 gap-1 item-description-container">
                          <div class="flex-1 item-description">
                            {{ item.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </form>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="loadingContainer">
    <cb-loading [diameter]="20"></cb-loading>
  </div>
</ng-template>

<ng-container *ngIf="(hasError$ | async) === true">
  <div class="errorContainer">
    <h3>
      {{ "COMMON.UNEXPECTED_ERROR" | translate }}
    </h3>
    <button mat-flat-button color="success" (click)="retry()">
      <i class="fa-solid fa-arrows-rotate"></i>
      Retry
    </button>
  </div>
</ng-container>
