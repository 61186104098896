import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { SelectDynamicOption, SingleSelectFilter } from "../../types/types";
import { FormControl } from "@angular/forms";
import { FilterService } from "../../services/filter.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

@Component({
  template: `<cb-custom-select
    [allowMultiple]="false"
    [selectConfig]="dynamicOptions$ | async"
    [title]="filter?.title"
    [name]="filter?.field"
    [control]="control"
    (onSelectionChange)="handleSelectionChange($event)"
  ></cb-custom-select>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSingleSelectComponent implements OnInit, OnDestroy {
  @Input() filter: SingleSelectFilter;
  @Input() control: FormControl;
  dynamicOptions$ = new BehaviorSubject<SelectDynamicOption>(null);
  destroyed$ = new Subject();
  constructor(private filterService: FilterService) {}

  ngOnInit() {
    this.dynamicOptions$.next(this.filter.dynamic_options);
    const parentFieldName = this.filter.dynamic_options.parent_field;
    if (parentFieldName) {
      this.filterService.filterForm.controls[parentFieldName]?.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value) => {
          if (!value) {
            this.control.setValue(null);
            this.handleSelectionChange({ value: null, label: "" });
          }
          this.dynamicOptions$.next({
            ...this.filter.dynamic_options,
            supportingData: this.prepareSupportingData(value, parentFieldName),
          });
        });
    }
  }

  prepareSupportingData(value, field) {
    if (!value) {
      return null;
    }
    return {
      [field]: value,
    };
  }

  handleSelectionChange(event: { value: string; label: string }) {
    this.filterService.setAppliedFilters({
      component: this.filter,
      ...event,
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
