import { createReducer, on } from "@ngrx/store";
import { appGlobalConfigActions } from "../actions/app.global.config.actions";
import { AppState } from "./app.reducers";

export interface AppGlobalConfigState {
  shouldTablesDisplayZero: boolean;
}

export const appGlobalConfigInitialState: AppGlobalConfigState = {
  shouldTablesDisplayZero: true,
};

const reducer = createReducer(
  appGlobalConfigInitialState,
  on(appGlobalConfigActions.updateAppGlobalConfig, (state, config) => ({
    ...state,
    shouldTablesDisplayZero: config["table_show_zero"],
  }))
);

export function appGlobalConfigReducer(
  state: AppGlobalConfigState | undefined,
  action: any
) {
  return reducer(state, action);
}

export interface State extends AppState {
  appGlobalConfig: AppGlobalConfigState;
}
