import { AbstractControl, ValidatorFn } from "@angular/forms";

export function serverValidator(field: string): ValidatorFn {
  return (formControl: AbstractControl) => {
    return {
      serverError: field,
      dependent: field,
    };
  };
}
