// Angular
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
// RxJS
import { Subscription } from "rxjs";
// State
import { AppState } from "../../../../../../../core/store/reducers/app.reducers";
// Lodash
// NGRX
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { CrudTableService } from "../../../../../../../../app/services";
import { ApiConfig } from "../../../../../../../core/config/api.config";

@Component({
  selector: "kt-variant-laptime-dialog",
  templateUrl: "./variant-laptime-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VariantLaptimeDialogComponent implements OnInit, OnDestroy {
  // Public properties
  time: string;
  type: string;
  description: string;
  viewLoading = false;
  loadingAfterSubmit = false;
  // Private properties
  private componentSubscriptions: Subscription;
  isTestdata: any;
  apiUrl: string;
  laptimeTypes: any[] = [];

  /**
   * Component constructor
   *
   * @param dialogRef: MatDialogRef<RoleEditDialogComponent>
   * @param data: any
   * @param store: Store<AppState>
   */
  constructor(
    public dialogRef: MatDialogRef<VariantLaptimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    public crudTableService: CrudTableService
  ) {
    //'LOCATIONS.VARIANTS.LAPTIME_TYPE.__LEVEL1__'
    this.laptimeTypes = [
      "__LEVEL1__",
      "__LEVEL2__",
      "__LEVEL3__",
      "__LEVEL4__",
      "__PERSONAL__",
      "__NOTIME__",
      "__NEVERONTHETRACK__",
    ];
  }

  /**
   * On init
   */
  ngOnInit() {}

  /**
   * On destroy
   */
  ngOnDestroy() {}

  /** UI */
  /**
   * Returns component title
   */
  getTitle(): string {
    return "Variant Laptime";
  }

  isValid(): boolean {
    return this.time != "00:00:00.000" && !!this.description;
  }

  onSubmit() {
    console.log(this.time);

    this.addLaptime();

    // this.hasFormErrors = false;
    // this.loadingAfterSubmit = false;
    // /** check form */
    // if (!this.isValid()) {
    // 	this.hasFormErrors = true;
    // 	return;
    // }

    // this.createCouponItems();
  }

  getTime(time: any) {
    this.time = time;
  }

  addLaptime() {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    this.apiUrl = ApiConfig.VariantsURL();

    const variantId = this.data.variant.id;

    var formData = {
      laptime: this.time,
      description: this.description,
      is_testdata: this.isTestdata,
    };

    this.crudTableService
      .createItem(this.apiUrl + `/${variantId}/laptimes`, formData)
      .subscribe(
        (response) => {
          this.dialogRef.close(true);
        },
        (err) => {},
        () => {
          this.viewLoading = false;
        }
      );
  }

  // createCouponItems() {
  // 	this.apiUrl = ApiConfig.CouponItemsURL();
  // 	this.loadingAfterSubmit = true;
  // 	this.viewLoading = true;
  // 	var formData = {
  // 		number_of_items: this.number,
  // 		is_testdata: this.isTestdata,
  // 	}
  // 	var pageURL = window.location.href;
  // 	var lastId = pageURL.substr(pageURL.lastIndexOf('/') + 1);
  // 	if(lastId === 'coupons') {
  // 		formData['item'] = this.data.item;
  // 	} else {
  // 		formData['item'] = lastId;
  // 	}
  // 	this.store.dispatch(new ItemOnServerCreated({ item: formData, url: this.apiUrl }));
  // 	this.dialogRef.close({
  // 		isEdit: false
  // 	});
  // }
}
