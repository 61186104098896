<header matDialogTitle>
  <div class="text">
    <span class="title">
      <i class="fa-solid fa-file-export"></i>
      <span>{{ "EXPORT_TABLE.TITLE" | translate }}</span>
    </span>
  </div>
  <div class="modal-actions">
    <!-- <button
      class="btn btn-outline-primary reset-button-styles info-outline-button"
      mat-raised-button
      [matMenuTriggerFor]="saveAsMenu"
      #menuTrigger="matMenuTrigger"
    >
      open menu
    </button> -->
    <button mat-flat-button color="warn" (click)="close()">
      <i class="fa-solid fa-circle-x"></i>
      {{ "COMMON.CLOSE" | translate }}
    </button>
    <button mat-flat-button color="success" (click)="export(false)">
      <i class="fa-light fa-file-export"></i>
      {{ "EXPORT_TABLE.EXPORT" | translate }}
    </button>
    <button mat-flat-button color="success" (click)="export(true)">
      <i class="fa-solid fa-floppy-disk"></i>
      {{ "EXPORT_TABLE.EXPORT_CLOSE" | translate }}
    </button>
  </div>
</header>
<div class="dialog-body" matDialogContent>
  <div class="export-settings">
    <div class="radio-container">
      <div>
        <h3>{{ "EXPORT_TABLE.DATA" | translate }}</h3>
      </div>
      <div class="radio-group-container">
        <mat-radio-group [formControl]="columnExportDataControl">
          <mat-radio-button
            color="primary"
            *ngFor="let item of columnExportData"
            class="columns-export-type-radio-button"
            [value]="item.value"
          >
            {{ item.text | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="radio-container">
      <div>
        <h3>{{ "EXPORT_TABLE.FORMAT" | translate }}</h3>
      </div>
      <div class="radio-group-container">
        <mat-radio-group [formControl]="columnExportFormatControl">
          <mat-radio-button
            color="primary"
            *ngFor="let item of exportFormats"
            class="columns-export-type-radio-button"
            [value]="item.value"
          >
            {{ item.text | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="radio-container">
      <div>
        <h3>{{ "EXPORT_TABLE.COLUMNS" | translate }}</h3>
      </div>
      <div class="radio-group-container">
        <mat-radio-group [formControl]="columnExportTypeControl">
          <mat-radio-button
            color="primary"
            *ngFor="let item of columnExportTypes"
            class="columns-export-type-radio-button"
            [value]="item.value"
          >
            {{ item.text | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="template-list" *ngIf="templates$ | async as templates">
      <ng-container *ngIf="isLoadingTemplates$ | async; else templateContainer">
        <div
          class="d-flex h-100 w-100 justify-content-center align-items-center"
        >
          <cb-loading [isAbsolute]="false"></cb-loading>
        </div>
      </ng-container>
      <ng-template #templateContainer>
        <mat-selection-list
          *ngIf="templates.length > 0; else noTemplates"
          #templatesList
          [multiple]="false"
          [disabled]="!(showTemplates$ | async)"
          (selectionChange)="templateChange($event)"
          [@removeItem]="templates.length"
        >
          <mat-list-option
            *ngFor="let template of templates"
            [value]="template.id"
            [selected]="(selectedTemplate$ | async)?.id === template.id"
          >
            <app-template-list-item
              [template]="template"
              (onDeleteEvent)="onDeleteTemplate($event)"
            ></app-template-list-item>
          </mat-list-option>
        </mat-selection-list>
      </ng-template>
      <ng-template #noTemplates>
        <div class="no-templates">
          <div class="icon">
            <i class="fa-solid fa-file"></i>
          </div>
          <div class="text">
            <span>{{ "MANAGE_COLUMNS.NO_TEMPLATES" | translate }}</span>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="template-actions">
      <button
        color="info"
        mat-flat-button
        (click)="saveConfigAs()"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="saveAsMenu"
      >
        <i class="fa-solid fa-floppy-disk-pen"></i>
        {{ "EXPORT_TABLE.SAVE_CONFIG_AS" | translate }}
      </button>
      <mat-menu
        #saveAsMenu="matMenu"
        overlapTrigger="false"
        class="save-as-menu"
        autoFocus="true"
        hasBackdrop="true"
        backdropClass="save-as-menu-backdrop"
      >
        <div (click)="stopEvent($event)">
          <ng-template formAnchor></ng-template>
        </div>
      </mat-menu>
      <button
        color="info"
        mat-flat-button
        (click)="saveConfig()"
        [disabled]="(selectedTemplate$ | async) === null"
      >
        <i class="fa-solid fa-floppy-disk"></i>
        {{ "EXPORT_TABLE.SAVE_CONFIG" | translate }}
      </button>
    </div>
  </div>
  <div
    *ngIf="(showColumnSelection$ | async) === true; else noColumnSelection"
    class="columns-section"
    cdkDropListGroup
    [cdkDropListGroupDisabled]="dndReadonly$ | async"
  >
    <div class="container">
      <div class="search-container">
        <div class="search">
          <div class="icon">
            <i class="fa fa-search"></i>
          </div>
          <input
            #searchInput
            [formControl]="searchControl"
            class="form-control"
            [placeholder]="'COMMON.SEARCH' | translate"
          />
          <button
            *ngIf="showClearSearchButton$ | async"
            mat-icon-button
            class="clear-icon"
            (click)="clearSearch()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>

      <div
        *ngIf="remainingColumns$ | async as remainingColumns"
        cdkDropList
        id="remainingColumns"
        [cdkDropListData]="remainingColumns"
        class="list"
        cdkDropListSortingDisabled
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="box"
          *ngFor="let item of filteredRemainingColumns$ | async"
          cdkDrag
          [cdkDragData]="item"
        >
          <div class="text">
            <i cdkDragHandle class="fa-solid fa-grip-vertical"></i>
            {{ item["translatedColumnName"] | translate }}
          </div>
          <div class="action">
            <button mat-icon-button class="cb-icon-button" (click)="add(item)">
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container"
      *ngIf="{
        displayedColumns: displayedColumns$ | async,
        readonly: dndReadonly$ | async
      } as columnData"
    >
      <!-- *ngIf="displayedColumns$ | async as displayedColumns" -->
      <div class="title">
        <div class="text">
          <span>{{ "MANAGE_COLUMNS.SECOND_COLUMN_TITLE" | translate }}</span>
          <span class="counter">{{ columnData.displayedColumns.length }}</span>
        </div>
        <div class="caption">
          {{ "MANAGE_COLUMNS.SECOND_COLUMN_CAPTION" | translate }}
        </div>
      </div>

      <div
        cdkDropList
        [cdkDropListData]="columnData.displayedColumns"
        class="list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="box"
          *ngFor="let item of columnData.displayedColumns; let index = index"
          cdkDrag
          [cdkDragData]="item"
        >
          <div class="text">
            <i
              cdkDragHandle
              class="fa-solid fa-grip-vertical"
              *ngIf="!columnData.readonly; else emptyIcon"
            ></i>
            <ng-template #emptyIcon>
              <i></i>
            </ng-template>
            {{ item["translatedColumnName"] | translate }}
          </div>
          <div class="action" *ngIf="!columnData.readonly">
            <button mat-icon-button class="cb-icon-button" (click)="remove(item)">
              <i class="fa-sharp fa-solid fa-delete-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noColumnSelection>
  <div class="columns-section">
    <div class="empty-columns w-100">
      <i class="fa-solid fa-file-export fa-10x"></i>
      <p>
        {{ "EXPORT_TABLE.NO_TEMPLATE_SELECTED_MESSAGE" | translate }}
      </p>
    </div>
  </div>
</ng-template>
