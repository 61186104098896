import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import {
  AppliedFilter,
  Filter,
  FilterComponentOutput,
  GenericFilter,
} from "../types/types";
import { filter, map, takeUntil } from "rxjs/operators";
import { formatDate } from "@angular/common";
import { DATE_FORMAT } from "../../crud-shell/utils/utility";
import { TranslateService } from "@ngx-translate/core";
import { SERVER_DATE_FORMAT } from "../static";
import { HttpParams } from "@angular/common/http";
import { DateAdapter } from "@angular/material/core";

@Injectable()
export class FilterService {
  private readonly _filtersConfig = new BehaviorSubject<Filter[]>([]);
  readonly filtersConfig$ = this._filtersConfig.asObservable();

  private readonly _searchableFields = new BehaviorSubject<string[]>([]);
  readonly searchableFields$ = this._searchableFields.asObservable();

  private readonly _hasBeenInitialized = new BehaviorSubject<boolean>(false);
  readonly hasBeenInitialized$ = this._hasBeenInitialized.asObservable();

  private readonly _appliedFilters = new BehaviorSubject([]);
  readonly appliedFilters$ = this._appliedFilters.asObservable();

  destroyed$ = new Subject();
  filterForm = new FormGroup({});
  resetFiltersSignal$ = new Subject();

  filterIndicators$ = this.appliedFilters$.pipe(
    map((appliedFilters) => {
      return appliedFilters.map((appliedFilter) =>
        this.prepareFilterIndicatorBadge(appliedFilter)
      );
    })
  );
  filteredQueryString$ = new BehaviorSubject<string | null>(null);
  componentOutput$ = new BehaviorSubject<FilterComponentOutput | null>(null);
  filteredObject = new BehaviorSubject<any>({});
  // later, we might need to include another service,
  // like for tables, we store use values,
  // in that case, this should consider those values as well
  filterAccordionStatus$ = this.filtersConfig$.pipe(
    takeUntil(this.destroyed$),
    filter((config) => !!config),
    map((config) => {
      return false;
    })
  );

  constructor(
    private translateService: TranslateService,
    private dateAdaptor: DateAdapter<any>
  ) {
    this.dateAdaptor.setLocale(this.translateService.currentLang || "en");
    // component output
    combineLatest([this.filterForm.valueChanges, this.hasBeenInitialized$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([_, hasBeenInitialized]) => hasBeenInitialized)
      )
      .subscribe(([formValue]) => {
        this.componentOutput$.next(this.prepareComponentOutput(formValue));
      });
  }

  private prepareComponentOutput(appliedFilters): FilterComponentOutput | null {
    if (!appliedFilters) return null;
    let params = new HttpParams();

    Object.keys(appliedFilters).map((key) => {
      if (key === "search") {
        if (appliedFilters[key] !== null && appliedFilters[key] !== "") {
          this.searchableFields.map((search) => {
            params = params.set(`search[${search}]`, appliedFilters[key]);
          });
        }
      } else {
        if (key && appliedFilters[key] !== null) {
          params = params.set(`filter[${key}]`, appliedFilters[key]);
        }
      }
    });

    return {
      raw: this.filterForm.getRawValue(),
      httpParams: params,
      queryString: params.toString(),
    };
  }

  private getFieldWidthClass = (el: Filter): string => {
    if (typeof el.width == "string") {
      return el.width;
    }
    if (typeof el.width == "number") {
      return `col-md-${el.width}`;
    }

    return "col-md-12";
  };

  extractForm = (): FormGroup => {
    if (this.filtersConfig.length === 0) return this.filterForm;
    this.filtersConfig.map((filter) => {
      this.filterForm.addControl(filter.field, new FormControl(filter.value), {
        emitEvent: true,
      });
    });

    this.filterForm.addControl("search", new FormControl(), {
      emitEvent: true,
    });

    // later, when we introduce initial values, we can set them here
    // before we set the hasBeenInitialized flag
    // in the controller, we can check if the form has been initialized
    // and prevent the form from being submitted

    this.hasBeenInitialized = true;
    return this.filterForm;
  };

  resetAllFilters() {
    this.filterForm.reset();
    this._appliedFilters.next([]);
    this.resetFiltersSignal$.next(true);
  }

  private prepareFilterIndicatorBadge(filter: AppliedFilter) {
    // if(filter.component.type === 'search') {

    // }
    if (
      filter.component.type === "daterange" ||
      filter.component.type === "date_range"
    ) {
      if (filter.value.from || filter.value.to) {
        let value = "";
        if (filter.value.from) {
          value += formatDate(
            filter.value.from,
            DATE_FORMAT,
            this.translateService.currentLang
          );
        }
        value = value + " - ";
        if (filter.value.to) {
          value += formatDate(
            filter.value.to,
            DATE_FORMAT,
            this.translateService.currentLang
          );
        }

        return {
          label: filter.component.title,
          value: filter.label,
        };
      }
    }

    return {
      label: filter.component.title,
      value: filter.label,
    };
  }

  updateFiltersConfig(filterConfig, searchableFields: string[]) {
    let updatedFilters = [...filterConfig];
    // we are adding a search filter here
    if (searchableFields.length) {
      updatedFilters = [
        {
          type: "search",
          field: "search",
          title: "Search",
          width: 3,
        },
        ...filterConfig,
      ];
    }
    let filters = updatedFilters.map((f: Filter) => {
      return {
        ...f,
        widthClass: this.getFieldWidthClass(f),
      } as Filter;
    });
    this.filtersConfig = filters;
    this.searchableFields = searchableFields;
  }

  formatDateRangeForServer(start, end) {
    let value = "";
    if (!start && !end) {
      return null;
    }

    if (start) {
      value = formatDate(
        start,
        SERVER_DATE_FORMAT,
        this.translateService.currentLang
      );
    }
    value += ",";
    if (end) {
      value += formatDate(
        end,
        SERVER_DATE_FORMAT,
        this.translateService.currentLang
      );
    }

    return value;
  }

  setAppliedFilters(appliedFilter: AppliedFilter) {
    const filters = this._appliedFilters.value.filter(
      (f) => f.component.field != appliedFilter.component.field
    );

    if (
      appliedFilter.component.type === "daterange" ||
      appliedFilter.component.type === "date_range"
    ) {
      if (!!appliedFilter.value.from || !!appliedFilter.value.to) {
        filters.push(appliedFilter);
      }
    } else if (appliedFilter.value) {
      filters.push(appliedFilter);
    }

    this._appliedFilters.next(filters);
  }

  get searchableFields() {
    return this._searchableFields.getValue();
  }

  get filtersConfig() {
    return this._filtersConfig.getValue();
  }

  get hasBeenInitialized() {
    return this._hasBeenInitialized.getValue();
  }

  private set hasBeenInitialized(hasBeenInitialized) {
    this._hasBeenInitialized.next(hasBeenInitialized);
  }

  private set filtersConfig(filterConfig) {
    this._filtersConfig.next(filterConfig);
  }

  private set searchableFields(searchableFields: string[]) {
    this._searchableFields.next(searchableFields);
  }
}
