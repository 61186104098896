import { createAction, props } from "@ngrx/store";
import {
  EmailTemplate,
  EmailTemplateDetails,
  EmailTheme,
} from "./send-email.model";

export const nGetTemplateTypes = createAction(
  "[sendEmail API] Get TemplateTypes If Not Exists"
);
export const getTemplateTypes = createAction(
  "[sendEmail API] Get TemplateTypes"
);
export const getTemplateTypesSuccess = createAction(
  "[sendEmail API] Get TemplateTypes Success",
  props<{ templateTypes: EmailTemplate[] }>()
);
export const getTemplateTypesFailure = createAction(
  "[sendEmail API] Get TemplateTypes Failure",
  props<{ error: any }>()
);
export const nGetTemplates = createAction(
  "[sendEmail API] Get Templates If Not Exists"
);
export const getTemplates = createAction("[sendEmail API] Get Templates");
export const getTemplatesSuccess = createAction(
  "[sendEmail API] Get Templates Success",
  props<{ templates: EmailTemplate[] }>()
);
export const getTemplatesFailure = createAction(
  "[sendEmail API] Get Templates Failure",
  props<{ error: any }>()
);

export const nGetThemes = createAction(
  "[sendEmail API] Get Themes If Not Exists"
);
export const getThemes = createAction("[sendEmail API] Get Themes");
export const getThemesSuccess = createAction(
  "[sendEmail API] Get Themes Success",
  props<{ themes: EmailTheme[] }>()
);
export const getThemesFailure = createAction(
  "[sendEmail API] Get Themes Failure",
  props<{ error: any }>()
);

export const getSelectedTemplateDetail = createAction(
  "[sendEmail API] Get Selected Template Detail",
  props<{ id: number }>()
);
export const getSelectedTemplateDetailSuccess = createAction(
  "[sendEmail API] Get Selected Template Detail Success",
  props<{ templateDetails: EmailTemplateDetails }>()
);
export const getSelectedTemplateDetailFailure = createAction(
  "[sendEmail API] Get Selected Template Detail Failure",
  props<{ error: any }>()
);

export const resetSelectedTemplateDetail = createAction(
  "[sendEmail API] Reset Selected Template Detail"
);

// component
export const updateSelectedTemplate = createAction(
  "[sendEmail COMPONENT] Update Selected Template",
  props<{ id: number | void }>()
);
export const updateSelectedTheme = createAction(
  "[sendEmail COMPONENT] Update Selected Theme",
  props<{ id: number | void }>()
);

export const updateTemplateSelectedLanguage = createAction(
  "[sendEmail COMPONENT] Update Selected Template Language",
  props<{ lang: string }>()
);

export const updateSelectedTemplateDetails = createAction(
  "[sendEmail COMPONENT] Update Selected Template Details",
  props<{ templates: any; newLang: string }>()
);

export const initiateSendEmailDialog = createAction(
  "[sendEmail COMPONENT] Initiate",
  props<{ participants: any[]; config: any }>()
);

export const updateParticipants = createAction(
  "[sendEmail COMPONENT] Update Participants",
  props<{ participants: any[] }>()
);

export const sendEmail = createAction("[sendEmail API] Send Email");
export const sendEmailSuccess = createAction(
  "[sendEmail API] Send Email Success",
  props<{ response: any }>()
);

export const sendTestEmail = createAction("[sendEmail API] Send Test Email");
export const sendTestEmailSuccess = createAction(
  "[sendEmail API] Send Test Email Success",
  props<{ response: any }>()
);

export const removeParticipant = createAction(
  "[sendEmail COMPONENT] Remove Participant",
  props<{ participant: any }>()
);

export const saveTemplate = createAction("[sendEmail API] Save Template");

export const saveTemplateSuccess = createAction(
  "[sendEmail API] Save Template Success",
  props<{ template: EmailTemplate }>()
);
export const saveTemplateFailure = createAction(
  "[sendEmail API] Save Template Failure",
  props<{ error: any }>()
);
export const saveTemplateAs = createAction(
  "[sendEmail API] Save Template As",
  props<{
    templateName: string;
    templateTypeId: number;
    templateDescription: string;
  }>()
);

export const saveTemplateAsSuccess = createAction(
  "[sendEmail API] Save Template As Success",
  props<{ template: EmailTemplate }>()
);

export const saveTemplateAsFailure = createAction(
  "[sendEmail API] Save Template As Failure",
  props<{ error: any }>()
);

export const toggleSaveAsModal = createAction(
  "[sendEmail Component] Toggle Save As Modal",
  props<{ status: boolean }>()
);

export const addTranslation = createAction(
  "[sendEmail Component] Add Translation",
  props<{ lang: string }>()
);

export const removeTranslation = createAction(
  "[sendEmail Component] Remove Translation",
  props<{ lang: string }>()
);

export const updateTempForm = createAction(
  "[sendEmail Component] Update Temp Form",
  props<{ templates: any }>()
);

export const updateFormErrors = createAction(
  "[sendEmail Component] Update Temp Form ERRORS",
  props<{ errors: string[] }>()
);
export const newTemplateMode = createAction(
  "[sendEmail Component] New Template Mode"
);

export const closeNewTemplateMode = createAction(
  "[sendEmail Component] Close New Template Mode"
);
export const getParticipantsFromServer = createAction(
  "[sendEmail APi] Get Participants From Server"
);
export const getParticipantsFromServerSuccess = createAction(
  "[sendEmail APi] Get Participants From Server Success",
  props<{ participants: any[] }>()
);
export const getParticipantsFromServerFailure = createAction(
  "[sendEmail APi] Get Participants From Server Success",
  props<{ error: string[] }>()
);

export const setFormState = createAction(
  "[sendEmail COMPONENT] Set Form State",
  props<{ state: boolean }>()
);

export const setFormDirty = createAction(
  "[sendEmail COMPONENT] Set Form Dirty"
);

export const resetStore = createAction("[sendEmail COMPONENT] reset store");
