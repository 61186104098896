// Angular
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CustomSelectService } from "./custom-select.service";
// TODO, this is an incomplete component
// the purpose is to replace the `custom-mat-select`
// as that component does not handle api calls itself
// but relies on the parent to do that
// I think the better option is to create a global
// service, which does the preparation (then we can use
// custom-mat-select (or this one).
// but for now, we pass the config and let the component does the rest
// in case of a rewrite, I think its better to use a global service,
// which takes in the select config, the data, and also uses the global
// parser function (which we don't have atm), and simply pass the parsed
// values to this or (custom-mat-select)
// this is currently only used in filters module
// this is mostly assumed to be used in Angular forms
// so the selected values probably should come in the forms, and not directly
// passed to it.
@Component({
  selector: "cb-custom-select",
  templateUrl: "./custom-select.component.html",
  styleUrls: ["./custom-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomSelectService],
})
export class CustomSelectComponent implements OnInit, OnChanges {
  @Input() selectConfig = {};
  @Input() allowMultiple = false;
  @Input() control: FormControl;
  @Input() selectedValue: any;
  @Input() title;
  @Input() name;

  @Output() onSelectionChange = new EventEmitter();

  customSelectService = inject(CustomSelectService);

  allOptions$ = this.customSelectService.allOptions$;
  isLoading$ = this.customSelectService.isLoading$;
  constructor(private translate: TranslateService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectConfig"]) {
      this.customSelectService.initiateSelectConfig(this.selectConfig);
    }
  }
  ngOnInit(): void {
    if (this.control === null) {
      this.control = new FormControl();
      this.control.setValue(this.selectedValue);
    }
    // console.log('init')
    this.customSelectService.initiateSelectName(this.name);
  }

  handleSelectionChange(event) {
    this.onSelectionChange.emit({
      value: event.value,
      label: event.source.triggerValue,
    });
  }
  clear(event) {
    event.preventDefault();
    event.stopPropagation();
    this.control.setValue(null);
    this.onSelectionChange.emit(null);
  }
}
