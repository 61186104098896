import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment_api } from "../../environments/environment.api";

// all endpoints are fire and forget
@Injectable({ providedIn: "root" })
export class AnalyticsService {
  constructor(private httpClient: HttpClient) {}

  logMenuClick(url: string) {
    this.httpClient
      .post(`${environment_api.api_url}/analytics/menu-click`, { url })
      .subscribe();
  }
}
