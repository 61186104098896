<ng-container *ngIf="!!content">
  <div class="kt-form">
    <form [formGroup]="forms">
      <div formGroupName="translations">
        <mat-tab-group
          [selectedIndex]="selectedTab"
          (selectedTabChange)="onTabChange($event)"
          animationDuration="0"
          class="custom-tabs"
          mat-stretch-tabs="false"
          #tabsEl
        >
          <mat-tab *ngFor="let tab of translationTabs" [label]="tab.name">
            <ng-template class="custom-tab" mat-tab-label>
              <span class="kt-nav__link-icon">
                <img src="{{ tab.flag }}" style="width: 16px" class="mr-2" />
              </span>
              <span class="kt-nav__link-text">{{ tab.caption }}</span>

              <button
                type="button"
                mat-icon-button
                aria-label="Remove"
                (click)="removeTranslation(tab.lang)"
                *ngIf="tab.name !== defaultLang"
              >
                <i class="fa fa-times"></i>
              </button>
            </ng-template>
            <ng-template matTabContent>
              <div [formGroupName]="tab.lang">
                <cb-tab-content-builder
                  [tab]="tab"
                  [forms]="forms?.controls['translations']"
                ></cb-tab-content-builder>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab
            label="Add Translation"
            disabled
            *ngIf="moreTranslationButton?.length > 0"
          >
            <ng-template mat-tab-label>
              <button
                mat-button
                [matMenuTriggerFor]="langMenu"
                (click)="$event.stopPropagation()"
              >
                <span class="text-dark">
                  <span class="mr-2"><i class="fa fa-plus"></i></span>
                  Add Translation
                </span>
              </button>
              <mat-menu #langMenu="matMenu">
                <ng-container *ngFor="let language of moreTranslationButton">
                  <button mat-menu-item (click)="addTranslation(language)">
                    <span class="kt-nav__link-icon">
                      <img
                        src="{{ language.flag }}"
                        style="width: 16px"
                        class="mr-2"
                      />
                    </span>
                    <span class="kt-nav__link-text">{{
                      language.caption
                    }}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </form>
  </div>
</ng-container>
