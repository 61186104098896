/*
 *ngFor="let c of oneDimArray | filterBy:['active', true]"
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterBy' })
export class FilterByPipe implements PipeTransform {

	transform(array: any, fieldNameValuePair: any[], truthy: boolean = true): any[] {
		if (!Array.isArray(array)) {
			return;
		}

		const fieldName = fieldNameValuePair[0];
		const fieldValue = fieldNameValuePair[1];

		return array.filter(r => (r[fieldName] == fieldValue) == truthy);
	}
}