import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmActionDialogComponent } from "../../../../views/partials/content";

import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { sendEmailActions, sendEmailSelectors } from "../../store";
import { EmailTheme } from "../../store/send-email.model";
import { TemplateDetailsComponent } from "../template-details/template-details.component";

@Component({
  selector: "app-send-email",
  templateUrl: "./send-email.component.html",
  styleUrls: ["./send-email.component.scss"],
  //   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendEmailComponent implements OnInit, OnDestroy {
  @ViewChild(TemplateDetailsComponent)
  templateDetailComponent: TemplateDetailsComponent;
  participantsCount = 0;
  newTemplateMode$ = this.store.pipe(
    select(sendEmailSelectors.selectIsNewTemplateMode)
  );
  newTemplateMode = false;
  hasTemplateExtraInfo$ = this.store.pipe(
    select(sendEmailSelectors.selectHasTemplateExtraInfo)
  );

  emailTemplates$ = this.store.pipe(select(sendEmailSelectors.selectTemplates));
  emailThemes$ = this.store.pipe(select(sendEmailSelectors.selectThemes));
  emailThemes: EmailTheme[] = [];
  isFormDirty$ = this.store.pipe(select(sendEmailSelectors.selectIsFormDirty));
  isFormDirty = false;
  emailSent$ = this.store.pipe(
    select(sendEmailSelectors.selectEmailSentStatus)
  );
  currentTemplateDetailId$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentTemplateId)
  );
  currentTemplateId: number | void;
  currentThemeId$ = this.store.pipe(
    select(sendEmailSelectors.selectCurrentThemeId)
  );
  currentThemeId: number | void;
  participantsCount$ = this.store.pipe(
    select(sendEmailSelectors.selectParticipantsCount)
  );
  destroyed$ = new Subject<void>();

  isTemplateFormValid$ = new BehaviorSubject<boolean>(false);
  isTemplateFormValid = false;
  selectorsForm: UntypedFormGroup = this.fb.group({
    themeId: [""],
    templateId: [""],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public settings: any,
    private store: Store,
    private _dialogRef: MatDialogRef<SendEmailComponent>,
    private confirmDialog: MatDialog,
    private fb: UntypedFormBuilder
  ) {
    this.store.dispatch(
      sendEmailActions.initiateSendEmailDialog({
        participants: settings.sendEmailConfig.callbacks.hasOwnProperty(
          "get_objects"
        )
          ? null
          : settings.participants,
        config: settings.sendEmailConfig,
      })
    );
  }

  ngOnInit(): void {
    this.newTemplateMode$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val: any) => {
        this.newTemplateMode = val;
      });
    this.isTemplateFormValid$
      .pipe()
      .subscribe((val) => (this.isTemplateFormValid = val));
    //   this.store.dispatch(sendEmailActions.nGetThemes());
    // this.store.dispatch(sendEmailActions.nGetTemplates());
    this.store.dispatch(sendEmailActions.getThemes());
    this.store.dispatch(sendEmailActions.getTemplates());

    this.emailThemes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((themes: any) => {
        this.emailThemes = themes;
        this.currentThemeId = themes.find(
          (t) => t.enum === "DEFAULT_THEME"
        )?.id;
        if (this.currentThemeId) {
          this.emailThemesChangeHandler({ value: this.currentThemeId });
        }
      });

    this.isFormDirty$.pipe(takeUntil(this.destroyed$)).subscribe((val: any) => {
      this.isFormDirty = val;
    });
    this.participantsCount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((count: any) => (this.participantsCount = count));

    this.currentTemplateDetailId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        this.currentTemplateId = value;
        if (value) {
          this.selectorsForm.patchValue(["templateId", [value]]);
        }
      });
    this.currentThemeId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        this.currentThemeId = value;
        if (value) {
          this.selectorsForm.patchValue(["themeId", [value]]);
        }
      });
  }

  newTemplateForm() {
    let dialogRef = this.confirmDialog.open(ConfirmActionDialogComponent, {
      data: {
        title: "SEND_EMAIL.ARE_YOU_SURE",
        message: `SEND_EMAIL.NEW_TEMPLATE_MESSAGE`,
      },
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.store.dispatch(sendEmailActions.newTemplateMode());
      }
    });
  }

  closeNewTemplateForm() {
    this.store.dispatch(sendEmailActions.closeNewTemplateMode());
  }

  closeDialog() {
    if (this.isFormDirty) {
      let dialogRef = this.confirmDialog.open(ConfirmActionDialogComponent, {
        data: {
          title: "SEND_EMAIL.ARE_YOU_SURE",
          message: `SEND_EMAIL.CONFIRM_CLOSE`,
        },
        width: "400px",
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.doClose();
        }
      });
    } else {
      this.doClose();
    }
  }

  doClose() {
    this.store.dispatch(sendEmailActions.resetStore());
    this._dialogRef.close();
  }

  emailTemplatesChangeHandler = (event) => {
    if (event) {
      this.store.dispatch(
        sendEmailActions.updateSelectedTemplate({ id: event.value })
      );
      return;
    }
    this.store.dispatch(sendEmailActions.updateSelectedTemplate({ id: null }));
  };

  emailThemesChangeHandler = (event) => {
    if (event) {
      this.store.dispatch(
        sendEmailActions.updateSelectedTheme({ id: event.value })
      );
      return;
    }
    this.store.dispatch(sendEmailActions.updateSelectedTheme({ id: null }));
  };

  templateFormStatusEventHandler({ status }) {
    this.isTemplateFormValid$.next(status);
  }

  //   sendEmail() {
  //     if (this.isTemplateFormValid) {
  //       let dialogRef = this.confirmDialog.open(ConfirmActionDialogComponent, {
  //         data: {
  //           title: "Are you sure?",
  //           message: `You are sending ${this.participantsCount} new emails.`,
  //         },
  //         width: "400px",
  //       });

  //       dialogRef.afterClosed().subscribe((dialogResult) => {
  //         if (dialogResult) {
  //           this.store.dispatch(sendEmailActions.sendEmail());
  //         }
  //       });
  //     } else {
  //       this.templateDetailComponent.markAllAsTouched();
  //     }
  //   }

  saveTemplateAs() {
    if (this.isTemplateFormValid) {
      this.store.dispatch(sendEmailActions.toggleSaveAsModal({ status: true }));
    } else {
      this.templateDetailComponent.markAllAsTouched();
    }
  }

  saveTemplate() {
    if (this.isTemplateFormValid) {
      this.store.dispatch(sendEmailActions.saveTemplate());
    } else {
      this.templateDetailComponent.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
