import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfigurationComponent } from "./components/configuration/configuration.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatDividerModule } from "@angular/material/divider";
import { SharedModule } from "../shared/shared.module";
import { MaterialModule } from "../material/material.module";

@NgModule({
  declarations: [ConfigurationComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    MatDividerModule,
    TranslateModule.forChild(),
    SharedModule
  ],
  exports: [ConfigurationComponent],
})
export class ConfigurationShellModule {}
