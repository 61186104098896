// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../../core/store/reducers/app.reducers';
import { ApiConfig } from "../../../../../core/config/api.config";
import { Validators } from "@angular/forms";
// Auth
import { AuthService } from '../../../../../core/store';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Inject } from '@angular/core';

@Component({
	selector: 'trackday-bookings-info-dialog',
	templateUrl: './trackday-bookings-info-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackdayBookingsInfoDialogComponent {

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param router: Router
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
		console.log('TrackdayBookingsInfoDialogComponent', data); 
	}

}
