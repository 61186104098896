import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatMenu } from "@angular/material/menu";
import { ProcessButton } from "../../../../shared/data/models";
import { ContextMenuService } from "../../service/context.menu.service";
@Component({
  selector: "app-context-menu-item",
  templateUrl: "./context-menu-item.component.html",
  styleUrls: ["./context-menu-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuItemComponent implements OnInit {
  @Input() buttons: ProcessButton[];
  @ViewChild("menu", { static: true }) menu: MatMenu;
  constructor(private contextMenuService: ContextMenuService) {}

  ngOnInit(): void {}

  clickHandler(event: ProcessButton) {
    this.contextMenuService.buttonClickEvent$.next(event);
  }

  buttonTrackByFn(index: number, item: ProcessButton) {
    return index;
  }
}
