import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ITab } from "../../../../../shared/data/models";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import { FormConfigService } from "../../../form.config.service";
import { distinctUntilChanged, filter, takeUntil } from "rxjs/operators";
import { promises } from "dns";

@Component({
  selector: "cb-tab-content-builder",
  templateUrl: "./tab-content-builder.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentBuilderComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() tab: ITab = null;
  @Input() forms: UntypedFormGroup = null;
  @Input() tabErrors: string[] = [];
  @Input() selectedTab: number = 0;

  forceRefresh$ = new BehaviorSubject(true);
  destroyed$ = new Subject();
  componentHasRenderedOnce = false;

  constructor(private formConfigService: FormConfigService) {}
  ngAfterViewInit(): void {
    this.formConfigService.forceRefreshTab$
      .pipe(
        takeUntil(this.destroyed$),
        filter((tab) => tab === this.tab.name),
        filter((tab) => this.componentHasRenderedOnce)
      )
      .subscribe((tab) => {
        this.forceRefresh$.next(false);
        setTimeout(() => {
          this.forceRefresh$.next(true);
        });
      });
  }
  ngOnChanges({ selectedTab }: SimpleChanges): void {
    if (selectedTab) {
      this.componentHasRenderedOnce = !selectedTab.firstChange;
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit() {}
}
