// NGRX
import { routerReducer } from "@ngrx/router-store";
import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { storeFreeze } from "ngrx-store-freeze";

import { environment } from "../../../../environments/environment";
import { reloadControllerReducer } from "./reload-controller.reducer";
import { helpSidebarReducer } from "./help-sidebar.reducer";
import { appGlobalConfigReducer } from "./app.global.config.reducer";

// tslint:disable-next-line:no-empty-interface
export interface AppState {}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  reloadController: reloadControllerReducer,
  helpSidebar: helpSidebarReducer,
  appGlobalConfig: appGlobalConfigReducer,
};

export const metaReducers: Array<MetaReducer<AppState>> =
  !environment.production ? [storeFreeze] : [];
