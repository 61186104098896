// Angular
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
// NGRX
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../../partials/partials.module";
// Services
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from "../../../core/base";
// Shared
// Components
import { CrudFormModule } from "../../partials/content/crud-form/crud-form.module";
import { CrudTableModule } from "../../partials/content/crud-table/crud-table.module";
import { PresetCancellationPricesFormComponent } from "./preset-cancellation-prices/preset-cancellation-prices-form/preset-cancellation-prices-form.component";
import { PresetCancellationPricesTableComponent } from "./preset-cancellation-prices/preset-cancellation-prices-table/preset-cancellation-prices-table.component";

import { UserEffects, usersReducer } from "../../../core/store";
import { CbFormsModule } from "../../../modules/cb-forms/cb-forms.module";
import { MaterialModule } from "../../../modules/material/material.module";

const routes: Routes = [
  {
    path: "preset-cancellation-prices",
    component: PresetCancellationPricesTableComponent,
  },
  {
    path: "preset-cancellation-prices/edit/:id",
    component: PresetCancellationPricesFormComponent,
  },
  {
    path: "preset-cancellation-prices/edit/:id/:tab",
    component: PresetCancellationPricesFormComponent,
  },
  {
    path: "preset-cancellation-prices/create",
    component: PresetCancellationPricesFormComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature("users", usersReducer),
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CrudTableModule,
    CrudFormModule,
    CbFormsModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [
    PresetCancellationPricesTableComponent,
    PresetCancellationPricesFormComponent,
  ],
})
export class PresetCancellationPricesManagementModule {}
