import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

import { ApiConfig } from "../../../../core/config/api.config";

@Component({
  selector: "virtualgarage-form",
  templateUrl: "./virtualgarage-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualGarageFormComponent implements OnInit {
  apiUrl: string;
  constructor() {}

  ngOnInit() {
    this.apiUrl = ApiConfig.VirtualGarageURL();
  }
}
