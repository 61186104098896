<div
  class="category-selector__container"
  [perfectScrollbar]="{ wheelPropagation: false }"
>
  <div
    *ngFor="let item of categories"
    (click)="changeCategory(item)"
    [ngClass]="{
      active: item.id === selectedCategory
    }"
    class="category-selector__item"
  >
    <div class="icon fa" [ngClass]="item.iconClass"></div>
    <span>{{ item.translation | translate }} {{getCountOfCategory(item.id)}}</span>
  </div>
</div>
<div class="kt-quick-search__result-scroll-container">
  <div class="kt-quick-search__result">
    <div
      *ngFor="
        let item of getDataBasedOnSelectedCategory();
        whenEmpty: emptyResultTemplate
      "
    >
      <ng-container
        *ngIf="item.type === 0"
        [ngTemplateOutlet]="separatorTemplate"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-container>
      <ng-container
        *ngIf="item.type !== 0"
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #itemTemplate let-item="item">
  <div class="kt-quick-search__item">
    <div
      class=""
      [ngClass]="{
        'kt-quick-search__item-icon': item.icon
      }"
    >
      <div class="icon fa" [ngClass]="item.icon"></div>
    </div>
    <div class="kt-quick-search__item-wrapper">
      <a [routerLink]="item.url" class="kt-quick-search__item-title">
        {{ item.title }}
      </a>
      <div
        class="kt-quick-search__item-desc"
        [innerHtml]="item.text | safe"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #separatorTemplate let-item="item">
  <div class="kt-quick-search__category">{{ item.text }}</div>
</ng-template>

<ng-template #emptyResultTemplate>
  <div class="kt-quick-search__message">
    {{ noRecordText || "No record found" }}
  </div>
</ng-template>
