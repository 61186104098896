// Angular
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
// Layout
import { LayoutConfigService, ToggleOptions } from '../../../core/base';
import { HtmlClassService } from '../html-class.service';
import { AuthService } from '../../../core/store/';
import { UserConfig } from '../../../core/store/models/user-config.model';
import { Router } from '@angular/router';

@Component({
	selector: 'kt-testdata-switch',
	templateUrl: './testdata-switch.component.html',
})
export class TestdataSwitchComponent implements OnInit, AfterViewInit {
	// Public properties

	checkedTestData: boolean;
	showSwitchTestData: boolean;
	disabled: boolean;
	color: string;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param htmlClassService: HtmlClassService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		public htmlClassService: HtmlClassService,
		private router: Router,
		private authService: AuthService,
		private cdr: ChangeDetectorRef,) {

		this.showSwitchTestData = localStorage.getItem('is_test_data_show_switch') == '1';
	}

	 
	ngOnInit(): void {

	}

	/**
	 * On after view init
	 */
	ngAfterViewInit(): void {

		// we check testdata from user config
		this.authService.getUserConfig().subscribe(config => {
			this.checkedTestData = !!config.testdata_config;
			this.crefDetectChanges();
		});

	}

	switchDataFunction() {

		console.log('is_testdata.before', this.checkedTestData);

		this.checkedTestData = !this.checkedTestData;
		const isTestData = this.checkedTestData ? '1' : '0';
		localStorage.setItem('is_testdata', isTestData);

		console.log('is_testdata.after', localStorage.getItem('is_testdata'));

		// save testdata switched to user config
		let userConfig = new UserConfig();
		userConfig.testdata_config = isTestData == '1';
		this.authService.saveUserConfig(userConfig).subscribe(result => {
			// redirect to dashboard
			let currentUrl = this.router.url;
			this.router.navigateByUrl('/profile/info', { skipLocationChange: true }).then(() =>
				this.router.navigate(['/']));
		});

		return;
		//this.eventEmitterService.onSwitchTheData();
	}

	getTestDataSwitchLabel() {
		return this.checkedTestData ? 'Test Data' : 'Real Data';
	}

	getTestDataSwitchTooltip() {
		return this.checkedTestData ? 'Toggle Real Data' : 'Toggle Test Data';
	}

	crefDetectChanges() {
		if (this.cdr && !(this.cdr as ViewRef).destroyed) {
			this.cdr.detectChanges();
		}
	}
}
