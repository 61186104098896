<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
    <crud-table
            title="Trackdays"
            name="trackday"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/trackdays-management/events"
    ></crud-table>
</div>
