import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  NgxDropzoneImagePreviewComponent,
  NgxDropzonePreviewComponent,
} from "ngx-dropzone";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ApiService } from "./../../../../../services";
import { ApiConfig } from "./../../../../../core/config/api.config";
import { catchError, map, finalize } from "rxjs/operators";
import { HttpEventType } from "@angular/common/http";
import { BehaviorSubject, of, Subscription, throwError } from "rxjs";
import { EventEmitterService } from "./../../../../../../app/event-emitter.service";
import { TranslationService } from "../../../../../core/base";

@Component({
  selector: "custom-dropzone-preview",
  templateUrl: "./custom-dropzone-preview.component.html",
  styleUrls: ["./custom-dropzone-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NgxDropzonePreviewComponent,
      useExisting: CustomDropzonePreviewComponent,
    },
  ],
})
export class CustomDropzonePreviewComponent
  extends NgxDropzonePreviewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  constructor(
    public sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    private eventEmitterService: EventEmitterService,
    private translationService: TranslationService
  ) {
    super(sanitizer);
  }

  @Input() groupType = "images";
  @Input() id: number | string; // media Id
  @Input() imageSrc: string | ArrayBuffer = "";
  @Input() fullImageUrl: string;
  @Input() description: string;

  // image types
  @Input() type: string = null;
  @Input() types: string[] = ["default"];

  // languages
  @Input() language: string = null;
  availableLanguages = this.translationService.getAvailableLanguages();

  @Input() model: string;
  @Input() modelId: number | string;

  @Input() readonly = false;

  componentId = KTUtil.getUniqueID("custom-dropdown");
  showEdit: boolean;
  oldDescription: string;
  oldType: string;
  saveInProgress: boolean;
  deleteInProgress: boolean;

  uploadInProgress: boolean;
  uploadSubscription: Subscription;

  uploadProgress: number;
  @Output() uploadDone: EventEmitter<any> = new EventEmitter<any>();

  @Output() onInit: EventEmitter<CustomDropzonePreviewComponent> =
    new EventEmitter<CustomDropzonePreviewComponent>();
  @Output() onDestroy: EventEmitter<CustomDropzonePreviewComponent> =
    new EventEmitter<CustomDropzonePreviewComponent>();

  errorMessage: string;

  @HostListener("keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    event.preventDefault();
  }

  ngAfterViewInit() {
    this.onInit.emit(this);
  }

  shouldReplaceImageWithIcon$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    if (this.id) return;

    if (this.groupType == "images") this.type = this.types[0];
    else if (this.groupType == "documents") this.type = this.types[0];

    if (!this.file) {
      console.error(
        "No file to read. Please provide a file using the [file] Input property."
      );
      return;
    }
    this.readFile()
      .then((file: string) => {
        const _this = this;
        if (this.groupType == "images") {
          var image = new Image();
          image.src = file;

          image.onload = function () {
            _this.imageSrc = image.src;
            _this.cdRef.detectChanges();
          };
        } else {
          _this.imageSrc = file;
          _this.cdRef.detectChanges();
        }
      })
      .catch((err) => console.error(err));
  }

  ngOnChanges({ types }: SimpleChanges) {
    if (types) {
      this.types = types.currentValue || ["default"];
    }
  }

  ngOnDestroy() {
    this.onDestroy.emit(this);
  }

  upload(event?: any) {
    event && event.stopPropagation();
    this.uploadProgress = 0;
    this.uploadInProgress = true;
    this.errorMessage = null;

    this.uploadSubscription = this.apiService
      .post(
        ApiConfig.MediaLibraryUrl(),
        {
          group_type: this.groupType,
          model: this.model,
          model_id: this.modelId,
          image: this.imageSrc,
          type: this.type,
          description: this.description,
          language: this.language ?? "",
        },
        {
          reportProgress: true,
          observe: "events",
        }
      )
      .pipe(
        map((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            if (percentDone % 5 == 0 || percentDone >= 100) {
              return {
                status: "progress",
                progress: percentDone,
              };
            }
          }
          if (event.type === HttpEventType.Response) {
            return event.body;
          }
        }),
        catchError((error) => throwError(error))
      )
      .subscribe(
        (result: any) => {
          console.log("result", result);
          if (!result) return;
          if (result.status && result.status == "progress") {
            this.uploadProgress = parseInt(result.progress);
            this.cdRef.detectChanges();
          } else {
            this.uploadInProgress = false;
            this.id = result.id;
            this.imageSrc = result.thumb_url;
            this.shouldReplaceImageWithIcon$.next(false);
            this.fullImageUrl = result.url;
            this.type = result.type;
            this.description = result.description;
            this.cdRef.detectChanges();

            this.eventEmitterService.invokeMedialLibrary.emit({
              action: "upload",
              type: this.type,
              url: this.imageSrc,
              description: this.description,
              model: this.model,
              model_id: this.modelId,
            });

            this.uploadDone.emit({
              status: "success",
              response: result,
            });
            this.uploadDone.complete();
          }
        },
        (err) => {
          console.log("err", err);
          this.errorMessage = err.error;
          this.cdRef.detectChanges();

          this.uploadDone.emit({
            status: "error",
            response: err,
          });
          this.uploadDone.complete();
        },
        () => {}
      );
  }

  cancelUpload(event) {
    event.stopPropagation();
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }

  retryUpload(event) {
    event.stopPropagation();
    this.errorMessage = null;
    this.uploadInProgress = false;
  }

  edit(event) {
    event.stopPropagation();
    this.showEdit = true;
    this.oldType = this.type;
    this.oldDescription = this.description;
  }

  save(event) {
    event.stopPropagation();
    this.saveInProgress = true;
    this.apiService
      .put(ApiConfig.MediaLibraryUrl(), {
        id: this.id,
        group_type: this.groupType,
        model: this.model,
        model_id: this.modelId,
        type: this.type,
        description: this.description,
        language: this.language,
      })
      .subscribe(
        () => {},
        (err) => {},
        () => {
          this.showEdit = false;
          this.saveInProgress = false;
          this.cdRef.detectChanges();
        }
      );
  }

  retrySave(event) {
    event.stopPropagation();
    this.errorMessage = null;
    this.saveInProgress = false;
    this.cdRef.detectChanges();
  }

  cancelEdit(event) {
    event.stopPropagation();
    this.showEdit = false;
    this.type = this.oldType;
    this.description = this.oldDescription;
  }

  delete(event) {
    event.stopPropagation();
    if (this.id) {
      this.deleteInProgress = true;
      this.cdRef.detectChanges();

      const url =
        ApiConfig.MediaLibraryUrl() +
        `?model=${this.model}&model_id=${this.modelId}&group_type=${this.groupType}`;

      this.apiService.delete(url, this.id).subscribe(
        () => {
          this._remove(event);
          this.eventEmitterService.invokeMedialLibrary.emit({
            action: "delete",
            type: this.type,
            url: this.imageSrc,
            description: this.description,
            model: this.model,
            model_id: this.modelId,
            group_type: this.groupType,
          });
        },
        (err) => {},
        () => {
          this.deleteInProgress = false;
          this.cdRef.detectChanges();
        }
      );
    } else {
      this._remove(event);
    }
  }

  replaceImageWithPdfLogo(event) {
    this.shouldReplaceImageWithIcon$.next(true);
  }

  // viewImage() {
  //     document.querySelector('body').appendChild(elementRef.nativeElement);
  // }
}
