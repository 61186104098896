<!--begin::Form-->
<form [formGroup]="addressForm" class="kt-form kt-form--group">

    <kt-alert *ngIf="!subForm && hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000"
              (close)="onAlertClose($event)">
        Oh snap! Change a few things up and try submitting again.
    </kt-alert>
    <div class="kt-form__section kt-form__section--first">
        <div class="form-group kt-form__group row">
			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="Address Line" [formControlName]="getformControlName('street')" [required]="required" />
					<mat-error>Street is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>Street</strong>
					</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="Number" [formControlName]="getformControlName('number')" [required]="required" />
					<mat-error>Number is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>Number</strong>
					</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="City" [formControlName]="getformControlName('city')" [required]="required" />
					<mat-error>City is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>City</strong>
					</mat-hint>
				</mat-form-field>
			</div>
		</div>
		<!--<div class="form-group kt-form__group row">-->
			<!--<div class="col-lg-4 kt-margin-bottom-20-mobile">-->
				<!--<mat-form-field class="mat-form-field-fluid">-->
					<!--<input matInput placeholder="Enter State Line" [formControlName]="getformControlName('state')" />-->
					<!--<mat-error>State is-->
						<!--<strong>required</strong>-->
					<!--</mat-error>-->
					<!--<mat-hint align="start">Please enter-->
						<!--<strong>State</strong>-->
					<!--</mat-hint>-->
				<!--</mat-form-field>-->
			<!--</div>-->
		<!--</div>-->
		<div class="form-group kt-form__group row">
			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="Zipcode" [required]="required" [formControlName]="getformControlName('zip')" />
					<mat-error>Zipcode is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>Zipcode</strong>
					</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<mat-select placeholder="Country" [required]="required" [formControlName]="getformControlName('country_id')">
                        <mat-option *ngFor="let country of countries" value="{{ country.id }}">{{country.name}}</mat-option>
					</mat-select>
					<mat-error>Country is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>Country</strong>
					</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="Web" [formControlName]="getformControlName('web')" />
					<mat-error>Website is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>Website</strong>
					</mat-hint>
				</mat-form-field>
			</div>
		</div>
		<div class="form-group kt-form__group row">
			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="Lang" [formControlName]="getformControlName('lang')" />
					<mat-error>Lang is
						<strong>required</strong>
					</mat-error>
					<mat-hint align="start">Please enter
						<strong>Lang</strong>
					</mat-hint>
				</mat-form-field>
			</div>
			<!--<div class="col-lg-4 kt-margin-bottom-20-mobile">-->
			<!--<mat-form-field class="mat-form-field-fluid">-->
			<!--<input matInput placeholder="Enter Company Name" [formControlName]="getformControlName('companyName')"/>-->
			<!--<mat-hint align="start">Please enter-->
			<!--<strong>Company Name</strong>-->
			<!--</mat-hint>-->
			<!--</mat-form-field>-->
			<!--</div>-->

			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="Phone" [formControlName]="getformControlName('phone')"/>
					<mat-hint align="start">Please enter
						<strong>Phone</strong>
					</mat-hint>
				</mat-form-field>
			</div>

			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="Fax" [formControlName]="getformControlName('fax')"/>
					<mat-hint align="start">Please enter
						<strong>Fax</strong>
					</mat-hint>
				</mat-form-field>
			</div>
		</div>
		<div class="form-group kt-form__group row">
			<div class="col-lg-4 kt-margin-bottom-20-mobile custom-field-holder">
				<mat-form-field class="mat-form-field-fluid custom-input">
					<input matInput placeholder="Mobile" [formControlName]="getformControlName('mobile')"/>
					<mat-hint align="start">Please enter
						<strong>Mobile</strong>
					</mat-hint>
				</mat-form-field>
			</div>
        </div>
    </div>
</form>
<!--end::Form-->

