<ng-container *ngIf="!dialogData; else dialog">
  <div>
    <crud-table
      title="{{ 'MENU.PRESET_DYNAMIC_PRICES' | translate }}"
      name="preset-dynamic-prices"
      [columns]="columns"
      [createButtonLabel]="createButtonLabel"
      [searchableColumns]="searchableColumns"
      [apiUrl]="apiUrl"
      baseUrl="/preset-dynamic-price-management/preset-dynamic-prices"
    ></crud-table>
  </div>
</ng-container>

<ng-template #dialog>
  <div class="kt-portlet__body">
    <crud-table
      title="{{ 'MENU.PRESET_DYNAMIC_PRICES' | translate }}"
      name="preset-dynamic-prices"
      [columns]="columns"
      [createButtonLabel]="createButtonLabel"
      [searchableColumns]="searchableColumns"
      [apiUrl]="apiUrl"
      baseUrl="/preset-dynamic-price-management/preset-dynamic-prices"
      [hideFormFilters]="true"
      [readonly]="true"
      [alwaysShowCheckboxes]="alwaysShowCheckboxes"
      (onSelectionChange)="selectionChange($event)"
    ></crud-table>
    <!-- [hideHeader]="true" -->
  </div>
</ng-template>

<!-- <ng-template #dialog>
  <div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
      <div class="kt-portlet__head-progress"></div>
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title">Preset Dynamic Prices</h3>
      </div>
    </div>
    <div class="kt-form">
      <div class="kt-portlet__body">
        <crud-table
          title="{{ 'MENU.PRESET_DYNAMIC_PRICES' | translate }}"
          name="preset-dynamic-prices"
          [columns]="columns"
          [createButtonLabel]="createButtonLabel"
          [searchableColumns]="searchableColumns"
          [apiUrl]="apiUrl"
          baseUrl="/preset-dynamic-price-management/preset-dynamic-prices"
          [hideFormFilters]="true"
          [readonly]="true"
          [alwaysShowCheckboxes]="alwaysShowCheckboxes"
          (onSelectionChange)="selection = $event"
        ></crud-table>        
      </div>
      <div
        class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right"
      >
        <div class="kt-form__actions kt-form__actions--sm">
          <button
            type="button"
            matToolTip="Cancel action"
            class="btn btn-outline-primary reset-button-styles danger-outline-button pull-left"
            mat-raised-button
            (click)="dialogRef.close()"
          >
            <i class="la la-close"></i>
            {{ "COMMON.CANCEL" | translate }}</button
          >&nbsp;
          <button
            class="btn btn-outline-primary reset-button-styles info-outline-button"
            mat-raised-button
            (click)="close('ADD')"
          >
            <i class="la la-plus"></i>
            {{ "DYNAMIC_PRICES.USE_PRESET.BTN_ADD_LABEL" | translate }}</button
          >&nbsp;
          <button
            class="btn btn-outline-primary reset-button-styles success-outline-button"
            mat-raised-button
            (click)="close('REPLACE')"
            cdkFocusInitial
          >
            {{ "DYNAMIC_PRICES.USE_PRESET.BTN_REPLACE_LABEL" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->
