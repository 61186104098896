import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";

@Component({
  selector: "preset-dynamic-prices-form",
  templateUrl: "./preset-dynamic-prices-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresetDynamicPricesFormComponent implements OnInit {
  apiUrl: string;

  constructor() {}

  ngOnInit() {
    this.apiUrl = ApiConfig.PresetDynamicPricesURL();
  }
}
