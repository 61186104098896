import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";
import { SubheaderService } from "./../../../../../core/base/services/subheader.service";

@Component({
  selector: "users-form",
  templateUrl: "./users-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersFormComponent implements OnInit {
  apiUrl: string;
  constructor(private subheaderService: SubheaderService) {}

  ngOnInit() {
    this.subheaderService.setTitle("User Management");

    this.apiUrl = ApiConfig.UsersURL();
  }
}
