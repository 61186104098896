// Angular
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from "@angular/core";
// RxJS
// NGRX
// AppState
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppState } from "../../../../core/store/reducers/app.reducers";
import { reloadControllerSelectors } from "../../../../core/store/selectors/reload-controller.selectors";
import { environment_api } from "./../../../../../environments/environment.api";
import { CrudTableService } from "./../../../../services/crud-table.service";
// Auth

@Component({
  selector: "html-content",
  templateUrl: "./html-content.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .loadingContainer {
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
})
export class HtmlContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() apiUrl: string;
  @Input() displayRefreshButton: boolean = true;
  // getting related table config name to reload html content if WS event is received
  @Input() relatedTableConfigName?: string = null;
  store = inject(Store<AppState>);
  loading$ = new BehaviorSubject(false);
  innerHtml: string;

  @Output() onBeforeLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAfterLoad: EventEmitter<any> = new EventEmitter<any>();
  destroyed$ = new Subject<void>();
  reloadController$ = this.store.pipe(
    takeUntil(this.destroyed$),
    select(reloadControllerSelectors.selectTargetComponent)
  );
  constructor(
    private crudTableService: CrudTableService,
    private translate: TranslateService
  ) {}
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.reloadController$.subscribe((target) => {
      if (target) {
        this.loadHtml();
      }
      //   if (target === this.relatedTableConfigName) {
      //     this.loadHtml();
      //     // this.store.dispatch(reloadControllerActions.resetTargetComponent());
      //   }
    });
  }

  ngOnChanges({ apiUrl }: SimpleChanges) {
    if (apiUrl) {
      this.apiUrl = apiUrl.currentValue;
      this.loadHtml();
    }
  }

  loadHtml() {
    if (!this.apiUrl) return;

    this.onBeforeLoad.emit();
    this.loading$.next(true);

    let apiUrl = this.apiUrl.startsWith("http")
      ? this.apiUrl
      : environment_api.api_url + this.apiUrl;

    apiUrl = KTUtil.updateURLParams(apiUrl, {
      lang: this.translate.currentLang,
      isTestdata: localStorage.getItem("is_testdata") || 0,
    });

    this.crudTableService.getHtml(apiUrl).subscribe(
      (response) => {
        this.loading$.next(false);
        this.innerHtml = response;
        this.onAfterLoad.emit(response);
      },
      (err) => {
        this.loading$.next(false);
        this.innerHtml = this.translate.instant("COMMON.UNEXPECTED_ERROR");
      }
    );
  }

  reload() {
    this.loadHtml();
  }
}
