<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
      <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">{{getTitle()}}</h3>
      </div>
  </div>
  <div>
      <div class="kt-form">
          <div class="kt-portlet__body">
            <div [innerHTML]="data"></div>
          </div>
      </div>
  </div>
</div>
