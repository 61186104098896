import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { TableConfigService } from "../../../../../../services/table.config.service";
export type SaveTemplateAsComponentProps = {
  tableName: string;
  displayedColumns: any[];
  remainingColumns: any[];
};

type TEMPLATE_MODE_TYPE = "private" | "public";

@Component({
  selector: "app-save-template-as",
  templateUrl: "./save-template-as.component.html",
  styleUrls: [
    "../../export-column-config.component.scss",
    "./save-template-as.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveTemplateAsComponent implements OnInit {
  templateModes: { value: TEMPLATE_MODE_TYPE; text: string }[] = [
    { value: "private", text: "COMMON.PRIVATE" },
    { value: "public", text: "COMMON.PUBLIC" },
  ];

  displayedColumns$ = new BehaviorSubject<any[]>([]);
  remainingColumns$ = new BehaviorSubject<any[]>([]);

  form: UntypedFormGroup;
  constructor(
    private dialogRef: MatDialogRef<SaveTemplateAsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveTemplateAsComponentProps,
    private tableConfigService: TableConfigService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.displayedColumns$.next(this.data.displayedColumns);
    this.remainingColumns$.next(this.data.remainingColumns);
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      mode: ["private", [Validators.required]],
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.invalid) {
      this.form.get("name").markAsTouched();
      return;
    }

    const payload = {
      name: this.form.get("name").value,
      mode: this.form.get("mode").value,
      columns: [...this.data.displayedColumns, ...this.data.remainingColumns],
      tableName: this.data.tableName,
    };
    this.tableConfigService.saveExportTemplate(payload).subscribe();
  }
}
