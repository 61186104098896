import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ReloadControllerState } from "../reducers/reload-controller.reducer";

export const selectReloadController =
  createFeatureSelector<ReloadControllerState>("reloadController");

export const selectConnectionStatus = createSelector(
  selectReloadController,
  (state) => state.connectionStatus
);

export const selectTargetComponent = createSelector(
  selectReloadController,
  (state) => {
    if (state) {
      return state.target ?? null;
    }

    return null;
  }
);

export const reloadControllerSelectors = {
  selectConnectionStatus,
  selectTargetComponent,
};
