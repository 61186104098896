<button
  *ngIf="shouldConfirm === false; else confirmAction"
  [ngClass]="buttonClass"
  mat-flat-button
  color="warn"
  (click)="click()"
>
  <i [ngClass]="buttonIcon"></i>
  {{ buttonLabel }}
</button>

<ng-template #confirmAction>
  <div class="confirm-container" @fade>
    <button
      class="btn btn-outline-primary reset-button-styles danger-outline-button cb-icon-button"
      mat-icon-button
      (click)="cancel()"
      [matTooltip]="cancelButtonText"
    >
      <i class="fa-solid fa-circle-x"></i>
    </button>
    <button
      class="btn btn-outline-primary reset-button-styles success-outline-button cb-icon-button"
      mat-icon-button
      (click)="confirm()"
      [matTooltip]="confirmButtonText"
    >
      <i class="fa-solid fa-check"></i>
    </button>
    <p>
      {{ confirmMessage }}
    </p>
  </div>
</ng-template>
