import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { RadioGroupConfig } from "../models/radio-group.config.model";

@Component({
  selector: "cb-radio",
  template: `
    <div [formGroup]="form">
      <label>{{ config.title }}</label>
      <mat-radio-group [formControlName]="config.formControlName">
        <div class="radio-items-container" [style]="customStyle">
          <mat-radio-button
            *ngFor="let item of config.options"
            [value]="item[config.optionValue]"
          >
            {{ item[config.optionLabel] }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  `,
  styles: [
    `
      .radio-items-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
      }
    `,
  ],
})
export class CbRadioComponent implements OnInit {
  customStyle =
    "display: grid; grid-template-columns: repeat(1, 1fr); gap: 10px;";
  ngOnInit(): void {
    if (this.config.columns) {
      this.customStyle = `display: grid; grid-template-columns: repeat(${this.config.columns}, 1fr); gap: 10px;`;
    }
  }
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: RadioGroupConfig;
}
