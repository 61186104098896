import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-forms-shell-modal",
  templateUrl: "./forms-shell-modal.component.html",
  styleUrls: ["./forms-shell-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsShellModalComponent implements OnInit {
  id;
  name;
  config;
  apiUrl;
  initialData;
  parentData;
  modalMode;
  constructor(
    public dialogRef: MatDialogRef<FormsShellModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }

  ngOnInit(): void {
    this.id = this.data.id;
    this.name = this.data.name;
    this.config = this.data.config;
    this.apiUrl = this.data.apiUrl;
    this.initialData = this.data.initialData;
    this.parentData = this.data.item;
    this.modalMode = this.data.modalMode;
  }

  onExit() {
    this.dialogRef.close();
  }
}
