import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CUSTOM_VALIDATIONS } from "../../../types/customValidations.type";

@Pipe({
  name: "inputErrors",
})
export class InputErrorsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: any, field): string {
    let res = "";
    if (value !== null) {
      if (value[CUSTOM_VALIDATIONS.REQUIRED] === true) {
        return this.translate.instant("VALIDATION_ERRORS.REQUIRED", {
          title: field?.title,
        });
      }
      if (value[CUSTOM_VALIDATIONS.LESS_EQUAL_THAN] === true) {
        if (field.type === "date") {
          return this.translate.instant("VALIDATION_ERRORS.LESS_EQUAL_DATE", {
            title: value["dependent"],
          });
        }
        return this.translate.instant("VALIDATION_ERRORS.LESS_EQUAL", {
          title: value["dependent"],
        });
      }
      if (value[CUSTOM_VALIDATIONS.LESS_THAN] === true) {
        if (field.type === "date") {
          return this.translate.instant("VALIDATION_ERRORS.LESS_THAN_DATE", {
            title: value["dependent"],
          });
        }
        return this.translate.instant("VALIDATION_ERRORS.LESS_THAN", {
          title: value["dependent"],
        });
      }
      if (value[CUSTOM_VALIDATIONS.GREATER_EQUAL_THAN] === true) {
        if (field.type === "date") {
          return this.translate.instant(
            "VALIDATION_ERRORS.GREATER_EQUAL_DATE",
            {
              title: value["dependent"],
            }
          );
        }
        return this.translate.instant("VALIDATION_ERRORS.GREATER_EQUAL", {
          title: value["dependent"],
        });
      }
      if (value[CUSTOM_VALIDATIONS.GREATER_THAN] === true) {
        if (field.type === "date") {
          return this.translate.instant("VALIDATION_ERRORS.GREATER_THAN", {
            title: value["dependent"],
          });
        }
        return this.translate.instant("VALIDATION_ERRORS.GREATER_THAN", {
          title: value["dependent"],
        });
      }
      if (value[CUSTOM_VALIDATIONS.MIN] === true) {
        return this.translate.instant("VALIDATION_ERRORS.MIN", {
          title: value["dependent"],
        });
      }
      if (value[CUSTOM_VALIDATIONS.MAX] === true) {
        return this.translate.instant("VALIDATION_ERRORS.MAX", {
          title: value["dependent"],
        });
      }
      if ("mask" in value) {
        return this.translate.instant("VALIDATION_ERRORS.MASK", {
          title: value["mask"]["requiredMask"],
        });
      }
      if ("serverError" in value) {
        return value["serverError"];
      }
    }

    return res;
  }
}
