<kt-portlet [attr.data-pgm-porlet]="name">
  <div class="dashboard-table-buttons">
    <ng-content select="[tableHeaderButtons]"></ng-content>
  </div>
  <kt-portlet-body>
    <ng-container *ngIf="htmlHeader$ | async as header">
      <ng-container *ngIf="header.show === true">
        <div
          class="html_header-container"
          [ngStyle]="{
            minWidth: header.width,
            maxWidth: header.width,
            height: header.height
          }"
        >
          <html-content
            [apiUrl]="header.apiUrl"
            [displayRefreshButton]="header.shouldDisplayRefreshButton"
            [relatedTableConfigName]="name"
          >
          </html-content></div
      ></ng-container>
    </ng-container>
    <div class="mainWrapper">
      <ng-container *ngIf="htmlSidebar$ | async as sidebar">
        <ng-container *ngIf="sidebar.show === true">
          <div
            class="html_sidebar-container"
            [ngStyle]="{ minWidth: sidebar.width, maxWidth: sidebar.width }"
          >
            <html-content
              [apiUrl]="sidebar.apiUrl"
              [displayRefreshButton]="sidebar.shouldDisplayRefreshButton"
              [relatedTableConfigName]="name"
            >
            </html-content></div
        ></ng-container>
      </ng-container>
      <div class="mainContent">
        <div *ngIf="!readonly && showInfoText">
          <kt-alert
            *ngFor="let info of infotext"
            type="{{ info.color }}"
            (close)="onAlertClose($event)"
          >
            {{ translations[lang || "en"][info.text] }}
          </kt-alert>
        </div>

        <div class="filters" [ngClass]="{ hideFilters: disableFilters }">
          <mat-accordion>
            <mat-expansion-panel
              [expanded]="filterStatus | async"
              (opened)="toggleFilterAccordionStatus(true)"
              (closed)="toggleFilterAccordionStatus(false)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5 class="mr-2">Filters</h5>
                  <span
                    *ngIf="filteredString.length"
                    class="filtered-indicators"
                    [innerHtml]="filteredString"
                  ></span>
                  <span
                    *ngIf="filteredString.length"
                    class="filtered-indicators"
                  >
                    <button
                      mat-icon-button
                      class="cb-icon-button"
                      (click)="resetLocallySavedFilters($event)"
                    >
                      <i class="fa-regular fa-filter-slash"></i>
                      <!-- <mat-icon class="material-icons"
                        >filter_list_off</mat-icon
                      > -->
                    </button>
                  </span>
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
              </mat-expansion-panel-header>
              <!-- start::FILTERS & GROUP ACTIONS -->
              <div
                class="kt-form m-4"
                [ngClass]="{ 'd-none': hideFormFilters }"
              >
                <!-- start::FILTERS -->
                <div class="kt-form__filtration">
                  <div class="row align-items-center">
                    <div class="col-md-3 col-lg-3 mb-4">
                      <mat-form-field
                        appearance="fill"
                        class="mat-form-field-fluid"
                      >
                        <mat-label>{{ "Search" | translate }}</mat-label>
                        <mat-icon matIconPrefix>search</mat-icon>
                        <input
                          matInput
                          autocomplete="do_not_fill"
                          #searchInput
                          placeholder="Search all fields"
                        />
                        <button
                          mat-icon-button
                          matSuffix
                          *ngIf="searchInput.value"
                          (click)="
                            searchInput.value = null;
                            onFilterChange('search', null)
                          "
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-hint>{{ getSearchableColumnHintText() }}</mat-hint>
                      </mat-form-field>
                    </div>

                    <ng-container *ngFor="let filter of customFilters">
                      <ng-container [ngSwitch]="filter.type">
                        <ng-container *ngSwitchCase="'single_select'">
                          <div
                            class="mb-4"
                            [ngClass]="filter.getFieldWidthClass()"
                          >
                            <custom-mat-select
                              [selection]="filter.value"
                              (selectionChange)="onFilterChange(filter, $event)"
                              (finishedLoading)="onFinishedLoading()"
                              [placeholder]="
                                filter.dynamic_options.loading
                                  ? ('COMMON.LOADING' | translate)
                                  : filter.title
                              "
                              [options]="filter.dynamic_options.data"
                              [optionValue]="filter.dynamic_options.value_field"
                              [optionLabel]="filter.dynamic_options.label_field"
                              [allowClear]="filter.allow_clear"
                              matFormFieldClass="mat-form-field-fluid"
                            >
                            </custom-mat-select>
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'daterange'">
                          <div
                            class="mb-4"
                            [ngClass]="filter.getFieldWidthClass()"
                          >
                            <div class="row">
                              <div class="col-6">
                                <mat-form-field
                                  appearance="fill"
                                  class="mat-form-field-fluid"
                                >
                                  <!-- <mat-label>{{ filter.title }}</mat-label> -->
                                  <input
                                    matInput
                                    [matDatepicker]="fromPicker"
                                    autocomplete="do_not_fill"
                                    placeholder="{{
                                      'COMMON.ANY_DATE' | translate
                                    }}"
                                    [(ngModel)]="filter.value.from"
                                    [required]="
                                      filter.validator.from == 'required'
                                    "
                                    (dateChange)="
                                      onFilterChange(filter, 'daterange')
                                    "
                                  />
                                  <span
                                    matTextPrefix
                                    style="font-size: x-small"
                                  >
                                    {{ filter.title }}
                                    {{ "COMMON.FROM" | translate }}
                                    &nbsp;</span
                                  >
                                  <button
                                    mat-icon-button
                                    matSuffix
                                    *ngIf="filter.value.from"
                                    (click)="
                                      filter.value.from = null;
                                      onFilterChange(filter, 'datarange')
                                    "
                                  >
                                    <mat-icon>close</mat-icon>
                                  </button>
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="fromPicker"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #fromPicker></mat-datepicker>
                                </mat-form-field>
                              </div>
                              <div class="col-6">
                                <mat-form-field
                                  class="mat-form-field-fluid"
                                  appearance="fill"
                                >
                                  <input
                                    matInput
                                    [matDatepicker]="toPicker"
                                    autocomplete="off"
                                    placeholder="{{
                                      'COMMON.ANY_DATE' | translate
                                    }}"
                                    [(ngModel)]="filter.value.to"
                                    [required]="
                                      filter.validator.to == 'required'
                                    "
                                    (dateChange)="
                                      onFilterChange(filter, 'daterange')
                                    "
                                  />
                                  <span
                                    matTextPrefix
                                    style="font-size: x-small"
                                  >
                                    {{ filter.title }}
                                    {{ "COMMON.TO" | translate }}: &nbsp;</span
                                  >

                                  <button
                                    mat-icon-button
                                    matSuffix
                                    *ngIf="filter.value.to"
                                    (click)="
                                      filter.value.to = null;
                                      onFilterChange(filter, 'daterange')
                                    "
                                  >
                                    <mat-icon>close</mat-icon>
                                  </button>

                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="toPicker"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #toPicker></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'date'">
                          <div
                            class="mb-4"
                            [ngClass]="filter.getFieldWidthClass()"
                          >
                            <mat-form-field
                              class="mat-form-field-fluid"
                              floatLabel="never"
                            >
                              <input
                                matInput
                                [matDatepicker]="datePicker"
                                autocomplete="do_not_fill"
                                placeholder="{{
                                  'COMMON.ANY_DATE' | translate
                                }}"
                                [(ngModel)]="filter.value"
                                [required]="filter.validator == 'required'"
                                (dateChange)="onFilterChange(filter, 'date')"
                              />
                              <button
                                mat-icon-button
                                matSuffix
                                *ngIf="filter.value"
                                (click)="
                                  filter.value = null;
                                  onFilterChange(filter, 'date')
                                "
                              >
                                <mat-icon> close</mat-icon>
                              </button>
                              <span matTextPrefix style="font-size: x-small">
                                {{ filter.title }} &nbsp;
                              </span>
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="datePicker"
                              >
                              </mat-datepicker-toggle>
                              <mat-datepicker #datePicker></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'filterInput'">
                          <div
                            class="mb-4"
                            [ngClass]="filter.getFieldWidthClass()"
                          >
                            <h6>
                              {{ "COMMON.FILTER" | translate }}
                              {{ filter.title }}
                            </h6>
                            <mat-form-field
                              class="mat-form-field-fluid"
                              floatLabel="never"
                            >
                              <input
                                matInput
                                [(ngModel)]="filter.value"
                                [type]="filter.type"
                                [min]="filter.min"
                                [max]="filter.max"
                                [step]="filter.step"
                                (input)="onFilterInputChange()"
                              />
                            </mat-form-field>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
                <!-- end::FILTERS -->
              </div>
              <!-- end::FILTERS & GROUP ACTIONS -->
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="table-container">
          <div class="table-header-row">
            <ng-container ktPortletTitle>
              <h3 class="table-title">
                <ng-container *ngIf="!hideTitle">
                  <i class="fa-light fa-calendar-circle-plus"></i>&nbsp;
                  {{ getPageTitle() }}
                </ng-container>
              </h3>
            </ng-container>
            <div class="table-actions-container">
              <div class="cell" *ngIf="displayLayoutSwitcher">
                <app-crud-layout-switcher
                  [defaultValue]="'table'"
                  (change)="handleLayoutChange($event)"
                ></app-crud-layout-switcher>
              </div>
              <ng-container ktPortletTools>
                <div
                  class="process-button-container"
                  [ngClass]="{ show: selection.selected.length > 0 }"
                >
                  <!-- <div *ngIf="!alwaysShowCheckboxes">
                          <label>
                            <span
                              translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"
                            ></span
                            >:&nbsp;
                            {{ selection.selected.length }}
                          </label>
                        </div> -->
                  <div class="cell">
                    <button
                      id="btn-fetch-selected"
                      *ngIf="showBtnFetchItems()"
                      (click)="fetchItems()"
                      mat-raised-button
                      class="btn btn-outline-primary reset-button-styles success-outline-button"
                      [attr.data-pgm-tablebutton]="
                        name + '_tablebutton_btn-fetch-selected_fetch_selected'
                      "
                    >
                      <i class="la la-plus"></i>
                      Fetch Selected</button
                    >&nbsp;
                    <button
                      *ngIf="!alwaysShowCheckboxes"
                      mat-raised-button
                      [matMenuTriggerFor]="menu"
                      class="btn btn-outline-primary reset-button-styles info-outline-button"
                    >
                      ({{ selection.selected.length }}) Please select...
                    </button>
                    <mat-menu #menu="matMenu">
                      <ng-container
                        *ngFor="let processButton of processButtons"
                      >
                        <ng-container
                          *ngIf="
                            processButton.processType == 'mass' ||
                            processButton.processType == 'both'
                          "
                        >
                          <ng-container
                            *ngIf="
                              processButton.hasChildren === false;
                              else withChildren
                            "
                          >
                            <button
                              type="button"
                              (click)="
                                processButtonClickEventHandler(
                                  processButton,
                                  selection.selected
                                )
                              "
                              mat-menu-item
                              matTooltip="{{
                                getConfigTranslate(processButton?.tooltip)
                              }}"
                              [disabled]="
                                processButton.isMassProcessing$ | async
                              "
                              [attr.data-pgm-tablebutton]="
                                name +
                                '_tablebutton_mass-' +
                                processButton.getID()
                              "
                            >
                              <ng-container
                                *ngIf="
                                  processButton.isMassProcessing$ | async;
                                  else btnTitle
                                "
                              >
                                <div class="d-flex">
                                  <cb-loading
                                    [diameter]="20"
                                    [isAbsolute]="false"
                                    class="mt-2"
                                  ></cb-loading>
                                  <span class="ml-2"
                                    >Please wait, while
                                    {{
                                      getConfigTranslate(processButton.title)
                                    }}</span
                                  >
                                </div>
                              </ng-container>
                              <ng-template #btnTitle>
                                {{ getConfigTranslate(processButton.title) }}
                              </ng-template>
                            </button>
                          </ng-container>
                          <ng-template #withChildren>
                            <button
                              type="button"
                              [matMenuTriggerFor]="tableChildMenu"
                              (click)="
                                processButtonClickEventHandler(
                                  processButton,
                                  selection.selected
                                )
                              "
                              mat-menu-item
                              [disabled]="
                                processButton.isMassProcessing$ | async
                              "
                              [attr.data-pgm-tablebutton]="
                                name +
                                '_tablebutton_mass-' +
                                processButton.getID()
                              "
                            >
                              <ng-container
                                *ngIf="
                                  processButton.isMassProcessing$ | async;
                                  else btnTitle
                                "
                              >
                                <div class="d-flex">
                                  <cb-loading
                                    [diameter]="20"
                                    [isAbsolute]="false"
                                    class="mt-2"
                                  ></cb-loading>
                                  <span class="ml-2"
                                    >Please wait, while
                                    {{
                                      getConfigTranslate(processButton.title)
                                    }}</span
                                  >
                                </div>
                              </ng-container>
                              <ng-template #btnTitle>
                                <i
                                  *ngIf="processButton.icon"
                                  class="{{
                                    processButton.getIconClass(
                                      processButton.icon
                                    )
                                  }}"
                                ></i>
                                {{ getConfigTranslate(processButton.title) }}
                              </ng-template>
                            </button>
                            <mat-menu #tableChildMenu="matMenu">
                              <ng-container
                                *ngFor="
                                  let childButton of processButton.children
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    childButton.processType == 'mass' ||
                                    childButton.processType == 'both'
                                  "
                                >
                                  <button
                                    type="button"
                                    (click)="
                                      processButtonClickEventHandler(
                                        childButton,
                                        selection.selected
                                      )
                                    "
                                    mat-menu-item
                                    [disabled]="
                                      childButton.isMassProcessing$ | async
                                    "
                                    [attr.data-pgm-tablebutton]="
                                      name +
                                      '_tablebutton_mass-' +
                                      childButton.getID()
                                    "
                                  >
                                    <ng-container
                                      *ngIf="
                                        childButton.isMassProcessing$ | async;
                                        else btnTitle
                                      "
                                    >
                                      <div class="d-flex">
                                        <cb-loading
                                          [diameter]="20"
                                          [isAbsolute]="false"
                                          class="mt-2"
                                        ></cb-loading>
                                        <span class="ml-2"
                                          >Please wait, while
                                          {{
                                            getConfigTranslate(
                                              childButton.title
                                            )
                                          }}</span
                                        >
                                      </div>
                                    </ng-container>
                                    <ng-template #btnTitle>
                                      <i
                                        *ngIf="childButton.icon"
                                        class="{{
                                          childButton.getIconClass(
                                            childButton.icon
                                          )
                                        }}"
                                      ></i>
                                      {{
                                        getConfigTranslate(childButton.title)
                                      }}
                                    </ng-template>
                                  </button>
                                </ng-container>
                              </ng-container>
                            </mat-menu>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </mat-menu>
                  </div>
                </div>
                <div class="cell">
                  <mat-slide-toggle
                    color="primary"
                    [ngModel]="isInQuickColumnEditorMode$ | async"
                    (ngModelChange)="isInQuickColumnEditorMode$.next($event)"
                  >
                    <i class="fa-solid fa-pen-to-square"></i>
                  </mat-slide-toggle>
                </div>
                <div class="cell">
                  <ng-container *ngFor="let config of configActionLoadBtns">
                    <ng-container *ngIf="!readonly">
                      <button
                        mat-stroked-button
                        type="button"
                        (click)="actionLoadBtnCallback(config)"
                      >
                        {{ config.caption }}
                      </button>
                      &nbsp; &nbsp;
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      !readonly && name === 'cancellation-prices-trackday-items'
                    "
                  >
                    <button
                      class="btn btn-outline-primary reset-button-styles success-outline-button"
                      mat-raised-button
                      type="button"
                      (click)="usePresetCancellationPrices()"
                      [attr.data-pgm-tablebutton]="
                        'tablebutton_use-preset-cancellation-prices'
                      "
                    >
                      <i class="la la-retweet"></i>
                      {{ "CANCELLATION_PRICES.BUTTON.USE_PRESET" | translate }}
                    </button>
                    &nbsp; &nbsp;
                  </ng-container>

                  <ng-container
                    *ngIf="
                      !readonly && name === 'dynamic-prices-trackday-items'
                    "
                  >
                    <button
                      class="btn btn-outline-primary reset-button-styles success-outline-button"
                      mat-raised-button
                      type="button"
                      (click)="usePresetDynamicPrices()"
                      [attr.data-pgm-tablebutton]="
                        'tablebutton_use-preset-dynamic-prices'
                      "
                    >
                      <i class="la la-retweet"></i>
                      {{ "DYNAMIC_PRICES.BUTTON.USE_PRESET" | translate }}
                    </button>
                    &nbsp; &nbsp;
                  </ng-container>

                  <ng-container
                    *ngIf="!readonly && name === 'trackday-booking-vehicles'"
                  >
                    <button
                      class="btn btn-outline-primary reset-button-styles success-outline-button"
                      mat-raised-button
                      type="button"
                      (click)="actionLoadVirtualGarage()"
                      [attr.data-pgm-tablebutton]="
                        'tablebutton_load-virtual-garage'
                      "
                    >
                      <i class="la la-download"></i>
                      {{
                        "TRACKDAYS.BOOKINGS.VEHICLES.BTN_LOAD_VIRTUAL_GARAGE"
                          | translate
                      }}
                    </button>
                    &nbsp; &nbsp;
                  </ng-container>

                  <ng-container
                    *ngIf="
                      !readonly && name == 'location-services-trackday-items'
                    "
                  >
                    <button
                      mat-stroked-button
                      type="button"
                      (click)="actionAddLocationServices()"
                      [attr.data-pgm-tablebutton]="'tablebutton_add-services'"
                    >
                      {{ "Assign Services" | translate }}
                    </button>
                    &nbsp; &nbsp;
                  </ng-container>

                  <ng-container
                    *ngIf="
                      !readonly && name == 'general-services-trackday-items'
                    "
                  >
                    <button
                      mat-stroked-button
                      type="button"
                      (click)="actionAddGeneralServices()"
                      [attr.data-pgm-tablebutton]="'tablebutton_add-services'"
                    >
                      {{ "Assign Services" | translate }}
                    </button>
                    &nbsp; &nbsp;
                  </ng-container>

                  <ng-container *ngIf="name == 'trackday-item-service-types'">
                    <button
                      mat-stroked-button
                      type="button"
                      (click)="actionAddPrivateServices()"
                      [attr.data-pgm-tablebutton]="
                        'tablebutton_add-private-services'
                      "
                    >
                      {{ "Assign Services" | translate }}
                    </button>
                    &nbsp; &nbsp;
                  </ng-container>

                  <button
                    mat-icon-button
                    class="cb-icon-button"
                    [attr.data-pgm-tablebutton]="'tablebutton_display-columns'"
                    (click)="openColumnEditor()"
                  >
                    <i class="fa fa-eye"></i>
                  </button>

                  <!-- DEPRECATED 3/1/2023 -->
                  <!-- <btn-dropdown-select
                  #gridColSelection
                  style="display: none"                  
                  value="name"
                  [title]="'COMMON.VISIBLE_COLUMNS' | translate"
                  label="text"
                  [cache]="name"
                  [options]="columns"
                  (onSelect)="updateDisplayColumns($event)"
                  [translations]="translations"
                  [lang]="lang"
                  [attr.data-pgm-tablebutton]="'tablebutton_display-columns'"
                >
                  <i class="fa fa-eye"></i>
                </btn-dropdown-select> -->
                  &nbsp;
                  <ng-container *ngIf="!readonly && showAddBtn && canAdd()">
                    <button
                      mat-icon-button
                      class="cb-icon-button"
                      (click)="newItem()"
                      [attr.data-pgm-tablebutton]="'tablebutton_add'"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </ng-container>

                  <button
                    mat-icon-button
                    (click)="export()"
                    *ngIf="allowExport"
                    class="cb-icon-button"
                    [attr.data-pgm-tablebutton]="'tablebutton_export'"
                  >
                    <i class="fa fa-file-export"></i>
                  </button>

                  <button
                    mat-icon-button
                    (click)="refresh()"
                    class="cb-icon-button"
                    [disabled]="isLoadingResults"
                    [attr.data-pgm-tablebutton]="'tablebutton_refresh'"
                  >
                    <i class="fa fa-sync-alt"></i>
                  </button>

                  <ng-container *ngIf="infotext">
                    <button
                      type="button"
                      mat-icon-button
                      (click)="showInfo()"
                      class="info-toggle-btn text-secondary cb-icon-button"
                    >
                      <i
                        class="far fa-question-circle text-info info-help-icon"
                      ></i>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="tableMenuButtons?.length > 0">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="tableMenu"
                      class="cb-icon-button"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #tableMenu="matMenu">
                      <ng-container *ngFor="let button of tableMenuButtons">
                        <button
                          (click)="
                            tableMenuButtonClickEventHandler(
                              button,
                              selection.selected
                            )
                          "
                          [matTooltip]="getConfigTranslate(button?.tooltip)"
                          mat-menu-item
                          [disabled]="button.isMassProcessing$ | async"
                        >
                          <span>
                            {{ getConfigTranslate(button?.title) }}
                          </span>
                        </button>
                      </ng-container>
                    </mat-menu>
                  </ng-container>
                </div>
              </ng-container>
              <div class="cell">
                <div class="paginator">
                  <cb-loading
                    *ngIf="dataSource.loading$ | async"
                    [diameter]="20"
                    [isAbsolute]="false"
                  ></cb-loading>
                  <mat-paginator
                    style="display: none"
                    #paginatorElement
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [length]="dataSource.paginatorTotal$ | async"
                    [showFirstLastButtons]="true"
                  ></mat-paginator>
                  <app-cb-paginator
                    (page)="paginationChange($event)"
                    #paginator
                    #paginatorElement
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [length]="dataSource.paginatorTotal$ | async"
                    [showFirstLastButtons]="true"
                    [pageIndex]="lastVisitedTablePage$ | async"
                    [condensed]="true"
                  >
                  </app-cb-paginator>
                </div>
              </div>
            </div>
          </div>
          <div class="mat-table__wrapper" style="overflow-y: auto; z-index: 10">
            <table
              mat-table
              class="crud-table lmat-elevation-z8"
              [ngClass]="{
                'table-container': columnNames.length <= 5,
                'table-container__wide': columnNames.length > 5
              }"
              #table
              [dataSource]="dataSource"
              matSort
              #sort1="matSort"
              matSortActive="id"
              matSortDirection="asc"
              matSortDisableClear
              cdkDropList
              cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="onQuickColumnReorder($event)"
            >
              <ng-container matColumnDef="select" sticky>
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="mat-column-checkbox"
                >
                  <span class="cell-content-wrap">
                    <mat-checkbox
                      class="custom-checkbox"
                      (change)="$event ? masterToggle($event) : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [color]="'primary'"
                    >
                    </mat-checkbox>
                  </span>
                </th>
                <td mat-cell *matCellDef="let row" class="mat-column-checkbox">
                  <span class="cell-content-wrap">
                    <mat-checkbox
                      class="custom-checkbox"
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="isSelected(row)"
                      [color]="'primary'"
                    >
                    </mat-checkbox>
                  </span>
                </td>
              </ng-container>

              <ng-container
                *ngFor="let column of columns; let i = index"
                matColumnDef="{{ column.name }}"
                [sticky]="column.sticky_start"
                [stickyEnd]="!!column.sticky_end"
              >
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  cdkDrag
                  [cdkDragData]="column"
                  [cdkDragPreviewClass]="'table-column-preview'"
                  [cdkDragDisabled]="
                    (isInQuickColumnEditorMode$ | async) === false
                  "
                  [disabled]="
                    column.sortable === false ||
                    (isInQuickColumnEditorMode$ | async) === true
                  "
                  class="table-header-cell"
                  [ngClass]="{
                    'email-cell': column.name === 'email',
                    'phone-cell': column.name === 'phone',
                    'fax-cell': column.name === 'fax',
                    'col-type-text': !column.type,
                    'col-type-boolean': column.type == 'boolean',
                    'col-type-date': column.type == 'date',
                    'col-type-datetime': column.type == 'datetime',
                    inQuickColumnEditorMode:
                      (isInQuickColumnEditorMode$ | async) === true
                  }"
                >
                  <ng-container
                    *ngIf="(isInQuickColumnEditorMode$ | async) === true"
                  >
                    <div class="quick-hide-column-wrapper">
                      <div class="drag-handle" cdkDragHandle>
                        <i class="far fa-solid fa-grip-horizontal"></i>
                      </div>
                      <div
                        class="hide-column-indicator"
                        (click)="quickHideColumn(column.name)"
                      >
                        <i class="far fa-solid fa-xmark"></i>
                      </div>
                      <!-- <button
                        mat-icon-button
                        color="warn"
                        (click)="quickHideColumn(column.name)"
                      ></button> -->
                    </div>
                  </ng-container>

                  <div
                    class="cell-content-wrap"
                    [ngStyle]="getColumnCssStyles(column)"
                  >
                    <ng-container *ngIf="!!column.tooltip">
                      <span
                        class="pr-1"
                        [matTooltip]="getConfigTranslate(column.tooltip)"
                        matTooltipPosition="above"
                      >
                        <i class="far fa-question-circle"></i>
                      </span>
                    </ng-container>
                    {{ getConfigTranslate(column.text) | translate }}
                  </div>
                </th>

                <ng-container
                  *ngIf="!(isLoadingResults || isLoadingTranslations)"
                >
                  <td
                    mat-cell
                    *matCellDef="let item"
                    class="table-content-cell"
                    [ngClass]="{
                      'email-cell': column.name === 'email',
                      'phone-cell': column.name === 'phone',
                      'fax-cell': column.name === 'fax',
                      'col-type-text': !column.type,
                      'col-type-boolean': column.type == 'boolean',
                      'col-type-date': column.type == 'date',
                      'col-type-datetime': column.type == 'datetime'
                    }"
                  >
                    <span
                      class="cell-content-wrap"
                      [ngStyle]="getColumnCssStyles(column)"
                    >
                      <span
                        *ngIf="
                          column.prefix !== undefined &&
                          getData(item, column) !== ''
                        "
                      >
                        {{ getPrefix(item, column.prefix) }}
                      </span>

                      <ng-container *ngIf="column.editable; else columnDisplay">
                        <ng-container
                          *ngIf="
                            column.type === 'single_select';
                            else columnTypeInput
                          "
                        >
                          <ng-container *ngIf="column.name == 'startnumber'">
                            <mat-select
                              placeholder="Starnumber"
                              [(ngModel)]="item.number"
                              (selectionChange)="
                                assignedStartnumber($event, item)
                              "
                            >
                              <mat-option
                                *ngFor="let option of item.start_numbers"
                                [value]="option.number"
                              >
                                {{ option.number }}</mat-option
                              >
                            </mat-select>
                          </ng-container>
                        </ng-container>
                        <ng-template #columnTypeInput>
                          <ng-container *ngIf="column.name == 'startnumber'">
                            <input
                              matInput
                              [type]="column.type"
                              placeholder="Startnumber"
                              [(ngModel)]="item.number"
                              (change)="assignedStartnumber($event, item)"
                            />
                          </ng-container>
                        </ng-template>
                      </ng-container>

                      <ng-template #columnDisplay>
                        <ng-container
                          *ngIf="
                            column.html && !column.type;
                            else columnTypeDisplay
                          "
                        >
                          <div [innerHTML]="getData(item, column) | safe"></div>
                        </ng-container>

                        <ng-template #columnTypeDisplay>
                          <ng-container
                            *ngIf="!!column.type; else otherColumns"
                          >
                            <ng-container [ngSwitch]="column.type">
                              <span *ngSwitchCase="'checkbox'">
                                <mat-checkbox
                                  class="custom-checkbox"
                                  (click)="onTableCellClickEvent($event)"
                                  (change)="onTableCellClickHandler(item, column)"
                                  [checked]="getData(item, column)"
                                  [color]="'primary'"
                                >
                                </mat-checkbox>
                              </span>
                              <span *ngSwitchCase="'decimal'">{{
                                getData(item, column)
                                  | localNumber : column.format
                              }}</span>
                              <span *ngSwitchCase="'date'">{{
                                getData(item, column) | localDate : "date"
                              }}</span>
                              <span *ngSwitchCase="'datetime'">{{
                                getData(item, column) | localDate : "datetime"
                              }}</span>
                              <span *ngSwitchCase="'boolean'">
                                <ng-container *ngIf="column.html">
                                  <div
                                    [innerHTML]="getData(item, column) | safe"
                                  ></div>
                                </ng-container>
                                <ng-container *ngIf="!column.html">
                                  <span
                                    class="badge badge-{{
                                      getData(item, column)
                                        ? 'success'
                                        : 'danger'
                                    }} text-white"
                                  >
                                    {{
                                      getData(item, column)
                                        | boolean
                                          : column.yes_label
                                          : column.no_label
                                    }}
                                  </span>
                                </ng-container>
                              </span>
                              <span *ngSwitchDefault>{{
                                getData(item, column)
                              }}</span>
                            </ng-container>
                          </ng-container>
                        </ng-template>
                      </ng-template>

                      <ng-template #otherColumns>
                        <span *ngIf="!column.type">
                          <span
                            *ngIf="column.format == 'color'"
                            class="format-color"
                            [style.background]="item[column.name]"
                          ></span>
                          <span>
                            <ng-container
                              *ngIf="!!column.link; else defaultText"
                            >
                              <span
                                [innerHTML]="
                                  parseLinkCell(item, column)
                                    | parseLink
                                      : getFormattedValue(item, column)
                                "
                              ></span>
                            </ng-container>
                            <ng-template #defaultText>
                              {{ getFormattedValue(item, column) | stripHtml }}
                            </ng-template>
                          </span>
                        </span>
                      </ng-template>

                      <span
                        *ngIf="
                          column.suffix !== undefined &&
                          getData(item, column) !== ''
                        "
                      >
                        {{ getSuffix(item, column.suffix) }}
                      </span>
                    </span>
                  </td>
                </ng-container>
              </ng-container>

              <ng-container #matColumnActions matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="table-header-cell">
                  &nbsp;
                </th>
                <td
                  mat-cell
                  *matCellDef="let item; let rowIndex = index"
                  [ngClass]="{ 'mat-row-first-child-actions': rowIndex == 0 }"
                >
                  <div class="cell-content-wrap">
                    <ng-container
                      *ngTemplateOutlet="
                        columnActions;
                        context: { $implicit: item, index: rowIndex }
                      "
                    >
                    </ng-container>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="getDisplayColumns(); sticky: true"
              ></tr>
              <ng-container
                *ngIf="!(isLoadingResults || isLoadingTranslations)"
              >
                <tr
                  mat-row
                  *matRowDef="let row; columns: getDisplayColumns()"
                ></tr>
              </ng-container>
            </table>

            <!-- Message for empty data  -->
            <div class="mat-table__message" *ngIf="hasResult === false">
              <!-- {{ "COMMON.NO_ENTRIES_FOUND" | translate }} -->
              <ng-container
                *ngIf="!readonly && name == 'location-services-trackday-items'"
              >
                <button
                  mat-raised-button
                  class="btn btn-outline-primary reset-button-styles success-outline-button"
                  type="button"
                  (click)="actionAddLocationServices()"
                  [attr.data-pgm-tablebutton]="'tablebutton_add-services'"
                >
                  {{ "Assign Services" | translate }}
                </button>
                &nbsp; &nbsp;
              </ng-container>

              <ng-container
                *ngIf="!readonly && name == 'general-services-trackday-items'"
              >
                <button
                  mat-stroked-button
                  type="button"
                  (click)="actionAddGeneralServices()"
                  [attr.data-pgm-tablebutton]="'tablebutton_add-services'"
                >
                  {{ "Assign Services" | translate }}
                </button>
                &nbsp; &nbsp;
              </ng-container>

              <ng-container *ngIf="name == 'trackday-item-service-types'">
                <button
                  mat-stroked-button
                  type="button"
                  (click)="actionAddPrivateServices()"
                  [attr.data-pgm-tablebutton]="
                    'tablebutton_add-private-services'
                  "
                >
                  {{ "Assign Services" | translate }}
                </button>
                &nbsp; &nbsp;
              </ng-container>
              <ng-container
                *ngIf="!readonly && showAddBtn && canAdd(); else emptyMessage"
              >
                <button
                  style="padding: 40px 100px"
                  mat-stroked-button
                  (click)="newItem()"
                  [attr.data-pgm-tablebutton]="'tablebutton_add'"
                >
                  <i class="fa fa-plus"></i>
                  Add your first item
                </button>
              </ng-container>
              <ng-template #emptyMessage>
                <div
                  class="text-black-50"
                  style="
                    display: flex;
                    gap: 8px;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i class="fa-sharp fa-light fa-road"></i>
                  <span>{{ "COMMON.emptyTableMessage" | translate }}</span>
                </div>
              </ng-template>
            </div>
            <div
              class="mat-table__message"
              *ngIf="hasResult === false && (dataSource.loading$ | async)"
            >
              {{ "COMMON.LOADING" | translate }}
            </div>
          </div>
        </div>
        <!-- start: BOTTOM -->
        <div class="mat-table__bottom">
          <ng-container *ngIf="isInQuickColumnEditorMode$ | async">
            <div class="quick-column-edit-indicator-wrapper">
              {{ "COMMON.QUICK_EDIT_MESSAGE" | translate }}
            </div>
          </ng-container>
          <cb-loading
            [diameter]="20"
            [isAbsolute]="false"
            *ngIf="dataSource.loading$ | async"
          ></cb-loading>
          <mat-paginator
            style="display: none"
            #paginatorElement
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [length]="dataSource.paginatorTotal$ | async"
            [showFirstLastButtons]="true"
          ></mat-paginator>
          <app-cb-paginator
            (page)="paginationChange($event)"
            #paginator
            #paginatorElement
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [length]="dataSource.paginatorTotal$ | async"
            [showFirstLastButtons]="true"
            [pageIndex]="lastVisitedTablePage$ | async"
            [condensed]="false"
          >
          </app-cb-paginator>
        </div>
        <!-- end: BOTTOM -->
      </div>
    </div>
  </kt-portlet-body>
  <!-- end::Body -->
</kt-portlet>

<ng-template #columnActions let-item let-index="index">
  <ng-container *ngIf="item">
    <ng-container
      *ngFor="
        let processButton of processButtons
          | filterBy : ['contextMenu', true] : false
      "
    >
      <ng-container
        *ngIf="
          (processButton.processType == 'action' ||
            processButton.processType == 'both') &&
          processButton.isVisible(item)
        "
      >
        <button
          mat-icon-button
          class="cb-icon-button"
          matTooltip="{{ getConfigTranslate(processButton?.tooltip) }}"
          (click)="
            processButton.process(
              [item],
              null,
              httpUtilService.convertQueryParams(this.getQueryParamsModel())
            )
          "
          [disabled]="
            processButton.isDisabled(item) || processButton.isProcessing(item)
          "
          [attr.data-pgm-tablebutton]="processButton.getPGMAttr(item)"
          [attr.data-pgm-tablerow]="
            name + '_tablerow_' + index + '_' + processButton.getID()
          "
        >
          <ng-container *ngIf="processButton.isProcessing(item); else btnTitle">
            <div class="d-flex">
              <cb-loading
                [diameter]="20"
                [isAbsolute]="false"
                style="margin: 0 5px"
              ></cb-loading>
            </div>
          </ng-container>
          <ng-template #btnTitle>
            <i
              *ngIf="processButton.icon"
              [ngClass]="processButton.getIconClass(item)"
              class="cb-icon-button"
            >
            </i>
          </ng-template>
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="customActionBtns">
      <ng-container
        *ngTemplateOutlet="customActionBtns; context: { $implicit: item }"
      >
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!readonly; else readonlyActions">
      <ng-container *ngIf="name == 'activity-logs'">
        <button
          mat-icon-button
          class="col-action-btn custom-icon-button edit-icon text-danger"
          (click)="showActivityLogs(item)"
        >
          <mat-icon>info</mat-icon>
        </button>
      </ng-container>

      <!-- ng-container *ngIf="name === 'permission-groups'">
        <button
          (click)="showDetail(item['description'])"
          mat-icon-button
          class="col-action-btn custom-icon-button edit-icon"
          [matTooltip]="getDescription(item['description'])"
          [attr.data-pgm-tablebutton]="
            name + '_tablebutton_' + item.id + '_info'
          "
          [attr.data-pgm-tablerow]="name + '_tablerow_' + index + '_info'"
        >
          <mat-icon>info</mat-icon>
        </button>
      </ng-container -->

      <ng-container *ngIf="name === 'trackday-items'">
        <span *ngIf="false">
          <ng-container
            *ngIf="
              getToggleItemStatus(
                'is_highlightevent',
                item.id,
                item.is_highlightevent
              )
            "
          >
            <button
              mat-icon-button
              class="col-action-btn custom-icon-button edit-icon"
              (click)="toggleItem('is_highlightevent', item.id, false)"
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.id + '_highlight'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_highlight'
              "
            >
              <i class="fas fa-star text-danger"></i>
            </button>
          </ng-container>
          <ng-container
            *ngIf="
              !getToggleItemStatus(
                'is_highlightevent',
                item.id,
                item.is_highlightevent
              )
            "
          >
            <button
              mat-icon-button
              class="col-action-btn custom-icon-button edit-icon"
              (click)="toggleItem('is_highlightevent', item.id, true)"
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.id + '_highlight'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_highlight'
              "
            >
              <i class="fas fa-star text-ghosted"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="getDatetimeActiveUntilStatus(item)">
            <button
              mat-icon-button
              class="col-action-btn custom-icon-button edit-icon"
              disabled
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.id + '_event-active'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_event-active'
              "
            >
              <i class="fas fa-unlock text-ghosted"></i>
            </button>
          </ng-container>
          <ng-container *ngIf="!getDatetimeActiveUntilStatus(item)">
            <button
              mat-icon-button
              class="col-action-btn custom-icon-button edit-icon"
              (click)="unlockDatetimeActiveUntil(item)"
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.id + '_event-active'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_event-active'
              "
            >
              <i class="fas fa-lock text-danger"></i>
            </button>
          </ng-container>

          <ng-container
            *ngIf="
              getToggleItemStatus(
                'is_usereditable',
                item.id,
                item.is_usereditable
              )
            "
          >
            <button
              mat-icon-button
              class="col-action-btn custom-icon-button edit-icon"
              (click)="toggleItem('is_usereditable', item.id, false)"
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.id + '_user_editable'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_user_editable'
              "
            >
              <i class="fas fa-user-edit text-danger"></i>
            </button>
          </ng-container>
          <ng-container
            *ngIf="
              !getToggleItemStatus(
                'is_usereditable',
                item.id,
                item.is_usereditable
              )
            "
          >
            <button
              mat-icon-button
              class="col-action-btn custom-icon-button edit-icon"
              (click)="toggleItem('is_usereditable', item.id, true)"
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.id + '_user_editable'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_user_editable'
              "
            >
              <i class="fas fa-user-edit text-ghosted"></i>
            </button>
          </ng-container>
        </span>
      </ng-container>

      <ng-container *ngIf="name == 'participants-trackday-items'">
        <!-- button mat-icon-button class="col-action-btn custom-icon-button edit-icon" matTooltip="Assign {{ title }}"
          (click)="newItem(item)" [attr.data-pgm-tablebutton]="name + '_tablebutton_' + item.id + '_edit'"
          [attr.data-pgm-tablerow]="name + '_tablerow_' + index + '_edit'" *ngIf="!item.user_id">
          <i class="la la-plus-square-o"></i>
        </button -->
        <button
          mat-icon-button
          class="cb-icon-button edit-icon"
          (click)="updateItem(item)"
          [attr.data-pgm-tablebutton]="
            name + '_tablebutton_' + item.id + '_edit'
          "
          [attr.data-pgm-tablerow]="name + '_tablerow_' + index + '_edit'"
        >
          <mat-icon>create</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngIf="notes">
        <ng-container *ngIf="hasNotes(item); else disabledNotesBtn">
          <button
            mat-icon-button
            class="col-action-btn custom-icon-button edit-icon text-danger"
            (click)="showNotes(item)"
          >
            <mat-icon>description</mat-icon>
          </button>
        </ng-container>

        <ng-template #disabledNotesBtn>
          <button
            mat-icon-button
            class="col-action-btn custom-icon-button edit-icon"
            disabled
          >
            <mat-icon>description</mat-icon>
          </button>
        </ng-template>
      </ng-container>

      <ng-container
        *ngIf="
          canEdit(item) &&
          name !== 'newsletter' &&
          name !== 'coupon-items' &&
          name !== 'coupon-assigned-items' &&
          name !== 'coupon-unassigned-items' &&
          name !== 'participants-trackday-items'
        "
      >
        <!-- [routerLink]="[baseUrl + '/edit/' + item.id]" -->
        <!-- matTooltip="Edit {{ title }}" -->
        <button
          mat-icon-button
          class="cb-icon-button edit-icon"
          (click)="updateItem(item)"
          [attr.data-pgm-tablebutton]="
            name + '_tablebutton_' + item.id + '_edit'
          "
          [attr.data-pgm-tablerow]="name + '_tablerow_' + index + '_edit'"
        >
          <mat-icon>create</mat-icon>
        </button>
      </ng-container>
    </ng-container>

    <ng-template #readonlyActions>
      <ng-container *ngIf="name == 'activity-logs'">
        <button
          mat-icon-button
          class="col-action-btn edit-icon text-danger"
          (click)="showActivityLogs(item)"
        >
          <mat-icon>info</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngIf="name == 'trackday-bookings'">
        <!-- matTooltip="Edit Booking" -->
        <a
          mat-icon-button
          class="cb-icon-button edit-icon"
          [routerLink]="['/trackdays-management/bookings/edit', item.id]"
          [attr.data-pgm-tablebutton]="
            name + '_tablebutton_' + item.id + '_edit'
          "
          [attr.data-pgm-tablerow]="name + '_tablerow_' + index + '_edit'"
        >
          <mat-icon>create</mat-icon>
        </a>
      </ng-container>

      <ng-container *ngIf="name == 'trackday-group-bookings'">
        <!-- matTooltip="Edit {{ title }}" -->
        <a
          mat-icon-button
          class="cb-icon-button edit-icon"
          [routerLink]="['/trackdays-management/group-bookings/edit', item.id]"
          [attr.data-pgm-tablebutton]="
            name + '_tablebutton_' + item.id + '_edit-item'
          "
          [attr.data-pgm-tablerow]="name + '_tablerow_' + index + '_edit-item'"
        >
          <mat-icon>create</mat-icon>
        </a>
      </ng-container>
    </ng-template>

    <ng-container
      *ngIf="
        showDeleteButton(item) ||
        (processButtons | filterBy : ['contextMenu', true]).length > 0
      "
    >
      <button mat-icon-button [matMenuTriggerFor]="menu" class="cb-icon-button">
        <mat-icon>more_vert</mat-icon>
      </button>
      <!-- context menu generation-->
      <mat-menu #menu="matMenu" xPosition="before">
        <ng-container
          *ngFor="
            let processButton of processButtons
              | filterBy : ['contextMenu', true]
          "
        >
          <ng-container *ngIf="processButton.isVisible(item)">
            <ng-container
              *ngIf="
                processButton.processType == 'action' ||
                processButton.processType == 'both'
              "
            >
              <!-- testing purposes -->
              <!-- {{ print(childrenProcessButtons[processButton.indexForChildrenProcessButtons]) }} -->
              <button
                mat-menu-item
                *ngIf="processButton.hasChildren"
                [matMenuTriggerFor]="menu"
                class="col-action-btn custom-icon-button"
                (click)="
                  processButton.process(
                    [item],
                    null,
                    httpUtilService.convertQueryParams(
                      this.getQueryParamsModel()
                    )
                  )
                "
                [disabled]="
                  processButton.isDisabled(item) ||
                  processButton.isProcessing(item)
                "
                [attr.data-pgm-tablebutton]="processButton.getPGMAttr(item)"
                [attr.data-pgm-tablerow]="
                  name + '_tablerow_' + index + '_' + processButton.getID()
                "
              >
                <ng-container
                  *ngIf="processButton.isProcessing(item); else btnTitle"
                >
                  <div class="d-flex">
                    <cb-loading
                      [diameter]="20"
                      [isAbsolute]="false"
                      style="margin: 0 5px"
                    ></cb-loading>
                  </div>
                </ng-container>

                <ng-template #btnTitle>
                  <i
                    *ngIf="processButton.icon"
                    class="{{ processButton.getIconClass(item) }}"
                  ></i>
                </ng-template>

                <span class="pl-2">{{
                  getConfigTranslate(processButton.getTitle(item))
                }}</span>
              </button>
              <!-- -------------sub menu generation----------------------- -->
              <mat-menu #menu="matMenu" xPosition="before">
                <ng-container
                  *ngFor="
                    let subMenuprocessButton of childrenProcessButtons[
                      processButton.indexForChildrenProcessButtons
                    ]
                  "
                >
                  <ng-container
                    *ngIf="
                      subMenuprocessButton.processType == 'action' ||
                      subMenuprocessButton.processType == 'both'
                    "
                  >
                    <button
                      mat-menu-item
                      class="col-action-btn custom-icon-button"
                      (click)="
                        subMenuprocessButton.process(
                          [item],
                          null,
                          httpUtilService.convertQueryParams(
                            this.getQueryParamsModel()
                          )
                        )
                      "
                      [disabled]="
                        subMenuprocessButton.isDisabled(item) ||
                        subMenuprocessButton.isProcessing(item)
                      "
                      [attr.data-pgm-tablebutton]="
                        subMenuprocessButton.getPGMAttr(item)
                      "
                      [attr.data-pgm-tablerow]="
                        name +
                        '_tablerow_' +
                        index +
                        '_' +
                        subMenuprocessButton.getID()
                      "
                    >
                      <ng-container
                        *ngIf="
                          subMenuprocessButton.isProcessing(item);
                          else btnTitle
                        "
                      >
                        <div class="d-flex">
                          <cb-loading
                            [diameter]="20"
                            [isAbsolute]="false"
                            style="margin: 0 5px"
                          ></cb-loading>
                        </div>
                      </ng-container>

                      <ng-template #btnTitle>
                        <i
                          *ngIf="subMenuprocessButton.icon"
                          class="{{ subMenuprocessButton.getIconClass(item) }}"
                        ></i>
                      </ng-template>

                      <span class="pl-2">{{
                        getConfigTranslate(subMenuprocessButton.getTitle(item))
                      }}</span>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="subMenuprocessButton.processType == 'actionlink'"
                  >
                    <button
                      mat-menu-item
                      class="col-action-btn custom-icon-button"
                      matTooltip="{{ subMenuprocessButton?.tooltip }}"
                      (click)="
                        openLink(item, subMenuprocessButton.actionLinkConfig)
                      "
                      [attr.data-pgm-tablebutton]="
                        subMenuprocessButton.getPGMAttr(item)
                      "
                      [attr.data-pgm-tablerow]="
                        name +
                        '_tablerow_' +
                        index +
                        '_' +
                        subMenuprocessButton.getID()
                      "
                    >
                      <ng-container>
                        <i
                          *ngIf="subMenuprocessButton.icon"
                          class="{{ subMenuprocessButton.getIconClass(item) }}"
                        ></i>
                      </ng-container>
                      <span class="pl-2">{{
                        getConfigTranslate(subMenuprocessButton.getTitle(item))
                      }}</span>
                    </button>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="readonly">
                  <ng-container *ngIf="name == 'trackday-bookings'">
                    <a
                      mat-menu-item
                      [routerLink]="[]"
                      class="col-action-btn custom-icon-button"
                      (click)="showBookingInfo(item)"
                      [attr.data-pgm-tablebutton]="
                        name + '_tablebutton_' + item.id + '_booking-info'
                      "
                      [attr.data-pgm-tablerow]="
                        name + '_tablerow_' + index + '_booking-info'
                      "
                    >
                      <i class="far fa-info-circle"></i>
                      <span class="pl-2">{{ "Booking Info" | translate }}</span>
                    </a>
                    <a
                      *ngIf="item.trackdayitem"
                      mat-menu-item
                      class="col-action-btn custom-icon-button"
                      [routerLink]="[
                        '/trackdays-management/trackday-events/edit',
                        item.trackdayitem.id
                      ]"
                      [attr.data-pgm-tablebutton]="
                        name +
                        '_tablebutton_' +
                        item.trackdayitem.id +
                        '_trackday-info'
                      "
                      [attr.data-pgm-tablerow]="
                        name + '_tablerow_' + index + '_trackday-info'
                      "
                    >
                      <i class="far fa-calendar-check"></i>
                      <span class="pl-2">{{
                        "Trackday Info" | translate
                      }}</span>
                    </a>
                    <a
                      *ngIf="item.user"
                      mat-menu-item
                      class="col-action-btn custom-icon-button"
                      [routerLink]="[
                        '/user-management/users/edit',
                        item.user.id
                      ]"
                      [attr.data-pgm-tablebutton]="
                        name + '_tablebutton_' + item.user.id + '_user-info'
                      "
                      [attr.data-pgm-tablerow]="
                        name + '_tablerow_' + index + '_user-info'
                      "
                    >
                      <i class="far fa-user"></i>
                      <span class="pl-2">{{ "User Info" | translate }}</span>
                    </a>
                  </ng-container>
                </ng-container>
              </mat-menu>
              <!-- ------------------------------------ -->

              <button
                mat-menu-item
                *ngIf="!processButton.hasChildren"
                class="col-action-btn custom-icon-button"
                (click)="
                  processButton.process(
                    [item],
                    null,
                    httpUtilService.convertQueryParams(
                      this.getQueryParamsModel()
                    )
                  )
                "
                [disabled]="
                  processButton.isDisabled(item) ||
                  processButton.isProcessing(item)
                "
                [attr.data-pgm-tablebutton]="processButton.getPGMAttr(item)"
                [attr.data-pgm-tablerow]="
                  name + '_tablerow_' + index + '_' + processButton.getID()
                "
              >
                <ng-container
                  *ngIf="processButton.isProcessing(item); else btnTitle"
                >
                  <div class="d-flex">
                    <cb-loading
                      [diameter]="20"
                      [isAbsolute]="false"
                      style="margin: 0 5px"
                    ></cb-loading>
                  </div>
                </ng-container>

                <ng-template #btnTitle>
                  <i
                    *ngIf="processButton.icon"
                    class="{{ processButton.getIconClass(item) }}"
                  ></i>
                </ng-template>

                <span class="pl-2">{{
                  getConfigTranslate(processButton.getTitle(item))
                }}</span>
              </button>
            </ng-container>
            <ng-container *ngIf="processButton.processType == 'actionlink'">
              <button
                mat-menu-item
                class="col-action-btn custom-icon-button"
                matTooltip="{{ processButton?.tooltip }}"
                (click)="openLink(item, processButton.actionLinkConfig)"
                [attr.data-pgm-tablebutton]="processButton.getPGMAttr(item)"
                [attr.data-pgm-tablerow]="
                  name + '_tablerow_' + index + '_' + processButton.getID()
                "
              >
                <ng-container>
                  <i
                    *ngIf="processButton.icon"
                    class="{{ processButton.getIconClass(item) }}"
                  ></i>
                </ng-container>
                <span class="pl-2">{{
                  getConfigTranslate(processButton.getTitle(item))
                }}</span>
              </button>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="readonly">
          <ng-container *ngIf="name == 'trackday-bookings'">
            <a
              mat-menu-item
              [routerLink]="[]"
              class="col-action-btn custom-icon-button"
              (click)="showBookingInfo(item)"
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.id + '_booking-info'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_booking-info'
              "
            >
              <i class="far fa-info-circle"></i>
              <span class="pl-2">{{ "Booking Info" | translate }}</span>
            </a>
            <a
              *ngIf="item.trackdayitem"
              mat-menu-item
              class="col-action-btn custom-icon-button"
              [routerLink]="[
                '/trackdays-management/trackday-events/edit',
                item.trackdayitem.id
              ]"
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.trackdayitem.id + '_trackday-info'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_trackday-info'
              "
            >
              <i class="far fa-calendar-check"></i>
              <span class="pl-2">{{ "Trackday Info" | translate }}</span>
            </a>
            <a
              *ngIf="item.user"
              mat-menu-item
              class="col-action-btn custom-icon-button"
              [routerLink]="['/user-management/users/edit', item.user.id]"
              [attr.data-pgm-tablebutton]="
                name + '_tablebutton_' + item.user.id + '_user-info'
              "
              [attr.data-pgm-tablerow]="
                name + '_tablerow_' + index + '_user-info'
              "
            >
              <i class="far fa-user"></i>
              <span class="pl-2">{{ "User Info" | translate }}</span>
            </a>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showDeleteButton(item)">
          <!-- matTooltip="Delete {{ title }}" -->
          <button
            mat-menu-item
            class="col-action-btn custom-icon-button"
            (click)="deleteItem(item)"
            [attr.data-pgm-tablebutton]="
              name + '_tablebutton_' + item.id + '_delete'
            "
            [attr.data-pgm-tablerow]="name + '_tablerow_' + index + '_delete'"
          >
            <i class="fas fa-trash text-danger"></i>
            <span class="pl-2 text-danger">{{ "Delete" | translate }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
  </ng-container>
</ng-template>
