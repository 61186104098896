import { Action, createReducer, on } from "@ngrx/store";
import { AppState } from "../../../core/store/reducers/app.reducers";
import { ACTIONS } from "./default.actions";
import { KanbanStoreModel } from "./default.model";

export const KANBAN_FEATURE = 'kanban'

export const initialState: KanbanStoreModel = {
    projects: [],
    tasks: [],
    activeProjectId: null
}



const reducer = createReducer(
    initialState,    
    on(ACTIONS.projects.GetAllSuccess, (state, { payload }) => {
        if (!payload) {
            return state
        }
        return {
            ...state,
            projects: payload
        }
    }),
    on(ACTIONS.boards.GetBoardsByProjectIdSuccess, (state, { payload }) => {
        const projects = state.projects.map(p => {
            const boards = payload.filter(b => b.project.id === p.id)
            return {
                ...p,
                boards
            }
        })
        return {
            ...state,
            projects
        }
    }),
    on(ACTIONS.projects.CreateProjectSuccess, (state, { payload }) => {
        return {
            ...state,
            projects: [...state.projects, payload]
        }
    }),
    on(ACTIONS.boards.CreateBoardSuccess, (state, { payload }) => {
        const projects = state.projects.map(p => {
            if (p.id === payload.project.id) {
                return {
                    ...p,
                    boards: [...p.boards, payload]
                }
            }
            return p
        })
        return {
            ...state,
            projects
        }
    }),
    on(ACTIONS.boards.GetBoardsByProjectId, (state, { payload }) => {
        return {
            ...state,
            activeProjectId: payload
        }
    }),
    on(ACTIONS.tasks.GetTasksByBoardIdSuccess, (state, { payload }) => {        
        return {
            ...state,
            tasks: payload
        }
    }),
    on(ACTIONS.tasks.ResetTasks, (state) => {        
        return {
            ...state,
            tasks: []
        }
    }),
);


export function kanbanReducer(
    state: KanbanStoreModel | undefined,
    action: Action
) {
    return reducer(state, action);
}



export interface State extends AppState {
    [KANBAN_FEATURE]: KanbanStoreModel;
}
