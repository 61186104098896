// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AppState } from './../../../../core/store/reducers/app.reducers';
import { AuthService } from './../../../../services/auth.service';
import { ApiConfig } from './../../../../core/config/api.config';
import { currentUser, Logout, User } from './../../../../core/store';
import { Subscription } from 'rxjs';
import { SubheaderService } from './../../../../core/base/services/subheader.service';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment_api } from "../../../../../environments/environment.api";
// Auth

@Component({
	selector: 'profile-password-two-factor',
	styleUrls: ['./profile-password-two-factor.component.scss'],
	templateUrl: './profile-password-two-factor.component.html',
})
export class ProfilePasswordTwoFactorComponent implements OnInit, OnDestroy {
	apiUrl: string;
	formConfig = [];
	formData: any;
	subs: Subscription;
	cdRef: any;
	user$ = new BehaviorSubject<User>(null);
	recoveryCodes$ = new BehaviorSubject<string[]>([]);
	@ViewChild('qrCodeImage') qrCodeImage;
	@ViewChild('qrCodeImageConfirmation') qrCodeImageConfirmation;
	changePasswordForm: UntypedFormGroup;
	twoFactorConfirmationForm: UntypedFormGroup;
	hasTwoFactor$ = new BehaviorSubject<boolean>(false);
	shouldConfirm$ = new BehaviorSubject<boolean>(false);






	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param router: Router
	 */
	constructor(
		private store: Store<AppState>,
		private router: Router,
		private auth: AuthService,
		private subheaderService: SubheaderService,
		private sanitizer: DomSanitizer,
		private http: HttpClient,
		private fb: UntypedFormBuilder) {
		this.changePasswordForm = this.fb.group({
			oldPassword: ["", [Validators.required]],
			newPassword: ["", [Validators.required]],
			confirmedNewPassword: ["", [Validators.required]],
		})

		this.twoFactorConfirmationForm = this.fb.group({
			code: ["", [Validators.required]],
		})
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// Set title to page breadCrumbs
		this.subheaderService.setTitle('Profile Password And Two Factor');

		this.apiUrl = ApiConfig.UserProfileInfoURL();
		this.subs = this.auth.getUserByToken().subscribe(res => {
			if (res) {
				this.formData = { id: res.id };
				this.user$.next(res);
			}
		});
		this.checkAuthType()
		if (this.hasTwoFactor$.value) {
			this.loadRecoveryCodes()
		}
	}

	ngOnDestroy() {
		if (this.subs) this.subs.unsubscribe();
	}

	confirmTwoFactor = () => {
		const baseUrlParts = environment_api.api_url.toString().split('/api')
		if (baseUrlParts.length > 0) {
			const url = `${baseUrlParts[0]}/user/confirmed-two-factor-authentication`;			
			this.http.post<any>(url, this.twoFactorConfirmationForm.getRawValue()).subscribe(data => {
				this.hasTwoFactor$.next(true);
				this.loadRecoveryCodes()
			});
		}
	}

	activate = () => {
		const baseUrlParts = environment_api.api_url.toString().split('/api')
		if (baseUrlParts.length > 0) {
			const url = `${baseUrlParts[0]}/user/two-factor-authentication`;
			this.http.post<any>(url, null).subscribe(data => {
				this.showActivationQrCode()
				this.shouldConfirm$.next(true)				
			});
		}
	}

	showActivationQrCode = () => {
		const baseUrlParts = environment_api.api_url.toString().split('/api')
		if (baseUrlParts.length > 0) {
			const qrCodeUrl = `${baseUrlParts[0]}/user/two-factor-qr-code`;
			this.http.get<any>(qrCodeUrl).subscribe(data => {
				if (data) {
					// @ts-ignore
					this.qrCodeImageConfirmation.nativeElement.innerHTML = this.sanitizer.bypassSecurityTrustHtml(data.svg).changingThisBreaksApplicationSecurity
				}
			});
		}
	}



	deactivate = () => {
		const baseUrlParts = environment_api.api_url.toString().split('/api')
		if (baseUrlParts.length > 0) {
			const url = `${baseUrlParts[0]}/user/two-factor-authentication`;
			this.http.delete<any>(url).subscribe(data => {
				this.hasTwoFactor$.next(false);
			});
		}
	}

	checkAuthType = () => {
		const baseUrl = environment_api.api_url
		const url = `${baseUrl}/two-factor/status`;
		this.http.get(url).subscribe(data => {
			if (data) {
				if (data === 1) {
					this.hasTwoFactor$.next(true);
					this.loadRecoveryCodes()
				} else {
					this.hasTwoFactor$.next(false);
				}
			}
		});

	}

	loadQrCode = () => {
		const baseUrlParts = environment_api.api_url.toString().split('/api')
		if (baseUrlParts.length > 0) {
			const qrCodeUrl = `${baseUrlParts[0]}/user/two-factor-qr-code`;
			this.http.get<any>(qrCodeUrl).subscribe(data => {
				if (data) {
					// @ts-ignore
					this.qrCodeImage.nativeElement.innerHTML = this.sanitizer.bypassSecurityTrustHtml(data.svg).changingThisBreaksApplicationSecurity
				}
			});
		}
	}

	loadRecoveryCodes = () => {
		const baseUrlParts = environment_api.api_url.toString().split('/api')
		if (baseUrlParts.length > 0) {
			const qrCodeUrl = `${baseUrlParts[0]}/user/two-factor-recovery-codes`;
			this.http.get<any>(qrCodeUrl).subscribe(data => {
				if (data) {
					this.recoveryCodes$.next(data)
				}
			});
		}
	}

	copyToClipBoard = async (model: string) => {
		const data = [new ClipboardItem({ "text/plain": new Blob([model], { type: "text/plain" }) })];
		navigator.clipboard.write(data).then(function () {
			console.log("Copied to clipboard successfully!");
		}, function () {
			console.error("Unable to write to clipboard. :-(");
		});
	}

	changePassword = () => {
		const values: any = this.changePasswordForm.getRawValue()
		this.subs = this.auth.changePassword(values.oldPassword, values.newPassword, values.confirmedNewPassword).subscribe(res => {
			if (res) {
				this.formData = { id: res.id };
				this.user$.next(res);
			}
		});
		this.loadRecoveryCodes()

	}
}