export function extractCssClasses(styles) {
  if (!styles) return {};
  const res = {};
  Object.entries(styles).forEach(([key, value]) => {
    if (typeof value === "string") {
      res[key] = value;
    }
  });
  return res;
}

export const DATE_FORMAT = "yyyy-MM-dd";

export const generateGenericDeleteButton = (apiUrl) => {
    const targetUrl = apiUrl.split('/').pop()
  return {
    title: "Delete",
    type: "danger",
    icon: "fa-solid fa-trash text-danger",
    callBack: null,
    isContextMenuButton: true,
    isSpecialType: "delete",
    callback: {
      method: "DELETE",
      url: `/${targetUrl}`,
    },
    confirm: {
      message: "Are you sure you want to delete this item?",
      ok_label: "Yes, continue",
      cancel_label: "No",
    },
  };
};
