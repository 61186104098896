/**
 * Usage: date | localDate'format'
 * If no format is provided `shortDate` will be used.
 **/

import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe, formatDate } from "@angular/common";
import { TranslationService } from "../services/translation.service";
import * as moment from "moment";

@Pipe({
    name: "localDate",
    pure: false
})
export class LocalDatePipe implements PipeTransform {
    constructor(
        private datePipe: DatePipe,
        private translationService: TranslationService
    ) { }

    transform(value: any, type: string, format?: string) {
        if (!value) return "";
        if (!moment(value).isValid()) return "";

        let locale = this.translationService.getSelectedLanguage();
        if (locale == "ch") locale = "zh";
        else if (locale == "jp") locale = "ja";

        try {

            let formattedDate = null;

            if (!format) {
                const localLocale = moment(value);
                localLocale.locale(locale);
                formattedDate = localLocale.format('L');
            } else {
                formattedDate = formatDate(moment(value).format(), format, locale);
            }

            if (type == "datetime") {
                formattedDate =
                    formattedDate + ", " + formatDate(moment(value).format(), "shortTime", locale);
            }
            return formattedDate;
        } catch (error) {
            return null;
        }
    }
}
