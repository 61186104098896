import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment_api } from "../../../../environments/environment.api";

const KEYS = {
  COLUMNS: "columns-",
  ACCORDION: "filter_accordion_status_",
  PAGINATION: "pagination-",
  LAYOUT: "layout-",
};

@Injectable()
export class TableUserConfigService {
  private _tableName = "";
  private _tableConfig: any = {};
  private _userConfig: any = {};
  private _columns: any[] = [];

  constructor(private http: HttpClient) {}

  // this is called on init of the table component
  // with data from server
  initiateConfig({ tableName, tableConfig }) {
    this._tableName = tableName;
    this._tableConfig = tableConfig;
    // this._userConfig = sampleData;
    this._userConfig = this._tableConfig?.["user_config"] || {};
    Object.entries(this._userConfig).forEach(([key, value]) => {
      // we need to update the server so that it checks for config table name instead of config name
      this.updateLocalStorageByKey(key, value);
    });
  }

  updateUserConfig(callback?: () => void) {
    // const payload = {
    //   table_config: { ...this.userConfig, [key]: value },
    // };
    const payload = this._preparePayload();
    this.http
      .post(
        `${environment_api.api_url}/update_user_tableconfig/${this._tableName}`,
        payload
      )
      .subscribe({
        next: (_) => {
          if (callback) {
            callback();
          }
        },
        error: (error) => {},
      });
  }

  updateTableState(
    state: any,
    pagination?: { pageSize: any; pageSizeOptions: any }
  ) {
    const key = this.tableName;
    this.updateLocalStorageByKey(key, state);
    if (pagination) {
      this.updatePaginationState(pagination);
    }
    this.updateUserConfig();
  }
  updatePaginationState(state: any) {
    // for some reason, the paginator component, and the actual page value used to
    // construct the api, are stored in 2 different places
    // so we need to update both
    // basically whenever this is called, there is already another request sent to the api
    // to update the table data, so we don't need to do anything else here
    const key = this.getPaginationKey();
    this.updateLocalStorageByKey(key, state);
    // this.updateUserConfig();
  }

  updateCrudLayoutState(state: string, callback?: () => void) {
    const key = this.getLayoutKey();
    this.updateLocalStorageByKey(key, state);
    this.updateUserConfig(callback);
  }

  updateFilterAccordionState(state: boolean) {
    // in order to fix the issue with opening of the filter accordion,
    // the call to open the accordion is at the very end of the table component
    // hence it recalls the updateFilterAccordionState method
    // so we need to check if the state is already the same as the one we are trying to set
    // if it is, we don't need to do anything
    const currentState = this.getFilterAccordionState();
    if (currentState === state) {
      return;
    }
    const key = this.getAccordionKey();
    this.updateLocalStorageByKey(key, state);
    this.updateUserConfig();
  }

  updateColumnsVisibility(columns: string[]) {
    const userColumns = this.getUserColumns();
    const key = this.getColumnsKey();
    const updatedColumns = columns.map((c) => {
      const target = userColumns.find((uc) => uc.name === c);
      if (!target) {
        return {
          name: c,
          visible: true,
          order: 0,
        };
      }
      return {
        ...target,
        visible: true,
      };
    });
    this.updateLocalStorageByKey(key, updatedColumns);
  }

  updateColumnsOrder(columns: string[]) {
    const userColumns = this.getUserColumns();
    const key = this.getColumnsKey();
    const updatedColumns = userColumns.map((c, idx) => {
      return {
        ...c,
        order: columns.indexOf(c.name),
      };
    });
    this.updateLocalStorageByKey(key, updatedColumns);
  }

  updateColumns({ columns, columnOrder, allColumns }) {
    this.updateColumnsVisibility(columns);
    this.updateColumnsOrder(columnOrder);
    this.setTableColumns(allColumns);
    this.updateUserConfig();
  }

  getColumnInitialOrder(columnName: string, idx: number) {
    const userColumns = this.getUserColumns();
    if (userColumns?.length > 0) {
      return userColumns.find((c) => c.name === columnName)?.order ?? idx;
    }
    return idx;
  }

  getColumnInitialVisibility(columnName: string) {
    const userColumns = this.getUserColumns();
    if (userColumns?.length > 0) {
      return userColumns.find((c) => c.name === columnName)?.visible;
    }
    return true;
  }

  getUserColumns() {
    return this.getFromLocalStorageByKey(this.getColumnsKey());
  }

  getFilterAccordionState() {
    return this.getFromLocalStorageByKey(this.getAccordionKey());
  }

  _preparePayload() {
    return {
      table_config: {
        ...this.userConfig,
        [this.getTableKey()]: this.getFromLocalStorageByKey(this.getTableKey()),
        [this.getColumnsKey()]: this.getFromLocalStorageByKey(
          this.getColumnsKey()
        ),
        [this.getAccordionKey()]: this.getFromLocalStorageByKey(
          this.getAccordionKey()
        ),
        [this.getPaginationKey()]: this.getFromLocalStorageByKey(
          this.getPaginationKey()
        ),
        [this.getLayoutKey()]: this.getFromLocalStorageByKey(
          this.getLayoutKey()
        ),
      },
    };
  }

  updateLocalStorageByKey(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getFromLocalStorageByKey(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  setTableColumns(columns: any[]) {
    this._columns = columns;
  }

  getTableColumns() {
    return this._columns;
  }

  getCurrentPaginationStatus() {
    const key = this.getTableKey();
    const value = this.getFromLocalStorageByKey(key);
    return { pageNumber: value.pageNumber, pageSize: value.pageSize };
  }

  get tableConfig() {
    return this._tableConfig;
  }
  set tableConfig(value) {
    this._tableConfig = value;
  }

  get userConfig() {
    return this._userConfig;
  }
  set userConfig(value) {
    this._userConfig = value;
    console.log(this._userConfig);
  }

  get tableName() {
    return this._tableName;
  }
  set tableName(value) {
    this._tableName = value;
  }

  private getTableKey() {
    return this.tableName;
  }

  private getLayoutKey() {
    return KEYS.LAYOUT + this.tableName;
  }

  private getColumnsKey() {
    return KEYS.COLUMNS + this.tableName;
  }

  private getAccordionKey() {
    return KEYS.ACCORDION + this.tableName;
  }

  private getPaginationKey() {
    return KEYS.PAGINATION + this.tableName;
  }
}
