<ng-container *ngIf="!!content">
  <custom-dropzone
    [name]="content['caption']"
    [model]="content['model']"
    [modelId]="id"
    [type]="content['type']"
    [types]="content['types']"
    [maxUploadCount]="content['maxUploadCount']"
    [readonly]="false"
    [acceptedFileTypes]="content['accepted_file_types']"
    (onInit)="customDropzoneComponents.push($event)"
  >
  </custom-dropzone>

  <!-- <div *ngIf="!getTabValid(tab)" class="invalid-feedback">
    Please upload a file
  </div> -->
</ng-container>
