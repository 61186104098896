// Angular
import {
    ChangeDetectionStrategy,
    Component,
    Input
} from "@angular/core";

export interface ISearchResult {
  icon: string;
  img: string;
  title: string;
  text: string;
  url: string;
  category: number;
}

export interface searchCategoryType {
  id: number;
  title: string;
  translation: string;
  iconClass: string;
}

@Component({
  selector: "kt-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.scss"],
//   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultComponent {
  // Public properties
  @Input() data: ISearchResult[] = [];
  @Input() noRecordText: string;
  memoizedIcons = [];
  categories: searchCategoryType[] = categories;
  selectedCategory: number = 0;

  changeCategory(item: searchCategoryType) {
    this.selectedCategory = item.id;
  }

  getDataBasedOnSelectedCategory = () => {
    if (this.data?.length) {
      if (this.selectedCategory === 0) {
        const updatedWithCategory = this.data.map((item) => {
          return {
            ...item,
            icon: this.getCategoryItemIcon(item.category),
          };
        });
        return updatedWithCategory;
      }
      const res = this.data.filter((item) => {
        return item.category === this.selectedCategory;
      });
      return res.map((item) => {
        return {
          ...item,
          icon: this.getCategoryItemIcon(item.category),
        };
      });
    }

    return this.data;
  };

  getCategoryItemIcon = (id: number) => {
    const item = this.categories.find((cat) => cat.id === id);
    return item?.iconClass;
  };

  getCountOfCategory = (categoryId) => {
    if (this.data?.length) {
      if (categoryId === 0) {
        return `(${this.data.length})`;
      }
      const res = this.data.filter((item) => {
        return item.category === categoryId;
      });
      return `(${res.length})`;
    }

    return null;
  };
}

export const categories: searchCategoryType[] = [
  {
    id: 0,
    title: "All",
    translation: "MENU.SEARCH.ALL",
    iconClass: "fa-globe",
  },
  {
    id: 1,
    title: "Users",
    translation: "MENU.SEARCH.USERS",
    iconClass: "fa-user",
  },
  {
    id: 2,
    title: "Participant Bookings",
    translation: "MENU.SEARCH.PARTICIPANTBOOKINGS",
    iconClass: "fa-user-friends",
  },
  {
    id: 16,
    title: "Booking", // concept
    translation: "MENU.SEARCH.BOOKINGS",
    iconClass: "fa-book-open",
  },
  {
    id: 4,
    title: "Trackday Events",
    translation: "MENU.SEARCH.TRACKDAYEVENTS",
    iconClass: "fa-calendar",
  },
  {
    id: 32,
    title: "Coupons",
    translation: "MENU.SEARCH.COUPONS",
    iconClass: "fa-ticket",
  },
  // {
  //   id: 8,
  //   title: "Trackdays", // concept
  //   translation: "MENU.SEARCH.TRACKDAYS",
  //   iconClass: "fa-thumbtack",
  // },
];
