<ng-container *ngIf="vm$ | async as vm">
  <div
    [ngClass]="{ hidden: !vm.items }"
    class="navigation-toolbar"
    [@growAnimation]="vm.isLoading ? 'loading' : 'loaded'"
  >
    <ng-container *ngIf="!vm.isLoading; else loading">
      <ng-container *ngFor="let item of vm.items">
        <ng-container [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="'menu'">
            <ng-container
              [ngTemplateOutlet]="menuItem"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'button'">
            <ng-container
              [ngTemplateOutlet]="linkItem"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'modal-route'">
            <ng-container
              [ngTemplateOutlet]="modalRoute"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'separator'">
            <ng-container *ngTemplateOutlet="separator"></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            a new type of item is added
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="hasError$ | async">
      something went wrong
    </ng-container>
  </div>
</ng-container>

<ng-template #modalRoute let-item>
  <div class="toolbar-item">
    <button
      [matTooltip]="item.translate | translate"
      mat-icon-button
      [routerLink]="{ outlets: { modal: [item.link] }, relativeTo: route.root }"
      class="cb-icon-button"
    >
      <i [ngClass]="item.icon"></i>
    </button>
  </div>
</ng-template>

<ng-template #menuItem let-item>
  <div class="toolbar-item">
    <button class="menu-trigger" mat-button [matMenuTriggerFor]="menu">
      <i [ngClass]="item.icon"></i>
      <span
        class="separator ml-2"
        style="height: 24px; display: inline-block"
      ></span>
      <i class="fa-solid fa-caret-down fa-1x"></i>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let submenu of item.submenu"
        [routerLink]="submenu.link"
      >
        <i [ngClass]="submenu.icon"></i>
        <span class="ml-2">{{ submenu.translate | translate }}</span>
      </button>
    </mat-menu>
  </div>
</ng-template>

<ng-template #linkItem let-item>
  <div class="toolbar-item">
    <button
      [matTooltip]="item.translate | translate"
      mat-icon-button
      [routerLink]="item.link"
      class="cb-icon-button"
    >
      <!-- <mat-icon fontSet="fa" fontIcon="fa-clipboard-check"></mat-icon> -->
      <i [ngClass]="item.icon"></i>
    </button>
  </div>
</ng-template>

<ng-template #separator>
  <div class="toolbar-item">
    <div class="separator"></div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="toolbar-item">
    <cb-loading [diameter]="20" [isAbsolute]="false"></cb-loading>
  </div>
</ng-template>
