import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "kt-crud-form-dialog",
  templateUrl: "./crud-form-dialog.component.html",
})
export class CrudFormDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CrudFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("CrudFormDialogComponent.MAT_DIALOG_DATA", data);
  }

  ngOnInit() {}
}
