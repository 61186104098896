import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ApiConfig } from "../../../../../core/config/api.config";

@Component({
  selector: "templates-form",
  templateUrl: "./templates-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatesFormComponent implements OnInit {
  apiUrl: string;

  ngOnInit() {
    this.apiUrl = ApiConfig.projectTemplatesURL();
  }
}
