<div class="filters" [formGroup]="filterForm">
  <mat-accordion>
    <mat-expansion-panel [expanded]="false" (opened)="(null)" (closed)="(null)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title">
            {{ "COMMON.FILTERS" | translate }}
          </div>
          <div
            class="filter-indicator-container"
            *ngIf="filterIndicators$ | async as selectedFilters"
          >
            <span
              *ngFor="let filter of selectedFilters"
              class="kt-badge badge-info kt-badge--inline kt-badge--rounded"
            >
              {{ filter.label }}: {{ filter.value }}
            </span>
            <span *ngIf="selectedFilters.length">
              <button
                mat-icon-button
                class="cb-icon-button"
                (click)="resetAllFilters($event)"
                [matTooltip]="'COMMON.RESET_FILTERS' | translate"
              >
                <i class="fa-regular fa-filter-slash"></i>
              </button>
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="kt-form m-4">
        <div class="align-items-center row">
          <ng-container *ngFor="let filter of allFilters$ | async">
            <cb-filters-generator
              [filter]="filter"
              [control]="filterForm.controls[filter.field]"
              [ngClass]="filter.widthClass"
            >
            </cb-filters-generator>
          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
