<header matDialogTitle>
  <div class="text">
    <span class="title">
      <i class="fa-light fa-arrow-up-1-9"></i>
      <span>{{ "MANAGE_COLUMNS.TITLE" | translate }}</span>
    </span>
    <!-- <span *ngIf="tableName" class="tableName">({{ tableName }})</span>
    <div *ngIf="hasChanged$ | async">
      <i class="fas fa-exclamation-circle text-danger"></i>
    </div> -->
  </div>
  <div class="modal-actions">
    <cb-confirm-action-button
      [shouldConfirm]="showConfirmationButtons$ | async"
      (onClick)="close()"
      (onConfirm)="forceClose()"
      (onCancel)="cancelClose()"
      [confirmMessage]="'MANAGE_COLUMNS.UNSAVED_CHANGES' | translate"
      [confirmButtonText]="'MANAGE_COLUMNS.CLOSE' | translate"
      [cancelButtonText]="'MANAGE_COLUMNS.CANCEL_BUTTON' | translate"
      [buttonLabel]="'MANAGE_COLUMNS.CLOSE' | translate"
      [buttonIcon]="'fa-solid fa-circle-x'"
    ></cb-confirm-action-button>

    <button color="success" mat-flat-button (click)="save()" cdkFocusInitial>
      <i class="fa-floppy-disk fa-solid"></i>
      {{ "MANAGE_COLUMNS.SAVE" | translate }}
    </button>
  </div>
</header>
<div class="dialog-body" matDialogContent>
  <div class="columns-section" cdkDropListGroup>
    <div class="container">
      <div class="search-container">
        <div class="search">
          <div class="icon">
            <i class="fa fa-search"></i>
          </div>
          <input
            #searchInput
            [formControl]="searchControl"
            class="form-control"
            [placeholder]="'COMMON.SEARCH' | translate"
          />
          <button
            *ngIf="showClearSearchButton$ | async"
            mat-icon-button
            class="clear-icon cb-icon-button"
            (click)="clearSearch()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>

      <div
        *ngIf="remainingColumns$ | async as remainingColumns"
        cdkDropList
        id="remainingColumns"
        [cdkDropListData]="remainingColumns"
        class="list"
        cdkDropListSortingDisabled
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="box"
          *ngFor="let item of filteredRemainingColumns$ | async"
          cdkDrag
          [cdkDragData]="item"
        >
          <div class="text">
            <i cdkDragHandle class="fa-solid fa-grip-vertical"></i>
            {{ item["translatedColumnName"] | translate }}
          </div>
          <div class="action">
            <button mat-icon-button (click)="add(item)" class="cb-icon-button">
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container"
      *ngIf="displayedColumns$ | async as displayedColumns"
    >
      <div class="title">
        <div class="text">
          <span>{{ "MANAGE_COLUMNS.SECOND_COLUMN_TITLE" | translate }}</span>
          <span class="counter">{{ displayedColumns.length }}</span>
        </div>
        <div class="caption">
          {{ "MANAGE_COLUMNS.SECOND_COLUMN_CAPTION" | translate }}
        </div>
      </div>

      <div
        cdkDropList
        [cdkDropListData]="displayedColumns"
        class="list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="box"
          *ngFor="let item of displayedColumns; let index = index"
          cdkDrag
          [cdkDragData]="item"
        >
          <div class="text">
            <i cdkDragHandle class="fa-solid fa-grip-vertical"></i>
            {{ item["translatedColumnName"] | translate }}
          </div>
          <div class="action">
            <button
              mat-icon-button
              (click)="remove(item)"
              class="cb-icon-button"
            >
              <i class="fa-sharp fa-solid fa-delete-left"></i>
            </button>
          </div>
          <!-- <button mat-button (click)="remove(item)">remove</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
