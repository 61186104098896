import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { TranslateModule } from "@ngx-translate/core";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { CrudTableModule } from "../../views/partials/content/crud-table/crud-table.module";
import { CustomDropzoneModule } from "../../views/partials/content/custom-dropzone/custom-dropzone.module";
import { PartialsModule } from "../../views/partials/partials.module";
import { DynamicFormBuilderComponent } from "./components/dynamic-form-builder/dynamic-form-builder.component";
import { FORMS_COMPONENTS } from "./components/dynamic-form-builder/elements";
import { FieldBuilderComponent } from "./components/dynamic-form-builder/field-builder/field-builder.component";
import { DynamicComponentWidthPipe } from "./components/dynamic-form-builder/pipes/dynamic-component-width.pipe";
import { InputErrorsPipe } from "./components/dynamic-form-builder/pipes/inputErrors.pipe";
import { DynamicTabsBuilderComponent } from "./components/dynamic-tabs-builder/dynamic-tabs-builder.component";
import { TabContentCrudTableComponent } from "./components/dynamic-tabs-builder/elements/tab-content-crud-table/tab-content-crud-table.component";
import { TabContentFormComponent } from "./components/dynamic-tabs-builder/elements/tab-content-form/tab-content-form.component";
import { TabContentPortletComponent } from "./components/dynamic-tabs-builder/elements/tab-content-portlet/tab-content-portlet.component";
import { TabContentTabComponent } from "./components/dynamic-tabs-builder/elements/tab-content-tab/tab-content-tab.component";
import { TabContentTranslationComponent } from "./components/dynamic-tabs-builder/elements/tab-content-translation/tab-content-translation.component";
import { TabContentUploadComponent } from "./components/dynamic-tabs-builder/elements/tab-content-upload/tab-content-upload.component";
import { TabContentBuilderComponent } from "./components/dynamic-tabs-builder/tab-builder/tab-content-builder.component";
import { FormHeaderComponent } from "./components/form-header/form-header.component";
import { FormsShellComponent } from "./components/forms-shell/forms-shell.component";

import { NgxEchartsModule } from "ngx-echarts";
import { ContextMenuModule } from "../context-menu/context-menu.module";
import { MaterialModule } from "../material/material.module";
import { SharedModule } from "../shared/shared.module";
import { FormFieldVisibilityStatePipe } from "./components/dynamic-form-builder/pipes/form-field-visibility.pipe";
import { PluckPipe } from "./components/dynamic-form-builder/pipes/pluck.pipe";
import { TabErrorPipe } from "./components/dynamic-form-builder/pipes/tabError.pipe";
import { CbComponentDirective } from "./components/dynamic-tabs-builder/directives/cb-component.directive";
import { CollapsiblePortalComponent } from "./components/dynamic-tabs-builder/elements/collapsible-portal/collapsible-portal.component";
import { FormGenericCardComponent } from "./components/dynamic-tabs-builder/elements/tab-content-card/cards/generic-card.component";
import { FormImageCardComponent } from "./components/dynamic-tabs-builder/elements/tab-content-card/cards/image-card.component";
import { TabContentCardComponent } from "./components/dynamic-tabs-builder/elements/tab-content-card/tab-content-card.component";
import { TabContentChartComponent } from "./components/dynamic-tabs-builder/elements/tab-content-chart/tab-content-chart.component";
import { TabContentComponentComponent } from "./components/dynamic-tabs-builder/elements/tab-content-component/tab-content-component.component";
import { TabContentHtmlComponent } from "./components/dynamic-tabs-builder/elements/tab-content-html/tab-content-html.component";
import { LazyLoadComponent } from "./components/dynamic-tabs-builder/lazy-load-container.component";
import { FormsShellModalComponent } from "./components/forms-shell-modal/forms-shell-modal.component";
import { GenericModalComponent } from "./components/generic-modal/generic-modal.component";
import { ModalHeaderComponent } from "./components/modal-header/modal-header.component";
import { ProcessButtonComponent } from "./components/process-buttons/components/process-button/process-button.component";
import { ProcessButtonsComponent } from "./components/process-buttons/process-buttons.component";
import { WizardBuilderComponent } from "./components/wizard/wizard-builder/wizard-builder.component";
import { PIPES } from "./pipes";
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
  provideNgxMask,
} from "ngx-mask";
@NgModule({
  declarations: [
    FormsShellComponent,
    FormHeaderComponent,
    ...FORMS_COMPONENTS,
    DynamicFormBuilderComponent,
    FieldBuilderComponent,
    DynamicTabsBuilderComponent,
    TabContentBuilderComponent,
    TabContentFormComponent,
    TabContentTabComponent,
    TabContentPortletComponent,
    TabContentTranslationComponent,
    TabContentUploadComponent,
    TabContentCrudTableComponent,
    DynamicComponentWidthPipe,
    InputErrorsPipe,
    TabErrorPipe,
    PluckPipe,
    FormFieldVisibilityStatePipe,
    TabContentHtmlComponent,
    ProcessButtonsComponent,
    ProcessButtonComponent,
    CbComponentDirective,
    TabContentComponentComponent,
    TabContentChartComponent,
    LazyLoadComponent,
    WizardBuilderComponent,
    ...PIPES,
    GenericModalComponent,
    FormsShellModalComponent,
    ModalHeaderComponent,
    TabContentCardComponent,
    FormGenericCardComponent,
    FormImageCardComponent,
    CollapsiblePortalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    PartialsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    CKEditorModule,
    ColorPickerModule,
    CrudTableModule,
    CustomDropzoneModule,
    NgxMatTimepickerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    ContextMenuModule,
    SharedModule,
  ],
  providers: [provideNgxMask()],
  exports: [FormsShellComponent, FormsShellModalComponent],
})
export class CbFormsModule {}
