import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";

const SORT_FIELD = "text";

@Component({
  selector: "cb-table-column-config",
  templateUrl: "./table-column-config.component.html",
  styleUrls: ["./table-column-config.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnConfigComponent implements OnInit, OnDestroy {
  allColumns = [];
  tableName = "";
  remainingColumns$ = new BehaviorSubject([]);
  displayedColumns$ = new BehaviorSubject([]);
  filteredRemainingColumns$ = new BehaviorSubject([]);
  searchControl = new UntypedFormControl("");
  hasChanged$ = new BehaviorSubject(false);
  showConfirmationButtons$ = new BehaviorSubject(false);
  showClearSearchButton$ = new BehaviorSubject(false);
  destroyed$ = new Subject();
  constructor(
    public dialogRef: MatDialogRef<TableColumnConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tableName = data.tableName ?? null;
  }

  ngOnInit(): void {
    combineLatest([
      this.remainingColumns$,
      this.searchControl.valueChanges.pipe(startWith("")),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([remainingColumns, searchValue]: [any, any]) => {
        if (!searchValue) {
          this.showClearSearchButton$.next(false);
          this.filteredRemainingColumns$.next(remainingColumns);
          return;
        }
        this.showClearSearchButton$.next(true);
        this.filteredRemainingColumns$.next(
          remainingColumns
            .filter(
              (r) =>
                r.translate?.toLowerCase().includes(searchValue.toLowerCase()) ||
                r.text?.toLowerCase().includes(searchValue.toLowerCase()) ||
                r.name?.toLowerCase().includes(searchValue.toLowerCase())
            )
            .sort(this.sortByNames)
        );
      });

    this.allColumns = this.data.columns.sort(this.sortByNames) || [];
    this.remainingColumns$.next(this.allColumns.filter((r) => !r.visible));
    this.displayedColumns$.next(
      this.data.columns
        .filter((r) => r.visible)
        .sort((a, b) => a.order - b.order)
    );
  }

  clearSearch() {
    this.searchControl.setValue("");
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      if (event.previousIndex === event.currentIndex) return;
      this.hasChanged$.next(true);
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      this.hasChanged$.next(true);
      const item = event.item.data;
      const prevIndex = event.previousContainer.data.indexOf(item);
      console.log(event);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        prevIndex,
        event.currentIndex
      );
      // sort drop list data only when the item is dropped in the first list
      if (event.container.id === "remainingColumns") {
        event.container.data.sort(this.sortByNames);
      }
      this.searchControl.updateValueAndValidity();
      //   this.filteredRemainingColumns$.next(event.previousContainer.data);
    }
  }
  close() {
    if (this.hasChanged$.value) {
      this.showConfirmationButtons$.next(true);
      return;
    }
    this.dialogRef.close();
  }

  forceClose() {
    this.dialogRef.close();
  }
  cancelClose() {
    this.showConfirmationButtons$.next(false);
  }

  remove(item) {
    const arraySnapshot = [...this.displayedColumns$.value];
    const index = arraySnapshot.indexOf(item);
    if (index > -1) {
      this.hasChanged$.next(true);
      arraySnapshot.splice(index, 1);
      this.remainingColumns$.next(
        [...this.remainingColumns$.value, item].sort(this.sortByNames)
      );
      this.displayedColumns$.next(arraySnapshot);
    }
  }

  add(item) {
    const arraySnapshot = [...this.remainingColumns$.value];
    const index = arraySnapshot.indexOf(item);
    if (index > -1) {
      this.hasChanged$.next(true);
      arraySnapshot.splice(index, 1);
      this.remainingColumns$.next(arraySnapshot);
      this.displayedColumns$.next([...this.displayedColumns$.value, item]);
    }
  }

  save() {
    const displayedColumns = this.displayedColumns$.value;
    const newSortedColumns = this.allColumns
      .map((e) => {
        const found = displayedColumns.find((f) => f.name === e.name);
        if (found) {
          return {
            ...e,
            order: displayedColumns.indexOf(found),
          };
        }
        return { ...e, order: 1000 };
      })
      .sort((a, b) => a.order - b.order);
    this.allColumns = this.allColumns.map((e) => {
      const toReturn = { ...e };
      const found = displayedColumns.find((f) => f.name === e.name);
      if (found) {
        toReturn.visible = true;
        toReturn.order = displayedColumns.indexOf(found);
      } else {
        toReturn.visible = false;
      }
      return toReturn;
    });
    this.dialogRef.close({
      columns: displayedColumns.map((e) => e.name),
      columnOrder: newSortedColumns.map((e) => e.name),
      allColumns: this.allColumns,
    });
  }

  private sortByNames(a, b) {
    if (a?.[SORT_FIELD] < b?.[SORT_FIELD]) {
      return -1;
    }
    if (a?.[SORT_FIELD] > b?.[SORT_FIELD]) {
      return 1;
    }
    return 0;
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
