import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";

@Component({
  selector: "app-preview-subject",
  templateUrl: "./preview-subject.component.html",
  styleUrls: ["./preview-subject.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewSubjectComponent implements OnInit {
  @Input() subject: string;
  constructor() {}

  ngOnInit(): void {}
}
