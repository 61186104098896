<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="status == ConfirmActionDialogStatus.LOADING"
      ></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ data.title | translate }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <div class="form-group kt-form__group row">
        <div class="col-lg-12">{{ getMessage() | translate }}</div>
        <ng-container *ngIf="!!paramName">
          <div class="col-lg-12 mt-4">
            <div [ngSwitch]="paramType">
              <div *ngSwitchCase="paramTypes.INPUT">
                <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
              </div>
              <div *ngSwitchCase="paramTypes.NUMBER">
                <ng-container *ngTemplateOutlet="numberTemplate"></ng-container>
              </div>
              <div *ngSwitchCase="paramTypes.DATE">
                <ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
              </div>
              <div *ngSwitchCase="paramTypes.DATERANGE">
                <ng-container
                  *ngTemplateOutlet="dateRangeTemplate"
                ></ng-container>
              </div>
              <div *ngSwitchDefault></div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right"
    >
      <div class="kt-form__actions kt-form__actions--sm">
        <ng-container
          *ngIf="
            status == ConfirmActionDialogStatus.SUCCESS ||
              status == ConfirmActionDialogStatus.ERROR;
            else defaultBtns
          "
        >
          <button
            class="btn btn-outline-primary reset-button-styles success-outline-button"
            mat-raised-button
            (click)="dialogRef.close()"
          >
            <i class="la la-check"></i>
            {{ "COMMON.OK" | translate }}
          </button>
        </ng-container>

        <ng-template #defaultBtns>
          <button
            class="btn btn-outline-primary reset-button-styles danger-outline-button"
            mat-raised-button
            type="button"
            matToolTip="Cancel action"
            *ngIf="data.showBtnCancel"
            class="pull-left"
            (click)="onCancelClick()"
            align="left"
          >
            <i class="la la-close"></i>
            {{ "COMMON.CANCEL" | translate }}</button
          >&nbsp;
          <button
            class="btn btn-outline-primary reset-button-styles danger-outline-button"
            mat-raised-button
            *ngIf="!data.hideBtnNo"
            [disabled]="status == ConfirmActionDialogStatus.LOADING"
            (click)="onNoClick()"
          >
            <i class="la la-close"></i>
            {{ data.btnNoLabel || "COMMON.NO" | translate }}</button
          >&nbsp;
          <button
            class="btn btn-outline-primary reset-button-styles success-outline-button"
            mat-raised-button
            (click)="onYesClick()"            
            cdkFocusInitial
            [disabled]="isButtonDisabled()"
          >
            <i class="la la-check"></i>
            {{ data.btnYesLabel || "COMMON.YES" | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #numberTemplate>
  <mat-form-field class="mat-form-field-fluid">
    <mat-label>{{ paramDisplayName }}</mat-label>
    <input
      type="number"
      [ngModel]="value"
      (ngModelChange)="onChangeValue($event)"
      matInput
      autocomplete="do_not_fill"
      [placeholder]="paramDisplayName"
    />
  </mat-form-field>
</ng-template>
<ng-template #inputTemplate>
  <mat-form-field class="mat-form-field-fluid">
    <mat-label>{{ paramDisplayName }}</mat-label>
    <input
      [ngModel]="value"
      (ngModelChange)="onChangeValue($event)"
      matInput
      autocomplete="do_not_fill"
      [placeholder]="paramDisplayName"
    />
  </mat-form-field>
</ng-template>
<ng-template #dateTemplate>
  <mat-form-field>
    <mat-label>{{ paramDisplayName }}</mat-label>
    <input
      (dateChange)="onDateChangeHandler($event)"
      matInput
      autocomplete="do_not_fill"
      [matDatepicker]="picker"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</ng-template>
<ng-template #dateRangeTemplate>
  <mat-form-field>
    <mat-label>{{ paramDisplayName }}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input
        matStartDate
        (dateChange)="dateRangeChangeHandler($event, 'start')"
        placeholder="Start date"
      />
      <input
        matEndDate
        (dateChange)="dateRangeChangeHandler($event, 'end')"
        placeholder="End date"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</ng-template>
