<header class="p-16 modal-header" matDialogTitle>
  <button mat-icon-button [matMenuTriggerFor]="menu" class="cb-icon-button">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="newTemplateForm()"
      class="info-outline-button"
      *ngIf="!newTemplateMode"
    >
      <i class="la la-plus"></i>
      <span class="kt-hidden-mobile">
        {{ "SEND_EMAIL.CREATE_NEW_TEMPLATE" | translate }}
      </span>
    </button>

    <button
      mat-menu-item
      class="success-outline-button"
      (click)="saveTemplate()"
      *ngIf="!newTemplateMode && (currentTemplateDetailId$ | async) !== null"
    >
      <i class="la la-save"></i>
      <span class="kt-hidden-mobile">
        {{ "COMMON.SAVE" | translate }}
      </span>
    </button>
    <button
      mat-menu-item
      class="success-outline-button"
      (click)="saveTemplateAs()"
      *ngIf="(currentTemplateDetailId$ | async) !== null"
    >
      <i class="la la-share-square"></i>
      <span class="kt-hidden-mobile">
        {{ "SEND_EMAIL.SAVE_AS" | translate }}
      </span>
    </button>
  </mat-menu>
  <button
    mat-raised-button
    (click)="closeNewTemplateForm()"
    class="btn btn-outline-primary reset-button-styles danger-outline-button"
    *ngIf="newTemplateMode"
  >
    <i class="la la-close"></i>
    <span class="kt-hidden-mobile">
      {{ "SEND_EMAIL.CLOSE_NEW_TEMPLATE" | translate }}
    </span>
  </button>
  <!-- <span> {{ "SEND_EMAIL.TITLE" | translate }} </span> -->
  <div class="actions">
    <button
      mat-icon-button
      class="kt-subheader__btn-primary"
      (click)="closeDialog()"
    >
      <i class="fa fa-times"></i>
    </button>
  </div>
</header>
<mat-dialog-content class="mat-typography">
  <div class="wrapper">
    <div class="placeholder">
      <app-template-placeholders></app-template-placeholders>
    </div>
    <form class="forms" [formGroup]="selectorsForm">
      <div class="templates">
        <div class="preview-header">
          <div class="selector theme-selector">
            <custom-mat-select
              [name]="'theme-selector'"
              [control]="selectorsForm.controls['themeId']"
              [title]="'themes'"
              [placeholder]="'SEND_EMAIL.EMAIL_THEMES' | translate"
              (selectionChange)="emailThemesChangeHandler($event)"
              [required]="false"
              [selection]="currentThemeId"
              [disabled]="false"
              [tooltip]="null"
              [multiple]="false"
              [options]="emailThemes"
              [optionValue]="'id'"
              [optionLabel]="'name'"
              [allowClear]="false"
              [customError]="null"
              matFormFieldClass="mat-form-field-fluid"
            >
            </custom-mat-select>
          </div>
          <div class="selector template-selector">
            <custom-mat-select
              *ngIf="!newTemplateMode"
              [name]="'template-selector'"
              [control]="selectorsForm.controls['templateId']"
              [title]="'templates'"
              [placeholder]="'SEND_EMAIL.EMAIL_TEMPLATES' | translate"
              (selectionChange)="emailTemplatesChangeHandler($event)"
              [required]="false"
              [disabled]="false"
              [selection]="currentTemplateId"
              [tooltip]="null"
              [multiple]="false"
              [options]="emailTemplates$ | async"
              [optionValue]="'id'"
              [optionLabel]="'name'"
              [customError]="null"
              matFormFieldClass="mat-form-field-fluid"
            >
            </custom-mat-select>
          </div>
        </div>
        <app-template-extra-information
          *ngIf="(hasTemplateExtraInfo$ | async) === true"
        ></app-template-extra-information>
        <app-template-details
          (templateFormStatusEvent)="templateFormStatusEventHandler($event)"
        ></app-template-details>
      </div>
    </form>
    <div class="recipients">
      <app-recipient-details></app-recipient-details>
    </div>
  </div>
</mat-dialog-content>
