import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatSelectionListChange } from "@angular/material/list";
import { get } from "lodash";

@Component({
  selector: "btn-dropdown-select",
  templateUrl: "./btn-dropdown-select.component.html",
  styleUrls: ["./btn-dropdown-select.component.scss"],
})
export class BtnDropdownselectComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() options: any[] = [];
  @Input() value: string;
  @Input() label: string;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() cache: string;
  @Input() selection = [];
  @Input() translations: any = {};
  @Input() lang: string = "en";
  ngOnInit() {
    if (this.cache) {
      this.selection = JSON.parse(
        localStorage.getItem("BtnDropdownselect-" + this.cache)
      );
      if (this.selection) {
        this.onSelect.emit(this.selection);
      } else if (this.options && this.options.length > 0) {
        this.selection = this.options.map((r) => r[this.value]);
        this.onSelect.emit(this.selection);
      }
    }
  }

  ngOnChanges({ options, selection }: SimpleChanges) {
    if (options) {
      this.options =
        options.currentValue.slice().sort((a, b) => {
          if (a[this.label] < b[this.label]) {
            return -1;
          }
          if (a[this.label] > b[this.label]) {
            return 1;
          }
          return 0;
        }) || [];

      // by default, all columns are selected
      if (!this.selection && this.options && this.options.length > 0) {
        this.selection = this.options
          .filter((r) => r.visible)
          .map((r) => r[this.value]);
        this.onSelect.emit(this.selection);
      }
    }

    if (selection && selection.currentValue)
      this.selection = selection.currentValue || [];
  }

  selectionChange(event: MatSelectionListChange) {
    this.selection = event.source.selectedOptions.selected.map((r) => r.value);
    // this.onSelect.emit(this.selection);
    this.onSelect.emit(this.selection);
  }

  translate(key) {
    const path = this.lang + "." + key;
    return get(this.translations, path) || key;
  }

  isSelected(optionValue) {
    if (this.selection == null || this.selection.length == 0) return true;
    return this.selection.find((r) => r == optionValue) != null;
  }
}
