import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TimePickerConfig } from "../models/timepicker.config";

@Component({
  selector: "cb-timePicker",
  template: `
    <div [formGroup]="form" *ngIf="!!config">
      <mat-form-field class="mat-form-field-fluid" appearance="fill">
        <mat-label>{{ config.placeholder }}</mat-label>
        <input
          [formControlName]="config.formControlName"
          format="24"
          matInput
          [ngxMatTimepicker]="pickerA"
          [placeholder]="config.placeholder"
          readonly
        />
        <mat-icon matSuffix (click)="pickerA.open()"> watch_later </mat-icon>
        <mat-error
          *ngIf="form.controls[this.config.formControlName].hasError"
          [innerHtml]="
            form.controls[this.config.formControlName].errors
              | inputErrors : field
          "
        >
        </mat-error>
      </mat-form-field>
      <ngx-mat-timepicker
        appendToInput="false"
        #pickerA
        color="accent"
      ></ngx-mat-timepicker>
    </div>
  `,
  styles: [":host {display: block; width: 100%}"],
})
export class CbTimePickerComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() config: TimePickerConfig;
  constructor() {}

  ngOnInit() {}
}
