<!-- begin:: Footer -->
<div
  class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
  id="kt_footer"
>
  <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
    <div class="kt-footer__copyright" id="pgmware-{{version.shortrelease}}">
      {{ today | date : "yyyy" }}&nbsp;&copy;&nbsp;<a
        href="https://www.circuit-booking.com"
        target="_blank"
        class="kt-link"
        >Booking Management System - Powered by HTA Circuit Booking GmbH - v{{
          version.version
        }}</a
      >
    </div>
    <div class="d-flex align-items-center">
      <!-- <ng-container *ngIf="pageInfoUrl$ | async">
        <div class="px-3 d-inline-block">
          <html-content
            *ngIf="pageInfoUrl$ | async"
            [apiUrl]="pageInfoUrl$ | async"
          ></html-content>
        </div>
      </ng-container> -->

      <ng-container *ngIf="subheaderService.staticInfo$ | async">
        <button mat-button [matMenuTriggerFor]="menu">
          {{ "COMMON.FOOTER_INFO_BUTTON" | translate }}
        </button>
        <mat-menu #menu="matMenu" class="large-menu">
          <div
            class="d-flex flex-column p-4"
            [innerHtml]="subheaderService.staticInfo$ | async | safe"
          ></div>
        </mat-menu>
      </ng-container>
      <div #scriptContainer style="height: 0; width: 0; visibility: none"></div>
      <div class="px-3 d-inline-block">
        <app-ws-status></app-ws-status>
      </div>
    </div>
  </div>
</div>
<!-- end:: Footer -->
