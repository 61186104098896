<kt-portlet>
    <!-- PORTLET LOADING | Binded to TABLE Datasource -->

    <!-- start::Body (attribute: ktPortletBody) -->
    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="kt-form kt-margin-b-30">
           
            <!-- start::GROUP ACTIONS -->
            <!-- Group actions list: 'Delete selected' | 'Select' | 'Update status for selected' -->
            <!-- Group actions are shared for all LISTS -->
            <div class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
                [ngClass]="{'show' : selection.selected.length > 0}">
                <!-- We show 'Group Actions' div if smth are selected -->
                <div class="col-xl-12">
                    <div class="kt-form__group kt-form__group--inline">
                        <div class="kt-form__label kt-form__label-no-wrap">
                            <label class="kt--font-bold kt-font-danger-">
                                <span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT">:</span>
                                {{ selection.selected.length }}
                            </label>
                            <!-- selectedCountsTitle => function from codeBehind (users-list.component.ts file) -->
                            <!-- selectedCountsTitle => just returns title of selected items count -->
                            <!-- for example: Selected records count: 4 -->
                        </div>
                        <!-- <div class="kt-form__control kt-form__group--inline">
                            <button (click)="fetchUsers()" mat-raised-button matTooltip="Select users"
                                class="mat-button-mt-4 danger-button-inverse">
                                <mat-icon>clear_all</mat-icon>
                                Select
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- end::GROUP ACTIONS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table__wrapper">
            <mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
                matSortActive="id" matSortDirection="asc" matSortDisableClear multiTemplateDataRows>
                <!-- Checkbox Column -->

                <!-- Table with selection -->
                <!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
                        <mat-checkbox class="custom-checkbox" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-checkbox">
                        <mat-checkbox class="custom-checkbox" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="value">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell class="table-header-cell" *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
                    <mat-cell class="table-content-cell" *matCellDef="let variant">{{variant.value}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="label">
                    <mat-header-cell class="table-header-cell" *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
                    <mat-cell class="table-content-cell" *matCellDef="let variant">{{variant.label}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="length">
                    <mat-header-cell class="table-header-cell" *matHeaderCellDef mat-sort-header>Length
                    </mat-header-cell>
                    <mat-cell class="table-content-cell" *matCellDef="let variant">{{variant.length}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="fia_grade_valid_before_date">
                    <mat-header-cell class="table-header-cell" *matHeaderCellDef mat-sort-header>Fia Grade Valid Until
                        Date</mat-header-cell>
                    <mat-cell class="table-content-cell" *matCellDef="let variant">
                        {{variant.fia_grade_valid_before_date}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="bookable">
                    <mat-header-cell class="table-header-cell" *matHeaderCellDef mat-sort-header>Bookable
                    </mat-header-cell>
                    <mat-cell class="table-content-cell" *matCellDef="let variant">{{variant.bookable ? 'Yes' : 'No'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="grade_id">
                    <mat-header-cell class="table-header-cell" *matHeaderCellDef mat-sort-header>Grade</mat-header-cell>
                    <mat-cell class="table-content-cell" *matCellDef="let variant">{{variant.grade_id}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell class="table-header-cell" *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let variant">
                        <button mat-icon-button matTooltip="Show Variant" class="menu-icon">
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element; let i = index;" [attr.colspan]="displayedColumns.length"
                        class="w-100 py-5">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                            <div class="row">
                                <div class="col-lg-7">
                                    <div class="kt-form__section kt-form__section--first">
                                        <div class="form-group kt-form__group row">
                                            <div class="col-lg-6 mb-3 kt-margin-bottom-20-mobile custom-field-holder">
                                                <mat-form-field
                                                    class="mat-form-field-fluid custom-input mat-form-field-plain-text">
                                                    <input matInput placeholder="Name" readonly
                                                        [(ngModel)]="element.name" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-6 mb-3 kt-margin-bottom-20-mobile custom-field-holder">
                                                <mat-form-field
                                                    class="mat-form-field-fluid custom-input mat-form-field-plain-text">
                                                    <input matInput placeholder="Length" readonly
                                                        [(ngModel)]="element.length" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-4 mb-3 kt-margin-bottom-20-mobile custom-field-holder">
                                                <mat-form-field
                                                    class="mat-form-field-fluid custom-input mat-form-field-plain-text">
                                                    <input matInput placeholder="Date" readonly
                                                        [(ngModel)]="element.fia_grade_valid_before_date" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-4 mb-3 kt-margin-bottom-20-mobile custom-field-holder">
                                                <mat-form-field
                                                    class="mat-form-field-fluid custom-input mat-form-field-plain-text">
                                                    <input matInput placeholder="Bookable" readonly
                                                        [(ngModel)]="element.bookable" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-4 mb-3 kt-margin-bottom-20-mobile custom-field-holder">
                                                <mat-form-field
                                                    class="mat-form-field-fluid custom-input mat-form-field-plain-text">
                                                    <input matInput placeholder="Grade" readonly
                                                        [(ngModel)]="element.grade_id" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-12 mb-3 kt-margin-bottom-20-mobile custom-field-holder">
                                                <mat-form-field
                                                    class=" mat-form-field-fluid custom-input mat-form-field-plain-text">
                                                    <textarea matInput placeholder="Description" readonly
                                                        [(ngModel)]="element.description"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">

                                    <div class="d-flex justify-content-between">

                                        <h5 class="" style="margin-top: 10px; margin-right: 5px;">Laptime List</h5>

                                        <button type="button" mat-icon-button (click)="addLaptime(element)"
                                            matTooltip="Add variant laptime">
                                            <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
                                        </button>

                                        <!-- <button type="button" (click)="addLaptime(item)" mat-icon-button
                                            class="custom-icon-button"
                                            matTooltip="Add variant laptime">
                                            <mat-icon>add</mat-icon>
                                        </button> -->

                                        <!-- <button type="button" mat-raised-button matTooltip="Add laptime"
                                            (click)="addLaptime(element)" class="ml-2 danger-button">
                                            Add
                                        </button> -->
                                    </div>

                                    <div class="mt-4" style="height: 290px;overflow: auto;">

                                        <ul class="list-group">
                                            <li class="list-group-item" *ngFor="let item of element.laptimes">
                                                {{ item.laptime }}

                                                <span class="pl-4">{{ item.description }}</span>

                                                <button mat-icon-button color="danger" class="position-absolute"
                                                    matTooltip="Delete item" type="button" (click)="deleteLaptime(item)"
                                                    style="right: 15px; top: 0;">
                                                    <mat-icon>delete</mat-icon>
                                                </button>

                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let element; columns: displayedColumns;"
                    [class.example-expanded-row]="expandedElement === element" (click)="showVariant(element)"
                    class="example-element-row" class="w-100">
                </mat-row>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                    [ngClass]="{'example-detail-row': expandedElement !== row, 'example-detail-row-with-detail': expandedElement === row}">
                </tr>
            </mat-table>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table__bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="loading$ | async"></mat-spinner>
            <!-- <cb-loading [diameter]="20"></cb-loading> -->
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [length]="paginatorTotal$ | async"
                [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->
</kt-portlet>
