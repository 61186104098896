<!-- <mat-form-field class="btn-dropdown-select-wrap">
  <mat-select
    #matSelect
    multiple
    [ngModel]="selection"
    (ngModelChange)="(selection)"
    (selectionChange)="selectionChange($event)"
  >
    <mat-option *ngFor="let option of options" [value]="option[value]">
      {{ translate(option[label]) }}</mat-option
    >
  </mat-select>
</mat-form-field> -->

<button
  mat-icon-button
  type="button"
  [matMenuTriggerFor]="appMenu"
  matTooltip="{{ title || '' }}"
>
  <ng-content></ng-content>
</button>

<mat-menu #appMenu="matMenu">
  <mat-selection-list (selectionChange)="selectionChange($event)">
    <mat-list-option
      *ngFor="let option of options"
      [value]="option[value]"
      [selected]="isSelected(option[value])"
      (click)="$event.stopPropagation()"
    >
      {{ translate(option[label]) }}
    </mat-list-option>

    <!-- <mat-list-option
      checkboxPosition="start"
      [selected]="tryHarder"
    >
      Enable Try-harder
    </mat-list-option>
    <mat-list-option
      checkboxPosition="start"
      (click)="toggleTorch()"
      [selected]="torchEnabled"
      *ngIf="torchAvailable$ | async"
    >
      Enable Torch
    </mat-list-option> -->
  </mat-selection-list>
  <!-- <mat-divider></mat-divider>
  <button mat-menu-item (click)="openFormatsDialog()">
    Barcode formats
  </button> -->
</mat-menu>

<!-- 
<button
  mat-raised-button
  matTooltip="{{ title || '' }}"
  class="custom-icon-button add-icon"
  type="button"
  (click)="matSelect.open()"
  style="margin: 0 10px"
>
  <ng-content></ng-content>
</button> -->
