// Angular
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";

const exportKey = "EXPORT_COLLECTION";
@Component({
  selector: "kt-export-entity-dialog",
  templateUrl: "./export-entity-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportEntityDialogComponent implements OnInit {
  // Public properties
  downloading$ = new BehaviorSubject<boolean>(false);

  visibleColumns: string[] = [];
  exportOnlyFiltered = true;
  exportType = "csv";
  exportPaperOrientation = "Landscape";

  selectionIndeter: boolean;
  selectionAll: boolean = true;

  exportTypeOptions = { csv: "CSV", excel: "Excel", pdf: "PDF" };

  /**
   * Component constructor
   *
   * @param dialogRef: MatDialogRef<ExportEntityDialogComponent>
   * @param data: any
   */
  constructor(
    public dialogRef: MatDialogRef<ExportEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.cacheName) {
      const cachedSelection = this.getVisibleColummnsCache();
      if (cachedSelection && cachedSelection.length > 0) {
        this.visibleColumns = cachedSelection;
        return;
      }
    }

    //this.toggleAll();
  }

  ngOnInit() {
    this.initLocalStorageCollection();
    this.updateSelectAll();
  }

  /**
   * Close dialog with false result
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  updateMasterToggle() {
    const selectionCount = this.data.columns.filter((r) =>
      this.visibleColumns.find((s) => s == r.name)
    ).length;
    this.selectionIndeter =
      selectionCount > 0 && selectionCount < this.data.columns.length;
    this.selectionAll = selectionCount == this.data.columns.length;
  }

  toggleAll() {
    if (this.selectionAll) {
      this.resetEntity(true);
    } else {
      this.resetEntity(false);
    }
  }

  isSelected(column: any) {
    const normalized = column.title.replace(" ", "_");
    return this.hasKeyInEntity(normalized);
  }

  download() {
    //this.setVisibleColummnsCache();

    this.downloading$.next(true);
    this.data.tableConfigService
      .getTableExportConfig(
        this.exportType,
        this.data.name,
        this.data.queryParams,
        this.visibleColumns,
        this.exportOnlyFiltered,
        this.exportPaperOrientation
      )
      .subscribe((link) => {
        this.downloading$.next(false);
        window.open(link.redirect.url, link.redirect.target);
        this.dialogRef.close(true);
      });
  }

  // setVisibleColummnsCache() {
  // 	localStorage.setItem('export-entity-dialog-' + this.data.cacheName, JSON.stringify(this.visibleColumns));
  // }

  getVisibleColummnsCache() {
    return JSON.parse(
      localStorage.getItem("export-entity-dialog-" + this.data.cacheName)
    );
  }

  toggleSelected(column) {
    this.updateCollectionByEntity(column.title.replace(" ", "_"));
    this.updateSelectAll();
  }

  updateSelectAll() {
    let collection = this.getLocalStorageExportCollection();
    if (collection[this.getEntityName()].length !== this.data.columns.length) {
      this.selectionAll = false;
    } else {
      this.selectionAll = true;
    }
  }

  initLocalStorageCollection() {
    let collection = this.getLocalStorageExportCollection();
    if (collection == null) {
      const columns = this.data.columns;
      const normalizedColumns = columns.map((e) => {
        return e.title.replace(" ", "_");
      });
      collection = {
        [this.getEntityName()]: normalizedColumns,
      };
      this.setLocalStorageExportCollection(collection);
    }
  }

  resetEntity(withColumns = true) {
    let collection = this.getLocalStorageExportCollection();
    if (withColumns === true) {
      const columns = this.data.columns;
      const normalizedColumns = columns.map((e) => {
        return e.title.replace(" ", "_");
      });
      collection = {
        [this.getEntityName()]: normalizedColumns,
      };
    } else {
      collection = {
        [this.getEntityName()]: [],
      };
    }
    this.setLocalStorageExportCollection(collection);
  }

  getEntityName() {
    return this.data.cacheName.toLocaleLowerCase().replace(" ", "_");
  }

  getLocalStorageExportCollection() {
    let collection = null;
    if (!!localStorage.getItem(exportKey)) {
      collection = JSON.parse(localStorage.getItem(exportKey));
    }
    return collection;
  }

  setLocalStorageExportCollection(collection) {
    localStorage.setItem(exportKey, JSON.stringify(collection));
  }

  updateCollectionByEntity(key) {
    const collection = this.getLocalStorageExportCollection();
    if (!this.hasKeyInEntity(key)) {
      collection[this.getEntityName()] = [
        ...collection[this.getEntityName()],
        key,
      ];
    } else {
      collection[this.getEntityName()] = collection[
        this.getEntityName()
      ].filter((e) => e != key);
    }
    this.setLocalStorageExportCollection(collection);
  }

  hasKeyInEntity(key) {
    const collection = this.getLocalStorageExportCollection();

    if (collection == null) {
      return false;
    }
    if (collection[this.getEntityName()] == null) {
      return false;
    }
    return collection[this.getEntityName()].includes(key);
  }
}
