import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { Subject } from "rxjs";
import { FilterService } from "../../services/filter.service";
import { takeUntil } from "rxjs/operators";

// this is the `filterrange` equivalent on the server
// this is not a date range, but some a component with 2 inputs
// one start and one end date
// the component name is bad, but for now should suffice
@Component({
  template: `
    <div class="row" [formGroup]="form">
      <div class="col-6">
        <mat-form-field
          class="mat-form-field-fluid"
          floatLabel="never"
          appearance="fill"
        >
          <input
            matInput
            [matDatepicker]="fromPicker"
            autocomplete="do_not_fill"
            placeholder="{{ 'COMMON.ANY_DATE' | translate }}"
            formControlName="from"
          />
          <span matTextPrefix>
            {{ filter.title }}
            {{ "COMMON.FROM" | translate }}: &nbsp;</span
          >
          <button
            mat-icon-button
            matSuffix
            *ngIf="from.value"
            (click)="reset('from')"
          >
            <mat-icon> close</mat-icon>
          </button>
          <mat-datepicker-toggle matSuffix [for]="fromPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field
          class="mat-form-field-fluid"
          floatLabel="never"
          appearance="fill"
        >
          <input
            matInput
            [matDatepicker]="toPicker"
            autocomplete="off"
            placeholder="{{ 'COMMON.ANY_DATE' | translate }}"
            formControlName="to"
          />
          <span matTextPrefix>
            {{ filter.title }}
            {{ "COMMON.TO" | translate }}: &nbsp;</span
          >

          <button
            mat-icon-button
            matSuffix
            *ngIf="to.value"
            (click)="reset('to')"
          >
            <mat-icon>close</mat-icon>
          </button>

          <mat-datepicker-toggle matSuffix [for]="toPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  `,
})
export class FilterRangeComponent implements OnInit, OnDestroy {
  @Input() filter: any;
  @Input() control: FormControl;
  destroyed$ = new Subject<void>();
  constructor(
    private fb: UntypedFormBuilder,
    private filterService: FilterService // private crudShellService: CrudShellService
  ) {}

  form: UntypedFormGroup;
  ngOnInit() {
    this.subscribeToReset();
    this.form = this.fb.group({
      from: [this.filter?.value?.value?.from ?? null],
      to: [this.filter?.value?.value?.to ?? null],
    });

    this.form.valueChanges.subscribe((value) => {
      let label = this.filterService.formatDateRangeForServer(
        value.from,
        value.to
      );
      this.filterService.setAppliedFilters({
        component: this.filter,
        value: value,
        label: label,
      });
      this.control.setValue(label);
    });
  }

  reset(field: string) {
    this.form.get(field).setValue(null);
  }

  get from() {
    return this.form.get("from");
  }

  get to() {
    return this.form.get("to");
  }

  subscribeToReset() {
    this.filterService.resetFiltersSignal$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.form.reset({}, { emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
