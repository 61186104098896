// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../../core/store/reducers/app.reducers';
import {ApiConfig} from "../../../../../core/config/api.config";
import {Validators} from "@angular/forms";
// Auth

@Component({
	selector: 'payments-form',
	templateUrl: './payments-form.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsFormComponent implements OnInit {
  apiUrl: string;
  relatedUrl: string;
	formConfig = [
		{
			name: 'id',
			title: 'ID',
			width: 4,
			validator: Validators.required,
		}
	];
  formData = {};

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param router: Router
	 */
	constructor(private store: Store<AppState>, private router: Router) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.apiUrl = ApiConfig.PaymentUrl();

	}
}
