import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormConfigService } from "../../../../form.config.service";
import { BehaviorSubject, Subject, combineLatest, of } from "rxjs";
import {
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { CbComponentDirective } from "../../directives/cb-component.directive";
import { FormGenericCardComponent } from "./cards/generic-card.component";
import { get } from "lodash";
import { FormImageCardComponent } from "./cards/image-card.component";
export type PortletCardContentType = {
  card: {
    body: any;
    header: any;
    image?: {
      field?: string;
      default?: string;
    };
    api_url?: string;
    type: "generic" | "image";
  };
  container_settings: any;
};

@Component({
  selector: "cb-tab-content-card",
  templateUrl: "./tab-content-card.component.html",
  styleUrls: ["./tab-content-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentCardComponent implements OnInit, OnDestroy {
  @ViewChild(CbComponentDirective, { static: true })
  cbComponentDirective!: CbComponentDirective;
  @Input() content: PortletCardContentType;
  @Input() componentIdentifier: number;
  @Output() onLoadingChangeEvent = new EventEmitter<{
    status: boolean;
    componentIdentifier: number;
  }>();
  containerSettings$ = new BehaviorSubject({});
  hasRemoteApiUrl$ = new BehaviorSubject("");
  isLoadingRemoteData$ = new BehaviorSubject(false);
  destroyed$ = new Subject();
  constructor(private formConfigService: FormConfigService) {}

  sampleSnapshot = {};

  ngOnInit(): void {
    combineLatest([of(this.content), of(this.formConfigService.formData)])
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(([content]) => {
          this.containerSettings$.next({
            ...content.container_settings,
          });
        })
      )
      .subscribe(([content, formData]) => {
        if (content.card.api_url) {
          this.hasRemoteApiUrl$.next(content.card.api_url);
          this.sampleSnapshot = formData;
          return;
        }
        this.generateCard(content.card, formData);
      });

    combineLatest([this.hasRemoteApiUrl$, of(this.content)])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([apiUrl, _]) => {
          return apiUrl !== "";
        }),
        tap(() => {
          this.isLoadingRemoteData$.next(true);
          this.onLoadingChangeEvent.emit({
            status: true,
            componentIdentifier: this.componentIdentifier,
          });
        }),
        switchMap(([apiUrl, content]) => {
          return this.formConfigService.getDataFromUrl(apiUrl).pipe(
            finalize(() => {
              this.isLoadingRemoteData$.next(false);
              this.onLoadingChangeEvent.emit({
                status: false,
                componentIdentifier: this.componentIdentifier,
              });
            }),
            map((res) => {
              this.generateCard(content.card, res);
            })
          );
        })
      )
      .subscribe();
  }

  private generateCard(cardContent, cardData) {
    if (cardContent.type === "generic") {
      this.generateGenericCard(cardContent, cardData);
    }

    if (cardContent.type === "image") {
      this.generateImageCard(cardContent, cardData);
    }
  }

  generateImageCard(
    cardContent: {
      body: any;
      header: any;
      image?: { field?: string; default?: string };
      type: "generic" | "image";
    },
    formData: any
  ) {
    const _viewContainerRef = this.cbComponentDirective.viewContainerRef;
    _viewContainerRef.clear();
    const component = _viewContainerRef.createComponent(FormImageCardComponent);
    component.instance.data = {
      header: { ...this.parseCardHeader(cardContent.header, formData) },
      image: this.parseImage(cardContent.image, formData),
      imageCustomCss: this.parseImageCss(cardContent.image),
    };
  }

  generateGenericCard(cardContent: any, data: any) {
    const _viewContainerRef = this.cbComponentDirective.viewContainerRef;
    _viewContainerRef.clear();
    const component = _viewContainerRef.createComponent(
      FormGenericCardComponent
    );
    component.instance.data = {
      header: { ...this.parseCardHeader(cardContent.header, data) },
      image: this.parseImage(cardContent.image, data),
      imageCustomCss: this.parseImageCss(cardContent.image),
      body: this.parseCardBody(cardContent.body, data),
    };
  }

  private parseCardBody(body: any, data: any) {
    return body.map((item) => {
      return {
        label: item?.title ?? "",
        // value: this.extractDataFromFormData(data, item?.name),
        value: this.parseBodyValue(item, data),
        width: this.getItemWidth(item),
        isHtml: item?.is_html ?? false,
      };
    });
  }

  private parseBodyValue(item: any, data: any) {
    if (item?.format_data) {
      return this.formConfigService.parsePlaceholders(
        item.format_data,
        false,
        data
      );
    }

    return this.extractDataFromFormData(data, item?.name);
  }

  private getItemWidth(item) {
    if (item.width) {
      return `col-md-${item.width}`;
    }

    return "col-md-12";
  }

  private extractDataFromFormData(data: any, field: string) {
    // const res = get(data, this.formConfigService.parsePlaceholders(field));
    return get(data, this.formConfigService.parsePlaceholders(field)) ?? "";
  }

  private parseImage(imageConfig, data) {
    if (!imageConfig) return null;
    if (
      imageConfig?.field !== undefined ||
      imageConfig?.field !== null ||
      imageConfig?.field !== ""
    ) {
      const imageUrl = this.extractDataFromFormData(data, imageConfig?.field);
      if (imageUrl) {
        return imageUrl;
      }
    }
    return this.formConfigService.parsePlaceholders(imageConfig?.default) ?? "";
  }

  private parseCardHeader(headerConfig, formData) {
    if (!headerConfig) {
      return {
        title: { label: "", value: "" },
        subtitle: { label: "", value: "" },
      };
    }
    return {
      title: {
        label: headerConfig?.title?.name,
        value: headerConfig?.title.format_data
          ? this.formConfigService.parsePlaceholders(
              headerConfig.title.format_data,
              false,
              formData
            )
          : this.extractDataFromFormData(
              formData,
              headerConfig?.title?.name ?? ""
            ),
      },
      subtitle: {
        label: headerConfig?.subtitle?.name ?? "",
        value: headerConfig?.subtitle?.format_data
          ? this.formConfigService.parsePlaceholders(
              headerConfig.subtitle.format_data,
              false,
              formData
            )
          : this.extractDataFromFormData(
              formData,
              headerConfig?.subtitle?.name ?? ""
            ),
      },
    };
  }

  parseImageCss(imageConfig) {
    if (!imageConfig) return null;
    return imageConfig?.css ?? "";
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
