<div>
  <ng-container *ngIf="!showForm; else form">
    <crud-table
      [title]="title | translate"
      name="dynamic-prices-trackday-items"
      relatedId="trackday_items_id"
      [apiUrl]="apiUrl"
      baseUrl="/trackdays-management/dynamic-prices-trackday-events"
      [isSubForm]="true"
      (onCreate)="onCreate()"
      (onEdit)="onEdit($event)"
      (onLoadTableList)="loadChartData()"
    ></crud-table>
  </ng-container>

  <ng-template #form>
    <crud-form
      [title]="title | translate"
      name="dynamic-prices-trackday-items"
      [apiUrl]="apiUrl"
      [formConfig]="formConfig"
      listUrl="/trackdays-management/dynamic-prices-trackday-events"
      [initialData]="initialFormData"
      [isSubForm]="true"
      (onExit)="showForm = false"
    ></crud-form>
  </ng-template>

  <ng-container *ngIf="!showForm">
    <kt-portlet>
      <kt-portlet-header [class]="'kt-portlet__head--lg'">
        <ng-container ktPortletTitle>
          <h2 class="kt-portlet__head-title table-subtitle">
            <span>{{
              "CHART.TRACKDAY_ITEM.DYNAMIC_PRICES.TITLE" | translate
            }}</span>
          </h2>
        </ng-container>
      </kt-portlet-header>

      <kt-portlet-body>
        <div class="p-5">
          <ul class="list-inline">
            <li class="list-inline-item mr-4">
              <span>{{ "CHART.TRACKDAY_ITEM.START_DATE" | translate }}</span
              >: {{ trackdayItem.date_start | localDate: "date" }}
            </li>
            <li class="list-inline-item mr-4">
              <span>{{ "CHART.TRACKDAY_ITEM.END_DATE" | translate }}</span
              >: {{ trackdayItem.date_end | localDate: "date" }}
            </li>
            <li class="list-inline-item mr-4">
              <span>{{ "CHART.TRACKDAY_ITEM.ACTIVE_UNTIL" | translate }}</span
              >: {{ trackdayItem.datetime_active_until | localDate: "date" }}
            </li>
            <li class="list-inline-item mr-4">
              <span>{{ "CHART.TRACKDAY_ITEM.PAYMENT_TARGET" | translate }}</span
              >: {{ trackdayItem.datetime_payment_target | localDate: "date" }}
            </li>
          </ul>
        </div>

        <!-- <highcharts-chart
          id="container"
          [Highcharts]="Highcharts"
          [constructorType]="chartConstructor"
          [options]="chartOptions"
          [callbackFunction]="chartCallback"
          [(update)]="updateFromInput"
          [oneToOne]="true"
          style="margin: 0; width: 100%; height: 400px; display: block"
        >
        </highcharts-chart> -->
        <div>
          <div echarts [merge]="mergeOptions" [options]="chartOption" class="demo-chart"></div>
        </div>
      </kt-portlet-body>
    </kt-portlet>
  </ng-container>
</div>
