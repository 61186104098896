// RxJS
import { of, Subscription } from "rxjs";
import {
    catchError,
    finalize,
    tap,
    debounceTime,
    delay,
    distinctUntilChanged,
} from "rxjs/operators";
// NGRX
import { Store, select } from "@ngrx/store";
// CRUD
import { BaseDataSource } from "../../../../core/store/data-sources/base.datasource";
import { QueryResultsModel } from "../../../../core/base/models";
// State
import { AppState } from "../../../../core/store/reducers/app.reducers";
import {
    selectItemsInStore,
    selectPageLoading,
    selectItemsShowInitWaitingMessage,
    CrudTableStore,
} from "../../../../core/store";

export class CrudTableDataSource extends BaseDataSource {
    scopeLoading = false;

    constructor(
        private store: Store<AppState>,
        private scoped: boolean = false
    ) {
        super();

        this.loading$ = this.store.pipe(select(selectPageLoading));

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectItemsShowInitWaitingMessage)
        );

        this.store
            .pipe(select(selectItemsInStore))
            .subscribe((response: QueryResultsModel) => {
                // fix issue: multiple crud-table in 1 page, triggers all subscription
                if (this.scoped && !this.scopeLoading) return;

                this.scopeLoading = false;
                this.paginatorTotalSubject.next(response.totalCount);
                this.entitySubject.next(response.items);
            });
    }
}

export class CrudTableDataSource2 extends BaseDataSource {
    scopeLoading = false;
    pageLoadedSubs: Subscription;

    actionLoading$: any;

    constructor(
        private store: CrudTableStore
    ) {
        super();

        this.actionLoading$ = this.store.select((state) => state.actionsloading);
        this.loading$ = this.store.select((state) => state.listLoading);
        this.isPreloadTextViewed$ = this.store.select(
            (state) => state.showInitWaitingMessage
        );

        // this.pageLoadedSubs = this.store.pageLoaded$.subscribe((response: QueryResultsModel) => {
        //     console.log("store.select.state.entities", response);

        //     // fix issue: multiple crud-table in 1 page, triggers all subscription
        //     //if (this.scoped && !this.scopeLoading) return;

        //     this.scopeLoading = false;
        //     this.paginatorTotalSubject.next(response.totalCount);
        //     this.entitySubject.next(response.items);
        // });
    }

    disconnect() {
        if (this.pageLoadedSubs) this.pageLoadedSubs.unsubscribe();
    }
}
