<!-- <div *ngIf="hasUserAccess$ | async">
    <router-outlet></router-outlet>
</div> -->
<div>
    <crud-table
            title="User Types"
            name="user-type"
            [columns]="columns"
            [createButtonLabel]="createButtonLabel"
            [searchableColumns]="searchableColumns"
            [apiUrl]="apiUrl"
            baseUrl="/user-management/user-types"
    ></crud-table>
</div>
