import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { EChartsOption } from "echarts";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  LocalDatePipe,
  LocalNumberPipe,
} from "../../../../../../../app/core/base";
import { reloadControllerActions } from "../../../../../../core/store/actions/reload-controller.actions";
import { AppState } from "../../../../../../core/store/reducers/app.reducers";
import { reloadControllerSelectors } from "../../../../../../core/store/selectors/reload-controller.selectors";
import { FormConfigService } from "../../../../form.config.service";
import { ChartConfig } from "../../../../types/form.config.type";

@Component({
  selector: "cb-tab-content-chart",
  templateUrl: "./tab-content-chart.component.html",
  styleUrls: ["./tab-content-chart.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentChartComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() content!: ChartConfig;
  @Input() chartName?: string = null;
  appStore = inject(Store<AppState>);
  formConfigService = inject(FormConfigService);
  translateService = inject(TranslateService);
  localDate = inject(LocalDatePipe);
  localNumber = inject(LocalNumberPipe);

  destroyed$ = new Subject<void>();
  updateOptions$ = new BehaviorSubject<Partial<EChartsOption>>(null);
  chartOption: EChartsOption = {};
  reloadController$ = this.appStore.pipe(
    takeUntil(this.destroyed$),
    select(reloadControllerSelectors.selectTargetComponent)
  );
  constructor() {
    this.chartOption = {
      xAxis: {
        type: "category",
        data: [],
        axisLabel: {
          rotate: -45,
          formatter: (value: string) => {
            return this.localDate.transform(+value, "date");
          },
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        axisLabel: {
          fontSize: "13px",
          formatter: (value: any) => {
            return this.localNumber.transform(+value, null);
          },
        },
      },
      series: [],
    };
  }

  ngAfterViewInit() {
    this.reloadController$.subscribe((target) => {
      if (target === this.chartName) {
        this.ngOnInit();
        this.appStore.dispatch(reloadControllerActions.resetTargetComponent());
      }
    });
  }

  ngOnInit(): void {
    const apiUrl = this.formConfigService.getApiUrl(this.content.api_url);
    this.formConfigService.getChartData(apiUrl).subscribe({
      next: (response) => {
        let parsedData = response.data_chart;
        if (!Array.isArray(response.data_chart)) {
          parsedData = Object.entries(response.data_chart).map((d: any[]) => {
            if (Array.isArray(d[1])) return [...d[1]];
          });
        }
        parsedData = parsedData
          .map((r) => [Date.parse(r[0]), r[1]])
          .sort((a, b) => a[0] - b[0]);

        this.updateOptions$.next({
          series: {
            data: parsedData.map((e) => e[1]),
            type: this.content.chart_type,
            barCategoryGap: 0,
          },
          xAxis: {
            data: parsedData.map((e) => e[0]),
          },
          tooltip: {
            formatter: (params) => {
              const currencyPrefix =
                response.currency_unit || response.currency_name;
              const header = this.localDate.transform(
                +params.name,
                "date",
                "fullDate"
              );
              const footer = this.translateService.instant(
                "CHART.TRACKDAY_ITEM.CANCELLATION_PRICES.TOOLTIP",
                {
                  price:
                    "<b>" +
                    currencyPrefix +
                    this.localNumber.transform(params.value, null) +
                    "</b>",
                }
              );
              return header + "<br>" + footer;
            },
          },
          yAxis: {
            name: this.translateService.instant(
              "CHART.TRACKDAY_ITEM.CANCELLATION_PRICES.YAXIS_TITLE",
              { currency: response.currency_name }
            ),
            nameLocation: "middle",
            nameRotate: 90,
            nameGap: 70,
          },
        });
      },
      error: (err) => {},
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
